import React from 'react';
import { PlusCircleFilled } from '@ant-design/icons';
import Button, { ButtonColors } from 'components/Button';
import classes from './MyTeam.module.scss';

const MainButton = ({ setInviteModal }) => {
  return (
    <>
      <div className={classes.tabHeaderButton}>
        <Button
          type="primary"
          size="large"
          className={classes.addCButton}
          onClick={() => {
            setInviteModal(true);
          }}
          color={ButtonColors.BLUE}
          leftIcon={<PlusCircleFilled />}
          largeIcon
        >
          Invite Teammate
        </Button>
      </div>
    </>
  );
};

export default MainButton;
