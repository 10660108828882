import createTypes from 'redux-create-action-types';

export default createTypes(
  // GET
  'GET_INTERNAL_CONVERSATIONS',
  'GET_CLIENT_CONVERSATIONS',
  'GET_CHAT_DEPARTMENTS',
  'GET_CHAT_DEPARTMENTS_LIST',
  'GET_MESSAGES',
  'GET_ATTACHMENT',
  'SEARCH_INTERNAL_MEMBERS',
  'SEARCH_CLIENT_MEMBERS',
  'GET_CHAT_MEMBERS',
  // POST
  'SEND_MESSAGE',
  'MARK_READ',
  'UPDATE_CHAT_SETTINGS',
  'ADD_MEMBERS',
  'REMOVE_MEMBERS',
  'CHECK_CONVERSATION',
  'CREATE_CHAT_GROUP',
  // CUSTOM
  'SYNC_SET_MESSAGES',
  'SYNC_SET_CHAT_MEMBERS',
  'SYNC_SET_INTERNAL_COUNT',
  'SYNC_SET_CLIENT_COUNT',
  'SYNC_INCREASE_UNREAD_CHAT',
  'SYNC_INCREASE_UNREAD_CHATS',
  'SYNC_SET_COMPANIES'
);
