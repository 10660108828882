import {
  useReducerData,
  useReducerIsMore,
  useReducerLoading,
  useReducerTotal,
} from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// ORDER_REQUESTER_LIST
export const useSelectOrderRequesterList = () => {
  return useReducerData(
    REDUCER_NAMES.ORDER,
    REDUCER_ATTRIBUTES.ORDER.ORDER_REQUESTER_LIST,
    {}
  );
};

// ORDER_DETAIL_FIELDS
export const useSelectOrderDetailFields = () => {
  return useReducerData(
    REDUCER_NAMES.ORDER,
    REDUCER_ATTRIBUTES.ORDER.ORDER_DETAIL_FIELDS,
    {}
  );
};
export const useSelectOrderDetailFieldsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.ORDER,
    REDUCER_ATTRIBUTES.ORDER.ORDER_DETAIL_FIELDS
  );
};

// ORDER_LIST
export const useSelectOrderList = () => {
  return useReducerData(
    REDUCER_NAMES.ORDER,
    REDUCER_ATTRIBUTES.ORDER.ORDER_LIST,
    {}
  );
};
// CLIENT_ORDER_DETAIL
export const useSelectClientOrderDetail = () => {
  return useReducerData(
    REDUCER_NAMES.ORDER,
    REDUCER_ATTRIBUTES.ORDER.CLIENT_ORDER_DETAIL,
    {}
  );
};
export const useSelectClientOrderDetailLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.ORDER,
    REDUCER_ATTRIBUTES.ORDER.CLIENT_ORDER_DETAIL
  );
};
// CLIENT_ORDER
export const useSelectClientOrder = () => {
  return useReducerData(
    REDUCER_NAMES.ORDER,
    REDUCER_ATTRIBUTES.ORDER.CLIENT_ORDER,
    {}
  );
};
export const useSelectClientOrderLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.ORDER,
    REDUCER_ATTRIBUTES.ORDER.CLIENT_ORDER
  );
};

export const useSelectOrderListLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.ORDER,
    REDUCER_ATTRIBUTES.ORDER.ORDER_LIST
  );
};

export const useSelectOrderListIsMore = () => {
  return useReducerIsMore(
    REDUCER_NAMES.ORDER,
    REDUCER_ATTRIBUTES.ORDER.ORDER_LIST
  );
};

export const useSelectOrderListTotal = () => {
  return useReducerTotal(
    REDUCER_NAMES.ORDER,
    REDUCER_ATTRIBUTES.ORDER.ORDER_LIST,
    0
  );
};

export const useSelectDeleteOrderLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.ORDER,
    REDUCER_ATTRIBUTES.ORDER.DELETE_ORDER
  );
};
export const useSelectUpdateClientOrderStatusLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.ORDER,
    REDUCER_ATTRIBUTES.ORDER.UPDATE_CLIENT_ORDER_STATUS
  );
};
