import React from 'react';
import cn from 'classnames';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  Title,
  DisabledListField,
} from 'views/pages/ClientDetail/components/Elements';
import { EQUIPMENT_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const Equipment = ({ className, isEdit, data }) => {
  const trucks = data.assigned_trucks || [];
  return (
    <div className={cn(classes.equipmentSection, className)}>
      <Title text="Assigned Equipment" />
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            <DisabledListField
              data={trucks}
              name={'truck_unit'}
              childName="assigned_trailers"
              childNameDisplay="trailer_unit"
              label="Assigned Truck"
              childLabel="Assigned Trailers"
              note="Equipment can only be assigned from the Truck Information Section"
            />
          </FormSection>
        ) : (
          <>
            <ListInfo objKeys={EQUIPMENT_OBJ_KEYS} data={data} />
            <div
              style={{
                textAlign: 'left',
                fontStyle: 'italic',
                paddingLeft: '24px',
                marginTop: '-12px',
              }}
            >
              Equipment can only be assigned from the Truck Information Section
            </div>
          </>
        )}
      </ElementSection>
    </div>
  );
};

export default Equipment;
