import get from 'lodash/get';
import { standardRequiredFieldObj } from 'utils/form';
import { getGeneralBR } from './tabUtils/general';
import { getIFTABR } from './tabUtils/ifta';
import { getIRPBR } from './tabUtils/irp';
import { getOregonBR } from './tabUtils/oregon';
import { getNewMexicoBR } from './tabUtils/newMexico';
import { getKentuckyBR } from './tabUtils/kentucky';
import { getNewYorkBR } from './tabUtils/newYork';
import { getFilingYearBR } from './tabUtils';

export const validateObj = (tab = 'general') => {
  return tab === 'irp' || tab === 'ifta'
    ? {
        state_id: [standardRequiredFieldObj],
      }
    : {};
};

export const getFillingBR = (values = {}, tab = 'general', brFullKey) => {
  const brMapping = {
    general_year: getFilingYearBR,
    irp_year: getFilingYearBR,
    ifta_year: getFilingYearBR,
    oregon_year: getFilingYearBR,
    newMexico_year: getFilingYearBR,
    kentucky_year: getFilingYearBR,
    newYork_year: getFilingYearBR,
    general: getGeneralBR,
    irp: getIRPBR,
    ifta: getIFTABR,
    oregon: getOregonBR,
    newMexico: getNewMexicoBR,
    kentucky: getKentuckyBR,
    newYork: getNewYorkBR,
  };
  const tBrFn = brMapping[brFullKey || tab] || null;
  return tBrFn ? tBrFn(values) : null;
};
export const transformData = data => {
  const returnObj = {
    general_filing: {},
    ifta_filing: {},
    irp_filing: {},
    kentucky_filing: {},
    new_mexico_filing: {},
    new_york_filing: {},
    oregon_filing: {},
  };
  Object.keys(data || {}).forEach(key => {
    Object.keys(returnObj).forEach(sectionKey => {
      returnObj[sectionKey][key] = data[key][sectionKey];
    });
  });
  return returnObj;
};
export const checkTabHasFile = (fieldArr, data) => {
  let hasFile = false;
  (fieldArr || []).forEach(obj => {
    if (get(data, obj.key)) {
      hasFile = true;
    }
  });
  return hasFile;
};
