import React from 'react';
import cn from 'classnames';
import classes from './TopTitle.module.scss';

const TopTitle = ({ className, title }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <h2>{title}</h2>
    </div>
  );
};

export default TopTitle;
