import React from 'react';
import cn from 'classnames';
import findIndex from 'lodash/findIndex';
import { SearchOutlined } from '@ant-design/icons';
import { searchBy } from 'utils';
import Badge from 'components/Badge';
import { getDisplayUnreadCount } from 'utils/chat';
import { Checkbox, RadioGroup, Input } from 'components/FormFields';
import {
  getDefaultCompanyFilters,
  getDefaultDepartmentFilters,
  filterDepartments,
} from './utils';
import InternalChat from './InternalChat';
import ClientChat from './ClientChat';
import DepartmentTag from './components/Elements/DepartmentTag';
import classes from './Chat.module.scss';

export const getTabs = ({
  tabProps,
  searchBox,
  newChatContent,
  renderChatDetail,
  internalSearch,
  setInternalSearch,
  clientSearch,
  setClientSearch,
  permission,
}) => {
  const {
    isCollapse,
    isStart,
    isDetail,
    internalCount,
    clientCount,
  } = tabProps;
  const tabLabels = permission.tabLabels || [];
  return [
    {
      title: (
        <span>
          {internalCount > 0 && (
            <Badge count={getDisplayUnreadCount(internalCount)} size="small" />
          )}
          {tabLabels[0] || 'Internal'}
          {!isCollapse && ' Chats'}
        </span>
      ),
      content: (
        <>
          {searchBox}
          {isStart ? (
            newChatContent
          ) : isDetail ? (
            renderChatDetail('internal')
          ) : (
            <InternalChat
              {...tabProps}
              internalSearch={internalSearch}
              setInternalSearch={setInternalSearch}
            />
          )}
        </>
      ),
    },
    {
      title: (
        <span>
          {clientCount > 0 && (
            <Badge count={getDisplayUnreadCount(clientCount)} size="small" />
          )}
          {tabLabels[1] || 'Client'}
          {!isCollapse && ' Chats'}
        </span>
      ),
      content: (
        <>
          {searchBox}
          {isDetail ? (
            renderChatDetail('client')
          ) : isStart ? (
            newChatContent
          ) : (
            <ClientChat
              {...tabProps}
              clientSearch={clientSearch}
              setClientSearch={setClientSearch}
            />
          )}
        </>
      ),
    },
  ];
};
export const getFilterMenus = ({
  arr = [],
  filters = {},
  setFilters,
  isClientTab,
  keyword,
  setKeyword,
}) => {
  const filteredArr = isClientTab
    ? searchBy(arr, keyword)
    : filterDepartments(arr);
  const getDefaultAction = isClientTab
    ? getDefaultCompanyFilters
    : getDefaultDepartmentFilters;
  const checkedFilterLength = Object.keys(filters)
    .filter(k => findIndex(filteredArr, { id: parseInt(k, 10) }) >= 0)
    .map(k => filters[k])
    .filter(f => f).length;
  const menuLabel = [
    {
      subs: {
        label: (
          <div
            className={cn(classes.filterLabelWrapper, {
              [classes.isClient]: isClientTab,
            })}
          >
            Filter Options
            {isClientTab && (
              <div className={classes.filterClientSearch}>
                <Input
                  size={'medium'}
                  value={keyword}
                  placeholder={'Search clients'}
                  onChange={e => {
                    const value = e.target.value;
                    setKeyword(value);
                  }}
                  prefix={<SearchOutlined />}
                  allowClear
                  className={classes.searchFilterInput}
                />
              </div>
            )}
          </div>
        ),
      },
    },
  ];
  const menus = [
    ...menuLabel,
    {
      subs: {
        label: (
          <>
            <Checkbox
              checked={
                checkedFilterLength > 0 &&
                checkedFilterLength === filteredArr.length
              }
              label="Select All"
              onChange={e => {
                setFilters({
                  ...filters,
                  ...getDefaultAction(filteredArr, !e.target.checked),
                });
              }}
              className={classes.textSmall}
            />
          </>
        ),
      },
    },
  ];
  filteredArr.map(obj => {
    const { name, id } = obj || {};
    menus.push({
      subs: {
        label: (
          <>
            <Checkbox
              checked={!!filters[id]}
              label={
                isClientTab ? (
                  <span>{name}</span>
                ) : (
                  <DepartmentTag department={name} departmentColor={name} />
                )
              }
              onChange={e => {
                const newFilters = { ...filters };
                newFilters[id] = e.target.checked;
                setFilters(newFilters);
              }}
            />
          </>
        ),
      },
    });
    return true;
  });
  return menus;
};
export const getSortMenus = ({ sort = '', setSort }) => {
  const handleSort = e => {
    setSort(e.target.value);
  };
  const menus = [
    {
      subs: {
        label: 'Sort Options',
      },
    },
    {
      subs: {
        label: (
          <div>
            <RadioGroup
              value={sort}
              items={[
                { value: 'newest', label: 'Newest Message' },
                { value: 'oldest', label: 'Oldest Message' },
                { value: 'unread', label: 'Unread' },
                { value: 'az', label: 'A to Z' },
                { value: 'za', label: 'Z to A' },
              ]}
              onChange={handleSort}
            />
          </div>
        ),
      },
    },
  ];
  return menus;
};
