import pick from 'lodash/pick';
import get from 'lodash/get';
import {
  standardRequiredFieldObj,
  getFileFieldInitialValues,
  phoneFieldObj,
} from 'utils/form';
import { parseStringToNumber } from 'utils/number';
import { getMomentDate, getBEDate, getDateFromMMYY } from 'utils/date';
import { getOtherDataValue } from 'utils/customField';
import {
  NORMAL_FIELDS,
  NULL_FIELDS,
  DATE_FIELDS,
  FILE_FIELDS,
  NUMBER_FIELDS,
  BODY_REQUEST_NORMAL_FIELDS,
  BOOLEAN_FIELDS,
  ARR_FIELDS,
  YES_NO_FIELDS,
  IS_SAME_ADDRESS_FIELD,
  MAILING_ADDRESS_FIELDS,
} from './constants';

const getFileField = field => {
  if (!field) return '';
  return field.replace('documents.', '');
};
export const validateObj = () => {
  return {
    first_name: [standardRequiredFieldObj],
    last_name: [standardRequiredFieldObj],
    phone: [standardRequiredFieldObj, phoneFieldObj],
  };
};
export const getInitAccountCard = (accounts, cards) => {
  const newAccounts = (accounts || []).map(account => ({
    name_on_account: account.name_on_account,
    account_number: account.account_number,
    is_default_account: !!account.is_primary,
    simple_account_type: 'ACH',
    originId: account.id,
  }));
  const newCards = (cards || []).map(card => ({
    name_on_card: card.name_on_card,
    simple_card_number: card.card_number,
    simple_card_type: card.card_type,
    is_primary: !!card.is_primary,
    originId: card.id,
  }));
  return {
    accounts: newAccounts,
    cards: newCards,
  };
};
export const getInitialValues = (data, isInternalUser) => {
  const { accounts, cards } = data || {};
  const initialValues = {
    delete_attachments: [],
    accounts: accounts.map(acc => ({
      ...acc,
      originId: acc.id,
    })),
    cards: cards.map(card => ({
      ...card,
      originId: card.id,
    })),
    delete_custom_fields: [],
    other: getOtherDataValue(data.custom_fields || []),
  };
  [...NORMAL_FIELDS, ...NULL_FIELDS].map(field => {
    if (['ssn', 'dl_number'].includes(field) && isInternalUser) {
      initialValues[field] = '******';
    } else {
      const eValue = data[field];
      initialValues[field] =
        eValue || (NULL_FIELDS.includes(field) ? null : '');
      if (YES_NO_FIELDS.includes(field) && (eValue || eValue === 0)) {
        initialValues[field] = String(eValue);
      }
    }
    return true;
  });
  DATE_FIELDS.map(field => {
    if (field === 'dob' && isInternalUser) {
      initialValues[field] = '******';
    } else {
      initialValues[field] = data[field]
        ? getMomentDate(data[field], false, true)
        : '';
    }
    return true;
  });
  BOOLEAN_FIELDS.map(field => {
    initialValues[field] = data[field] ? [field] : [];
    return true;
  });
  ARR_FIELDS.map(field => {
    initialValues[field] = data[field] ? data[field].map(v => v.id) : [];
    return true;
  });

  return {
    ...initialValues,
    ...getFileFieldInitialValues(FILE_FIELDS, data, 'documents'),
  };
};
export const getDriverBR = (
  values = {},
  driverId,
  avatarFile,
  currentProfilePic,
  finalProfileSrc
) => {
  const { delete_attachments, other, delete_custom_fields } = values;
  let isSameAddress = false;
  const bodyRequest = {
    ...pick(values, BODY_REQUEST_NORMAL_FIELDS),
  };
  if (driverId) {
    bodyRequest.employee_id = driverId;
  }
  DATE_FIELDS.map(field => {
    bodyRequest[field] = values[field] ? getBEDate(values[field]) : '';
    return true;
  });
  YES_NO_FIELDS.map(field => {
    const value = values[field];
    bodyRequest[field] = value ? parseInt(value, 10) : '';
    return true;
  });
  NUMBER_FIELDS.map(field => {
    bodyRequest[field] = parseStringToNumber(values[field]);
    return true;
  });
  BOOLEAN_FIELDS.map(field => {
    bodyRequest[field] = values[field] && values[field].length > 0 ? 1 : 0;
    if (field === IS_SAME_ADDRESS_FIELD) {
      isSameAddress = bodyRequest[field];
    }
    return true;
  });
  if (isSameAddress) {
    MAILING_ADDRESS_FIELDS.map(field => {
      bodyRequest[field] = values[field.replace('mailing_', '')];
      return true;
    });
  }
  if (
    !!values.dob &&
    typeof values.dob === 'string' &&
    values.dob.includes('*')
  ) {
    delete bodyRequest.dob;
  }
  if (!!values.ssn && values.ssn.includes('*')) {
    delete bodyRequest.ssn;
  }
  if (!!values.dl_number && values.dl_number.includes('*')) {
    delete bodyRequest.dl_number;
  }
  const fData = new FormData();
  Object.keys(bodyRequest).map(k => {
    fData.append(
      k,
      bodyRequest[k] || bodyRequest[k] === 0 ? bodyRequest[k] : ''
    );
    return true;
  });
  if (avatarFile && avatarFile.type && finalProfileSrc) {
    fData.append('profile_pic', avatarFile);
    fData.append('remove_profile_pic', 0);
  } else if (currentProfilePic && !finalProfileSrc) {
    fData.append('remove_profile_pic', 1);
  }
  ARR_FIELDS.map(field => {
    const vals = values[field] || [];
    vals.map(val => {
      fData.append(`${field}[]`, val);
      return true;
    });
    if (vals.length === 0) {
      fData.append(`${field}[]`, '');
    }
    return true;
  });
  FILE_FIELDS.map(originKey => {
    const key = getFileField(originKey);
    if (values[key] && values[key].length > 0) {
      values[key].map(file => {
        if (file.originFileObj && !file.id) {
          fData.append(`${key}[]`, file.originFileObj);
        }
        return true;
      });
    } else {
      fData.append(key, '');
    }
    return true;
  });
  if (delete_attachments && delete_attachments.length > 0) {
    delete_attachments.map(id => {
      fData.append('delete_documents[]', id);
      return true;
    });
  }
  if (other && other.length > 0) {
    other.map((field, index) => {
      fData.append(`custom_fields[${index}][field_name]`, field.field_name);
      if (field.originId) {
        fData.append(`custom_fields[${index}][id]`, field.originId);
      }
      const firstDocument = get(field, 'document[0]') || null;
      const existingFileName = get(field, 'originData.document_name', '');
      if (firstDocument && !firstDocument.url) {
        fData.append(
          `custom_fields[${index}][document]`,
          firstDocument.originFileObj
        );
      } else if (!firstDocument && !!existingFileName) {
        fData.append(`custom_fields[${index}][document_delete]`, 1);
      }
      return true;
    });
  } else {
    fData.append('custom_fields', '');
  }
  if (delete_custom_fields && delete_custom_fields.length > 0) {
    delete_custom_fields.map(id => {
      fData.append('delete_custom_fields[]', id);
      return true;
    });
  }
  return fData;
};
export const getCheckingAccountBR = (accounts = []) => {
  return accounts
    .filter(account => !account.originId)
    .map(account => ({
      ...pick(account, [
        'name_on_account',
        'bank_name',
        'account_number',
        'type',
      ]),
      ach: account.routing_number_ach || null,
      wire: account.routing_number_wire || null,
      is_primary: account.is_default_account ? 1 : 0,
    }));
};
export const getFuelCardsBR = (cards = []) => {
  return cards
    .filter(card => !card.originId)
    .map(card => ({
      ...pick(card, ['name_on_card', 'card_type', 'credit_or_debit']),
      card_number: parseStringToNumber(card.card_number),
      carrier_id: parseStringToNumber(card.carrier_id),
      pin: parseStringToNumber(card.pin),
      cvv: parseStringToNumber(card.cvv),
      expiration_date: getBEDate(
        getDateFromMMYY(card.expiration_date, '/'),
        false,
        'YYYY-MM'
      ),
      is_primary: card.is_primary ? 1 : 0,
    }));
};
export const parseCheckingAccountsData = data => {
  const { payment_accounts } = data || {};
  return (payment_accounts || []).map(account => ({
    is_default_account: !!account.is_primary,
    name_on_account: account.name_on_account,
    simple_account_type: 'ACH',
    simple_account_number: account.account_number,
    id: account.id,
  }));
};
export const parseFuelCardsData = data => {
  const { fuel_cards } = data || {};
  return (fuel_cards || []).map(card => ({
    is_primary: !!card.is_primary,
    name_on_card: card.name_on_card,
    simple_card_type: card.card_type,
    simple_card_number: card.last4 ? `**********${card.last4}` : '',
    id: card.id,
  }));
};
export const getTruckTrailerAssigned = data => {
  const { assigned_trucks } = data || {};
  const trucks = [];
  const trailers = [];
  (assigned_trucks || []).map(truck => {
    trucks.push(truck.truck_unit);
    (truck.assigned_trailers || []).map(trailer => {
      trailers.push(trailer.trailer_unit);
      return true;
    });
    return true;
  });
  return { trucks, trailers };
};
