import createTypes from 'redux-create-action-types';

export default createTypes(
  'SYNC_UPDATE_UNDERWRITING_RECORD',
  'SYNC_SET_UNDERWRITING_PRIMARY_CONTACT',
  'GET_BACKGROUNDS',
  'GET_UCCS',
  'GET_BUSINESS_ASSOCIATIONS',
  'GET_CREDITS',
  'GET_ALERTS',
  'GET_LOCATIONS',
  'CREATE_UNDERWRITING_RECORD',
  'UPDATE_UNDERWRITING_RECORD',
  'UPDATE_UNDERWRITING_RECORD_STATUS'
);
