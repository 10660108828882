import {
  apiSubscribeReminderEmail,
  apiGetCompanyReminders,
  apiChangeReminderStatus,
  apiGetReminders,
  apiCreateReminder,
  apiGetReminderDetail,
  apiUpdateReminder,
  apiDeleteReminder,
} from 'api';

import { reminderTypes } from '../types';

// SYNC_UPDATE_REMINDER_DETAIL_STATUS
export const syncUpdateReminderdetailStatus = status => dispatch => {
  dispatch({
    type: reminderTypes.SYNC_UPDATE_REMINDER_DETAIL_STATUS,
    payload: { status },
  });
};
// SYNC_UPDATE_COMPANY_REMINDER_CHECKLISTS
export const syncUpdateCompanyReminderChecklists = (
  id,
  checklists,
  key
) => dispatch => {
  dispatch({
    type: reminderTypes.SYNC_UPDATE_COMPANY_REMINDER_CHECKLISTS,
    payload: { id, checklists, key },
  });
};
// SYNC_UPDATE_COMPANY_REMINDERS
export const syncUpdateCompanyReminders = (data, key) => dispatch => {
  dispatch({
    type: reminderTypes.SYNC_UPDATE_COMPANY_REMINDERS,
    payload: { data, key },
  });
};

// GET_ACTIVE_REMINDERS
export const getActiveReminders = (filters = {}, cb) => {
  return {
    isAsyncCall: true,
    baseType: reminderTypes.GET_ACTIVE_REMINDERS,
    payload: {},
    asyncCall: () => {
      return apiGetReminders({
        ...filters,
        status: 'active',
      });
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_INACTIVE_REMINDERS
export const getInactiveReminders = (filters = {}, cb) => {
  return {
    isAsyncCall: true,
    baseType: reminderTypes.GET_INACTIVE_REMINDERS,
    payload: {},
    asyncCall: () => {
      return apiGetReminders({
        ...filters,
        status: 'inactive',
      });
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_ACTIVE_COMPANY_REMINDERS
export const getActiveCompanyReminders = (id, filters = {}, cb) => {
  return {
    isAsyncCall: true,
    baseType: reminderTypes.GET_ACTIVE_COMPANY_REMINDERS,
    payload: {},
    asyncCall: () => {
      return apiGetCompanyReminders(id, {
        ...filters,
        status: 'active',
      });
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_DISMISSED_COMPANY_REMINDERS
export const getDismissedCompanyReminders = (id, filters = {}, cb) => {
  return {
    isAsyncCall: true,
    baseType: reminderTypes.GET_DISMISSED_COMPANY_REMINDERS,
    payload: {},
    asyncCall: () => {
      return apiGetCompanyReminders(id, {
        ...filters,
        status: 'dismiss',
      });
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_COMPLETED_COMPANY_REMINDERS
export const getCompletedCompanyReminders = (id, filters = {}, cb) => {
  return {
    isAsyncCall: true,
    baseType: reminderTypes.GET_COMPLETED_COMPANY_REMINDERS,
    payload: {},
    asyncCall: () => {
      return apiGetCompanyReminders(id, {
        ...filters,
        status: 'complete',
      });
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_REMINDER_DETAIL
export const getReminderDetail = (id, cb) => {
  return {
    isAsyncCall: true,
    baseType: reminderTypes.GET_REMINDER_DETAIL,
    payload: {},
    asyncCall: () => {
      return apiGetReminderDetail(id);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CREATE_REMINDER
export const createReminder = (data = {}, cb) => {
  return {
    isAsyncCall: true,
    baseType: reminderTypes.CREATE_REMINDER,
    payload: {},
    asyncCall: () => {
      return apiCreateReminder(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_REMINDER
export const updateReminder = (id, data = {}, cb) => {
  return {
    isAsyncCall: true,
    baseType: reminderTypes.UPDATE_REMINDER,
    payload: {},
    asyncCall: () => {
      return apiUpdateReminder(id, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// DELETE_REMINDER
export const deleteReminder = (ids, cb) => {
  return {
    isAsyncCall: true,
    baseType: reminderTypes.DELETE_REMINDER,
    payload: {},
    asyncCall: () => {
      return apiDeleteReminder(ids);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CHANGE_REMINDER_STATUS
export const changeReminderStatus = (ids, status, companyId, cb) => {
  return {
    isAsyncCall: true,
    baseType: reminderTypes.CHANGE_REMINDER_STATUS,
    payload: { ids },
    asyncCall: () => {
      return apiChangeReminderStatus(ids, status, companyId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// SUBSCRIBE_REMINDER_EMAIL
export const subscribeReminderEmail = (companyId, memberId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: reminderTypes.SUBSCRIBE_REMINDER_EMAIL,
    payload: {},
    asyncCall: () => {
      return apiSubscribeReminderEmail(companyId, memberId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
