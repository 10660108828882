import React from 'react';
import cn from 'classnames';
import classes from './NewForm.module.scss';
import Form, { useForm, FormItem } from 'components/Form';
import { Input } from 'components/FormFields';
import Button, { ButtonColors } from 'components/Button';

const NewForm = ({ className, onCancel = () => {}, onSubmit, loading }) => {
  const [form] = useForm();
  return (
    <div className={cn(classes.wrapper, className)}>
      <Form onFinish={onSubmit} initialValues={{}} form={form}>
        <FormItem name={'text'} label="">
          <Input
            name="text"
            form={form}
            size="medium"
            isBlue
            placeholder="Add an item"
            autoFocus
          />
        </FormItem>
        <FormItem shouldUpdate className={classes.footerAction}>
          {() => {
            const values = form.getFieldsValue();
            return (
              <>
                <Button
                  color={ButtonColors.BLUE}
                  htmlType="submit"
                  disabled={!(values.text || '').trim()}
                  loading={loading}
                >
                  Add Item
                </Button>
                <Button
                  htmlType="button"
                  color={ButtonColors.DEFAULT}
                  onClick={() => {
                    form.resetFields();
                    onCancel();
                  }}
                >
                  Cancel
                </Button>
              </>
            );
          }}
        </FormItem>
      </Form>
    </div>
  );
};

export default NewForm;
