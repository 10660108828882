import React from 'react';
import cn from 'classnames';
import { useOptions } from 'helper/hooks';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  Title,
} from 'views/pages/ClientDetail/components/Elements';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { TITLE_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const TrailerTitle = ({ className, isEdit, form, data }) => {
  const { stateOptions } = useOptions();
  return (
    <div className={cn(classes.trailerTitleSection, className)}>
      <Title text="Trailer Title" />
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            <InputItem
              name={'title_receipt'}
              label="Title / Title Receipt"
              form={form}
              type="file"
            />
            <InputItem
              name={'title_number'}
              label="Title Number"
              form={form}
              addressGroupField
            />
            <InputItem
              name={'trailer_state_id'}
              label="State"
              form={form}
              addressGroupField
              type="select"
              options={stateOptions}
            />
          </FormSection>
        ) : (
          <ListInfo objKeys={TITLE_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default TrailerTitle;
