import { STATUS_VALUES } from 'helper/const';

export const STATUS_OPTIONS = [
  { value: STATUS_VALUES.ACTIVE, label: 'Active' },
  { value: STATUS_VALUES.COMPLETE, label: 'Completed' },
  { value: STATUS_VALUES.DISMISS, label: 'Dismissed' },
];
export const REMINDER_STORE_KEY = {
  [STATUS_VALUES.ACTIVE]: 'activeCompanyReminders',
  [STATUS_VALUES.DISMISS]: 'dismissedCompanyReminders',
  [STATUS_VALUES.COMPLETE]: 'completedCompanyReminders',
};
