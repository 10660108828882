const initialAuthState = {
  // GET
  currentUser: {
    loading: false,
    data: {},
    error: '',
  },
  currentPassword: '',

  // POST
  login: {
    loading: false,
    access_token: '',
    error: '',
  },
  createUser: {
    loading: false,
    data: {},
    error: '',
  },
  forgotPassword: {
    loading: false,
    data: {},
    error: '',
  },
  validateToken: {
    loading: false,
    data: {},
    error: '',
  },
  resetPassword: {
    loading: false,
    data: {},
    error: '',
  },
  confirmRegister: {
    loading: false,
    data: {},
    error: '',
  },
  updateProfile: {
    loading: false,
    data: {},
    error: '',
  },
  changePassword: {
    loading: false,
    data: {},
    error: '',
  },
  validateInviteToken: {
    loading: false,
    data: {},
    error: '',
  },
  validateClientToken: {
    loading: false,
    data: {},
    error: '',
  },
  createAccount: {
    loading: false,
    data: {},
    error: '',
  },
  createClientAccount: {
    loading: false,
    data: {},
    error: '',
  },
  validatePassword: {
    loading: false,
    data: {},
    error: '',
  },
  additionalAttribute: {
    tokenAddedAt: 0,
    tokenExpireAt: 0,
  },
};

export { initialAuthState };
