/* eslint-disable */
import React, { createElement } from 'react';
// react library for routing
// import { connect } from 'react-redux';
// import cx from 'classnames';
// reactstrap components
import {
  Button,
  Timeline as PVTimeline,
  Select as PVSelect,
  RadioGroup,
  RadioButtonGroup,
  Avatar,
  Space,
} from 'components';

import {
  UserOutlined,
  SearchOutlined,
  InfoCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  InboxOutlined,
  DislikeOutlined,
  LikeOutlined,
  DislikeFilled,
  LikeFilled,
  ClockCircleOutlined,
  PlusOutlined,
  AppleOutlined,
  QuestionCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
// core components
// import IndexNavbar from 'components/Navbars/IndexNavbar.js';
// import IndexHeader from 'components/Headers/IndexHeader.js';
// import AuthFooter from 'components/Footers/AuthFooter.js';
// import { dummyAsyncReduxEvent } from 'store/actions/app';
// Ant Components
import {
  // Breadcrumb,
  // Menu as AntdMenu,
  // Dropdown,
  Pagination,
  PageHeader,
  Tag,
  Steps,
  Checkbox,
  DatePicker,
  Input,
  Form,
  Rate,
  // Radio,
  Switch,
  Slider,
  // Select,
  Transfer,
  Upload,
  InputNumber,
  Badge,
  Comment,
  Tooltip,
  Collapse,
  Card,
  Calendar,
  Popover,
  // Timeline,
  Tabs,
  Table,
  Alert,
  Drawer,
  Modal,
  message,
  notification,
  Progress,
  Popconfirm,
  Result,
  Typography,
} from 'antd';
import ImgCrop from 'antd-img-crop';

import moment from 'moment';

const { Step } = Steps;
// const { Option } = Select;
const { Dragger } = Upload;
const { TextArea } = Input;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Paragraph, Text } = Typography;

class RestComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerVisible: false,
      showModal: false,
    };
  }
  renderPagination = () => {
    return <Pagination total={100} current={4} />;
  };

  renderPageHeader = () => {
    const routes = [
      {
        path: 'index',
        breadcrumbName: 'First Link',
      },
      {
        path: 'first',
        breadcrumbName: 'Second Link',
      },
      {
        path: 'second',
        breadcrumbName: 'Last Link',
      },
    ];

    return (
      <PageHeader
        title={'Title'}
        breadcrumb={{ routes }}
        subTitle={'This is a subtitle'}
        onBack={() => {}}
        tags={<Tag>Tag</Tag>}
        extra={[
          <Button type={'ghost'} key="3">
            Operation
          </Button>,
          <Button key="2">Primary</Button>,
        ]}
        style={{ border: 'solid 1px #595959' }}
      />
    );
  };

  renderInput = () => {
    return (
      <Space direction={'vertical'} size={16}>
        <Space direction={'horizontal'}>
          <Input size={'large'} placeholder={'Large Input'} />
          <Input
            size={'large'}
            placeholder={'Large Input'}
            suffix={<SearchOutlined />}
          />
          <Input
            size={'large'}
            placeholder={'Large Input'}
            prefix={<SearchOutlined />}
          />
          <Input
            size={'large'}
            placeholder={'Large Input'}
            prefix={<SearchOutlined />}
            suffix={<InfoCircleOutlined />}
          />
        </Space>
        <Space direction={'horizontal'}>
          <Input placeholder={'Large Input'} />
          <Input placeholder={'Large Input'} suffix={<SearchOutlined />} />
          <Input placeholder={'Large Input'} prefix={<SearchOutlined />} />
          <Input
            placeholder={'Large Input'}
            prefix={<SearchOutlined />}
            suffix={<InfoCircleOutlined />}
          />
        </Space>
        <Space direction={'horizontal'}>
          <Input size={'small'} placeholder={'Large Input'} />
          <Input
            size={'small'}
            placeholder={'Large Input'}
            suffix={<SearchOutlined />}
          />
          <Input
            size={'small'}
            placeholder={'Large Input'}
            prefix={<SearchOutlined />}
          />
          <Input
            size={'small'}
            placeholder={'Large Input'}
            prefix={<SearchOutlined />}
            suffix={<InfoCircleOutlined />}
          />
        </Space>
        <Space direction={'horizontal'}>
          <Form.Item>
            <Input placeholder={'Large Input'} />
          </Form.Item>
          <Form.Item validateStatus={'error'} hasFeedback={true}>
            <Input placeholder={'Large Input'} />
          </Form.Item>
          <Form.Item validateStatus={'warning'} hasFeedback={true}>
            <Input placeholder={'Large Input'} />
          </Form.Item>
          <Form.Item validateStatus={'success'} hasFeedback={true}>
            <Input placeholder={'Large Input'} />
          </Form.Item>
        </Space>
        <Space direction={'horizontal'}>
          <Form.Item help={'Normal Help Message'}>
            <Input placeholder={'Large Input'} />
          </Form.Item>
          <Form.Item
            validateStatus={'error'}
            hasFeedback={true}
            help={'Error Help Message'}
          >
            <Input placeholder={'Large Input'} />
          </Form.Item>
          <Form.Item
            validateStatus={'warning'}
            hasFeedback={true}
            help={'Warning Help Message'}
          >
            <Input placeholder={'Large Input'} />
          </Form.Item>
          <Form.Item
            validateStatus={'success'}
            hasFeedback={true}
            help={'Success Help Message'}
          >
            <Input placeholder={'Large Input'} />
          </Form.Item>
        </Space>
      </Space>
    );
  };

  renderTransfer = () => {
    return (
      <Transfer
        showSearch={true}
        listStyle={{
          width: 250,
          height: 300,
        }}
        footer={() => (
          <div style={{ float: 'right' }}>
            <Button type={'ghost'}>Button</Button>
          </div>
        )}
      />
    );
  };

  renderInputNumber = () => {
    return (
      <Space direction={'horizontal'}>
        <InputNumber size="large" min={1} max={100000} defaultValue={3} />
        <InputNumber min={1} max={100000} defaultValue={3} />
        <InputNumber size="small" min={1} max={100000} defaultValue={3} />
      </Space>
    );
  };

  renderBadge = () => {
    return (
      <Space direction={'vertical'}>
        <Space direction={'horizontal'}>
          <Badge count={99}></Badge>
          <Badge count={100}></Badge>
          <Badge count={99} overflowCount={10}></Badge>
          <Badge count={1000} overflowCount={999}></Badge>
        </Space>
        <Space direction={'vertical'}>
          <Badge status="success" text="Success" />
          <Badge status="error" text="Error" />
          <Badge status="default" text="Default" />
          <Badge status="processing" text="Processing" />
          <Badge status="warning" text="Warning" />
        </Space>
      </Space>
    );
  };

  renderCollapse = () => {
    const text = `
      A dog is a type of domesticated animal.
      Known for its loyalty and faithfulness,
      it can be found as a welcome guest in many households across the world.`;
    return (
      <Space direction={'vertical'} size={32}>
        <Collapse defaultActiveKey={['1']}>
          <Panel header="This is panel header 1" key="1">
            <p>{text}</p>
          </Panel>
          <Panel header="This is panel header 2" key="2">
            <p>{text}</p>
          </Panel>
          <Panel header="This is panel header 3" key="3">
            <p>{text}</p>
          </Panel>
        </Collapse>
        <Collapse bordered={false} defaultActiveKey={['1']}>
          <Panel header="This is panel header 1" key="1">
            <p>{text}</p>
          </Panel>
          <Panel header="This is panel header 2" key="2">
            <p>{text}</p>
          </Panel>
          <Panel header="This is panel header 3" key="3">
            <p>{text}</p>
          </Panel>
        </Collapse>
      </Space>
    );
  };

  renderCard = () => {
    return (
      <Space direction={'horizontal'}>
        <Card
          title="Default size card"
          extra={<a href="#">More</a>}
          style={{ width: 300 }}
        >
          <p>Card content</p>
          <p>Card content</p>
          <p>Card content</p>
        </Card>
        <Card
          size="small"
          title="Small size card"
          extra={<a href="#">More</a>}
          style={{ width: 300 }}
        >
          <p>Card content</p>
          <p>Card content</p>
          <p>Card content</p>
        </Card>
      </Space>
    );
  };

  renderCalendar = () => {
    return (
      <Space>
        <Calendar />
      </Space>
    );
  };

  renderPopover = () => {
    const buttonWidth = 70;
    const text = <span>Popover title</span>;
    const content = (
      <div>
        <p>Popover content goes here and here and here</p>
      </div>
    );
    return (
      <Space direction={'vertical'}>
        <div style={{ marginLeft: buttonWidth, whiteSpace: 'nowrap' }}>
          <Popover
            placement="topLeft"
            title={text}
            content={content}
            trigger="click"
          >
            <Button>TL</Button>
          </Popover>
          <Popover
            placement="top"
            title={text}
            content={content}
            trigger="click"
          >
            <Button>Top</Button>
          </Popover>
          <Popover
            placement="topRight"
            title={text}
            content={content}
            trigger="click"
          >
            <Button>TR</Button>
          </Popover>
        </div>
        <div style={{ width: buttonWidth, float: 'left' }}>
          <Popover
            placement="leftTop"
            title={text}
            content={content}
            trigger="click"
          >
            <Button>LT</Button>
          </Popover>
          <Popover
            placement="left"
            title={text}
            content={content}
            trigger="click"
          >
            <Button>Left</Button>
          </Popover>
          <Popover
            placement="leftBottom"
            title={text}
            content={content}
            trigger="click"
          >
            <Button>LB</Button>
          </Popover>
        </div>
        <div style={{ width: buttonWidth, marginLeft: buttonWidth * 4 + 24 }}>
          <Popover
            placement="rightTop"
            title={text}
            content={content}
            trigger="click"
          >
            <Button>RT</Button>
          </Popover>
          <Popover
            placement="right"
            title={text}
            content={content}
            trigger="click"
          >
            <Button>Right</Button>
          </Popover>
          <Popover
            placement="rightBottom"
            title={text}
            content={content}
            trigger="click"
          >
            <Button>RB</Button>
          </Popover>
        </div>
        <div
          style={{
            marginLeft: buttonWidth,
            clear: 'both',
            whiteSpace: 'nowrap',
          }}
        >
          <Popover
            placement="bottomLeft"
            title={text}
            content={content}
            trigger="click"
          >
            <Button>BL</Button>
          </Popover>
          <Popover
            placement="bottom"
            title={text}
            content={content}
            trigger="click"
          >
            <Button>Bottom</Button>
          </Popover>
          <Popover
            placement="bottomRight"
            title={text}
            content={content}
            trigger="click"
          >
            <Button>BR</Button>
          </Popover>
        </div>
      </Space>
    );
  };

  renderTooltip = () => {
    const text = <span>Tooltip text</span>;
    const buttonWidth = 70;
    return (
      <Space direction={'vertical'}>
        <div style={{ marginLeft: buttonWidth, whiteSpace: 'nowrap' }}>
          <Tooltip placement="topLeft" title={text}>
            <Button>TL</Button>
          </Tooltip>
          <Tooltip placement="top" title={text}>
            <Button>Top</Button>
          </Tooltip>
          <Tooltip placement="topRight" title={text}>
            <Button>TR</Button>
          </Tooltip>
        </div>
        <div style={{ width: buttonWidth, float: 'left' }}>
          <Tooltip placement="leftTop" title={text}>
            <Button>LT</Button>
          </Tooltip>
          <Tooltip placement="left" title={text}>
            <Button>Left</Button>
          </Tooltip>
          <Tooltip placement="leftBottom" title={text}>
            <Button>LB</Button>
          </Tooltip>
        </div>
        <div style={{ width: buttonWidth, marginLeft: buttonWidth * 4 + 24 }}>
          <Tooltip placement="rightTop" title={text}>
            <Button>RT</Button>
          </Tooltip>
          <Tooltip placement="right" title={text}>
            <Button>Right</Button>
          </Tooltip>
          <Tooltip placement="rightBottom" title={text}>
            <Button>RB</Button>
          </Tooltip>
        </div>
        <div
          style={{
            marginLeft: buttonWidth,
            clear: 'both',
            whiteSpace: 'nowrap',
          }}
        >
          <Tooltip placement="bottomLeft" title={text}>
            <Button>BL</Button>
          </Tooltip>
          <Tooltip placement="bottom" title={text}>
            <Button>Bottom</Button>
          </Tooltip>
          <Tooltip placement="bottomRight" title={text}>
            <Button>BR</Button>
          </Tooltip>
        </div>
      </Space>
    );
  };

  renderTimeline = () => {
    return (
      <Space direction={'horizontal'}>
        <PVTimeline
          items={[
            { content: 'Create a services site 2015-09-01' },
            { content: 'Solve initial network problems 2015-09-01' },
            { content: 'Technical testing 2015-09-01' },
            { content: 'Network problems being solved 2015-09-01' },
          ]}
        />
        <PVTimeline
          mode={'right'}
          items={[
            { content: 'Create a services site 2015-09-01' },
            { content: 'Solve initial network problems 2015-09-01' },
            {
              content: 'Technical testing 2015-09-01',
              dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
              color: 'red',
            },
            { content: 'Network problems being solved 2015-09-01' },
          ]}
        />
        <PVTimeline
          mode={'alternate'}
          items={[
            { content: 'Create a services site 2015-09-01' },
            {
              content: 'Solve initial network problems 2015-09-01',
              color: 'green',
            },
            {
              content:
                'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
              dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
            },
            {
              content: 'Network problems being solved 2015-09-01',
              color: 'red',
            },
            { content: 'Create a services site 2015-09-01' },
            {
              content: 'Technical testing 2015-09-01',
              dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
            },
          ]}
        />
      </Space>
    );
  };

  renderTags = () => {
    return (
      <Space direction={'vertical'}>
        <Space direction={'horizontal'}>
          <Tag>Tag 1</Tag>
          <Tag closable={true}>Closable Tag 1</Tag>
          <Tag closable={true}>Closable Tag 2</Tag>
          <Tag>
            <PlusOutlined /> New Tag
          </Tag>
        </Space>
        <Space direction={'horizontal'}>
          <Tag color="success">success</Tag>
          <Tag color="processing">processing</Tag>
          <Tag color="error">error</Tag>
          <Tag color="warning">warning</Tag>
          <Tag color="default">default</Tag>
        </Space>
      </Space>
    );
  };

  renderAlert = () => {
    return (
      <Space direction={'vertical'} style={{ width: '100%' }}>
        <Space direction={'horizontal'}>
          <Alert message={'Success Text'} type={'success'} closable={true} />
          <Alert
            message={'Success Text'}
            type={'info'}
            closeText={'close now'}
            style={{ width: 200 }}
          />
          <Alert message={'Success Text'} type={'warning'} />
          <Alert message={'Success Text'} type={'error'} />
        </Space>
        <Space direction={'horizontal'}>
          <Alert
            showIcon={true}
            message={'Success Text'}
            type={'success'}
            closable={true}
          />
          <Alert
            showIcon={true}
            message={'Success Text'}
            type={'info'}
            closeText={'close now'}
            style={{ width: 200 }}
          />
          <Alert showIcon={true} message={'Success Text'} type={'warning'} />
          <Alert showIcon={true} message={'Success Text'} type={'error'} />
        </Space>
        <Space direction={'horizontal'}>
          <Alert
            message="Success"
            description="Detailed description and advice about successful copywriting."
            type="success"
            showIcon
          />
          <Alert
            message="Info"
            description="Detailed description and advice about successful copywriting."
            type="info"
            showIcon
          />
          <Alert
            message="Warning"
            description="Detailed description and advice about successful copywriting."
            type="warning"
            showIcon
          />
          <Alert
            message="Error"
            description="Detailed description and advice about successful copywriting."
            type="error"
            showIcon
          />
        </Space>
        <Space direction={'horizontal'}>
          <Alert
            message="Success"
            description="Detailed description and advice about successful copywriting."
            type="success"
            closable={true}
          />
          <Alert
            message="Info"
            description="Detailed description and advice about successful copywriting."
            type="info"
            closable={true}
          />
          <Alert
            message="Warning"
            description="Detailed description and advice about successful copywriting."
            type="warning"
            closable={true}
          />
          <Alert
            message="Error"
            description="Detailed description and advice about successful copywriting."
            type="error"
            closable={true}
          />
        </Space>
      </Space>
    );
  };

  renderDrawer = () => {
    const { drawerVisible } = this.state;
    return (
      <div>
        <Button
          onClick={() => {
            this.setState({
              drawerVisible: !!!drawerVisible,
            });
          }}
        >
          Open
        </Button>
        <Drawer
          title={'Drawer'}
          visible={drawerVisible}
          onClose={() => {
            this.setState({
              drawerVisible: false,
            });
          }}
          footer={
            <div style={{ float: 'right' }}>
              <Button
                style={{ marginRight: 8 }}
                onClick={() => {
                  this.setState({
                    drawerVisible: false,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                type={'primary'}
                onClick={() => {
                  this.setState({
                    drawerVisible: false,
                  });
                }}
              >
                Submit
              </Button>
            </div>
          }
        />
      </div>
    );
  };

  renderModal = () => {
    const config = {
      title: 'Do you want to delete these items?',
      content:
        'Energistically communicate client-focused e-commerce without dynamic markets',
    };
    return (
      <Space direction={'vertical'}>
        <Space>
          <Button
            onClick={() =>
              Modal.confirm({
                ...config,
                icon: <QuestionCircleOutlined />,
              })
            }
          >
            Confirm
          </Button>
          <Button onClick={() => Modal.info(config)}>Info</Button>
          <Button onClick={() => Modal.warn(config)}>Warning</Button>
          <Button onClick={() => Modal.error(config)}>Error</Button>
          <Button onClick={() => Modal.success(config)}>Success</Button>
        </Space>
        <Space>
          <div>
            <Button
              type="primary"
              onClick={() => {
                this.setState({
                  showModal: true,
                });
              }}
            >
              Open Modal
            </Button>
            <Modal
              title="Basic Modal"
              visible={this.state.showModal}
              onOk={() => this.setState({ showModal: false })}
              onCancel={() => this.setState({ showModal: false })}
            >
              <p>Some contents...</p>
              <p>Some contents...</p>
              <p>Some contents...</p>
            </Modal>
          </div>
        </Space>
      </Space>
    );
  };

  renderMessage = () => {
    return (
      <Space direction={'horizontal'}>
        <Button onClick={() => message.success('This is success message.')}>
          Success
        </Button>
        <Button onClick={() => message.info('This is info message.')}>
          Info
        </Button>
        <Button onClick={() => message.warn('This is warning message.')}>
          Warning
        </Button>
        <Button onClick={() => message.error('This is error message.')}>
          Error
        </Button>
        <Button onClick={() => message.loading('This is loading message.')}>
          Loading
        </Button>
      </Space>
    );
  };

  renderNotifications = () => {
    const openNotificationWithIcon = type => {
      notification[type]({
        message: 'Notification Title',
        description:
          'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      });
    };
    return (
      <Space>
        <Button
          onClick={() =>
            notification.open({
              message: 'Notification Title',
              description:
                'I will never close automatically. This is a purposely very very long description that has many many characters and words.',
              duration: 0,
            })
          }
        >
          Notification
        </Button>
        <Button
          onClick={() =>
            notification.open({
              message: 'Notification Title',
              description:
                'A function will be be called after the notification is closed (automatically after the "duration" time of manually).',
              btn: (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => notification.close('btnconfirm')}
                >
                  Confirm
                </Button>
              ),
              key: 'btnconfirm',
            })
          }
        >
          Confirm
        </Button>
        <Button onClick={() => openNotificationWithIcon('success')}>
          Success
        </Button>
        <Button onClick={() => openNotificationWithIcon('info')}>Info</Button>
        <Button onClick={() => openNotificationWithIcon('warning')}>
          Warning
        </Button>
        <Button onClick={() => openNotificationWithIcon('error')}>Error</Button>
      </Space>
    );
  };

  renderProgress = () => {
    return (
      <Space direction={'horizontal'}>
        <Space direction={'vertical'} style={{ width: 300 }}>
          <Progress percent={30} status={'info'} />
          <Progress percent={50} status="active" />
          <Progress percent={70} status="exception" />
          <Progress percent={100} />
          <Progress percent={50} showInfo={false} />
        </Space>
        <Space direction={'vertical'}>
          <Progress type="circle" percent={75} />
          <Progress type="circle" percent={70} status="exception" />
          <Progress type="circle" percent={100} />
        </Space>
      </Space>
    );
  };

  renderPopconfirm = () => {
    const text = 'Are you sure to delete this task?';
    function confirm() {
      message.info('Clicked on Yes.');
    }
    return (
      <Space direction={'vertical'}>
        <div style={{ marginLeft: 70, whiteSpace: 'nowrap' }}>
          <Popconfirm
            placement="topLeft"
            title={text}
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
          >
            <Button>TL</Button>
          </Popconfirm>
          <Popconfirm
            placement="top"
            title={text}
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
          >
            <Button>Top</Button>
          </Popconfirm>
          <Popconfirm
            placement="topRight"
            title={text}
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
          >
            <Button>TR</Button>
          </Popconfirm>
        </div>
        <div style={{ width: 70, float: 'left' }}>
          <Popconfirm
            placement="leftTop"
            title={text}
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
          >
            <Button>LT</Button>
          </Popconfirm>
          <Popconfirm
            placement="left"
            title={text}
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
          >
            <Button>Left</Button>
          </Popconfirm>
          <Popconfirm
            placement="leftBottom"
            title={text}
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
          >
            <Button>LB</Button>
          </Popconfirm>
        </div>
        <div style={{ width: 70, marginLeft: 304 }}>
          <Popconfirm
            placement="rightTop"
            title={text}
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
          >
            <Button>RT</Button>
          </Popconfirm>
          <Popconfirm
            placement="right"
            title={text}
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
          >
            <Button>Right</Button>
          </Popconfirm>
          <Popconfirm
            placement="rightBottom"
            title={text}
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
          >
            <Button>RB</Button>
          </Popconfirm>
        </div>
        <div style={{ marginLeft: 70, clear: 'both', whiteSpace: 'nowrap' }}>
          <Popconfirm
            placement="bottomLeft"
            title={text}
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
          >
            <Button>BL</Button>
          </Popconfirm>
          <Popconfirm
            placement="bottom"
            title={text}
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
          >
            <Button>Bottom</Button>
          </Popconfirm>
          <Popconfirm
            placement="bottomRight"
            title={text}
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
          >
            <Button>BR</Button>
          </Popconfirm>
        </div>
      </Space>
    );
  };

  renderResult = () => {
    return (
      <Space direction={'vertical'}>
        <Result
          status="success"
          title="Successfully Purchased Cloud Server ECS!"
          subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
          extra={[
            <Button type="primary" key="console">
              Go Console
            </Button>,
            <Button key="buy">Buy Again</Button>,
          ]}
        />
        <Result
          title="Your operation has been executed"
          extra={
            <Button type="primary" key="console">
              Go Console
            </Button>
          }
        />
        <Result
          status="warning"
          title="There are some problems with your operation."
          extra={
            <Button type="primary" key="console">
              Go Console
            </Button>
          }
        />
        <Result
          status="error"
          title="Submission Failed"
          subTitle="Please check and modify the following information before resubmitting."
          extra={[
            <Button type="primary" key="console">
              Go Console
            </Button>,
            <Button key="buy">Buy Again</Button>,
          ]}
        >
          <div className="desc">
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                The content you submitted has the following error:
              </Text>
            </Paragraph>
            <Paragraph>
              <CloseCircleOutlined className="site-result-demo-error-icon" />{' '}
              Your account has been frozen. <a>Thaw immediately &gt;</a>
            </Paragraph>
            <Paragraph>
              <CloseCircleOutlined className="site-result-demo-error-icon" />{' '}
              Your account is not yet eligible to apply.{' '}
              <a>Apply Unlock &gt;</a>
            </Paragraph>
          </div>
        </Result>
      </Space>
    );
  };
  render() {
    return (
    <>
      <div>
        <h2>Pagination</h2>
        {this.renderPagination()}
      </div>
      <div>
        <h2>Page Header</h2>
        {this.renderPageHeader()}
      </div>

      <h1>Data Entry</h1>
      <div>
        <h2>Input</h2>
        {this.renderInput()}
      </div>
      <div>
        <h2>Transfer</h2>
        {this.renderTransfer()}
      </div>
      <div>
        <h2>Input Number</h2>
        {this.renderInputNumber()}
      </div>
      <h1>Data Display</h1>
      <div>
        <h2>Badge</h2>
        {this.renderBadge()}
      </div>
      <div>
        <h2>Collapse</h2>
        {this.renderCollapse()}
      </div>
      <div>
        <h2>Card</h2>
        {this.renderCard()}
      </div>
      <div>
        <h2>Calendar</h2>
        {this.renderCalendar()}
      </div>
      <div>
        <h2>Popover</h2>
        {this.renderPopover()}
      </div>
      <div>
        <h2>Tooltip</h2>
        {this.renderTooltip()}
      </div>
      <div>
        <h2>Timeline</h2>
        {this.renderTimeline()}
      </div>
      <div>
        <h2>Tag</h2>
        {this.renderTags()}
      </div>
      <h1>Feedback</h1>
      <div>
        <h2>Alert</h2>
        {this.renderAlert()}
      </div>
      <div>
        <h2>Drawer</h2>
        {this.renderDrawer()}
      </div>
      <div>
        <h2>Modal</h2>
        {this.renderModal()}
      </div>
      <div>
        <h2>Message</h2>
        {this.renderMessage()}
      </div>
      <div>
        <h2>Notifications</h2>
        {this.renderNotifications()}
      </div>
      <div>
        <h2>Progress</h2>
        {this.renderProgress()}
      </div>
      <div>
        <h2>Popconfirm</h2>
        {this.renderPopconfirm()}
      </div>
      <div>
        <h2>Result</h2>
        {this.renderResult()}
      </div>
    </>
  );
  }
}

export default RestComponent;
