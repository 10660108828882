import React from 'react';

import { TextArea } from 'components/FormFields';
import Space from 'components/Space';

const TextAreaComponent = () => {
  return (
    <Space direction="vertical">
      <Space
        direction={'horizontal'}
        style={{
          width: '100%',
          marginBottom: '50px',
          alignItems: 'flex-end',
        }}
      >
        <TextArea placeholder={'Text Area Placeholder...'} />

        <TextArea
          placeholder={'Text Area Placeholder...'}
          caption={'Textarea caption message.'}
        />
        <TextArea
          placeholder={'Text Area Placeholder...'}
          caption={'Textarea caption message.'}
          label="Textarea Label"
        />
        <TextArea
          placeholder={'Text Area Placeholder...'}
          label="Textarea Label"
        />
      </Space>
      <Space
        direction={'horizontal'}
        style={{ width: '100%', alignItems: 'flex-end' }}
      >
        <TextArea placeholder={'Text Area Placeholder...'} disabled />

        <TextArea
          placeholder={'Text Area Placeholder...'}
          caption={'Textarea caption message.'}
          disabled
        />
        <TextArea
          placeholder={'Text Area Placeholder...'}
          caption={'Textarea caption message.'}
          disabled
          label="Textarea Label"
        />
        <TextArea
          placeholder={'Text Area Placeholder...'}
          disabled
          label="Textarea Label"
        />
      </Space>
    </Space>
  );
};

export default TextAreaComponent;
