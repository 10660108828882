import {
  apiUpdateFuelCardDetail,
  apiGetAccounts,
  apiGetAccount,
  apiCreatePaymentAccount,
  apiUpdatePaymentAccount,
  apiGetFuelCards,
  apiCreateFuelCard,
  apiGetFuelCardDetail,
  apiDeleteFuelCard,
} from 'api';

import { paymentSourceTypes } from '../types';

// SYNC_UPDATE_FUEL_CARD
export const syncUpdateFuelCard = (cardId, cardData) => dispatch => {
  dispatch({
    type: paymentSourceTypes.SYNC_UPDATE_FUEL_CARD,
    payload: { cardId, cardData },
  });
};

// GET_ACCOUNTS
export const getAccounts = (clientId, filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: paymentSourceTypes.GET_CHECKING_ACCOUNTS,
    payload: {},
    asyncCall: () => {
      return apiGetAccounts(clientId, filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_ACCOUNT
export const getAccount = (clientId, accountId, cb) => {
  return {
    isAsyncCall: true,
    baseType: paymentSourceTypes.GET_CHECKING_ACCOUNT,
    payload: { accountId },
    asyncCall: () => {
      return apiGetAccount(clientId, accountId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_FUEL_CARDS
export const getFuelCards = (clientId, filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: paymentSourceTypes.GET_FUEL_CARDS,
    payload: {},
    asyncCall: () => {
      return apiGetFuelCards(clientId, filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_FUEL_CARD
export const getFuelCard = (clientId, cardId, filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: paymentSourceTypes.GET_FUEL_CARD,
    payload: { cardId },
    asyncCall: () => {
      return apiGetFuelCardDetail(clientId, cardId, filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CREATE_ACCOUNT
export const createAccount = (clientId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: paymentSourceTypes.CREATE_CHECKING_ACCOUNT,
    payload: {},
    asyncCall: () => {
      return apiCreatePaymentAccount(clientId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_DRIVER_ACCOUNT
export const updateAccount = (clientId, accountId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: paymentSourceTypes.UPDATE_CHECKING_ACCOUNT,
    payload: {},
    asyncCall: () => {
      return apiUpdatePaymentAccount(clientId, accountId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CREATE_FUEL_CARD
export const createFuelCard = (clientId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: paymentSourceTypes.CREATE_FUEL_CARD,
    payload: {},
    asyncCall: () => {
      return apiCreateFuelCard(clientId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_FUEL_CARD
export const updateFuelCard = (clientId, cardId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: paymentSourceTypes.UPDATE_FUEL_CARD,
    payload: {},
    asyncCall: () => {
      return apiUpdateFuelCardDetail(clientId, cardId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// DELETE_FUEL_CARD
export const deleteFuelCard = (clientId, cardId, cb) => {
  return {
    isAsyncCall: true,
    baseType: paymentSourceTypes.DELETE_FUEL_CARD,
    payload: {},
    asyncCall: () => {
      return apiDeleteFuelCard(clientId, cardId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
