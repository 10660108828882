import React, { useState } from 'react';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import Tabs from 'components/Tabs';
import toast from 'helper/toast';
import { getErrorMessageFromObj } from 'utils/object';
import ROUTES from 'routes';
import AllClientsTab from './AllClientsTab';
import ArchiveClientsTab from './ArchiveClientsTab';
import Header from './Header';
import Content from './Content';
import MainButton from './MainButton';
import LoadingSpin from './LoadingSpin';
import { getFilters, getOtherFilters } from './utils';
import { useActions, useIndexData } from './selectorData';
import classes from './ClientList.module.scss';

const ClientList = () => {
  const history = useHistory();
  const { getAllClients, createClient, getArchivedClients } = useActions();
  const { createClientLoading } = useIndexData();
  const [activeTab, setActiveTab] = useState(1);
  const [keyword, setKeyword] = useState('');
  const handleGetAllClients = (page, sort, keyword, otherFilter) => {
    getAllClients(
      getFilters(page, sort, keyword, getOtherFilters(otherFilter))
    );
  };
  const handleGetArchivedClients = (page, sort, keyword, otherFilter) => {
    getArchivedClients(
      getFilters(page, sort, keyword, getOtherFilters(otherFilter))
    );
  };
  const tabs = [
    {
      title: <span>Active Clients</span>,
      content: (
        <AllClientsTab
          handleGetAllClients={handleGetAllClients}
          keyword={keyword}
          setKeyword={setKeyword}
          activeTab={activeTab}
        />
      ),
    },
    {
      title: <span>Archived Clients</span>,
      content: (
        <ArchiveClientsTab
          handleGetArchivedClients={handleGetArchivedClients}
          keyword={keyword}
          setKeyword={setKeyword}
          activeTab={activeTab}
        />
      ),
    },
  ];
  return (
    <LoadingSpin>
      <div className={classes.wrapper}>
        <Header />
        <Content>
          <Tabs
            tabs={tabs}
            activeKey={activeTab.toString()}
            onChange={activeKey => {
              const iActiveKey = parseInt(activeKey, 10);
              setActiveTab(iActiveKey);
            }}
            spaceTop
          />
          <MainButton
            onClick={() => {
              createClient(
                {
                  legal_name: 'Input Client Name',
                },
                (res, err) => {
                  if (res) {
                    const newClientId = get(res, 'data.id');
                    if (newClientId) {
                      history.push(ROUTES.CLIENT_DETAIL({ id: newClientId }));
                    }
                  } else {
                    toast.error(getErrorMessageFromObj(err)[0]);
                  }
                }
              );
            }}
            loading={createClientLoading}
          />
        </Content>
      </div>
    </LoadingSpin>
  );
};

export default ClientList;
