import { useReducerLoading, useReducerData } from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// CLIENT_ORDER_FIELD_VALUES
export const useSelectSaveClientOrderFieldValues = () => {
  return useReducerData(
    REDUCER_NAMES.CLIENT_ORDER_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_ORDER_DETAIL.CLIENT_ORDER_FIELD_VALUES
  );
};
export const useSelectSaveClientOrderFieldValuesLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_ORDER_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_ORDER_DETAIL.CLIENT_ORDER_FIELD_VALUES
  );
};
// CLIENT_ORDER_CHECKLIST
export const useSelectClientOrderChecklist = () => {
  return useReducerData(
    REDUCER_NAMES.CLIENT_ORDER_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_ORDER_DETAIL.CLIENT_ORDER_CHECKLIST
  );
};
export const useSelectClientOrderChecklistLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_ORDER_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_ORDER_DETAIL.CLIENT_ORDER_CHECKLIST
  );
};
// SAVE_CLIENT_ORDER_CHECKLIST
export const useSelectSaveClientOrderChecklistLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_ORDER_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_ORDER_DETAIL.SAVE_CLIENT_ORDER_CHECKLIST
  );
};
// MARK_CLIENT_ORDER_CHECKLIST
export const useSelectMarkClientOrderChecklistLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_ORDER_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_ORDER_DETAIL.MARK_CLIENT_ORDER_CHECKLIST
  );
};
// DELETE_CLIENT_ORDER_CHECKLIST
export const useSelectDeleteClientOrderChecklistLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_ORDER_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_ORDER_DETAIL.DELETE_CLIENT_ORDER_CHECKLIST
  );
};
