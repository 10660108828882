import React from 'react';
import cn from 'classnames';
import { PlusCircleFilled } from '@ant-design/icons';
import Button, { ButtonColors } from 'components/Button';
import classes from './ClientList.module.scss';

const MainButton = ({
  onClick,
  loading,
  buttonText = 'Create New Client',
  className,
}) => {
  return (
    <>
      <div className={cn(classes.tabHeaderButton, className)}>
        <Button
          type="primary"
          size="large"
          className={classes.addCButton}
          onClick={onClick}
          color={ButtonColors.BLUE}
          loading={loading}
          leftIcon={<PlusCircleFilled />}
          largeIcon
        >
          {buttonText}
        </Button>
      </div>
    </>
  );
};

export default MainButton;
