import React from 'react';
import cn from 'classnames';
import NotFound from '../NotFound';
import NotificationItem from '../NotificationItem';
import BottomMark from '../BottomMark';
import classes from './TabContent.module.scss';

const TabContent = ({
  className,
  notFoundMessage,
  markText,
  onMark,
  onStartOrder,
  notifications,
  onItemClick,
  hideStartOrder,
}) => {
  const isNotFound = (notifications || []).length === 0;
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isNotFound]: isNotFound,
        },
        className
      )}
    >
      <div>
        {isNotFound ? (
          <NotFound message={notFoundMessage} className={classes.notFound} />
        ) : (
          <>
            {(notifications || []).map(notification => {
              return (
                <NotificationItem
                  onStartOrder={onStartOrder && onStartOrder(notification)}
                  key={notification.id}
                  data={notification}
                  onItemClick={onItemClick && onItemClick(notification)}
                  hideStartOrder={hideStartOrder}
                  readNotification={hideStartOrder}
                />
              );
            })}
            <BottomMark
              className={classes.bottomMark}
              text={markText}
              onClick={onMark}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TabContent;
