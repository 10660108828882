const initialTeammateDetailState = {
  // GET
  detail: {
    loading: false,
    data: {},
    error: '',
  },
  // POST
  update: {
    loading: false,
    data: {},
    error: '',
  },
  doAction: {
    loading: false,
    data: {},
    error: '',
  },
};

export { initialTeammateDetailState };
