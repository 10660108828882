import {
  apiGetUnderwritingTabList,
  apiCreateUpdateUnderwritingTab,
  apiUpdateUnderwritingRecordStatus,
} from 'api';
import { underwritingTypes } from '../types';

// SYNC_UPDATE_UNDERWRITING_RECORD
export const syncUpdateUnderwritingRecord = (
  recordId,
  recordData,
  tabKey
) => dispatch => {
  dispatch({
    type: underwritingTypes.SYNC_UPDATE_UNDERWRITING_RECORD,
    payload: { recordId, recordData, tabKey },
  });
};
// SYNC_SET_UNDERWRITING_PRIMARY_CONTACT
export const syncSetUnderwritingPrimaryContact = (
  recordId,
  tabName
) => dispatch => {
  dispatch({
    type: underwritingTypes.SYNC_SET_UNDERWRITING_PRIMARY_CONTACT,
    payload: { recordId, tabKey: `${tabName}s` },
  });
};

// GET_BACKGROUNDS
export const getBackgrounds = ({ id }, filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: underwritingTypes.GET_BACKGROUNDS,
    payload: {},
    asyncCall: () => {
      return apiGetUnderwritingTabList(id, filters, 'background');
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_UCCS
export const getUccs = ({ id }, filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: underwritingTypes.GET_UCCS,
    payload: {},
    asyncCall: () => {
      return apiGetUnderwritingTabList(id, filters, 'ucc');
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_BUSINESS_ASSOCIATIONS
export const getBusinessAssociations = ({ id }, filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: underwritingTypes.GET_BUSINESS_ASSOCIATIONS,
    payload: {},
    asyncCall: () => {
      return apiGetUnderwritingTabList(id, filters, 'business');
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_CREDITS
export const getCredits = ({ id }, filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: underwritingTypes.GET_CREDITS,
    payload: {},
    asyncCall: () => {
      return apiGetUnderwritingTabList(id, filters, 'credit');
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_ALERTS
export const getAlerts = ({ id }, filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: underwritingTypes.GET_ALERTS,
    payload: {},
    asyncCall: () => {
      return apiGetUnderwritingTabList(id, filters, 'ifa');
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_LOCATIONS
export const getLocations = ({ id }, filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: underwritingTypes.GET_LOCATIONS,
    payload: {},
    asyncCall: () => {
      return apiGetUnderwritingTabList(id, filters, 'locations');
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};

// CREATE_UNDERWRITING_RECORD
export const createUnderwritingRecord = (clientId, data, cb, tabKey) => {
  return {
    isAsyncCall: true,
    baseType: underwritingTypes.CREATE_UNDERWRITING_RECORD,
    payload: {},
    asyncCall: () => {
      return apiCreateUpdateUnderwritingTab(clientId, data, tabKey);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_UNDERWRITING_RECORD
export const updateUnderwritingRecord = (
  clientId,
  data,
  cb,
  recordId,
  tabKey
) => {
  return {
    isAsyncCall: true,
    baseType: underwritingTypes.UPDATE_UNDERWRITING_RECORD,
    payload: { recordId },
    asyncCall: () => {
      return apiCreateUpdateUnderwritingTab(clientId, data, tabKey);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(
        syncUpdateUnderwritingRecord(
          recordId,
          response?.data || {},
          `${tabKey}s`
        )
      );
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_UNDERWRITING_RECORD_STATUS
export const updateUnderwritingRecordStatus = (
  clientId,
  data,
  cb,
  recordId,
  tabKey
) => {
  return {
    isAsyncCall: true,
    baseType: underwritingTypes.UPDATE_UNDERWRITING_RECORD_STATUS,
    payload: { recordId },
    asyncCall: () => {
      return apiUpdateUnderwritingRecordStatus(
        clientId,
        data,
        recordId,
        tabKey
      );
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
