import { chatInformationTypes } from '../types';
import { initialChatInformationState } from '../states/chatInformation';

export default (state = initialChatInformationState, action) => {
  const { attr, value } = action.payload || {};
  switch (action.type) {
    // CUSTOM
    // SYNC_SET_CHAT_INFORMATION
    case chatInformationTypes.SYNC_SET_CHAT_INFORMATION: {
      return {
        ...state,
        [attr]: value,
      };
    }
    // SYNC_RESET_CHAT_INFORMATION
    case chatInformationTypes.SYNC_RESET_CHAT_INFORMATION: {
      return initialChatInformationState;
    }
    default:
      return state;
  }
};
