import React from 'react';
import cn from 'classnames';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import Timeline from 'components/Timeline';
import { useOptions } from 'helper/hooks';
import { getNewFeeds } from './utils';
import classes from './Activity.module.scss';

const Activity = ({
  className,
  name,
  activityLog,
  onAddNote,
  loading,
  isCollapsed,
  setIsCollapsed = () => {},
}) => {
  const { salesDepartmentUserOptions } = useOptions();

  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isCollapsed]: isCollapsed,
        },
        className
      )}
      onClick={() => {
        if (isCollapsed) {
          setIsCollapsed(false);
        }
      }}
    >
      <div className={classes.content}>
        <div className={classes.title}>
          <span
            className={classes.titleIcon}
            onClick={() => {
              if (!isCollapsed) {
                setIsCollapsed(true);
              }
            }}
          >
            {isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </span>
          <h3>Activity</h3>
        </div>
        <Timeline
          items={getNewFeeds({
            name,
            activityLog,
            onAddNote,
            loading,
            salesDepartmentUserOptions,
          })}
          className={classes.timeline}
        />
      </div>
    </div>
  );
};

export default Activity;
