import { useReducerData, useReducerLoading } from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// SEARCH_DOT_NUMBER
export const useSelectDotNumberLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.INTAKE_FORM,
    REDUCER_ATTRIBUTES.INTAKE_FORM.DOT_NUMBER_DETAILS
  );
};
export const useSelectDotNumber = () => {
  return useReducerData(
    REDUCER_NAMES.INTAKE_FORM,
    REDUCER_ATTRIBUTES.INTAKE_FORM.DOT_NUMBER_DETAILS,
    {}
  );
};
