import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  myTeamTypes,
} from '../types';
import get from 'lodash/get';
import { initialMyTeamState } from '../states/myTeam';

export default (state = initialMyTeamState, action) => {
  const resData = get(action, 'response.data') || {};
  const { next_page_url, current_page, data = [], total = 0 } = resData;
  const isMore = get(action, 'payload.isMore');
  const isBEMore = !!next_page_url || current_page > 1;
  switch (action.type) {
    // GET_COMPANY_TEAM_LIST
    case asyncActionTypeRequest(myTeamTypes.GET_COMPANY_TEAM_LIST): {
      return {
        ...state,
        companyTeamList: {
          ...state.companyTeamList,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(myTeamTypes.GET_COMPANY_TEAM_LIST): {
      return {
        ...state,
        companyTeamList: {
          data: isMore ? [...state.companyTeamList.data, ...data] : data,
          totalNo: total || 0,
          loading: false,
          isMore: isBEMore,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(myTeamTypes.GET_COMPANY_TEAM_LIST): {
      return {
        ...state,
        companyTeamList: {
          loading: false,
          error: action.payload,
          data: [],
          totalNo: 0,
          isMore: false,
        },
      };
    }
    // GET_ALL_TEAMS
    case asyncActionTypeRequest(myTeamTypes.GET_ALL_TEAMS): {
      return {
        ...state,
        allTeams: {
          ...state.allTeams,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(myTeamTypes.GET_ALL_TEAMS): {
      return {
        ...state,
        allTeams: {
          data: isMore ? [...state.allTeams.data, ...data] : data,
          totalNo: total || 0,
          loading: false,
          isMore: isBEMore,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(myTeamTypes.GET_ALL_TEAMS): {
      return {
        ...state,
        allTeams: {
          loading: false,
          error: action.payload,
          data: [],
          totalNo: 0,
          isMore: false,
        },
      };
    }
    // GET_IN_ACTIVE_TEAM
    case asyncActionTypeRequest(myTeamTypes.GET_IN_ACTIVE_TEAM): {
      return {
        ...state,
        inActiveTeam: {
          ...state.inActiveTeam,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(myTeamTypes.GET_IN_ACTIVE_TEAM): {
      return {
        ...state,
        inActiveTeam: {
          data: isMore ? [...state.inActiveTeam.data, ...data] : data,
          totalNo: total || 0,
          loading: false,
          isMore: isBEMore,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(myTeamTypes.GET_IN_ACTIVE_TEAM): {
      return {
        ...state,
        inActiveTeam: {
          loading: false,
          error: action.payload,
          data: [],
          totalNo: 0,
          isMore: false,
        },
      };
    }
    // GET_DEPARTMENT_TEAM
    case asyncActionTypeRequest(myTeamTypes.GET_DEPARTMENT_TEAM): {
      return {
        ...state,
        departmentTeam: {
          ...state.departmentTeam,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(myTeamTypes.GET_DEPARTMENT_TEAM): {
      return {
        ...state,
        departmentTeam: {
          data: isMore ? [...state.departmentTeam.data, ...data] : data,
          totalNo: total || 0,
          loading: false,
          isMore: isBEMore,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(myTeamTypes.GET_DEPARTMENT_TEAM): {
      return {
        ...state,
        departmentTeam: {
          loading: false,
          error: action.payload,
          data: [],
          totalNo: 0,
          isMore: false,
        },
      };
    }
    // POST
    // INVITE_TEAMMATE
    case asyncActionTypeRequest(myTeamTypes.INVITE_TEAMMATE): {
      return {
        ...state,
        inviteTeammate: {
          ...state.inviteTeammate,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(myTeamTypes.INVITE_TEAMMATE): {
      return {
        ...state,
        inviteTeammate: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(myTeamTypes.INVITE_TEAMMATE): {
      return {
        ...state,
        inviteTeammate: {
          data: {},
          loading: false,
          error: action.payload,
        },
      };
    }
    // RESEND_INVITE_TEAMMATE
    case asyncActionTypeRequest(myTeamTypes.RESEND_INVITE_TEAMMATE): {
      return {
        ...state,
        resendInviteTeammate: {
          ...state.resendInviteTeammate,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(myTeamTypes.RESEND_INVITE_TEAMMATE): {
      return {
        ...state,
        resendInviteTeammate: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(myTeamTypes.RESEND_INVITE_TEAMMATE): {
      return {
        ...state,
        resendInviteTeammate: {
          data: {},
          loading: false,
          error: action.payload,
        },
      };
    }
    // INVITE_COMPANY_TEAM_MEMBER
    case asyncActionTypeRequest(myTeamTypes.INVITE_COMPANY_TEAM_MEMBER): {
      return {
        ...state,
        inviteCompanyTeamMember: {
          ...state.inviteCompanyTeamMember,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(myTeamTypes.INVITE_COMPANY_TEAM_MEMBER): {
      return {
        ...state,
        inviteCompanyTeamMember: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(myTeamTypes.INVITE_COMPANY_TEAM_MEMBER): {
      return {
        ...state,
        inviteCompanyTeamMember: {
          data: {},
          loading: false,
          error: action.payload,
        },
      };
    }
    // UPDATE_COMPANY_TEAM_MEMBER
    case asyncActionTypeRequest(myTeamTypes.UPDATE_COMPANY_TEAM_MEMBER): {
      return {
        ...state,
        updateCompanyTeamMember: {
          ...state.updateCompanyTeamMember,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(myTeamTypes.UPDATE_COMPANY_TEAM_MEMBER): {
      return {
        ...state,
        updateCompanyTeamMember: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(myTeamTypes.UPDATE_COMPANY_TEAM_MEMBER): {
      return {
        ...state,
        updateCompanyTeamMember: {
          data: {},
          loading: false,
          error: action.payload,
        },
      };
    }
    // UPDATE_COMPANY_TEAM_MEMBER_STATUS
    case asyncActionTypeRequest(
      myTeamTypes.UPDATE_COMPANY_TEAM_MEMBER_STATUS
    ): {
      return {
        ...state,
        updateCompanyTeamMemberStatus: {
          ...state.updateCompanyTeamMemberStatus,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(
      myTeamTypes.UPDATE_COMPANY_TEAM_MEMBER_STATUS
    ): {
      return {
        ...state,
        updateCompanyTeamMemberStatus: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(
      myTeamTypes.UPDATE_COMPANY_TEAM_MEMBER_STATUS
    ): {
      return {
        ...state,
        updateCompanyTeamMemberStatus: {
          data: {},
          loading: false,
          error: action.payload,
        },
      };
    }
    default:
      return state;
  }
};
