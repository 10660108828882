import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Collapse, { Panel } from 'components/Collapse';
import Header from 'views/pages/ClientDetail/CompanyOfficial/components/Header';
import NotFound from 'views/pages/ClientDetail/CompanyOfficial/components/NotFound';
import PanelHeader from 'views/pages/ClientDetail/CompanyOfficial/PanelHeader';
import {
  isAlreadyNew,
  afterCbFn,
} from 'views/pages/ClientDetail/CompanyOfficial/utils';
import { CollapseTable } from 'views/pages/ClientDetail/components/Elements';
import {
  onKeyChangeFn,
  getEditKeys,
  editChangeFn,
  getIsKey,
} from 'views/pages/ClientDetail/utils';
import Section from './components/Section';
import { DEFAULT_SECTIONS, NEW_KEY } from './constants';
import { getCreditBR } from './utils';
import { useActions, useIndexData } from './selectorData';
import classes from './CreditReports.module.scss';

const CreditReports = ({
  className,
  confirmModal,
  openWarningModal,
  clientId,
  setIsEdit,
  setIsFormChanged,
  activeTab,
  tabIndex,
  tabName,
  onUpdated,
}) => {
  const {
    getCredits,
    createUnderwritingRecord,
    updateUnderwritingRecord,
  } = useActions();
  const { credits, updateCreditLoading } = useIndexData();
  const setIsEditParent = setIsEdit;
  const [createLoading, setCreateLoading] = useState(false);
  const [sections, setSections] = useState(DEFAULT_SECTIONS);
  const [activeKeys, setActiveKeys] = useState([]);
  const [editKeys, setEditKeys] = useState([]);
  const hasEdit = sections.length > 0 || editKeys.length > 0;
  const [editChanged, setEditChanged] = useState({});
  const notFound = sections.length === 0 && credits.length === 0;
  const handleEditChange = (...params) =>
    editChangeFn(editChanged, setEditChanged, setIsEdit, ...params);
  const handleSubmit = (id, eData) => (values, cb) => {
    const creditId = id && id !== NEW_KEY ? id : '';
    const actionFn = creditId
      ? updateUnderwritingRecord
      : createUnderwritingRecord;
    if (!creditId) {
      setCreateLoading(true);
    }
    actionFn(
      clientId,
      getCreditBR(values, creditId, eData),
      afterCbFn(
        res => {
          if (onUpdated) onUpdated();
          if (!creditId) {
            handleLoadCredit(() => {
              setCreateLoading(false);
              if (cb) cb();
            });
          } else if (cb) cb();
        },
        () => {
          if (!creditId) setCreateLoading(false);
        }
      ),
      !creditId ? tabName : creditId,
      tabName
    );
  };
  const handleStatusChange = () => () => {};
  const handleLoadCredit = cb => {
    getCredits({ id: clientId }, {}, res => {
      if (res && cb) cb();
    });
  };

  useEffect(() => {
    setIsEditParent(hasEdit);
    setIsFormChanged(hasEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections, editKeys]);
  useEffect(() => {
    if (String(activeTab) !== String(tabIndex)) {
      if (hasEdit) {
        if (sections.length > 0) {
          setSections(DEFAULT_SECTIONS);
        }
        if (editKeys.length > 0) {
          setEditKeys([]);
        }
      }
    }
    if (String(activeTab) === String(tabIndex)) {
      handleLoadCredit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div className={cn(classes.wrapper, className)}>
      <Header
        title="Credit Reports"
        onAdd={() => {
          if (!isAlreadyNew(sections)) {
            setSections([
              { label: 'Report', id: NEW_KEY, isNew: true },
              ...sections,
            ]);
            setEditKeys([...editKeys, NEW_KEY]);
            setActiveKeys([...activeKeys, NEW_KEY]);
          }
        }}
        className={classes.header}
      />
      {notFound ? (
        <NotFound />
      ) : (
        <div className={classes.content}>
          <CollapseTable hideHeader section className={classes.sectionTable}>
            <Collapse
              destroyInactivePanel
              onChange={keys =>
                onKeyChangeFn(keys, activeKeys, editKeys, setActiveKeys)
              }
              activeKey={activeKeys}
            >
              {[...sections, ...credits].map(section => {
                const { isNew, id } = section;
                const key = String(id);
                const label = section.label || 'Report';
                const isActive = getIsKey(activeKeys, key);
                const isEdit = getIsKey(editKeys, key);
                const sectionInnerProps = {
                  clientId,
                  rootKey: key,
                  isEdit,
                  isActive,
                  confirmModal,
                  setIsEdit: edit => {
                    const newEditKeys = getEditKeys(editKeys, edit, key);
                    setEditKeys(newEditKeys);
                    if (!edit) {
                      handleEditChange(key, false, newEditKeys);
                    }
                    if (isNew && !edit) {
                      setSections(DEFAULT_SECTIONS);
                    }
                  },
                  data: section,
                  onFormChange: () => {
                    handleEditChange(key, true, editKeys);
                  },
                  openWarningModal,
                  loading: isNew ? createLoading : updateCreditLoading[id],
                  statusLoading: false,
                  onSubmit: handleSubmit(id, section),
                  onStatusChange: handleStatusChange(id),
                };
                const pHeaderProps = {
                  isEdit,
                  label,
                  isActive,
                  className: cn(classes.panelHeader),
                };
                return (
                  <Panel
                    forceRender={true}
                    className={classes.panel}
                    header={<PanelHeader {...pHeaderProps} />}
                    key={key}
                    extra={null}
                  >
                    <Section {...sectionInnerProps} />
                  </Panel>
                );
              })}
            </Collapse>
          </CollapseTable>
        </div>
      )}
    </div>
  );
};

export default CreditReports;
