import React from 'react';
import cn from 'classnames';
import { Checkbox as AntdCheckbox } from 'antd';

import classes from './Checkbox.module.scss';

const CheckboxGroupWrapper = AntdCheckbox.Group;

const Checkbox = props => {
  const {
    label = 'Checkbox',
    className,
    form,
    customValidateName,
    ...other
  } = props;
  return (
    <AntdCheckbox
      {...other}
      className={cn(classes.wrapper, className)}
      onChange={e => {
        if (other.onChange) {
          other.onChange(e);
        }
        if (form) {
          form.setFields([
            {
              name: customValidateName || other.name,
              errors: null,
            },
          ]);
        }
      }}
    >
      {label}
    </AntdCheckbox>
  );
};

export { CheckboxGroupWrapper };
export default Checkbox;
