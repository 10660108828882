import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
// import { onEditRow } from 'utils/table';
import { PAGINATION } from 'helper/const';
import FilterActions from './FilterActions';
import {
  getReportColumns,
  getDisplayActiveFields,
  getTableWidth,
} from './utils';
import classes from './MyReports.module.scss';
import { useReportTabData } from './selectorData';

const ReportTab = props => {
  const {
    keyword,
    setKeyword,
    activeTab,
    currentTab,
    activeClients,
    setActiveClients,
    handleGetReports,
    handleDownloadReport,
    activeFields,
    setActiveFields,
    fields,
    data,
  } = props;
  const { downloadReportsLoading } = useReportTabData();
  const isNotFound = false;
  const displayActiveFields = getDisplayActiveFields(activeFields);
  const isAllFieldsActived =
    displayActiveFields.length === 0 ||
    (displayActiveFields.length > 0 &&
      displayActiveFields.length === fields.length);
  const [sort, setSort] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = getReportColumns(fields, displayActiveFields);
  const reports = (data?.data || []).map((record, i) => ({
    ...record,
    id: !record.id ? i + 1 : record.id,
  }));
  const handleGetReportFn = (tPage, tKeyword, tAFields, tAClients, tSort) => {
    handleGetReports({
      page: tPage,
      keyword: tKeyword,
      tFields: tAFields,
      tClients: tAClients,
      tSort,
      cb: () => {
        setSelectedRows([]);
        setSelectedRowKeys([]);
      },
    });
  };
  const filterActionProps = {
    keyword,
    setKeyword,
    onSearch: val => {
      handleGetReportFn(1, val, activeFields, activeClients, sort);
      setCurrentPage(1);
    },
    activeClients,
    setActiveClients,
    activeFields,
    setActiveFields,
    fields,
    onFieldChange: val => {
      handleGetReportFn(1, keyword, val, activeClients, sort);
      setCurrentPage(1);
    },
    onClientChange: val => {
      handleGetReportFn(1, keyword, activeFields, val, sort);
      setCurrentPage(1);
    },
    onDownload: () => {
      handleDownloadReport({
        page: currentPage,
        keyword: keyword,
        tFields: activeFields,
        tClients: activeClients,
        tSort: sort,
        tSelectedRows: selectedRows,
        cb: () => {
          setSelectedRows([]);
          setSelectedRowKeys([]);
        },
      });
    },
    downloadLoading: downloadReportsLoading,
  };
  useEffect(() => {
    if (activeTab === currentTab) {
      handleGetReportFn(1, keyword, activeFields, activeClients, sort);
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div
      className={cn(classes.mainContentTab, {
        [classes.isNotFound]: isNotFound,
      })}
    >
      {isNotFound ? (
        <></>
      ) : (
        <>
          <FilterActions {...filterActionProps} />
          <Table
            rowSelection={{
              selectedRowKeys,
              onChange: (sRowKeys, sRows) => {
                setSelectedRowKeys(sRowKeys);
                setSelectedRows(sRows);
              },
            }}
            columns={columns}
            data={reports}
            rowKey="id"
            loading={data?.loading}
            // rowIsClick
            isScrollCSS
            // onRow={record => {
            //   return {
            //     onClick: event => {
            //       onEditRow(event, () => {});
            //     },
            //   };
            // }}
            showSorterTooltip={false}
            locale={{
              emptyText: `No reports found`,
            }}
            tableWidth={
              isAllFieldsActived ? '' : getTableWidth(displayActiveFields)
            }
            onSortChange={sorter => {
              const newSort = {
                field: sorter.field,
                order: sorter.order,
              };
              setSort(newSort);
              handleGetReportFn(
                currentPage,
                keyword,
                activeFields,
                activeClients,
                newSort
              );
            }}
          />
          {data?.isMore && (
            <Pagination
              current={currentPage}
              pageSize={PAGINATION.PER_PAGE}
              total={data?.totalNo || 0}
              right
              onChange={page => {
                setCurrentPage(page);
                handleGetReportFn(
                  page,
                  keyword,
                  activeFields,
                  activeClients,
                  sort
                );
              }}
              className={classes.pagination}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ReportTab;
