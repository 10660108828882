const initialPaymentSourceState = {
  // GET
  accounts: {
    loading: false,
    data: [],
    error: {},
  },
  account: {
    loading: false,
    data: {},
    error: {},
  },
  fuelCards: {
    loading: false,
    data: [],
    error: {},
  },
  fuelCard: {
    loading: false,
    data: {},
    error: {},
  },
  // POST
  createAccount: {
    loading: false,
    data: {},
    error: '',
  },
  updateAccount: {
    loading: {},
    data: {},
    error: {},
  },
  createFuelCard: {
    loading: false,
    data: {},
    error: '',
  },
  updateFuelCard: {
    loading: {},
    data: {},
    error: {},
  },
};

export { initialPaymentSourceState };
