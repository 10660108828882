import React from 'react';
import get from 'lodash/get';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import {
  Section,
  FormSection,
  ListInfo,
} from 'views/pages/ClientDetail/components/Elements';
import CollapseSection from 'views/pages/ClientDetail/components/CollapseSection';
import { FILLING_2290_OBJ_KEYS } from '../../tabConstants/general';

const Filling2290 = ({
  form,
  data,
  years,
  handleYearSubmit,
  initialValues,
}) => {
  const tYears = years || [];
  return (
    <Section flexBox>
      {tYears.map(year => {
        const listInfoArr = FILLING_2290_OBJ_KEYS(year);
        const tFileKey = listInfoArr?.[0]?.key;
        const tFile = get(data, tFileKey);
        const tName = `2290_year_wise_${year}`;
        return (
          <CollapseSection
            onEdit={() => {
              form.setFieldsValue({
                delete_attachments: [],
                [tName]: get(initialValues, tName),
              });
            }}
            key={year}
            form={form}
            title={year}
            headerLink={
              tFile?.name
                ? {
                    url: tFile?.url || '#',
                    name: tFile.name,
                  }
                : undefined
            }
            onSubmit={(values, cb) => {
              if (handleYearSubmit) handleYearSubmit(values, cb);
            }}
            renderContent={isEdit => {
              return (
                <>
                  {isEdit ? (
                    <FormSection>
                      <InputItem
                        name={'delete_attachments'}
                        label=""
                        form={form}
                        hide
                      />
                      <InputItem
                        name={tName}
                        label={`2290 File - ${year}`}
                        form={form}
                        type="file"
                      />
                    </FormSection>
                  ) : (
                    <ListInfo objKeys={listInfoArr} data={data} noPadding />
                  )}
                </>
              );
            }}
          />
        );
      })}
    </Section>
  );
};

export default Filling2290;
