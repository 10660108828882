import { useReducerData, useReducerLoading } from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// CC_COLUMNS
export const useSelectCCColumnsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.WORK_QUEUE,
    REDUCER_ATTRIBUTES.WORK_QUEUE.CC_COLUMNS
  );
};
export const useSelectCCColumns = () => {
  return useReducerData(
    REDUCER_NAMES.WORK_QUEUE,
    REDUCER_ATTRIBUTES.WORK_QUEUE.CC_COLUMNS
  );
};
// COLUMNS
export const useSelectColumnsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.WORK_QUEUE,
    REDUCER_ATTRIBUTES.WORK_QUEUE.COLUMNS
  );
};
export const useSelectColumns = () => {
  return useReducerData(
    REDUCER_NAMES.WORK_QUEUE,
    REDUCER_ATTRIBUTES.WORK_QUEUE.COLUMNS
  );
};
// ADD_COLUMN
export const useSelectAddColumnLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.WORK_QUEUE,
    REDUCER_ATTRIBUTES.WORK_QUEUE.ADD_COLUMN
  );
};
// UPDATE_COLUMN
export const useSelectUpdateColumnLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.WORK_QUEUE,
    REDUCER_ATTRIBUTES.WORK_QUEUE.UPDATE_COLUMN
  );
};
// DELETE_COLUMN
export const useSelectDeleteColumnLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.WORK_QUEUE,
    REDUCER_ATTRIBUTES.WORK_QUEUE.DELETE_COLUMN
  );
};
// MOVE_ORDER_TO_COLUMN
export const useSelectMoveOrderToColumnLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.WORK_QUEUE,
    REDUCER_ATTRIBUTES.WORK_QUEUE.MOVE_ORDER_TO_COLUMN
  );
};
// FILTER_SETTING
export const useSelectFilterSetting = () => {
  return useReducerData(
    REDUCER_NAMES.WORK_QUEUE,
    REDUCER_ATTRIBUTES.WORK_QUEUE.FILTER_SETTING,
    {}
  );
};
export const useSelectFilterSettingLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.WORK_QUEUE,
    REDUCER_ATTRIBUTES.WORK_QUEUE.FILTER_SETTING
  );
};
// STORE_FILTER_SETTING
export const useSelectStoreFiltetSettingLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.WORK_QUEUE,
    REDUCER_ATTRIBUTES.WORK_QUEUE.STORE_FILTER_SETTING
  );
};
