const REDUCER_NAMES = {
  APP: 'app',
  AUTH: 'auth',
  SHARED: 'shared',
  ORDER_TYPE: 'orderType',
  MY_TEAM: 'myTeam',
  TEAMMATE_DETAIL: 'teammateDetail',
  CHAT: 'chat',
  CHAT_INFORMATION: 'chatInformation',
  CONVERSATION_DETAIL: 'conversationDetail',
  CLIENT: 'client',
  CLIENT_DETAIL: 'clientDetail',
  FORMS_MSA: 'formsMSA',
  TRUCK: 'truck',
  TRAILER: 'trailer',
  DRIVER: 'driver',
  LEASOR: 'leasor',
  CUSTOM_FIELD: 'customField',
  PAYMENT_SOURCE: 'paymentSource',
  UNDERWRITING: 'underwriting',
  INTAKE_FORM: 'intakeForm',
  ORDER: 'order',
  CLIENT_ORDER_DETAIL: 'clientOrderDetail',
  WORK_QUEUE: 'workQueue',
  REMINDER: 'reminder',
  CHECKLIST: 'checklist',
  NOTIFICATION: 'notification',
};
const REDUCER_ATTRIBUTES = {
  APP: {},
  AUTH: {
    CURRENT_USER: 'currentUser',
    LOGIN: 'login',
    CREATE_USER: 'createUser',
    FORGOT_PASSWORD: 'forgotPassword',
    VALIDATE_TOKEN: 'validateToken',
    RESET_PASSWORD: 'resetPassword',
    CONFIRM_REGISTER: 'confirmRegister',
    UPDATE_PROFILE: 'updateProfile',
    CHANGE_PASSWORD: 'changePassword',
    VALIDATE_INVITE_TOKEN: 'validateInviteToken',
    VALIDATE_CLIENT_TOKEN: 'validateClientToken',
    CREATE_ACCOUNT: 'createAccount',
    CREATE_CLIENT_ACCOUNT: 'createClientAccount',
    VALIDATE_PASSWORD: 'validatePassword',
  },
  ORDER_TYPE: {
    ALL_ORDER_TYPE_LIST: 'orderTypes',
    ALL_ORDER_TYPES: 'allOrderTypes',
    ORDER_TYPE_DETAIL: 'orderTypeDetail',
    ORDER_TYPE_LOGS: 'orderTypeLogs',
    CREATE_ORDER_TYPE: 'createOrderType',
    UPDATE_ORDER_TYPE: 'updateOrderType',
    DELETE_ORDER_TYPE: 'deleteOrderType',
    ADD_ORDER_TYPE_NOTE: 'addOrderTypeNote',
    UPDATE_ORDER_TYPE_DOCUMENT: 'updateOrderTypeDocument',
  },
  ORDER: {
    ORDER_REQUESTER_LIST: 'orderRequesters',
    ORDER_DETAIL_FIELDS: 'orderDetailFields',
    ORDER_LIST: 'orderList',
    CLIENT_ORDER_DETAIL: 'clientOrderDetail',
    CLIENT_ORDER: 'clientOrder',
    DELETE_ORDER: 'deleteOrder',
    UPDATE_CLIENT_ORDER_STATUS: 'updateClientOrderStatus',
  },
  MY_TEAM: {
    COMPANY_TEAM_LIST: 'companyTeamList',
    ALL_TEAMS: 'allTeams',
    IN_ACTIVE_TEAM: 'inActiveTeam',
    DEPARTMENT_TEAM: 'departmentTeam',
    INVITE_TEAMMATE: 'inviteTeammate',
    RESEND_INVITE_TEAMMATE: 'resendInviteTeammate',
    INVITE_COMPANY_TEAM_MEMBER: 'inviteCompanyTeamMember',
    UPDATE_COMPANY_TEAM_MEMBER: 'updateCompanyTeamMember',
    UPDATE_COMPANY_TEAM_MEMBER_STATUS: 'updateCompanyTeamMemberStatus',
  },
  TEAMMATE_DETAIL: {
    DETAIL: 'detail',
    UPDATE: 'update',
    DO_ACTION: 'doAction',
  },
  CHAT: {
    INTERNAL_COUNT: 'internalCount',
    CLIENT_COUNT: 'clientCount',
    COMPANIES: 'companies',
    INTERNAL_CONVERSATIONS: 'internalConversations',
    CLIENT_CONVERSATIONS: 'clientConversations',
    CHAT_DEPARTMENTS: 'chatDepartments',
    CHAT_DEPARTMENTS_LIST: 'chatDepartmentsList',
    MESSAGES: 'messages',
    ATTACHMENT: 'attachment',
    SEARCH_INTERNAL_MEMBERS: 'searchInternalMembers',
    SEARCH_CLIENT_MEMBERS: 'searchClientMembers',
    CHAT_MEMBERS: 'chatMembers',
    SEND_MESSAGE: 'sendMessage',
    MARK_READ: 'markRead',
    UPDATE_CHAT_SETTINGS: 'updateChatSettings',
    ADD_MEMBERS: 'addMembers',
    REMOVE_MEMBERS: 'removeMembers',
    CHECK_CONVERSATION: 'checkConversation',
    CREATE_CHAT_GROUP: 'createChatGroup',
  },
  CHAT_INFORMATION: {
    FILTER_INTERNAL_IDS: 'filterInternalIds',
    FILTER_CLIENT_IDS: 'filterClientIds',
    SORT_INTERNAL: 'sortInternal',
    SORT_CLIENT: 'sortClient',
  },
  CONVERSATION_DETAIL: {
    CONVERSATION_DETAIL: 'conversationDetail',
    ACTIVE_CONVERSATION: 'activeConversation',
    RESET_ACTIVE_CONVERSATION: 'resetActiveConversation',
    MARK_CHAT_UNREAD: 'markChatUnread',
  },
  CLIENT: {
    ALL_CLIENTS: 'allClients',
    ARCHIVED_CLIENTS: 'archivedClients',
    CREATE_CLIENT: 'createClient',
  },
  CLIENT_DETAIL: {
    CLIENT_DETAIL: 'clientDetail',
    CLIENT_DETAIL_LOG: 'clientDetailLog',
    COMPANY_OFFICIALS: 'companyOfficials',
    UPDATE_SALES_INFO: 'updateSalesInfo',
    UPDATE_COMPANY: 'updateCompany',
    UPDATE_FILLING: 'updateFilling',
    MANAGE_LOGO: 'manageLogo',
    ADD_LOG: 'addLog',
    CREATE_COMPANY_OFFICIAL: 'createCompanyOfficial',
    UPDATE_COMPANY_OFFICIAL: 'updateCompanyOfficial',
    UPDATE_COMPANY_OFFICIAL_STATUS: 'updateCompanyOfficialStatus',
    UPDATE_INSURANCE: 'updateInsurance',
    SEARCH_SAFER: 'searchSafer',
    DOT_SEARCH_SAFER: 'dotSearchSafer',
    SAVE_DOT: 'saveDOT',
    DOT_CLASSIFICATIONS: 'DOTClassifications',
  },
  FORMS_MSA: {
    FORMS_MSAS: 'formsMSAs',
    UPDATE_FORMS_MSA: 'updateFormsMSA',
    UPDATE_FORMS_MSA_STATUS: 'updateFormsMSAStatus',
  },
  TRUCK: {
    TRUCKS: 'trucks',
    CREATE_TRUCK: 'createTruck',
    UPDATE_TRUCK: 'updateTruck',
    UPDATE_TRUCK_STATUS: 'updateTruckStatus',
  },
  TRAILER: {
    TRAILERS: 'trailers',
    CREATE_TRAILER: 'createTrailer',
    UPDATE_TRAILER: 'updateTrailer',
    UPDATE_TRAILER_STATUS: 'updateTrailerStatus',
  },
  DRIVER: {
    DRIVERS: 'drivers',
    DRIVER_ACCOUNT: 'driverAccount',
    DRIVER_FUEL_CARD: 'driverFuelCard',
    CREATE_DRIVER: 'createDriver',
    UPDATE_DRIVER: 'updateDriver',
    UPDATE_DRIVER_STATUS: 'updateDriverStatus',
    CREATE_DRIVER_ACCOUNT: 'createDriverAccount',
    UPDATE_DRIVER_ACCOUNT: 'updateDriverAccount',
    CREATE_DRIVER_FUEL_CARD: 'createDriverFuelCard',
    UPDATE_DRIVER_FUEL_CARD: 'updateDriverFuelCard',
  },
  LEASOR: {
    LEASORS: 'leasors',
    LEASOR_ACCOUNT: 'leasorAccount',
    LEASOR_FUEL_CARD: 'leasorFuelCard',
    CREATE_LEASOR: 'createLeasor',
    UPDATE_LEASOR: 'updateLeasor',
    UPDATE_LEASOR_STATUS: 'updateLeasorStatus',
    CREATE_LEASOR_ACCOUNT: 'createLeasorAccount',
    UPDATE_LEASOR_ACCOUNT: 'updateLeasorAccount',
    CREATE_LEASOR_FUEL_CARD: 'createLeasorFuelCard',
    UPDATE_LEASOR_FUEL_CARD: 'updateLeasorFuelCard',
  },
  CUSTOM_FIELD: {
    CREATE_CUSTOM_FIELD: 'createCustomField',
    UPDATE_CUSTOM_FIELD: 'updateCustomField',
    DELETE_CUSTOM_FIELD: 'deleteCustomField',
  },
  SHARED: {
    STATES: 'states',
    FIELD_CATEGORIES: 'fieldCategories',
    POSITIONS: 'positions',
    DEPARTMENTS: 'departments',
    DEPARTMENTS_LIST: 'departmentsList',
    TRAILERS_LIST: 'trailersList',
    DRIVERS_LIST: 'driversList',
    LEASORS_LIST: 'leasorsList',
    ROLES: 'roles',
    UPLOAD_FILE: 'uploadFile',
    SYSTEM_CODES: 'systemCodes',
    SALES_DEPARTMENT_USERS: 'salesDepartmentUsers',
    VIN_DECODER: 'vinDecoder',
    BANK_NAME: 'bankName',
    EMPLOYEE_LIST: 'employeeList',
    CLIENT_LIST: 'clientList',
    STATUS_LIST: 'statusList',
    PAGINATION_CLIENT_LIST: 'paginationClientList',
    PAGINATION_ORDER_TYPE_LIST: 'paginationOrderTypeList',
    PAGINATION_EMPLOYEE_LIST: 'paginationEmployeeList',
  },
  PAYMENT_SOURCE: {
    CHECKING_ACCOUNTS: 'accounts',
    CHECKING_ACCOUNT: 'account',
    CREATE_CHECKING_ACCOUNT: 'createCheckingAccount',
    UPDATE_CHECKING_ACCOUNT: 'updateCheckingAccount',
    FUEL_CARDS: 'fuelCards',
    FUEL_CARD: 'fuelCard',
    CREATE_FUEL_CARD: 'createFuelCard',
    UPDATE_FUEL_CARD: 'updateFuelCard',
  },
  UNDERWRITING: {
    BACKGROUNDS: 'backgrounds',
    UCCS: 'uccs',
    BUSINESS_ASSOCIATIONS: 'businesss',
    CREDITS: 'credits',
    ALERTS: 'ifas',
    LOCATIONS: 'locationss',
    CREATE_UNDERWRITING_RECORD: 'createUnderwritingRecord',
    UPDATE_UNDERWRITING_RECORD: 'updateUnderwritingRecord',
    UPDATE_UNDERWRITING_RECORD_STATUS: 'updateUnderwritingRecordStatus',
  },
  INTAKE_FORM: {
    DOT_NUMBER_DETAILS: 'dotNumberDetails',
  },
  CLIENT_ORDER_DETAIL: {
    CLIENT_ORDER_FIELD_VALUES: 'clientOrderFieldValues',
    CLIENT_ORDER_CHECKLIST: 'clientOrderChecklist',
    SAVE_CLIENT_ORDER_CHECKLIST: 'saveClientOrderChecklist',
    MARK_CLIENT_ORDER_CHECKLIST: 'markClientOrderChecklist',
    DELETE_CLIENT_ORDER_CHECKLIST: 'deleteClientOrderChecklist',
  },
  WORK_QUEUE: {
    CC_COLUMNS: 'ccColumns',
    COLUMNS: 'columns',
    FILTER_SETTING: 'filterSetting',
    ADD_COLUMN: 'addColumn',
    UPDATE_COLUMN: 'updateColumn',
    DELETE_COLUMN: 'deleteColumn',
    MOVE_ORDER_TO_COLUMN: ' moveOrderToColumn',
    STORE_FILTER_SETTING: ' storeFilterSetting',
  },
  REMINDER: {
    ACTIVE_REMINDERS: 'activeReminders',
    INACTIVE_REMINDERS: 'inactiveReminders',
    ACTIVE_COMPANY_REMINDERS: 'activeCompanyReminders',
    DISMISSED_COMPANY_REMINDERS: 'dismissedCompanyReminders',
    COMPLETED_COMPANY_REMINDERS: 'completedCompanyReminders',
    REMINDER_DETAIL: 'reminderDetail',
    CREATE_REMINDER: 'createReminder',
    UPDATE_REMINDER: 'updateReminder',
    DELETE_REMINDER: 'deleteReminder',
    CHANGE_REMINDER_STATUS: 'changeReminderStatus',
    SUBSCRIBE_REMINDER_EMAIL: 'subscribeReminderEmail',
  },
  CHECKLIST: {
    CHECKLISTS: 'checklists',
    CREATE_UPDATE_CHECKLIST: 'createUpdateChecklist',
    CHECK_UNCHECK_CHECKLIST: 'checkUncheckChecklist',
    DELETE_CHECKLIST: 'deleteChecklist',
  },
  NOTIFICATION: {
    UNREAD_NOTIFICATIONS: 'unreadNotifications',
    READ_NOTIFICATIONS: 'readNotifications',
    NOTIFICATION_UNREAD_COUNT: 'notificationUnreadCount',
    READ_NOTIFICATION: 'readNotification',
    DELETE_NOTIFICATION: 'deleteNotification',
  },
};

export { REDUCER_NAMES, REDUCER_ATTRIBUTES };
