import React from 'react';
import { Slider as AntdSlider } from 'antd';

import classes from './Slider.module.scss';

const Slider = props => {
  return <AntdSlider {...props} className={classes.wrapper} />;
};

export default Slider;
