import React from 'react';
import cn from 'classnames';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  Title,
} from 'views/pages/ClientDetail/components/Elements';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { MVR_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const MVR = ({ className, isEdit, form, data }) => {
  return (
    <div className={cn(classes.mvrSection, className)}>
      <Title text="MVR - Motor Vehicle Records" />
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            <InputItem
              name={'mvr_current_year'}
              label="MVR Current Year"
              form={form}
              type="file"
            />
            <InputItem
              name={'mvr_expiration_date'}
              label="Expiration Date"
              form={form}
              type="date"
            />
            <InputItem
              name={'mvr_verification'}
              label="I have hearby reviewed the driving record of the above named driver in accordance with §391.25 and find that the above named driver meets the minimum qualifications."
              form={form}
              type="checkbox"
              fullWidth
            />
            <InputItem
              name={'mvr_first_name'}
              label="First Name"
              form={form}
              addressGroupField
            />
            <InputItem
              name={'mvr_last_name'}
              label="Last Name"
              form={form}
              addressGroupField
            />
            <InputItem
              name={'mvr_title'}
              label="Title"
              form={form}
              addressGroupField
            />
            <InputItem
              name={'mvr_date'}
              label="Date"
              form={form}
              addressGroupField
              type="date"
            />
            <InputItem
              name={'mvr_previous_years'}
              label="MVR Previous Years"
              form={form}
              type="file"
              multiple
            />
          </FormSection>
        ) : (
          <ListInfo objKeys={MVR_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default MVR;
