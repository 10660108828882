import React from 'react';
import cn from 'classnames';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
} from 'views/pages/ClientDetail/components/Elements';
import { BANK_NUMBER_FIELD } from 'helper/const';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { PROFILE_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const Profile = ({ className, isEdit, form, data }) => {
  return (
    <div className={cn(classes.profileSection, className)}>
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            {/* Use it to save delete_attachments for attachment removing */}
            <InputItem name={'delete_attachments'} label="" form={form} hide />
            <InputItem
              name={'credit_report'}
              label="Credit Report"
              form={form}
              type="file"
            />
            <InputItem
              name={'date'}
              label="Date"
              form={form}
              type="date"
              addressGroupField
            />
            <InputItem
              name={'score'}
              label="Score"
              form={form}
              addressGroupField
              type="number"
              placeholder="000"
              mask={BANK_NUMBER_FIELD.MASK}
            />
            <InputItem
              name={'no_of_accounts'}
              label="Number of Accounts"
              form={form}
              type="number"
              placeholder="000"
              mask={BANK_NUMBER_FIELD.MASK}
            />
            <InputItem
              name={'no_of_good_standing_accounts'}
              label="Number of Accounts in Good Standing"
              form={form}
              type="number"
              placeholder="000"
              mask={BANK_NUMBER_FIELD.MASK}
            />
          </FormSection>
        ) : (
          <ListInfo objKeys={PROFILE_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default Profile;
