import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  sharedTypes,
} from '../types';
import get from 'lodash/get';
import { initialSharedState } from '../states/shared';

export default (state = initialSharedState, action) => {
  switch (action.type) {
    // GET_STATES
    case asyncActionTypeRequest(sharedTypes.GET_STATES): {
      return {
        ...state,
        states: {
          ...state.states,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.GET_STATES): {
      return {
        ...state,
        states: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.GET_STATES): {
      return {
        ...state,
        states: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_FIELD_CATEGORIES
    case asyncActionTypeRequest(sharedTypes.GET_FIELD_CATEGORIES): {
      return {
        ...state,
        fieldCategories: {
          ...state.fieldCategories,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.GET_FIELD_CATEGORIES): {
      return {
        ...state,
        fieldCategories: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.GET_FIELD_CATEGORIES): {
      return {
        ...state,
        fieldCategories: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_POSITIONS
    case asyncActionTypeRequest(sharedTypes.GET_POSITIONS): {
      return {
        ...state,
        positions: {
          ...state.positions,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.GET_POSITIONS): {
      return {
        ...state,
        positions: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.GET_POSITIONS): {
      return {
        ...state,
        positions: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_DEPARTMENTS
    case asyncActionTypeRequest(sharedTypes.GET_DEPARTMENTS): {
      return {
        ...state,
        departments: {
          ...state.departments,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.GET_DEPARTMENTS): {
      return {
        ...state,
        departments: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.GET_DEPARTMENTS): {
      return {
        ...state,
        departments: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_DEPARTMENTS_LIST
    case asyncActionTypeRequest(sharedTypes.GET_DEPARTMENTS_LIST): {
      return {
        ...state,
        departmentsList: {
          ...state.departmentsList,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.GET_DEPARTMENTS_LIST): {
      return {
        ...state,
        departmentsList: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.GET_DEPARTMENTS_LIST): {
      return {
        ...state,
        departmentsList: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_SYSTEM_CODES
    case asyncActionTypeRequest(sharedTypes.GET_SYSTEM_CODES): {
      return {
        ...state,
        systemCodes: {
          ...state.systemCodes,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.GET_SYSTEM_CODES): {
      return {
        ...state,
        systemCodes: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.GET_SYSTEM_CODES): {
      return {
        ...state,
        systemCodes: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // GET_SALES_DEPARTMENT_USERS
    case asyncActionTypeRequest(sharedTypes.GET_SALES_DEPARTMENT_USERS): {
      return {
        ...state,
        salesDepartmentUsers: {
          ...state.salesDepartmentUsers,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.GET_SALES_DEPARTMENT_USERS): {
      return {
        ...state,
        salesDepartmentUsers: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.GET_SALES_DEPARTMENT_USERS): {
      return {
        ...state,
        salesDepartmentUsers: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_TRAILERS_LIST
    case asyncActionTypeRequest(sharedTypes.GET_TRAILERS_LIST): {
      return {
        ...state,
        trailersList: {
          ...state.trailersList,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.GET_TRAILERS_LIST): {
      return {
        ...state,
        trailersList: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.GET_TRAILERS_LIST): {
      return {
        ...state,
        trailersList: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_DRIVERS_LIST
    case asyncActionTypeRequest(sharedTypes.GET_DRIVERS_LIST): {
      return {
        ...state,
        driversList: {
          ...state.driversList,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.GET_DRIVERS_LIST): {
      return {
        ...state,
        driversList: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.GET_DRIVERS_LIST): {
      return {
        ...state,
        driversList: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_LEASORS_LIST
    case asyncActionTypeRequest(sharedTypes.GET_LEASORS_LIST): {
      return {
        ...state,
        leasorsList: {
          ...state.leasorsList,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.GET_LEASORS_LIST): {
      return {
        ...state,
        leasorsList: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.GET_LEASORS_LIST): {
      return {
        ...state,
        leasorsList: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_ROLES
    case asyncActionTypeRequest(sharedTypes.GET_ROLES): {
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.GET_ROLES): {
      return {
        ...state,
        roles: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.GET_ROLES): {
      return {
        ...state,
        roles: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // UPLOAD_FILE
    case asyncActionTypeRequest(sharedTypes.UPLOAD_FILE): {
      return {
        ...state,
        uploadFile: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.UPLOAD_FILE): {
      return {
        ...state,
        uploadFile: {
          data: get(action, 'response.data'),
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.UPLOAD_FILE): {
      return {
        ...state,
        uploadFile: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // VIN_DECODER
    case asyncActionTypeRequest(sharedTypes.VIN_DECODER): {
      return {
        ...state,
        vinDecoder: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.VIN_DECODER): {
      return {
        ...state,
        vinDecoder: {
          data: get(action, 'response.data'),
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.VIN_DECODER): {
      return {
        ...state,
        vinDecoder: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // GET_BANK_NAME
    case asyncActionTypeRequest(sharedTypes.GET_BANK_NAME): {
      return {
        ...state,
        bankName: {
          data: {},
          error: {},
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.GET_BANK_NAME): {
      return {
        ...state,
        bankName: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.GET_BANK_NAME): {
      return {
        ...state,
        bankName: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // GET_EMPLOYEE_LIST
    case asyncActionTypeRequest(sharedTypes.GET_EMPLOYEE_LIST): {
      return {
        ...state,
        employeeList: {
          ...state.employeeList,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.GET_EMPLOYEE_LIST): {
      return {
        ...state,
        employeeList: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.GET_EMPLOYEE_LIST): {
      return {
        ...state,
        employeeList: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_CLIENT_LIST
    case asyncActionTypeRequest(sharedTypes.GET_CLIENT_LIST): {
      return {
        ...state,
        clientList: {
          ...state.clientList,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.GET_CLIENT_LIST): {
      return {
        ...state,
        clientList: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.GET_CLIENT_LIST): {
      return {
        ...state,
        clientList: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_STATUS_LIST
    case asyncActionTypeRequest(sharedTypes.GET_STATUS_LIST): {
      return {
        ...state,
        statusList: {
          ...state.statusList,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.GET_STATUS_LIST): {
      return {
        ...state,
        statusList: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.GET_STATUS_LIST): {
      return {
        ...state,
        statusList: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_PAGINATION_CLIENT_LIST
    case asyncActionTypeRequest(sharedTypes.GET_PAGINATION_CLIENT_LIST): {
      return {
        ...state,
        paginationClientList: {
          ...state.paginationClientList,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.GET_PAGINATION_CLIENT_LIST): {
      const resData = get(action, 'response.data') || {};
      return {
        ...state,
        paginationClientList: {
          data: action?.payload?.isMore
            ? {
                ...state.paginationClientList.data,
                ...resData,
                data: [
                  ...(state.paginationClientList.data?.data || []),
                  ...(resData.data || []),
                ],
              }
            : resData,
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.GET_PAGINATION_CLIENT_LIST): {
      return {
        ...state,
        paginationClientList: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // GET_PAGINATION_ORDER_TYPE_LIST
    case asyncActionTypeRequest(sharedTypes.GET_PAGINATION_ORDER_TYPE_LIST): {
      return {
        ...state,
        paginationOrderTypeList: {
          ...state.paginationOrderTypeList,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.GET_PAGINATION_ORDER_TYPE_LIST): {
      const resData = get(action, 'response.data') || {};
      return {
        ...state,
        paginationOrderTypeList: {
          data: action?.payload?.isMore
            ? {
                ...state.paginationOrderTypeList.data,
                ...resData,
                data: [
                  ...(state.paginationOrderTypeList.data?.data || []),
                  ...(resData.data || []),
                ],
              }
            : resData,
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.GET_PAGINATION_ORDER_TYPE_LIST): {
      return {
        ...state,
        paginationOrderTypeList: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // GET_PAGINATION_EMPLOYEE_LIST
    case asyncActionTypeRequest(sharedTypes.GET_PAGINATION_EMPLOYEE_LIST): {
      return {
        ...state,
        paginationEmployeeList: {
          ...state.paginationEmployeeList,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(sharedTypes.GET_PAGINATION_EMPLOYEE_LIST): {
      const resData = get(action, 'response.data') || {};
      return {
        ...state,
        paginationEmployeeList: {
          data: action?.payload?.isMore
            ? {
                ...state.paginationEmployeeList.data,
                ...resData,
                data: [
                  ...(state.paginationEmployeeList.data?.data || []),
                  ...(resData.data || []),
                ],
              }
            : resData,
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(sharedTypes.GET_PAGINATION_EMPLOYEE_LIST): {
      return {
        ...state,
        paginationEmployeeList: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }

    default:
      return state;
  }
};
