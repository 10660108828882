import React, { useState, useEffect } from 'react';
import find from 'lodash/find';
import {
  SearchOutlined,
  ExportOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Table from 'components/Table';
import toast from 'helper/toast';
import { getErrorMessageFromObj } from 'utils/object';
import { useModal } from 'components/Modal';
import { onEditRow } from 'utils/table';
import { confirmModalBlueConfig, getConfirmModal } from 'utils/modal';
import Pagination from 'components/Pagination';
import Button, { ButtonColors } from 'components/Button';
import { Input } from 'components/FormFields';
import { PAGINATION } from 'helper/const';
import { applySearch } from 'utils';
import ROUTES from 'routes';
import { getAllOrderTypesColumns, getOrderTypeListFilter } from './utils';
import { handleConfirm } from '../NewOrderType/utils';
import { DEFAULT_SORT } from './constants';
import { useAllTabData, useActions } from './selectorData';
import classes from './OrderTypes.module.scss';

const DepartmentTab = ({
  keyword,
  setKeyword,
  activeTab,
  currentTab,
  tabSlug,
  onSortChange,
  tabSorts,
}) => {
  const history = useHistory();
  const [modal, contextHolder] = useModal();
  const confirmModal = getConfirmModal(modal, confirmModalBlueConfig);
  const {
    orderTypesLoading,
    orderTypes,
    orderTypesTotal,
    deleteLoading,
    departments,
  } = useAllTabData();
  const { getAllOrderTypes, deleteOrderType } = useActions();
  const [checkedRows, setCheckedRows] = useState([]);
  const hasChecked = checkedRows.length > 0;
  const [currentPage, setCurrentPage] = useState(1);
  const reloadData = (val, page, sort) => {
    setCurrentPage(page);
    getAllOrderTypes(
      getOrderTypeListFilter({
        page,
        name: val,
        sort,
        department_id: find(departments, { slug: tabSlug })?.id || '',
      })
    );
  };
  const onDelete = record => {
    handleConfirm({
      confirmModal,
      onDelete: () => {
        deleteOrderType(record.id, (res, err) => {
          if (res) {
            reloadData(
              keyword,
              orderTypes.length > 1
                ? currentPage
                : currentPage > 1
                ? currentPage - 1
                : 1
            );
          } else {
            const errorMessage = getErrorMessageFromObj(err)[0];
            toast.error(errorMessage);
          }
        });
      },
    });
  };
  const onViewEdit = (record, isEdit) => {
    history.push(
      `${ROUTES.ORDER_TYPE_DETAIL({
        id: record.id,
      })}${isEdit ? '?edit=true' : ''}`
    );
  };
  const columns = getAllOrderTypesColumns({
    onDelete,
    onEdit: onViewEdit,
  });
  useEffect(() => {
    if (activeTab === currentTab) {
      const thisTabSort = tabSorts[currentTab] || DEFAULT_SORT;
      reloadData(keyword, currentPage, thisTabSort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div className={classes.allOrderTypesTab}>
      <div className={classes.tabContentHeader}>
        <Input
          size={'medium'}
          name="keyword"
          value={keyword}
          placeholder={`Search order types`}
          allowClear
          onChange={e => {
            const value = e.target.value;
            setKeyword(value);
            if (!value) {
              reloadData('', 1);
            } else {
              applySearch(value, val => {
                reloadData(val, 1);
              });
            }
          }}
          prefix={<SearchOutlined />}
          isBlue
        />
        <div className={classes.actions}>
          <Button
            htmlType="button"
            color={ButtonColors.DEFAULT}
            leftIcon={<ExportOutlined />}
            disabled={!hasChecked}
          >
            Export
          </Button>
          <Button
            htmlType="button"
            color={ButtonColors.DEFAULT}
            leftIcon={<DeleteOutlined />}
            disabled={!hasChecked}
          >
            Archive
          </Button>
        </div>
      </div>
      <Table
        rowSelection={{
          onChange: selectedRowKeys => {
            setCheckedRows(selectedRowKeys);
          },
        }}
        columns={columns}
        data={orderTypes}
        rowKey="id"
        loading={orderTypesLoading || deleteLoading}
        isScroll
        rowIsClick
        scroll={{ x: 850 }}
        onRow={record => {
          return {
            onClick: event => {
              onEditRow(event, () => {
                onViewEdit(record, false);
              });
            },
          };
        }}
        showSorterTooltip={false}
        onSortChange={sorter => {
          const newSort = {
            field: sorter.field,
            order: sorter.order,
          };
          onSortChange(currentTab, newSort);
          reloadData(keyword, currentPage, newSort);
        }}
      />
      <Pagination
        current={currentPage}
        pageSize={PAGINATION.PER_PAGE}
        total={orderTypesTotal}
        right
        onChange={page => {
          reloadData(keyword, page);
        }}
        className={classes.pagination}
      />
      {contextHolder}
    </div>
  );
};

export default DepartmentTab;
