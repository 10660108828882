import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import find from 'lodash/find';
import omit from 'lodash/omit';
import { useWorkQueuePageIds } from 'helper/hooks';
import { actionCb } from 'utils/action';
import Header from './Header';
import MainContent from './MainContent';
import LoadingSpin from './LoadingSpin';
import getPermission from './permissions';
import { DEFAULT_FILTER } from './constants';
import { getFiltersRequest, getSortParams, makeDDArea } from './utils';
import { useIndexData, useActions } from './selectorData';
import classes from './WorkQueue.module.scss';

const WorkQueue = ({ fullDepartmentName }) => {
  const {
    currentUser,
    clientMeta,
    orderTypeMeta,
    employeeMeta,
  } = useIndexData();
  const {
    getWorkQueueColumns,
    getStatusList,
    getWorkQueueFilterSetting,
    syncUpdateWorkQueueFilterSetting,
    storeWorkQueueFilterSetting,
    getPClientList,
    getPOrderTypeList,
    getPEmployeeList,
  } = useActions();
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [filters, setFilters] = useState(DEFAULT_FILTER);
  const [sorts, setSorts] = useState({});
  const queueDepartments = useWorkQueuePageIds();
  const thisDepartment =
    find(queueDepartments, { name: fullDepartmentName }) || {};
  const departmentId = thisDepartment.id;
  const currentRole = currentUser.role || '';
  const permission = getPermission(currentRole);
  const userFullName = [
    currentUser?.first_name || '',
    currentUser?.last_name || '',
  ].join(' ');
  const reloadWorkQueue = (appliedFilters, appliedSorts, silent, page = 1) => {
    setLoadMoreLoading(true);
    const filterRequest = getFiltersRequest(
      appliedFilters === null ? filters : appliedFilters
    );
    const sortRequest = getSortParams(
      appliedSorts === null ? sorts : appliedSorts
    );
    if (sortRequest.length > 0) {
      filterRequest.sort = sortRequest;
    }
    filterRequest.page = page;
    getWorkQueueColumns(
      { departmentId, silent, isMore: page > 1 },
      filterRequest,
      actionCb(
        () => {
          setLoadMoreLoading(false);
          makeDDArea();
        },
        () => {
          setLoadMoreLoading(false);
        }
      )
    );
  };
  const reloadMetadata = () => {
    getStatusList();
    if (!clientMeta?.data?.length) {
      getPClientList();
    }
    if (!orderTypeMeta?.data?.length) {
      getPOrderTypeList();
    }
    if (!employeeMeta?.data?.length) {
      getPEmployeeList();
    }
  };
  const handleSettingsStore = (tFilters, tSorts) => {
    const newFilterObj = {
      ...(tFilters === null ? filters : tFilters),
      sort: tSorts === null ? sorts : tSorts,
    };
    syncUpdateWorkQueueFilterSetting(departmentId, newFilterObj);
    storeWorkQueueFilterSetting(departmentId, newFilterObj);
  };
  useEffect(() => {
    if (departmentId) {
      reloadMetadata();
      getWorkQueueFilterSetting({ departmentId }, res => {
        const resFilter = res.data || '';
        const thisDepartmentFilterSetting = resFilter
          ? JSON.parse(resFilter)
          : null;
        const storedSorts = thisDepartmentFilterSetting?.sort || null;
        const storedFilters = thisDepartmentFilterSetting
          ? omit(thisDepartmentFilterSetting, ['sort'])
          : null;
        if (!!storedFilters) {
          setFilters(storedFilters);
        }
        if (!!storedSorts) {
          setSorts(storedSorts);
        }
        reloadWorkQueue(storedFilters, storedSorts);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  return (
    <LoadingSpin>
      <div className={cn(classes.wrapper)}>
        <Header departmentName={fullDepartmentName} />
        <MainContent
          departmentName={fullDepartmentName}
          permission={permission}
          userFullName={userFullName}
          departmentId={departmentId}
          reloadWorkQueue={reloadWorkQueue}
          filters={filters}
          setFilters={setFilters}
          sorts={sorts}
          setSorts={setSorts}
          handleSettingsStore={handleSettingsStore}
          loadMoreLoading={loadMoreLoading}
        />
      </div>
    </LoadingSpin>
  );
};

export default WorkQueue;
