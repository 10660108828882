import React from 'react';
import get from 'lodash/get';
import {
  PlusOutlined,
  MinusOutlined,
  CheckCircleFilled,
} from '@ant-design/icons';
import cn from 'classnames';
import SectionPlaceholderPng from 'assets/img/SectionPlaceholder.png';
import { SECURITY_FIELDS } from 'helper/const';
import SecurityField from 'components/SecurityField';
import { useIsInternalUser } from 'helper/hooks';
import { Checkbox } from 'components/FormFields';
import ProfilePicture from 'views/pages/MyProfile/ProfilePicture';
import StatusTag from 'views/pages/ClientDetail/CompanyOfficial/components/StatusTag';
import Tag from 'components/Tag';
import Dropdown from 'components/Dropdown';
import Tooltip, { TooltipBlock } from 'components/Tooltip';
import Button, { ButtonColors } from 'components/Button';

import { FormItem } from 'components/Form';
import {
  getValue,
  getDate,
  getFormatValue,
  getCheckbox,
  getFile,
  getMultiValue,
  getPrice,
  getBoolean,
} from './utils';
import InputItem from '../InputItem';
import classes from './Elements.module.scss';

export const getMethodMapping = {
  value: (key, data, other) => getValue(key, data, other),
  price: (key, data, other) => getPrice(key, data, other),
  date: (key, data) => getDate(key, data),
  formatValue: (key, data, other) => getFormatValue(key, data, other),
  checkbox: (key, data) => getCheckbox(key, data),
  file: (key, data, other) => getFile(key, data, other),
  multiValue: (key, data, other) => getMultiValue(key, data, other),
  boolean: (key, data, other) => getBoolean(key, data, other),
};

export const TableHeader = ({ className, columns = ['Section', 'Action'] }) => {
  return (
    <div className={cn(classes.tableHeader, className)}>
      <span>&nbsp;</span>
      {columns.map((c, i) => {
        return <span key={i}>{c}</span>;
      })}
    </div>
  );
};
export const CollapseLabel = ({
  className,
  title,
  notStart,
  big,
  subTitle,
  section,
  normal,
  hasAdditional,
  isEdit,
  firstIsSmall,
}) => {
  return (
    <span
      className={cn(
        classes.collapseLabel,
        {
          [classes.labelBig]: big,
          [classes.isSection]: section,
          [classes.isNormal]: normal,
          [classes.hasAdditional]: hasAdditional,
          [classes.isEdit]: isEdit,
          [classes.firstIsSmall]: firstIsSmall,
        },
        className
      )}
    >
      <span className={classes.clTitle}>{title}</span>
      {(notStart || subTitle) && (
        <>
          <span className={classes.separate}>|</span>
          <span
            className={cn(classes.clSubTitle, {
              [classes.clNotStart]: notStart,
            })}
          >
            {notStart ? 'Not Started' : subTitle || ''}
          </span>
        </>
      )}
    </span>
  );
};
export const CollapseAdditionalLabel = ({
  children,
  isActive,
  has3Items,
  className,
}) => {
  return (
    <span
      className={cn(
        classes.collapseAdditionalLabel,
        {
          [classes.isActive]: !!isActive,
          [classes.has3Items]: !!has3Items,
        },
        className
      )}
    >
      {children}
    </span>
  );
};
export const CollapseTable = ({
  className,
  columns,
  children,
  hideHeader,
  section,
  hasPrimary,
}) => {
  return (
    <div
      className={cn(
        classes.collapseTable,
        {
          [classes.isSection]: section,
          [classes.hasPrimary]: hasPrimary,
        },
        className
      )}
    >
      {!hideHeader && <TableHeader columns={columns} />}
      {children}
    </div>
  );
};
export const ExpandIcon = ({ className, isActive }) => {
  return (
    <span className={cn(classes.expandIcon, className)}>
      {isActive ? <MinusOutlined /> : <PlusOutlined />}
    </span>
  );
};
export const EditLink = ({ className, onClick }) => {
  return (
    <span className={cn(classes.editLink, className)} onClick={onClick}>
      Edit
    </span>
  );
};
export const Title = ({
  className,
  text,
  children,
  tooltip,
  placement,
  tooltipWidth,
  big,
  nonBorder,
  hideTitle,
}) => {
  const titleEl = tooltip ? (
    <Tooltip
      title={tooltip}
      placement={placement || 'leftTop'}
      overlayClassName={classes.tooltipOverlay}
      tooltipWidth={tooltipWidth}
    >
      <span className={classes.mainTitle}>{text}</span>
    </Tooltip>
  ) : (
    <span className={classes.mainTitle}>{text}</span>
  );
  return (
    <div
      className={cn(
        classes.title,
        {
          [classes.titleBig]: big,
          [classes.nonBorder]: nonBorder,
          [classes.hideTitle]: hideTitle,
        },
        className
      )}
    >
      {!hideTitle && titleEl}
      {children}
    </div>
  );
};
export const SectionWrapper = ({ className, children, ...other }) => {
  return (
    <div {...other} className={cn(classes.sectionWrapper, className)}>
      {children}
    </div>
  );
};
export const Section = ({ className, children, flexBox }) => {
  return (
    <div
      className={cn(
        classes.section,
        {
          [classes.flexBox]: flexBox,
        },
        className
      )}
    >
      {children}
    </div>
  );
};
export const FormSection = ({ className, children }) => {
  return <div className={cn(classes.formSection, className)}>{children}</div>;
};
export const ContactBlock = ({ className, children, ...other }) => {
  return (
    <a {...other} className={cn(classes.contactBlock, className)}>
      {children}
    </a>
  );
};
export const Color = ({ children, className, color = '' }) => {
  return (
    <span
      className={cn(
        classes.color,
        {
          [classes.isSuccess]: color === 'success',
          [classes.isError]: color === 'error',
          [classes.isBlue]: color === 'blue',
        },
        className
      )}
    >
      {children}
    </span>
  );
};
export const ListInfo = ({
  objKeys,
  data,
  className,
  noPadding,
  sType,
  sCompanyId,
  needSecure,
}) => {
  const { isInternalUser } = useIsInternalUser();

  return (
    <ul
      className={cn(
        classes.listInfo,
        {
          [classes.noPadding]: noPadding,
        },
        className
      )}
    >
      {objKeys.map((obj, i) => {
        const {
          addressGroup,
          alignLeft,
          fields,
          fullWidth,
          nameGroup,
          label,
          type,
          key,
          dependField,
          dependFn,
          capitalize,
          valueFn = val => val,
          childValueFn = val => val,
          childLabel,
          ...other
        } = obj;
        if (dependField && dependFn && dependFn(get(data, dependField))) {
          return '';
        }
        const singleField = (displayVal, displayLabel) => (
          <>
            <span>{displayLabel ? displayLabel : label}</span>
            <span
              className={cn({
                [classes.capitalize]: !!capitalize,
              })}
            >
              {displayVal}
            </span>
          </>
        );
        const listData = data[key] || [];
        return (
          <li
            key={i}
            className={cn({
              [classes.nameGroup]: nameGroup,
              [classes.addressGroup]: addressGroup,
              [classes.alignLeft]: alignLeft,
              [classes.fullWidth]: fullWidth,
              [classes.list]: type === 'list',
            })}
          >
            <>
              {addressGroup || nameGroup ? (
                <>
                  {(fields || []).map((field, j) => {
                    const fType = field.type;
                    const fKey = field.key;
                    const fValueFn = field.valueFn ? field.valueFn : val => val;
                    return (
                      <div
                        key={j}
                        className={cn({
                          [classes.fullWidth]: field.fullWidth,
                        })}
                      >
                        {SECURITY_FIELDS.includes(fKey) &&
                        isInternalUser &&
                        needSecure ? (
                          <SecurityField
                            fieldObj={{
                              label: field.label,
                              value: get(data, fKey),
                              type_id: data?.id,
                              type: sType,
                              company_id: sCompanyId,
                              key: fKey,
                              otherSettings: {
                                ...field,
                              },
                            }}
                          />
                        ) : (
                          <>
                            <span>{field.label}</span>
                            <span>
                              {getMethodMapping[fType] &&
                                fValueFn(
                                  getMethodMapping[fType](fKey, data, {
                                    ...other,
                                    ...field,
                                  })
                                )}
                            </span>
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  {SECURITY_FIELDS.includes(key) &&
                  isInternalUser &&
                  needSecure ? (
                    <SecurityField
                      fieldObj={{
                        label: label,
                        value: get(data, key),
                        type_id: data?.id,
                        type: sType,
                        company_id: sCompanyId,
                        key,
                        otherSettings: {
                          ...obj,
                        },
                      }}
                    />
                  ) : (
                    <>
                      {type === 'checkbox' ? (
                        <div className={classes.checkboxWrapper}>
                          <Checkbox
                            checked={
                              getMethodMapping[type] &&
                              getMethodMapping[type](key, data)
                            }
                            label={label}
                            onChange={e => {}}
                            disabled
                          />
                        </div>
                      ) : type === 'list' ? (
                        <>
                          {listData.length === 0 && (
                            <div>
                              <div>{singleField('None', label)}</div>
                              <div>{singleField('None', childLabel)}</div>
                            </div>
                          )}
                          {listData.map((val, i) => {
                            return (
                              <div key={i}>
                                <div>
                                  {singleField(
                                    valueFn ? valueFn(val) : val,
                                    label
                                  )}
                                </div>
                                <div>
                                  {singleField(
                                    childValueFn ? childValueFn(val) : val,
                                    childLabel
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        singleField(
                          getMethodMapping[type] &&
                            valueFn(getMethodMapping[type](key, data, other))
                        )
                      )}
                    </>
                  )}
                </>
              )}
            </>
          </li>
        );
      })}
    </ul>
  );
};
export const TextareaField = ({ className, ...other }) => {
  return <InputItem {...other} className={cn(classes.notesField, className)} />;
};
export const PrimaryTag = ({ text, isDefault, isBig, className }) => {
  return (
    <Tag
      color="blue"
      borderSameColor
      className={cn(
        classes.primaryTag,
        {
          [classes.defaultTag]: !!isDefault,
          [classes.isBig]: isBig,
        },
        className
      )}
    >
      {text || (isDefault ? 'Default' : 'Primary')}
    </Tag>
  );
};
export const MailingAddressWrapper = ({ className, ...other }) => {
  return (
    <FormItem
      {...other}
      shouldUpdate
      className={cn(classes.mailingAddressWrapper, className)}
    />
  );
};
export const ShouldUpdateWrapper = ({ className, ...other }) => {
  return (
    <FormItem
      {...other}
      shouldUpdate
      className={cn(classes.shouldUpdateWrapper, className)}
    />
  );
};
export const FieldArrayBlock = ({
  className,
  title,
  onCancel,
  children,
  status,
  statusLoading,
  onStatusChange,
  statusDropdown,
  changeStatusLoading,
  additionalTitle = null,
  hideHeader = false,
  hideTitle = false,
  unBox = false,
  canSave = false,
  onSave,
  saveLoading,
  titleClassName,
  saveBtnText,
  buttonRevert,
  hideStatus,
  cancelBtn = {},
}) => {
  return (
    <div
      className={cn(
        classes.fieldArrayBlock,
        {
          [classes.unBox]: unBox,
        },
        className
      )}
    >
      <Title text={title} hideTitle={hideTitle} className={titleClassName}>
        {!!additionalTitle && additionalTitle}
        {!hideHeader && (
          <div
            className={cn(classes.fieldArrayHeader, {
              [classes.buttonRevert]: buttonRevert,
            })}
          >
            {status ? (
              <>
                {hideStatus ? (
                  <></>
                ) : (
                  <StatusTagControl
                    status={status}
                    statusLoading={statusLoading}
                    onStatusChange={onStatusChange}
                    dropdown={statusDropdown}
                    changeStatusLoading={changeStatusLoading}
                  />
                )}
              </>
            ) : (
              <div className={classes.fieldArrayButtons}>
                <Button
                  onClick={onCancel}
                  color={ButtonColors.DEFAULT}
                  className={classes.cancelBtn}
                  type="ghost"
                >
                  {cancelBtn.text || 'Cancel'}
                </Button>
                {canSave && (
                  <Button
                    onClick={onSave}
                    color={ButtonColors.BLUE}
                    type="primary"
                    htmlType="button"
                    loading={saveLoading}
                    leftIcon={!saveBtnText && <CheckCircleFilled />}
                  >
                    {!!saveBtnText ? saveBtnText : 'Save'}
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
      </Title>
      {children}
    </div>
  );
};
export const FieldSingleBlock = ({
  className,
  title,
  children,
  additionalTitle = null,
  hideTitle = false,
  titleClassName,
}) => {
  return (
    <div
      className={cn(
        classes.fieldArrayBlock,
        classes.fieldSingleBlock,
        className
      )}
    >
      <Title text={title} hideTitle={hideTitle} className={titleClassName}>
        {!!additionalTitle && additionalTitle}
      </Title>
      {children}
    </div>
  );
};
const statusMapping = {
  active: 'inactive',
  inactive: 'active',
};
export const StatusTagControl = ({
  status,
  statusLoading,
  onStatusChange,
  dropdown,
}) => {
  const nextStatus = statusMapping[status];
  const menus = dropdown
    ? [
        {
          label: <StatusTag status={nextStatus} loading={statusLoading} />,
          props: {
            onClick: () => {
              if (!statusLoading && onStatusChange) {
                onStatusChange(nextStatus);
              }
            },
          },
        },
      ]
    : '';

  return (
    <Dropdown
      menus={menus}
      className={classes.statusDropdown}
      menuClassName={classes.statusDropdownMenu}
      trigger={['click']}
    >
      <StatusTag status={status} loading={statusLoading} />
    </Dropdown>
  );
};
export const InputSearchButton = ({
  className,
  onClick,
  form,
  disabled,
  ...other
}) => {
  return (
    <FormItem shouldUpdate className={cn(classes.inputSearchButton, className)}>
      {() => {
        const values = form.getFieldsValue();
        const isDisabled = disabled || !values.vin;
        return (
          <Button
            {...other}
            htmlType="button"
            onClick={isDisabled ? undefined : onClick}
            color={ButtonColors.BLUE}
            size="small"
            disabled={isDisabled}
          >
            Search
          </Button>
        );
      }}
    </FormItem>
  );
};
export const DisabledListField = ({
  className,
  data,
  label,
  childLabel,
  name,
  childName,
  childNameDisplay,
  revert,
  note,
}) => {
  const displayData = data || [];
  return (
    <div
      className={cn(
        classes.listField,
        {
          [classes.revert]: revert,
        },
        className
      )}
    >
      {displayData.length === 0 && (
        <div>
          <div>
            <label>{label}</label>
            <div>&nbsp;</div>
          </div>
          <div>
            <label>{childLabel}</label>
            <div>&nbsp;</div>
          </div>
        </div>
      )}
      {displayData.map((d, i) => {
        const childData = d[childName] || [];
        return (
          <div key={i}>
            <div>
              <label>{label}</label>
              <div>{d[name]}</div>
            </div>
            <div>
              <label>{childLabel}</label>
              <div>
                {childData.length === 0 && <>&nbsp;</>}
                {childData.map((c, cIndex) => {
                  return (
                    <React.Fragment key={cIndex}>
                      {c[childNameDisplay]}
                      {cIndex < childData.length - 1 ? <br /> : ''}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
      {!!note && <div className={classes.note}>{note}</div>}
    </div>
  );
};
const SectionAvatar = ({
  className,
  isEdit,
  confirmModal,
  avatarFinalSrc,
  setAvatarFinalSrc,
  finalFile,
  setFinalFile,
  handleManageLogo,
  manageLogoLoading,
  profilePic,
  onDelete = null,
}) => {
  return (
    <div className={cn(classes.sectionAvatar, className)}>
      <ProfilePicture
        isEdit={isEdit}
        confirmModal={confirmModal}
        avatarFinalSrc={avatarFinalSrc}
        setAvatarFinalSrc={setAvatarFinalSrc}
        finalFile={finalFile}
        setFinalFile={setFinalFile}
        onCropFile={handleManageLogo}
        loading={manageLogoLoading}
        profilePic={profilePic}
        defaultImg={
          <img
            src={SectionPlaceholderPng}
            alt="section-avatar"
            className={classes.defaultSectionAvatar}
          />
        }
        nonStyle
        uploadElement={
          <span className={classes.sectionAvatarUploadElement}>Update</span>
        }
        uploadClassName={classes.uploadBox}
        onDelete={onDelete}
      />
    </div>
  );
};
export const LabelWithAvatar = ({ label, className, ...other }) => {
  return (
    <span className={cn(classes.labelWithAvatar, className)}>
      <SectionAvatar {...other} />
      <TooltipBlock tooltip={label}>{label}</TooltipBlock>
    </span>
  );
};
