import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import {
  SettingOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { getStandardNameFromObj } from 'utils/text';
import { getDisplayUnreadCount } from 'utils/chat';
import Badge from 'components/Badge';
import Tooltip from 'components/Tooltip';
import { Checkbox, Input } from 'components/FormFields';
import { isMarkUnreadClick } from './utils';
import ChatAvatar from './ChatAvatar';
import DepartmentTag from './DepartmentTag';
import classes from './Elements.module.scss';

const Contact = ({
  className,
  children,
  name,
  first_name,
  last_name,
  message,
  dateStr,
  unread,
  avatar,
  isCollapse,
  department,
  departmentColor,
  onClick,
  isAddContact,
  onAddChange,
  checked,
  isDetail,
  detailObj = {},
  additionalAction,
  isClient,
  role,
  isAddDisabled,
  notEditName,
  isMe,
  hideRoleAtName,
  isMultipleAvatar,
  hideUnread,
  onMarkUnread = () => {},
}) => {
  const [inputValue, setInputValue] = useState(name);
  useEffect(() => {
    if (name !== inputValue) {
      setInputValue(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);
  useEffect(() => {
    if (detailObj.isEditing) {
      setInputValue(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailObj.isEditing]);
  const displayName = name
    ? name
    : getStandardNameFromObj({ first_name, last_name });
  const departmentTag = department ? (
    <DepartmentTag department={department} departmentColor={departmentColor} />
  ) : (
    ''
  );

  return (
    <div
      className={cn(
        classes.contact,
        {
          [classes.isCollapse]: isCollapse,
          [classes.isPointer]: !!onClick,
          [classes.isAddContact]: isAddContact,
          [classes.hasChildren]: !!children,
          [classes.hasUnread]: unread > 0 && !isAddContact,
          [classes.isDetail]: isDetail,
          [classes.isClientNameOnly]: isClient && !role && !message,
          [classes.isMultipleAvatar]: isMultipleAvatar,
        },
        className
      )}
      onClick={e => {
        if (!isMarkUnreadClick(e) && onClick) {
          onClick();
        }
      }}
    >
      {!hideUnread && (
        <div className={classes.unread}>
          {unread > 0 ? (
            <Badge count={getDisplayUnreadCount(unread)} size="small" />
          ) : (
            <Tooltip
              title="Mark as Unread"
              overlayClassName={classes.markUnreadTooltip}
              placement="topLeft"
            >
              <span
                className={cn('ant-blank-circle-icon')}
                onClick={onMarkUnread}
              ></span>
            </Tooltip>
          )}
        </div>
      )}
      {children || (
        <>
          {!detailObj.isEditing && (
            <>
              <ChatAvatar
                avatar={avatar}
                name={displayName}
                departmentColor={isMultipleAvatar ? '' : departmentColor}
                size={isMultipleAvatar ? 'medium' : 'large'}
                isMe={isMultipleAvatar || !departmentColor}
              />
              {isMultipleAvatar && (
                <ChatAvatar
                  avatar={''}
                  name={department}
                  departmentColor={departmentColor}
                  size="medium"
                />
              )}
            </>
          )}
          <div
            className={cn(classes.information, {
              [classes.hasDepartment]: !!department && !isDetail,
              [classes.noMessage]: !message,
              [classes.isEditing]: detailObj.isEditing,
            })}
          >
            <div className={classes.informationTop}>
              {detailObj.isEditing ? (
                <div className={classes.textBox}>
                  <Input
                    value={inputValue}
                    onChange={e => {
                      const value = e.target.value;
                      setInputValue(value);
                    }}
                    size="medium"
                    isBlue
                    placeholder="Contact name"
                  />
                </div>
              ) : (
                <div className={classes.nameWrapper}>
                  <span
                    className={cn(classes.name, {
                      [classes.pointer]: detailObj.isInitEdit && !notEditName,
                    })}
                    onClick={() => {
                      if (detailObj.isInitEdit && !notEditName) {
                        detailObj.onEdit();
                      }
                    }}
                  >
                    {displayName}
                    {isMe ? ' (you)' : ''}
                    {isClient && !isAddContact && !hideRoleAtName && (
                      <span className={classes.contactRole}>
                        {role ? <>, {role}</> : ''}
                      </span>
                    )}
                  </span>
                </div>
              )}

              {!!department &&
                !isAddContact &&
                !isClient &&
                !isDetail &&
                departmentTag}
              {!!additionalAction ? (
                <div
                  className={cn(
                    classes.additionalAction,
                    'ant-additional-action'
                  )}
                >
                  {additionalAction}
                </div>
              ) : (
                <>
                  {!!dateStr && !isAddContact && (
                    <div className={classes.time}>{dateStr}</div>
                  )}
                  {isAddContact && (
                    <div className={classes.addCheckbox}>
                      <Checkbox
                        checked={checked}
                        label=""
                        onChange={e => {
                          if (onAddChange && !isAddDisabled) {
                            onAddChange(e.target.checked);
                          }
                        }}
                        disabled={isAddDisabled}
                      />
                    </div>
                  )}
                </>
              )}
              {isDetail && (
                <div className={classes.settingIcon}>
                  {detailObj.isInitEdit ? (
                    <>
                      {!notEditName && (
                        <FormOutlined onClick={detailObj.onEdit} />
                      )}
                    </>
                  ) : detailObj.isEditing ? (
                    <>
                      <CloseCircleOutlined onClick={detailObj.onCancel} />
                      <CheckCircleOutlined
                        onClick={() => {
                          detailObj.onSave(inputValue);
                        }}
                      />
                    </>
                  ) : (
                    <SettingOutlined onClick={detailObj.onInitEdit} />
                  )}
                </div>
              )}
            </div>
            {!!message && !isAddContact && (
              <div className={classes.message}>{message}</div>
            )}
            {(isAddContact || (isDetail && detailObj.isIndividual)) &&
              !detailObj.isEditing && (
                <div>
                  {isClient ? (
                    <span className={classes.addClientRole}>{role}</span>
                  ) : (
                    departmentTag
                  )}
                </div>
              )}
          </div>
        </>
      )}
    </div>
  );
};

export default Contact;
