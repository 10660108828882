import pick from 'lodash/pick';
import get from 'lodash/get';
import { getMomentDate, getBEDate } from 'utils/date';
import { parseStringToNumber } from 'utils/number';
import { getOtherDataValue } from 'utils/customField';
import {
  standardRequiredFieldObj,
  emailFieldObj,
  phoneFieldObj,
  getFileFieldInitialValues,
} from 'utils/form';
import {
  NORMAL_FIELDS,
  DATE_FIELDS,
  FILE_FIELDS,
  BODY_REQUEST_NORMAL_FIELDS,
  NUMBER_FIELDS,
} from './constants';

const getFileField = field => {
  if (!field) return '';
  return field.replace('documents.', '');
};

export const getInitialValues = data => {
  const initialValues = {
    delete_attachments: [],
    delete_custom_fields: [],
    other: getOtherDataValue(data.custom_fields || []),
  };
  [...NORMAL_FIELDS].map(field => {
    initialValues[field] = data[field] || '';
    return true;
  });
  DATE_FIELDS.map(field => {
    initialValues[field] = data[field]
      ? getMomentDate(data[field], false, true)
      : '';
    return true;
  });
  const newInitialValues = {
    ...initialValues,
    ...getFileFieldInitialValues(FILE_FIELDS, data, 'documents'),
  };
  return newInitialValues;
};
export const validateObj = {
  insurance_company: [standardRequiredFieldObj],
  agent_email: [emailFieldObj],
  agent_phone: [phoneFieldObj],
  agent_fax: [phoneFieldObj],
};

export const getInsuranceBR = (values = {}) => {
  const { delete_attachments, other, delete_custom_fields } = values;
  const bodyRequest = {
    ...pick(values, BODY_REQUEST_NORMAL_FIELDS),
  };
  NUMBER_FIELDS.map(field => {
    bodyRequest[field] = parseStringToNumber(values[field]);
    return true;
  });
  DATE_FIELDS.map(field => {
    bodyRequest[field] = values[field] ? getBEDate(values[field]) : '';
    return true;
  });
  const fData = new FormData();
  Object.keys(bodyRequest).map(k => {
    fData.append(k, bodyRequest[k] || '');
    return true;
  });
  FILE_FIELDS.map(originKey => {
    const key = getFileField(originKey);
    if (values[key] && values[key].length > 0) {
      values[key].map(file => {
        if (file.originFileObj && !file.id) {
          fData.append(`${key}[]`, file.originFileObj);
        }
        return true;
      });
    } else {
      fData.append(key, '');
    }
    return true;
  });
  if (delete_attachments && delete_attachments.length > 0) {
    delete_attachments.map(id => {
      fData.append('delete_attachments[]', id);
      return true;
    });
  }
  if (other && other.length > 0) {
    other.map((field, index) => {
      fData.append(`custom_fields[${index}][field_name]`, field.field_name);
      if (field.originId) {
        fData.append(`custom_fields[${index}][id]`, field.originId);
      }
      const firstDocument = get(field, 'document[0]') || null;
      const existingFileName = get(field, 'originData.document_name', '');
      if (firstDocument && !firstDocument.url) {
        fData.append(
          `custom_fields[${index}][document]`,
          firstDocument.originFileObj
        );
      } else if (!firstDocument && !!existingFileName) {
        fData.append(`custom_fields[${index}][document_delete]`, 1);
      }
      return true;
    });
  } else {
    fData.append('custom_fields', '');
  }
  if (delete_custom_fields && delete_custom_fields.length > 0) {
    delete_custom_fields.map(id => {
      fData.append('delete_custom_fields[]', id);
      return true;
    });
  }
  return fData;
};
