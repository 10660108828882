import React from 'react';
import cn from 'classnames';
import {
  SearchOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
} from '@ant-design/icons';
import { Input } from 'components/FormFields';
import Button, { ButtonColors } from 'components/Button';
import classes from './TabContentTop.module.scss';

const TabContentTop = ({
  className,
  search,
  setSearch,
  handleExpandAll,
  handleCollapseAll,
}) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <Input
        size={'medium'}
        allowClear
        value={search}
        placeholder={`Search sections`}
        onChange={e => {
          const value = e.target.value;
          setSearch(value || '');
        }}
        prefix={<SearchOutlined />}
        isBlue
      />
      <div>
        <Button
          htmlType="button"
          color={ButtonColors.DEFAULT}
          leftIcon={<PlusSquareOutlined />}
          onClick={handleExpandAll}
          blueHover
        >
          Expand All
        </Button>
        <Button
          htmlType="button"
          color={ButtonColors.DEFAULT}
          leftIcon={<MinusSquareOutlined />}
          onClick={handleCollapseAll}
          blueHover
        >
          Collapse All
        </Button>
      </div>
    </div>
  );
};

export default TabContentTop;
