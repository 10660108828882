import pick from 'lodash/pick';
import { getFileFieldInitialValues } from 'utils/form';
import { parseStringToNumber } from 'utils/number';
import { getMomentDate, getBEDate } from 'utils/date';
import {
  NORMAL_FIELDS,
  NULL_FIELDS,
  DATE_FIELDS,
  FILE_FIELDS,
  NUMBER_FIELDS,
  BODY_REQUEST_NORMAL_FIELDS,
  BOOLEAN_FIELDS,
  SECTION_FIELDS,
  SECTION_NULL_FIELDS,
  SECTION_DATE_FIELDS,
} from './constants';

const getFileField = field => {
  if (!field) return '';
  return field.replace('documents.', '');
};
export const validateObj = () => {
  return {};
};
export const getInitialValues = data => {
  const initialValues = {
    delete_attachments: [],
  };
  [...NORMAL_FIELDS, ...NULL_FIELDS].map(field => {
    const eValue = data[field];
    initialValues[field] = eValue || (NULL_FIELDS.includes(field) ? null : '');
    return true;
  });
  DATE_FIELDS.map(field => {
    initialValues[field] = data[field]
      ? getMomentDate(data[field], false, true)
      : '';
    return true;
  });
  BOOLEAN_FIELDS.map(field => {
    initialValues[field] = data[field] ? [field] : [];
    return true;
  });
  SECTION_FIELDS.map(section => {
    const { parentName, fields } = section;
    initialValues[parentName] = (data[parentName] || []).map(d => {
      const returnO = {};
      fields.map(f => {
        returnO[f] = d[f] || (SECTION_NULL_FIELDS.includes(f) ? null : '');
        if (SECTION_DATE_FIELDS.includes(f)) {
          returnO[f] = returnO[f]
            ? getMomentDate(returnO[f], false, true)
            : null;
        }
        return true;
      });
      returnO.id = d.id;
      return returnO;
    });
    return true;
  });

  return {
    ...initialValues,
    ...getFileFieldInitialValues(FILE_FIELDS, data, 'documents'),
  };
};
export const getCreditBR = (values = {}, id, eData) => {
  const { delete_attachments, pulls } = values;
  const currentPullIds = (pulls || []).filter(p => p.id).map(p => p.id);
  const ePullIds = (eData.pulls || []).map(p => p.id);
  const deletePullIds = ePullIds.filter(id => !currentPullIds.includes(id));
  const bodyRequest = {
    ...pick(values, BODY_REQUEST_NORMAL_FIELDS),
  };
  if (id) {
    bodyRequest.credit_report_id = id;
  }
  DATE_FIELDS.map(field => {
    bodyRequest[field] = values[field] ? getBEDate(values[field]) : '';
    return true;
  });
  NUMBER_FIELDS.map(field => {
    bodyRequest[field] = parseStringToNumber(values[field]);
    return true;
  });
  BOOLEAN_FIELDS.map(field => {
    bodyRequest[field] = values[field] && values[field].length > 0 ? 1 : 0;
    return true;
  });
  const fData = new FormData();
  Object.keys(bodyRequest).map(k => {
    fData.append(
      k,
      bodyRequest[k] || bodyRequest[k] === 0 ? bodyRequest[k] : ''
    );
    return true;
  });
  if (deletePullIds.length > 0) {
    deletePullIds.map(id => {
      fData.append('delete_pull[]', id);
      return true;
    });
  }
  SECTION_FIELDS.map(section => {
    const { parentName, fields } = section;
    const parentD = values[parentName] || [];
    parentD.map((d, i) => {
      fields.map(f => {
        let fValue = d[f] || '';
        if (SECTION_DATE_FIELDS.includes(f)) {
          fValue = getBEDate(fValue);
        }
        fData.append(`${parentName}[${i}][${f}]`, fValue);
        return true;
      });
      if (d.id) {
        fData.append(`${parentName}[${i}][id]`, d.id);
      }
      return true;
    });
    return true;
  });
  FILE_FIELDS.map(originKey => {
    const key = getFileField(originKey);
    if (values[key] && values[key].length > 0) {
      values[key].map(file => {
        if (file.originFileObj && !file.id) {
          fData.append(`${key}[]`, file.originFileObj);
        }
        return true;
      });
    } else {
      fData.append(key, '');
    }
    return true;
  });
  if (delete_attachments && delete_attachments.length > 0) {
    delete_attachments.map(id => {
      fData.append('delete_documents[]', id);
      return true;
    });
  }
  return fData;
};
