import { CLIENT_MY_TEAM_DISPLAY_TABS } from 'helper/const';
import { CLIENT_TAB_TYPE } from './constants';

export const clientDriverPermission = {
  teamAction: false,
  teamInvite: false,
  tabNames: CLIENT_MY_TEAM_DISPLAY_TABS,
  tabNameFromDepartment: false,
  showAdditionalTab: false,
  tabIdFromDepartment: false,
  detailFields: ['email', 'phone_number', 'role_id'],
  detailFieldLabels: ['', '', 'Role'],
  tabTypes: CLIENT_TAB_TYPE,
  departmentDataAttrPrefix: 'companyTeamList',
  isClientColumns: true,
  inviteTeamAsClient: true,
  isDetailCompanyTeam: true,
};
