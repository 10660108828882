import React from 'react';
import { MinusOutlined, PlusOutlined, DragOutlined } from '@ant-design/icons';
import Cropper from 'react-easy-crop';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Slider from 'components/Slider';
import classes from './MyProfile.module.scss';

const sliderMin = 1;
const sliderMax = 3;
const sliderStep = 0.1;

const CropImageModal = ({
  modalProps,
  avatarSrc,
  cropObj,
  onCropChange,
  onCropComplete,
  onZoomChange,
  setCropObj,
}) => {
  return (
    <Modal {...modalProps}>
      <Cropper
        image={avatarSrc}
        crop={cropObj.crop}
        zoom={cropObj.zoom}
        aspect={cropObj.aspect}
        cropShape="round"
        showGrid={false}
        onCropChange={onCropChange}
        onCropComplete={onCropComplete}
        onZoomChange={onZoomChange}
        cropSize={{
          width: 293,
          height: 293,
        }}
      />
      <Button
        type={'primary'}
        size={'large'}
        htmlType="button"
        className={classes.dragToRepositionBtn}
        leftIcon={<DragOutlined />}
      >
        Drag to Reposition
      </Button>
      <div className={classes.sliderContainer}>
        <MinusOutlined
          onClick={() => {
            if (cropObj.zoom >= sliderMin) {
              setCropObj({
                ...cropObj,
                zoom: cropObj.zoom - sliderStep,
              });
            }
          }}
        />
        <Slider
          onChange={val => {
            setCropObj({
              ...cropObj,
              zoom: val,
            });
          }}
          step={sliderStep}
          min={sliderMin}
          max={sliderMax}
          value={cropObj.zoom}
        />
        <PlusOutlined
          onClick={() => {
            if (cropObj.zoom <= sliderMax) {
              setCropObj({
                ...cropObj,
                zoom: cropObj.zoom + sliderStep,
              });
            }
          }}
        />
      </div>
    </Modal>
  );
};
export default CropImageModal;
