import Request from './request';

export const apiGetMe = async () => {
  return Request.call({
    url: '/user/me',
    method: 'GET',
  });
};

export const apiLogin = async data => {
  return Request.call({
    url: '/user/login',
    method: 'POST',
    data,
  });
};

export const apiCreateUser = async data => {
  return Request.call({
    url: '/user/register',
    method: 'POST',
    data,
  });
};

export const apiForgotPassword = async data => {
  return Request.call({
    url: '/user/password/forgot',
    method: 'POST',
    data,
  });
};

export const apiValidateToken = async data => {
  return Request.call({
    url: '/user/password/validate-token',
    method: 'POST',
    data,
  });
};

export const apiResetPassword = async data => {
  return Request.call({
    url: '/user/password/reset',
    method: 'POST',
    data,
  });
};

export const apiLogout = async () => {
  return Request.call({
    url: '/user/logout',
    method: 'GET',
  });
};

export const apiConfirmRegister = async data => {
  return Request.call({
    url: '/user/verify-email',
    method: 'POST',
    data,
  });
};
export const apiUpdateProfile = async data => {
  return Request.call({
    url: '/user/edit-profile',
    method: 'POST',
    data,
  });
};
export const apiChangePassword = async data => {
  return Request.call({
    url: '/user/change-password',
    method: 'POST',
    data,
  });
};
export const apiValidateInviteToken = async data => {
  return Request.call({
    url: '/user/invite/validate',
    method: 'POST',
    data,
  });
};
export const apiValidateClientToken = async data => {
  return Request.call({
    url: '/team-invite/validate',
    method: 'POST',
    data,
  });
};
export const apiCreateAccount = async data => {
  return Request.call({
    url: '/user/create-account',
    method: 'POST',
    data,
  });
};
export const apiCreateClientAccount = async data => {
  return Request.call({
    url: '/team-member/create',
    method: 'POST',
    data,
  });
};
export const apiValidatePassword = async data => {
  return Request.call({
    url: '/user/validate-password',
    method: 'POST',
    data,
  });
};
export const apiRefreshToken = async () => {
  return Request.call({
    url: '/user/token/refresh',
    method: 'GET',
  });
};
export const apiDeleteAccount = async password => {
  return Request.call({
    url: `/user/delete?password=${password}`,
    method: 'DELETE',
  });
};
