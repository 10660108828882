import React from 'react';

import RadioComponent from './Radio';
import CheckboxComponent from './Checkbox';
import DatePickerComponent from './DatePicker';
import RateComponent from './Rate';
import SwitchComponent from './Switch';
import SelectComponent from './Select';
import TextAreaComponent from './TextArea';
import UploadComponent from './Upload';

const DataEntryComponent = ({ classes }) => {
  return (
    <>
      <h1>Data Entry</h1>
      <div>
        <h2>Radio Button</h2>
        <RadioComponent classes={classes} />
      </div>
      <div>
        <h2>Checkbox</h2>
        <CheckboxComponent classes={classes} />
      </div>
      <div>
        <h2>Date Picker</h2>
        <DatePickerComponent classes={classes} />
      </div>
      <div>
        <h2>Rate</h2>
        <RateComponent classes={classes} />
      </div>
      <div>
        <h2>Switch</h2>
        <SwitchComponent classes={classes} />
      </div>
      <div>
        <h2>Select</h2>
        <SelectComponent classes={classes} />
      </div>
      <div>
        <h2>Text Area</h2>
        <TextAreaComponent classes={classes} />
      </div>
      <div>
        <h2>Upload</h2>
        <UploadComponent classes={classes} />
      </div>
    </>
  );
};

export default DataEntryComponent;
