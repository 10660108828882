import React from 'react';
import InputItem from '../InputItem';
import { Section, FormSection, ListInfo } from '../Elements';
import {
  ENROLLMENT_OBJ_KEYS,
  ENROLLMENT_DRIVER_HIDE_FIELDS,
} from './constants';

const Enrollment = ({ isEdit, form, data, permission }) => {
  return (
    <Section>
      {isEdit ? (
        <FormSection>
          {/* <InputItem
            name={'drug_alcohol_policy_handbook'}
            label="Drug & Alcohol Policy Handbook"
            form={form}
            type="file"
          />
          <InputItem
            name={'drug_alcohol_enrollment_certificate'}
            label="Drug & Alcohol Enrollment Certificate"
            form={form}
            type="file"
          /> */}
          <InputItem
            name={'drug_alcohol_consortium_name'}
            label="Drug & Alcohol Consortium Name"
            form={form}
          />
          <InputItem
            name={'consortium_contact_name'}
            label="Contact Name"
            form={form}
          />
          <InputItem
            name={'consortium_phone'}
            label="Phone"
            form={form}
            type="phone"
          />
          <InputItem
            name={'consortium_email'}
            label="Email"
            form={form}
            placeholder="example@emailaddress.com"
          />
        </FormSection>
      ) : (
        <ListInfo
          objKeys={ENROLLMENT_OBJ_KEYS.filter(
            o =>
              !permission?.isDriver ||
              (permission?.isDriver &&
                !ENROLLMENT_DRIVER_HIDE_FIELDS.includes(o.key))
          )}
          data={data}
        />
      )}
    </Section>
  );
};

export default Enrollment;
