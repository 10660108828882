import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { Checkbox } from 'components/FormFields';
import Form, { useForm } from 'components/Form';
import { useOptions } from 'helper/hooks';
import { validateForm, scrollToErrorField } from 'utils/form';
import InputItem from '../InputItem';
import {
  Title,
  Section,
  FormSection,
  ListInfo,
  TextareaField,
  SectionWrapper,
  ShouldUpdateWrapper,
} from '../Elements';
import OtherIntegrateSection from '../OtherIntegrateSection';
import ButtonActions from '../ButtonActions';
import { getInitialValues, validateObj } from './utils';
import { TOP_OBJ_KEYS, BOTTOM_OBJ_KEYS } from './constants';
import classes from './SalesInformation.module.scss';

const SalesInformation = ({
  className,
  isEdit,
  setIsEdit,
  rootKey,
  data,
  onFormChange,
  openWarningModal,
  onSubmit,
  loading,
}) => {
  const {
    scTitleOptions,
    scProspectSourceOptions,
    scDotNumberStatusOptions,
    salesDepartmentUserOptions,
  } = useOptions();
  const initialValues = getInitialValues(data);
  const currentPrimaryContact = !!data.is_primary;
  const wrapperId = `record_information_${rootKey}`;
  const [form] = useForm();
  const [isFormChange, setIsFormChanged] = useState(false);
  const [primaryContact, setPrimaryContact] = useState(false);
  const hidePrimaryCheckbox = isEmpty(data) && !isEdit;
  const handleSubmit = values => {
    const fieldErrors = validateForm(validateObj, values);
    if (fieldErrors.length > 0) {
      form.setFields(fieldErrors);
      scrollToErrorField(wrapperId);
    } else {
      onSubmit({
        ...values,
        primaryContact,
      });
    }
  };
  useEffect(() => {
    if (isEdit) {
      setIsFormChanged(false);
      if (form) {
        form.resetFields();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);
  useEffect(() => {
    if (currentPrimaryContact !== primaryContact) {
      setPrimaryContact(currentPrimaryContact);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPrimaryContact]);

  return (
    <SectionWrapper className={cn(classes.wrapper, className)} id={wrapperId}>
      <Form
        validateMessages={''}
        onFinish={handleSubmit}
        initialValues={initialValues}
        form={form}
        onValuesChange={() => {
          setIsFormChanged(true);
          onFormChange();
        }}
      >
        <div>
          <Title text="Sales Card">
            <ButtonActions
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              form={form}
              isFormChanged={isFormChange}
              loading={loading}
              className={classes.buttonActions}
              onSubmit={handleSubmit}
              openWarningModal={openWarningModal}
            />
          </Title>
          <Section>
            {isEdit ? (
              <FormSection>
                <InputItem
                  name={'sales_person_id'}
                  label="Sales Person"
                  form={form}
                  type="select"
                  options={salesDepartmentUserOptions}
                />
                <InputItem
                  name={'initial_contact'}
                  label="Initial Contact"
                  form={form}
                  type="date"
                />
                <InputItem
                  name={'prospect_source_id'}
                  label="Prospect Source"
                  form={form}
                  type="select"
                  options={scProspectSourceOptions}
                />
                <InputItem
                  name={'date_of_application'}
                  label="Date of Application"
                  form={form}
                  type="date"
                />
                <ShouldUpdateWrapper>
                  {() => {
                    const values = form.getFieldsValue();
                    const foundOption =
                      find(scProspectSourceOptions, {
                        value: values.prospect_source_id,
                      }) || {};
                    if (foundOption.label !== 'Other') {
                      return <></>;
                    }
                    return (
                      <>
                        <InputItem
                          name={'prospect_source_other'}
                          label="Other"
                          form={form}
                        />
                      </>
                    );
                  }}
                </ShouldUpdateWrapper>
                <TextareaField
                  name={'notes'}
                  label="Notes"
                  form={form}
                  type="textarea"
                />
              </FormSection>
            ) : (
              <ListInfo objKeys={TOP_OBJ_KEYS} data={data} />
            )}
          </Section>
        </div>
        <div>
          <Title text="Applicant Information" />
          <Section>
            {isEdit ? (
              <FormSection>
                <div className="form-item-wrapper-has-sub">
                  <InputItem
                    name={'first_name'}
                    label="First Name"
                    required
                    form={form}
                  />
                  <InputItem
                    name={'last_name'}
                    label="Last Name"
                    required
                    form={form}
                  />
                </div>
                <InputItem
                  name={'title_id'}
                  label="Title"
                  required
                  form={form}
                  type="select"
                  options={scTitleOptions}
                />
                <InputItem
                  name={'phone'}
                  label="Phone"
                  required
                  form={form}
                  type="phone"
                />
                <InputItem
                  name={'email'}
                  label="Email"
                  required
                  form={form}
                  placeholder="example@emailaddress.com"
                />
                <InputItem
                  name={'dot_number_status_id'}
                  label="DOT Preference"
                  form={form}
                  type="select"
                  options={scDotNumberStatusOptions}
                />
                <InputItem
                  name={'dot_number'}
                  label="DOT Number"
                  form={form}
                  type="number"
                />
              </FormSection>
            ) : (
              <ListInfo objKeys={BOTTOM_OBJ_KEYS} data={data} />
            )}
            {!hidePrimaryCheckbox && (
              <Checkbox
                checked={primaryContact}
                label="Primary Contact"
                onChange={e => {
                  setPrimaryContact(e.target.checked);
                }}
                className={cn(classes.checkbox, {
                  [classes.isEdit]: isEdit,
                })}
                disabled={!isEdit}
              />
            )}
          </Section>
        </div>
        <OtherIntegrateSection
          isEdit={isEdit}
          form={form}
          other={data.custom_fields || []}
        />
      </Form>
    </SectionWrapper>
  );
};

export default SalesInformation;
