import React from 'react';
import InputItem from '../InputItem';
import { Section, FormSection, ListInfo } from '../Elements';
import { AGENT_INFO_OBJ_KEYS } from './constants';

const AgentInfo = ({ isEdit, form, data }) => {
  return (
    <Section>
      {isEdit ? (
        <FormSection>
          <InputItem name={'agent_name'} label="Agent Name" form={form} />
          <InputItem
            name={'agent_phone'}
            label="Agent Phone"
            form={form}
            type="phone"
          />
          <InputItem
            name={'agent_email'}
            label="Agent Email"
            form={form}
            placeholder="example@emailaddress.com"
          />
          <InputItem
            name={'agent_fax'}
            label="Agent Fax"
            form={form}
            type="phone"
          />
        </FormSection>
      ) : (
        <ListInfo objKeys={AGENT_INFO_OBJ_KEYS} data={data} />
      )}
    </Section>
  );
};

export default AgentInfo;
