import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Container from './Container';

const DragDrop = props => {
  const {
    items,
    setItems,
    onIndexChange,
    disabled,
    cardClassName,
    wholeHandler,
    onDropCard,
  } = props;
  return (
    <DndProvider backend={HTML5Backend}>
      <Container
        items={items}
        setItems={setItems}
        onIndexChange={onIndexChange}
        disabled={disabled}
        cardClassName={cardClassName}
        wholeHandler={wholeHandler}
        onDropCard={onDropCard}
      />
    </DndProvider>
  );
};

export default DragDrop;
