import React from 'react';
import { PlusCircleFilled } from '@ant-design/icons';
import Empty, { EMPTY_SIZE } from 'components/Empty';
import Button, { ButtonColors } from 'components/Button';

import classes from './ClientList.module.scss';

const NotFound = ({
  onClick,
  title = 'No clients have been created yet.',
  buttonText = 'Create New Client',
  isArchived = false,
}) => {
  return (
    <div className={classes.notFound}>
      <Empty type="image2" size={EMPTY_SIZE.LARGE}>
        <h3>{title}</h3>
        {!isArchived && (
          <Button
            type="primary"
            size="large"
            className={classes.addCButton}
            onClick={onClick}
            color={ButtonColors.BLUE}
            leftIcon={<PlusCircleFilled />}
            largeIcon
          >
            {buttonText}
          </Button>
        )}
      </Empty>
    </div>
  );
};

export default NotFound;
