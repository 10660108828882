import React from 'react';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import {
  Section,
  FormSection,
  ListInfo,
} from 'views/pages/ClientDetail/components/Elements';
import { BOC3_OBJ_KEYS } from '../../tabConstants/general';

const BOC3 = ({ isEdit, form, data }) => {
  return (
    <Section>
      {isEdit ? (
        <FormSection>
          {/* Use it to save delete_attachments for attachment removing */}
          <InputItem name={'delete_attachments'} label="" form={form} hide />
          <InputItem name={'boc3'} label="BOC3" form={form} type="file" />
        </FormSection>
      ) : (
        <ListInfo objKeys={BOC3_OBJ_KEYS} data={data} />
      )}
    </Section>
  );
};

export default BOC3;
