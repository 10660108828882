import Request from './request';

export const apiGetTrailers = async (id, filters = {}) => {
  return Request.call({
    url: `/company/${id}/trailers/list`,
    method: 'GET',
    params: filters,
  });
};

export const apiCreateUpdateTrailer = async (id, data) => {
  return Request.call({
    url: `/company/${id}/trailers`,
    method: 'POST',
    data,
  });
};
export const apiUpdateTrailerStatus = async (companyId, trailerId, data) => {
  return Request.call({
    url: `/company/${companyId}/trailers/${trailerId}/status`,
    method: 'POST',
    data,
  });
};
