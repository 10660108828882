import React from 'react';
import styled from 'styled-components';
import { EllipsisOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { Table as AntdTable } from 'antd';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Dropdown from 'components/Dropdown';
import { TooltipBlock } from 'components/Tooltip';

import classes from './Table.module.scss';

export const ActionItemColors = {
  RED: 'red',
  BLUE: 'blue',
};

let sampleData = [];
for (let i = 1; i <= 5; i = i + 1) {
  sampleData.push({
    key: i,
    column1: 'John Doe',
    column2: 'Londone, Park Lane no. 1',
    column3: '$1,000,1000',
    column4: 32,
    description: 'Description',
  });
}

export const ColumnActions = props => {
  const {
    actions = [],
    className,
    trigger = ['click'],
    isBorderLastItem,
    lastItemColor,
    placement,
    menuClassName,
    children,
    disabled,
  } = props;
  return (
    <span className={cx(classes.columnActionWrapper, className)}>
      <Dropdown
        menus={actions}
        menuClassName={cx(
          classes.columnActionDropdown,
          {
            [classes.isEmpty]: actions.length === 0,
            [classes.isBorderLastItem]: isBorderLastItem,
            [classes.disabled]: disabled,
            [classes.lastItemRed]: lastItemColor === ActionItemColors.RED,
            [classes.lastItemBlue]: lastItemColor === ActionItemColors.BLUE,
          },
          menuClassName
        )}
        overlayClassName={classes.columnActionOverlay}
        trigger={trigger}
        hideArrow
        placement={placement || 'bottomLeft'}
      >
        {children || <EllipsisOutlined />}
      </Dropdown>
    </span>
  );
};

export const StandardColumn = ({ children, className }) => {
  return <span className={cx('standard-column', className)}>{children}</span>;
};

export const StandardTooltipColumn = ({ children, className, tooltip }) => {
  // return <div className={cx('standard-column', className)}>{children}</div>;
  return (
    <TooltipBlock
      className={cx('standard-column', className)}
      tooltip={tooltip}
    >
      {children}
    </TooltipBlock>
  );
};

const Table = props => {
  const {
    data,
    columns,
    className,
    hideSelectInput,
    bordered,
    pagination,
    rowIsClick,
    isScroll,
    scroll = {},
    minHeight,
    isBox,
    locale,
    loading,
    onSortChange,
    isScrollCSS,
    ...other
  } = props;
  setTimeout(() => {
    if (minHeight) {
      const tableEl = document.getElementById(other.id);
      if (tableEl && minHeight) {
        tableEl.querySelector(
          '.ant-table-body'
        ).style.minHeight = `${minHeight}px`;
      }
    }
  }, 100);

  return (
    <StyledTable
      {...other}
      loading={loading}
      locale={{
        ...locale,
        emptyText: loading ? <>&nbsp;</> : get(locale, 'emptyText'),
      }}
      scroll={
        isScroll
          ? {
              y: scroll.y || '100%',
              x: scroll.x || 1024,
            }
          : false
      }
      columns={columns}
      dataSource={data}
      bordered={bordered}
      pagination={pagination}
      className={cx(
        classes.wrapper,
        {
          [classes.hideSelectInput]: hideSelectInput,
          [classes.bordered]: bordered,
          [classes.rowIsClick]: rowIsClick,
          [classes.isBox]: isBox,
          [classes.isScrollCSS]: isScrollCSS,
        },
        className
      )}
      onChange={(_pagination, _filters, sorter) => {
        onSortChange(sorter);
      }}
    />
  );
};

const StyledTable = styled(AntdTable)`
  table {
    width: ${props => (props.tableWidth ? `${props.tableWidth}px` : '100%')};
  }
`;

Table.defaultProps = {
  columns: [
    {
      title: 'Column 1',
      dataIndex: 'column1',
    },
    {
      title: 'Column 2',
      dataIndex: 'column2',
    },
    {
      title: 'Column 3',
      dataIndex: 'column3',
    },
    {
      title: 'Column 4',
      dataIndex: 'column4',
    },
    {
      title: '',
      render: () => (
        <ColumnActions
          actions={[
            {
              label: 'Edit',
              props: {
                onClick: () => {},
              },
            },
          ]}
        />
      ),
    },
  ],
  data: sampleData,
  className: '',
  bordered: false,
  pagination: false,
  rowIsClick: false,
  isScroll: false,
  onSortChange: () => {},
};

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  className: PropTypes.any,
  bordered: PropTypes.bool,
  pagination: PropTypes.bool,
  rowIsClick: PropTypes.bool,
  isScroll: PropTypes.bool,
  onSortChange: PropTypes.func,
};

export default Table;
