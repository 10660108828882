import React from 'react';
import cx from 'classnames';
import { Typography as AntdTypography } from 'antd';

import classes from './Typography.module.scss';

const { Paragraph, Title, Text } = AntdTypography;

const Heading = props => {
  const { className, ...other } = props;
  return <Title {...other} className={cx(classes.headingWrapper, className)} />;
};

const Typography = props => {
  const { className, ...other } = props;
  return (
    <AntdTypography {...other} className={cx(classes.wrapper, className)} />
  );
};

export { Paragraph, Heading, Text };
export default Typography;
