import {
  apiGetOrderDetailFields,
  apiGetOrderDetailFieldSubRecords,
  apiGetOrderRequesterList,
  apiGetOrderList,
  apiSaveOrderAsDraft,
  apiSubmitOrder,
  apiDeleteOrder,
  apiGetClientOrderDetail,
  apiGetClientOrder,
  apiUpdateClientOrderStatus,
} from 'api';

import { orderTypes } from '../types';

// GET_ORDER_REQUESTER_LIST
export const getOrderRequesterList = (id, cb) => {
  return {
    isAsyncCall: true,
    baseType: orderTypes.GET_ORDER_REQUESTER_LIST,
    payload: {},
    asyncCall: () => {
      return apiGetOrderRequesterList(id);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_ORDER_DETAIL_FIELDS
export const getOrderDetailFields = (id, orderTypeId, cb) => {
  return {
    isAsyncCall: true,
    baseType: orderTypes.GET_ORDER_DETAIL_FIELDS,
    payload: {},
    asyncCall: () => {
      return apiGetOrderDetailFields(id, orderTypeId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_ORDER_DETAIL_FIELDS_SUB_RECORDS
export const getOrderDetailFieldSubRecords = (
  id,
  orderTypeId,
  value,
  field,
  cb
) => {
  return {
    isAsyncCall: true,
    baseType: orderTypes.GET_ORDER_DETAIL_FIELD_SUB_RECORDS,
    payload: {},
    asyncCall: () => {
      return apiGetOrderDetailFieldSubRecords(id, orderTypeId, value, field);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_ORDER_LIST
export const getOrderList = (id, params, cb) => {
  return {
    isAsyncCall: true,
    baseType: orderTypes.GET_ORDER_LIST,
    payload: params,
    asyncCall: () => {
      return apiGetOrderList(id, params);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_CLIENT_ORDER_DETAIL
export const getClientOrderDetail = (clientId, orderId, cb) => {
  return {
    isAsyncCall: true,
    baseType: orderTypes.GET_CLIENT_ORDER_DETAIL,
    payload: {},
    asyncCall: () => {
      return apiGetClientOrderDetail(clientId, orderId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_CLIENT_ORDER
export const getClientOrder = (clientId, orderId, cb, silent = false) => {
  return {
    isAsyncCall: true,
    baseType: orderTypes.GET_CLIENT_ORDER,
    payload: { silent },
    asyncCall: () => {
      return apiGetClientOrder(clientId, orderId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// SAVE_ORDER_AS_DRAFT
export const saveOrderAsDraft = (id, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: orderTypes.SAVE_ORDER_AS_DRAFT,
    payload: {},
    asyncCall: () => {
      return apiSaveOrderAsDraft(id, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// SUBMIT_ORDER
export const submitOrder = (id, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: orderTypes.SUBMIT_ORDER,
    payload: {},
    asyncCall: () => {
      return apiSubmitOrder(id, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// DELETE_ORDER
export const deleteOrder = (id, orderId, cb) => {
  return {
    isAsyncCall: true,
    baseType: orderTypes.DELETE_ORDER,
    payload: {},
    asyncCall: () => {
      return apiDeleteOrder(id, orderId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_CLIENT_ORDER_STATUS
export const updateClientOrderStatus = (id, orderId, status, cb) => {
  return {
    isAsyncCall: true,
    baseType: orderTypes.UPDATE_CLIENT_ORDER_STATUS,
    payload: {},
    asyncCall: () => {
      return apiUpdateClientOrderStatus(id, orderId, status);
    },
    afterSuccessCall: (dispatch, response) => {
      if (cb) cb(response);
      dispatch(syncUpdateClientOrderStatus(status));
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};

// CUSTOM
export const syncUpdateClientOrderStatus = status => dispatch => {
  dispatch({
    type: orderTypes.SYNC_UPDATE_CLIENT_ORDER_STATUS,
    payload: status,
  });
};
// SYNC_ADD_NEW_CHECKLIST
export const syncAddNewChecklist = data => dispatch => {
  dispatch({
    type: orderTypes.SYNC_ADD_NEW_CHECKLIST,
    payload: data,
  });
};
// SYNC_REMOVE_CHECKLIST
export const syncRemoveChecklist = id => dispatch => {
  dispatch({
    type: orderTypes.SYNC_REMOVE_CHECKLIST,
    payload: id,
  });
};
// SYNC_UPDATE_CHECKLIST
export const syncUpdateChecklist = (id, attr, value) => dispatch => {
  dispatch({
    type: orderTypes.SYNC_UPDATE_CHECKLIST,
    payload: { id, attr, value },
  });
};
// SYNC_UPDATE_CLIENT_ORDER_NOTE
export const syncUpdateClientOrderNote = note => dispatch => {
  dispatch({
    type: orderTypes.SYNC_UPDATE_CLIENT_ORDER_NOTE,
    payload: note,
  });
};
// SYNC_UPDATE_ORDER_DETAIL_FIELDS
export const syncUpdateOrderDetailFields = data => dispatch => {
  dispatch({
    type: orderTypes.SYNC_UPDATE_ORDER_DETAIL_FIELDS,
    payload: data,
  });
};
