export const getChecklistBR = (values, eReminder, checklistId) => {
  const eChecklists = eReminder.checklists || [];
  const lastChecklist = eChecklists[eChecklists.length - 1] || null;
  const newChecklist = {
    description: values.text,
    order: (lastChecklist ? lastChecklist.order : eChecklists.length) + 1,
  };
  const newChecklists = eChecklists.map(c => {
    if (!!checklistId && c.id === checklistId) {
      c.description = values.text;
    }
    return c;
  });
  const returnObj = {
    checklist_type_id: eReminder.id,
    checklist_type: 'Reminder',
    checklist: !!checklistId ? newChecklists : [...eChecklists, newChecklist],
  };

  return returnObj;
};
