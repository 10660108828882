import pick from 'lodash/pick';
import get from 'lodash/get';
import {
  standardRequiredFieldObj,
  getFileFieldInitialValues,
} from 'utils/form';
import { parseStringToNumber } from 'utils/number';
import { getOtherDataValue } from 'utils/customField';
import { getMomentDate, getBEDate } from 'utils/date';
import {
  NORMAL_FIELDS,
  NULL_FIELDS,
  DATE_FIELDS,
  FILE_FIELDS,
  NUMBER_FIELDS,
  BODY_REQUEST_NORMAL_FIELDS,
  BOOLEAN_FIELDS,
  ARR_FIELDS,
  YES_NO_FIELDS,
} from './constants';

const getFileField = field => {
  if (!field) return '';
  return field.replace('documents.', '');
};
export const validateObj = () => {
  return {
    truck_unit: [standardRequiredFieldObj],
    truck_type_id: [standardRequiredFieldObj],
    vin: [standardRequiredFieldObj],
    year: [standardRequiredFieldObj],
    make: [standardRequiredFieldObj],
    model: [standardRequiredFieldObj],
    axles: [standardRequiredFieldObj],
    unladen_weight: [standardRequiredFieldObj],
    gvwr: [standardRequiredFieldObj],
  };
};
export const getInitialValues = data => {
  const initialValues = {
    delete_attachments: [],
    delete_custom_fields: [],
    other: getOtherDataValue(data.custom_fields || []),
    valid_vin: data.valid_vin || 0,
  };
  [...NORMAL_FIELDS, ...NULL_FIELDS].map(field => {
    const eValue = data[field];
    initialValues[field] = eValue || (NULL_FIELDS.includes(field) ? null : '');
    if (YES_NO_FIELDS.includes(field) && (eValue || eValue === 0)) {
      initialValues[field] = String(eValue);
    }
    return true;
  });
  DATE_FIELDS.map(field => {
    initialValues[field] = data[field]
      ? getMomentDate(data[field], false, true)
      : '';
    return true;
  });
  BOOLEAN_FIELDS.map(field => {
    initialValues[field] = data[field] ? [field] : [];
    return true;
  });
  ARR_FIELDS.map(field => {
    initialValues[field] = data[field] ? data[field].map(v => v.id) : [];
    return true;
  });

  return {
    ...initialValues,
    ...getFileFieldInitialValues(FILE_FIELDS, data, 'documents'),
  };
};
export const getTruckBR = (
  values = {},
  truckId,
  avatarFile,
  searchFields,
  currentProfilePic,
  finalProfileSrc
) => {
  const { delete_attachments, other, delete_custom_fields } = values;
  const bodyRequest = {
    ...pick(values, BODY_REQUEST_NORMAL_FIELDS),
    valid_vin: truckId
      ? values.valid_vin || 0
      : !!searchFields?.make && !!searchFields?.model && !!searchFields?.year
      ? 1
      : 0,
  };
  if (truckId) {
    bodyRequest.truck_id = truckId;
  }
  DATE_FIELDS.map(field => {
    bodyRequest[field] = values[field] ? getBEDate(values[field]) : '';
    return true;
  });
  YES_NO_FIELDS.map(field => {
    const value = values[field];
    bodyRequest[field] = value ? parseInt(value, 10) : '';
    return true;
  });
  NUMBER_FIELDS.map(field => {
    bodyRequest[field] = parseStringToNumber(values[field]);
    return true;
  });
  BOOLEAN_FIELDS.map(field => {
    bodyRequest[field] = values[field] && values[field].length > 0 ? 1 : 0;
    return true;
  });
  const fData = new FormData();
  Object.keys(bodyRequest).map(k => {
    fData.append(
      k,
      bodyRequest[k] || bodyRequest[k] === 0 ? bodyRequest[k] : ''
    );
    return true;
  });
  if (avatarFile && avatarFile.type && finalProfileSrc) {
    fData.append('profile_pic', avatarFile);
    fData.append('remove_profile_pic', 0);
  } else if (currentProfilePic && !finalProfileSrc) {
    fData.append('remove_profile_pic', 1);
  }
  ARR_FIELDS.map(field => {
    const vals = values[field] || [];
    vals.map(val => {
      fData.append(`${field}[]`, val);
      return true;
    });
    if (vals.length === 0) {
      fData.append(`${field}[]`, '');
    }
    return true;
  });
  FILE_FIELDS.map(originKey => {
    const key = getFileField(originKey);
    if (values[key] && values[key].length > 0) {
      values[key].map(file => {
        if (file.originFileObj && !file.id) {
          fData.append(`${key}[]`, file.originFileObj);
        }
        return true;
      });
    } else {
      fData.append(key, '');
    }
    return true;
  });
  if (delete_attachments && delete_attachments.length > 0) {
    delete_attachments.map(id => {
      fData.append('delete_documents[]', id);
      return true;
    });
  }
  if (other && other.length > 0) {
    other.map((field, index) => {
      fData.append(`custom_fields[${index}][field_name]`, field.field_name);
      if (field.originId) {
        fData.append(`custom_fields[${index}][id]`, field.originId);
      }
      const firstDocument = get(field, 'document[0]') || null;
      const existingFileName = get(field, 'originData.document_name', '');
      if (firstDocument && !firstDocument.url) {
        fData.append(
          `custom_fields[${index}][document]`,
          firstDocument.originFileObj
        );
      } else if (!firstDocument && !!existingFileName) {
        fData.append(`custom_fields[${index}][document_delete]`, 1);
      }
      return true;
    });
  } else {
    fData.append('custom_fields', '');
  }
  if (delete_custom_fields && delete_custom_fields.length > 0) {
    delete_custom_fields.map(id => {
      fData.append('delete_custom_fields[]', id);
      return true;
    });
  }
  return fData;
};
export const getDriverTrailerAssigned = data => {
  const { assigned_drivers, assigned_trailers } = data || {};
  return {
    drivers: (assigned_drivers || []).map(driver => driver.name),
    trailers: (assigned_trailers || []).map(trailer => trailer.trailer_unit),
  };
};
