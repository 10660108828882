export const getOtherDataObj = (data, index) => {
  return {
    field_name: data.field_name,
    id: index + 1,
    originId: data.id,
    originData: {
      ...data,
    },
    document: !!data.url
      ? [
          {
            uid: data.id,
            name: data.document_name,
            url: data.url,
          },
        ]
      : null,
  };
};
export const getOtherDataValue = otherData => {
  return (otherData || []).map((o, i) => getOtherDataObj(o, i));
};
export const getSyncFieldsDelete = (otherData, formOtherData) => {
  const ids = otherData.map(o => o.id);
  const newOtherData = formOtherData.filter(
    o => !o.originId || ids.includes(o.originId)
  );
  return newOtherData;
};
export const getSyncFieldsAdd = (otherData, formOtherData) => {
  const newOtherData = [...formOtherData];
  const existingIds = newOtherData.filter(o => o.originId).map(o => o.originId);
  const addObjs = otherData.filter(o => !existingIds.includes(o.id));
  return [...newOtherData, ...getOtherDataValue(addObjs)];
};
