import BaseAxios from 'axios';
import get from 'lodash/get';
// import { message as AntMessage } from 'antd';
import { store } from 'store';
// import ROUTES from 'routes';

// TODO: temp hide toast for error
// const isTokenExpiredMessage = message => {
//   return [
//     'Token has expired',
//     'Token Signature could not be verified.',
//   ].includes(message);
// };
// const handleIncorrectPermission = message => {
//   if (message === 'Unauthorized access') {
//     if (window && window.stop) {
//       window.stop();
//     }
//     window.location.href = ROUTES.INDEX();
//   }
// };

class Request {
  axios;

  constructor() {
    // Timeout is 60 * 60000 = 60x60s = 60m = 1hour
    this.axios = BaseAxios.create({ timeout: 3600000 });
  }

  async call(config) {
    try {
      const serverBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const state = store.getState();
      const token = get(state, 'auth.login.access_token', '');
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization:
          token || config.token ? `Bearer ${token || config.token}` : undefined,
      };
      const res = await this.axios.request({
        baseURL: serverBaseUrl,
        headers,
        ...config,
      });
      const status = get(res, 'data.status');
      if (status === 0) {
        // TODO: temp hide toast for error
        // const message = get(res, 'data.message', '');
        // handleIncorrectPermission(message);
        // if (message && !isTokenExpiredMessage(message)) {
        //   AntMessage.error(message);
        // }
        throw res;
      } else {
        return res.data;
      }
    } catch (error) {
      // TODO: temp hide toast for error
      // const errMessageWithout200Code = get(
      //   error,
      //   'response.data.error.message'
      // );
      // handleIncorrectPermission(errMessageWithout200Code);
      // if (
      //   errMessageWithout200Code &&
      //   !isTokenExpiredMessage(errMessageWithout200Code)
      // ) {
      //   AntMessage.error(errMessageWithout200Code);
      //   throw error;
      // }
      // const { response } = error;
      // if (response) {
      //   const { data } = response;
      //   if (data) {
      //     const { message } = data;
      //     let errMessage = '';

      //     if (typeof message === 'string') {
      //       errMessage = message;
      //     } else if (message instanceof Object) {
      //       for (const key in message) {
      //         errMessage += message[key].join(',');
      //       }
      //     }
      //     if (typeof errMessage !== 'string') {
      //       errMessage = 'Something went wrong!';
      //     }
      //     handleIncorrectPermission(errMessage);
      //     if (errMessage && !isTokenExpiredMessage(errMessage)) {
      //       AntMessage.error(errMessage);
      //     }
      //   }
      // }
      if (config?.seeError) {
        return error?.response;
      }
      throw error;
    }
  }
}

export default new Request();
