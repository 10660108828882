import React from 'react';
import cn from 'classnames';
import { Form as AntdForm } from 'antd';
import Tooltip from '../Tooltip';
import classes from './Form.module.scss';

const { Item } = AntdForm;

const FormItem = props => {
  const {
    required,
    label,
    labelTooltip,
    tooltipWidth,
    placement,
    tooltipClassName,
    tooltipDefaultStyle,
    noMargin,
    ...other
  } = props;
  const labelEl = required ? (
    <span>
      {label}
      <span className={classes.requiredMark}>*</span>
    </span>
  ) : (
    label
  );
  const classNameProps = {
    className: cn(
      classes.formItem,
      {
        [classes.noMargin]: noMargin,
      },
      other.className
    ),
  };
  if (!!labelTooltip) {
    return (
      <Item
        {...other}
        {...classNameProps}
        label={
          <Tooltip
            title={labelTooltip}
            tooltipWidth={tooltipWidth}
            placement={placement || 'leftTop'}
            overlayClassName={
              tooltipDefaultStyle
                ? ''
                : cn(classes.tooltipOverlay, tooltipClassName)
            }
          >
            {labelEl}
          </Tooltip>
        }
      />
    );
  }
  return <Item {...other} {...classNameProps} label={labelEl} />;
};

export default FormItem;
