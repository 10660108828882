import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { validateForm, scrollToErrorField } from 'utils/form';
import Form, { useForm } from 'components/Form';
import HeaderActions from 'views/pages/ClientDetail/CompanyOfficial/components/HeaderActions';
import OtherIntegrateSection from 'views/pages/ClientDetail/components/OtherIntegrateSection';
import Profile from './Profile';
import VehicleTitle from './VehicleTitle';
import GarageAddress from './GarageAddress';
import Equipment from './Equipment';
import Filings from './Filings';
import Leased from './Leased';
import { validateObj, getInitialValues } from '../../utils';
import { NEW_KEY } from '../../constants';
import classes from './Section.module.scss';

const Section = ({
  className,
  isActive,
  isEdit,
  setIsEdit,
  onFormChange,
  openWarningModal,
  rootKey,
  data,
  onSubmit,
  onStatusChange,
  statusLoading,
  loading,
  vinDecoder,
  confirmModal,
  vinDecoderLoading,
  permission,
}) => {
  const eTruckId = data.id;
  const isEId = eTruckId && eTruckId !== NEW_KEY;
  const wrapperId = `truck_information_${rootKey}`;
  const vinRef = useRef(null);
  const [isFormChange, setIsFormChanged] = useState(false);
  const [showManualVin, setShowManualVin] = useState(false);
  const [searchedVinSuccess, setSearchedVinSuccess] = useState(false);
  const [searchFields, setSearchFields] = useState({});
  const [form] = useForm();
  const handleSubmit = values => {
    const fieldErrors = validateForm(validateObj(), values);
    if (fieldErrors.length > 0) {
      form.setFields(fieldErrors);
      scrollToErrorField(wrapperId);
    } else {
      onSubmit(values, searchFields, () => {
        setIsEdit(false);
      });
    }
  };
  const handleSearchVIN = () => {
    const values = form.getFieldsValue();
    const { vin } = values || {};
    vinDecoder(vin, res => {
      if (res) {
        const vinData = res?.data || {};
        setShowManualVin(true);
        setSearchedVinSuccess(true);
        form.setFieldsValue({
          year: vinData.year,
          make: vinData.make,
          model: vinData.model,
          axles: vinData.axles,
          combined_axle: vinData.combined_axle,
          truck_unit: vinData.track_unit,
        });
        setSearchFields({
          year: !!vinData.year,
          make: !!vinData.make,
          model: !!vinData.model,
        });
      } else {
        confirmModal(
          'Unable to Decode VIN',
          <div>
            Please enter another VIN or manually enter
            <br />
            vehicle information.
          </div>,
          () => {
            if (vinRef && vinRef.current) {
              vinRef.current.focus();
            }
          },
          {
            type: 'confirm',
            onCancel: () => {
              setShowManualVin(true);
              setSearchedVinSuccess(false);
              setSearchFields({});
            },
            okText: 'Enter Another VIN',
            cancelText: 'Manually Enter Information',
            centered: true,
            className: classes.warningModal,
          }
        );
      }
    });
  };

  useEffect(() => {
    if (isEdit) {
      setIsFormChanged(false);
      setSearchFields({});
      setShowManualVin(isEId);
      setSearchedVinSuccess(isEId);
      if (form) {
        form.resetFields();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  return (
    <div className={cn(classes.wrapper, className)} id={wrapperId}>
      <Form
        validateMessages={''}
        onFinish={handleSubmit}
        initialValues={getInitialValues(data)}
        form={form}
        onValuesChange={() => {
          setIsFormChanged(true);
          onFormChange();
        }}
      >
        {isActive && (
          <HeaderActions
            className={classes.headerActions}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            form={form}
            isFormChange={isFormChange}
            loading={loading}
            handleSubmit={handleSubmit}
            openWarningModal={openWarningModal}
            status={data?.status}
            onStatusChange={onStatusChange}
            statusLoading={statusLoading}
            inSection
            hideEdit={permission.isDriver}
            hideStatusEdit={permission.isDriver}
          />
        )}
        <Profile
          isEdit={isEdit}
          form={form}
          data={data}
          showManualVin={showManualVin}
          onSearchVIN={handleSearchVIN}
          vinDecoderLoading={vinDecoderLoading}
          vinRef={vinRef}
          searchedVinSuccess={searchedVinSuccess}
          isEId={isEId}
          searchFields={searchFields}
        />
        <VehicleTitle isEdit={isEdit} form={form} data={data} />
        <GarageAddress isEdit={isEdit} form={form} data={data} />
        <Equipment isEdit={isEdit} form={form} data={data} />
        <Filings isEdit={isEdit} form={form} data={data} />
        <Leased isEdit={isEdit} form={form} data={data} />
        <OtherIntegrateSection
          isEdit={isEdit}
          form={form}
          other={data.custom_fields || []}
        />
      </Form>
    </div>
  );
};

export default Section;
