import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Form, { useForm } from 'components/Form';
import { validateForm, scrollToErrorField } from 'utils/form';
import { useIsInternalUser } from 'helper/hooks';
import { Title, SectionWrapper } from '../Elements';
import OtherIntegrateSection from '../OtherIntegrateSection';
import ButtonActions from '../ButtonActions';
import { getInitialValues, validateObj } from './utils';
import CompanyInfo from './CompanyInfo';
import StateFilling from './StateFilling';
import Documents from './Documents';
import Addresses from './Addresses';
import Enrollment from './Enrollment';
import classes from './CompanyInformation.module.scss';

const CompanyInformation = ({
  className,
  isEdit,
  setIsEdit,
  rootKey,
  data,
  onFormChange,
  openWarningModal,
  onSubmit,
  loading,
  permission,
  clientId,
}) => {
  const { isInternalUser } = useIsInternalUser();
  const initialValues = getInitialValues(data, isInternalUser);
  const wrapperId = `record_information_${rootKey}`;
  const [form] = useForm();
  const [isFormChange, setIsFormChanged] = useState(false);
  const handleSubmit = values => {
    const fieldErrors = validateForm(validateObj, values);
    if (fieldErrors.length > 0) {
      form.setFields(fieldErrors);
      scrollToErrorField(wrapperId);
    } else {
      onSubmit(values);
    }
  };
  useEffect(() => {
    if (isEdit) {
      setIsFormChanged(false);
      if (form) {
        form.resetFields();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  return (
    <SectionWrapper className={cn(classes.wrapper, className)} id={wrapperId}>
      <Form
        validateMessages={''}
        onFinish={handleSubmit}
        initialValues={initialValues}
        form={form}
        onValuesChange={() => {
          setIsFormChanged(true);
          onFormChange();
        }}
      >
        <div>
          <Title text="Company Information">
            <ButtonActions
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              form={form}
              isFormChanged={isFormChange}
              loading={loading}
              className={classes.buttonActions}
              onSubmit={handleSubmit}
              openWarningModal={openWarningModal}
              hideEdit={permission.isDriver}
            />
          </Title>
          <CompanyInfo
            isEdit={isEdit}
            form={form}
            data={data}
            permission={permission}
          />
        </div>
        {!permission.isDriver && (
          <>
            <div>
              <Title text="Sec. of State Filing" />
              <StateFilling
                isEdit={isEdit}
                form={form}
                data={data}
                permission={permission}
              />
            </div>
            <div>
              <Title text="Documents" />
              <Documents
                isEdit={isEdit}
                form={form}
                data={data}
                permission={permission}
                clientId={clientId}
              />
            </div>
          </>
        )}
        <div>
          <Title text="Addresses" />
          <Addresses
            isEdit={isEdit}
            form={form}
            data={data}
            permission={permission}
          />
        </div>
        <div>
          <Title text="Enrollment" />
          <Enrollment
            isEdit={isEdit}
            form={form}
            data={data}
            permission={permission}
          />
        </div>
        <OtherIntegrateSection
          isEdit={isEdit}
          form={form}
          other={data.custom_fields || []}
          otherName="custom_fields"
        />
      </Form>
    </SectionWrapper>
  );
};

export default CompanyInformation;
