// import React from 'react';
// import Popover from 'components/Popover';
// import { QuestionCircleOutlined, InfoCircleFilled } from '@ant-design/icons';
import OrderTypeStep from './steps/OrderType';
import OrderDetailsStep from './steps/OrderDetails';
import PaymentsStep from './steps/Payments';
import ConfirmStep from './steps/Confirm';
// import classes from './NewOrderModal.module.scss';

export const SUB_RECORD_PREFIX = '_sub_record_';
export const MULTIPLE_FIELD_PREFIX = [
  'company-officials',
  'forms',
  'driver-information',
  'leasor-information',
  'truck-information',
  'trailer-information',
  'background-checks',
  'business-associations',
  'locations',
  'credit-reports',
  'ucc-filings',
];
export const ARRAY_FIELD_CATEGORY = [
  'Company Officials',
  'Driver Information',
  'Leasor Information',
  'Truck Information',
  'Trailer Information',
  'Background Checks',
  'Business Associations',
  'Locations',
  'Credit Reports',
  'UCC Filings',
];
export const ORDER_SUMMARY_OBJ_KEYS = [
  {
    label: 'Legal Company Name',
    key: 'company_name',
    type: 'value',
    fullWidth: true,
  },
  {
    label: 'Order Type',
    key: 'order_type',
    type: 'value',
    fullWidth: true,
  },
  {
    label: 'Order Requested By',
    key: 'order_requested_by',
    type: 'value',
    fullWidth: true,
  },
  {
    label: 'Order Submitted By',
    key: 'order_submitted_by',
    type: 'value',
    fullWidth: true,
  },
  {
    label: 'Payment Method',
    key: 'payment_method',
    type: 'multiValue',
    fullWidth: true,
    labelKey: 'label',
  },
  {
    label: 'Notes',
    key: 'notes',
    type: 'value',
    fullWidth: true,
  },
];
export const steps = [
  {
    title: 'Order Type',
    content: OrderTypeStep,
  },
  {
    title: 'Order Details',
    content: OrderDetailsStep,
  },
  {
    title: 'Payments',
    content: PaymentsStep,
  },
  {
    title: 'Confirm',
    content: ConfirmStep,
  },
];
export const PAYMENT_METHODS = [
  // {
  //   value: 'account',
  //   label: (
  //     <>
  //       ACH&nbsp;
  //       <Popover
  //         placement={'rightTop'}
  //         overlayClassName={classes.paymentMethodAch}
  //         content={
  //           <>
  //             <InfoCircleFilled />
  //             <p>
  //               ACH payments take a few days to clear and we will not work on
  //               the order until the payment is cleared.
  //             </p>
  //           </>
  //         }
  //         trigger={'hover'}
  //       >
  //         <QuestionCircleOutlined size={'large'} />
  //       </Popover>
  //     </>
  //   ),
  // },
  { value: 'card', label: 'Credit / Debit Card' },
];
