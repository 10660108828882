import React from 'react';
import cn from 'classnames';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  Title,
} from 'views/pages/ClientDetail/components/Elements';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { FILINGS_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const Filings = ({ className, isEdit, form, data }) => {
  return (
    <div className={cn(classes.billOfStateSection, className)}>
      <Title text="Filings" />
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            <InputItem name={'ucr'} label="UCR" form={form} type="file" />
            <InputItem name={'2290'} label="2290" form={form} type="file" />
            <InputItem
              name={'irp_number'}
              label="IRP Number"
              form={form}
              placeholder="000-000000"
            />
            <InputItem
              name={'cab_card'}
              label="Cab Card"
              form={form}
              type="file"
            />
            <InputItem
              name={'ifta_number'}
              label="IFTA Number"
              form={form}
              placeholder="LL000000000000"
            />
            <InputItem
              name={'ifta_license'}
              label="IFTA License"
              form={form}
              type="file"
            />
            <InputItem
              name={'insurance_policy_number'}
              label="Ins. Policy Number"
              form={form}
              placeholder="00-00000000000"
            />
            <InputItem
              name={'cert_of_insurance'}
              label="Cert. of Insurance"
              form={form}
              type="file"
            />
            <InputItem
              name={'or_permit_number'}
              label="OR Permit Number"
              placeholder="0000000000"
              form={form}
            />
            <InputItem
              name={'or_permit'}
              label="OR Permit"
              form={form}
              type="file"
            />
            <InputItem
              name={'nm_permit_number'}
              label="NM Permit Number"
              placeholder="00L-000000"
              form={form}
            />
            <InputItem
              name={'nm_permit'}
              label="NM Permit"
              form={form}
              type="file"
            />
            <InputItem
              name={'kyu_permit_number'}
              label="KYU Permit Number"
              placeholder="00000000"
              form={form}
            />
            <InputItem
              name={'kyu_permit'}
              label="KYU Permit"
              form={form}
              type="file"
            />
            <InputItem
              name={'ny_hut_permit_number'}
              label="NY HUT Permit Number"
              placeholder="L00000"
              form={form}
            />
            <InputItem
              name={'ny_hut_permit'}
              label="NY HUT Permit"
              form={form}
              type="file"
            />
          </FormSection>
        ) : (
          <ListInfo objKeys={FILINGS_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default Filings;
