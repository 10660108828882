import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import Collapse, { Panel } from 'components/Collapse';
import Header from './components/Header';
import NotFound from './components/NotFound';
import Section from './components/Section';
import StatusTag from './components/StatusTag';
import {
  isAlreadyNew,
  getCompanyOfficialBR,
  getLabel,
  afterCbFn,
} from './utils';
import { ALL_OPTION, DEFAULT_SECTIONS, NEW_KEY } from './constants';
import { CollapseTable } from '../components/Elements';
import PanelHeader from './PanelHeader';
import { onKeyChangeFn, getEditKeys, editChangeFn, getIsKey } from '../utils';
import { useActions, useIndexData } from './selectorData';
import classes from './CompanyOfficial.module.scss';

const CompanyOfficial = ({
  className,
  confirmModal,
  openWarningModal,
  setIsEditing,
  activeTab,
  clientId,
  officials_count,
  setIsEdit,
  status,
  setStatus,
  onUpdated,
}) => {
  const setIsEditParent = setIsEdit;
  const { companyOfficials, updateCOLoading } = useIndexData();
  const {
    getCompanyOfficials,
    updateCompanyOfficial,
    createCompanyOfficial,
    updateCOStatus,
    syncSetSectionTotal,
    syncSetCOPrimaryContact,
  } = useActions();
  const [createLoading, setCreateLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState({});
  const [sections, setSections] = useState(DEFAULT_SECTIONS);
  const [activeKeys, setActiveKeys] = useState([]);
  const [editKeys, setEditKeys] = useState([]);
  const [editChanged, setEditChanged] = useState({});
  const notFound = sections.length === 0 && companyOfficials.length === 0;
  const handleEditChange = (...params) =>
    editChangeFn(editChanged, setEditChanged, setIsEditing, ...params);
  const handleSubmit = id => (values, cb) => {
    const officialId = id && id !== NEW_KEY ? id : '';
    const actionFn = officialId ? updateCompanyOfficial : createCompanyOfficial;
    if (!officialId) {
      setCreateLoading(true);
    }
    actionFn(
      clientId,
      getCompanyOfficialBR(values, officialId),
      afterCbFn(
        res => {
          if (onUpdated) onUpdated();
          const latestOfficialId = get(res, 'data.id', '');
          const setPrimaryContact =
            values.is_primary && values.is_primary.length > 0;
          if (setPrimaryContact && latestOfficialId) {
            syncSetCOPrimaryContact(latestOfficialId);
          }
          if (!officialId) {
            syncSetSectionTotal(
              officials_count + 1,
              'company_officials',
              'officials_count'
            );
            handleLoadCO(status, () => {
              setCreateLoading(false);
              if (cb) cb();
            });
          } else if (cb) cb();
        },
        () => {
          if (!officialId) setCreateLoading(false);
        }
      ),
      officialId
    );
  };
  const handleLoadCO = (val, cb) => {
    const filters = {};
    if (val !== ALL_OPTION.value) {
      filters.status = val;
    }
    getCompanyOfficials({ id: clientId }, filters, afterCbFn(cb));
  };
  const handleSetStatusLoading = (id, val) => {
    setStatusLoading({
      ...statusLoading,
      [id]: val,
    });
  };
  const handleStatusChange = id => newStatus => {
    handleSetStatusLoading(id, true);
    updateCOStatus(
      clientId,
      id,
      { status: newStatus },
      afterCbFn(
        () => {
          if (onUpdated) onUpdated();
          handleLoadCO(status, () => {
            handleSetStatusLoading(id, false);
            setActiveKeys(activeKeys.filter(key => String(key) !== String(id)));
          });
        },
        () => handleSetStatusLoading(id, false)
      )
    );
  };

  useEffect(() => {
    if (activeTab === 1) {
      setSections(DEFAULT_SECTIONS);
      setEditKeys([]);
      setEditChanged({});
    }
  }, [activeTab]);
  useEffect(() => {
    setIsEditParent(sections.length > 0 || editKeys.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections, editKeys]);

  return (
    <div className={cn(classes.wrapper, className)}>
      <Header
        status={status}
        setStatus={val => {
          setStatus(val);
          handleLoadCO(val);
        }}
        onAdd={() => {
          if (!isAlreadyNew(sections)) {
            setSections([
              { label: 'New Official', id: NEW_KEY, isNew: true },
              ...sections,
            ]);
            setEditKeys([...editKeys, NEW_KEY]);
            setActiveKeys([...activeKeys, NEW_KEY]);
          }
        }}
        className={classes.header}
      />
      {notFound ? (
        <NotFound />
      ) : (
        <div className={classes.content}>
          <CollapseTable hideHeader section className={classes.sectionTable}>
            <Collapse
              destroyInactivePanel
              onChange={keys =>
                onKeyChangeFn(keys, activeKeys, editKeys, setActiveKeys)
              }
              activeKey={activeKeys}
            >
              {[...sections, ...companyOfficials].map(section => {
                const { isNew, id, is_primary } = section;
                const key = String(id);
                const label = getLabel(section);
                const isActive = getIsKey(activeKeys, key);
                const isEdit = getIsKey(editKeys, key);
                const sectionInnerProps = {
                  isNew,
                  clientId,
                  rootKey: key,
                  isEdit,
                  isActive,
                  confirmModal,
                  setIsEdit: edit => {
                    const newEditKeys = getEditKeys(editKeys, edit, key);
                    setEditKeys(newEditKeys);
                    if (!edit) {
                      handleEditChange(key, false, newEditKeys);
                    }
                    if (isNew && !edit) {
                      setSections(DEFAULT_SECTIONS);
                    }
                  },
                  data: section,
                  onFormChange: () => {
                    handleEditChange(key, true, editKeys);
                  },
                  openWarningModal,
                  loading: isNew ? createLoading : updateCOLoading[id],
                  statusLoading: statusLoading[id],
                  onSubmit: handleSubmit(id),
                  onStatusChange: handleStatusChange(id),
                };
                const pHeaderProps = { label, is_primary, isActive };
                return (
                  <Panel
                    forceRender={true}
                    header={<PanelHeader {...pHeaderProps} />}
                    key={key}
                    extra={
                      !isActive ? <StatusTag status={section.status} /> : null
                    }
                  >
                    <Section {...sectionInnerProps} />
                  </Panel>
                );
              })}
            </Collapse>
          </CollapseTable>
        </div>
      )}
    </div>
  );
};

export default CompanyOfficial;
