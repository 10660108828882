import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_ALL_ORDER_TYPE_LIST',
  'GET_ALL_ORDER_TYPES',
  'GET_ORDER_TYPE_DETAIL',
  'GET_ORDER_TYPE_LOGS',
  'CREATE_ORDER_TYPE',
  'UPDATE_ORDER_TYPE',
  'DELETE_ORDER_TYPE',
  'ADD_ORDER_TYPE_NOTE',
  'UPDATE_ORDER_TYPE_DOCUMENT'
);
