import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as authSelectors from 'store/selectors/auth';
import * as conversationDetailSelectors from 'store/selectors/conversationDetail';
import * as chatSelectors from 'store/selectors/chat';
import * as sharedSelectors from 'store/selectors/shared';
// ACTIONS
import * as chatActions from 'store/actions/chat';
import * as conversationDetailActions from 'store/actions/conversationDetail';
import * as chatInformationActions from 'store/actions/chatInformation';

export const useIndexData = () => {
  return {
    currentUser: authSelectors.useSelectCurrentUser(),
  };
};

export const useMainContentData = () => {
  return {
    currentUser: authSelectors.useSelectCurrentUser(),
    internalCount: chatSelectors.useSelectInternalCount(),
    clientCount: chatSelectors.useSelectClientCount(),
  };
};

export const useInternalChatData = () => {
  return {
    conversations: chatSelectors.useSelectInternalConversations(),
    currentUser: authSelectors.useSelectCurrentUser(),
  };
};
export const useClientChatData = () => {
  return {
    conversations: chatSelectors.useSelectClientConversations(),
    currentUser: authSelectors.useSelectCurrentUser(),
  };
};

export const useChatDetailData = () => {
  return {
    currentUser: authSelectors.useSelectCurrentUser(),
    messages: chatSelectors.useSelectMessages(),
    members: chatSelectors.useSelectChatMembers(),
    sendMessageLoading: chatSelectors.useSelectSendMessageLoading(),
  };
};
export const useMessageInputData = () => {
  return {
    sendMessageLoading: chatSelectors.useSelectSendMessageLoading(),
  };
};
export const useSettingContentData = () => {
  return {
    currentUser: authSelectors.useSelectCurrentUser(),
    conversationDetail: conversationDetailSelectors.useSelectConversationDetail(),
  };
};
export const useChatData = () => {
  return {
    conversationDetail: conversationDetailSelectors.useSelectConversationDetail(),
    resetConversationDetail: conversationDetailSelectors.useSelectResetActiveConversation(),
    currentUser: authSelectors.useSelectCurrentUser(),
    messages: chatSelectors.useSelectMessages(),
  };
};

export const useStartNewChatData = () => {
  return {
    internalConversations: chatSelectors.useSelectInternalConversations(),
    clientConversations: chatSelectors.useSelectClientConversations(),
    currentUser: authSelectors.useSelectCurrentUser(),
    members: chatSelectors.useSelectChatMembers(),
  };
};

export const useNewChatContentData = () => {
  return {
    internalSearchedMembers: chatSelectors.useSelectSearchInternalMembers(),
    clientSearchedMembers: chatSelectors.useSelectSearchClientMembers(),
    currentUser: authSelectors.useSelectCurrentUser(),
  };
};
export const useSearchBoxData = () => {
  return {
    departments: sharedSelectors.useSelectDepartments(),
    companies: chatSelectors.useSelectCompanies(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...chatActions,
    ...conversationDetailActions,
    ...chatInformationActions,
  });
};
