import React from 'react';
import { UserOutlined } from '@ant-design/icons';

import Steps, { Step } from 'components/Steps';
import Space from 'components/Space';

const StepComponent = () => {
  return (
    <>
      <Space direction={'vertical'} size={48} style={{ width: '100%' }}>
        <Steps current={1}>
          <Step title={'Finished'} description={'This is a finished step'} />
          <Step title={'In Progress'} description={'This is the description'} />
          <Step title={'Waiting'} description={'This is the description'} />
        </Steps>
        <Steps current={1} status={'error'}>
          <Step title={'Finished'} />
          <Step title={'Error'} />
          <Step title={'Waiting'} />
        </Steps>
        <Steps current={1} status={'error'}>
          <Step title={'Finished'} description={'This is a finished step'} />
          <Step title={'Error'} description={'This is the description'} />
          <Step title={'Waiting'} description={'This is the description'} />
        </Steps>
        <Steps current={1} size={'small'}>
          <Step title={'Finished'} />
          <Step title={'In Progress'} />
          <Step title={'Waiting'} />
        </Steps>
        <Steps current={1} size={'small'} status={'error'}>
          <Step title={'Finished'} description={'This is a finished step'} />
          <Step title={'Error'} description={'This is the description'} />
          <Step title={'Waiting'} description={'This is the description'} />
        </Steps>
        <Steps current={1} size={'small'} status={'error'}>
          <Step title={'Finished'} />
          <Step title={'Error'} />
          <Step title={'Waiting'} />
        </Steps>
        <Steps current={1}>
          <Step
            title={'Finished'}
            description={'This is a finished step'}
            icon={<UserOutlined />}
          />
          <Step
            title={'In Progress'}
            icon={<UserOutlined />}
            description={'This is the description'}
          />
          <Step
            title={'Waiting'}
            icon={<UserOutlined />}
            description={'This is the description'}
          />
        </Steps>
        <Steps current={1}>
          <Step title={'Finished'} icon={<UserOutlined />} />
          <Step title={'In Progress'} icon={<UserOutlined />} />
          <Step title={'Waiting'} icon={<UserOutlined />} />
        </Steps>
        <Steps current={1} progressDot={true}>
          <Step title={'Finished'} description={'This is a finished step'} />
          <Step
            title={'In Progress'}
            description={'This is the step in progress'}
          />
          <Step title={'Waiting'} description={'Waiting'} />
        </Steps>
        <Steps current={1} progressDot={true} status={'error'}>
          <Step title={'Finished'} description={'This is a finished step'} />
          <Step title={'Error'} description={'This is the step in progress'} />
          <Step title={'Waiting'} description={'Waiting'} />
        </Steps>
        <Steps current={1} progressDot={true}>
          <Step title={'Finished'} />
          <Step title={'In Progress'} />
          <Step title={'Waiting'} />
        </Steps>
        <Steps current={1} progressDot={true} status={'error'}>
          <Step title={'Finished'} />
          <Step title={'Error'} />
          <Step title={'Waiting'} />
        </Steps>
      </Space>
      <Space direction={'horizontal'} size={48}>
        <Steps direction="vertical" current={1}>
          <Step title="Finished" description="This is a description." />
          <Step title="In Progress" description="This is a description." />
          <Step title="Waiting" description="This is a description." />
        </Steps>
        <Steps direction="vertical" size="small" current={1}>
          <Step title="Finished" description="This is a description." />
          <Step title="In Progress" description="This is a description." />
          <Step title="Waiting" description="This is a description." />
        </Steps>
      </Space>
    </>
  );
};

export default StepComponent;
