import React from 'react';
import cn from 'classnames';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'components/FormFields';

import { AvailableFieldLine } from '../../Elements';
import Tree from './Tree';

import classes from './TreeTransfer.module.scss';

const TreeBox = ({
  isLeftSide,
  setAvailableSearch,
  setSelectedSearch,
  selectedFieldsCount,
  direction,
  treeProps,
  className,
  customHeaderContents = [],
  headerClassName,
  treeHeaderClassName,
  searchPlaceholder,
}) => {
  return (
    <div className={cn(classes.treeBox, className)}>
      <div
        className={cn(
          classes.treeHeader,
          {
            [classes.headerRight]: !isLeftSide,
          },
          treeHeaderClassName
        )}
      >
        <div>
          <Input
            size={'medium'}
            allowClear
            name="keyword"
            placeholder={
              searchPlaceholder ||
              `Search ${isLeftSide ? 'available' : 'selected'} fields`
            }
            onChange={e => {
              const setSearchFn = isLeftSide
                ? setAvailableSearch
                : setSelectedSearch;
              setSearchFn(e.target.value);
            }}
            prefix={<SearchOutlined />}
            isBlue
          />
          {selectedFieldsCount > 0 && (
            <div className={classes.selectedFieldsCount}>
              {selectedFieldsCount} selected field
              {selectedFieldsCount > 1 ? 's' : ''}
            </div>
          )}
        </div>
        <div>
          <AvailableFieldLine
            direction={direction}
            customContents={customHeaderContents}
            className={headerClassName}
          />
        </div>
      </div>
      <Tree {...treeProps} />
    </div>
  );
};

export default TreeBox;
