import React from 'react';

import { Steps as AntdSteps } from 'antd';

import classes from './Steps.module.scss';

const { Step } = AntdSteps;

const Steps = props => {
  return <AntdSteps {...props} className={classes.wrapper} />;
};
export { Step };
export default Steps;
