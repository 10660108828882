import React from 'react';
import cn from 'classnames';
import { useOptions } from 'helper/hooks';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  Title,
} from 'views/pages/ClientDetail/components/Elements';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { TITLE_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const VehicleTitle = ({ className, isEdit, form, data }) => {
  const { stateOptions } = useOptions();
  return (
    <div className={cn(classes.vehicleTitleSection, className)}>
      <Title text="Vehicle Title" />
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            <InputItem
              name={'title_receipt'}
              label="Title / Title Receipt"
              form={form}
              type="file"
            />
            <InputItem
              name={'title_number'}
              label="Title Number"
              form={form}
              addressGroupField
            />
            <InputItem
              name={'truck_state_id'}
              label="State"
              form={form}
              addressGroupField
              type="select"
              options={stateOptions}
            />
            <InputItem
              name={'purchase_price'}
              label="Purchase Price"
              form={form}
              type="price"
              addressGroupField
              prefix={'$'}
              placeholder="000,000,000.00"
            />
            <InputItem
              name={'factory_price'}
              label="Factory Price"
              form={form}
              type="price"
              addressGroupField
              prefix={'$'}
              placeholder="000,000,000.00"
            />
            <InputItem
              name={'purchase_date'}
              label="Purchase Date"
              form={form}
              type="date"
              placeholder="01/01/2000"
            />
            <InputItem
              name={'bill_of_sale'}
              label="Bill of Sale"
              form={form}
              type="file"
            />
            <InputItem
              name={'vehicle_inspection'}
              label="Vehicle Inspection"
              form={form}
              type="file"
            />
            <InputItem
              name={'vehicle_inspection_exp_date'}
              label="Vehicle Inspection Expiration Date"
              form={form}
              type="date"
              placeholder="01/01/2000"
            />
          </FormSection>
        ) : (
          <ListInfo objKeys={TITLE_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default VehicleTitle;
