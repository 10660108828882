import React from 'react';
import cn from 'classnames';
import find from 'lodash/find';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  Title,
  ShouldUpdateWrapper,
} from 'views/pages/ClientDetail/components/Elements';
import { useOptions } from 'helper/hooks';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { CONTACT_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const Contact = ({ className, isEdit, form, data, disabled }) => {
  const { scTitleOptions } = useOptions();

  return (
    <div className={cn(classes.contactSection, className)}>
      <Title text="Contact" />
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            <InputItem
              name={'first_name'}
              label="First Name"
              form={form}
              required
              addressGroupField
              disabled={disabled}
            />
            <InputItem
              name={'last_name'}
              label="Last Name"
              form={form}
              required
              addressGroupField
              disabled={disabled}
            />
            <InputItem
              name={'title_id'}
              label="Title"
              required
              form={form}
              type="select"
              options={scTitleOptions}
            />
            <ShouldUpdateWrapper>
              {() => {
                const values = form.getFieldsValue();
                const foundOption =
                  find(scTitleOptions, {
                    value: values.title_id,
                  }) || {};
                if (foundOption.label !== 'Other') {
                  return <></>;
                }
                return (
                  <>
                    <InputItem name={'other'} label="Other" form={form} />
                  </>
                );
              }}
            </ShouldUpdateWrapper>
            <InputItem
              name={'phone'}
              label="Phone"
              form={form}
              type="phone"
              required
              disabled={disabled}
            />
            <InputItem
              name={'email'}
              label="Email"
              form={form}
              disabled={disabled}
            />
          </FormSection>
        ) : (
          <ListInfo objKeys={CONTACT_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default Contact;
