export const getLatestMessageText = (obj, displayName) => {
  let message = `${obj.text || obj.attachment_name || ''}`;
  if (displayName) {
    if (obj.first_name) {
      message = `${obj.first_name || ''}: ${message}`;
    } else {
      message = '';
    }
  }
  return message;
};
