export const NEW_KEY = 'new_location';
export const NORMAL_FIELDS = [
  'address',
  'apt',
  'city',
  'county',
  'zip_code',
  'mailing_address',
  'mailing_apt',
  'mailing_city',
  'mailing_county',
  'mailing_zip_code',
];
export const NULL_FIELDS = ['state_id', 'mailing_state_id'];
export const NUMBER_FIELDS = ['zip_code', 'mailing_zip_code'];
export const DATE_FIELDS = [];
export const FILE_FIELDS = [];
export const IS_SAME_ADDRESS_FIELD = 'is_same_as_physical';
export const BOOLEAN_FIELDS = ['is_primary', IS_SAME_ADDRESS_FIELD];
export const MAILING_ADDRESS_FIELDS = [
  'mailing_address',
  'mailing_apt',
  'mailing_city',
  'mailing_county',
  'mailing_zip_code',
];
export const BODY_REQUEST_NORMAL_FIELDS = [
  'address',
  'apt',
  'city',
  'county',
  'zip_code',
  'mailing_address',
  'mailing_apt',
  'mailing_city',
  'mailing_county',
  'mailing_zip_code',
  'state_id',
  'mailing_state_id',
];
export const DEFAULT_TRUCK = {
  key: 'section 1',
  id: 10,
  label: '435',
  status: 'active',
};
export const DEFAULT_SECTIONS = [];

export const PROFILE_OBJ_KEYS = [
  {
    label: 'Physical Address',
    key: 'address',
    type: 'value',
  },
  {
    label: 'Apt.',
    key: 'apt',
    type: 'value',
    empty: true,
  },
  {
    label: 'Address',
    addressGroup: true,
    alignLeft: true,
    fields: [
      {
        label: 'City',
        key: 'city',
        type: 'value',
      },
      // {
      //   label: 'County',
      //   key: 'county',
      //   type: 'value',
      // },
      {
        label: 'State',
        key: 'state',
        type: 'value',
      },
      {
        label: 'Zip Code',
        key: 'zip_code',
        type: 'value',
      },
    ],
  },
  {
    label: 'Primary Address',
    key: 'is_primary',
    type: 'checkbox',
    fullWidth: true,
  },
  {
    label: 'Mailing address same as physical address',
    key: 'is_same_as_physical',
    type: 'checkbox',
    fullWidth: true,
    dependField: 'is_same_as_physical',
    dependFn: val => !val,
  },
  {
    label: 'Mailing Address',
    key: 'mailing_address',
    type: 'value',
    dependField: 'is_same_as_physical',
    dependFn: val => !!val,
  },
  {
    label: 'Apt.',
    key: 'mailing_apt',
    type: 'value',
    empty: true,
    dependField: 'is_same_as_physical',
    dependFn: val => !!val,
  },
  {
    label: 'Address',
    addressGroup: true,
    alignLeft: true,
    dependField: 'is_same_as_physical',
    dependFn: val => !!val,
    fields: [
      {
        label: 'City',
        key: 'mailing_city',
        type: 'value',
      },
      // {
      //   label: 'County',
      //   key: 'mailing_county',
      //   type: 'value',
      // },
      {
        label: 'State',
        key: 'mailing_state',
        type: 'value',
      },
      {
        label: 'Zip Code',
        key: 'mailing_zip_code',
        type: 'value',
      },
    ],
  },
];
