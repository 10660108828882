import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import Activity from 'views/pages/ClientDetail/components/Activity';
import toast from 'helper/toast';
import { getErrorMessageFromObj } from 'utils/object';
import LoadingSpin from './LoadingSpin';
import MainContent from './MainContent';
import Header from './Header';
import getPermission from './permissions';
import { useActions, useIndexData } from './selectorData';
import classes from './ClientOrderDetail.module.scss';

const ClientOrderDetail = ({ match }) => {
  const clientId = get(match, 'params.id', '');
  const orderId = get(match, 'params.orderId', '');
  const {
    getClientDetail,
    getClientOrder,
    getClientDetailLog,
    addLog,
    updateClientOrderStatus,
    getClientOrderFieldValues,
  } = useActions();
  const {
    activityLog,
    detailData,
    activityLogLoading,
    addLogLoading,
    currentUser,
  } = useIndexData();
  const permission = getPermission(currentUser.role, currentUser);
  const isAdmin = permission.isAdmin;
  const [isActivityCollapsed, setIsActivityCollapsed] = useState(false);
  const handleStatusChange = status => {
    if (isAdmin) {
      updateClientOrderStatus(clientId, orderId, status, (res, err) => {
        if (res) {
          getClientOrder(clientId, orderId, null, true);
          if (isAdmin) getClientDetailLog({ id: clientId });
        } else {
          toast.error(getErrorMessageFromObj(err)[0]);
        }
      });
    }
  };

  useEffect(() => {
    if (clientId) {
      getClientDetail({ id: clientId });
      if (isAdmin) getClientDetailLog({ id: clientId });
      if (orderId) {
        getClientOrder(clientId, orderId);
        getClientOrderFieldValues(clientId, orderId);
      }
    }
    // eslint-disable-next-line
  }, []);
  return (
    <LoadingSpin>
      <div
        className={cn(classes.wrapper, {
          [classes.isActivityCollapsed]: isActivityCollapsed,
          [classes.hideActivity]: !isAdmin,
        })}
      >
        <div className={classes.leftContent}>
          <Header isAdmin={isAdmin} />
          <div className={classes.contentWrapper}>
            <MainContent
              onStatusChange={handleStatusChange}
              clientId={clientId}
              orderId={orderId}
              isAdmin={isAdmin}
            />
            {isAdmin && (
              <Activity
                className={classes.activity}
                isCollapsed={isActivityCollapsed}
                setIsCollapsed={setIsActivityCollapsed}
                activityLog={activityLog}
                name={detailData?.legal_name || ''}
                loading={activityLogLoading || addLogLoading}
                onAddNote={(val, cb) => {
                  addLog(
                    clientId,
                    {
                      note: val,
                    },
                    (res, err) => {
                      if (res) {
                        if (cb) cb();
                        if (isAdmin) getClientDetailLog({ id: clientId }, {});
                      } else {
                        toast.error(getErrorMessageFromObj(err)[0]);
                      }
                    }
                  );
                }}
              />
            )}
          </div>
        </div>
      </div>
    </LoadingSpin>
  );
};

export default ClientOrderDetail;
