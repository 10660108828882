import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import omit from 'lodash/omit';
import { useHistory } from 'react-router-dom';
import {
  CheckCircleFilled,
  EditFilled,
  InfoCircleOutlined,
} from '@ant-design/icons';
import PageHeader from 'components/PageHeader';
import PageContent from 'components/PageContent';
import Spin from 'components/Spin';
import Form, { useForm, FormItem } from 'components/Form';
import { getErrorMessageFromObj } from 'utils/object';
import { getProfileDateTime } from 'utils/date';
import TopBarContent from 'layouts/Main/TopBarContent';
import { Input } from 'components/FormFields';
import toast from 'helper/toast';
import { validateForm, scrollToErrorField } from 'utils/form';
import Button, { ButtonColors } from 'components/Button';
import { useModal } from 'components/Modal';
import { confirmModalBlueConfig, getConfirmModal } from 'utils/modal';
import ProfilePicture from './ProfilePicture';
import InformationForm from './InformationForm';
import {
  getMyProfileBodyRequest,
  getInitialValues,
  validateObj,
} from './utils';
import { useIndexData, useActions } from './selectorData';
import classes from './MyProfile.module.scss';

let deletePassword = '';

const MyProfile = () => {
  const history = useHistory();
  const { currentUser, updateProfileLoading } = useIndexData();
  const { updateProfile, deleteAccount, doLogout, createPIN } = useActions();
  const [modal, contextHolder] = useModal();
  const confirmModal = getConfirmModal(modal, confirmModalBlueConfig);
  const [avatarFinalSrc, setAvatarFinalSrc] = useState('');
  const [finalFile, setFinalFile] = useState({});
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [form] = useForm();
  const handleCancelForm = () => {
    setIsEdit(false);
    setAvatarFinalSrc('');
    setFinalFile({});
    form.setFieldsValue({
      password: '',
      confirmPassword: '',
    });
  };
  const checkSaveChangeWarning = () => {
    if (isFormChanged) {
      confirmModal(
        'Do you want to leave without saving?',
        <div>Any unsaved changes will be discarded.</div>,
        () => {
          handleCancelForm();
        },
        {
          type: 'confirm',
          onCancel: () => {},
          okText: 'Leave Without Saving',
          cancelText: 'Continue Editing',
          revertButton: true,
          centered: true,
          className: classes.myProfileWarningModal,
        }
      );
    } else {
      handleCancelForm();
    }
  };
  const onSubmit = values => {
    if (!isEdit) {
      setIsEdit(true);
      setAvatarFinalSrc(currentUser?.profile_pic);
    } else {
      const fieldErrors = validateForm(validateObj(), values);
      if (fieldErrors.length > 0) {
        form.setFields(fieldErrors);
        scrollToErrorField();
      } else {
        if (values.company_pin_number) {
          createPIN({
            pin: values.company_pin_number,
          });
        }
        updateProfile(
          getMyProfileBodyRequest(
            omit(values, ['company_pin_number']),
            finalFile,
            currentUser.profile_pic,
            avatarFinalSrc
          ),
          (res, err) => {
            if (res) {
              handleCancelForm();
            } else {
              const errorMessage = getErrorMessageFromObj(err)[0];
              toast.error(errorMessage);
            }
          }
        );
      }
    }
  };
  useEffect(() => {
    if (isEdit && form) {
      form.setFieldsValue(getInitialValues(currentUser));
      setIsFormChanged(false);
    }
    // eslint-disable-next-line
  }, [isEdit])

  return (
    <Spin
      className={cn(classes.wrapper)}
      spinning={deleteLoading}
      tip={'Deleting...'}
    >
      <TopBarContent>
        <PageHeader
          title={null}
          breadcrumbs={[
            {
              label: 'My Profile',
            },
          ]}
        />
      </TopBarContent>
      <div className={classes.innerWrapper}>
        <PageContent initFullHeight className={classes.mainContent}>
          <Form
            onFinish={onSubmit}
            initialValues={getInitialValues(currentUser)}
            form={form}
            className={classes.formContent}
            onValuesChange={() => {
              setIsFormChanged(true);
            }}
          >
            <ProfilePicture
              isEdit={isEdit}
              confirmModal={confirmModal}
              profilePic={currentUser.profile_pic}
              avatarFinalSrc={avatarFinalSrc}
              setAvatarFinalSrc={setAvatarFinalSrc}
              finalFile={finalFile}
              setFinalFile={setFinalFile}
              onDelete={cb => {
                setAvatarFinalSrc('');
                if (cb) cb();
              }}
              className={classes.myProfileAvatar}
            />
            <InformationForm
              form={form}
              isEdit={isEdit}
              currentUser={currentUser}
              onDeleteAccountClick={() => {
                deletePassword = '';
                setDeleteLoading(false);
                confirmModal(
                  'Warning:',
                  <div>
                    This action is not reversible. By deleting your account,
                    your data will be deleted and you’ll no longer have access
                    to your account. Do you wish to proceed?
                  </div>,
                  () => {
                    confirmModal(
                      'To delete your account, please enter your password to confirm.',
                      <div>
                        <input
                          type="password"
                          name="password"
                          style={{ position: 'absolute', left: '-99999px' }}
                        />
                        <FormItem label="Password">
                          <Input
                            size="large"
                            name="password"
                            placeholder=""
                            onChange={e => {
                              const value = e.target.value;
                              deletePassword = value;
                            }}
                            type="password"
                            autoComplete="off"
                            isBlue
                          />
                        </FormItem>
                      </div>,
                      () => {},
                      {
                        type: 'confirm',
                        onCancel: () => {},
                        onOk: closeFn => {
                          if (!!deletePassword) {
                            closeFn();
                            setDeleteLoading(true);
                            deleteAccount(deletePassword, (res, err) => {
                              setDeleteLoading(false);
                              if (res) {
                                doLogout(() => {
                                  toast.success(
                                    'User account deleted successfully'
                                  );
                                  history.push('/login');
                                });
                              } else {
                                const errMsg =
                                  err?.details &&
                                  typeof err.details === 'string'
                                    ? err.details
                                    : 'Delete account failed. Please try again!';
                                toast.error(errMsg);
                              }
                            });
                          }
                        },
                        okText: 'Delete My Account',
                        cancelText: 'Cancel',
                        centered: true,
                        className: classes.deleteAccountModal,
                        icon: <InfoCircleOutlined />,
                      }
                    );
                  },
                  {
                    type: 'confirm',
                    onCancel: () => {},
                    okText: 'Delete My Account',
                    cancelText: 'Cancel',
                    centered: true,
                    className: classes.deleteAccountModal,
                    icon: <InfoCircleOutlined />,
                  }
                );
              }}
            />
            <div className={classes.actions}>
              <Button
                type={'primary'}
                size={'large'}
                htmlType="submit"
                loading={updateProfileLoading}
                color={ButtonColors.BLUE}
                leftIcon={isEdit ? <CheckCircleFilled /> : <EditFilled />}
              >
                {isEdit ? 'Save' : 'Edit Profile'}
              </Button>
              {isEdit && (
                <Button
                  type={'ghost'}
                  size={'large'}
                  htmlType="button"
                  disabled={updateProfileLoading}
                  onClick={checkSaveChangeWarning}
                >
                  Cancel
                </Button>
              )}
            </div>
          </Form>
          <div className={classes.lastUpdated}>
            Last Updated:{' '}
            {getProfileDateTime(
              currentUser.updated_at || currentUser.created_at
            )}
          </div>
        </PageContent>
      </div>
      {contextHolder}
    </Spin>
  );
};

export default MyProfile;
