import React from 'react';
import { CheckCircleFilled, EditOutlined } from '@ant-design/icons';
import Button, { ButtonColors } from 'components/Button';
import { useButtonActionsData } from './selectorData';
import classes from './Detail.module.scss';

const ButtonActions = ({
  setIsEdit,
  isEdit,
  form,
  onSubmit,
  isFormChanged,
  confirmModal,
  finalFile,
  permission,
}) => {
  const {
    updateLoading,
    updateCompanyTeamMemberLoading,
  } = useButtonActionsData();

  if (!permission.teamAction) {
    return '';
  }

  return (
    <div className={classes.actions}>
      {isEdit && (
        <Button
          type={'ghost'}
          size={'large'}
          htmlType="button"
          onClick={() => {
            if (isFormChanged || finalFile.name) {
              confirmModal(
                'Do you want to leave without saving?',
                <div>Any unsaved changes will be discarded.</div>,
                () => {
                  setIsEdit(false);
                },
                {
                  type: 'confirm',
                  onCancel: () => {},
                  okText: 'Leave Without Saving',
                  cancelText: 'Continue Editing',
                  centered: true,
                  revertButton: true,
                  className: classes.teammateDetailWarningModal,
                }
              );
            } else {
              setIsEdit(false);
            }
          }}
        >
          Cancel
        </Button>
      )}
      <Button
        type={'primary'}
        size={'large'}
        htmlType="button"
        loading={
          permission.inviteTeamAsClient
            ? updateCompanyTeamMemberLoading
            : updateLoading
        }
        color={ButtonColors.BLUE}
        onClick={() => {
          if (!isEdit) {
            setIsEdit(true);
          } else {
            const values = form.getFieldsValue();
            onSubmit(values);
          }
        }}
        leftIcon={isEdit ? <CheckCircleFilled /> : <EditOutlined />}
      >
        {isEdit ? 'Save' : 'Edit'}
      </Button>
    </div>
  );
};

export default ButtonActions;
