import Request from './request';

export const apiGetUnderwritingTabList = async (
  id,
  filters = {},
  tab = 'background'
) => {
  return Request.call({
    url: `/company/${id}/underwriting/${tab}/list`,
    method: 'GET',
    params: filters,
  });
};

export const apiCreateUpdateUnderwritingTab = async (
  id,
  data,
  tab = 'background'
) => {
  return Request.call({
    url: `/company/${id}/underwriting/${tab}`,
    method: 'POST',
    data,
  });
};
export const apiUpdateUnderwritingRecordStatus = async (
  id,
  data,
  recordId,
  tab = 'background'
) => {
  return Request.call({
    url: `/company/${id}/underwriting/${tab}/${recordId}/status`,
    method: 'POST',
    data,
  });
};
