import React from 'react';
import {
  UserOutlined,
  PictureOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import cn from 'classnames';
import Spin from 'components/Spin';
import ConfirmDeleteAvatarModal from './components/ConfirmDeleteAvatarModal';
import classes from './MyProfile.module.scss';

export const DefaultAvatar = ({ className, isPicture, defaultImg }) => {
  return (
    <div className={cn(classes.defaultAvatar, className)}>
      {defaultImg || (isPicture ? <PictureOutlined /> : <UserOutlined />)}
    </div>
  );
};
export const ProfilePicDisplay = ({
  isEdit,
  avatarFinalSrc,
  profilePic,
  updateText,
  loading,
  defaultImg,
  onDelete,
  nonStyle,
  isDelete,
  setIsDelete,
}) => {
  return (
    <>
      {isEdit ? (
        <>
          {avatarFinalSrc ? (
            <>
              {onDelete && (
                <span
                  className={cn(classes.avatarDeleteIcon, {
                    [classes.nonStyle]: nonStyle,
                  })}
                  onClick={e => {
                    setIsDelete(true);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <CloseOutlined />
                </span>
              )}
              <img src={avatarFinalSrc} alt="profile" />
            </>
          ) : (
            <DefaultAvatar isPicture={updateText} defaultImg={defaultImg} />
          )}
        </>
      ) : (
        <>
          {profilePic ? (
            <img src={profilePic} alt="profile" />
          ) : (
            <DefaultAvatar isPicture={updateText} defaultImg={defaultImg} />
          )}
        </>
      )}
      {updateText && (
        <span
          className={cn(classes.updateText, {
            [classes.hasImage]: !!avatarFinalSrc,
          })}
        >
          Update
        </span>
      )}
      {loading && (
        <span className={classes.profileImageLoading}>
          <Spin icon />
        </span>
      )}
      <ConfirmDeleteAvatarModal
        isOpen={isDelete}
        avatarFinalSrc={avatarFinalSrc}
        onClose={() => {
          setIsDelete(false);
        }}
        onSubmit={() => {
          onDelete(() => {
            setIsDelete(false);
          });
        }}
      />
    </>
  );
};
