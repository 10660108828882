import React from 'react';
import { InputNumber as AntdInputNumber } from 'antd';
import { PATTERN } from 'helper/const';
import cx from 'classnames';

import classes from './InputNumber.module.scss';

const InputNumber = props => {
  const {
    className,
    isInt,
    isFloat,
    hideHandler,
    fullWidth,
    textLeft,
    suffix,
    prefix,
    form,
    resetFieldNames = [],
    customValidateName = '',
    ...other
  } = props;
  const isEmpty = !other.value && other.value !== 0;
  const addProps = {};
  if (isInt || isFloat) {
    addProps.onKeyPress = e => {
      const specialCharRegex = new RegExp(
        isInt ? PATTERN.NUMBER : PATTERN.FLOAT
      );
      const pressedKey = String.fromCharCode(
        !e.charCode ? e.which : e.charCode
      );
      if (!specialCharRegex.test(pressedKey)) {
        e.preventDefault();
        return false;
      }
    };
  }
  const content = (
    <AntdInputNumber
      {...other}
      {...addProps}
      className={cx(
        classes.wrapper,
        {
          [classes.hideHandler]: hideHandler,
          [classes.fullWidth]: fullWidth,
          [classes.textLeft]: textLeft,
          [classes.hasPrefix]: !!prefix,
        },
        className
      )}
      onChange={value => {
        if (props.onChange) {
          props.onChange(value);
        }
        if (value && form) {
          const resetFields = resetFieldNames.map(f => ({
            name: f,
            errors: null,
          }));
          form.setFields([
            ...resetFields,
            {
              name: customValidateName || props.name,
              errors: null,
            },
          ]);
        }
      }}
    />
  );
  if (suffix || prefix) {
    return (
      <div
        className={cx(classes.rootWrapper, {
          [classes.isEmpty]: isEmpty,
        })}
      >
        {prefix && <span className={classes.prefix}>{prefix}</span>}
        {content}
        {suffix && <span className={classes.suffix}>{suffix}</span>}
      </div>
    );
  }
  return <>{content}</>;
};

export default InputNumber;
