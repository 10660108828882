import {
  useReducerData,
  useReducerTotal,
  useReducerLoading,
  useReducerIsMore,
} from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// ACTIVE_REMINDERS
export const useSelectActiveRemindersLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.ACTIVE_REMINDERS
  );
};
export const useSelectActiveRemindersTotal = () => {
  return useReducerTotal(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.ACTIVE_REMINDERS,
    0
  );
};
export const useSelectActiveRemindersIsMore = () => {
  return useReducerIsMore(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.ACTIVE_REMINDERS
  );
};
export const useSelectActiveReminders = () => {
  return useReducerData(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.ACTIVE_REMINDERS
  );
};
// INACTIVE_REMINDERS
export const useSelectInactiveRemindersLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.INACTIVE_REMINDERS
  );
};
export const useSelectInactiveRemindersTotal = () => {
  return useReducerTotal(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.INACTIVE_REMINDERS,
    0
  );
};
export const useSelectInactiveRemindersIsMore = () => {
  return useReducerIsMore(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.INACTIVE_REMINDERS
  );
};
export const useSelectInactiveReminders = () => {
  return useReducerData(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.INACTIVE_REMINDERS
  );
};
// ACTIVE_COMPANY_REMINDERS
export const useSelectActiveCompanyRemindersLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.ACTIVE_COMPANY_REMINDERS
  );
};
export const useSelectActiveCompanyReminders = () => {
  return useReducerData(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.ACTIVE_COMPANY_REMINDERS,
    {}
  );
};
// DISMISSED_COMPANY_REMINDERS
export const useSelectDismissedCompanyRemindersLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.DISMISSED_COMPANY_REMINDERS
  );
};
export const useSelectDismissedCompanyReminders = () => {
  return useReducerData(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.DISMISSED_COMPANY_REMINDERS,
    {}
  );
};
// COMPLETED_COMPANY_REMINDERS
export const useSelectCompletedCompanyRemindersLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.COMPLETED_COMPANY_REMINDERS
  );
};
export const useSelectCompletedCompanyReminders = () => {
  return useReducerData(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.COMPLETED_COMPANY_REMINDERS,
    {}
  );
};
// REMINDER_DETAIL
export const useSelectReminderDetail = () => {
  return useReducerData(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.REMINDER_DETAIL,
    {}
  );
};
export const useSelectReminderDetailLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.REMINDER_DETAIL
  );
};
// CREATE_REMINDER
export const useSelectCreateReminderLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.CREATE_REMINDER
  );
};
// UPDATE_REMINDER
export const useSelectUpdateReminderLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.UPDATE_REMINDER
  );
};
// DELETE_REMINDER
export const useSelectDeleteReminderLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.DELETE_REMINDER
  );
};
// CHANGE_REMINDER_STATUS
export const useSelectChangeReminderStatusLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.CHANGE_REMINDER_STATUS,
    {}
  );
};
// SUBSCRIBE_REMINDER_EMAIL
export const useSelectSubscribeReminderEmailLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.REMINDER,
    REDUCER_ATTRIBUTES.REMINDER.SUBSCRIBE_REMINDER_EMAIL,
    false
  );
};
