import React from 'react';
import get from 'lodash/get';
import cn from 'classnames';
import { scrollChatContentToBottom } from 'utils/index';
import { getDisplayMembers, getDisplayMessages } from 'utils/chat';
import { CHAT_TYPES } from 'helper/const';
import { Contact, Messages } from './components/Elements';
import MessageInput from './MessageInput';
import SettingContent from './SettingContent';
import {
  showErrorMessage,
  sendMessageFn,
  updateChatSettingFn,
} from './actionUtils';
import { useActions, useChatDetailData } from './selectorData';
import classes from './Chat.module.scss';

const ChatDetail = ({
  isCollapse,
  isInitEdit,
  setIsInitEdit,
  isEditing,
  setIsEditing,
  isNewContact,
  handleResetIsNewContact,
  detailObj,
  onSaveContactName,
  confirmModal,
  isClientTab,
  setIsEditContact,
  setSelectedContacts,
  setDetailObj,
  searchMemberAction,
  renderTab,
  permission,
}) => {
  const {
    updateChatSettings,
    sendMessage,
    getMessages,
    getInternalConversations,
  } = useActions();
  const { messages, currentUser, members } = useChatDetailData();
  const chattingIndividualMember = members.filter(
    m => m.user_id !== currentUser.id
  );
  const displayMembers = getDisplayMembers(members);
  const {
    name,
    isIndividual,
    type,
    conversation_id,
    group,
    company_name,
    department,
    client_name,
    client_profile_pic,
  } = detailObj || {};
  const displayMessages = getDisplayMessages({
    messages,
    currentUser,
  });
  const isClientDepartment = type === CHAT_TYPES.CLIENT_DEPARTMENT;
  const detailAvatar =
    type === CHAT_TYPES.INTERNAL_CUSTOM
      ? 'group'
      : isIndividual
      ? get(chattingIndividualMember, '[0].profile_pic', '')
      : '';
  const handleLoadMessage = conversationId => {
    getMessages(
      conversationId,
      {
        conversation_id: conversationId,
      },
      () => {
        scrollChatContentToBottom();
      }
    );
  };
  const cancelEditing = () => {
    setIsEditing(false);
    if (!!isNewContact) {
      setIsInitEdit(false);
      handleResetIsNewContact();
    } else {
      setIsInitEdit(isEditing);
    }
  };

  return (
    <div className={classes.chatDetail}>
      <Contact
        isCollapse={isCollapse}
        name={isClientDepartment ? client_name : name}
        isDetail
        message={''}
        avatar={isClientDepartment ? client_profile_pic : detailAvatar}
        className={classes.detailContact}
        department={
          isClientTab
            ? permission.showDepartmentAtIndividual
              ? group
              : department
            : group
        }
        departmentColor={
          isClientTab
            ? permission.showDepartmentAtIndividual
              ? group
              : department
            : group
        }
        role={company_name}
        hideRoleAtName={isClientTab}
        isMultipleAvatar={isClientDepartment}
        hideUnread
        detailObj={{
          onInitEdit: () => {
            setIsInitEdit(true);
          },
          onEdit: () => {
            setIsInitEdit(false);
            setIsEditing(true);
          },
          onCancel: cancelEditing,
          onSave: val => {
            updateChatSettingFn({
              updateChatSettings,
              conversation_id,
              val,
              cb: (res, err) => {
                if (res) {
                  cancelEditing();
                  onSaveContactName(val);
                  getInternalConversations();
                } else {
                  showErrorMessage(err);
                }
              },
            });
          },
          isInitEdit,
          isEditing: isEditing || (!!isNewContact && !isIndividual),
          isIndividual,
        }}
        isClient={isClientTab && !permission.showDepartmentAtIndividual}
        notEditName={
          isIndividual || isClientTab || type === CHAT_TYPES.INTERNAL_DEPARTMENT
        }
      />
      {isInitEdit || isEditing ? (
        <SettingContent
          confirmModal={confirmModal}
          onUpdateContact={() => {
            setIsEditContact(true);
            setSelectedContacts(displayMembers);
            searchMemberAction({
              search: '',
            });
          }}
          displayMembers={displayMembers}
          setDetailObj={setDetailObj}
          detailObj={detailObj}
          setIsInitEdit={setIsInitEdit}
          setIsEditing={setIsEditing}
          isClientTab={isClientTab}
        />
      ) : (
        <>
          <div className={cn(classes.detailContent, classes.messages)}>
            <Messages messages={displayMessages} renderTab={renderTab} />
          </div>
          <MessageInput
            confirmModal={confirmModal}
            onSend={({ message, attachments, isSync }, cb) => {
              sendMessageFn({
                conversation_id,
                message,
                attachments,
                sendMessage,
                handleLoadMessage,
                cb,
                showErrorMessage,
                isSync,
                permission,
                renderTab,
              });
            }}
          />
        </>
      )}
    </div>
  );
};

export default ChatDetail;
