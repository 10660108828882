import React, { useState } from 'react';
import uniq from 'lodash/uniq';
import unionBy from 'lodash/unionBy';
import { Checkbox } from 'components/FormFields';
import { setCookie, getCookie } from 'utils/cookie';
import { REQUIRED, DEFAULT_REQUIRED, ALL_NODE_KEY } from './constants';
import {
  getCheckedDataOfSelectAllWithSearch,
  getAllSelectedKeys,
  getSelectedKeyWithOption,
  getUpdatedNodeWithParent,
  getAllParents,
} from './utils';
import TreeTransfer from './components/TreeTransfer';
import classes from './NewOrderType.module.scss';

const cookieName = 'available-field-required-optional-change';
let checked = false;

export const FieldsHeader = ({ children }) => {
  return (
    <div className={classes.fieldsHeader}>
      {children || (
        <>
          <h2>Available Fields</h2>
          <h2>Selected Fields</h2>
        </>
      )}
    </div>
  );
};

const NewOrderTypeFields = ({
  confirmModal,
  targetKeys,
  setTargetKeys,
  treeData,
  setTreeData,
  initTreeData,
  haftKeys,
  setHaftKeys,
}) => {
  const [expandKeys, setExpandKeys] = useState([]);
  const [availableSearch, setAvailableSearch] = useState('');
  const [selectedSearch, setSelectedSearch] = useState('');
  const onChange = keys => {
    setTargetKeys(uniq(keys));
  };
  const handleTreeDataChange = (
    nodeData,
    val,
    nodeKey,
    selectedChildNodes = []
  ) => {
    const uniqSelectChild = unionBy(selectedChildNodes, 'key');
    const { key, parent } = nodeData || {};
    let oldTree = [];
    if (nodeKey !== ALL_NODE_KEY) {
      oldTree = [...treeData];
      if (parent) {
        // use getAllParents if we need to update required/optional for parent node
        oldTree = getUpdatedNodeWithParent(oldTree, key, val);
        oldTree = getUpdatedNodeWithParent(oldTree, key, val, true);
        if (val === null) {
          // When remove any node, will remove required/optional for all parent nodes
          const allParents = getAllParents(parent);
          allParents.map(pKey => {
            oldTree = getUpdatedNodeWithParent(oldTree, pKey, null);
            return true;
          });
        }
      } else {
        // Update all child nodes with required/optional
        getSelectedKeyWithOption(
          oldTree,
          key,
          initTreeData,
          uniqSelectChild,
          val
        );
      }
      // If remove any node or update required/optional of any node
      // remove required/optional of ALL_NODE_KEY
      if (val === null || val !== oldTree[0].add) {
        oldTree[0].add = null;
      }
    } else {
      // If ALL_NODE_KEY
      // Update all child nodes
      // Get all node keys
      const keys = [];
      oldTree = getAllSelectedKeys(keys, treeData, val);
      if ([null, DEFAULT_REQUIRED].includes(val)) {
        // If remove, remove all
        // If add, add all
        setTargetKeys(val === DEFAULT_REQUIRED ? uniq(keys) : []);
      }
    }
    // Update tree nodes
    setTreeData(oldTree);
  };
  const handleDontAsk = () => {
    if (checked) {
      setCookie(cookieName, 'true', 356);
      checked = false;
    }
  };
  const handleSelectTree = (itemData, val, cb) => {
    const txt = val === REQUIRED ? 'Required' : 'Optional';
    const { parent } = itemData || {};
    const dontAskCookie = getCookie(cookieName);
    if (!parent && dontAskCookie !== 'true') {
      confirmModal(
        'Change requirement settings?',
        <div>
          Are you sure you want to change all fields in this category to
          {` ${txt}`}?
          <Checkbox
            label="Don't ask me this again."
            onChange={e => {
              checked = e.target.checked;
            }}
            className={classes.notAskMeCheckbox}
          />
        </div>,
        () => {
          handleDontAsk();
          if (cb) cb();
        },
        {
          type: 'confirm',
          onCancel: () => {
            handleDontAsk();
          },
          okText: `Change To ${txt}`,
          cancelText: 'Keep As Is',
          centered: true,
          className: classes.confirmationModal,
        }
      );
    } else if (cb) cb();
  };
  const handleHaftChange = (key, isRemove, removeHalfKeys = []) => {
    if (!isRemove) {
      if (typeof key === 'object') {
        const newHalfKeys = uniq([...haftKeys, ...key]).filter(
          k => !removeHalfKeys.includes(k)
        );
        setHaftKeys(newHalfKeys);
      } else {
        if (!haftKeys.includes(key)) setHaftKeys([...haftKeys, key]);
      }
    } else {
      if (key === ALL_NODE_KEY) {
        setHaftKeys([]);
      } else {
        setHaftKeys(haftKeys.filter(k => k !== key));
      }
    }
  };
  const handleSelectAllWithSearch = currentTreeData => {
    const checkedData = getCheckedDataOfSelectAllWithSearch(
      currentTreeData,
      initTreeData,
      treeData,
      targetKeys
    );
    const { tKeys, hKeys, oTreeData } = checkedData;
    setTargetKeys(uniq([...targetKeys, ...tKeys]));
    setHaftKeys([...haftKeys, ...hKeys]);
    setTreeData(oTreeData);
  };
  return (
    <div className={classes.orderTypeFields}>
      <FieldsHeader />
      <TreeTransfer
        dataSource={treeData}
        targetKeys={targetKeys}
        onChange={onChange}
        onTreeDataChange={handleTreeDataChange}
        confirmModal={confirmModal}
        onSelectTree={handleSelectTree}
        availableSearch={availableSearch}
        setAvailableSearch={setAvailableSearch}
        selectedSearch={selectedSearch}
        setSelectedSearch={setSelectedSearch}
        onHaftChange={handleHaftChange}
        haftKeys={haftKeys}
        initTreeData={initTreeData}
        onSelectAllWithSearch={handleSelectAllWithSearch}
        expandKeys={expandKeys}
        setExpandKeys={setExpandKeys}
      />
    </div>
  );
};

export default NewOrderTypeFields;
