import React from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import cn from 'classnames';
import Button, { ButtonColors } from 'components/Button';
import { RadioGroup } from 'components/FormFields';
import { STATUS_OPTIONS } from '../../constants';
import classes from './Header.module.scss';

const Header = ({
  className,
  status,
  setStatus,
  onAdd,
  title = 'Officials',
  hideAdd = false,
  statusOptions = null,
}) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.hideAdd]: !onAdd,
        },
        className
      )}
    >
      <h1>{title}</h1>
      {!!setStatus && (
        <div>
          <RadioGroup
            items={statusOptions || STATUS_OPTIONS}
            onChange={e => setStatus(e.target.value)}
            value={status}
          />
        </div>
      )}
      {!hideAdd ? (
        <Button
          onClick={onAdd}
          isLink
          color={ButtonColors.BLUE}
          leftIcon={<PlusCircleOutlined />}
        >
          Add Additional Record
        </Button>
      ) : (
        <span></span>
      )}
    </div>
  );
};

export default Header;
