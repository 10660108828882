import {
  apiCreateUpdateDriver,
  apiGetDrivers,
  apiUpdateDriverStatus,
  apiGetDriverAccount,
  apiCreateDriverAccount,
  apiUpdateDriverAccount,
  apiGetDriverFuelCard,
  apiCreateDriverFuelCard,
  apiUpdateDriverFuelCard,
} from 'api';
import { getDriversList } from '../actions/shared';
import { driverTypes } from '../types';

// SYNC_UPDATE_DRIVER
export const syncUpdateDriver = (driverId, driverData) => dispatch => {
  dispatch({
    type: driverTypes.SYNC_UPDATE_DRIVER,
    payload: { driverId, driverData },
  });
};
// SYNC_UPDATE_DRIVER_SIMPLE_ACCOUNT
export const syncUpdateDriverSimpleAccount = (
  driverId,
  accountId,
  simpleAccountData
) => dispatch => {
  dispatch({
    type: driverTypes.SYNC_UPDATE_DRIVER_SIMPLE_ACCOUNT,
    payload: { driverId, accountId, simpleAccountData },
  });
};
// SYNC_UPDATE_DRIVER_SIMPLE_FUEL_CARD
export const syncUpdateDriverSimpleCard = (
  driverId,
  cardId,
  simpleCardData
) => dispatch => {
  dispatch({
    type: driverTypes.SYNC_UPDATE_DRIVER_SIMPLE_FUEL_CARD,
    payload: { driverId, cardId, simpleCardData },
  });
};

// GET_DRIVERS
export const getDrivers = ({ id }, filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: driverTypes.GET_DRIVERS,
    payload: {},
    asyncCall: () => {
      return apiGetDrivers(id, filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_DRIVER_ACCOUNT
export const getDriverAccount = (clientId, driverId, accountId, cb) => {
  return {
    isAsyncCall: true,
    baseType: driverTypes.GET_DRIVER_ACCOUNT,
    payload: { driverId, accountId },
    asyncCall: () => {
      return apiGetDriverAccount(clientId, driverId, accountId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_DRIVER_FUEL_CARD
export const getDriverFuelCard = (clientId, driverId, cardId, cb) => {
  return {
    isAsyncCall: true,
    baseType: driverTypes.GET_DRIVER_FUEL_CARD,
    payload: { driverId, cardId },
    asyncCall: () => {
      return apiGetDriverFuelCard(clientId, driverId, cardId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};

// CREATE_DRIVER
export const createDriver = (clientId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: driverTypes.CREATE_DRIVER,
    payload: {},
    asyncCall: () => {
      return apiCreateUpdateDriver(clientId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(getDriversList(clientId));
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_DRIVER
export const updateDriver = (clientId, data, cb, driverId) => {
  return {
    isAsyncCall: true,
    baseType: driverTypes.UPDATE_DRIVER,
    payload: { driverId },
    asyncCall: () => {
      return apiCreateUpdateDriver(clientId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(getDriversList(clientId));
      dispatch(syncUpdateDriver(driverId, response?.data || {}));
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_DRIVER_STATUS
export const updateDriverStatus = (clientId, driverId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: driverTypes.UPDATE_DRIVER_STATUS,
    payload: { driverId },
    asyncCall: () => {
      return apiUpdateDriverStatus(clientId, driverId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(getDriversList(clientId));
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CREATE_DRIVER_ACCOUNT
export const createDriverAccount = (clientId, driverId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: driverTypes.CREATE_DRIVER_ACCOUNT,
    payload: { driverId },
    asyncCall: () => {
      return apiCreateDriverAccount(clientId, driverId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_DRIVER_ACCOUNT
export const updateDriverAccount = (
  clientId,
  driverId,
  accountId,
  data,
  cb
) => {
  return {
    isAsyncCall: true,
    baseType: driverTypes.UPDATE_DRIVER_ACCOUNT,
    payload: { driverId },
    asyncCall: () => {
      return apiUpdateDriverAccount(clientId, driverId, accountId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(
        syncUpdateDriverSimpleAccount(driverId, accountId, response.data)
      );
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CREATE_DRIVER_FUEL_CARD
export const createDriverFuelCard = (clientId, driverId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: driverTypes.CREATE_DRIVER_FUEL_CARD,
    payload: { driverId },
    asyncCall: () => {
      return apiCreateDriverFuelCard(clientId, driverId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_DRIVER_FUEL_CARD
export const updateDriverFuelCard = (clientId, driverId, cardId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: driverTypes.UPDATE_DRIVER_FUEL_CARD,
    payload: { driverId },
    asyncCall: () => {
      return apiUpdateDriverFuelCard(clientId, driverId, cardId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(syncUpdateDriverSimpleCard(driverId, cardId, response.data));
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
