import React from 'react';

import Space from 'components/Space';
import Slider from 'components/Slider';

const SliderComponent = () => {
  return (
    <Space style={{ width: '100%' }} direction={'vertical'}>
      <Slider defaultValue={40} />
      <Slider defaultValue={40} disabled />
    </Space>
  );
};

export default SliderComponent;
