import React from 'react';
import { PlusCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import Button, { ButtonColors } from 'components/Button';
import { useFooterData } from './selectorData';
import classes from './NewReminder.module.scss';

const NewReminderFooter = ({
  handleCancel,
  isDetail,
  isEdit,
  setIsEdit,
  onStatusChange,
  reminderId,
}) => {
  const {
    createReminderLoading,
    updateReminderLoading,
    reminderDetail,
    chagneReminderStatusLoading,
  } = useFooterData();
  const isActiveReminder = reminderDetail.status === 'active';
  return (
    <div className={classes.actions}>
      {isDetail && (
        <Button
          type={'primary'}
          size={'large'}
          htmlType="button"
          color={isActiveReminder ? ButtonColors.DARK_RED : ButtonColors.GREEN}
          disabled={false}
          largeIcon
          className={classes.statusButton}
          onClick={onStatusChange}
          loading={chagneReminderStatusLoading[reminderId]}
        >
          {isActiveReminder ? 'Deactivate' : 'Reactivate'}
        </Button>
      )}
      {isDetail && !isEdit ? (
        <Button
          type={'primary'}
          size={'large'}
          htmlType="button"
          color={ButtonColors.BLUE}
          disabled={false}
          largeIcon
          onClick={() => {
            setIsEdit(true);
          }}
        >
          Edit Alert
        </Button>
      ) : (
        <>
          <Button
            size={'large'}
            htmlType="button"
            color={ButtonColors.DEFAULT}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            type={'primary'}
            size={'large'}
            htmlType="submit"
            color={ButtonColors.BLUE}
            leftIcon={isEdit ? <CheckCircleFilled /> : <PlusCircleFilled />}
            disabled={false}
            largeIcon
            loading={createReminderLoading || updateReminderLoading}
          >
            {isEdit ? 'Save Edits' : 'Create New Alert'}
          </Button>
        </>
      )}
    </div>
  );
};

export default NewReminderFooter;
