import React from 'react';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import {
  Section,
  FormSection,
  ListInfo,
  TextareaField,
} from 'views/pages/ClientDetail/components/Elements';
import { AGREEMENT_INFORMATION_OBJ_KEYS } from './constants';

const AgreementInformation = ({ isEdit, form, data }) => {
  return (
    <Section>
      {isEdit ? (
        <FormSection>
          <InputItem
            name={'remit_account'}
            label="Remit to Account"
            form={form}
          />
          <InputItem
            name={'aging_report'}
            label="Aging Report"
            form={form}
            type="file"
          />
          <InputItem
            name={'buyout_amount'}
            label="Buyout Amount"
            form={form}
            type="price"
            prefix={'$'}
            placeholder="0.00"
          />
          <InputItem
            name={'buyout_agreement'}
            label="Buyout Agreement"
            form={form}
            type="file"
          />
          <TextareaField
            name={'summary'}
            label="Summary"
            form={form}
            type="textarea"
            placeholder="Notes"
          />
        </FormSection>
      ) : (
        <ListInfo objKeys={AGREEMENT_INFORMATION_OBJ_KEYS} data={data} />
      )}
    </Section>
  );
};

export default AgreementInformation;
