import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  teammateDetailTypes,
} from '../types';
import get from 'lodash/get';
import { initialTeammateDetailState } from '../states/teammateDetail';

export default (state = initialTeammateDetailState, action) => {
  switch (action.type) {
    // CUSTOM
    // SYNC_UPDATE_TEAMMATE_DETAIL
    case teammateDetailTypes.SYNC_UPDATE_TEAMMATE_DETAIL: {
      return {
        ...state,
        detail: {
          ...state.detail,
          data: action.payload,
        },
      };
    }
    // GET_TEAMMATE_DETAIL
    case asyncActionTypeRequest(teammateDetailTypes.GET_TEAMMATE_DETAIL): {
      return {
        ...state,
        detail: {
          ...state.detail,
          data: {
            ...state.detail.data,
            profile_pic: '',
          },
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(teammateDetailTypes.GET_TEAMMATE_DETAIL): {
      return {
        ...state,
        detail: {
          data: get(action, 'response.data'),
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(teammateDetailTypes.GET_TEAMMATE_DETAIL): {
      return {
        ...state,
        detail: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // UPDATE_TEAMMATE
    case asyncActionTypeRequest(teammateDetailTypes.UPDATE_TEAMMATE): {
      return {
        ...state,
        update: {
          ...state.update,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(teammateDetailTypes.UPDATE_TEAMMATE): {
      return {
        ...state,
        update: {
          data: get(action, 'response.data'),
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(teammateDetailTypes.UPDATE_TEAMMATE): {
      return {
        ...state,
        update: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // DO_ACTION
    case asyncActionTypeRequest(teammateDetailTypes.DO_ACTION): {
      return {
        ...state,
        doAction: {
          ...state.doAction,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(teammateDetailTypes.DO_ACTION): {
      return {
        ...state,
        doAction: {
          data: get(action, 'response.data'),
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(teammateDetailTypes.DO_ACTION): {
      return {
        ...state,
        doAction: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    default:
      return state;
  }
};
