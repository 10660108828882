import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  orderTypeTypes,
} from '../types';
import get from 'lodash/get';
import { initialOrderTypeState } from '../states/orderType';

export default (state = initialOrderTypeState, action) => {
  switch (action.type) {
    // GET_ALL_ORDER_TYPES
    case asyncActionTypeRequest(orderTypeTypes.GET_ALL_ORDER_TYPE_LIST): {
      return {
        ...state,
        orderTypes: {
          ...state.orderTypes,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(orderTypeTypes.GET_ALL_ORDER_TYPE_LIST): {
      return {
        ...state,
        orderTypes: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(orderTypeTypes.GET_ALL_ORDER_TYPE_LIST): {
      return {
        ...state,
        orderTypes: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_ALL_ORDER_TYPES
    case asyncActionTypeRequest(orderTypeTypes.GET_ALL_ORDER_TYPES): {
      return {
        ...state,
        allOrderTypes: {
          ...state.allOrderTypes,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(orderTypeTypes.GET_ALL_ORDER_TYPES): {
      const isMore = get(action, 'payload.isMore');
      const data = get(action, 'response.data.data') || [];
      const total = get(action, 'response.data.total') || 0;
      return {
        ...state,
        allOrderTypes: {
          data: isMore ? [...state.allOrderTypes.data, ...data] : data,
          totalNo: total || 0,
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(orderTypeTypes.GET_ALL_ORDER_TYPES): {
      return {
        ...state,
        allOrderTypes: {
          loading: false,
          error: action.payload,
          data: [],
          totalNo: 0,
        },
      };
    }
    // GET_ORDER_TYPE_DETAIL
    case asyncActionTypeRequest(orderTypeTypes.GET_ORDER_TYPE_DETAIL): {
      return {
        ...state,
        orderTypeDetail: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(orderTypeTypes.GET_ORDER_TYPE_DETAIL): {
      return {
        ...state,
        orderTypeDetail: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(orderTypeTypes.GET_ORDER_TYPE_DETAIL): {
      return {
        ...state,
        orderTypeDetail: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // GET_ORDER_TYPE_LOGS
    case asyncActionTypeRequest(orderTypeTypes.GET_ORDER_TYPE_LOGS): {
      return {
        ...state,
        orderTypeLogs: {
          ...state.orderTypeLogs,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(orderTypeTypes.GET_ORDER_TYPE_LOGS): {
      return {
        ...state,
        orderTypeLogs: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(orderTypeTypes.GET_ORDER_TYPE_LOGS): {
      return {
        ...state,
        orderTypeLogs: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // CREATE_ORDER_TYPE
    case asyncActionTypeRequest(orderTypeTypes.CREATE_ORDER_TYPE): {
      return {
        ...state,
        createOrderType: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(orderTypeTypes.CREATE_ORDER_TYPE): {
      return {
        ...state,
        createOrderType: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(orderTypeTypes.CREATE_ORDER_TYPE): {
      return {
        ...state,
        createOrderType: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // UPDATE_ORDER_TYPE
    case asyncActionTypeRequest(orderTypeTypes.UPDATE_ORDER_TYPE): {
      return {
        ...state,
        updateOrderType: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(orderTypeTypes.UPDATE_ORDER_TYPE): {
      return {
        ...state,
        updateOrderType: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(orderTypeTypes.UPDATE_ORDER_TYPE): {
      return {
        ...state,
        updateOrderType: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // DELETE_ORDER_TYPE
    case asyncActionTypeRequest(orderTypeTypes.DELETE_ORDER_TYPE): {
      return {
        ...state,
        deleteOrderType: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(orderTypeTypes.DELETE_ORDER_TYPE): {
      return {
        ...state,
        deleteOrderType: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(orderTypeTypes.DELETE_ORDER_TYPE): {
      return {
        ...state,
        deleteOrderType: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // ADD_ORDER_TYPE_NOTE
    case asyncActionTypeRequest(orderTypeTypes.ADD_ORDER_TYPE_NOTE): {
      return {
        ...state,
        addOrderTypeNote: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(orderTypeTypes.ADD_ORDER_TYPE_NOTE): {
      return {
        ...state,
        addOrderTypeNote: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(orderTypeTypes.ADD_ORDER_TYPE_NOTE): {
      return {
        ...state,
        addOrderTypeNote: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // UPDATE_ORDER_TYPE_DOCUMENT
    case asyncActionTypeRequest(orderTypeTypes.UPDATE_ORDER_TYPE_DOCUMENT): {
      return {
        ...state,
        updateOrderTypeDocument: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(orderTypeTypes.UPDATE_ORDER_TYPE_DOCUMENT): {
      return {
        ...state,
        updateOrderTypeDocument: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(orderTypeTypes.UPDATE_ORDER_TYPE_DOCUMENT): {
      return {
        ...state,
        updateOrderTypeDocument: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    default:
      return state;
  }
};
