import { useReducerData, useReducerLoading } from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// FUEL_CARD
export const useSelectFuelCard = () => {
  return useReducerData(
    REDUCER_NAMES.PAYMENT_SOURCE,
    REDUCER_ATTRIBUTES.PAYMENT_SOURCE.FUEL_CARD,
    {}
  );
};
// FUEL_CARD_LOADING
export const useSelectFuelCardLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.PAYMENT_SOURCE,
    REDUCER_ATTRIBUTES.PAYMENT_SOURCE.FUEL_CARD,
    false
  );
};
// FUEL_CARDS
export const useSelectFuelCards = () => {
  return useReducerData(
    REDUCER_NAMES.PAYMENT_SOURCE,
    REDUCER_ATTRIBUTES.PAYMENT_SOURCE.FUEL_CARDS,
    []
  );
};
export const useSelectFuelCardsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.PAYMENT_SOURCE,
    REDUCER_ATTRIBUTES.PAYMENT_SOURCE.FUEL_CARDS,
    false
  );
};

// CREATE_FUEL_CARD
export const useSelectCreateFuelCardLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.PAYMENT_SOURCE,
    REDUCER_ATTRIBUTES.PAYMENT_SOURCE.CREATE_FUEL_CARD,
    {}
  );
};
// UPDATE_FUEL_CARD
export const useSelectUpdateFuelCardLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.PAYMENT_SOURCE,
    REDUCER_ATTRIBUTES.PAYMENT_SOURCE.UPDATE_FUEL_CARD,
    {}
  );
};

// CHECKING_ACCOUNT_LOADING
export const useSelectCheckingAccountLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.PAYMENT_SOURCE,
    REDUCER_ATTRIBUTES.PAYMENT_SOURCE.CHECKING_ACCOUNT,
    {}
  );
};
// CHECKING_ACCOUNT
export const useSelectCheckingAccount = () => {
  return useReducerData(
    REDUCER_NAMES.PAYMENT_SOURCE,
    REDUCER_ATTRIBUTES.PAYMENT_SOURCE.CHECKING_ACCOUNT,
    {}
  );
};
// CHECKING_ACCOUNTS
export const useSelectCheckingAccounts = () => {
  return useReducerData(
    REDUCER_NAMES.PAYMENT_SOURCE,
    REDUCER_ATTRIBUTES.PAYMENT_SOURCE.CHECKING_ACCOUNTS,
    []
  );
};
export const useSelectCheckingAccountsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.PAYMENT_SOURCE,
    REDUCER_ATTRIBUTES.PAYMENT_SOURCE.CHECKING_ACCOUNTS,
    false
  );
};

// CREATE_CHECKING_ACCOUNT
export const useSelectCreateCheckingAccountLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.PAYMENT_SOURCE,
    REDUCER_ATTRIBUTES.PAYMENT_SOURCE.CREATE_CHECKING_ACCOUNT,
    {}
  );
};
// UPDATE_CHECKING_ACCOUNT
export const useSelectUpdateCheckingAccountLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.PAYMENT_SOURCE,
    REDUCER_ATTRIBUTES.PAYMENT_SOURCE.UPDATE_CHECKING_ACCOUNT,
    {}
  );
};
