import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
  UnorderedListOutlined,
  TeamOutlined,
  FileSyncOutlined,
  NotificationOutlined,
  LineChartOutlined,
} from '@ant-design/icons';
import ROUTES from 'routes';
import { ReactComponent as LogoSvg } from 'assets/icons/Logo.svg';
import Menu from 'components/Menu';
import { QUEUE_DEPARTMENTS } from 'helper/const';
import ChatContainer from './ChatContainer';
import LogoutMenu from './LogoutMenu';
import getPermission from './permissions';
import { useIndexData } from './selectorData';
import styles from './style.module.scss';

export const EmptyIcon = ({ className, onClick }) => {
  return (
    <span
      className={cx('anticon', styles.emptyIcon, className)}
      onClick={onClick}
    />
  );
};

const MenuItems = [
  {
    to: ROUTES.DASHBOARD(),
    label: 'Order Types',
    icon: <PieChartOutlined />,
  },
  {
    to: ROUTES.MY_COMPANY(),
    label: 'My Company',
    icon: <UnorderedListOutlined />,
  },
  {
    to: ROUTES.CLIENT_LIST(),
    label: 'Client List',
    icon: <UnorderedListOutlined />,
  },
  {
    to: ROUTES.MY_TEAM(),
    label: 'My Team',
    icon: <TeamOutlined />,
  },
  {
    to: ROUTES.REMINDERS(),
    label: 'Alerts',
    icon: <NotificationOutlined />,
  },
  {
    to: ROUTES.MY_REPORTS(),
    label: 'My Reports',
    icon: <LineChartOutlined />,
  },
];

const urlMapping = {
  [ROUTES.HOME()]: ROUTES.HOME(),
  [ROUTES.DASHBOARD()]: ROUTES.DASHBOARD(),
  [ROUTES.ORDER_TYPE_DETAIL()]: ROUTES.DASHBOARD(),
  [ROUTES.NEW_ORDER_TYPE()]: ROUTES.DASHBOARD(),
  [ROUTES.MY_COMPANY()]: ROUTES.MY_COMPANY(),
  [ROUTES.MY_TEAM()]: ROUTES.MY_TEAM(),
  [ROUTES.TEAMMATE_DETAIL()]: ROUTES.MY_TEAM(),
  [ROUTES.CLIENT_LIST()]: ROUTES.CLIENT_LIST(),
  [ROUTES.CLIENT_DETAIL()]: ROUTES.CLIENT_LIST(),
  [ROUTES.REMINDERS()]: ROUTES.REMINDERS(),
  [ROUTES.REMINDER_DETAIL()]: ROUTES.REMINDERS(),
  [ROUTES.MY_REPORTS()]: ROUTES.MY_REPORTS(),
};

QUEUE_DEPARTMENTS.map(({ upperText, cName }) => {
  const routerUrl = ROUTES[`${upperText}_WORK_QUEUE`]();
  MenuItems.push({
    to: routerUrl,
    label: <>{cName} Work Queue</>,
    icon: <FileSyncOutlined />,
  });
  urlMapping[routerUrl] = routerUrl;
  return true;
});

const MainLayout = props => {
  const { currentUser, isLoggedIn } = useIndexData();
  const currentRole = currentUser.role || '';
  const permission = getPermission(currentRole, currentUser);
  const permissionUrls = permission.urls || [];
  const permissionExcludeUrls = permission.excludeUrls || [];
  const {
    component: Component,
    match,
    history,
    isFullWidth,
    noPadding,
    isWhite,
    departmentName,
    ...otherProps
  } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isChatCollapsed, setIsChatCollapsed] = useState(true);
  const currentPath = get(match, 'path', ROUTES.INDEX());
  const filteredMenuItems = MenuItems.filter(
    menu =>
      (permissionUrls.length === 0 || permissionUrls.includes(menu.to)) &&
      !permissionExcludeUrls.includes(menu.to)
  );
  useEffect(() => {
    if (!isEmpty(currentUser) && isLoggedIn) {
      const { urls, excludeUrls } = permission;
      const isRequiredUpdatePassword =
        currentPath === ROUTES.REQUIRED_UPDATE_PASSWORD() &&
        !!currentUser.internally_invited &&
        !currentUser.password_updated_at;
      if (!isRequiredUpdatePassword) {
        if (!urls) {
          window.location.href = ROUTES.MY_PROFILE();
        } else {
          if (
            (!urls.includes(currentPath) && urls.length !== 0) ||
            excludeUrls.includes(currentPath)
          ) {
            window.location.href = ROUTES.MY_PROFILE();
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [currentUser]);

  return (
    <div>
      <div
        className={cx(styles.sideBar, {
          [styles.isCollapsed]: isCollapsed,
        })}
      >
        <div className={styles.sideBarTopContainer}>
          <div
            className={cx(styles.logoContainer, {
              [styles.isCollapsed]: isCollapsed,
            })}
          >
            <LogoSvg className={styles.logo} /> <span>RoadOffice</span>
            <div
              onClick={() => setIsCollapsed(!isCollapsed)}
              className={styles.toggleNav}
            >
              {isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </div>
          </div>
          <Menu
            menus={filteredMenuItems}
            selectedKeys={[urlMapping[currentPath]]}
            isCollapsed={isCollapsed}
          />
        </div>
        <LogoutMenu isCollapsed={isCollapsed} />
      </div>

      <div
        className={cx(styles.componentContainer, {
          [styles.fullWidth]: isFullWidth,
          [styles.isCollapsed]: isCollapsed,
          [styles.isChatCollapsed]: isChatCollapsed,
        })}
      >
        <div
          className={cx(styles.mainContentWrapper, {
            [styles.noPadding]: noPadding,
            [styles.isWhite]: isWhite,
          })}
        >
          <Component
            {...{
              ...otherProps,
              match,
            }}
            history={history}
            departmentName={departmentName}
          />
        </div>
      </div>
      <ChatContainer
        isChatCollapsed={isChatCollapsed}
        setIsChatCollapsed={setIsChatCollapsed}
      />
    </div>
  );
};

export default MainLayout;
