import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as clientDetailSelectors from 'store/selectors/clientDetail';
import * as formsMSASelectors from 'store/selectors/formsMSA';
import * as orderTypeSelectors from 'store/selectors/orderType';
import * as orderSelectors from 'store/selectors/order';
import * as sharedSelectors from 'store/selectors/shared';
import * as paymentSourceSelectors from 'store/selectors/paymentSource';
import * as authSelectors from 'store/selectors/auth';
import * as clientOrderDetailSelectors from 'store/selectors/clientOrderDetail';
// ACTIONS
import * as orderActions from 'store/actions/order';
import * as orderTypeActions from 'store/actions/orderType';
import * as clientDetailActions from 'store/actions/clientDetail';
import * as formsMSAActions from 'store/actions/formsMSA';
import * as truckActions from 'store/actions/truck';
import * as trailerActions from 'store/actions/trailer';
import * as driverActions from 'store/actions/driver';
import * as leasorActions from 'store/actions/leasor';
import * as sharedActions from 'store/actions/shared';
import * as paymentSourceActions from 'store/actions/paymentSource';
import * as underwritingActions from 'store/actions/underwriting';
import * as clientOrderDetailActions from 'store/actions/clientOrderDetail';

export const useIndexData = () => {
  return {
    detailData: clientDetailSelectors.useSelectClientDetail(),
    activityLog: clientDetailSelectors.useSelectClientDetailLog(),
    activityLogLoading: clientDetailSelectors.useSelectClientDetailLogLoading(),
    addLogLoading: clientDetailSelectors.useSelectAddLogLoading(),
  };
};
export const useMainContentData = () => {
  return {
    currentUser: authSelectors.useSelectCurrentUser(),
    clientOrderDetail: orderSelectors.useSelectClientOrderDetail(),
    orderDetailFieldValues: clientOrderDetailSelectors.useSelectSaveClientOrderFieldValues(),
  };
};
export const useNewOrderModalData = () => {
  return {
    accounts: paymentSourceSelectors.useSelectCheckingAccounts(),
    cards: paymentSourceSelectors.useSelectFuelCards(),
    orderTypes: orderTypeSelectors.useSelectAllOrderTypesList(),
    orderRequesters: orderSelectors.useSelectOrderRequesterList(),
    orderDetailFields: orderSelectors.useSelectOrderDetailFields(),
    fieldCategories: sharedSelectors.useSelectFieldCategories(),
    orderTypeDetail: orderTypeSelectors.useSelectOrderTypeDetail(),
    currentUser: authSelectors.useSelectCurrentUser(),
    detailData: clientDetailSelectors.useSelectClientDetail(),
    orderDetailFieldValues: clientOrderDetailSelectors.useSelectSaveClientOrderFieldValues(),
  };
};
export const useHeaderData = () => {
  return {
    manageLogoLoading: clientDetailSelectors.useSelectManageLogoLoading(),
    detailData: clientDetailSelectors.useSelectClientDetail(),
  };
};
export const useClientRecordTabData = () => {
  return {
    detailData: clientDetailSelectors.useSelectClientDetail(),
    updateSaleInfoLoading: clientDetailSelectors.useSelectUpdateSalesInfoLoading(),
    updateCompanyLoading: clientDetailSelectors.useSelectUpdateCompanyLoading(),
    updateFormsMSALoading: formsMSASelectors.useSelectUpdateFormsMSALoading(),
    companyOfficials: clientDetailSelectors.useSelectCompanyOfficials(),
    formsMSAs: formsMSASelectors.useSelectFormsMSAs(),
    updateInsuranceLoading: clientDetailSelectors.useSelectUpdateInsuranceLoading(),
    updateFillingLoading: clientDetailSelectors.useSelectUpdateFillingLoading(),
    DOTClassifications:
      clientDetailSelectors.useSelectDOTClassifications()?.data || {},
  };
};
export const useLoadingSpinData = () => {
  return {
    detailLoading: clientDetailSelectors.useSelectClientDetailLoading(),
    DOTClassificationsLoading: clientDetailSelectors.useSelectDOTClassificationsLoading(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...orderTypeActions,
    ...clientDetailActions,
    ...formsMSAActions,
    ...truckActions,
    ...trailerActions,
    ...driverActions,
    ...leasorActions,
    ...sharedActions,
    ...paymentSourceActions,
    ...underwritingActions,
    ...orderActions,
    ...clientOrderDetailActions,
  });
};
