import React from 'react';
import { ColumnActions } from 'components/Table';
import { renderStandardColumn, renderTagColumn } from 'utils/table';

const getReminderColumns = ({
  handleView,
  handleChangeStatus,
  handleDelete,
}) => {
  return [
    {
      title: 'Alert Name',
      dataIndex: 'name',
      render: renderStandardColumn,
      width: 150,
    },
    {
      title: 'Field Trigger',
      dataIndex: 'trigger_field',
      render: renderStandardColumn,
      width: 150,
    },
    {
      title: 'Order Type',
      dataIndex: 'order_type',
      render: renderStandardColumn,
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value, record) => {
        return renderTagColumn(value, record, () => {
          handleView(record);
        });
      },
      width: 80,
    },
    {
      title: '',
      width: 50,
      render: (_value, record) => {
        const actions = [
          {
            label: 'Edit',
            props: {
              onClick: () => {
                handleView(record, true);
              },
            },
          },
          {
            label: record.status === 'active' ? 'Deactivate' : 'Reactivate',
            props: {
              onClick: () => {
                handleChangeStatus(record);
              },
            },
          },
          {
            label: 'Delete',
            props: {
              onClick: () => {
                handleDelete(record);
              },
            },
          },
        ];
        return <ColumnActions trigger={['click']} actions={actions} />;
      },
    },
  ];
};
const getFilters = (page, keyword, otherFilter = {}) => {
  const filters = {
    page,
    search: keyword || '',
    ...otherFilter,
  };
  return filters;
};
const getIsDefaultSearchFilter = ({ keyword }) => {
  return keyword === '';
};

export { getReminderColumns, getFilters, getIsDefaultSearchFilter };
