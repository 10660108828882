import pick from 'lodash/pick';
import omit from 'lodash/omit';
import { parseStringToNumber } from 'utils/number';
import { getBEDate, getDateFromMMYY } from 'utils/date';

export const getCardBR = data => {
  const tDateVal = getDateFromMMYY(data.expiration_date);
  const returnObj = {
    ...pick(data, ['name_on_card', 'card_type', 'credit_or_debit', 'zip_code']),
    card_number: parseStringToNumber(data.card_number),
    carrier_id: parseStringToNumber(data.carrier_id),
    pin: parseStringToNumber(data.pin),
    cvv: parseStringToNumber(data.cvv),
    expiration_date: getBEDate(tDateVal, true, 'YYYY-MM'),
    is_primary: data.is_primary ? 1 : 0,
  };
  if (data.address) {
    returnObj.address = data.address;
  }
  if (data.apt) {
    returnObj.apt = data.apt;
  }
  if (data.city) {
    returnObj.city = data.city;
  }
  if (data.state_id) {
    returnObj.state_id = data.state_id;
  }
  return returnObj;
};
export const setFullCard = (newCardObj, cardId, form) => {
  const values = form.getFieldsValue();
  const cards = (values.cards || []).map(card => {
    if (card.originId === cardId) {
      card = {
        ...card,
        ...omit(newCardObj, ['id']),
        expiration_date: newCardObj.expiration_date
          ? getBEDate(newCardObj.expiration_date, false, 'MM/YY')
          : '',
        is_primary: !!newCardObj.is_primary,
      };
    }
    return card;
  });
  form.setFieldsValue({
    cards,
  });
};
