import React from 'react';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import {
  Section,
  FormSection,
  ListInfo,
  TextareaField,
} from 'views/pages/ClientDetail/components/Elements';
import { LEASED_OPTIONS } from 'views/pages/ClientDetail/Trailer/constants';
import { PROFILE_OBJ_KEYS } from './constants';

const Profile = ({ isEdit, form, data }) => {
  return (
    <Section>
      {isEdit ? (
        <FormSection>
          <InputItem
            name={'business_name_search'}
            label="Business Name Search"
            form={form}
            type="select"
            options={LEASED_OPTIONS}
          />
          <InputItem
            name={'client_name_search'}
            label="Client Name Search"
            form={form}
            type="select"
            options={LEASED_OPTIONS}
          />
          <TextareaField
            name={'notes'}
            label="Notes"
            form={form}
            type="textarea"
          />
        </FormSection>
      ) : (
        <ListInfo objKeys={PROFILE_OBJ_KEYS} data={data} />
      )}
    </Section>
  );
};

export default Profile;
