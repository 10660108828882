import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import ROUTES from 'routes';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import Button, { ButtonColors } from 'components/Button';
import { onEditRow } from 'utils/table';
import { Input } from 'components/FormFields';
import { applySearch } from 'utils';
import { PAGINATION, STATUS_VALUES } from 'helper/const';
import { useMyTeamTabIds } from 'helper/hooks';
import { NotInitDataContent } from './components/Elements';
import { getMyTeamColumns } from './utils';
import { DEFAULT_SORT } from './constants';
import { useDepartmentsTabData } from './selectorData';
import classes from './MyTeam.module.scss';

const DepartmentsTab = props => {
  const history = useHistory();
  const tabIds = useMyTeamTabIds();
  const {
    getDepartmentTeam,
    handleInactive,
    handleResendInvite,
    handleActive,
    activeTab,
    resetList,
    setResetList,
    currentTab,
    tabName,
    keyword,
    setKeyword,
    permission,
    handleGetCompanyTeamList,
  } = props;
  const dataObj = useDepartmentsTabData();
  const currentUser = dataObj.currentUser;
  const listLoading =
    dataObj[`${permission.departmentDataAttrPrefix}Loading`] || false;
  const listData = dataObj[`${permission.departmentDataAttrPrefix}`] || [];
  const listTotal = dataObj[`${permission.departmentDataAttrPrefix}Total`] || 0;
  const listIsMore =
    dataObj[`${permission.departmentDataAttrPrefix}IsMore`] || false;
  const doActionLoading = dataObj.doActionLoading || false;
  const updateCompanyTeamMemberStatusLoading =
    dataObj.updateCompanyTeamMemberStatusLoading || false;
  const handleReloadData = (page, sort, keyword) => {
    if (permission?.tabIdFromDepartment) {
      getDepartmentTeam(page, sort, keyword, {
        group_id: tabIds[currentTab - 1],
      });
    } else {
      const tabTypes = permission.tabTypes || {};
      const thisTabType = tabTypes[currentTab] || '';
      handleGetCompanyTeamList(page, sort, keyword, {
        type: thisTabType,
      });
    }
  };
  const [sort, setSort] = useState(DEFAULT_SORT);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const isInitEmpty =
    listTotal === 0 &&
    !listLoading &&
    !keyword &&
    currentPage === 1 &&
    isEqual(sort, DEFAULT_SORT);
  const handleView = record => {
    history.push(ROUTES.TEAMMATE_DETAIL({ id: record.id }));
  };
  const handleEdit = record => {
    history.push(`${ROUTES.TEAMMATE_DETAIL({ id: record.id })}?edit=true`);
  };
  const handleAction = record => {
    const { status } = record;
    const fnMapping = {
      [STATUS_VALUES.ACTIVE]: handleInactive,
      [STATUS_VALUES.INVITE_SENT]: handleResendInvite,
      [STATUS_VALUES.IN_ACTIVE]: handleActive,
    };
    if (fnMapping[status]) {
      fnMapping[status]([record], () => {
        handleReloadData(currentPage, sort, keyword);
      });
    }
  };
  const columns = getMyTeamColumns(
    handleView,
    handleEdit,
    handleAction,
    permission,
    currentUser
  );
  const canDeactive =
    selectedRows.length > 0 &&
    !(selectedRowKeys.length === 1 && selectedRowKeys[0] === currentUser.id);
  useEffect(() => {
    if (activeTab === currentTab) {
      handleReloadData(1, DEFAULT_SORT, keyword);
      setCurrentPage(1);
      setSort(DEFAULT_SORT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
  useEffect(() => {
    if (
      (resetList === true || resetList === false) &&
      activeTab === currentTab
    ) {
      handleReloadData(1, DEFAULT_SORT, '');
      setCurrentPage(1);
      setSort(DEFAULT_SORT);
      setResetList(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetList]);

  return (
    <div
      className={cn(classes.mainContentTab, {
        [classes.isInitEmpty]: isInitEmpty,
      })}
    >
      {isInitEmpty ? (
        <NotInitDataContent message={`There are no ${tabName} teammates`} />
      ) : (
        <>
          <div className={classes.tabContentHeader}>
            <Input
              size={'medium'}
              name="keyword"
              allowClear
              value={keyword}
              placeholder={`Search teammates`}
              onChange={e => {
                const value = e.target.value;
                setKeyword(value);
                applySearch(value, val => {
                  setCurrentPage(1);
                  handleReloadData(1, sort, val);
                });
              }}
              prefix={<SearchOutlined />}
              isBlue
            />
            <div className={classes.actions}>
              {permission.teamAction && (
                <Button
                  htmlType="button"
                  color={canDeactive ? ButtonColors.BLUE : ButtonColors.DEFAULT}
                  onClick={() => {
                    if (canDeactive) {
                      handleInactive(selectedRows, () => {
                        setSelectedRows([]);
                        setSelectedRowKeys([]);
                        handleReloadData(currentPage, sort, keyword);
                      });
                    }
                  }}
                  loading={
                    (permission.isDetailCompanyTeam
                      ? updateCompanyTeamMemberStatusLoading
                      : doActionLoading) && canDeactive
                  }
                >
                  Deactivate
                </Button>
              )}
            </div>
          </div>
          <Table
            rowSelection={{
              selectedRowKeys,
              onChange: (sRowKeys, sRows) => {
                setSelectedRowKeys(sRowKeys);
                setSelectedRows(sRows);
              },
            }}
            columns={columns}
            data={listData}
            rowKey="id"
            loading={listLoading}
            rowIsClick
            isScroll
            scroll={{ x: 850 }}
            onRow={record => {
              return {
                onClick: event => {
                  onEditRow(event, () => {
                    history.push(ROUTES.TEAMMATE_DETAIL({ id: record.id }));
                  });
                },
              };
            }}
            showSorterTooltip={false}
            onSortChange={sorter => {
              const newSort = {
                field: sorter.field,
                order: sorter.order,
              };
              setSort(newSort);
              handleReloadData(currentPage, newSort, keyword);
            }}
            locale={{
              emptyText: `No ${tabName} teammates found`,
            }}
          />
          {listIsMore && (
            <Pagination
              current={currentPage}
              pageSize={PAGINATION.PER_PAGE}
              total={listTotal}
              right
              onChange={page => {
                setCurrentPage(page);
                handleReloadData(page, sort, keyword);
              }}
              className={classes.pagination}
            />
          )}
        </>
      )}
    </div>
  );
};

export default DepartmentsTab;
