import * as React from 'react';
import { Radio as AntRadio } from 'antd';
import PropTypes from 'prop-types';

import classes from './RadioGroup.module.scss';

const RadioGroup = ({ items, ...extraProps }) => {
  return (
    <AntRadio.Group {...extraProps} className={classes.wrapper}>
      {items.map(({ value, label, ...extraItemProps }, index) =>
        undefined === label ? (
          <AntRadio value={value} key={index} {...extraItemProps} />
        ) : (
          <AntRadio value={value} key={index} {...extraItemProps}>
            {label}
          </AntRadio>
        )
      )}
    </AntRadio.Group>
  );
};

RadioGroup.defaultProps = {
  items: [
    { value: 'value1', label: 'Label 1' },
    { value: 'value2', label: 'Label 2' },
    { value: 'value3', label: 'Label 3', disabled: true },
  ],
};

RadioGroup.propTypes = {
  items: PropTypes.array,
};

export default RadioGroup;
