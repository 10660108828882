import React from 'react';
import find from 'lodash/find';
import get from 'lodash/get';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import {
  FormSection,
  ShouldUpdateWrapper,
} from 'views/pages/ClientDetail/components/Elements';
import { useOptions } from 'helper/hooks';
import ListSection from '../ListSection';

const Evictions = ({ className, isEdit, form, data }) => {
  const { scEvictionTypeOptions } = useOptions();
  return (
    <ListSection
      className={className}
      isEdit={isEdit}
      form={form}
      data={data['evictions'] || []}
      title="Evictions"
      addBtnText="Add Eviction"
      parentName="evictions"
      fields={[
        {
          label: 'Type of Violation',
          key: 'eviction_type',
          type: 'value',
        },
        {
          label: 'Year',
          key: 'year',
          type: 'value',
        },
        {
          label: 'Other',
          key: 'other',
          type: 'value',
          dependField: 'eviction_type',
          dependFn: val => val !== 'Other',
        },
      ]}
      renderEditForm={index => {
        return (
          <FormSection>
            <InputItem
              name={[index, 'eviction_type_id']}
              label="Type of Violation"
              form={form}
              type="select"
              options={scEvictionTypeOptions}
            />
            <InputItem
              name={[index, 'year']}
              label="Year"
              form={form}
              placeholder="Enter year"
              addressGroupField
            />
            <InputItem
              name={[index, 'fake']}
              label="Fake"
              form={form}
              addressGroupField
              fake
            />
            <ShouldUpdateWrapper>
              {() => {
                const values = form.getFieldsValue();
                const valueObj = get(values, `evictions[${index}]`) || {};
                const foundType =
                  find(scEvictionTypeOptions, {
                    value: valueObj.eviction_type_id,
                  }) || {};
                if (foundType.label !== 'Other') {
                  return <></>;
                }
                return (
                  <>
                    <InputItem
                      name={[index, 'other']}
                      label="Other"
                      form={form}
                    />
                  </>
                );
              }}
            </ShouldUpdateWrapper>
          </FormSection>
        );
      }}
    />
  );
};

export default Evictions;
