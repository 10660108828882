import { QUARTERS_STRING, QUARTERS } from 'helper/const';

const quarterLower = val => (val ? val.toLowerCase() : '');
export const NORMAL_FIELDS = ['number'];
export const FILE_FIELDS = [
  'documents.new_york_permit_current_year',
  'documents.new_york_permit_previous_years',
  ...QUARTERS_STRING.map(
    q => `documents.new_york_${quarterLower(q)}_return_current_year`
  ),
  ...QUARTERS_STRING.map(
    q => `documents.new_york_${quarterLower(q)}_receipt_current_year`
  ),
  ...QUARTERS_STRING.map(
    q => `documents.new_york_${quarterLower(q)}_return_previous_year`
  ),
  ...QUARTERS_STRING.map(
    q => `documents.new_york_${quarterLower(q)}_receipt_previous_year`
  ),
];
export const NULL_FIELDS = [];
export const NUMBER_FIELDS = ['number'];
export const DATE_FIELDS = ['expiration_date'];
export const BODY_REQUEST_NORMAL_FIELDS = [];
export const PORTAL_1_FIELDS = ['hut_username', 'hut_password'];
export const PORTAL_2_FIELDS = ['oscar_username', 'oscar_password'];
// Fields for view mode
export const PROFILE_OBJ_KEYS = [
  {
    label: 'NY HUT Permit Number',
    key: 'number',
    type: 'value',
  },
];
export const PERMIT_OBJ_KEYS = year => {
  return [
    {
      label: 'NY HUT Permit',
      key: `year_wise.${year}.documents.new_york_permit_year_wise[0]`,
      type: 'file',
      isSingleFile: true,
    },
  ];
};
export const PERMIT2_OBJ_KEYS = year => {
  const returnArr = [];
  QUARTERS.forEach(obj => {
    returnArr.push({
      label: `${obj.label} Return`,
      key: `year_wise.${year}.documents.new_york_q${obj.value}_return_year_wise[0]`,
      type: 'file',
      isSingleFile: true,
    });
    returnArr.push({
      label: `${obj.label} Receipt`,
      key: `year_wise.${year}.documents.new_york_q${obj.value}_receipt_year_wise[0]`,
      type: 'file',
      isSingleFile: true,
    });
  });
  return returnArr;
};
const CURRENT_YEAR_OBJ_KEYS = [];
const PREVIOUS_YEAR_OBJ_KEYS = [];
QUARTERS_STRING.map(q => {
  CURRENT_YEAR_OBJ_KEYS.push({
    label: `${q} Return`,
    key: `documents.new_york_${quarterLower(q)}_return_current_year`,
    type: 'file',
    isSingleFile: true,
  });
  CURRENT_YEAR_OBJ_KEYS.push({
    label: `${q} Receipt`,
    key: `documents.new_york_${quarterLower(q)}_receipt_current_year`,
    type: 'file',
    isSingleFile: true,
  });
  PREVIOUS_YEAR_OBJ_KEYS.push({
    label: `${q} Return`,
    key: `documents.new_york_${quarterLower(q)}_return_previous_year`,
    type: 'file',
  });
  PREVIOUS_YEAR_OBJ_KEYS.push({
    label: `${q} Receipt`,
    key: `documents.new_york_${quarterLower(q)}_receipt_previous_year`,
    type: 'file',
  });
  return true;
});
export { CURRENT_YEAR_OBJ_KEYS, PREVIOUS_YEAR_OBJ_KEYS };
