import React from 'react';

import { DatePicker } from 'components/FormFields';
import Space from 'components/Space';

const DatePickerComponent = () => {
  return (
    <Space direction={'vertical'}>
      <Space direction={'horizontal'}>
        <DatePicker size={'large'} />
        <DatePicker />
        <DatePicker size={'small'} />
      </Space>
      <Space direction={'horizontal'}>
        <DatePicker size={'large'} disabled />
        <DatePicker disabled />
        <DatePicker size={'small'} disabled />
      </Space>
    </Space>
  );
};

export default DatePickerComponent;
