import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as leasorSelectors from 'store/selectors/leasor';
import * as authSelectors from 'store/selectors/auth';
// ACTIONS
import * as authActions from 'store/actions/auth';
import * as clientDetailActions from 'store/actions/clientDetail';
import * as leasorActions from 'store/actions/leasor';

export const useIndexData = () => {
  return {
    leasors: leasorSelectors.useSelectLeasors(),
    leasorLoading: leasorSelectors.useSelectLeasorsLoading(),
    updateLeasorLoading: leasorSelectors.useSelectUpdateLeasorLoading(),
    getLeasorAccountLoading: leasorSelectors.useSelectLeasorAccountLoading(),
    getFuelCardLoading: leasorSelectors.useSelectLeasorFuelCardLoading(),
    validatePasswordLoading: authSelectors.useSelectValidatePasswordLoading(),
    leasorAccount: leasorSelectors.useSelectLeasorAccount(),
    leasorFuelCard: leasorSelectors.useSelectLeasorFuelCard(),
    createAccountsLoading: leasorSelectors.useSelectCreateLeasorAccountLoading(),
    createFuelCardsLoading: leasorSelectors.useSelectCreateLeasorFuelCardLoading(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...clientDetailActions,
    ...authActions,
    ...leasorActions,
  });
};
