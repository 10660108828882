import { ROLES } from 'helper/const';
import { adminPermission } from './admin';
import { otherUserPermission } from './otherUser';

const permissions = {
  [ROLES.ADMIN]: adminPermission,
  [ROLES.SUPER_ADMIN]: adminPermission,
};

const getPermission = role => {
  return permissions[role] || otherUserPermission;
};

export default getPermission;
