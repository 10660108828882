import {
  standardRequiredFieldObj,
  confirmPasswordDontMatchFieldObj,
  phoneFieldObj,
  emailFieldObj,
} from 'utils/form';
import { parseStringToNumber } from 'utils/number';
import { FORM_VALIDATION_MESSAGES } from 'helper/const';

export const validateObj = () => {
  return {
    firstName: [
      {
        ...standardRequiredFieldObj,
        message: FORM_VALIDATION_MESSAGES.FIRST_NAME_REQUIRED,
      },
    ],
    lastName: [
      {
        ...standardRequiredFieldObj,
        message: FORM_VALIDATION_MESSAGES.LAST_NAME_REQUIRED,
      },
    ],
    email: [
      {
        ...standardRequiredFieldObj,
        message: FORM_VALIDATION_MESSAGES.EMAIL_REQUIRED,
      },
      emailFieldObj,
    ],
    phone: [
      {
        ...standardRequiredFieldObj,
        message: FORM_VALIDATION_MESSAGES.PHONE_REQUIRED,
      },
      phoneFieldObj,
    ],
    password: [
      {
        ...standardRequiredFieldObj,
        message: FORM_VALIDATION_MESSAGES.PASSWORD_REQUIRED,
      },
    ],
    confirmPassword: [
      {
        ...standardRequiredFieldObj,
        message: FORM_VALIDATION_MESSAGES.PASSWORD_REQUIRED,
      },
      confirmPasswordDontMatchFieldObj,
    ],
  };
};
export const getInitialValues = () => {
  return {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  };
};
export const getBR = (values, hasToken, token) => {
  const returnObj = {};
  if (!hasToken) {
    returnObj.first_name = values.firstName;
    returnObj.last_name = values.lastName;
    returnObj.email = values.email;
    returnObj.phone_number = parseStringToNumber(values.phone);
  } else {
    returnObj.token = token || '';
  }
  returnObj.password = values.password;
  returnObj.confirm_password = values.confirmPassword;
  return returnObj;
};
export const fillClientFormValue = (client, form) => {
  form.setFieldsValue({
    firstName: client.first_name || '',
    lastName: client.last_name || '',
    email: client.email || '',
    phone: client.phone_number || '',
  });
};
