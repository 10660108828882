import React from 'react';
import find from 'lodash/find';
import cn from 'classnames';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  TextareaField,
  ShouldUpdateWrapper,
} from 'views/pages/ClientDetail/components/Elements';
import { BANK_NUMBER_FIELD } from 'helper/const';
import { useOptions } from 'helper/hooks';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { PROFILE_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const Profile = ({ className, isEdit, form, data }) => {
  const { scCollateralTypeOptions } = useOptions();

  return (
    <div className={cn(classes.profileSection, className)}>
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            {/* Use it to save delete_attachments for attachment removing */}
            <InputItem name={'delete_attachments'} label="" form={form} hide />
            <InputItem name={'company_name'} label="Company Name" form={form} />
            <InputItem
              name={'ucc_filing'}
              label="UCC Filing"
              form={form}
              type="file"
              multiple
            />
            <InputItem
              name={'effective_date'}
              label="Effective Date"
              form={form}
              type="date"
            />
            <InputItem
              name={'expiration_date'}
              label="Expiration Date"
              form={form}
              type="date"
            />
            <InputItem
              name={'collateral'}
              label="Collateral"
              form={form}
              type="select"
              options={scCollateralTypeOptions}
              mode="multiple"
              isMultipleOptionCheckbox
            />
            <InputItem
              name={'filing_number'}
              label="Filing Number"
              form={form}
              type="number"
              placeholder="0000000000"
              mask={BANK_NUMBER_FIELD.MASK}
            />
            <ShouldUpdateWrapper>
              {() => {
                const values = form.getFieldsValue();
                let hasOther = false;
                (values.collateral || []).map(collateralId => {
                  const foundType =
                    find(scCollateralTypeOptions, {
                      value: collateralId,
                    }) || {};
                  if (foundType.label === 'Other') {
                    hasOther = true;
                  }
                  return true;
                });
                if (!hasOther) {
                  return <></>;
                }
                return (
                  <>
                    <InputItem name={'other'} label="Other" form={form} />
                  </>
                );
              }}
            </ShouldUpdateWrapper>
            <TextareaField
              name={'notes'}
              label="Notes"
              form={form}
              type="textarea"
            />
          </FormSection>
        ) : (
          <ListInfo objKeys={PROFILE_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default Profile;
