import { useStoreActions, useReducerAttribute } from 'store/hooks';
import * as authSelectors from 'store/selectors/auth';
import * as reportActions from 'store/actions/report';

export const useIndexData = () => {
  return {
    reportFields: useReducerAttribute('report', 'reportFields.data', {}),
    reportData: useReducerAttribute('report', 'reports.data', {}),
  };
};
export const useReportTabData = () => {
  return {
    downloadReportsLoading: useReducerAttribute(
      'report',
      'downloadReports.loading',
      false
    ),
  };
};
export const useLoadingSpinData = () => {
  return {};
};
export const useFilterActionsData = () => {
  return {
    currentUser: authSelectors.useSelectCurrentUser(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...reportActions,
  });
};
