import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import toast from 'helper/toast';
import { getErrorMessageFromObj } from 'utils/object';
import Button, { ButtonColors } from 'components/Button';
import Space from 'components/Space';
import Form, { FormItem, useForm } from 'components/Form';
import { Input } from 'components/FormFields';
import {
  standardRequiredFieldObj,
  validateForm,
  scrollToErrorField,
  emailFieldObj,
} from 'utils/form';
import ROUTES from 'routes';
import { useActions, useIndexData } from './selectorData';
import classes from './ForgotPassword.module.scss';

const validateObj = () => {
  return {
    email: [standardRequiredFieldObj, emailFieldObj],
  };
};

const ForgotPassword = () => {
  const { forgotPassword } = useActions();
  const { forgotPasswordLoading } = useIndexData();
  const [submittedEmail, setSubmittedEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const history = useHistory();
  const [form] = useForm();

  const onSubmit = values => {
    if (isSubmitted) {
      history.push(ROUTES.LOGIN());
    } else {
      const fieldErrors = validateForm(validateObj(), values);
      if (fieldErrors.length > 0) {
        form.setFields(fieldErrors);
        scrollToErrorField();
      } else {
        forgotPassword(
          {
            email: values.email,
          },
          (res, err) => {
            if (res) {
              setIsSubmitted(true);
              setSubmittedEmail(values.email);
            } else {
              toast.error(getErrorMessageFromObj(err)[0]);
            }
          }
        );
      }
    }
  };

  const initialValues = {
    email: '',
  };

  return (
    <Space
      direction={'vertical'}
      size={'large'}
      className={cn(classes.wrapper, {
        [classes.isSubmitted]: isSubmitted,
      })}
    >
      <div className={classes.heading}>
        {isSubmitted ? 'We’ve sent you an email!' : 'Forgot Password'}
      </div>
      <p>
        {isSubmitted
          ? `A password reset link has been sent to your email address, ${submittedEmail}. Follow the directions in the email to reset your password.`
          : 'Enter the emaill address associated with your account to receive your password reset link.'}
      </p>
      <Form
        validateMessages={''}
        onFinish={onSubmit}
        initialValues={initialValues}
        form={form}
      >
        {!isSubmitted && (
          <FormItem name={'email'} label="Email">
            <Input size={'large'} name="email" form={form} />
          </FormItem>
        )}
        <div className={classes.bottomButton}>
          <Button
            type={'primary'}
            size={'large'}
            htmlType={'submit'}
            className={classes.fullWidth}
            loading={forgotPasswordLoading}
            color={ButtonColors.DARK_RED1}
          >
            {isSubmitted ? 'Back to Sign In' : 'Send Password Reset LInk'}
          </Button>
          {!isSubmitted && (
            <Button
              type={'primary'}
              size={'large'}
              htmlType={'button'}
              isLink
              className={classes.fullWidth}
              onClick={() => {
                history.push(ROUTES.LOGIN());
              }}
            >
              Cancel
            </Button>
          )}
        </div>
      </Form>
    </Space>
  );
};

export default ForgotPassword;
