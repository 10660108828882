import React from 'react';
import { Rate as AntdRate } from 'antd';

import classes from './Rate.module.scss';

const Rate = props => {
  const { label, ...other } = props;
  return (
    <>
      <AntdRate {...other} />
      {!!label && <span className={classes.label}>{label}</span>}
    </>
  );
};

export default Rate;
