import React, { useEffect } from 'react';
import cn from 'classnames';
import { useModal } from 'components/Modal';
import { confirmModalBlueConfig, getConfirmModal } from 'utils/modal';
import getPermission from './permissions';
import MainContent from './MainContent';
import { useIndexData, useActions } from './selectorData';
import classes from './Chat.module.scss';

const Chat = ({ isCollapse, handleExpandChat }) => {
  const [modal, contextHolder] = useModal();
  const confirmModal = getConfirmModal(modal, confirmModalBlueConfig);
  const { currentUser } = useIndexData();
  const { getInternalConversations, syncResetChatInformation } = useActions();
  const permission = getPermission(currentUser.role, currentUser);
  useEffect(() => {
    getInternalConversations();
    syncResetChatInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className={cn(classes.wrapper, {
        [classes.isCollapse]: isCollapse,
      })}
    >
      <MainContent
        isCollapse={isCollapse}
        handleExpandChat={handleExpandChat}
        confirmModal={confirmModal}
        permission={permission}
      />
      {contextHolder}
    </div>
  );
};

export default Chat;
