import {
  apiInviteCompanyTeamMember,
  apiGetCompanyTeamList,
  apiInviteTeammate,
  apiGetAllTeams,
  apiResendInviteTeammate,
  apiUpdateCompanyTeamMember,
  apiUpdateCompanyTeamMemberStatus,
} from 'api';

import { STATUS_VALUES } from 'helper/const';
import { updateTeammateDetail } from 'store/actions/teammateDetail';

import { myTeamTypes } from '../types';

// GET_COMPANY_TEAM_LIST
export const getCompanyTeamList = (companyId, filters, cb, isMore) => {
  return {
    isAsyncCall: true,
    baseType: myTeamTypes.GET_COMPANY_TEAM_LIST,
    payload: { isMore },
    asyncCall: () => {
      return apiGetCompanyTeamList(companyId, filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_ALL_TEAMS
export const getAllTeams = (filters, cb, isMore) => {
  return {
    isAsyncCall: true,
    baseType: myTeamTypes.GET_ALL_TEAMS,
    payload: { isMore },
    asyncCall: () => {
      return apiGetAllTeams(filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_IN_ACTIVE_TEAM
export const getDepartmentTeam = (filters, cb, isMore) => {
  return {
    isAsyncCall: true,
    baseType: myTeamTypes.GET_DEPARTMENT_TEAM,
    payload: { isMore },
    asyncCall: () => {
      return apiGetAllTeams({
        ...filters,
      });
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_DEPARTMENT_TEAM
export const getInActiveTeam = (filters, cb, isMore) => {
  return {
    isAsyncCall: true,
    baseType: myTeamTypes.GET_IN_ACTIVE_TEAM,
    payload: { isMore },
    asyncCall: () => {
      return apiGetAllTeams({
        ...filters,
        status: STATUS_VALUES.IN_ACTIVE,
      });
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// INVITE_TEAMMATE
export const inviteTeammate = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: myTeamTypes.INVITE_TEAMMATE,
    payload: {},
    asyncCall: () => {
      return apiInviteTeammate(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// RESEND_INVITE_TEAMMATE
export const resendInviteTeammate = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: myTeamTypes.RESEND_INVITE_TEAMMATE,
    payload: {},
    asyncCall: () => {
      return apiResendInviteTeammate(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// INVITE_COMPANY_TEAM_MEMBER
export const inviteCompanyTeamMember = (companyId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: myTeamTypes.INVITE_COMPANY_TEAM_MEMBER,
    payload: {},
    asyncCall: () => {
      return apiInviteCompanyTeamMember(companyId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_COMPANY_TEAM_MEMBER
export const updateCompanyTeamMember = (companyId, memberId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: myTeamTypes.UPDATE_COMPANY_TEAM_MEMBER,
    payload: {},
    asyncCall: () => {
      return apiUpdateCompanyTeamMember(companyId, memberId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      if (cb) cb(response);
      if (response?.data) {
        dispatch(updateTeammateDetail(response.data));
      }
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_COMPANY_TEAM_MEMBER_STATUS
export const updateCompanyTeamMemberStatus = (companyId, ids, status, cb) => {
  return {
    isAsyncCall: true,
    baseType: myTeamTypes.UPDATE_COMPANY_TEAM_MEMBER_STATUS,
    payload: {},
    asyncCall: () => {
      return apiUpdateCompanyTeamMemberStatus(companyId, ids, status);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
