import React from 'react';
import CheckingAccountEditForm from 'views/pages/ClientDetail/Driver/components/CheckingAccountEditForm';

const CAEditForm = ({ form, index, parentName }) => {
  return (
    <CheckingAccountEditForm
      form={form}
      index={index}
      parentName={parentName}
    />
  );
};

export default CAEditForm;
