import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Spin from 'components/Spin';
import Space from 'components/Space';
import Form, { useForm } from 'components/Form';
import { getErrorMessageFromObj } from 'utils/object';
import { validateForm, scrollToErrorField } from 'utils/form';
import ROUTES from 'routes';
import toast from 'helper/toast';
import { useModal } from 'components/Modal';
import { confirmModalConfig, getConfirmModal } from 'utils/modal';
import FormContent from './Form';
import {
  validateObj,
  getInitialValues,
  getBR,
  fillClientFormValue,
} from './utils';
import { useActions, useIndexData } from './selectorData';
import classes from './Registration.module.scss';

const Registration = ({ match }) => {
  const token = get(match, 'params.token', '');
  const [modal, contextHolder] = useModal();
  const { createUser, validateClientToken, createClientAccount } = useActions();
  const {
    createUserLoading,
    validateClientTokenLoading,
    createClientAccountLoading,
  } = useIndexData();
  const history = useHistory();
  const [form] = useForm();
  const [hasToken, setHasToken] = useState(false);
  const [strongPassword, setStrongPassword] = useState([]);
  const confirmModal = getConfirmModal(modal, confirmModalConfig);
  const onSubmit = values => {
    const fieldErrors = validateForm(validateObj(), values);
    if (fieldErrors.length > 0) {
      form.setFields(fieldErrors);
      scrollToErrorField();
    } else {
      if (strongPassword.length === 3) {
        const bodyRequest = getBR(values, hasToken, token);
        if (hasToken) {
          createClientAccount(bodyRequest, (res, err) => {
            if (res) {
              toast.success('Your account was created successfully!');
              history.push(ROUTES.LOGIN());
            } else {
              toast.error(getErrorMessageFromObj(err)[0]);
            }
          });
        } else {
          createUser(bodyRequest, (res, err) => {
            if (res) {
              confirmModal(
                'A confirmation link has been sent to your email account',
                <div>
                  Please click on the link that has been sent to your email
                  account to verify your email.
                </div>,
                () => {
                  history.push(ROUTES.LOGIN());
                }
              );
            } else {
              const hasEmailError = get(err, 'error.email');
              const errorMessage = getErrorMessageFromObj(err)[0];
              if (hasEmailError) {
                const fieldError = {
                  name: 'email',
                  errors: [errorMessage],
                };
                form.setFields([fieldError]);
                scrollToErrorField();
              } else {
                toast.error(errorMessage);
              }
            }
          });
        }
      }
    }
  };
  useEffect(() => {
    if (token) {
      validateClientToken({ token }, res => {
        if (res) {
          const clientData = res.data || {};
          setHasToken(!isEmpty(clientData));
          fillClientFormValue(clientData, form);
        } else {
          toast.error('The token is not valid');
          history.push(ROUTES.LOGIN());
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Spin spinning={validateClientTokenLoading} tip={'Verifying token...'}>
      <Space direction={'vertical'} size={'large'} className={classes.wrapper}>
        <div className={classes.heading}>Create an account</div>
        <Form
          onFinish={onSubmit}
          initialValues={getInitialValues()}
          form={form}
        >
          <FormContent
            hasToken={hasToken}
            form={form}
            setStrongPassword={setStrongPassword}
            createUserLoading={createUserLoading || createClientAccountLoading}
          />
        </Form>
      </Space>
      {contextHolder}
    </Spin>
  );
};

export default Registration;
