import { useReducerData, useReducerLoading } from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// FORMS_MSAS
export const useSelectFormsMSAsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.FORMS_MSA,
    REDUCER_ATTRIBUTES.FORMS_MSA.FORMS_MSAS
  );
};
export const useSelectFormsMSAs = () => {
  return useReducerData(
    REDUCER_NAMES.FORMS_MSA,
    REDUCER_ATTRIBUTES.FORMS_MSA.FORMS_MSAS,
    []
  );
};
// UPDATE_FORMS_MSA
export const useSelectUpdateFormsMSALoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.FORMS_MSA,
    REDUCER_ATTRIBUTES.FORMS_MSA.UPDATE_FORMS_MSA,
    {}
  );
};
// UPDATE_FORMS_MSA_STATUS
export const useSelectUpdateFormsMSAStatusLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.FORMS_MSA,
    REDUCER_ATTRIBUTES.FORMS_MSA.UPDATE_FORMS_MSA_STATUS,
    {}
  );
};
