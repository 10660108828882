import { useReducerData, useReducerLoading } from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// UNREAD_NOTIFICATIONS
export const useSelectUnreadNotificationsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.NOTIFICATION,
    REDUCER_ATTRIBUTES.NOTIFICATION.UNREAD_NOTIFICATIONS
  );
};
export const useSelectUnreadNotifications = () => {
  return useReducerData(
    REDUCER_NAMES.NOTIFICATION,
    REDUCER_ATTRIBUTES.NOTIFICATION.UNREAD_NOTIFICATIONS
  );
};
// READ_NOTIFICATIONS
export const useSelectReadNotificationsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.NOTIFICATION,
    REDUCER_ATTRIBUTES.NOTIFICATION.READ_NOTIFICATIONS
  );
};
export const useSelectReadNotifications = () => {
  return useReducerData(
    REDUCER_NAMES.NOTIFICATION,
    REDUCER_ATTRIBUTES.NOTIFICATION.READ_NOTIFICATIONS
  );
};
// NOTIFICATION_UNREAD_COUNT
export const useSelectNotificationUnreadCountLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.NOTIFICATION,
    REDUCER_ATTRIBUTES.NOTIFICATION.NOTIFICATION_UNREAD_COUNT
  );
};
export const useSelectNotificationUnreadCount = () => {
  return useReducerData(
    REDUCER_NAMES.NOTIFICATION,
    REDUCER_ATTRIBUTES.NOTIFICATION.NOTIFICATION_UNREAD_COUNT,
    0
  );
};
// READ_NOTIFICATION
export const useSelectReadNotificationLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.NOTIFICATION,
    REDUCER_ATTRIBUTES.NOTIFICATION.READ_NOTIFICATION
  );
};
// DELETE_NOTIFICATION
export const useSelectDeleteNotificationLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.NOTIFICATION,
    REDUCER_ATTRIBUTES.NOTIFICATION.DELETE_NOTIFICATION
  );
};
