import React from 'react';
import cn from 'classnames';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  Title,
} from 'views/pages/ClientDetail/components/Elements';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { DRUG_TESTS_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const DrugTests = ({ className, isEdit, form, data }) => {
  return (
    <div className={cn(classes.drugTestsSection, className)}>
      <Title text="Drug Tests" />
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            <InputItem
              name={'pre_emp_drug_test'}
              label="Pre-Employment Drug Test"
              form={form}
              type="file"
            />
            <InputItem
              name={'other_drug_test'}
              label="Random Tests"
              form={form}
              type="file"
              multiple
            />
          </FormSection>
        ) : (
          <ListInfo objKeys={DRUG_TESTS_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default DrugTests;
