import React from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import { WarningOutlined } from '@ant-design/icons';
import { FormList } from 'components/Form';
import DragDrop from 'components/DragDrop';
import Button, { ButtonColors } from 'components/Button';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import InputField from './InputField';
import classes from './Checklist.module.scss';

const Checklist = ({
  className,
  form,
  confirmModal,
  isViewDetail,
  checklistErrors,
  setChecklistErrors,
}) => {
  const listName = 'checklist';

  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isViewDetail]: isViewDetail,
        },
        className
      )}
    >
      <h2>Checklist</h2>
      {!isViewDetail && <p>Add checklist item(s)</p>}
      <InputItem name={'delete_checklist'} label="" form={form} hide />
      <FormList name={listName}>
        {(fields, { add, remove, move }) => {
          const values = form.getFieldsValue();
          const items = fields.map((field, index) => {
            const obj = get(values, `${listName}[${index}]`) || {};
            const checklistName = obj.name || '';
            const removeChecklist = () => {
              remove(index);
              if (!!obj.originId) {
                const currentDeleteIds = values.delete_checklist || [];
                form.setFieldsValue({
                  delete_checklist: [...currentDeleteIds, obj.originId],
                });
              }
            };
            return {
              id: obj.id || index,
              content: (
                <InputField
                  isViewDetail={isViewDetail}
                  key={obj.id || index}
                  id={obj.id || index}
                  listName={listName}
                  index={index}
                  form={form}
                  values={values}
                  value={checklistName}
                  onChangeCb={() => {
                    setChecklistErrors(
                      checklistErrors.filter(id => id !== obj.id)
                    );
                  }}
                  className={cn({
                    'ant-form-item-has-error': checklistErrors.includes(obj.id),
                  })}
                  onRemove={() => {
                    if (!!checklistName) {
                      confirmModal(
                        `Delete "${checklistName}"?`,
                        <div>
                          Are you sure you want to delete? This item will be
                          permanently deleted from{' '}
                          {values.name
                            ? `"${values.name}"`
                            : 'current order type'}
                          .
                        </div>,
                        () => {
                          removeChecklist();
                        },
                        {
                          type: 'confirm',
                          onCancel: () => {},
                          okText: 'Yes, Delete',
                          cancelText: 'No, Keep',
                          centered: true,
                          revertButton: true,
                          className: classes.confirmationModal,
                          icon: <WarningOutlined />,
                        }
                      );
                    } else {
                      removeChecklist();
                    }
                  }}
                />
              ),
            };
          });
          return (
            <>
              <DragDrop
                items={items}
                onIndexChange={(oldIndex, newIndex) => {
                  move(oldIndex, newIndex);
                }}
                cardClassName={classes.dndCard}
                disabled={!!isViewDetail}
              />
              {!isViewDetail && (
                <div className={classes.buttons}>
                  <Button
                    onClick={() => {
                      const newId = fields.length + 1;
                      add({
                        id: newId,
                        name: '',
                      });
                      setTimeout(() => {
                        const inputField = document.getElementById(newId);
                        if (inputField) {
                          inputField.focus();
                        }
                      }, 0);
                    }}
                    color={ButtonColors.BLUE}
                    isLink
                  >
                    + Add Item
                  </Button>
                </div>
              )}
            </>
          );
        }}
      </FormList>
    </div>
  );
};

export default Checklist;
