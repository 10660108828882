import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Collapse, { Panel } from 'components/Collapse';
import Header from 'views/pages/ClientDetail/CompanyOfficial/components/Header';
import NotFound from 'views/pages/ClientDetail/CompanyOfficial/components/NotFound';
import StatusTag from 'views/pages/ClientDetail/CompanyOfficial/components/StatusTag';
import PanelHeader from 'views/pages/ClientDetail/CompanyOfficial/PanelHeader';
import { ALL_OPTION } from 'views/pages/ClientDetail/CompanyOfficial/constants';
import Section from './components/Section';
import {
  afterCbFn,
  getLabel,
  isAlreadyNew,
} from 'views/pages/ClientDetail/CompanyOfficial/utils';
import { TooltipBlock } from 'components/Tooltip';
import { getTrailerBR, getTruckDriverAssigned } from './utils';
import { DEFAULT_SECTIONS, NEW_KEY } from './constants';
import {
  CollapseTable,
  CollapseAdditionalLabel,
  LabelWithAvatar,
} from '../components/Elements';
import { onKeyChangeFn, getEditKeys, editChangeFn, getIsKey } from '../utils';
import { useIndexData, useActions } from './selectorData';
import classes from './Trailer.module.scss';

const Trailer = ({
  className,
  confirmModal,
  openWarningModal,
  setIsEditing,
  activeTab,
  clientId,
  trailers_count,
  setIsEdit,
  status,
  setStatus,
  onUpdated,
  permission,
}) => {
  const setIsEditParent = setIsEdit;
  const { trailers, updateTrailerLoading, vinDecoderLoading } = useIndexData();
  const {
    getTrailers,
    createTrailer,
    updateTrailer,
    syncSetSectionTotal,
    updateTrailerStatus,
    vinDecoder,
  } = useActions();
  const [createLoading, setCreateLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState({});
  const [avatarFinalSrc, setAvatarFinalSrc] = useState('');
  const [finalFile, setFinalFile] = useState({});
  const [sections, setSections] = useState(DEFAULT_SECTIONS);
  const [activeKeys, setActiveKeys] = useState([]);
  const [editKeys, setEditKeys] = useState([]);
  const [editChanged, setEditChanged] = useState({});
  const notFound = sections.length === 0 && trailers.length === 0;
  const handleEditChange = (...params) =>
    editChangeFn(editChanged, setEditChanged, setIsEditing, ...params);
  const handleSubmit = (id, currentProfilePic) => (
    values,
    searchFields,
    cb
  ) => {
    const trailerId = id && id !== NEW_KEY ? id : '';
    const actionFn = trailerId ? updateTrailer : createTrailer;
    if (!trailerId) {
      setCreateLoading(true);
    }
    actionFn(
      clientId,
      getTrailerBR(
        values,
        trailerId,
        finalFile,
        searchFields,
        currentProfilePic,
        avatarFinalSrc
      ),
      afterCbFn(
        res => {
          if (onUpdated) onUpdated();
          if (!trailerId) {
            syncSetSectionTotal(
              trailers_count + 1,
              'trailer_info',
              'trailers_count'
            );
            handleLoadTrailer(status, () => {
              setCreateLoading(false);
              if (cb) cb();
            });
          } else if (cb) cb();
        },
        () => {
          if (!trailerId) setCreateLoading(false);
        }
      ),
      trailerId
    );
  };
  const handleLoadTrailer = (val, cb) => {
    const filters = {};
    if (val !== ALL_OPTION.value) {
      filters.status = val;
    }
    getTrailers({ id: clientId }, filters, afterCbFn(cb));
  };
  // eslint-disable-next-line
  const handleSetStatusLoading = (id, val) => {
    setStatusLoading({
      ...statusLoading,
      [id]: val,
    });
  };
  const handleStatusChange = id => newStatus => {
    handleSetStatusLoading(id, true);
    updateTrailerStatus(
      clientId,
      id,
      { status: newStatus },
      afterCbFn(
        () => {
          if (onUpdated) onUpdated();
          handleLoadTrailer(status, () => {
            handleSetStatusLoading(id, false);
            setActiveKeys(activeKeys.filter(key => String(key) !== String(id)));
          });
        },
        () => handleSetStatusLoading(id, false)
      )
    );
  };

  useEffect(() => {
    if (activeTab === 1) {
      setSections(DEFAULT_SECTIONS);
      setEditKeys([]);
      setEditChanged({});
    }
  }, [activeTab]);
  useEffect(() => {
    setIsEditParent(sections.length > 0 || editKeys.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections, editKeys]);

  return (
    <div className={cn(classes.wrapper, className)}>
      <Header
        title="Trailers"
        status={status}
        setStatus={val => {
          setStatus(val);
          handleLoadTrailer(val);
        }}
        onAdd={
          !permission.addRecord
            ? null
            : () => {
                if (!isAlreadyNew(sections)) {
                  setSections([
                    { label: 'New Trailer', id: NEW_KEY, isNew: true },
                    ...sections,
                  ]);
                  setEditKeys([...editKeys, NEW_KEY]);
                  setActiveKeys([...activeKeys, NEW_KEY]);
                  setFinalFile({});
                  setAvatarFinalSrc('');
                }
              }
        }
        className={classes.header}
      />
      {notFound ? (
        <NotFound />
      ) : (
        <div className={classes.content}>
          <CollapseTable hideHeader section className={classes.sectionTable}>
            <Collapse
              destroyInactivePanel
              onChange={keys =>
                onKeyChangeFn(keys, activeKeys, editKeys, setActiveKeys)
              }
              activeKey={activeKeys}
            >
              {[...sections, ...trailers].map(section => {
                const { isNew, id, trailer_unit, make, color, year } = section;
                const key = String(id);
                const label = trailer_unit || getLabel(section);
                const isActive = getIsKey(activeKeys, key);
                const isEdit = getIsKey(editKeys, key);
                const sectionInnerProps = {
                  clientId,
                  rootKey: key,
                  isEdit,
                  isActive,
                  confirmModal,
                  setIsEdit: edit => {
                    const newEditKeys = getEditKeys(editKeys, edit, key);
                    setEditKeys(newEditKeys);
                    if (!edit) {
                      handleEditChange(key, false, newEditKeys);
                    }
                    if (isNew && !edit) {
                      setSections(DEFAULT_SECTIONS);
                    }
                    setFinalFile({});
                    setAvatarFinalSrc(section.profile_pic);
                  },
                  data: section,
                  onFormChange: () => {
                    handleEditChange(key, true, editKeys);
                  },
                  openWarningModal,
                  loading: isNew ? createLoading : updateTrailerLoading[id],
                  statusLoading: statusLoading[id],
                  onSubmit: handleSubmit(id, section.profile_pic),
                  onStatusChange: handleStatusChange(id),
                  vinDecoder,
                  vinDecoderLoading,
                  permission,
                };
                const avatarProps = {
                  confirmModal,
                  avatarFinalSrc,
                  setAvatarFinalSrc,
                  finalFile,
                  setFinalFile,
                  profilePic: section.profile_pic,
                };
                const { trucks, drivers } = getTruckDriverAssigned(section);
                const trucksDisplay = trucks.join(', ');
                const driversDisplay = drivers.join(', ');
                const pHeaderProps = {
                  isEdit,
                  label: (
                    <LabelWithAvatar
                      {...avatarProps}
                      label={label}
                      isEdit={isEdit}
                      onDelete={cb => {
                        setAvatarFinalSrc('');
                        if (cb) cb();
                      }}
                    />
                  ),
                  isActive,
                  firstIsSmall: true,
                  className: cn(classes.panelHeader, {
                    [classes.isActive]: isActive,
                    [classes.isEdit]: isEdit,
                  }),
                  additionalLabel: (
                    <CollapseAdditionalLabel
                      isActive={isActive}
                      has3Items
                      className={classes.additionalLabel}
                    >
                      {!isNew && (
                        <>
                          <span>
                            {' '}
                            <span>
                              Trucks:
                              <TooltipBlock tooltip={trucksDisplay}>
                                {trucksDisplay}
                              </TooltipBlock>
                            </span>
                            <span>
                              Driver:
                              <TooltipBlock tooltip={driversDisplay}>
                                {driversDisplay}
                              </TooltipBlock>
                            </span>
                          </span>
                          <span>
                            <span>
                              Year:
                              <TooltipBlock tooltip={year}>{year}</TooltipBlock>
                            </span>
                            <span>
                              Make:
                              <TooltipBlock tooltip={make}>{make}</TooltipBlock>
                            </span>
                            <span>
                              Color:
                              <TooltipBlock tooltip={color}>
                                {color}
                              </TooltipBlock>
                            </span>
                          </span>
                        </>
                      )}
                    </CollapseAdditionalLabel>
                  ),
                };
                return (
                  <Panel
                    forceRender={true}
                    className={classes.panel}
                    header={<PanelHeader {...pHeaderProps} />}
                    key={key}
                    extra={
                      !isActive ? <StatusTag status={section.status} /> : null
                    }
                  >
                    <Section {...sectionInnerProps} />
                  </Panel>
                );
              })}
            </Collapse>
          </CollapseTable>
        </div>
      )}
    </div>
  );
};

export default Trailer;
