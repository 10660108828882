import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as authSelectors from 'store/selectors/auth';
// ACTIONS
import * as authActions from 'store/actions/auth';
import * as sharedActions from 'store/actions/shared';

export const useIndexData = () => {
  return {
    validateInviteTokenLoading: authSelectors.useSelectValidateInviteTokenLoading(),
    accountData: authSelectors.useSelectAccountDataFromInviteToken(),
  };
};
export const useFormContentData = () => {
  return {
    createAccountLoading: authSelectors.useSelectCreateAccountLoading(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...authActions,
    ...sharedActions,
  });
};
