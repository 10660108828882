import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  checklistTypes,
} from '../types';
import get from 'lodash/get';
import { initialChecklistState } from '../states/checklist';

export default (state = initialChecklistState, action) => {
  switch (action.type) {
    // SYNC_UPDATE_CHECKLISTS
    case checklistTypes.SYNC_UPDATE_CHECKLISTS: {
      return {
        ...state,
        checklists: {
          ...state.checklists,
          data: action.payload || [],
        },
      };
    }
    // GET_CHECKLISTS
    case asyncActionTypeRequest(checklistTypes.GET_CHECKLISTS): {
      return {
        ...state,
        checklists: {
          ...state.checklists,
          loading: action.payload?.silent ? false : true,
          data: action.payload?.emptyPrevState ? [] : state.checklists?.data,
        },
      };
    }
    case asyncActionTypeSuccess(checklistTypes.GET_CHECKLISTS): {
      return {
        ...state,
        checklists: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(checklistTypes.GET_CHECKLISTS): {
      return {
        ...state,
        checklists: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // CREATE_UPDATE_CHECKLIST
    case asyncActionTypeRequest(checklistTypes.CREATE_UPDATE_CHECKLIST): {
      return {
        ...state,
        createUpdateChecklist: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(checklistTypes.CREATE_UPDATE_CHECKLIST): {
      return {
        ...state,
        createUpdateChecklist: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(checklistTypes.CREATE_UPDATE_CHECKLIST): {
      return {
        ...state,
        createUpdateChecklist: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // CHECK_UNCHECK_CHECKLIST
    case asyncActionTypeRequest(checklistTypes.CHECK_UNCHECK_CHECKLIST): {
      return {
        ...state,
        checkUncheckChecklist: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(checklistTypes.CHECK_UNCHECK_CHECKLIST): {
      return {
        ...state,
        checkUncheckChecklist: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(checklistTypes.CHECK_UNCHECK_CHECKLIST): {
      return {
        ...state,
        checkUncheckChecklist: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // DELETE_CHECKLIST
    case asyncActionTypeRequest(checklistTypes.DELETE_CHECKLIST): {
      return {
        ...state,
        deleteChecklist: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(checklistTypes.DELETE_CHECKLIST): {
      return {
        ...state,
        deleteChecklist: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(checklistTypes.DELETE_CHECKLIST): {
      return {
        ...state,
        deleteChecklist: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    default:
      return state;
  }
};
