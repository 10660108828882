import React from 'react';
import get from 'lodash/get';
import { BANK_NUMBER_FIELD } from 'helper/const';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import Checkbox from 'components/FormFields/Checkbox';
import { FormItem } from 'components/Form';
import { FormSection } from 'views/pages/ClientDetail/components/Elements';
import { BUSINESS_PERSONAL_OPTIONS } from '../../constants';

const CheckingAccountEditForm = ({ form, index, parentName }) => {
  return (
    <FormSection>
      <InputItem
        name={[index, 'name_on_account']}
        label="Name on Account"
        form={form}
        placeholder="Name on Account"
        customValidateName={[parentName, index, 'name_on_account']}
      />
      <InputItem
        name={[index, 'bank_name']}
        label="Bank Name"
        form={form}
        placeholder="Bank Name"
        customValidateName={[parentName, index, 'bank_name']}
      />
      <InputItem
        name={[index, 'routing_number_ach']}
        label="Routing Number ACH"
        form={form}
        placeholder="Routing Number ACH"
        mask={BANK_NUMBER_FIELD.MASK}
        type="number"
      />
      <InputItem
        name={[index, 'routing_number_wire']}
        label="Routing Number WIRE"
        form={form}
        placeholder="Routing Number WIRE"
        mask={BANK_NUMBER_FIELD.MASK}
        type="number"
      />
      <InputItem
        name={[index, 'account_number']}
        label="Account Number"
        form={form}
        placeholder="Account Number"
        mask={BANK_NUMBER_FIELD.MASK}
        type="number"
        customValidateName={[parentName, index, 'account_number']}
      />
      <InputItem
        name={[index, 'type']}
        label="Business or Personal"
        form={form}
        placeholder="Business or Personal"
        type="select"
        options={BUSINESS_PERSONAL_OPTIONS}
        customValidateName={[parentName, index, 'type']}
      />
      <FormItem shouldUpdate>
        {() => {
          const values = form.getFieldsValue();
          return (
            <Checkbox
              checked={get(
                values,
                `${parentName}.[${index}].is_default_account`
              )}
              label="Default Account"
              onChange={e => {
                const checked = e.target.checked;
                const oldAccounts = (get(values, parentName) || []).map(
                  (account, i) => {
                    if (i === index) {
                      account.is_default_account = checked;
                    }
                    if (checked && i !== index) {
                      account.is_default_account = false;
                    }
                    return account;
                  }
                );
                form.setFieldsValue({
                  [parentName]: oldAccounts,
                });
              }}
            />
          );
        }}
      </FormItem>
    </FormSection>
  );
};

export default CheckingAccountEditForm;
