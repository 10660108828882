import React from 'react';
import cn from 'classnames';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  TextareaField,
} from 'views/pages/ClientDetail/components/Elements';
import { LEASED_OPTIONS } from 'views/pages/ClientDetail/Trailer/constants';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { PROFILE_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const Profile = ({ className, isEdit, form, data }) => {
  return (
    <div className={cn(classes.profileSection, className)}>
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            {/* Use it to save delete_attachments for attachment removing */}
            <InputItem name={'delete_attachments'} label="" form={form} hide />
            <InputItem
              name={'business_name'}
              label="Business Name"
              form={form}
            />
            <InputItem
              name={'ifa_watchlist'}
              label="IFA Watchlist"
              form={form}
              type="select"
              options={LEASED_OPTIONS}
            />
            <InputItem
              name={'business_start_date'}
              label="Business Start Date"
              form={form}
              type="date"
            />
            <InputItem name={'industry'} label="Industry" form={form} />
            <TextareaField
              name={'notes'}
              label="Notes"
              form={form}
              type="textarea"
            />
          </FormSection>
        ) : (
          <ListInfo objKeys={PROFILE_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default Profile;
