import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as clientDetailSelectors from 'store/selectors/clientDetail';
// ACTIONS
import * as clientDetailActions from 'store/actions/clientDetail';

export const useIndexData = () => {
  return {
    companyOfficials: clientDetailSelectors.useSelectCompanyOfficials(),
    companyOfficialsLoading: clientDetailSelectors.useSelectCompanyOfficialsLoading(),
    updateCOLoading: clientDetailSelectors.useSelectUpdateCompanyOfficialLoading(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...clientDetailActions,
  });
};
