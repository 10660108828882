import React from 'react';
import PageContent from 'components/PageContent';
import classes from './ClientList.module.scss';

const Content = ({ children }) => {
  return (
    <PageContent
      initFullHeight
      noBackground
      noPadding
      className={classes.mainContent}
    >
      {children}
    </PageContent>
  );
};

export default Content;
