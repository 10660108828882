import { combineReducers } from 'redux';
import AppReducer from './app';
import AuthReducer from './auth';
import SharedReducer from './shared';
import OrderTypeReducer from './orderType';
import MyTeamReducer from './myTeam';
import TeammateDetailReducer from './teammateDetail';
import ChatReducer from './chat';
import ConversationDetailReducer from './conversationDetail';
import ChatInformationReducer from './chatInformation';
import ClientReducer from './client';
import ClientDetailReducer from './clientDetail';
import FormMSAReducer from './formsMSA';
import TruckReducer from './truck';
import TrailerReducer from './trailer';
import DriverReducer from './driver';
import LeasorReducer from './leasor';
import CustomFieldReducer from './customField';
import PaymentSourceReducer from './paymentSource';
import UnderwritingReducer from './underwriting';
import IntakeFormReducer from './intakeForm';
import OrderReducer from './order';
import ClientOrderDetail from './clientOrderDetail';
import WorkQueue from './workQueue';
import Reminder from './reminder';
import Checklist from './checklist';
import Notification from './notification';
import Report from './report';
import { authTypes, asyncActionTypeFailure } from '../types';

const reducers = {
  app: AppReducer,
  auth: AuthReducer,
  orderType: OrderTypeReducer,
  myTeam: MyTeamReducer,
  shared: SharedReducer,
  teammateDetail: TeammateDetailReducer,
  chat: ChatReducer,
  conversationDetail: ConversationDetailReducer,
  chatInformation: ChatInformationReducer,
  client: ClientReducer,
  clientDetail: ClientDetailReducer,
  formsMSA: FormMSAReducer,
  truck: TruckReducer,
  trailer: TrailerReducer,
  driver: DriverReducer,
  leasor: LeasorReducer,
  customField: CustomFieldReducer,
  paymentSource: PaymentSourceReducer,
  underwriting: UnderwritingReducer,
  intakeForm: IntakeFormReducer,
  order: OrderReducer,
  clientOrderDetail: ClientOrderDetail,
  workQueue: WorkQueue,
  reminder: Reminder,
  checklist: Checklist,
  notification: Notification,
  report: Report,
};

const combinedReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (
    action.type === authTypes.LOGOUT ||
    action.type === asyncActionTypeFailure(authTypes.GET_ME)
  ) {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default rootReducer;
