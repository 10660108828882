import React from 'react';

const LEAVE_GROUP = {
  LABEL: 'Leave Group Chat?',
  CONTENT: (
    <div>
      Are you sure you want to leave this group chat?
      <br />
      You will no longer be able to view chat history.
    </div>
  ),
};
export { LEAVE_GROUP };
