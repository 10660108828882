import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import { onEditRow } from 'utils/table';
import { actionCb } from 'utils/action';
import { PAGINATION } from 'helper/const';
import ROUTES from 'routes';
import NotFound from 'views/pages/ClientList/NotFound';
import { getReminderColumns, getIsDefaultSearchFilter } from './utils';
import FilterActions from './FilterActions';
import { useActions } from './selectorData';
import classes from './Reminders.module.scss';

const ReminderTab = props => {
  const history = useHistory();
  const {
    handleGetReminders,
    keyword,
    setKeyword,
    activeTab,
    allRemindersLoading,
    allReminders,
    allRemindersTotal,
    allRemindersIsMore,
    currentTab,
    handleCreateReminder,
    isNoData,
    updateStatusCb,
  } = props;
  const { changeReminderStatus, deleteReminder } = useActions();
  const [bulkDeleteLoading, setBulkDeleteLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const handleView = (record, isEdit) => {
    history.push(
      `${ROUTES.REMINDER_DETAIL({ id: record.id })}${
        isEdit ? '?edit=true' : ''
      }`
    );
  };
  const handleChangeStatus = record => {
    const isActiveReminder = record.status === 'active';
    const newStatus = isActiveReminder ? 'inactive' : 'active';
    changeReminderStatus(
      [record.id],
      newStatus,
      null,
      actionCb(() => {
        handleGetReminders(1, keyword);
        if (updateStatusCb) {
          updateStatusCb();
        }
        setCurrentPage(1);
      })
    );
  };
  const handleDelete = record => {
    deleteReminder(
      [record.id],
      actionCb(() => {
        handleGetReminders(1, keyword);
        setCurrentPage(1);
      })
    );
  };
  const handleBulkDelete = () => {
    setBulkDeleteLoading(true);
    deleteReminder(
      selectedRows.map(r => r.id),
      actionCb(
        () => {
          handleGetReminders(1, keyword);
          setCurrentPage(1);
          setBulkDeleteLoading(false);
        },
        () => {
          setBulkDeleteLoading(false);
        }
      )
    );
  };
  const columns = getReminderColumns({
    handleView,
    handleChangeStatus,
    handleDelete,
  });
  const isNotFound = getIsDefaultSearchFilter({ keyword }) && isNoData;
  const filterActionProps = {
    keyword,
    setKeyword,
    onSearch: val => {
      handleGetReminders(1, val);
      setCurrentPage(1);
    },
    selectedRows,
    onBulkDelete: handleBulkDelete,
    allReminders,
    bulkDeleteLoading,
  };

  useEffect(() => {
    if (activeTab === currentTab) {
      handleGetReminders(1, keyword);
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div
      className={cn(classes.mainContentTab, {
        [classes.isNotFound]: isNotFound,
      })}
    >
      {isNotFound ? (
        <NotFound
          onClick={handleCreateReminder}
          buttonText="Create New Alert"
          title="No alerts have been created yet. "
        />
      ) : (
        <>
          <FilterActions {...filterActionProps} />
          <Table
            rowSelection={{
              selectedRowKeys,
              onChange: (sRowKeys, sRows) => {
                setSelectedRowKeys(sRowKeys);
                setSelectedRows(sRows);
              },
            }}
            columns={columns}
            data={allReminders}
            rowKey="id"
            loading={allRemindersLoading}
            rowIsClick
            isScroll
            scroll={{ x: 680 }}
            onRow={record => {
              return {
                onClick: event => {
                  onEditRow(event, () => {
                    handleView(record);
                  });
                },
              };
            }}
            locale={{
              emptyText: `No alerts found`,
            }}
          />
          {allRemindersIsMore && (
            <Pagination
              current={currentPage}
              pageSize={PAGINATION.PER_PAGE}
              total={allRemindersTotal}
              right
              onChange={page => {
                setCurrentPage(page);
                handleGetReminders(page, keyword);
              }}
              className={classes.pagination}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ReminderTab;
