import pick from 'lodash/pick';
import omit from 'lodash/omit';
import { standardRequiredFieldObj, expDateFieldObj } from 'utils/form';
import { parseStringToNumber } from 'utils/number';
import { getBEDate, getMomentDate } from 'utils/date';

export const getAccountBR = data => {
  const returnObj = {
    name_on_account: data.name_on_account || '',
    bank_name: data.bank_name || '',
    ach: data.routing_number_ach || null,
    wire: data.routing_number_wire || null,
    account_number: data.account_number || null,
    type: data.type,
    is_primary: !!data.is_default_account ? 1 : 0,
  };
  return returnObj;
};
export const getCardBR = data => {
  const returnObj = {
    ...pick(data, ['name_on_card', 'card_type', 'credit_or_debit', 'zip_code']),
    card_number: parseStringToNumber(data.card_number),
    carrier_id: parseStringToNumber(data.carrier_id),
    pin: parseStringToNumber(data.pin),
    cvv: parseStringToNumber(data.cvv),
    expiration_date: getBEDate(data.expiration_date, false, 'YYYY-MM'),
    is_primary: data.is_primary ? 1 : 0,
  };
  if (data.address) {
    returnObj.address = data.address;
  }
  if (data.apt) {
    returnObj.apt = data.apt;
  }
  if (data.city) {
    returnObj.city = data.city;
  }
  if (data.state_id) {
    returnObj.state_id = data.state_id;
  }
  return returnObj;
};

export const setFullAccount = (account, accountId, form) => {
  const values = form.getFieldsValue();
  const accounts = (values.accounts || []).map(acc => {
    if (acc.originId === accountId) {
      acc = {
        ...acc,
        ...account,
        routing_number_ach: account.ach,
        routing_number_wire: account.wire,
        is_default_account: !!account.is_primary,
      };
    }
    return acc;
  });
  form.setFieldsValue({
    accounts,
  });
};
export const setFullCard = (newCardObj, cardId, form) => {
  const values = form.getFieldsValue();
  const cards = (values.cards || []).map(card => {
    if (card.originId === cardId) {
      card = {
        ...card,
        ...omit(newCardObj, ['id']),
        expiration_date: newCardObj.expiration_date
          ? getMomentDate(newCardObj.expiration_date)
          : '',
        is_primary: !!newCardObj.is_primary,
      };
    }
    return card;
  });
  form.setFieldsValue({
    cards,
  });
};
export const validateCheckingAccountObj = () => {
  return {
    name_on_account: [standardRequiredFieldObj],
    bank_name: [standardRequiredFieldObj],
    account_number: [standardRequiredFieldObj],
    type: [standardRequiredFieldObj],
  };
};
export const validateFuelCardObj = () => {
  return {
    card_number: [standardRequiredFieldObj],
    carrier_id: [standardRequiredFieldObj],
    pin: [standardRequiredFieldObj],
    cvv: [standardRequiredFieldObj],
    expiration_date: [standardRequiredFieldObj],
  };
};
export const getValidateArray = values => {
  const validateArray = [];
  const formAccounts = values.accounts || [];
  const formCards = values.cards || [];
  formAccounts.map((account, i) => {
    if (!account.originId) {
      validateArray.push({
        name: ['accounts', i, 'name_on_account'],
        rules: [standardRequiredFieldObj],
      });
      validateArray.push({
        name: ['accounts', i, 'bank_name'],
        rules: [standardRequiredFieldObj],
      });
      validateArray.push({
        name: ['accounts', i, 'account_number'],
        rules: [standardRequiredFieldObj],
      });
      validateArray.push({
        name: ['accounts', i, 'type'],
        rules: [standardRequiredFieldObj],
      });
    }
    return true;
  });
  formCards.map((card, i) => {
    if (!card.originId) {
      validateArray.push({
        name: ['cards', i, 'card_number'],
        rules: [standardRequiredFieldObj],
      });
      validateArray.push({
        name: ['cards', i, 'cvv'],
        rules: [standardRequiredFieldObj],
      });
      validateArray.push({
        name: ['cards', i, 'expiration_date'],
        rules: [standardRequiredFieldObj, expDateFieldObj],
      });
      validateArray.push({
        name: ['cards', i, 'carrier_id'],
        rules: [standardRequiredFieldObj],
      });
      validateArray.push({
        name: ['cards', i, 'pin'],
        rules: [standardRequiredFieldObj],
      });
    }
    return true;
  });
  return validateArray;
};
export const updateAccountCardIds = (form, accounts, cards) => {
  const values = form.getFieldsValue();
  const oldAccounts = values.accounts || [];
  const oldCards = values.cards || [];
  form.setFieldsValue({
    accounts: oldAccounts.map((acc, i) => {
      const returnObj = {
        ...acc,
      };
      if (accounts[i]) {
        returnObj.originId = accounts[i].id;
      }
      return returnObj;
    }),
    cards: oldCards.map((card, i) => {
      const returnObj = {
        ...card,
      };
      if (cards[i]) {
        returnObj.originId = cards[i].id;
      }
      return returnObj;
    }),
  });
};
