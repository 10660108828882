import { useStoreActions } from 'store/hooks';
import * as clientDetailSelectors from 'store/selectors/clientDetail';
// ACTIONS
import * as clientDetailActions from 'store/actions/clientDetail';

export const useIndexData = () => {
  return {
    searchSaferLoading: clientDetailSelectors.useSelectDOTSearchSaferLoading(),
    saveDOTLoading: clientDetailSelectors.useSelectSaveDOTLoading(),
    detailLoading: clientDetailSelectors.useSelectClientDetailLoading(),
    data: clientDetailSelectors.useSelectDOTClassifications()?.data || {},
  };
};

export const useActions = () => {
  return useStoreActions({
    ...clientDetailActions,
  });
};
