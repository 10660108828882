import { useStoreActions } from 'store/hooks';
// SELECTORS
// import * as clientDetailSelectors from 'store/selectors/clientDetail';
// import * as orderSelectors from 'store/selectors/order';

// ACTIONS
import * as myTeamActions from 'store/actions/myTeam';

export const useIndexData = () => {
  return {};
};

export const useActions = () => {
  return useStoreActions({
    ...myTeamActions,
  });
};
