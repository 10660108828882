import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import cn from 'classnames';
import { useModal } from 'components/Modal';
import toast from 'helper/toast';
import { getErrorMessageFromObj } from 'utils/object';
import { confirmModalBlueConfig, getConfirmModal } from 'utils/modal';
import Activity from './components/Activity';
import Header from './Header';
import MainContent from './MainContent';
import LoadingSpin from './LoadingSpin';
import { useIndexData, useActions } from './selectorData';
import classes from './ClientDetail.module.scss';

const ClientDetail = ({ match, location }) => {
  const [modal, contextHolder] = useModal();
  const confirmModal = getConfirmModal(modal, confirmModalBlueConfig);
  const [avatarFinalSrc, setAvatarFinalSrc] = useState('');
  const [isActivityCollapsed, setIsActivityCollapsed] = useState(false);
  const [finalFile, setFinalFile] = useState({});
  const {
    detailData,
    activityLog,
    activityLogLoading,
    addLogLoading,
  } = useIndexData();
  const {
    getClientDetail,
    getClientDetailLog,
    addLog,
    getCompanyOfficials,
    getFormsMSAs,
    getTrucks,
    getTrailers,
    getDrivers,
    getTrailersList,
    getDriversList,
    getLeasors,
    getAccounts,
    getFuelCards,
    getAllOrderTypesList,
    getOrderRequesterList,
    getOrderList,
    getBackgrounds,
    getLeasorsList,
    getDOTClassifications,
    getCompanyUsers,
  } = useActions();
  const { company_logo } = detailData;
  const clientId = get(match, 'params.id');
  useEffect(() => {
    setAvatarFinalSrc(company_logo || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_logo]);
  useEffect(() => {
    setFinalFile({});
    if (clientId) {
      getClientDetail({ id: clientId });
      getClientDetailLog({ id: clientId });
      getCompanyOfficials({ id: clientId }, { status: 'active' });
      getTrucks({ id: clientId }, { status: 'active' });
      getTrailers({ id: clientId }, { status: 'active' });
      getFormsMSAs({ id: clientId }, { status: 'active' });
      getDrivers({ id: clientId }, { status: 'active' });
      getLeasors({ id: clientId }, { status: 'active' });
      getBackgrounds({ id: clientId });
      getTrailersList(clientId);
      getDriversList(clientId);
      getAccounts(clientId);
      getFuelCards(clientId);
      getAllOrderTypesList();
      getOrderRequesterList(clientId);
      getOrderList(clientId);
      getLeasorsList(clientId);
      getDOTClassifications(clientId);
      getCompanyUsers(clientId, {
        sort_by: 'name',
        order: 'asc',
      });
    }
    // eslint-disable-next-line
  }, []);
  return (
    <LoadingSpin>
      <div
        className={cn(classes.wrapper, {
          [classes.isActivityCollapsed]: isActivityCollapsed,
        })}
      >
        <div className={classes.leftContent}>
          <Header
            avatarFinalSrc={avatarFinalSrc}
            setAvatarFinalSrc={setAvatarFinalSrc}
            finalFile={finalFile}
            setFinalFile={setFinalFile}
            confirmModal={confirmModal}
            clientId={clientId}
          />
          <div className={classes.contentWrapper}>
            <MainContent
              confirmModal={confirmModal}
              clientId={clientId}
              location={location}
            />
            <Activity
              className={classes.activity}
              activityLog={activityLog}
              isCollapsed={isActivityCollapsed}
              setIsCollapsed={setIsActivityCollapsed}
              name={detailData?.legal_name || ''}
              loading={activityLogLoading || addLogLoading}
              onAddNote={(val, cb) => {
                addLog(
                  clientId,
                  {
                    note: val,
                  },
                  (res, err) => {
                    if (res) {
                      if (cb) cb();
                      getClientDetailLog({ id: clientId }, {}, (res, err) => {
                        if (!res) {
                          toast.error(getErrorMessageFromObj(err)[0]);
                        }
                      });
                    } else {
                      toast.error(getErrorMessageFromObj(err)[0]);
                    }
                  }
                );
              }}
            />
          </div>
        </div>
        {contextHolder}
      </div>
    </LoadingSpin>
  );
};

export default ClientDetail;
