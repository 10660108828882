import React, { useState, useEffect } from 'react';
import { UserOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { Switch } from 'components/FormFields';
import { getMoreAvatars } from 'utils/avatar';
import { getStandardNameFromObj } from 'utils/text';
import { CHAT_TYPES } from 'helper/const';
import { showErrorMessage, leaveGroupFn } from './actionUtils';
import { ChatAvatar, Contact, Action } from './components/Elements';
import { useSettingContentData, useActions } from './selectorData';
import classes from './Chat.module.scss';

const SettingContent = ({
  isCollapse,
  confirmModal,
  onUpdateContact,
  displayMembers,
  detailObj,
  setDetailObj,
  setIsInitEdit,
  setIsEditing,
  isClientTab,
}) => {
  const { conversation_id, type, isIndividual } = detailObj;
  const isInternalDepartment = type === CHAT_TYPES.INTERNAL_DEPARTMENT;
  const isClientDepartment = type === CHAT_TYPES.CLIENT_DEPARTMENT;
  // Do not show add and remove members feature
  const notUpdateMembers =
    isInternalDepartment || isIndividual || isClientTab || isClientDepartment;
  const { currentUser, conversationDetail } = useSettingContentData();
  const [notification, setNotification] = useState({
    message: !!conversationDetail.notification,
    sound: !!conversationDetail.sound,
  });
  const {
    removeMembers,
    getChatMembers,
    getInternalConversations,
    updateChatSettings,
    syncUpdateConversationDetail,
  } = useActions();
  const { isMoreAvatar, moreLength, displayAvatars } = getMoreAvatars(
    displayMembers
  );
  const handleNotificationChange = name => checked => {
    const notificationType = name === 'sound' ? name : 'notification';
    const notificationValue = checked ? 1 : 0;
    setNotification({
      ...notification,
      [name]: checked,
    });
    updateChatSettings(
      conversation_id,
      {
        type: notificationType,
        value: notificationValue,
      },
      (res, err) => {
        if (!res) {
          showErrorMessage(err);
          setNotification({
            ...notification,
            [name]: !checked,
          });
        } else {
          syncUpdateConversationDetail({
            attr: notificationType,
            value: notificationValue,
          });
        }
      }
    );
  };
  const handleLeaveGroup = d => {
    removeMembers(
      conversation_id,
      {
        members: [d.user_id],
      },
      (res, err) => {
        if (res) {
          getInternalConversations();
          if (d.user_id === currentUser.id) {
            setDetailObj({});
            setIsInitEdit(false);
            setIsEditing(false);
          } else {
            getChatMembers(conversation_id);
          }
        } else {
          showErrorMessage(err);
        }
      }
    );
  };
  useEffect(() => {
    if (conversationDetail.name) {
      setNotification({
        message: !!conversationDetail.notification,
        sound: !!conversationDetail.sound,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationDetail]);
  return (
    <div className={cn(classes.detailContent, classes.settingContentWrapper)}>
      <div className={classes.detailContentInner}>
        <div className={classes.settingAvatars}>
          {displayAvatars.map((d, i) => {
            return (
              <ChatAvatar
                avatar={d.profile_pic}
                departmentColor={d.group}
                name={getStandardNameFromObj(d)}
                key={i}
                className={classes.avatar}
                isMe={!d.group}
              />
            );
          })}
          {isMoreAvatar && (
            <ChatAvatar
              name={`+${moreLength}`}
              className={classes.avatar}
              isDisplayFullName
              isMe
            />
          )}
        </div>
        <div className={classes.settingNotification}>
          <div>
            <label htmlFor="messageNotification">
              New Message Notifications
            </label>
            <Switch
              checked={notification.message}
              onChange={handleNotificationChange('message')}
            />
          </div>
          <div>
            <label htmlFor="soundNotification">
              Notification Sounds<span>Play sounds for new notifications</span>
            </label>
            <Switch
              checked={notification.sound}
              onChange={handleNotificationChange('sound')}
            />
          </div>
        </div>
        <div className={classes.settingMembers}>
          <h3>Members</h3>
          {displayMembers.map((d, i) => {
            const isMe = currentUser.id === d.user_id;
            const action = (
              <Action
                actions={[
                  {
                    label: 'Leave group',
                    props: {
                      onClick: () => {
                        leaveGroupFn({
                          confirmModal,
                          onLeave: () => {
                            handleLeaveGroup(d);
                          },
                        });
                      },
                    },
                  },
                ]}
              />
            );
            return (
              <Contact
                {...d}
                isClient={isClientTab && !isMe && !d.department}
                key={i}
                role={d.company_name || ''}
                isCollapse={isCollapse}
                isAddContact
                isMe={isMe}
                avatar={d.profile_pic || ''}
                additionalAction={!isMe || notUpdateMembers ? <></> : action}
                hideUnread
              />
            );
          })}
        </div>
        {!notUpdateMembers && (
          <div
            className={classes.settingMemberAction}
            onClick={onUpdateContact}
          >
            <span>Add or remove members</span>
            <UserOutlined />
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingContent;
