import React from 'react';
import { Form as AntdForm } from 'antd';

const { List } = AntdForm;

const FormList = props => {
  return <List {...props} />;
};

export default FormList;
