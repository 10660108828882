import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import { CameraFilled } from '@ant-design/icons';
import { FILE_TYPE_ACCEPT, MODAL, FILE_SIZE_LIMIT } from 'helper/const';
import Upload from 'components/Upload';
import Button, { ButtonColors } from 'components/Button';
import { dataURLtoFile } from 'utils/file';
import { getImageDataURI, getCroppedImg } from 'utils/image';
import CropImageModal from './CropImageModal';
import { ProfilePicDisplay } from './Elements';
import { isValidPicture } from './utils';
import classes from './MyProfile.module.scss';

const defaultCropObj = {
  crop: { x: 0, y: 0 },
  zoom: 1,
  aspect: 1,
};

const ProfilePicture = ({
  isEdit,
  confirmModal,
  profilePic,
  avatarFinalSrc,
  setAvatarFinalSrc,
  finalFile,
  setFinalFile,
  alowAllFile,
  hideButton,
  disabled = false,
  onCropFile,
  loading,
  defaultImg,
  nonStyle,
  uploadElement,
  uploadClassName,
  onDelete = null,
  className,
}) => {
  const [avatarSrc, setAvatarSrc] = useState('');
  const [cropObj, setCropObj] = useState(defaultCropObj);
  const [isOpen, setIsOpen] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const modalProps = {
    isOpen,
    onSubmit: () => {
      getCropperFile();
    },
    onClose: () => {
      confirmModal(
        'Discard Changes?',
        <div>Are you sure you want to discard your changes?</div>,
        () => {
          setIsOpen(false);
          setCropObj(defaultCropObj);
        },
        {
          type: 'confirm',
          onCancel: () => {},
          okText: 'Discard',
          centered: true,
          className: classes.myProfileWarningModal,
        }
      );
    },
    title: <>Update Profile PIcture</>,
    maskClosable: false,
    closable: true,
    cancelText: 'Cancel',
    okText: 'Save',
    width: MODAL.SIZE.SEMI_LARGE,
    className: classes.cropperPicModal,
    isSubmitLoading: false,
    closeIcon: null,
    okBtnProps: {
      size: 'large',
    },
    cancelBtnProps: {
      size: 'large',
    },
    blueColor: true,
  };
  const onCropChange = crop => {
    setCropObj({
      ...cropObj,
      crop,
    });
  };
  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const onZoomChange = zoom => {
    setCropObj({
      ...cropObj,
      zoom,
    });
  };
  const getCropperFile = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        avatarSrc,
        croppedAreaPixels,
        finalFile.type
      );
      const cropperFile = dataURLtoFile(croppedImage, finalFile.name);
      if (onCropFile) {
        onCropFile(cropperFile, croppedImage);
      } else {
        setFinalFile(cropperFile);
        setAvatarFinalSrc(croppedImage);
      }
      setIsOpen(false);
      setCropObj(defaultCropObj);
    } catch (e) {
      console.error(e);
    }
  }, [
    avatarSrc,
    croppedAreaPixels,
    finalFile.name,
    finalFile.type,
    onCropFile,
    setAvatarFinalSrc,
    setFinalFile,
  ]);

  return (
    <div
      className={cn(
        classes.avatar,
        {
          [classes.hideButton]: hideButton,
          [classes.nonStyle]: nonStyle,
        },
        'ant-profile-pic-avatar',
        className
      )}
    >
      {!hideButton && (
        <ProfilePicDisplay
          isEdit={isEdit}
          avatarFinalSrc={avatarFinalSrc}
          profilePic={profilePic}
          defaultImg={defaultImg}
          onDelete={onDelete}
          nonStyle={nonStyle}
          isDelete={isDelete}
          setIsDelete={setIsDelete}
        />
      )}
      {isEdit && (
        <>
          <Upload
            fileList={[]}
            accept={alowAllFile ? '' : FILE_TYPE_ACCEPT.IMAGE}
            beforeUpload={file => {
              if (isValidPicture(file, confirmModal, classes)) {
                setFinalFile(file);
                getImageDataURI(file, imageUrl => {
                  setAvatarSrc(imageUrl);
                  setIsOpen(true);
                });
              }
              return false;
            }}
            disabled={disabled || isDelete}
            className={uploadClassName}
          >
            {!hideButton ? (
              <>
                {uploadElement || (
                  <Button
                    type={'ghost'}
                    size={'large'}
                    htmlType={'button'}
                    loading={false}
                    color={ButtonColors.BLUE}
                    leftIcon={<CameraFilled />}
                  >
                    Update
                  </Button>
                )}
              </>
            ) : (
              <ProfilePicDisplay
                isEdit={isEdit}
                avatarFinalSrc={avatarFinalSrc}
                profilePic={profilePic}
                updateText
                loading={loading}
                onDelete={onDelete}
                nonStyle={nonStyle}
                isDelete={isDelete}
                setIsDelete={setIsDelete}
              />
            )}
          </Upload>
          {!hideButton && !uploadElement && (
            <p>JPG, PNG up to {FILE_SIZE_LIMIT.IMAGE}MB</p>
          )}
        </>
      )}
      <CropImageModal
        modalProps={modalProps}
        avatarSrc={avatarSrc}
        cropObj={cropObj}
        onCropChange={onCropChange}
        onCropComplete={onCropComplete}
        onZoomChange={onZoomChange}
        setCropObj={setCropObj}
      />
    </div>
  );
};

export default ProfilePicture;
