import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  clientTypes,
} from '../types';
import get from 'lodash/get';
import { initialClientState } from '../states/client';

export default (state = initialClientState, action) => {
  const resData = get(action, 'response.data') || {};
  const { next_page_url, current_page, data = [], total = 0 } = resData;
  const isMore = get(action, 'payload.isMore');
  const isBEMore = !!next_page_url || current_page > 1;
  switch (action.type) {
    // GET_ALL_CLIENTS
    case asyncActionTypeRequest(clientTypes.GET_ALL_CLIENTS): {
      return {
        ...state,
        allClients: {
          ...state.allClients,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(clientTypes.GET_ALL_CLIENTS): {
      return {
        ...state,
        allClients: {
          data: isMore ? [...state.allClients.data, ...data] : data,
          totalNo: total || 0,
          loading: false,
          isMore: isBEMore,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(clientTypes.GET_ALL_CLIENTS): {
      return {
        ...state,
        allClients: {
          loading: false,
          error: action.payload,
          data: [],
          totalNo: 0,
          isMore: false,
        },
      };
    }
    // GET_ARCHIVED_CLIENTS
    case asyncActionTypeRequest(clientTypes.GET_ARCHIVED_CLIENTS): {
      return {
        ...state,
        archivedClients: {
          ...state.archivedClients,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(clientTypes.GET_ARCHIVED_CLIENTS): {
      return {
        ...state,
        archivedClients: {
          data: isMore ? [...state.archivedClients.data, ...data] : data,
          totalNo: total || 0,
          loading: false,
          isMore: isBEMore,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(clientTypes.GET_ARCHIVED_CLIENTS): {
      return {
        ...state,
        archivedClients: {
          loading: false,
          error: action.payload,
          data: [],
          totalNo: 0,
          isMore: false,
        },
      };
    }
    // CREATE_CLIENT
    case asyncActionTypeRequest(clientTypes.CREATE_CLIENT): {
      return {
        ...state,
        createClient: {
          ...state.createClient,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(clientTypes.CREATE_CLIENT): {
      return {
        ...state,
        createClient: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(clientTypes.CREATE_CLIENT): {
      return {
        ...state,
        createClient: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    default:
      return state;
  }
};
