import React from 'react';
import cx from 'classnames';
import { Avatar as AntdAvatar } from 'antd';

import classes from './Avatar.module.scss';

const Avatar = props => {
  const {
    children,
    className,
    card,
    border,
    notRadius,
    size,
    shape,
    ...other
  } = props;
  return (
    <span
      className={cx(
        classes.wrapper,
        {
          [classes.card]: card,
          [classes.cardExtra]: size === 'extra',
          [classes.cardAuto]: size === 'auto',
          [classes.border]: border,
          [classes.notRadius]: notRadius,
        },
        className
      )}
    >
      <AntdAvatar {...other} size={size} shape={card ? 'square' : shape}>
        {children}
      </AntdAvatar>
    </span>
  );
};

export default Avatar;
