import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Collapse, { Panel } from 'components/Collapse';
import Header from 'views/pages/ClientDetail/CompanyOfficial/components/Header';
import NotFound from 'views/pages/ClientDetail/CompanyOfficial/components/NotFound';
import StatusTag from 'views/pages/ClientDetail/CompanyOfficial/components/StatusTag';
import PanelHeader from 'views/pages/ClientDetail/CompanyOfficial/PanelHeader';
import {
  isAlreadyNew,
  afterCbFn,
} from 'views/pages/ClientDetail/CompanyOfficial/utils';
import { CollapseTable } from 'views/pages/ClientDetail/components/Elements';
import {
  onKeyChangeFn,
  getEditKeys,
  editChangeFn,
  getIsKey,
} from 'views/pages/ClientDetail/utils';
import Section from './components/Section';
import { useActions, useIndexData } from './selectorData';
import { DEFAULT_SECTIONS, NEW_KEY } from './constants';
import { getBusinessAssociationsBR } from './utils';
import classes from './BusinessAssociations.module.scss';

const BusinessAssociations = ({
  className,
  confirmModal,
  openWarningModal,
  clientId,
  setIsEdit,
  setIsFormChanged,
  activeTab,
  tabIndex,
  status,
  setStatus,
  tabName,
  onUpdated,
}) => {
  const {
    getBusinessAssociations,
    createUnderwritingRecord,
    updateUnderwritingRecord,
    updateUnderwritingRecordStatus,
  } = useActions();
  const { businessAssociations, updateBALoading } = useIndexData();
  const setIsEditParent = setIsEdit;
  const [createLoading, setCreateLoading] = useState(false);
  const [sections, setSections] = useState(DEFAULT_SECTIONS);
  const [statusLoading, setStatusLoading] = useState({});
  const [activeKeys, setActiveKeys] = useState([]);
  const [editKeys, setEditKeys] = useState([]);
  const hasEdit = sections.length > 0 || editKeys.length > 0;
  const [editChanged, setEditChanged] = useState({});
  const notFound = sections.length === 0 && businessAssociations.length === 0;
  const handleLoadBA = (loadStatus, cb) => {
    const payloadData = {};
    const payloadStatus = loadStatus || status;
    if (payloadStatus !== 'all') {
      payloadData.status = payloadStatus;
    }
    getBusinessAssociations({ id: clientId }, payloadData, res => {
      if (res && cb) cb();
    });
  };
  const handleEditChange = (...params) =>
    editChangeFn(editChanged, setEditChanged, setIsEdit, ...params);
  const handleSubmit = id => (values, cb) => {
    const baId = id && id !== NEW_KEY ? id : '';
    const actionFn = baId ? updateUnderwritingRecord : createUnderwritingRecord;
    if (!baId) {
      setCreateLoading(true);
    }
    actionFn(
      clientId,
      getBusinessAssociationsBR(values, baId),
      afterCbFn(
        res => {
          if (onUpdated) onUpdated();
          if (!baId) {
            handleLoadBA(null, () => {
              setCreateLoading(false);
              if (cb) cb();
            });
          } else if (cb) cb();
        },
        () => {
          if (!baId) setCreateLoading(false);
        }
      ),
      !baId ? tabName : baId,
      tabName
    );
  };
  const handleSetStatusLoading = (id, val) => {
    setStatusLoading({
      ...statusLoading,
      [id]: val,
    });
  };
  const handleStatusChange = id => newStatus => {
    handleSetStatusLoading(id, true);
    updateUnderwritingRecordStatus(
      clientId,
      { status: newStatus },
      afterCbFn(
        () => {
          if (onUpdated) onUpdated();
          handleLoadBA(null, () => {
            handleSetStatusLoading(id, false);
            setActiveKeys(activeKeys.filter(key => String(key) !== String(id)));
          });
        },
        () => handleSetStatusLoading(id, false)
      ),
      id,
      tabName
    );
  };
  const handleListStatusChange = newStatus => {
    handleLoadBA(newStatus);
  };

  useEffect(() => {
    setIsEditParent(hasEdit);
    setIsFormChanged(hasEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections, editKeys]);
  useEffect(() => {
    if (String(activeTab) !== String(tabIndex) && hasEdit) {
      if (sections.length > 0) {
        setSections(DEFAULT_SECTIONS);
      }
      if (editKeys.length > 0) {
        setEditKeys([]);
      }
    }
    if (String(activeTab) === String(tabIndex)) {
      handleLoadBA();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div className={cn(classes.wrapper, className)}>
      <Header
        title="Business Associations"
        status={status}
        setStatus={val => {
          setStatus(val);
          handleListStatusChange(val);
        }}
        onAdd={() => {
          if (!isAlreadyNew(sections)) {
            setSections([
              { label: 'Company Name', id: NEW_KEY, isNew: true },
              ...sections,
            ]);
            setEditKeys([...editKeys, NEW_KEY]);
            setActiveKeys([...activeKeys, NEW_KEY]);
          }
        }}
        className={classes.header}
      />
      {notFound ? (
        <NotFound />
      ) : (
        <div className={classes.content}>
          <CollapseTable hideHeader section className={classes.sectionTable}>
            <Collapse
              destroyInactivePanel
              onChange={keys =>
                onKeyChangeFn(keys, activeKeys, editKeys, setActiveKeys)
              }
              activeKey={activeKeys}
            >
              {[...sections, ...businessAssociations].map(section => {
                const { isNew, id, business_name } = section;
                const key = String(id);
                const label = isNew ? section.label : business_name;
                const isActive = getIsKey(activeKeys, key);
                const isEdit = getIsKey(editKeys, key);
                const sectionInnerProps = {
                  clientId,
                  rootKey: key,
                  isEdit,
                  isActive,
                  confirmModal,
                  setIsEdit: edit => {
                    const newEditKeys = getEditKeys(editKeys, edit, key);
                    setEditKeys(newEditKeys);
                    if (!edit) {
                      handleEditChange(key, false, newEditKeys);
                    }
                    if (isNew && !edit) {
                      setSections(DEFAULT_SECTIONS);
                    }
                  },
                  data: section,
                  onFormChange: () => {
                    handleEditChange(key, true, editKeys);
                  },
                  openWarningModal,
                  loading: createLoading || updateBALoading[id],
                  statusLoading: statusLoading[id],
                  onSubmit: handleSubmit(id),
                  onStatusChange: handleStatusChange(id),
                };
                const pHeaderProps = {
                  isEdit,
                  label,
                  isActive,
                  className: cn(classes.panelHeader),
                };
                return (
                  <Panel
                    forceRender={true}
                    className={classes.panel}
                    header={<PanelHeader {...pHeaderProps} />}
                    key={key}
                    extra={
                      !isActive ? <StatusTag status={section.status} /> : null
                    }
                  >
                    <Section {...sectionInnerProps} />
                  </Panel>
                );
              })}
            </Collapse>
          </CollapseTable>
        </div>
      )}
    </div>
  );
};

export default BusinessAssociations;
