import React, { useEffect } from 'react';
import cn from 'classnames';
import Form, { useForm } from 'components/Form';
import {
  Title,
  SectionWrapper,
} from 'views/pages/ClientDetail/components/Elements';
import ButtonActions from 'views/pages/ClientDetail/components/ButtonActions';
import OnlinePortal from 'views/pages/ClientDetail/components/OnlinePortal';
import Profile from './Profile';
import License from './License';
import { getInitialValues } from '../../tabUtils/ifta';
import { filingTransformData } from '../../tabUtils/index';
import classes from './IFTA.module.scss';

const IFTA = ({
  className,
  wrapperId,
  handleSubmit,
  isEdit,
  setIsEdit,
  isFormChange,
  loading,
  openWarningModal,
  data,
  setIsFormChanged,
  onFormChange,
  years,
  handleYearSubmit,
}) => {
  const transformData = filingTransformData(data);
  const [form] = useForm();
  const initialValues = getInitialValues(data);

  useEffect(() => {
    if (isEdit) {
      setIsFormChanged(false);
      if (form) {
        form.resetFields();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  return (
    <SectionWrapper className={cn(classes.wrapper, className)} id={wrapperId}>
      <Form
        validateMessages={''}
        onFinish={values => {
          handleSubmit(values, form);
        }}
        initialValues={initialValues}
        form={form}
        onValuesChange={() => {
          setIsFormChanged(true);
          onFormChange();
        }}
      >
        <div>
          <Title text="IFTA">
            <ButtonActions
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              form={form}
              isFormChanged={isFormChange}
              loading={loading}
              className={classes.buttonActions}
              onSubmit={values => {
                handleSubmit(values, form);
              }}
              openWarningModal={openWarningModal}
            />
          </Title>
          <Profile isEdit={isEdit} form={form} data={transformData} />
          <OnlinePortal
            title="IFTA Online Portal"
            form={form}
            isEdit={isEdit}
            data={transformData}
            fields={[
              {
                name: 'username',
                label: 'Username',
                type: 'value',
                key: 'username',
              },
              {
                name: 'password',
                label: 'Password',
                type: 'value',
                key: 'password',
              },
            ]}
          />
        </div>
        <div>
          <License
            years={years}
            form={form}
            data={transformData}
            handleYearSubmit={handleYearSubmit}
            initialValues={initialValues}
          />
        </div>
      </Form>
    </SectionWrapper>
  );
};

export default IFTA;
