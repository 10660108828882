import React from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { Switch } from 'components/FormFields';
import Space from 'components/Space';

const SwitchComponent = props => {
  return (
    <Space direction={'vertical'}>
      <Space direction={'horizontal'} size={32}>
        <Switch />
        <Switch defaultChecked={true} />
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
        <Switch
          defaultChecked
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
        <Switch checkedChildren={'1'} unCheckedChildren={'0'} />
        <Switch checkedChildren={'1'} unCheckedChildren={'0'} defaultChecked />
      </Space>
      <Space direction={'horizontal'} size={32}>
        <Switch disabled />
        <Switch defaultChecked={true} disabled />
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          disabled
        />
        <Switch
          defaultChecked
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          disabled
        />
        <Switch checkedChildren={'1'} unCheckedChildren={'0'} disabled />
        <Switch
          checkedChildren={'1'}
          unCheckedChildren={'0'}
          defaultChecked
          disabled
        />
      </Space>
      <Space direction={'horizontal'} size={32}>
        <Switch size={'small'} />
        <Switch defaultChecked={true} size={'small'} />
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          size={'small'}
        />
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          size={'small'}
          defaultChecked
        />
        <Switch checkedChildren={'1'} unCheckedChildren={'0'} size={'small'} />
        <Switch
          checkedChildren={'1'}
          unCheckedChildren={'0'}
          size={'small'}
          defaultChecked
        />
      </Space>
      <Space direction={'horizontal'} size={32}>
        <Switch size={'small'} disabled />
        <Switch defaultChecked={true} size={'small'} disabled />
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          size={'small'}
          disabled
        />
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          size={'small'}
          defaultChecked
          disabled
        />
        <Switch
          checkedChildren={'1'}
          unCheckedChildren={'0'}
          size={'small'}
          disabled
        />
        <Switch
          checkedChildren={'1'}
          unCheckedChildren={'0'}
          size={'small'}
          defaultChecked
          disabled
        />
      </Space>
    </Space>
  );
};

export default SwitchComponent;
