import React from 'react';
import cn from 'classnames';

import classes from './Elements.module.scss';

const MessagesGroupTime = ({ children, className }) => {
  return (
    <div className={cn(classes.messagesGroupTime, className)}>{children}</div>
  );
};

export default MessagesGroupTime;
