import {
  apiGetRoles,
  apiGetClientList,
  apiGetStatusList,
  apiGetEmployeeList,
  apiVinDecoder,
  apiGetDepartmentUsers,
  apiGetSystemCodes,
  apiGetDepartments,
  apiGetDepartmentsList,
  apiGetPositions,
  apiUploadFile,
  apiGetFieldCategories,
  apiGetStates,
  apiGetTrailersList,
  apiGetDriversList,
  apiGetLeasorsList,
  apiGetBankName,
  apiGetPClientList,
  apiGetPOrderTypeList,
  apiGetPEmployeeList,
  apiCreatePIN,
} from 'api';

import { sharedTypes } from '../types';

// GET_STATES
export const getStates = cb => {
  return {
    isAsyncCall: true,
    baseType: sharedTypes.GET_STATES,
    asyncCall: () => {
      return apiGetStates();
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_FIELD_CATEGORIES
export const getFieldCategories = cb => {
  return {
    isAsyncCall: true,
    baseType: sharedTypes.GET_FIELD_CATEGORIES,
    asyncCall: () => {
      return apiGetFieldCategories();
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_POSITIONS
export const getPositions = (filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: sharedTypes.GET_POSITIONS,
    asyncCall: () => {
      return apiGetPositions(filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_DEPARTMENTS
export const getDepartments = (filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: sharedTypes.GET_DEPARTMENTS,
    asyncCall: () => {
      return apiGetDepartments(filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_DEPARTMENTS_LIST
export const getDepartmentsList = (filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: sharedTypes.GET_DEPARTMENTS_LIST,
    asyncCall: () => {
      return apiGetDepartmentsList(filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_SYSTEM_CODES
export const getSystemCodes = (filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: sharedTypes.GET_SYSTEM_CODES,
    asyncCall: () => {
      return apiGetSystemCodes(filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_SALES_DEPARTMENT_USERS
export const getSalesDepartmentUsers = cb => {
  return {
    isAsyncCall: true,
    baseType: sharedTypes.GET_SALES_DEPARTMENT_USERS,
    asyncCall: () => {
      return apiGetDepartmentUsers({
        type: 'sales',
      });
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_TRAILERS_LIST
export const getTrailersList = (companyId, cb) => {
  return {
    isAsyncCall: true,
    baseType: sharedTypes.GET_TRAILERS_LIST,
    asyncCall: () => {
      return apiGetTrailersList(companyId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_DRIVERS_LIST
export const getDriversList = (companyId, cb) => {
  return {
    isAsyncCall: true,
    baseType: sharedTypes.GET_DRIVERS_LIST,
    asyncCall: () => {
      return apiGetDriversList(companyId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_LEASORS_LIST
export const getLeasorsList = (companyId, cb) => {
  return {
    isAsyncCall: true,
    baseType: sharedTypes.GET_LEASORS_LIST,
    asyncCall: () => {
      return apiGetLeasorsList(companyId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_ROLES
export const getRoles = cb => {
  return {
    isAsyncCall: true,
    baseType: sharedTypes.GET_ROLES,
    asyncCall: () => {
      return apiGetRoles();
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPLOAD_FILE
export const uploadFile = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: sharedTypes.UPLOAD_FILE,
    asyncCall: () => {
      return apiUploadFile(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// VIN_DECODER
export const vinDecoder = (vinNumber, cb) => {
  return {
    isAsyncCall: true,
    baseType: sharedTypes.VIN_DECODER,
    asyncCall: () => {
      return apiVinDecoder(vinNumber);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_BANK_NAME
export const getBankName = (routingNumber, cb) => {
  return {
    isAsyncCall: true,
    baseType: sharedTypes.GET_BANK_NAME,
    payload: {},
    asyncCall: () => {
      return apiGetBankName(routingNumber);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_EMPLOYEE_LIST
export const getEmployeeList = cb => {
  return {
    isAsyncCall: true,
    baseType: sharedTypes.GET_EMPLOYEE_LIST,
    payload: {},
    asyncCall: () => {
      return apiGetEmployeeList();
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_CLIENT_LIST
export const getClientList = cb => {
  return {
    isAsyncCall: true,
    baseType: sharedTypes.GET_CLIENT_LIST,
    payload: {},
    asyncCall: () => {
      return apiGetClientList();
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_STATUS_LIST
export const getStatusList = cb => {
  return {
    isAsyncCall: true,
    baseType: sharedTypes.GET_STATUS_LIST,
    payload: {},
    asyncCall: () => {
      return apiGetStatusList();
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_PAGINATION_CLIENT_LIST
export const getPClientList = (filters, cb) => {
  const { isMore, ...other } = filters || {};
  return {
    isAsyncCall: true,
    baseType: sharedTypes.GET_PAGINATION_CLIENT_LIST,
    payload: { isMore },
    asyncCall: () => {
      return apiGetPClientList(other);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_PAGINATION_ORDER_TYPE_LIST
export const getPOrderTypeList = (filters, cb) => {
  const { isMore, ...other } = filters || {};
  return {
    isAsyncCall: true,
    baseType: sharedTypes.GET_PAGINATION_ORDER_TYPE_LIST,
    payload: { isMore },
    asyncCall: () => {
      return apiGetPOrderTypeList(other);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_PAGINATION_EMPLOYEE_LIST
export const getPEmployeeList = (filters, cb) => {
  const { isMore, ...other } = filters || {};
  return {
    isAsyncCall: true,
    baseType: sharedTypes.GET_PAGINATION_EMPLOYEE_LIST,
    payload: { isMore },
    asyncCall: () => {
      return apiGetPEmployeeList(other);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CREATE_PIN
export const createPIN = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: sharedTypes.CREATE_PIN,
    payload: {},
    asyncCall: () => {
      return apiCreatePIN(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
