import React from 'react';
import find from 'lodash/find';
import cn from 'classnames';
import { Select } from 'components/FormFields';
import {
  getIsDefaultFilter,
  getIsDefaultFilters,
  getAppliedFilters,
} from './utils';
import classes from './WorkQueue.module.scss';

export const getFilterMenus = ({
  filters,
  onFilterChange,
  employeeOptions,
  clientOptions,
  statusOptions,
  allOrderTypeOptions,
  onClear,
  metadata,
  loadMore,
  searchData,
}) => {
  const commonProps = {
    placeholder: 'All',
    size: 'medium',
    isMultipleOptionCheckbox: true,
    allValue: 'all',
    showSearch: true,
    showArrow: true,
    mode: 'multiple',
    isBlue: true,
    seeFullOption: true,
    // dropdownMatchSelectWidth: false,
  };
  const menuLabel = [
    {
      subs: {
        label: (
          <div className={cn(classes.filterLabelWrapper, {})}>
            Filter Options
          </div>
        ),
      },
    },
  ];
  const menus = [
    ...menuLabel,
    {
      subs: {
        label: (
          <div className={classes.filterForm}>
            <span>By Employee</span>
            <Select
              items={[
                {
                  value: 'all',
                  label: 'All',
                },
                ...employeeOptions,
              ]}
              name={'employee'}
              onChange={val => {
                onFilterChange('employee', val);
              }}
              value={filters?.employee}
              {...commonProps}
              asyncLoadMore
              syncLoadMoreData={(tSearch, tPage, cb) => {
                if (loadMore) loadMore(tSearch, tPage, cb, 'employee');
              }}
              syncLoadData={tSearch => {
                if (searchData) searchData(tSearch, 'employee');
              }}
              metadata={metadata?.employee}
            />
            <span>By Order Type</span>
            <Select
              items={[
                {
                  value: 'all',
                  label: 'All',
                },
                ...allOrderTypeOptions,
              ]}
              name={'order_type'}
              onChange={val => {
                onFilterChange('order_type', val);
              }}
              value={filters?.order_type}
              {...commonProps}
              asyncLoadMore
              syncLoadMoreData={(tSearch, tPage, cb) => {
                if (loadMore) loadMore(tSearch, tPage, cb, 'order_type');
              }}
              syncLoadData={tSearch => {
                if (searchData) searchData(tSearch, 'order_type');
              }}
              metadata={metadata?.orderType}
            />
            <span>By Client</span>
            <Select
              items={[
                {
                  value: 'all',
                  label: 'All',
                },
                ...clientOptions,
              ]}
              name={'client'}
              onChange={val => {
                onFilterChange('client', val);
              }}
              value={filters?.client}
              {...commonProps}
              asyncLoadMore
              syncLoadMoreData={(tSearch, tPage, cb) => {
                if (loadMore) loadMore(tSearch, tPage, cb, 'client');
              }}
              syncLoadData={tSearch => {
                if (searchData) searchData(tSearch, 'client');
              }}
              metadata={metadata?.client}
              // dropdownAlign={{ offset: [-243, 0] }}
              // dropdownClassName={classes.clientDropdown}
            />
            <span>By Order Status</span>
            <Select
              items={[
                {
                  value: 'all',
                  label: 'All',
                },
                ...statusOptions,
              ]}
              name={'order_status'}
              onChange={val => {
                onFilterChange('order_status', val);
              }}
              value={filters?.order_status}
              {...commonProps}
            />
            <div className={classes.clearAllFilters}>
              <span onClick={onClear}>Clear All Filters</span>
            </div>
          </div>
        ),
      },
    },
  ];
  return menus;
};
const getEachFilterDisplay = (key, label, filter, options, rawFilter) => {
  if (!getIsDefaultFilter(rawFilter)) {
    if (filter.length === 1) {
      const id = filter[0];
      const foundObj = find(options, { value: id }) || {};
      return {
        ids: [id],
        key,
        label: (
          <>
            {label}:&nbsp;<b>{foundObj.label}</b>
          </>
        ),
      };
    } else if (filter.length > 1) {
      return {
        ids: filter,
        key,
        label: (
          <>
            {label}:&nbsp;<b>{filter.length} Selected</b>
          </>
        ),
      };
    }
  }
  return null;
};
export const getFiltersDisplayData = ({
  filters,
  employeeOptions,
  clientOptions,
  statusOptions,
  allOrderTypeOptions,
}) => {
  if (getIsDefaultFilters(filters)) {
    return [];
  }
  const returnArr = [];
  const { employees, clients, orderTypes, orderStatuses } = getAppliedFilters(
    filters
  );
  const filterMapping = [
    {
      key: 'order_status',
      label: 'Order Status',
      value: orderStatuses,
      options: statusOptions,
      rawValue: filters.order_status,
    },
    {
      key: 'client',
      label: 'Client',
      value: clients,
      options: clientOptions,
      rawValue: filters.client,
    },
    {
      key: 'order_type',
      label: 'Order Type',
      value: orderTypes,
      options: allOrderTypeOptions,
      rawValue: filters.order_type,
    },
    {
      key: 'employee',
      label: 'Employee',
      value: employees,
      options: employeeOptions,
      rawValue: filters.employee,
    },
  ];
  filterMapping.map(fm => {
    const fmVal = getEachFilterDisplay(
      fm.key,
      fm.label,
      fm.value,
      fm.options,
      fm.rawValue
    );
    if (fmVal) {
      returnArr.push(fmVal);
    }
    return true;
  });
  return returnArr;
};
