import React from 'react';
import cn from 'classnames';
import { ZIP_CODE_NUMBER_FIELD } from 'helper/const';
import { useOptions } from 'helper/hooks';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  Title,
  MailingAddressWrapper,
} from 'views/pages/ClientDetail/components/Elements';
import { FakeListFields } from 'components/FormFields/FakeAutocompleteField';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { ADDRESSES_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const Address = ({ className, isEdit, form, data, disabled }) => {
  const { stateOptions } = useOptions();

  return (
    <div className={cn(classes.addressSection, className)}>
      <Title text="Addresses" />
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            <FakeListFields
              fields={[
                'address',
                'apt',
                'city',
                'county',
                'state_id',
                'zip_code',
                'mailing_address',
                'mailing_apt',
                'mailing_city',
                'mailing_county',
                'mailing_state_id',
                'mailing_zip_code',
              ]}
            />
            <InputItem
              name={'address'}
              label="Physical Address"
              form={form}
              addressField
              disabled={disabled}
              autoComplete="chrome-off"
            />
            <InputItem
              name={'apt'}
              label="Apt."
              form={form}
              aptField
              disabled={disabled}
              autoComplete="chrome-off"
            />
            <InputItem
              name={'city'}
              label="City"
              form={form}
              addressGroupField
              disabled={disabled}
              autoComplete="chrome-off"
            />
            {/* <InputItem
              name={'county'}
              label="County"
              form={form}
              addressGroupField
              disabled={disabled}
              autoComplete="chrome-off"
            /> */}
            <InputItem
              name={'state_id'}
              label="State"
              form={form}
              type="select"
              options={stateOptions}
              addressGroupField
              disabled={disabled}
              autoComplete="chrome-off"
            />
            <InputItem
              name={'zip_code'}
              label="Zip Code"
              form={form}
              type="number"
              mask={ZIP_CODE_NUMBER_FIELD.MASK}
              placeholder={ZIP_CODE_NUMBER_FIELD.PLACEHOLDER}
              addressGroupField
              disabled={disabled}
              autoComplete="chrome-off"
            />
            <InputItem
              name={'is_same_as_physical'}
              label="Mailing address same as physical address"
              form={form}
              type="checkbox"
              fullWidth
              disabled={disabled}
            />
            <MailingAddressWrapper>
              {() => {
                const values = form.getFieldsValue();
                if ((values.is_same_as_physical || []).length > 0) {
                  return <></>;
                }
                return (
                  <>
                    <InputItem
                      name={'mailing_address'}
                      label="Mailing Address"
                      form={form}
                      addressField
                      disabled={disabled}
                      autoComplete="chrome-off"
                    />
                    <InputItem
                      name={'mailing_apt'}
                      label="Apt."
                      form={form}
                      aptField
                      disabled={disabled}
                      autoComplete="chrome-off"
                    />
                    <InputItem
                      name={'mailing_city'}
                      label="City"
                      form={form}
                      addressGroupField
                      disabled={disabled}
                      autoComplete="chrome-off"
                    />
                    {/* <InputItem
                      name={'mailing_county'}
                      label="County"
                      form={form}
                      addressGroupField
                      disabled={disabled}
                      autoComplete="chrome-off"
                    /> */}
                    <InputItem
                      name={'mailing_state_id'}
                      label="State"
                      form={form}
                      type="select"
                      options={stateOptions}
                      addressGroupField
                      disabled={disabled}
                      autoComplete="chrome-off"
                    />
                    <InputItem
                      name={'mailing_zip_code'}
                      label="Zip Code"
                      form={form}
                      type="number"
                      mask={ZIP_CODE_NUMBER_FIELD.MASK}
                      placeholder={ZIP_CODE_NUMBER_FIELD.PLACEHOLDER}
                      addressGroupField
                      disabled={disabled}
                      autoComplete="chrome-off"
                    />
                  </>
                );
              }}
            </MailingAddressWrapper>
          </FormSection>
        ) : (
          <ListInfo objKeys={ADDRESSES_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default Address;
