import React from 'react';
import cn from 'classnames';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import Chat from 'views/pages/Chat';

import classes from './style.module.scss';

const ChatContainer = ({ isChatCollapsed, setIsChatCollapsed }) => {
  const handleExpandChat = () => {
    if (isChatCollapsed) {
      setIsChatCollapsed(false);
    }
  };
  return (
    <div
      className={cn(classes.chatContainer, {
        [classes.isCollapsed]: isChatCollapsed,
      })}
      onClick={handleExpandChat}
    >
      <div className={classes.chatCollapseIcon}>
        <span onClick={() => setIsChatCollapsed(!isChatCollapsed)}>
          {!isChatCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </span>
      </div>
      <Chat isCollapse={isChatCollapsed} handleExpandChat={handleExpandChat} />
    </div>
  );
};

export default ChatContainer;
