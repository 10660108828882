import Request from './request';

// GET
export const apiGetConversationDetail = async id => {
  return Request.call({
    url: `chats/conversations/${id}`,
    method: 'GET',
  });
};

// POST
export const apiMarkChatUnread = async id => {
  return Request.call({
    url: `chats/mark-unread/${id}`,
    method: 'POST',
  });
};
