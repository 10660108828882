import React from 'react';
import cn from 'classnames';
import {
  LikeOutlined,
  DislikeOutlined,
  LikeFilled,
  DislikeFilled,
} from '@ant-design/icons';
import Tooltip from 'components/Tooltip';
import classes from './Elements.module.scss';

const MessageLike = ({
  className,
  onAction = () => {},
  likeCount,
  unLikeCount,
}) => {
  return (
    <div
      className={cn(
        classes.likeBlock,
        {
          [classes.hasActionCount]: likeCount > 0 || unLikeCount > 0,
        },
        className
      )}
    >
      {likeCount > 0 ? (
        <Tooltip
          key="basic-like"
          title="Anthony DeMarco"
          overlayClassName={classes.tooltip}
        >
          <LikeFilled className={classes.hasAction} />
        </Tooltip>
      ) : (
        <LikeOutlined
          onClick={() => {
            onAction(true);
          }}
        />
      )}
      {likeCount > 0 && <span>{likeCount}</span>}
      {unLikeCount > 0 ? (
        <Tooltip
          key="basic-un-like"
          title="Anthony DeMarco"
          overlayClassName={classes.tooltip}
        >
          <DislikeFilled className={classes.hasAction} />
        </Tooltip>
      ) : (
        <DislikeOutlined
          onClick={() => {
            onAction(false);
          }}
        />
      )}
      {unLikeCount > 0 && <span>{unLikeCount}</span>}
    </div>
  );
};

export default MessageLike;
