import React, { useState, useEffect } from 'react';
import Space from 'components/Space';
import { getUrlParamValueByKey } from 'utils/routes';
import { actionCb } from 'utils/action';
import LoadingSpin from './LoadingSpin';
import { useActions } from './selectorData';
import classes from './UnsubscribeReminders.module.scss';

const UnsubscribeReminders = ({ location }) => {
  const { subscribeReminderEmail } = useActions();
  const [isUnsubscribe, setIsUnsubscribe] = useState(true);
  const memberId = getUrlParamValueByKey(location.search, 'emp_id');
  const clientId = getUrlParamValueByKey(location.search, 'c_id');
  const handleReSubscribe = () => {
    subscribeReminderEmail(
      clientId,
      memberId,
      { subscribe: 1 },
      actionCb(() => {
        setIsUnsubscribe(false);
      })
    );
  };

  useEffect(() => {
    if (memberId && clientId) {
      subscribeReminderEmail(
        clientId,
        memberId,
        { subscribe: 0 },
        actionCb(() => {
          setIsUnsubscribe(true);
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingSpin>
      <Space direction={'vertical'} size={'large'} className={classes.wrapper}>
        <h2>Success!</h2>
        <p>
          {!isUnsubscribe
            ? `You’ve been resubscribed and will again receive alert emails.`
            : 'You will no longer receive alert emails.'}
        </p>
        {isUnsubscribe && (
          <div className={classes.link}>
            Changed your mind?
            <div>
              <span onClick={handleReSubscribe}>Click here to resubscribe</span>
            </div>
          </div>
        )}
      </Space>
    </LoadingSpin>
  );
};

export default UnsubscribeReminders;
