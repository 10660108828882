import { chatInformationTypes } from '../types';

// SYNC_SET_CHAT_INFORMATION
export const syncSetChatInformation = (attr, value) => dispatch => {
  dispatch({
    type: chatInformationTypes.SYNC_SET_CHAT_INFORMATION,
    payload: { attr, value },
  });
};
// SYNC_RESET_CHAT_INFORMATION
export const syncResetChatInformation = () => dispatch => {
  dispatch({
    type: chatInformationTypes.SYNC_RESET_CHAT_INFORMATION,
  });
};
