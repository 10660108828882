import omit from 'lodash/omit';
import get from 'lodash/get';
import { getMomentDate, getBEDate } from 'utils/date';
import { parseStringToNumber } from 'utils/number';
import { getOtherDataValue } from 'utils/customField';
import {
  standardRequiredFieldObj,
  emailFieldObj,
  phoneFieldObj,
} from 'utils/form';
import { NORMAL_FIELDS, NULL_FIELDS, DATE_FIELDS } from './constants';

export const getInitialValues = data => {
  const initialValues = {
    delete_custom_fields: [],
    other: getOtherDataValue(data.custom_fields || []),
  };
  [...NORMAL_FIELDS, ...NULL_FIELDS].map(field => {
    initialValues[field] =
      data[field] || (NULL_FIELDS.includes(field) ? null : '');
    return true;
  });
  DATE_FIELDS.map(field => {
    initialValues[field] = data[field]
      ? getMomentDate(data[field], false, true)
      : '';
    return true;
  });
  return initialValues;
};
export const validateObj = {
  first_name: [standardRequiredFieldObj],
  last_name: [standardRequiredFieldObj],
  title_id: [standardRequiredFieldObj],
  phone: [standardRequiredFieldObj, phoneFieldObj],
  email: [standardRequiredFieldObj, emailFieldObj],
};

export const getSalesInfoBR = (values = {}) => {
  const {
    phone,
    initial_contact,
    date_of_application,
    primaryContact,
    delete_custom_fields,
    other,
  } = values;
  const bodyRequest = {
    ...omit(values, ['other', 'delete_custom_fields', 'primaryContact']),
    is_primary: primaryContact ? 1 : 0,
    phone: parseStringToNumber(phone),
    initial_contact: initial_contact ? getBEDate(initial_contact) : '',
    date_of_application: date_of_application
      ? getBEDate(date_of_application)
      : '',
  };
  const fData = new FormData();
  Object.keys(bodyRequest).map(k => {
    fData.append(k, bodyRequest[k] || '');
    return true;
  });
  if (other && other.length > 0) {
    other.map((field, index) => {
      fData.append(`custom_fields[${index}][field_name]`, field.field_name);
      if (field.originId) {
        fData.append(`custom_fields[${index}][id]`, field.originId);
      }
      const firstDocument = get(field, 'document[0]') || null;
      const existingFileName = get(field, 'originData.document_name', '');
      if (firstDocument && !firstDocument.url) {
        fData.append(
          `custom_fields[${index}][document]`,
          firstDocument.originFileObj
        );
      } else if (!firstDocument && !!existingFileName) {
        fData.append(`custom_fields[${index}][document_delete]`, 1);
      }
      return true;
    });
  } else {
    fData.append('custom_fields', '');
  }
  if (delete_custom_fields && delete_custom_fields.length > 0) {
    delete_custom_fields.map(id => {
      fData.append('delete_custom_fields[]', id);
      return true;
    });
  }
  return fData;
};
