import { useStoreActions } from 'store/hooks';
import * as reminderSelectors from 'store/selectors/reminder';
import * as orderTypeActions from 'store/actions/orderType';
import * as reminderActions from 'store/actions/reminder';

export const useIndexData = () => {
  return {
    reminderDetail: reminderSelectors.useSelectReminderDetail(),
  };
};
export const useHeaderData = () => {
  return {
    reminderDetail: reminderSelectors.useSelectReminderDetail(),
  };
};
export const useFormContentData = () => {
  return {
    reminderDetail: reminderSelectors.useSelectReminderDetail(),
  };
};
export const useInformationData = () => {
  return {
    reminderDetail: reminderSelectors.useSelectReminderDetail(),
  };
};
export const useFooterData = () => {
  return {
    createReminderLoading: reminderSelectors.useSelectCreateReminderLoading(),
    updateReminderLoading: reminderSelectors.useSelectUpdateReminderLoading(),
    reminderDetail: reminderSelectors.useSelectReminderDetail(),
    chagneReminderStatusLoading: reminderSelectors.useSelectChangeReminderStatusLoading(),
  };
};
export const useLoadingData = () => {
  return {
    reminderDetailLoading: reminderSelectors.useSelectReminderDetailLoading(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...orderTypeActions,
    ...reminderActions,
  });
};
