import React from 'react';

import EmptyComponent from './Empty';
import AvatarComponent from './Avatar';
import TableComponent from './Table';
import TabsComponent from './Tabs';
import CommentComponent from './Comment';

const DataDisplayComponent = ({ classes }) => {
  return (
    <>
      <h1>Data Display</h1>
      <div>
        <h2>Empty</h2>
        <EmptyComponent classes={classes} />
      </div>
      <div>
        <h2>Avatar</h2>
        <AvatarComponent classes={classes} />
      </div>
      <div>
        <h2>Table</h2>
        <TableComponent classes={classes} />
      </div>
      <div>
        <h2>Tabs</h2>
        <TabsComponent classes={classes} />
      </div>
      <div>
        <h2>Comment</h2>
        <CommentComponent classes={classes} />
      </div>
    </>
  );
};

export default DataDisplayComponent;
