import get from 'lodash/get';
import { store } from 'store';
import { CHAT_SORT_MAPPING, CHAT_ORDER_MAPPING } from 'helper/const';
import { scrollChatContentToBottom } from 'utils/index';
import { playSound } from 'utils/chat';
import {
  getInternalConversations,
  getClientConversations,
  markRead,
  getChatMembers,
  syncSetMessages,
} from 'store/actions/chat';
import {
  getConversationDetail,
  syncSetResetActiveConversation,
} from 'store/actions/conversationDetail';

const getStoreActiveConversation = state => {
  return get(state, 'conversationDetail.activeConversation') || {};
};
const getStoreConversationDetail = state => {
  return get(state, 'conversationDetail.conversationDetail.data') || {};
};
const getFilterParams = (filterKey, filters, sorts) => {
  const filterParams = {};
  if (filters.length > 0) {
    filterParams[filterKey] = filters;
  }
  if (sorts) {
    filterParams['sort_by'] = CHAT_SORT_MAPPING[sorts];
    filterParams['order'] = CHAT_ORDER_MAPPING[sorts];
  }
  return filterParams;
};
export const handleLoadInternalConversation = () => {
  const storeData = store.getState();
  const filterInternalIds =
    get(storeData, 'chatInformation.filterInternalIds') || [];
  const sortInternal = get(storeData, 'chatInformation.sortInternal') || '';
  const filterParams = getFilterParams(
    'group_ids',
    filterInternalIds,
    sortInternal
  );
  store.dispatch(getInternalConversations(filterParams));
};
export const handleLoadClientConversation = () => {
  const storeData = store.getState();
  const filterInternalIds =
    get(storeData, 'chatInformation.filterClientIds') || [];
  const sortInternal = get(storeData, 'chatInformation.sortClient') || '';
  const filterParams = getFilterParams(
    'company_ids',
    filterInternalIds,
    sortInternal
  );
  store.dispatch(getClientConversations(filterParams));
};
const reloadMessageOrChatList = (conversationId, type, messageData = {}) => {
  if (conversationId) {
    const storeData = store.getState();
    const storeActiveConversation = getStoreActiveConversation(storeData);
    const handleLoadConversationActions = type.includes('internal')
      ? handleLoadInternalConversation
      : handleLoadClientConversation;
    if (conversationId === storeActiveConversation.conversation_id) {
      const conversationDetail = getStoreConversationDetail(storeData);
      store.dispatch(syncSetMessages(messageData));
      if (!!conversationDetail.sound) {
        playSound();
      }
      scrollChatContentToBottom();
      store.dispatch(
        markRead(conversationId, {
          message_id: messageData.id,
        })
      );
    } else {
      store.dispatch(
        getConversationDetail(conversationId, res => {
          const sound = !!get(res, 'data.sound', '');
          if (sound) {
            playSound();
          }
        })
      );
      handleLoadConversationActions();
    }
  }
};
const reloadChatList = type => {
  const conversationType = type || '';
  if (conversationType) {
    if (conversationType.includes('internal')) {
      handleLoadInternalConversation();
    } else {
      handleLoadClientConversation();
    }
  } else {
    handleLoadInternalConversation();
    handleLoadClientConversation();
  }
};
const reloadConversationName = conversationId => {
  const storeActiveConversation = getStoreActiveConversation(store.getState());
  if (conversationId === storeActiveConversation.conversation_id) {
    store.dispatch(getConversationDetail(conversationId));
  }
  reloadChatList();
};
const leaveConversation = conversationId => {
  const storeActiveConversation = getStoreActiveConversation(store.getState());
  if (conversationId === storeActiveConversation.conversation_id) {
    store.dispatch(syncSetResetActiveConversation(true));
  }
  reloadChatList();
};
const updateMembers = conversationId => {
  const storeActiveConversation = getStoreActiveConversation(store.getState());
  if (conversationId === storeActiveConversation.conversation_id) {
    store.dispatch(getChatMembers(conversationId));
  }
  reloadChatList();
};
const joinConversation = type => {
  reloadChatList(type);
};

export {
  getStoreActiveConversation,
  reloadMessageOrChatList,
  reloadConversationName,
  leaveConversation,
  updateMembers,
  joinConversation,
};
