import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import cn from 'classnames';
import { validateForm, scrollToErrorField } from 'utils/form';
import Form, { useForm } from 'components/Form';
import HeaderActions from 'views/pages/ClientDetail/CompanyOfficial/components/HeaderActions';
import OtherIntegrateSection from 'views/pages/ClientDetail/components/OtherIntegrateSection';
import { useIsInternalUser } from 'helper/hooks';
import Profile from './Profile';
import Address from './Address';
import Equipment from './Equipment';
import DriverLicense from './DriverLicense';
import MedicalCard from './MedicalCard';
import DrugTests from './DrugTests';
import EmploymentApplication from './EmploymentApplication';
import DriverQuery from './DriverQuery';
import MVR from './MVR';
import CheckingAccount from './CheckingAccount';
import FuelCard from './FuelCard';
import Documents from './Documents';
import { getValidateArray, updateAccountCardIds } from './utils';
import { validateObj, getInitialValues } from '../../utils';
import classes from './Section.module.scss';

const Section = ({
  className,
  isActive,
  isEdit,
  setIsEdit,
  onFormChange,
  openWarningModal,
  rootKey,
  data,
  onSubmit,
  onStatusChange,
  statusLoading,
  loading,
  isOO,
  onEditCheckingAccount,
  onValidatePassword,
  validatePasswordLoading,
  getDriverAccountLoading,
  onEditFuelCard,
  getFuelCardLoading,
  clientId,
  isNew,
}) => {
  const { isInternalUser } = useIsInternalUser();
  const accountsData = data.accounts || [];
  const cardsData = data.cards || [];
  const wrapperId = `driver_information_${rootKey}`;
  const [isFormChange, setIsFormChanged] = useState(false);
  const [form] = useForm();
  const handleSubmit = values => {
    const fieldSingleErrors = validateForm(validateObj(), values);
    const fieldArrayErrors = validateForm(
      getValidateArray(values),
      values,
      true
    );
    if (fieldSingleErrors.length > 0 || fieldArrayErrors.length > 0) {
      form.setFields([...fieldSingleErrors, ...fieldArrayErrors]);
      scrollToErrorField(wrapperId);
    } else {
      onSubmit(values, res => {
        const newAccounts = get(res, 'data.payment_accounts') || [];
        const newCards = get(res, 'data.fuel_cards') || [];
        if (newAccounts.length > 0 || newCards.length > 0) {
          updateAccountCardIds(form, newAccounts, newCards);
        }
        setIsEdit(false);
      });
    }
  };

  useEffect(() => {
    if (isEdit) {
      setIsFormChanged(false);
      if (form) {
        form.resetFields();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);
  useEffect(() => {
    if (form && isActive) {
      updateAccountCardIds(form, accountsData, cardsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <div className={cn(classes.wrapper, className)} id={wrapperId}>
      <Form
        validateMessages={''}
        onFinish={handleSubmit}
        initialValues={getInitialValues(data, isInternalUser)}
        form={form}
        onValuesChange={() => {
          setIsFormChanged(true);
          onFormChange();
        }}
      >
        {isActive && (
          <HeaderActions
            className={classes.headerActions}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            form={form}
            isFormChange={isFormChange}
            loading={loading}
            handleSubmit={handleSubmit}
            openWarningModal={openWarningModal}
            status={data?.status}
            onStatusChange={onStatusChange}
            statusLoading={statusLoading}
            inSection
          />
        )}
        <Profile
          isEdit={isEdit}
          form={form}
          data={data}
          disabled={isOO}
          clientId={clientId}
          isNew={isNew}
        />
        <Address isEdit={isEdit} form={form} data={data} disabled={isOO} />
        <Equipment isEdit={isEdit} form={form} data={data} />
        <DriverLicense
          isEdit={isEdit}
          form={form}
          data={data}
          disabled={isOO}
          clientId={clientId}
          isNew={isNew}
        />
        <MedicalCard isEdit={isEdit} form={form} data={data} disabled={isOO} />
        <EmploymentApplication isEdit={isEdit} form={form} data={data} />
        <DrugTests isEdit={isEdit} form={form} data={data} />
        <DriverQuery isEdit={isEdit} form={form} data={data} />
        <MVR isEdit={isEdit} form={form} data={data} />
        <Documents isEdit={isEdit} form={form} data={data} />
        <CheckingAccount
          isEdit={isEdit}
          form={form}
          accounts={accountsData}
          onEdit={onEditCheckingAccount}
          onValidatePassword={onValidatePassword('accounts')}
          validatePasswordLoading={validatePasswordLoading}
          getDriverAccountLoading={getDriverAccountLoading}
          wrapperId={wrapperId}
        />
        <FuelCard
          isEdit={isEdit}
          form={form}
          cards={cardsData}
          onEdit={onEditFuelCard}
          onValidatePassword={onValidatePassword('fuel_cards')}
          validatePasswordLoading={validatePasswordLoading}
          getFuelCardLoading={getFuelCardLoading}
          wrapperId={wrapperId}
        />
        <OtherIntegrateSection
          isEdit={isEdit}
          form={form}
          other={data.custom_fields || []}
        />
      </Form>
    </div>
  );
};

export default Section;
