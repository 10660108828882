import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import toast from 'helper/toast';
import { CHAT_TYPES } from 'helper/const';
import { getErrorMessageFromObj } from 'utils/object';
import { getCheckConversationBD, getDetailObjFromStartNew } from './utils';
import { LEAVE_GROUP } from './constants';

export const showErrorMessage = err => {
  toast.error(getErrorMessageFromObj(err)[0]);
};
export const sendMessageFn = ({
  conversation_id,
  message,
  attachments,
  sendMessage,
  handleLoadMessage,
  cb,
  showErrorMessage,
  isSync,
  permission,
  renderTab,
}) => {
  const bodyRequest = {
    conversation_id,
    type: permission?.isClient
      ? renderTab === 'client'
        ? CHAT_TYPES.CLIENT_INDIVIDUAL
        : CHAT_TYPES.CLIENT_DEPARTMENT
      : CHAT_TYPES.INTERNAL_CUSTOM,
    text: message || '',
  };
  const fData = new FormData();
  Object.keys(bodyRequest).map(k => {
    const val = bodyRequest[k];
    fData.append(k, val);
    return true;
  });
  if (attachments && attachments.length > 0) {
    attachments.map(attachment => {
      fData.append('attachments[]', attachment);
      return true;
    });
  }
  sendMessage(fData, (res, err) => {
    if (res) {
      if (!isSync) {
        handleLoadMessage(conversation_id);
      }
      if (cb) cb();
    } else {
      showErrorMessage(err);
    }
  });
};
export const updateChatSettingFn = ({
  updateChatSettings,
  conversation_id,
  val,
  cb,
}) => {
  updateChatSettings(
    conversation_id,
    {
      type: 'name',
      value: val,
    },
    (res, err) => {
      cb(res, err);
    }
  );
};
export const leaveGroupFn = ({ confirmModal, onLeave }) => {
  confirmModal(
    LEAVE_GROUP.LABEL,
    LEAVE_GROUP.CONTENT,
    () => {
      onLeave();
    },
    {
      type: 'confirm',
      onCancel: () => {},
      okText: 'Leave',
      centered: true,
    }
  );
};
export const startNewChatFn = ({
  selectedContacts,
  checkConversation,
  existingChats,
  cbAfterStartChat,
  addMembers,
  currentUser,
  getConversationDetail,
  setIsNewContact,
  createChatGroup,
  isClientTab,
}) => {
  const checkConversationBR = getCheckConversationBD(
    selectedContacts,
    isClientTab
  );
  const companyName = get(selectedContacts, '[0].company_name', '');
  checkConversation(checkConversationBR, res => {
    const existingConversationId = get(res, 'data') || false;
    if (existingConversationId !== false && existingConversationId > 0) {
      const existingConversation =
        find(existingChats, {
          conversation_id: existingConversationId,
        }) || {};
      if (!isEmpty(existingConversation)) {
        if (isClientTab) {
          existingConversation.isIndividual = true;
        }
        cbAfterStartChat(existingConversation);
      } else {
        addMembers(
          existingConversationId,
          {
            members: [currentUser.id],
          },
          (res, err) => {
            if (res) {
              getConversationDetail(existingConversationId, (res, err) => {
                if (res) {
                  const cdData = res.data || {};
                  const conversation = {
                    type: checkConversationBR.type,
                    ...cdData,
                    isIndividual: isClientTab
                      ? true
                      : checkConversationBR.type ===
                        CHAT_TYPES.INTERNAL_INDIVIDUAL,
                    conversation_id: cdData.id,
                    company_name: isClientTab ? companyName : '',
                  };
                  setIsNewContact(false);
                  cbAfterStartChat(conversation);
                } else {
                  showErrorMessage(err);
                }
              });
            } else {
              showErrorMessage(err);
            }
          }
        );
      }
    } else {
      const newChatGroupData = getDetailObjFromStartNew({
        selectedContacts,
        currentUser,
        isClientTab,
      });
      createChatGroup(newChatGroupData.createChatData, (res, err) => {
        if (res) {
          setIsNewContact(true);
          newChatGroupData.conversation_id = res.data;
          if (isClientTab) {
            newChatGroupData.company_name = companyName;
          }
          cbAfterStartChat(newChatGroupData);
        } else {
          showErrorMessage(err);
        }
      });
    }
  });
};
