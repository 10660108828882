import { useStoreActions, useReducerAttribute } from 'store/hooks';
// ACTIONS
import * as clientDetailActions from 'store/actions/clientDetail';

export const useIndexData = () => {
  const companyUsersStore = useReducerAttribute('clientDetail', 'companyUsers');
  return {
    users: companyUsersStore?.data || [],
    total: companyUsersStore?.totalNo || 0,
    usersLoading: companyUsersStore?.loading,
  };
};

export const useActions = () => {
  return useStoreActions({
    ...clientDetailActions,
  });
};
