import React from 'react';
import { InputNumber } from 'components/FormFields';

const InputPrice = ({
  form,
  name,
  placeholder,
  prefix,
  suffix,
  hideHandler,
  className,
  ...other
}) => {
  return (
    <InputNumber
      parser={value => (value || '').replace(/\$\s?|(,*)/g, '')}
      formatter={value => {
        const valArr = String(value).split('.');
        const firstVal = valArr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return `${firstVal}${valArr.length > 1 ? `.${valArr[1]}` : ''}`;
      }}
      name={name}
      form={form}
      step="0.01"
      stringMode
      placeholder={placeholder || 'Input Price'}
      prefix={prefix ? prefix : suffix ? '' : '$'}
      suffix={suffix}
      textLeft
      isFloat
      hideHandler
      onStep={(value, { type }) => {
        const floatValue = parseFloat(value);
        form.setFieldsValue({
          [name]: type === 'up' ? floatValue + 0.99 : floatValue - 0.99,
        });
      }}
      {...other}
    />
  );
};

export default InputPrice;
