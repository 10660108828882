import React from 'react';
import cn from 'classnames';
import {
  SearchOutlined,
  FilterOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { ReactComponent as ArchiveSvg } from 'assets/icons/Archive.svg';
import { useOptions } from 'helper/hooks';
import Dropdown from 'components/Dropdown';
import Button, { ButtonColors } from 'components/Button';
import { Input } from 'components/FormFields';
import { applySearch } from 'utils';
import { getFilterMenus } from './uiUtils';
import classes from './ClientList.module.scss';

const FilterActions = ({
  keyword,
  setKeyword,
  onSearch,
  selectedRows,
  handleAllFilterChange,
  isDefaultFilter,
  hideButtons,
  onArchive,
  archiveLoading,
}) => {
  const { stateOptions } = useOptions();
  return (
    <div className={classes.tabContentHeader}>
      <Input
        size={'medium'}
        name="keyword"
        allowClear
        value={keyword}
        placeholder={`Search clients`}
        onChange={e => {
          const value = e.target.value;
          setKeyword(value);
          applySearch(value, val => {
            onSearch(val);
          });
        }}
        prefix={<SearchOutlined />}
        isBlue
      />

      <div className={classes.actions}>
        {!hideButtons && (
          <Button
            htmlType="button"
            color={
              // will use BLUE for active when finish integrate
              selectedRows.length > 0
                ? ButtonColors.DEFAULT
                : ButtonColors.DEFAULT
            }
            disabled={selectedRows.length === 0}
            onClick={onArchive}
            leftIcon={<ArchiveSvg />}
            loading={archiveLoading}
          >
            Archive
          </Button>
        )}
        <span />
        <Dropdown
          trigger={['click']}
          menus={getFilterMenus({
            onFilterChange: handleAllFilterChange,
            stateOptions,
          })}
          hideArrow
          className={cn({
            [classes.iconActive]: !isDefaultFilter,
          })}
          menuClassName={cn(classes.filterOverlay)}
          placement="bottomRight"
        >
          <Button
            htmlType="button"
            color={ButtonColors.DEFAULT}
            iconOnly
            leftIcon={<FilterOutlined />}
          ></Button>
        </Dropdown>
        <Button
          htmlType="button"
          color={ButtonColors.DEFAULT}
          iconOnly
          leftIcon={<MoreOutlined />}
        ></Button>
      </div>
    </div>
  );
};

export default FilterActions;
