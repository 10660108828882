import cn from 'classnames';
import React, { memo } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { useDrag, useDrop } from 'react-dnd';
import classes from './DragDrop.module.scss';

const Card = props => {
  const {
    id,
    content,
    moveCard,
    findCard,
    disabled,
    className,
    wholeHandler,
    onDropCard,
  } = props;
  const originalIndex = findCard(id).index;
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: 'card',
      item: { id, originalIndex },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        if (onDropCard) onDropCard();
      },
    }),
    [id, originalIndex, moveCard]
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'card',
      canDrop: () => false,
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard]
  );

  const opacity = isDragging ? 0 : 1;
  return (
    <div
      className={cn(
        classes.card,
        'dnd-card',
        {
          'dnd-card-disabled': disabled,
        },
        className
      )}
      style={{ opacity }}
      ref={preview}
    >
      {wholeHandler ? (
        <div
          ref={node => {
            if (!disabled) {
              drag(drop(node));
            }
          }}
          className={classes.wholeHandler}
        >
          {!disabled && (
            <div className={cn(classes.handler, 'dnd-handler')}>
              <MenuOutlined />
            </div>
          )}
          <div className={classes.cardContent}>{content}</div>
        </div>
      ) : (
        <>
          {!disabled && (
            <div
              ref={node => drag(drop(node))}
              className={cn(classes.handler, 'dnd-handler')}
            >
              <MenuOutlined />
            </div>
          )}
          <div className={classes.cardContent}>{content}</div>
        </>
      )}
    </div>
  );
};

export default memo(Card);
