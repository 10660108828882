import React, { useState } from 'react';
import cx from 'classnames';
import {
  UserOutlined,
  // MessageOutlined,
  // QuestionCircleOutlined,
  BellOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import ROUTES from 'routes';
import Dropdown from 'components/Dropdown';
import Badge from 'components/Badge';
import Notification from './Notification';
import getPermission from './permissions';
import { useIndexData } from './selectorData';
import styles from './style.module.scss';

const TopBarContent = ({ children }) => {
  const { currentUser, notificationUnreadCount } = useIndexData();
  const { first_name, last_name, profile_pic } = currentUser || {};
  const currentRole = currentUser.role || '';
  const permission = getPermission(currentRole, currentUser);
  const currentUserFullName = `${first_name || ''} ${last_name || ''}`;
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const menus = [
    {
      to: ROUTES.MY_PROFILE(),
      icon: <UserOutlined />,
      label: 'My Profile',
      props: {
        onClick: () => {
          setIsMenuVisible(false);
        },
      },
    },
  ];

  return (
    <div className={styles.pageHeader}>
      <div>{children}</div>
      <div>
        <div className={styles.notification}>
          {/* <div>
            <Badge count={3} size="default">
              <MessageOutlined />
            </Badge>
          </div>
          <div>
            <QuestionCircleOutlined />
          </div> */}
          {!!permission.hasNotification && (
            <div>
              <Notification>
                <Badge count={notificationUnreadCount}>
                  <BellOutlined />
                </Badge>
              </Notification>
            </div>
          )}
        </div>
        <div
          className={cx(styles.userAvatar, {
            [styles.hadImage]: !!profile_pic,
          })}
        >
          {profile_pic ? (
            <img src={profile_pic} alt="profile" />
          ) : (
            <UserOutlined />
          )}
        </div>
        <Dropdown
          menus={menus}
          className={styles.profileDropdown}
          menuClassName={styles.profileDropdownMenu}
          arrowIcon={isMenuVisible ? <UpOutlined /> : <DownOutlined />}
          onVisibleChange={visible => {
            setIsMenuVisible(visible);
          }}
        >
          {currentUserFullName}
        </Dropdown>
      </div>
    </div>
  );
};

export default TopBarContent;
