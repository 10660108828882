import get from 'lodash/get';
import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  customFieldTypes,
} from '../types';
import { initialCustomFieldState } from '../states/customField';

export default (state = initialCustomFieldState, action) => {
  const { type } = action?.payload || {};
  switch (action.type) {
    // CREATE_CUSTOM_FIELD
    case asyncActionTypeRequest(customFieldTypes.CREATE_CUSTOM_FIELD): {
      return {
        ...state,
        createCustomField: {
          ...state.createCustomField,
          loading: {
            ...state.createCustomField.loading,
            [type]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(customFieldTypes.CREATE_CUSTOM_FIELD): {
      return {
        ...state,
        createCustomField: {
          loading: {
            ...state.createCustomField.loading,
            [type]: false,
          },
          data: {
            ...state.createCustomField.data,
            [type]: get(action, 'response.data') || {},
          },
          error: {
            ...state.createCustomField.error,
            [type]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(customFieldTypes.CREATE_CUSTOM_FIELD): {
      return {
        ...state,
        createCustomField: {
          loading: {
            ...state.createCustomField.loading,
            [type]: false,
          },
          data: {
            ...state.createCustomField.data,
            [type]: {},
          },
          error: {
            ...state.createCustomField.error,
            [type]: action.error,
          },
        },
      };
    }
    // UPDATE_CUSTOM_FIELD
    case asyncActionTypeRequest(customFieldTypes.UPDATE_CUSTOM_FIELD): {
      return {
        ...state,
        updateCustomField: {
          ...state.updateCustomField,
          loading: {
            ...state.updateCustomField.loading,
            [type]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(customFieldTypes.UPDATE_CUSTOM_FIELD): {
      return {
        ...state,
        updateCustomField: {
          loading: {
            ...state.updateCustomField.loading,
            [type]: false,
          },
          data: {
            ...state.updateCustomField.data,
            [type]: get(action, 'response.data') || {},
          },
          error: {
            ...state.updateCustomField.error,
            [type]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(customFieldTypes.UPDATE_CUSTOM_FIELD): {
      return {
        ...state,
        updateCustomField: {
          loading: {
            ...state.updateCustomField.loading,
            [type]: false,
          },
          data: {
            ...state.updateCustomField.data,
            [type]: {},
          },
          error: {
            ...state.updateCustomField.error,
            [type]: action.error,
          },
        },
      };
    }
    // DELETE_CUSTOM_FIELD
    case asyncActionTypeRequest(customFieldTypes.DELETE_CUSTOM_FIELD): {
      return {
        ...state,
        deleteCustomField: {
          ...state.deleteCustomField,
          loading: {
            ...state.deleteCustomField.loading,
            [type]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(customFieldTypes.DELETE_CUSTOM_FIELD): {
      return {
        ...state,
        deleteCustomField: {
          loading: {
            ...state.deleteCustomField.loading,
            [type]: false,
          },
          data: {
            ...state.deleteCustomField.data,
            [type]: get(action, 'response.data') || {},
          },
          error: {
            ...state.deleteCustomField.error,
            [type]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(customFieldTypes.DELETE_CUSTOM_FIELD): {
      return {
        ...state,
        deleteCustomField: {
          loading: {
            ...state.deleteCustomField.loading,
            [type]: false,
          },
          data: {
            ...state.deleteCustomField.data,
            [type]: {},
          },
          error: {
            ...state.deleteCustomField.error,
            [type]: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
};
