import React from 'react';
import cn from 'classnames';
import FormListSection from 'views/pages/ClientDetail/components/FormListSection';
import SecurityBlock from 'views/pages/ClientDetail/components/SecurityBlock';
import {
  Title,
  PrimaryTag,
} from 'views/pages/ClientDetail/components/Elements';
import {
  MONTH_FIELD,
  BANK_NUMBER_FIELD,
  ZIP_CODE_NUMBER_FIELD,
} from 'helper/const';
import { useOptions } from 'helper/hooks';
import FuelCardEditForm from '../FuelCardEditForm';
import Loading from './Loading';
import { validateFuelCardObj } from '../../utils';
import { getCardBR, setFullCard } from './utils';
import classes from './Section.module.scss';

const FuelCard = ({
  className,
  isEdit,
  form,
  cards,
  onSave = () => () => {},
  getFuelCardLoading,
  fuelCardsLoading,
  wrapperId,
  onValidatePassword,
  onConfirmEditCard,
  saveLoading,
  hideActions,
  onDeleteCard,
  deleteLoading,
}) => {
  const { stateOptions } = useOptions();
  const cardsData = cards || [];

  return (
    <div className={cn(classes.cardSection, className)}>
      {!!fuelCardsLoading && <Loading />}
      <Title text="Cards" />
      <FormListSection
        notFoundMessage="Currently there are no cards."
        isEdit={isEdit}
        form={form}
        canSave={true}
        onSave={onSave}
        data={cardsData}
        saveLoading={saveLoading}
        initialObjFn={() => {
          return {
            card_number: '',
            cvv: '',
            expiration_date: '',
            zip_code: '',
          };
        }}
        additionalTitleFn={(d, index) => {
          const obj = d || {};
          return obj.is_primary ? <PrimaryTag isDefault /> : '';
        }}
        titleField="title"
        name="cards"
        addBtnText="Add Card"
        newLabel="No. "
        isSecurity
        renderFields={(index, isExisting) => {
          if (isExisting) {
            const currentCardData = cardsData[index] || {};
            const simpleFields = [
              { name: 'simple_card_number', label: 'Card Number' },
            ];
            const fullFields = [
              {
                name: [index, 'card_number'],
                label: 'Card Number',
              },
              {
                name: [index, 'expiration_date'],
                label: 'Expiration Date',
                type: 'number',
                mask: MONTH_FIELD.MASK,
                addressGroupField: true,
              },
              {
                name: [index, 'cvv'],
                label: 'CVV',
                type: 'number',
                mask: BANK_NUMBER_FIELD.MASK,
                addressGroupField: true,
              },
              {
                name: 'is_primary',
                label: 'Default Account',
                type: 'checkbox',
                fullWidth: true,
              },
              {
                label: 'Billing Address',
                type: 'label',
                fullWidth: true,
              },
              {
                name: [index, 'address'],
                label: 'Street Address',
                addressField: true,
              },
              {
                name: [index, 'apt'],
                label: 'Apt.',
                aptField: true,
              },
              {
                name: [index, 'city'],
                label: 'City',
                addressGroupField: true,
              },
              {
                name: [index, 'state_id'],
                label: 'State',
                type: 'select',
                options: stateOptions,
                addressGroupField: true,
              },
              {
                name: [index, 'zip_code'],
                label: 'Zip Code',
                type: 'number',
                mask: ZIP_CODE_NUMBER_FIELD.MASK,
                addressGroupField: true,
              },
              {
                type: 'block',
                width: '25%',
              },
            ];
            return (
              <SecurityBlock
                form={form}
                index={index}
                data={currentCardData}
                parentName="cards"
                validateObj={validateFuelCardObj()}
                wrapperId={wrapperId}
                submitLoading={!!getFuelCardLoading?.[currentCardData.id]}
                getBR={getCardBR}
                simpleFields={simpleFields}
                fullFields={fullFields}
                title={<>Secured Information</>}
                isEdit
                resetAfter2s
                onValidatePassword={onValidatePassword(
                  currentCardData.id,
                  res => {
                    setFullCard(res.data || {}, currentCardData.id, form);
                  }
                )}
                renderEdit={() => {
                  return (
                    <FuelCardEditForm
                      index={index}
                      form={form}
                      parentName="cards"
                    />
                  );
                }}
                onSubmit={onConfirmEditCard}
                hideActions={hideActions}
                deleteObj={
                  !!onDeleteCard
                    ? {
                        text: 'Delete Card',
                        onClick: () => {
                          onDeleteCard(currentCardData);
                        },
                        loading: !!deleteLoading[currentCardData.id],
                      }
                    : undefined
                }
              />
            );
          }
          return (
            <FuelCardEditForm index={index} form={form} parentName="cards" />
          );
        }}
        renderViews={() => {
          return '';
        }}
      />
    </div>
  );
};

export default FuelCard;
