import { message } from 'antd';

class Toast {
  DELAY = 6; // Seconds

  error(text, delay = this.DELAY) {
    message.error(
      typeof text === 'string' ? text : 'Something went wrong!',
      delay
    );
  }

  success(text, delay = this.DELAY) {
    message.success(typeof text === 'string' ? text : 'Success!', delay);
  }
}

export default new Toast();
