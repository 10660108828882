import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_STATES',
  'GET_FIELD_CATEGORIES',
  'GET_POSITIONS',
  'GET_DEPARTMENTS',
  'GET_DEPARTMENTS_LIST',
  'GET_SYSTEM_CODES',
  'GET_SALES_DEPARTMENT_USERS',
  'GET_TRAILERS_LIST',
  'GET_DRIVERS_LIST',
  'GET_LEASORS_LIST',
  'GET_ROLES',
  'UPLOAD_FILE',
  'VIN_DECODER',
  'GET_BANK_NAME',
  'GET_EMPLOYEE_LIST',
  'GET_CLIENT_LIST',
  'GET_STATUS_LIST',
  'GET_PAGINATION_CLIENT_LIST',
  'GET_PAGINATION_ORDER_TYPE_LIST',
  'GET_PAGINATION_EMPLOYEE_LIST',
  'CREATE_PIN'
);
