import React from 'react';
import Spin from 'components/Spin';
import classes from './Section.module.scss';

const Loading = () => {
  return (
    <div className={classes.loading}>
      <Spin icon />
    </div>
  );
};

export default Loading;
