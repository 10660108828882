import * as React from 'react';
import { Radio as AntRadio } from 'antd';

import classes from './RadioButtonGroup.module.scss';

const RadioButtonGroup = ({ items, ...extraProps }) => {
  return (
    <AntRadio.Group {...extraProps} className={classes.wrapper}>
      {items.map((item, index) => (
        <AntRadio.Button value={item.value} key={index}>
          {item.label || ''}
        </AntRadio.Button>
      ))}
    </AntRadio.Group>
  );
};

export default RadioButtonGroup;
