import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import cn from 'classnames';

import classes from './Elements.module.scss';

const MessageSending = ({ className, message }) => {
  return (
    <div className={cn(classes.messageSending, className)}>
      {message || 'Sending Message'}
      <LoadingOutlined spin />
    </div>
  );
};

export default MessageSending;
