import {
  apiUpdateOrderTypeDocument,
  apiGetOrderTypesList,
  apiDeleteOrderType,
  apiGetOrderTypeDetail,
  apiUpdateOrderType,
  apiCreateOrderType,
  apiGetAllOrderTypes,
  apiGetOrderTypeLogs,
  apiAddOrderTypeNote,
} from 'api';

import { orderTypeTypes } from '../types';

// GET_ALL_ORDER_TYPES
export const getAllOrderTypesList = cb => {
  return {
    isAsyncCall: true,
    baseType: orderTypeTypes.GET_ALL_ORDER_TYPE_LIST,
    payload: {},
    asyncCall: () => {
      return apiGetOrderTypesList();
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};

// GET_ALL_ORDER_TYPES
export const getAllOrderTypes = (filters, cb, isMore) => {
  return {
    isAsyncCall: true,
    baseType: orderTypeTypes.GET_ALL_ORDER_TYPES,
    payload: { isMore },
    asyncCall: () => {
      return apiGetAllOrderTypes(filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_ORDER_TYPE_DETAIL
export const getOrderTypeDetail = (id, cb) => {
  return {
    isAsyncCall: true,
    baseType: orderTypeTypes.GET_ORDER_TYPE_DETAIL,
    asyncCall: () => {
      return apiGetOrderTypeDetail(id);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_ORDER_TYPE_LOGS
export const getOrderTypeLogs = (id, cb) => {
  return {
    isAsyncCall: true,
    baseType: orderTypeTypes.GET_ORDER_TYPE_LOGS,
    asyncCall: () => {
      return apiGetOrderTypeLogs(id);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CREATE_ORDER_TYPE
export const createOrderType = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: orderTypeTypes.CREATE_ORDER_TYPE,
    asyncCall: () => {
      return apiCreateOrderType(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_ORDER_TYPE
export const updateOrderType = (id, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: orderTypeTypes.UPDATE_ORDER_TYPE,
    asyncCall: () => {
      return apiUpdateOrderType(id, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// DELETE_ORDER_TYPE
export const deleteOrderType = (id, cb) => {
  return {
    isAsyncCall: true,
    baseType: orderTypeTypes.DELETE_ORDER_TYPE,
    asyncCall: () => {
      return apiDeleteOrderType(id);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// ADD_ORDER_TYPE_NOTE
export const addOrderTypeNote = (id, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: orderTypeTypes.ADD_ORDER_TYPE_NOTE,
    asyncCall: () => {
      return apiAddOrderTypeNote(id, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_ORDER_TYPE_DOCUMENT
export const updateOrderTypeDocument = (id, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: orderTypeTypes.UPDATE_ORDER_TYPE_DOCUMENT,
    asyncCall: () => {
      return apiUpdateOrderTypeDocument(id, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
