import React from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import { InfoCircleFilled } from '@ant-design/icons';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import {
  MONTH_FIELD,
  ZIP_CODE_NUMBER_FIELD,
  CARD_NUMBER_FIELD,
} from 'helper/const';
import { FormItem } from 'components/Form';
import { useOptions } from 'helper/hooks';
import { isAMEXCardNumber } from 'utils/card';
import { FormSection } from 'views/pages/ClientDetail/components/Elements';
import classes from 'views/pages/ClientDetail/Driver/components/FuelCardEditForm/FuelCardEditForm.module.scss';
import tClasses from './Payments.module.scss';

const NewCardForm = ({ form, index, cardError }) => {
  const { stateOptions } = useOptions();
  const errorArr = (cardError || '').split(':');

  return (
    <div
      className={cn(tClasses.wrapper, {
        [tClasses.cardError]: !!cardError,
      })}
    >
      <div className={tClasses.label}>
        Add a new account
        {!!cardError && (
          <span className={tClasses.errorTitle}>
            <InfoCircleFilled />
            <b>{errorArr[0]}:</b>
            <span>{errorArr[1]}</span>
          </span>
        )}
      </div>
      <FormSection className={cn(classes.fuel_card_form, tClasses.cardBlock)}>
        <FormItem shouldUpdate>
          {() => {
            const values = form.getFieldsValue();
            const tCardNumber = get(values, `${index}.card_number`);
            const isAMEX = isAMEXCardNumber(tCardNumber);
            return (
              <InputItem
                name={[index, 'card_number']}
                label="Card Number"
                form={form}
                placeholder={
                  isAMEX
                    ? CARD_NUMBER_FIELD.AMEX.PLACEHOLDER
                    : CARD_NUMBER_FIELD.NORMAL.PLACEHOLDER
                }
                customValidateName={[index, 'card_number']}
                type="number"
                mask={
                  isAMEX
                    ? CARD_NUMBER_FIELD.AMEX.MASK
                    : CARD_NUMBER_FIELD.NORMAL.MASK
                }
                required
              />
            );
          }}
        </FormItem>
        <div />
        <InputItem
          name={[index, 'expiration_date']}
          label="Expiration Date"
          form={form}
          placeholder={MONTH_FIELD.PLACEHOLDER}
          customValidateName={[index, 'expiration_date']}
          type="number"
          mask={MONTH_FIELD.MASK}
          className={classes.field_one_fourth}
          required
        />
        <FormItem shouldUpdate>
          {() => {
            const values = form.getFieldsValue();
            const tCardNumber = get(values, `${index}.card_number`);
            const isAMEX = isAMEXCardNumber(tCardNumber);
            return (
              <InputItem
                name={[index, 'cvv']}
                label="CVV"
                form={form}
                placeholder={isAMEX ? '0000' : '000'}
                customValidateName={[index, 'cvv']}
                type="number"
                mask={isAMEX ? '9999' : '999'}
                className={classes.field_one_fourth}
                required
              />
            );
          }}
        </FormItem>
      </FormSection>
      <div className={cn(tClasses.label)}>Billing Address</div>
      <FormSection className={tClasses.addressSection}>
        <InputItem
          name={[index, 'address']}
          label="Street Address"
          form={form}
          addressField
          autoComplete="chrome-off"
          required
        />
        <InputItem
          name={[index, 'apt']}
          label="Apt."
          form={form}
          aptField
          autoComplete="chrome-off"
        />
        <InputItem
          name={[index, 'city']}
          label="City"
          form={form}
          addressGroupField
          autoComplete="chrome-off"
          required
        />
        <InputItem
          name={[index, 'state_id']}
          label="State"
          form={form}
          type="select"
          options={stateOptions}
          addressGroupField
          autoComplete="chrome-off"
          required
        />
        <InputItem
          name={[index, 'zip_code']}
          label="Zip Code"
          form={form}
          type="number"
          mask={ZIP_CODE_NUMBER_FIELD.MASK}
          placeholder={ZIP_CODE_NUMBER_FIELD.PLACEHOLDER}
          addressGroupField
          autoComplete="chrome-off"
          required
        />
      </FormSection>
    </div>
  );
};

export default NewCardForm;
