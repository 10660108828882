import {
  standardRequiredFieldObj,
  phoneFieldObj,
  emailFieldObj,
} from 'utils/form';
import { getMomentDate, getTeammateProfileDateTime } from 'utils/date';
import { STATUS_VALUES } from 'helper/const';
import { ButtonColors } from 'components/Button';

export const validateObj = permission => {
  const detailFields = permission.detailFields || [];
  return {
    first_name: [standardRequiredFieldObj],
    last_name: [standardRequiredFieldObj],
    email: detailFields.includes('email')
      ? [standardRequiredFieldObj, emailFieldObj]
      : [emailFieldObj],
    alternate_email: [emailFieldObj],
    phone_number: detailFields.includes('phone_number')
      ? [standardRequiredFieldObj, phoneFieldObj]
      : [phoneFieldObj],
    dob: [],
    group_id: detailFields.includes('group_id')
      ? [standardRequiredFieldObj]
      : [],
    role_id: detailFields.includes('role_id') ? [standardRequiredFieldObj] : [],
    start_date: detailFields.includes('start_date')
      ? [standardRequiredFieldObj]
      : [],
    employee_id: [],
  };
};

export const getStatusMapping = (
  status,
  classes,
  buttonClick,
  { invite_sent_at, status_changed_at, status_changed_by }
) => {
  const inviteSentAt = invite_sent_at
    ? getTeammateProfileDateTime(invite_sent_at)
    : '';
  const statusChangeAt = status_changed_at
    ? getTeammateProfileDateTime(status_changed_at)
    : '';
  const isReActivate = !!status_changed_by;
  const statusMapping = {
    [STATUS_VALUES.INVITE_SENT]: {
      label: 'Invite Sent',
      color: 'warning',
      des: `Invite sent on ${inviteSentAt}`,
      button: {
        label: 'Resend Invite',
        color: ButtonColors.GRAY,
        className: classes.resendInviteBtn,
        onClick: buttonClick(STATUS_VALUES.INVITE_SENT),
      },
    },
    [STATUS_VALUES.ACTIVE]: {
      label: 'Active',
      color: 'green',
      des: isReActivate
        ? `Reactivated by ${status_changed_by} ${statusChangeAt}`
        : `Accepted invite on ${statusChangeAt}`,
      button: {
        label: 'Deactivate',
        color: 'danger',
        className: classes.deactivateBtn,
        onClick: buttonClick(STATUS_VALUES.ACTIVE),
      },
    },
    [STATUS_VALUES.IN_ACTIVE]: {
      label: 'Inactive',
      color: 'red',
      des: `Deactivated by ${status_changed_by} ${statusChangeAt}`,
      button: {
        label: 'Reactivate',
        color: ButtonColors.GREEN,
        className: classes.activateBtn,
        onClick: buttonClick(STATUS_VALUES.IN_ACTIVE),
      },
    },
  };
  return statusMapping[status] || {};
};
export const getInitialValues = detailData => {
  const initialValues = {
    first_name: detailData.first_name || '',
    mi: detailData.mi || '',
    last_name: detailData.last_name || '',
    email: detailData.email || '',
    alternate_email: detailData.alternate_email || '',
    phone_number: detailData.phone_number || '',
    dob: detailData.dob ? getMomentDate(detailData.dob, false, true) : '',
    group_id: detailData.group_id ? detailData.group_id : null,
    role_id: detailData.role_id ? detailData.role_id : null,
    start_date: detailData.start_date
      ? getMomentDate(detailData.start_date, false, true)
      : '',
    employee_id: detailData.employee_id || '',
  };
  return initialValues;
};
export const getEditTeammateBodyRequest = (
  values,
  finalFile,
  currentProfilePic,
  finalProfileSrc
) => {
  const bodyRequest = new FormData();
  Object.keys(values).map(k => {
    bodyRequest.append(k, values[k] || '');
    return true;
  });
  bodyRequest.append('mi', values.mi || '');
  bodyRequest.append('alternate_email', values.alternate_email || '');
  if (!!values.dob) {
    bodyRequest.append('dob', values.dob);
  }
  bodyRequest.append('employee_id', values.employee_id || '');
  if (finalFile && finalFile.type && finalProfileSrc) {
    bodyRequest.append('profile_pic', finalFile);
    bodyRequest.append('remove_profile_pic', 0);
  } else if (currentProfilePic && !finalProfileSrc) {
    bodyRequest.append('remove_profile_pic', 1);
  }
  return bodyRequest;
};
