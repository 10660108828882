const initialCustomFieldState = {
  // POST
  createCustomField: {
    loading: {},
    data: {},
    error: {},
  },
  updateCustomField: {
    loading: {},
    data: {},
    error: {},
  },
  deleteCustomField: {
    loading: {},
    data: {},
    error: {},
  },
};

export { initialCustomFieldState };
