import { ROLES } from 'helper/const';
import { adminPermission } from './admin';
import { clientPermission } from './client';
import { employeePermission } from './employee';
import { clientdriverPermission } from './clientDriver';

const permissions = {
  [ROLES.ADMIN]: adminPermission,
  [ROLES.SUPER_ADMIN]: adminPermission,
  [ROLES.CLIENT]: clientPermission,
  [ROLES.CLIENT_DRIVER]: clientdriverPermission,
};

const getPermission = (role = ROLES.CLIENT, currentUser = {}) => {
  // if role is not Admin or Client or Driver, it will be Employee
  // For now, we don't have the way to know role is Employee
  // Employee is not Admin but had group_id
  // So this way will cover
  return (
    permissions[role] ||
    (!!currentUser.group_id ? employeePermission : clientPermission)
  );
};

export default getPermission;
