import React from 'react';
import cn from 'classnames';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  Title,
} from 'views/pages/ClientDetail/components/Elements';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { EMPLOYMENT_APPLICATION_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const EmploymentApplication = ({ className, isEdit, form, data }) => {
  return (
    <div className={cn(classes.employmentApplicationSection, className)}>
      <Title text="Employment Application" />
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            <InputItem
              name={'employment_application'}
              label="Employment Application"
              form={form}
              type="file"
            />
            <InputItem
              name={'inquiry_to_previous_employers'}
              label="Inquiry to Pervious Employers"
              form={form}
              type="file"
            />
            <InputItem
              name={'certification_of_violations'}
              label="Driver’s Certification of Violations"
              form={form}
              type="file"
            />
            <InputItem
              name={'employment_other'}
              label="Other"
              form={form}
              type="file"
            />
          </FormSection>
        ) : (
          <ListInfo objKeys={EMPLOYMENT_APPLICATION_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default EmploymentApplication;
