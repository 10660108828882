import React from 'react';
import cn from 'classnames';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  Title,
} from 'views/pages/ClientDetail/components/Elements';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { DOCUMENTS_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const Documents = ({ className, isEdit, form, data }) => {
  return (
    <div className={cn(classes.documentsSection, className)}>
      <Title text="Documents" />
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            <InputItem name={'w9'} label="W9" form={form} type="file" />
            <InputItem name={'w2'} label="W2" form={form} type="file" />
            <InputItem name={'1099'} label="1099" form={form} type="file" />
            <InputItem
              name={'documents_other'}
              label="Other"
              form={form}
              type="file"
            />
          </FormSection>
        ) : (
          <ListInfo objKeys={DOCUMENTS_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default Documents;
