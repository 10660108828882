// Sometime we need to convert some format strings into number as string
// So we replace all other chars: space, _, -, (, )
// We do not use parseInt because some numbers will have 0 at the beginning char
// So we will other params of function to use parseInt if we need and we make sure first char is not 0
const parseStringToNumber = (str, isUseParseInt) => {
  if (typeof str !== 'string') return str;
  if (str) {
    const newStr = str
      .replace(/ /g, '')
      .replace(/_/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace(/\+/g, '')
      .replace(/-/g, '')
      .replace(/%/g, '');
    return isUseParseInt ? parseInt(newStr, 10) : newStr;
  }
  return '';
};

const parseNumberWithComma = value => {
  return value || value === 0
    ? String(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    : '';
};

const getStandardCurrency = value => {
  if ((!value && value !== 0) || typeof value !== 'number') {
    return '';
  }
  return value
    ? parseNumberWithComma(value.toFixed(2))
    : value === 0
    ? '0.00'
    : '';
};
const getCurrencyValue = (value, hasSpace) => {
  if (typeof value === 'object' || (!value && value !== 0)) {
    return '-';
  }
  let val = '';
  // use space between currency symbol and value
  const space = hasSpace ? ' ' : '';
  if (typeof value === 'string') {
    val = `$${space}${getStandardCurrency(parseFloat(value))}`;
  } else {
    val = `$${space}${getStandardCurrency(value)}`;
  }
  return val;
};

export { parseStringToNumber, getCurrencyValue, parseNumberWithComma };
