import React, { useState } from 'react';
import get from 'lodash/get';
import cn from 'classnames';
import Modal from 'components/Modal';
import { MODAL } from 'helper/const';
import { validateForm, scrollToErrorField } from 'utils/form';
import { getLastCharOfText } from 'utils/text';
import { InputPassword, FakeAutocompleteField } from 'components/FormFields';
import Actions from './Actions';
import SimpleView from './SimpleView';
import FullView from './FullView';
import classes from './SecurityBlock.module.scss';

let securityTimeout = null;

const SecurityBlock = ({
  className,
  form,
  index,
  parentName,
  renderEdit,
  data,
  onSubmit,
  onValidatePassword,
  submitLoading,
  getBR,
  simpleFields,
  fullFields,
  title,
  validateObj = {},
  wrapperId,
  hideActions,
  resetAfter2s,
  deleteObj,
  ...other
}) => {
  const parentEdit = other.isEdit;
  const [password, setPassword] = useState('');
  const [editLoading, setEditLoading] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState('view');
  const handleView = () => {
    setPassword('');
    setIsOpen(true);
    setAction('view');
  };
  const handleEdit = () => {
    setPassword('');
    setIsOpen(true);
    setAction('edit');
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleSubmit = () => {
    const values = form.getFieldsValue();
    const currentObj = get(values, `${parentName}[${index}]`) || {};
    if (onSubmit && validateObj) {
      const fieldErrors = validateForm(validateObj, currentObj);
      if (fieldErrors.length > 0) {
        const displayFieldErrors = fieldErrors.map(field => ({
          ...field,
          name: [parentName, index, field.name],
        }));
        form.setFields(displayFieldErrors);
        scrollToErrorField(wrapperId);
      } else {
        setEditLoading(true);
        onSubmit(
          currentObj.originId,
          getBR ? getBR(currentObj) : {},
          res => {
            const newAccountId = res.data?.id || '';
            if (newAccountId) {
              form.setFieldsValue({
                [parentName]: (get(values, `${parentName}`) || []).map(
                  (obj, i) => {
                    if (index === i) {
                      obj.originId = newAccountId;
                      obj.last4 = getLastCharOfText(obj.card_number, 4);
                    }
                    return obj;
                  }
                ),
              });
            }
            setIsEdit(false);
            setEditLoading(false);
          },
          () => {
            setEditLoading(false);
          }
        );
      }
    }
  };
  const actionsProps = {
    isView,
    setIsView: val => {
      if (!val && securityTimeout) {
        clearTimeout(securityTimeout);
        securityTimeout = null;
      }
      setIsView(val);
    },
    isEdit,
    setIsEdit,
    onView: handleView,
    onEdit: handleEdit,
    parentEdit,
    onSubmit: handleSubmit,
    loading: editLoading,
    deleteLoading: !!deleteObj?.loading,
  };
  const handleValidatePassword = () => {
    if (onValidatePassword)
      onValidatePassword(password, () => {
        setIsOpen(false);
        if (securityTimeout) {
          clearTimeout(securityTimeout);
          securityTimeout = null;
        }
        if (action === 'view') {
          setIsView(true);
          if (resetAfter2s) {
            securityTimeout = setTimeout(() => {
              setIsView(false);
            }, 2000 * 60);
          }
        } else {
          setIsEdit(true);
          if (resetAfter2s) {
            securityTimeout = setTimeout(() => {
              setIsEdit(false);
            }, 2000 * 60);
          }
        }
      });
  };
  const modalProps = {
    isOpen,
    onSubmit: () => {
      handleValidatePassword();
    },
    onClose: handleClose,
    title,
    maskClosable: false,
    closable: true,
    hideCancel: true,
    okText: 'Submit',
    width: MODAL.SIZE.SMALL,
    className: classes.unlockModal,
    isSubmitLoading: submitLoading,
    closeIcon: null,
    okBtnProps: {
      size: 'large',
      disabled: !password,
    },
    blueColor: true,
  };
  return (
    <div className={cn(classes.wrapper, className)}>
      {!hideActions ? <Actions {...actionsProps} /> : ''}
      {isEdit ? (
        <>{renderEdit ? renderEdit() : ''}</>
      ) : (
        <div>
          {isView ? (
            <FullView
              fields={fullFields}
              form={form}
              parentName={parentName}
              parentIndex={index}
            />
          ) : (
            <SimpleView
              fields={simpleFields}
              data={data}
              deleteObj={deleteObj}
            />
          )}
        </div>
      )}
      <Modal {...modalProps}>
        <FakeAutocompleteField />
        <p className={classes.description}>
          In order to view or edit this information, you must provide the
          security password.
        </p>
        <label>Password</label>
        <InputPassword
          size={'medium'}
          name="pwd"
          isBlue
          autoFocus={true}
          value={password}
          onChange={e => {
            setPassword(e.target.value);
          }}
          onPressEnter={e => {
            handleValidatePassword();
          }}
        />
      </Modal>
    </div>
  );
};

export default SecurityBlock;
