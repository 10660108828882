import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

const getNewDataByUpdateObj = (
  payload,
  oldData,
  attr = 'encrypt_id',
  isUpdateAttr,
  updatedAttr
) => {
  const updatedData = payload || {};
  const newData = [...(oldData || [])];
  const updatedIndex = findIndex(newData, {
    [attr]: updatedData[attr],
  });
  const oldObject = find(newData, {
    [attr]: updatedData[attr],
  });
  if (updatedIndex >= 0) {
    newData[updatedIndex] = isUpdateAttr
      ? {
          ...oldObject,
          [updatedAttr]: updatedData[updatedAttr],
        }
      : updatedData;
  }
  return newData;
};
const getUpdateClientDetailData = (state, sectionKey, sectionData, type) => {
  const oldClientDetailData = { ...(state.clientDetail.data || {}) };
  oldClientDetailData.sections = (oldClientDetailData.sections || []).map(
    section => {
      if (section.key === sectionKey) {
        if (type) {
          section.data[type] = sectionData;
        } else {
          section.data = sectionData;
        }
      }
      return section;
    }
  );
  return oldClientDetailData;
};
const getUpdateCDWithTotal = (state, total, key, totalKey) => {
  const oldClientDetailData = { ...(state.clientDetail.data || {}) };
  oldClientDetailData.sections = (oldClientDetailData.sections || []).map(
    section => {
      if (section.key === key) {
        section[totalKey] = total;
      }
      return section;
    }
  );
  return oldClientDetailData;
};
const getUpdatedArrayData = (storeData, id, coData) => {
  const newData = (storeData.data || []).map(obj => {
    if (obj.id === id) {
      obj = { ...coData };
    }
    return obj;
  });
  return newData;
};
const getUpdatedDriverAccount = (
  oldData,
  newData,
  driverId,
  id,
  isNew,
  attr
) => {
  let driverData = {};
  oldData.map(d => {
    if (d.id === driverId) {
      if (isNew) {
        const hasPrimary = newData.filter(obj => !!obj.is_primary).length > 0;
        const oldD = hasPrimary
          ? d[attr].map(obj => ({
              ...obj,
              is_primary: 0,
            }))
          : [...d[attr]];
        d[attr] = [...oldD, ...newData];
      } else {
        const hasPrimary = !!newData.is_primary;
        d[attr] = (d[attr] || []).map(a => {
          if (a.id === id) {
            a = {
              ...a,
              ...newData,
            };
          } else {
            a.is_primary = hasPrimary ? 0 : a.is_primary;
          }
          return a;
        });
      }
      driverData = { ...d };
    }
    return true;
  });
  return driverData;
};
const getUpdatedCompanyOfficialsWithPC = (reducerData, primaryId) => {
  const newData = (reducerData?.data || []).map(co => {
    if (co.id === primaryId) {
      co.is_primary = 1;
    } else {
      co.is_primary = 0;
    }
    return co;
  });
  return newData;
};

export {
  getNewDataByUpdateObj,
  getUpdateClientDetailData,
  getUpdateCDWithTotal,
  getUpdatedArrayData,
  getUpdatedDriverAccount,
  getUpdatedCompanyOfficialsWithPC,
};
