import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import toast from 'helper/toast';
import PageContent from 'components/PageContent';
import Button, { ButtonColors } from 'components/Button';
import {
  COMPLETED_WORK_QUEUE_NAME,
  CANCELLED_WORK_QUEUE_NAME,
} from 'helper/const';
import ROUTES from 'routes';
import Column, { ColumnHeader, ColumnTitle } from './components/Column';
import AddColumn from './components/AddColumn';
import OrderCard from './components/OrderCard';
import Filter from './Filter';
import {
  handleCb,
  getNewColumn,
  reorder,
  move,
  getListStyle,
  getItemStyle,
  getUpdatedColumns,
  getNewArrayObjects,
  getDisplaySortLabel,
} from './utils';
import { DEFAULT_SORT, SORT_OPTIONS } from './constants';
import { useMainContentData, useActions } from './selectorData';
import classes from './WorkQueue.module.scss';

const MainContent = ({
  departmentName,
  permission,
  userFullName,
  departmentId,
  reloadWorkQueue,
  filters,
  setFilters,
  sorts,
  setSorts,
  handleSettingsStore,
  loadMoreLoading,
}) => {
  const isCompletedWorkQueuePage =
    departmentId === COMPLETED_WORK_QUEUE_NAME.NORMAL;
  const completedStatuses = [
    COMPLETED_WORK_QUEUE_NAME.NORMAL,
    CANCELLED_WORK_QUEUE_NAME.NORMAL,
  ];
  const history = useHistory();
  const [prevCLOrder, setPrevCLOrder] = useState(-1);
  const [stateWorkQueueColumns, setStateWorkQueueColumns] = useState([]);
  const {
    workQueueColumns,
    addWorkQueueColumnLoading,
    orderChecklist,
    addChecklistLoading,
    orderChecklistLoading,
  } = useMainContentData();
  const {
    addWorkQueueColumn,
    updateWorkQueueColumn,
    syncAddWorkQueueColumn,
    syncUpdateWorkQueueColumn,
    deleteWorkQueueColumn,
    moveWorkQueueOrderToColumn,
    updateClientOrderStatus,
    saveClientOrderChecklist,
    syncUpdateChecklists,
    getChecklists,
    checkUncheckChecklist,
  } = useActions();
  const hasLoadMore =
    workQueueColumns.filter(c => !!c.orders && !!c.orders.next_page_url)
      .length > 0;
  const handleAddColumn = (values, cb) => {
    addWorkQueueColumn(
      departmentId,
      {
        title: values.title,
      },
      handleCb(res => {
        toast.success('Column added successfully.');
        syncAddWorkQueueColumn(getNewColumn(res));
        if (cb) cb();
      })
    );
  };
  const handleUpdateColumn = (columnId, oldTitle) => (values, cb) => {
    syncUpdateWorkQueueColumn(columnId, 'title', values.title);
    if (cb) cb();
    updateWorkQueueColumn(
      departmentId,
      {
        title: values.title,
        work_queue_id: columnId,
      },
      handleCb(null, () => {
        syncUpdateWorkQueueColumn(columnId, 'title', oldTitle);
      })
    );
  };
  const handleLoadChecklist = columnOrder => {
    getChecklists({
      checklist_type: 'Order',
      checklist_type_id: columnOrder.id,
      emptyPrevState: columnOrder.id !== prevCLOrder,
    });
    setPrevCLOrder(columnOrder.id);
  };
  const handleAddChecklist = (columnOrder, columnOrders, columnId) => (
    val,
    cb
  ) => {
    saveClientOrderChecklist(
      columnOrder.company_id,
      columnOrder.id,
      {
        checklist: [
          {
            description: val,
            order: (orderChecklist.length || 0) + 1,
          },
        ],
      },
      handleCb(res => {
        syncUpdateChecklists(res.data);
        syncUpdateWorkQueueColumn(
          columnId,
          'orders',
          getNewArrayObjects(
            columnOrders,
            columnOrder.id,
            'total_checklists_count',
            val => val + 1
          )
        );
        if (cb) cb();
      })
    );
  };
  const handleCheckedChange = (columnOrder, columnOrders, columnId) => (
    checked,
    checklistId
  ) => {
    checkUncheckChecklist(
      checklistId,
      checked,
      handleCb(res => {
        syncUpdateChecklists(
          orderChecklist,
          checklistId,
          'is_checked',
          checked ? 1 : 0
        );
        syncUpdateWorkQueueColumn(
          columnId,
          'orders',
          getNewArrayObjects(
            columnOrders,
            columnOrder.id,
            'completed_checklists_count',
            val => (checked ? val + 1 : val - 1)
          )
        );
      })
    );
  };
  const handleStatusChange = (order, columnId) => newStatus => {
    const oldStatus = order.status;
    const newColumns = getUpdatedColumns(
      stateWorkQueueColumns,
      columnId,
      order.id,
      'status',
      newStatus
    );
    const foundColumn = find(newColumns, {
      id: columnId,
    });
    if (foundColumn) {
      syncUpdateWorkQueueColumn(columnId, 'orders', foundColumn.orders?.data);
    }
    setStateWorkQueueColumns(newColumns);
    updateClientOrderStatus(
      order.company_id,
      order.id,
      newStatus,
      handleCb(
        () => {},
        () => {
          const ordersWithOldStatus = (foundColumn?.orders?.data || []).map(
            o => {
              if (o.id === order.id) {
                o.status = oldStatus;
              }
              return o;
            }
          );
          syncUpdateWorkQueueColumn(columnId, 'orders', ordersWithOldStatus);
        }
      )
    );
  };
  const onDragEnd = ({ source, destination }) => {
    // dropped outside the list
    const sInd = +source?.droppableId;
    const dInd = +destination?.droppableId;
    if (!destination) {
      return;
    }
    const isMove = sInd !== dInd;
    const actionFn = isMove ? move : reorder;
    const cloneColumns = cloneDeep(stateWorkQueueColumns);
    const newColumns = actionFn(source, destination, cloneColumns);
    const sourceColumnObj = { ...(stateWorkQueueColumns[sInd] || {}) };
    const sourceColumnId = sourceColumnObj.id || '';
    const orderId = get(sourceColumnObj, `orders.data[${source.index}].id`, '');
    const targetColumnObj = { ...(stateWorkQueueColumns[dInd] || {}) };
    const targetColumnId = targetColumnObj.id || '';
    if (orderId && targetColumnId) {
      moveWorkQueueOrderToColumn(
        departmentId,
        isMove ? targetColumnId : sourceColumnId,
        orderId,
        {
          orderNumber: destination.index + 1,
          columnData: newColumns,
          isMove,
          sourceColumnId,
        }
      );
    }
    setStateWorkQueueColumns(newColumns);
  };
  useEffect(() => {
    if (!isEqual(workQueueColumns, stateWorkQueueColumns)) {
      setStateWorkQueueColumns(workQueueColumns);
      const newSorts = {};
      workQueueColumns.map(c => {
        const previousSortVal = sorts[c.id] || '';
        newSorts[c.id] = !previousSortVal ? DEFAULT_SORT : previousSortVal;
        return true;
      });
      setSorts(newSorts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workQueueColumns]);

  return (
    <PageContent initFullHeight hasMargin className={cn(classes.mainContent)}>
      <h2>Welcome back, {userFullName}!</h2>
      <div className={classes.title}>
        {departmentName || ''} {isCompletedWorkQueuePage ? '' : 'Department'}{' '}
        Work Queue
        <Filter
          reloadWorkQueue={reloadWorkQueue}
          filters={filters}
          setFilters={setFilters}
          departmentId={departmentId}
          handleSettingsStore={handleSettingsStore}
          isCompletedWorkQueuePage={isCompletedWorkQueuePage}
        />
      </div>
      <div
        className={cn(classes.innerContent, {
          [classes.hasLoadMore]: hasLoadMore,
        })}
        id="work-queue-inner-content"
      >
        <div id="work-queue-inner-content-child">
          <div style={{ display: 'flex' }}>
            <DragDropContext onDragEnd={onDragEnd}>
              {stateWorkQueueColumns.map((qwColumn, ind) => {
                const orders = (qwColumn.orders?.data || []).filter(o =>
                  !isCompletedWorkQueuePage
                    ? !completedStatuses.includes(o.status)
                    : completedStatuses.includes(o.status)
                );
                const columnId = qwColumn.id;
                const currentSort = sorts[columnId] || '';
                const isDES = currentSort.includes('z_a');
                return (
                  <Droppable key={ind} droppableId={`${ind}`}>
                    {(provided, snapshot) => {
                      return (
                        <Column
                          innerRef={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          {...provided.droppableProps}
                          className={classes.column}
                        >
                          <div className={classes.columnHeaderWrapper}>
                            <ColumnHeader
                              count={qwColumn.orders?.total || 0}
                              title={qwColumn.title}
                              onUpdate={handleUpdateColumn(
                                columnId,
                                qwColumn.title
                              )}
                              disabled={qwColumn.is_default}
                              tooltip={
                                qwColumn.is_default
                                  ? `Default column name can’t be edited.`
                                  : ''
                              }
                            />
                            <ColumnTitle
                              column={qwColumn}
                              onDelete={() => {
                                deleteWorkQueueColumn(departmentId, columnId);
                              }}
                              text={getDisplaySortLabel(
                                (find(SORT_OPTIONS, { key: currentSort }) || {})
                                  .label
                              )}
                              isDES={isDES}
                              currentSort={currentSort}
                              onSort={key => () => {
                                const newSorts = {
                                  ...sorts,
                                  [columnId]: key,
                                };
                                setSorts(newSorts);
                                handleSettingsStore(null, newSorts);
                                reloadWorkQueue(null, newSorts, true);
                              }}
                            />
                          </div>
                          <div className={classes.orderCards}>
                            {orders.map((order, index) => {
                              const stringId = String(order.id);
                              return (
                                <Draggable
                                  key={stringId}
                                  draggableId={stringId}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <OrderCard
                                      key={order.id}
                                      onLoadChecklist={handleLoadChecklist}
                                      onAddChecklist={handleAddChecklist(
                                        order,
                                        orders,
                                        columnId
                                      )}
                                      onCheckedChange={handleCheckedChange(
                                        order,
                                        orders,
                                        columnId
                                      )}
                                      checklists={orderChecklist}
                                      addChecklistLoading={addChecklistLoading}
                                      checklistLoading={orderChecklistLoading}
                                      order={order}
                                      onClick={() => {
                                        history.push(
                                          ROUTES.CLIENT_ORDER_DETAIL({
                                            id: order.company_id || '0',
                                            orderId: order.id || '0',
                                          })
                                        );
                                      }}
                                      onStatusChange={handleStatusChange(
                                        order,
                                        columnId
                                      )}
                                      innerRef={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    />
                                  )}
                                </Draggable>
                              );
                            })}
                          </div>
                          {provided.placeholder}
                        </Column>
                      );
                    }}
                  </Droppable>
                );
              })}
            </DragDropContext>
          </div>
        </div>
        <Column className={cn(classes.column, classes.addColumn)}>
          {permission.addColumn && (
            <AddColumn
              onAdd={handleAddColumn}
              loading={addWorkQueueColumnLoading}
            />
          )}
        </Column>
        {hasLoadMore && (
          <div className={classes.loadMore}>
            <Button
              type={'primary'}
              size={'large'}
              htmlType={'button'}
              color={ButtonColors.BLUE}
              onClick={() => {
                const currentPage = workQueueColumns.filter(
                  c => !!c.orders
                )?.[0]?.orders?.current_page;
                reloadWorkQueue(null, null, true, currentPage + 1);
              }}
              loading={loadMoreLoading}
            >
              Load More
            </Button>
          </div>
        )}
      </div>
    </PageContent>
  );
};

export default MainContent;
