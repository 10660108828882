import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import Collapse, { Panel } from 'components/Collapse';
import toast from 'helper/toast';
import { getErrorMessageFromObj } from 'utils/object';
import Header from 'views/pages/ClientDetail/CompanyOfficial/components/Header';
import NotFound from 'views/pages/ClientDetail/CompanyOfficial/components/NotFound';
import StatusTag from 'views/pages/ClientDetail/CompanyOfficial/components/StatusTag';
import PanelHeader from 'views/pages/ClientDetail/CompanyOfficial/PanelHeader';
import { ALL_OPTION } from 'views/pages/ClientDetail/CompanyOfficial/constants';
import { TooltipBlock } from 'components/Tooltip';
import {
  afterCbFn,
  getLabel,
  isAlreadyNew,
} from 'views/pages/ClientDetail/CompanyOfficial/utils';
import Section from './components/Section';
import {
  getDriverBR,
  getCheckingAccountBR,
  parseCheckingAccountsData,
  parseFuelCardsData,
  getFuelCardsBR,
  getTruckTrailerAssigned,
} from './utils';
import { DEFAULT_SECTIONS, NEW_KEY } from './constants';
import {
  CollapseTable,
  CollapseAdditionalLabel,
  LabelWithAvatar,
} from '../components/Elements';
import { onKeyChangeFn, getEditKeys, editChangeFn, getIsKey } from '../utils';
import { useIndexData, useActions } from './selectorData';
import classes from './Driver.module.scss';

let isAddedAccount = false;
let isAddedCard = false;

const Driver = ({
  className,
  confirmModal,
  openWarningModal,
  setIsEditing,
  activeTab,
  clientId,
  setIsEdit,
  status,
  setStatus,
  onUpdated,
  permission,
}) => {
  const setIsEditParent = setIsEdit;
  const {
    drivers,
    updateDriverLoading,
    getDriverAccountLoading,
    validatePasswordLoading,
    getFuelCardLoading,
    createAccountsLoading,
    createFuelCardsLoading,
  } = useIndexData();
  const {
    getDrivers,
    getClientDetailLog,
    updateDriver,
    createDriver,
    updateDriverStatus,
    getDriverAccount,
    createDriverAccount,
    updateDriverAccount,
    validatePassword,
    createDriverFuelCard,
    updateDriverFuelCard,
    getDriverFuelCard,
  } = useActions();
  const [createLoading, setCreateLoading] = useState(false);
  const [finalFile, setFinalFile] = useState({});
  const [avatarFinalSrc, setAvatarFinalSrc] = useState('');
  const [statusLoading, setStatusLoading] = useState({});
  const [sections, setSections] = useState(DEFAULT_SECTIONS);
  const [activeKeys, setActiveKeys] = useState([]);
  const [editKeys, setEditKeys] = useState([]);
  const [editChanged, setEditChanged] = useState({});
  const notFound = sections.length === 0 && drivers.length === 0;
  const handleEditChange = (...params) =>
    editChangeFn(editChanged, setEditChanged, setIsEditing, ...params);
  const handleAddCA = (driverId, values, cb) => {
    const checkingAccountsBR = getCheckingAccountBR(values.accounts || []);
    if (checkingAccountsBR.length > 0) {
      createDriverAccount(
        clientId,
        driverId,
        { accounts: checkingAccountsBR },
        (res, err) => {
          if (res) {
            if (cb) cb(res);
          } else {
            toast.error(getErrorMessageFromObj(err)[0]);
          }
        }
      );
    } else if (cb) {
      cb();
    }
  };
  const handleAddCard = (driverId, values, cb) => {
    const cardBR = getFuelCardsBR(values.cards || []);
    if (cardBR.length > 0) {
      createDriverFuelCard(
        clientId,
        driverId,
        { fuel_cards: cardBR, type: 'driver' },
        (res, err) => {
          if (res) {
            if (cb) cb(res);
          } else {
            toast.error(getErrorMessageFromObj(err)[0]);
          }
        }
      );
    } else if (cb) {
      cb();
    }
  };
  const handleEditCA = driverId => (accountId, accountBR, cb, errCb) => {
    updateDriverAccount(
      clientId,
      driverId,
      accountId,
      accountBR,
      (res, err) => {
        if (res) {
          getClientDetailLog({ id: clientId });
          if (cb) cb(res);
        } else {
          if (errCb) errCb();
          toast.error(getErrorMessageFromObj(err)[0]);
        }
      }
    );
  };
  const handleEditCard = driverId => (cardId, cardBR, cb, errCb) => {
    updateDriverFuelCard(clientId, driverId, cardId, cardBR, (res, err) => {
      if (res) {
        getClientDetailLog({ id: clientId });
        if (cb) cb(res);
      } else {
        if (errCb) errCb();
        toast.error(getErrorMessageFromObj(err)[0]);
      }
    });
  };
  const handleValidatePassword = driverId => type => (id, caCb) => (
    password,
    cb
  ) => {
    validatePassword({ password }, (res, err) => {
      if (res) {
        const actionFn =
          type === 'accounts' ? getDriverAccount : getDriverFuelCard;
        actionFn(clientId, driverId, id, (res1, err1) => {
          if (res1) {
            if (caCb) caCb(res1);
            if (cb) cb();
          } else {
            toast.error(getErrorMessageFromObj(err1)[0]);
          }
        });
      } else {
        toast.error(getErrorMessageFromObj(err)[0]);
      }
    });
  };
  const handleSubmitCb = (driverId, cb, res) => {
    if (isAddedAccount && isAddedCard) {
      isAddedAccount = false;
      isAddedCard = false;
      getClientDetailLog({ id: clientId });
      if (!driverId) {
        handleLoadDriver(status, () => {
          setCreateLoading(false);
          if (cb) cb();
        });
      } else if (cb) cb(res);
    }
  };
  const handleSubmit = (id, currentProfilePic) => (values, cb) => {
    const driverId = id && id !== NEW_KEY ? id : '';
    const actionFn = driverId ? updateDriver : createDriver;
    if (!driverId) {
      setCreateLoading(true);
    }
    isAddedAccount = false;
    isAddedCard = false;
    actionFn(
      clientId,
      getDriverBR(
        values,
        driverId,
        finalFile,
        currentProfilePic,
        avatarFinalSrc
      ),
      afterCbFn(
        res => {
          if (onUpdated) onUpdated();
          const newDriverId = get(res, 'data.id', '');
          handleAddCA(newDriverId, values, () => {
            isAddedAccount = true;
            handleSubmitCb(driverId, cb, res);
          });
          handleAddCard(newDriverId, values, () => {
            isAddedCard = true;
            handleSubmitCb(driverId, cb, res);
          });
        },
        () => {
          if (!driverId) setCreateLoading(false);
        }
      ),
      driverId
    );
  };
  const handleLoadDriver = (val, cb) => {
    const filters = {};
    if (val !== ALL_OPTION.value) {
      filters.status = val;
    }
    getDrivers({ id: clientId }, filters, afterCbFn(cb));
  };
  const handleSetStatusLoading = (id, val) => {
    setStatusLoading({
      ...statusLoading,
      [id]: val,
    });
  };
  const handleStatusChange = id => newStatus => {
    handleSetStatusLoading(id, true);
    updateDriverStatus(
      clientId,
      id,
      { status: newStatus },
      afterCbFn(
        () => {
          if (onUpdated) onUpdated();
          handleLoadDriver(status, () => {
            handleSetStatusLoading(id, false);
            setActiveKeys(activeKeys.filter(key => String(key) !== String(id)));
          });
        },
        () => handleSetStatusLoading(id, false)
      )
    );
  };

  useEffect(() => {
    if (activeTab === 1) {
      setSections(DEFAULT_SECTIONS);
      setEditKeys([]);
      setEditChanged({});
    }
  }, [activeTab]);
  useEffect(() => {
    setIsEditParent(sections.length > 0 || editKeys.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections, editKeys]);

  return (
    <div className={cn(classes.wrapper, className)}>
      <Header
        title="Drivers"
        status={status}
        setStatus={val => {
          setStatus(val);
          handleLoadDriver(val);
        }}
        onAdd={
          !permission.addRecord
            ? null
            : () => {
                if (!isAlreadyNew(sections)) {
                  setSections([
                    { label: 'New Driver', id: NEW_KEY, isNew: true },
                    ...sections,
                  ]);
                  setEditKeys([...editKeys, NEW_KEY]);
                  setActiveKeys([...activeKeys, NEW_KEY]);
                  setFinalFile({});
                  setAvatarFinalSrc('');
                }
              }
        }
        className={classes.header}
      />
      {notFound ? (
        <NotFound />
      ) : (
        <div className={classes.content}>
          <CollapseTable hideHeader section className={classes.sectionTable}>
            <Collapse
              destroyInactivePanel
              onChange={keys => {
                onKeyChangeFn(keys, activeKeys, editKeys, setActiveKeys);
              }}
              activeKey={activeKeys}
            >
              {[...sections, ...drivers].map(section => {
                const { isNew, id, title, profile_pic } = section;
                const key = String(id);
                const isOO = title && title === 'Owner/Operator';
                const label = `${getLabel(section)}${
                  isOO ? ` (Owner / Operator)` : ''
                }`;
                const isActive = getIsKey(activeKeys, key);
                const isEdit = getIsKey(editKeys, key);
                const sectionInnerProps = {
                  clientId,
                  isOO,
                  rootKey: key,
                  isEdit,
                  isActive,
                  confirmModal,
                  setIsEdit: edit => {
                    const newEditKeys = getEditKeys(editKeys, edit, key);
                    setEditKeys(newEditKeys);
                    if (!edit) {
                      handleEditChange(key, false, newEditKeys);
                    }
                    if (isNew && !edit) {
                      setSections(DEFAULT_SECTIONS);
                    }
                    setFinalFile({});
                    setAvatarFinalSrc(profile_pic);
                  },
                  data: {
                    ...section,
                    accounts: parseCheckingAccountsData(section),
                    cards: parseFuelCardsData(section),
                  },
                  onFormChange: () => {
                    handleEditChange(key, true, editKeys);
                  },
                  openWarningModal,
                  loading:
                    (isNew ? createLoading : updateDriverLoading[id]) ||
                    createAccountsLoading ||
                    createFuelCardsLoading,
                  statusLoading: statusLoading[id],
                  onSubmit: handleSubmit(id, profile_pic),
                  onStatusChange: handleStatusChange(id),
                  onEditCheckingAccount: handleEditCA(id),
                  onEditFuelCard: handleEditCard(id),
                  onValidatePassword: handleValidatePassword(id),
                  validatePasswordLoading,
                  getDriverAccountLoading,
                  getFuelCardLoading,
                  isNew,
                };
                const avatarProps = {
                  confirmModal,
                  avatarFinalSrc,
                  setAvatarFinalSrc,
                  finalFile,
                  setFinalFile,
                  profilePic: profile_pic,
                };
                const { trucks, trailers } = getTruckTrailerAssigned(section);
                const trucksDisplay = trucks.join(', ');
                const trailersDisplay = trailers.join(', ');
                const pHeaderProps = {
                  label: (
                    <LabelWithAvatar
                      {...avatarProps}
                      label={label}
                      isEdit={isEdit}
                      onDelete={cb => {
                        setAvatarFinalSrc('');
                        if (cb) cb();
                      }}
                    />
                  ),
                  isActive,
                  additionalLabel: (
                    <CollapseAdditionalLabel isActive={isActive}>
                      {!isNew && (
                        <>
                          <span>
                            Trucks:{' '}
                            <TooltipBlock tooltip={trucksDisplay}>
                              {trucksDisplay}
                            </TooltipBlock>
                          </span>
                          <span>
                            Trailers:{' '}
                            <TooltipBlock tooltip={trailersDisplay}>
                              {trailersDisplay}
                            </TooltipBlock>
                          </span>
                        </>
                      )}
                    </CollapseAdditionalLabel>
                  ),
                  isEdit,
                  className: cn(classes.panelHeader, {
                    [classes.isActive]: isActive,
                    [classes.isEdit]: isEdit,
                  }),
                };
                return (
                  <Panel
                    className={classes.panel}
                    forceRender={true}
                    header={<PanelHeader {...pHeaderProps} />}
                    key={key}
                    extra={
                      !isActive ? <StatusTag status={section.status} /> : null
                    }
                  >
                    <Section {...sectionInnerProps} />
                  </Panel>
                );
              })}
            </Collapse>
          </CollapseTable>
        </div>
      )}
    </div>
  );
};

export default Driver;
