import React from 'react';
import cn from 'classnames';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  Title,
} from 'views/pages/ClientDetail/components/Elements';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { MEDICAL_CARD_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const MedicalCard = ({ className, isEdit, form, data }) => {
  return (
    <div className={cn(classes.medicalCardSection, className)}>
      <Title text="Medical Card" />
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            <InputItem
              name={'mc_expiration_date'}
              label="Expiration Date"
              form={form}
              type="date"
            />
            <InputItem
              name={'medical_card'}
              label="Medical Card Certificate"
              form={form}
              type="file"
            />
          </FormSection>
        ) : (
          <ListInfo objKeys={MEDICAL_CARD_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default MedicalCard;
