import React from 'react';
import { TeamOutlined } from '@ant-design/icons';
import cn from 'classnames';
import classes from './Elements.module.scss';

export const Color = ({ children, className, color = '' }) => {
  return (
    <span
      className={cn(
        classes.color,
        {
          [classes.isGreen]: color === 'green',
          [classes.isRed]: color === 'red',
          [classes.isWarning]: color === 'warning',
          [classes.isBlue]: color === 'blue',
        },
        className
      )}
    >
      {children}
    </span>
  );
};
export const NotInitDataContent = ({ className, icon, message }) => {
  return (
    <div className={cn(classes.notInitDataContent, className)}>
      {icon || <TeamOutlined />}
      <p>{message || 'There are no teammates'}</p>
    </div>
  );
};
