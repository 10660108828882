import { ROLES } from 'helper/const';
import { adminPermission } from './admin';
import { otherPermission } from './other';

const permissions = {
  [ROLES.ADMIN]: adminPermission,
  [ROLES.SUPER_ADMIN]: adminPermission,
  [ROLES.ACCOUNTING_MANAGER]: adminPermission,
  [ROLES.COMPLIANCE_MANAGER]: adminPermission,
  [ROLES.FACTORING_DEPARTMENT_MANAGER]: adminPermission,
  [ROLES.FACTORING_ACCOUNT_MANAGER]: adminPermission,
  [ROLES.SALE_MANAGER]: adminPermission,
  [ROLES.TECH_SUPPORT_MANAGER]: adminPermission,
  [ROLES.UNDERWRITING_MANAGER]: adminPermission,
};

const getPermission = role => {
  return permissions[role] || otherPermission;
};

export default getPermission;
