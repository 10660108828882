import Request from './request';

export const apiGetWorkQueueCCColumns = async (filters = {}, page = 1) => {
  return Request.call({
    url: `/work-queue?page=${page}&per_page=20`,
    method: 'POST',
    data: filters,
  });
};
export const apiGetWorkQueueColumns = async (
  departmentId,
  filters = {},
  page = 1
) => {
  return Request.call({
    url: `/work-queue/${departmentId}?page=${page}&per_page=20`,
    method: 'POST',
    data: filters,
  });
};
export const apiGetWorkQueueColumnOrders = async (
  departmentId,
  columnId,
  filters = {}
) => {
  return Request.call({
    url: `/work-queue/${departmentId}/orders/${columnId}`,
    method: 'POST',
    data: filters,
  });
};
export const apiGetWorkQueueFilters = async departmentId => {
  return Request.call({
    url: `/work-queue/${departmentId}/filters`,
    method: 'GET',
  });
};
export const apiModifyColumn = async (departmentId, data = {}) => {
  return Request.call({
    url: `/work-queue/${departmentId}/column`,
    method: 'POST',
    data,
  });
};
export const apiDeleteColumn = async (departmentId, columnId, data = {}) => {
  return Request.call({
    url: `/work-queue/${departmentId}/column/${columnId}`,
    method: 'DELETE',
  });
};
export const apiMoveOrderToColumn = async (
  departmentId,
  columnId,
  orderId,
  orderNumber
) => {
  return Request.call({
    url: `/work-queue/${departmentId}/move`,
    method: 'POST',
    data: {
      order_id: orderId,
      work_queue_id: columnId,
      work_queue_order: orderNumber,
    },
  });
};
export const apiStoreWorkQueueFilters = async (departmentId, data) => {
  return Request.call({
    url: `/work-queue/${departmentId}/filters`,
    method: 'POST',
    data: {
      filter_data: data ? JSON.stringify(data) : '',
    },
  });
};
