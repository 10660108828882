import React, { useState } from 'react';
import cn from 'classnames';
import { CheckCircleFilled, EditOutlined } from '@ant-design/icons';
import Button, { ButtonColors } from 'components/Button';
import classes from './ButtonActions.module.scss';

const ButtonActions = ({
  setIsEdit,
  isEdit,
  form,
  onSubmit,
  isFormChanged,
  loading,
  className,
  openWarningModal,
  hideEdit,
  preventDefault,
  selfLoading,
}) => {
  const [saveLoading, setSaveLoading] = useState(false);
  return (
    <div className={cn(classes.wrapper, className)}>
      {isEdit && (
        <Button
          type={'ghost'}
          htmlType="button"
          disabled={selfLoading ? saveLoading : loading}
          onClick={e => {
            if (isFormChanged) {
              openWarningModal(() => {
                setIsEdit(false);
              });
            } else {
              setIsEdit(false);
            }
            if (preventDefault) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          Cancel
        </Button>
      )}
      {!hideEdit && (
        <Button
          type={'primary'}
          htmlType="button"
          loading={selfLoading ? saveLoading : loading}
          color={ButtonColors.BLUE}
          onClick={e => {
            if (!isEdit) {
              setIsEdit(true);
            } else {
              if (selfLoading) {
                setSaveLoading(true);
              }
              const values = form.getFieldsValue();
              onSubmit(values, () => {
                setSaveLoading(false);
              });
            }
            if (preventDefault) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          leftIcon={isEdit ? <CheckCircleFilled /> : <EditOutlined />}
        >
          {isEdit ? 'Save' : 'Edit'}
        </Button>
      )}
    </div>
  );
};

export default ButtonActions;
