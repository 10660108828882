import { apiSearchDotNumber } from 'api';

import { intakeFormTypes } from '../types';

// SEARCH_DOT_NUMBER
export const searchDotNumber = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: intakeFormTypes.SEARCH_DOT_NUMBER,
    payload: data,
    asyncCall: () => {
      return apiSearchDotNumber(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
