import { FORMATS } from 'helper/const';

// Fields for initialValues
export const NORMAL_FIELDS = [
  'legal_name',
  'phone_number',
  'email',
  'state_taxpayer_id',
  'file_number',
  'dba',
  'ein',
  'mc_number',
  'dot_pin',
  'dot_number',
  'street_address',
  'apt_suite',
  'city',
  'county',
  'zip_code',
  'mailing_street_address',
  'mailing_apt_suite',
  'mailing_city',
  'mailing_county',
  'mailing_zip_code',
  'drug_alcohol_consortium_name',
  'consortium_contact_name',
  'consortium_phone',
  'consortium_email',
  'other',
];
export const NULL_FIELDS = [
  'industry_id',
  'business_entity_id',
  'sos_id',
  'state_id',
  'mailing_state_id',
  'gvw_id',
];
export const ARR_FIELDS = ['type_of_trucks'];
export const FILE_FIELDS = [
  'documents.articles_of_org',
  'documents.dba_form',
  'documents.ein_letter',
  'documents.mc_certificate',
  'documents.dot_pin_letter',
  'documents.drug_alcohol_policy_handbook',
  'documents.drug_alcohol_enrollment_certificate',
];
export const DATE_FIELDS = ['filing_date'];
export const BOOLEAN_FIELDS = ['is_same_as_physical'];
// Fields for body request
export const BODY_REQUEST_NORMAL_FIELDS = [
  'legal_name',
  'sos_id',
  'file_number',
  'state_taxpayer_id',
  'dot_pin',
  'dot_number',
  'mc_number',
  'street_address',
  'apt_suite',
  'city',
  'county',
  'state_id',
  'zip_code',
  'mailing_street_address',
  'mailing_apt_suite',
  'mailing_city',
  'mailing_county',
  'mailing_state_id',
  'mailing_zip_code',
  'email',
  'industry_id',
  'dba',
  'business_entity_id',
  'gvw_id',
  'drug_alcohol_consortium_name',
  'consortium_contact_name',
  'consortium_phone',
  'consortium_email',
  'other',
];
export const MAILING_ADDRESS_FIELDS = [
  'mailing_street_address',
  'mailing_apt_suite',
  'mailing_city',
  'mailing_county',
  'mailing_state_id',
  'mailing_zip_code',
];
// Fields for view mode
export const COMPANY_INFO_OBJ_KEYS = [
  {
    label: 'Legal Company Name',
    key: 'legal_name',
    type: 'value',
  },
  {
    label: 'Industry',
    key: 'industry',
    type: 'value',
  },
  {
    label: 'Other',
    key: 'other',
    type: 'value',
    dependField: 'industry',
    dependFn: val => val !== 'Other',
    fullWidth: true,
  },
  {
    label: 'Phone',
    key: 'phone_number',
    type: 'formatValue',
  },
  {
    label: 'Email',
    key: 'email',
    type: 'value',
  },
  {
    label: 'Type of Truck(s)',
    key: 'type_of_trucks',
    type: 'multiValue',
  },
  {
    label: 'GVW (lbs.)',
    key: 'gvw',
    type: 'value',
  },
];
export const STATE_FILLING_OBJ_KEYS = [
  {
    label: 'Form of Entity',
    key: 'business_entity',
    type: 'value',
  },
  {
    label: 'Articles of Inc. / Org.',
    key: 'documents.articles_of_org',
    type: 'file',
  },
  // {
  //   label: 'Secretary of State Filing',
  //   key: 'sos',
  //   type: 'value',
  // },
  // {
  //   label: 'State Taxpayer ID (*Required in Texas)',
  //   key: 'state_taxpayer_id',
  //   type: 'value',
  // },
  // {
  //   label: 'File Number',
  //   key: 'file_number',
  //   type: 'value',
  // },
  // {
  //   label: 'File Date',
  //   key: 'filing_date',
  //   type: 'date',
  // },
  {
    label: 'DBA - Doing Business As',
    key: 'dba',
    type: 'value',
  },
  {
    label: 'Assumed Name / DBA Certificate',
    key: 'documents.dba_form',
    type: 'file',
  },
];
export const DOCUMENTS_OBJ_KEYS = [
  {
    label: 'EIN - Employer Identification Number',
    key: 'ein',
    type: 'formatValue',
    format: FORMATS.EIN,
  },
  // {
  //   label: 'EIN Letter',
  //   key: 'documents.ein_letter',
  //   type: 'file',
  // },
  {
    label: 'MC Number',
    key: 'mc_number',
    type: 'value',
  },
  // {
  //   label: 'MC Certificate',
  //   key: 'documents.mc_certificate',
  //   type: 'file',
  // },
  {
    label: 'DOT PIN',
    key: 'dot_pin',
    type: 'value',
  },
  {
    label: 'DOT #',
    key: 'dot_number',
    type: 'value',
  },
  // {
  //   label: 'DOT PIN Letter',
  //   key: 'documents.dot_pin_letter',
  //   type: 'file',
  // },
];
export const ADDRESSES_OBJ_KEYS = [
  {
    label: 'Physical Address',
    key: 'street_address',
    type: 'value',
  },
  {
    label: 'Apt.',
    key: 'apt_suite',
    type: 'value',
    empty: true,
  },
  {
    label: 'Address',
    addressGroup: true,
    alignLeft: true,
    fields: [
      {
        label: 'City',
        key: 'city',
        type: 'value',
      },
      // {
      //   label: 'County',
      //   key: 'county',
      //   type: 'value',
      // },
      {
        label: 'State',
        key: 'state',
        type: 'value',
      },
      {
        label: 'Zip Code',
        key: 'zip_code',
        type: 'value',
      },
    ],
  },
  {
    label: 'Mailing address same as physical address',
    key: 'is_same_as_physical',
    type: 'checkbox',
    fullWidth: true,
    dependField: 'is_same_as_physical',
    dependFn: val => !val,
  },
  {
    label: 'Mailing Address',
    key: 'mailing_street_address',
    type: 'value',
    dependField: 'is_same_as_physical',
    dependFn: val => !!val,
  },
  {
    label: 'Apt.',
    key: 'mailing_apt_suite',
    type: 'value',
    empty: true,
    dependField: 'is_same_as_physical',
    dependFn: val => !!val,
  },
  {
    label: 'Address',
    addressGroup: true,
    alignLeft: true,
    dependField: 'is_same_as_physical',
    dependFn: val => !!val,
    fields: [
      {
        label: 'City',
        key: 'mailing_city',
        type: 'value',
      },
      // {
      //   label: 'County',
      //   key: 'mailing_county',
      //   type: 'value',
      // },
      {
        label: 'State',
        key: 'mailing_state',
        type: 'value',
      },
      {
        label: 'Zip Code',
        key: 'mailing_zip_code',
        type: 'value',
      },
    ],
  },
];
export const ENROLLMENT_OBJ_KEYS = [
  // {
  //   label: 'Drug & Alcohol Policy Handbook',
  //   key: 'documents.drug_alcohol_policy_handbook',
  //   type: 'file',
  //   isSingleFile: true,
  // },
  // {
  //   label: 'Drug & Alcohol Enrollment Certificate',
  //   key: 'documents.drug_alcohol_enrollment_certificate',
  //   type: 'file',
  //   isSingleFile: true,
  // },
  {
    label: 'Drug & Alcohol Consortium Name',
    key: 'drug_alcohol_consortium_name',
    type: 'value',
  },
  {
    label: 'Contact Name',
    key: 'consortium_contact_name',
    type: 'value',
  },
  {
    label: 'Phone',
    key: 'consortium_phone',
    type: 'formatValue',
  },
  {
    label: 'Email',
    key: 'consortium_email',
    type: 'value',
  },
];
export const ENROLLMENT_DRIVER_HIDE_FIELDS = [
  'consortium_contact_name',
  'consortium_phone',
  'consortium_email',
];
