import React from 'react';
import { MailOutlined, HomeOutlined, SettingOutlined } from '@ant-design/icons';

import Menu from 'components/Menu';
import Space from 'components/Space';

const menuData = [
  {
    to: '#',
    icon: <HomeOutlined />,
    label: 'Navigation One',
    props: {},
    selected: true,
  },
  {
    to: '#',
    icon: <SettingOutlined />,
    label: 'Navigation Two',
    props: {},
  },
  {
    subs: {
      label: 'Navigation Three',
      key: 'sub2',
      icon: <MailOutlined />,
      items: [
        {
          to: '#',
          label: 'Option',
          props: {},
          selected: true,
        },
        {
          to: '#',
          label: 'Option',
          props: {},
          disabled: true,
        },
        {
          to: '#',
          label: 'Option',
          props: {},
          subs: {
            label: 'Sub Menu',
            key: 'sub22',
            items: [
              {
                to: '#',
                label: 'Option',
                props: {},
              },
              {
                to: '#',
                label: 'Option',
                props: {},
              },
            ],
          },
        },
      ],
    },
  },
  {
    subs: {
      label: 'Navigation Four',
      key: 'sub3',
      icon: <MailOutlined />,
      items: [
        {
          to: '#',
          icon: null,
          label: 'Sub Menu Item 1',
          props: {},
        },
        {
          to: '#',
          icon: null,
          label: 'Sub Menu Item 2',
          props: {},
        },
        {
          to: '#',
          icon: null,
          label: 'Sub Menu Item 3',
          props: {},
        },
      ],
    },
  },
  {
    subs: {
      label: 'Navigation Five',
      key: 'sub4',
      icon: <MailOutlined />,
      items: [
        {
          title: 'Menu Group Title',
          subItems: [
            {
              to: '#',
              icon: null,
              label: 'Option',
              props: {},
            },
            {
              to: '#',
              icon: null,
              label: 'Option',
              props: {},
            },
          ],
        },
        {
          title: 'Menu Group Title',
          subItems: [
            {
              to: '#',
              label: 'Option',
              props: {},
              disabled: true,
            },
            {
              to: '#',
              label: 'Option',
              props: {},
            },
          ],
        },
      ],
    },
  },
];

const MenuComponent = ({ classes }) => {
  return (
    <Space size={32} direction={'horizontal'}>
      <Menu
        menus={menuData}
        style={{ width: 256 }}
        defaultOpenKeys={['sub2', 'sub4']}
      />
      <Menu
        menus={menuData}
        style={{ width: 256 }}
        theme={'dark'}
        defaultOpenKeys={['sub2']}
      />
    </Space>
  );
};

export default MenuComponent;
