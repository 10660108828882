import React from 'react';
import get from 'lodash/get';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { getMomentDateWithFormat } from 'utils/date';
import {
  Section,
  FormSection,
  ListInfo,
} from 'views/pages/ClientDetail/components/Elements';
import { DATE_TIME_FORMAT } from 'helper/const';
import CollapseSection from 'views/pages/ClientDetail/components/CollapseSection';
import { FILES_OBJ_KEYS } from '../../tabConstants/irp';

const FilesSection = ({
  form,
  data,
  years,
  handleYearSubmit,
  initialValues,
}) => {
  const tYears = years || [];

  return (
    <Section flexBox>
      {tYears.map(year => {
        const tName = `irp_year_wise_${year}`;
        const listInfoArr = FILES_OBJ_KEYS(year);
        const tFileKey = listInfoArr?.[0]?.key;
        const tExpirationDateKey = listInfoArr?.[1]?.key;
        const tFile = get(data, tFileKey);
        return (
          <CollapseSection
            onEdit={() => {
              form.setFieldsValue({
                delete_attachments: [],
                [tName]: get(initialValues, tName),
              });
            }}
            key={year}
            form={form}
            title={year}
            headerLink={
              tFile?.name
                ? {
                    url: tFile?.url || '#',
                    name: tFile.name,
                  }
                : undefined
            }
            headerExpiredDate={
              getMomentDateWithFormat(
                get(data, tExpirationDateKey),
                DATE_TIME_FORMAT.PROFILE_DATE,
                true
              ) || '-'
            }
            onSubmit={(values, cb) => {
              if (handleYearSubmit) handleYearSubmit(values, cb);
            }}
            renderContent={isEdit => {
              return (
                <>
                  {isEdit ? (
                    <FormSection>
                      <InputItem
                        name={'delete_attachments'}
                        label=""
                        form={form}
                        hide
                      />
                      <InputItem
                        name={tName}
                        label={`IRP File - ${year}`}
                        form={form}
                        type="file"
                      />
                      <InputItem
                        name={`irp_expiration_date_${year}`}
                        label="Expiration Date"
                        form={form}
                        type="date"
                        placeholder="01/01/2000"
                      />
                    </FormSection>
                  ) : (
                    <ListInfo
                      objKeys={FILES_OBJ_KEYS(year)}
                      data={data}
                      noPadding
                    />
                  )}
                </>
              );
            }}
          />
        );
      })}
    </Section>
  );
};

export default FilesSection;
