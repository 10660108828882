import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  reminderTypes,
} from '../types';
import get from 'lodash/get';
import { initialReminderState } from '../states/reminder';

export default (state = initialReminderState, action) => {
  const resData = get(action, 'response.data') || {};
  const { next_page_url, current_page, data = [], total = 0 } = resData;
  const isMore = get(action, 'payload.isMore');
  const isBEMore = !!next_page_url || current_page > 1;
  const ids = get(action, 'payload.ids') || [];
  switch (action.type) {
    // SYNC_UPDATE_REMINDER_DETAIL_STATUS
    case reminderTypes.SYNC_UPDATE_REMINDER_DETAIL_STATUS: {
      return {
        ...state,
        reminderDetail: {
          ...state.reminderDetail,
          data: {
            ...state.reminderDetail.data,
            status: action.payload?.status,
          },
        },
      };
    }
    // SYNC_UPDATE_COMPANY_REMINDER_CHECKLISTS
    case reminderTypes.SYNC_UPDATE_COMPANY_REMINDER_CHECKLISTS: {
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          data: {
            ...(state[action.payload.key]?.data || {}),
            data: (state[action.payload.key]?.data?.data || []).map(d => {
              if (d.id === action.payload.id) {
                d.checklists = action.payload.checklists;
              }
              return d;
            }),
          },
        },
      };
    }
    // SYNC_UPDATE_COMPANY_REMINDERS
    case reminderTypes.SYNC_UPDATE_COMPANY_REMINDERS: {
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          data: [...(action.payload?.data || [])],
        },
      };
    }
    // GET_ACTIVE_REMINDERS
    case asyncActionTypeRequest(reminderTypes.GET_ACTIVE_REMINDERS): {
      return {
        ...state,
        activeReminders: {
          ...state.activeReminders,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(reminderTypes.GET_ACTIVE_REMINDERS): {
      return {
        ...state,
        activeReminders: {
          data: isMore ? [...state.activeReminders.data, ...data] : data,
          totalNo: total || 0,
          loading: false,
          isMore: isBEMore,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(reminderTypes.GET_ACTIVE_REMINDERS): {
      return {
        ...state,
        activeReminders: {
          loading: false,
          error: action.payload,
          data: [],
          totalNo: 0,
          isMore: false,
        },
      };
    }
    // GET_INACTIVE_REMINDERS
    case asyncActionTypeRequest(reminderTypes.GET_INACTIVE_REMINDERS): {
      return {
        ...state,
        inactiveReminders: {
          ...state.inactiveReminders,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(reminderTypes.GET_INACTIVE_REMINDERS): {
      return {
        ...state,
        inactiveReminders: {
          data: isMore ? [...state.inactiveReminders.data, ...data] : data,
          totalNo: total || 0,
          loading: false,
          isMore: isBEMore,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(reminderTypes.GET_INACTIVE_REMINDERS): {
      return {
        ...state,
        inactiveReminders: {
          loading: false,
          error: action.payload,
          data: [],
          totalNo: 0,
          isMore: false,
        },
      };
    }
    // GET_REMINDER_DETAIL
    case asyncActionTypeRequest(reminderTypes.GET_REMINDER_DETAIL): {
      return {
        ...state,
        reminderDetail: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(reminderTypes.GET_REMINDER_DETAIL): {
      return {
        ...state,
        reminderDetail: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(reminderTypes.GET_REMINDER_DETAIL): {
      return {
        ...state,
        reminderDetail: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // GET_ACTIVE_COMPANY_REMINDERS
    case asyncActionTypeRequest(reminderTypes.GET_ACTIVE_COMPANY_REMINDERS): {
      return {
        ...state,
        activeCompanyReminders: {
          ...state.activeCompanyReminders,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(reminderTypes.GET_ACTIVE_COMPANY_REMINDERS): {
      return {
        ...state,
        activeCompanyReminders: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(reminderTypes.GET_ACTIVE_COMPANY_REMINDERS): {
      return {
        ...state,
        activeCompanyReminders: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // GET_DISMISSED_COMPANY_REMINDERS
    case asyncActionTypeRequest(
      reminderTypes.GET_DISMISSED_COMPANY_REMINDERS
    ): {
      return {
        ...state,
        dismissedCompanyReminders: {
          ...state.dismissedCompanyReminders,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(
      reminderTypes.GET_DISMISSED_COMPANY_REMINDERS
    ): {
      return {
        ...state,
        dismissedCompanyReminders: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(
      reminderTypes.GET_DISMISSED_COMPANY_REMINDERS
    ): {
      return {
        ...state,
        dismissedCompanyReminders: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // GET_COMPLETED_COMPANY_REMINDERS
    case asyncActionTypeRequest(
      reminderTypes.GET_COMPLETED_COMPANY_REMINDERS
    ): {
      return {
        ...state,
        completedCompanyReminders: {
          ...state.completedCompanyReminders,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(
      reminderTypes.GET_COMPLETED_COMPANY_REMINDERS
    ): {
      return {
        ...state,
        completedCompanyReminders: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(
      reminderTypes.GET_COMPLETED_COMPANY_REMINDERS
    ): {
      return {
        ...state,
        completedCompanyReminders: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // CREATE_REMINDER
    case asyncActionTypeRequest(reminderTypes.CREATE_REMINDER): {
      return {
        ...state,
        createReminder: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(reminderTypes.CREATE_REMINDER): {
      return {
        ...state,
        createReminder: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(reminderTypes.CREATE_REMINDER): {
      return {
        ...state,
        createReminder: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // UPDATE_REMINDER
    case asyncActionTypeRequest(reminderTypes.UPDATE_REMINDER): {
      return {
        ...state,
        updateReminder: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(reminderTypes.UPDATE_REMINDER): {
      return {
        ...state,
        updateReminder: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
        reminderDetail: {
          ...state.reminderDetail,
          data: {
            ...state.reminderDetail.data,
            ...(get(action, 'response.data') || {}),
          },
        },
      };
    }
    case asyncActionTypeFailure(reminderTypes.UPDATE_REMINDER): {
      return {
        ...state,
        updateReminder: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // DELETE_REMINDER
    case asyncActionTypeRequest(reminderTypes.DELETE_REMINDER): {
      return {
        ...state,
        deleteReminder: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(reminderTypes.DELETE_REMINDER): {
      return {
        ...state,
        deleteReminder: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(reminderTypes.DELETE_REMINDER): {
      return {
        ...state,
        deleteReminder: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // CHANGE_REMINDER_STATUS
    case asyncActionTypeRequest(reminderTypes.CHANGE_REMINDER_STATUS): {
      const newLoading = {};
      ids.map(id => {
        newLoading[id] = true;
        return true;
      });
      return {
        ...state,
        changeReminderStatus: {
          data: {},
          error: '',
          loading: newLoading,
        },
      };
    }
    case asyncActionTypeSuccess(reminderTypes.CHANGE_REMINDER_STATUS): {
      const newLoading = {};
      ids.map(id => {
        newLoading[id] = false;
        return true;
      });
      return {
        ...state,
        changeReminderStatus: {
          data: get(action, 'response.data') || {},
          loading: newLoading,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(reminderTypes.CHANGE_REMINDER_STATUS): {
      const newLoading = {};
      ids.map(id => {
        newLoading[id] = false;
        return true;
      });
      return {
        ...state,
        changeReminderStatus: {
          loading: newLoading,
          error: action.payload,
          data: {},
        },
      };
    }
    // SUBSCRIBE_REMINDER_EMAIL
    case asyncActionTypeRequest(reminderTypes.SUBSCRIBE_REMINDER_EMAIL): {
      return {
        ...state,
        subscribeReminderEmail: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(reminderTypes.SUBSCRIBE_REMINDER_EMAIL): {
      return {
        ...state,
        subscribeReminderEmail: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(reminderTypes.SUBSCRIBE_REMINDER_EMAIL): {
      return {
        ...state,
        subscribeReminderEmail: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    default:
      return state;
  }
};
