import React from 'react';
import cx from 'classnames';
import { Modal as AntdModal } from 'antd';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { FormItem } from 'components/Form';
import { formRequired } from 'utils/form';

import classes from './Modal.module.scss';

const { useModal } = AntdModal;

const ModalFooter = ({
  cancelProps = {},
  okProps = {},
  form,
  validateObj,
  className,
}) => {
  return (
    <div className={cx(classes.customFooter, className)}>
      <Button htmlType="button" onClick={cancelProps.onClick} type="ghost">
        {cancelProps.text || 'Cancel'}
      </Button>
      <FormItem shouldUpdate>
        {() => {
          const values = form.getFieldsValue();
          return (
            <Button
              htmlType="submit"
              disabled={formRequired(validateObj, values)}
            >
              {okProps.text || 'Ok'}
            </Button>
          );
        }}
      </FormItem>
    </div>
  );
};

const Modal = props => {
  const {
    isOpen,
    onSubmit,
    onClose,
    title,
    children,
    className,
    cancelText,
    okText,
    isSubmitLoading,
    hideCancel,
    hideSubmit,
    disabledSubmit,
    footer,
    hideFooter,
    okBtnProps = {},
    cancelBtnProps = {},
    blueColor,
    ...other
  } = props;
  return (
    <AntdModal
      centered
      {...other}
      title={title}
      visible={isOpen}
      onOk={onSubmit}
      onCancel={onClose}
      wrapClassName={cx(
        classes.wrapper,
        {
          [classes.hideFooter]: hideFooter,
          [classes.blueColor]: blueColor,
        },
        className
      )}
      footer={
        !hideFooter ? (
          <>
            {footer || (
              <div className={classes.footer}>
                {!hideCancel && (
                  <Button
                    type="primary"
                    ghost
                    onClick={onClose}
                    {...cancelBtnProps}
                  >
                    {cancelText}
                  </Button>
                )}
                {!hideSubmit && (
                  <Button
                    type="primary"
                    onClick={onSubmit}
                    loading={isSubmitLoading}
                    disabled={disabledSubmit}
                    {...okBtnProps}
                  >
                    {okText}
                  </Button>
                )}
              </div>
            )}
          </>
        ) : (
          []
        )
      }
    >
      {children}
    </AntdModal>
  );
};

Modal.defaultProps = {
  isOpen: false,
  className: '',
  title: 'Modal title',
  onSubmit: () => {},
  onClose: () => {},
  cancelText: 'Cancel',
  okText: 'OK',
  isSubmitLoading: false,
  hideCancel: false,
  hideFooter: false,
  blueColor: false,
  footer: null,
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.any,
  title: PropTypes.any,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  cancelText: PropTypes.string,
  okText: PropTypes.string,
  isSubmitLoading: PropTypes.bool,
  hideCancel: PropTypes.bool,
  hideFooter: PropTypes.bool,
  blueColor: PropTypes.bool,
  footer: PropTypes.any,
};

export { useModal, ModalFooter };

export default Modal;
