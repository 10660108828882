const initialNotificationState = {
  // GET
  unreadNotifications: {
    loading: false,
    data: [],
    error: '',
  },
  readNotifications: {
    loading: false,
    data: [],
    error: '',
  },
  notificationUnreadCount: {
    loading: false,
    data: 0,
    error: '',
  },
  readNotification: {
    loading: false,
    data: {},
    error: '',
  },
  deleteNotification: {
    loading: false,
    data: {},
    error: '',
  },
};

export { initialNotificationState };
