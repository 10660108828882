import Echo from 'laravel-echo';
import socketIoClient from 'socket.io-client';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import {
  reloadMessageOrChatList,
  reloadConversationName,
  leaveConversation,
  updateMembers,
  joinConversation,
} from 'utils/socket';

class Socket {
  echo;

  start(meData) {
    // stop earlier socket connection if there is any before starting new socket connection
    this.stop();
    const accessToken = get(meData, 'data.auth_token', '');
    const userId = get(meData, 'data.id');
    if (!accessToken) {
      return false;
    }
    const authToken = `Bearer ${accessToken}`;
    const echoHost =
      process.env.REACT_APP_SOCKET_BASE_URL ||
      (process.env.REACT_APP_API_BASE_URL || '').replace('/api', '');

    this.echo = new Echo({
      auth: {
        headers: {
          Authorization: authToken,
        },
      },
      broadcaster: 'socket.io',
      host: echoHost,
      client: socketIoClient,
    });
    this.listenMessages(userId);
    this.listenSystemMessages(userId);
  }

  listenMessages(userId) {
    this.echo
      .private(`messages.${userId}`)
      .listen('MessageSent', async data => {})
      .listen('MessageReceived', async data => {
        const messageData = isArray(data?.data)
          ? data?.data[0] || {}
          : data?.data || {};
        messageData.conversation_id = parseInt(messageData.conversation_id, 10);
        const { conversation_id, type } = messageData;
        reloadMessageOrChatList(conversation_id || '', type || '', messageData);
      })
      .listen('MessageSeen', async data => {});
  }

  listenSystemMessages(userId) {
    this.echo
      .private(`system-messages.${userId}`)
      .listen('ConversationUpdated', async data => {
        const { conversationId } = data || {};
        reloadConversationName(conversationId);
      })
      .listen('MemberRemoved', async data => {
        const conversationId = parseInt(get(data, 'conversationId') || 0, 10);
        updateMembers(conversationId);
      })
      .listen('MemberAdded', async data => {
        const conversationId = parseInt(get(data, 'conversationId') || 0, 10);
        updateMembers(conversationId);
      })
      .listen('RemovedConversation', async data => {
        const conversationId = parseInt(get(data, 'conversationId') || 0, 10);
        leaveConversation(conversationId);
      })
      .listen('JoinedConversation', async data => {
        const { type } = data || {};
        joinConversation(type);
      });
  }

  stop() {
    // this method used to unregister all subscription on socket
    if (this.echo) {
      this.echo.disconnect();
    }
  }
}

export default new Socket();
