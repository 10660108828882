import get from 'lodash/get';
import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  paymentSourceTypes,
} from '../types';
// eslint-disable-next-line import/named
import { initialPaymentSourceState } from '../states/paymentSource';

export default (state = initialPaymentSourceState, action) => {
  const { accountId, cardId, cardData } = action?.payload || {};
  switch (action.type) {
    // SYNC_UPDATE_FUEL_CARD
    case paymentSourceTypes.SYNC_UPDATE_FUEL_CARD: {
      return {
        ...state,
        fuelCards: {
          ...state.fuelCards,
          data: state.fuelCards.data.map(card => {
            if (card.id === cardId) {
              return {
                ...card,
                ...(cardData || {}),
              };
            }
            return card;
          }),
        },
      };
    }
    // GET_CHECKING_ACCOUNTS
    case asyncActionTypeRequest(paymentSourceTypes.GET_CHECKING_ACCOUNTS): {
      return {
        ...state,
        accounts: {
          ...state.accounts,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(paymentSourceTypes.GET_CHECKING_ACCOUNTS): {
      return {
        ...state,
        accounts: {
          loading: false,
          data: get(action, 'response.data') || [],
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(paymentSourceTypes.GET_CHECKING_ACCOUNTS): {
      return {
        ...state,
        accounts: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    }
    // GET_FUEL_CARDS
    case asyncActionTypeRequest(paymentSourceTypes.GET_FUEL_CARDS): {
      return {
        ...state,
        fuelCards: {
          ...state.fuelCards,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(paymentSourceTypes.GET_FUEL_CARDS): {
      return {
        ...state,
        fuelCards: {
          loading: false,
          data: get(action, 'response.data') || [],
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(paymentSourceTypes.GET_FUEL_CARDS): {
      return {
        ...state,
        fuelCards: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    }
    // GET_CHECKING_ACCOUNT
    case asyncActionTypeRequest(paymentSourceTypes.GET_CHECKING_ACCOUNT): {
      return {
        ...state,
        account: {
          ...state.account,
          loading: {
            ...state.account?.loading,
            [accountId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(paymentSourceTypes.GET_CHECKING_ACCOUNT): {
      return {
        ...state,
        account: {
          loading: {
            ...state.account?.loading,
            [accountId]: false,
          },
          data: {
            ...state.account?.data,
            [accountId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.account?.error,
            [accountId]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(paymentSourceTypes.GET_CHECKING_ACCOUNT): {
      return {
        ...state,
        account: {
          loading: {
            ...state.account?.loading,
            [accountId]: false,
          },
          data: {
            ...state.account?.data,
            [accountId]: {},
          },
          error: {
            ...state.account?.error,
            [accountId]: action.error,
          },
        },
      };
    }
    // GET_DRIVER_FUEL_CARD
    case asyncActionTypeRequest(paymentSourceTypes.GET_FUEL_CARD): {
      return {
        ...state,
        fuelCard: {
          ...state.fuelCard,
          loading: {
            ...state.fuelCard?.loading,
            [cardId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(paymentSourceTypes.GET_FUEL_CARD): {
      return {
        ...state,
        fuelCard: {
          loading: {
            ...state.fuelCard?.loading,
            [cardId]: false,
          },
          data: {
            ...state.fuelCard?.data,
            [cardId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.fuelCard?.error,
            [cardId]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(paymentSourceTypes.GET_FUEL_CARD): {
      return {
        ...state,
        fuelCard: {
          loading: {
            ...state.fuelCard?.loading,
            [cardId]: false,
          },
          data: {
            ...state.fuelCard?.data,
            [cardId]: {},
          },
          error: {
            ...state.fuelCard?.error,
            [cardId]: action.error,
          },
        },
      };
    }
    // CREATE_CHECKING_ACCOUNT
    case asyncActionTypeRequest(paymentSourceTypes.CREATE_CHECKING_ACCOUNT): {
      return {
        ...state,
        createAccount: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(paymentSourceTypes.CREATE_CHECKING_ACCOUNT): {
      return {
        ...state,
        createAccount: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(paymentSourceTypes.CREATE_CHECKING_ACCOUNT): {
      return {
        ...state,
        createAccount: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // UPDATE_CHECKING_ACCOUNT
    case asyncActionTypeRequest(paymentSourceTypes.UPDATE_CHECKING_ACCOUNT): {
      return {
        ...state,
        updateAccount: {
          ...state.updateAccount,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(paymentSourceTypes.UPDATE_CHECKING_ACCOUNT): {
      return {
        ...state,
        updateAccount: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(paymentSourceTypes.UPDATE_CHECKING_ACCOUNT): {
      return {
        ...state,
        updateAccount: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // CREATE_FUEL_CARD
    case asyncActionTypeRequest(paymentSourceTypes.CREATE_FUEL_CARD): {
      return {
        ...state,
        createFuelCard: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(paymentSourceTypes.CREATE_FUEL_CARD): {
      return {
        ...state,
        createFuelCard: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(paymentSourceTypes.CREATE_FUEL_CARD): {
      return {
        ...state,
        createFuelCard: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // UPDATE_FUEL_CARD
    case asyncActionTypeRequest(paymentSourceTypes.UPDATE_FUEL_CARD): {
      return {
        ...state,
        updateFuelCard: {
          ...state.updateFuelCard,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(paymentSourceTypes.UPDATE_FUEL_CARD): {
      return {
        ...state,
        updateFuelCard: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(paymentSourceTypes.UPDATE_FUEL_CARD): {
      return {
        ...state,
        updateFuelCard: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    default:
      return state;
  }
};
