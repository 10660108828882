export const FILE_FIELDS = [
  'documents.fsa',
  'documents.transportation_rider',
  'documents.8821_form',
  'documents.construction_rider',
  'documents.ucc_filing',
  'documents.non_recourse_rider',
];
// Fields for view mode
export const PROFILE_OBJ_KEYS = [
  {
    label: 'FSA',
    key: 'documents.fsa',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Transportation Rider',
    key: 'documents.transportation_rider',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: '8821 Form',
    key: 'documents.8821_form',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Construction Rider',
    key: 'documents.construction_rider',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'UCC Filing',
    key: 'documents.ucc_filing',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Non Recourse Rider',
    key: 'documents.non_recourse_rider',
    type: 'file',
    isSingleFile: true,
  },
];
