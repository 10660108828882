import React from 'react';

import Table from 'components/Table';
import Space from 'components/Space';

const TableComponent = () => {
  const expandable = {
    expandedRowRender: record => <p>{record.description}</p>,
  };
  const basicTable = size => {
    return (
      <Space direction={'vertical'}>
        <Space direction={'horizontal'}>
          <Table size={size} />
          <Table rowSelection={true} size={size} />
        </Space>
        <Table rowSelection={true} expandable={expandable} size={size} />
      </Space>
    );
  };
  const borderTable = size => {
    return (
      <Space direction={'vertical'}>
        <Space direction={'horizontal'}>
          <Table bordered size={size} />
          <Table bordered rowSelection={true} size={size} />
        </Space>
        <Table
          bordered
          rowSelection={true}
          expandable={expandable}
          size={size}
        />
      </Space>
    );
  };

  return (
    <Space direction={'vertical'}>
      <h2>Basic Table</h2>
      <h3>Medium</h3>
      <Space direction={'horizontal'}>{basicTable()}</Space>
      <h3>Small</h3>
      <Space direction={'horizontal'}>{basicTable('middle')}</Space>
      <h3>Mini</h3>
      <Space direction={'horizontal'}>{basicTable('small')}</Space>
      <h2>Border Table</h2>
      <h3>Medium</h3>
      <Space direction={'horizontal'}>{borderTable()}</Space>
      <h3>Small</h3>
      <Space direction={'horizontal'}>{borderTable('middle')}</Space>
      <h3>Mini</h3>
      <Space direction={'horizontal'}>{borderTable('small')}</Space>
    </Space>
  );
};

export default TableComponent;
