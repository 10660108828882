import {
  useReducerData,
  useReducerLoading,
  useReducerAttribute,
} from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// CURRENT_USER
export const useSelectCurrentUserLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.AUTH,
    REDUCER_ATTRIBUTES.AUTH.CURRENT_USER
  );
};
export const useSelectCurrentUser = () => {
  return useReducerData(
    REDUCER_NAMES.AUTH,
    REDUCER_ATTRIBUTES.AUTH.CURRENT_USER,
    {}
  );
};
// LOGIN
export const useSelectLoginLoading = () => {
  return useReducerLoading(REDUCER_NAMES.AUTH, REDUCER_ATTRIBUTES.AUTH.LOGIN);
};
export const useSelectAccessToken = () => {
  return useReducerAttribute(
    REDUCER_NAMES.AUTH,
    `${REDUCER_ATTRIBUTES.AUTH.LOGIN}.access_token`,
    null
  );
};
// CREATE_USER
export const useSelectCreateUserLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.AUTH,
    REDUCER_ATTRIBUTES.AUTH.CREATE_USER
  );
};
// FORGOT_PASSWORD
export const useSelectForgotPasswordLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.AUTH,
    REDUCER_ATTRIBUTES.AUTH.FORGOT_PASSWORD
  );
};
// VALIDATE_TOKEN
export const useSelectValidateTokenLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.AUTH,
    REDUCER_ATTRIBUTES.AUTH.VALIDATE_TOKEN
  );
};
// RESET_PASSWORD
export const useSelectResetPasswordLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.AUTH,
    REDUCER_ATTRIBUTES.AUTH.RESET_PASSWORD
  );
};
// CONFIRM_REGISTER
export const useSelectConfirmRegisterLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.AUTH,
    REDUCER_ATTRIBUTES.AUTH.CONFIRM_REGISTER
  );
};
// UPDATE_PROFILE
export const useSelectUpdateProfileLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.AUTH,
    REDUCER_ATTRIBUTES.AUTH.UPDATE_PROFILE
  );
};
// CHANGE_PASSWORD
export const useSelectChangePasswordLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.AUTH,
    REDUCER_ATTRIBUTES.AUTH.CHANGE_PASSWORD
  );
};
// VALIDATE_INVITE_TOKEN
export const useSelectValidateInviteTokenLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.AUTH,
    REDUCER_ATTRIBUTES.AUTH.VALIDATE_INVITE_TOKEN
  );
};
export const useSelectAccountDataFromInviteToken = () => {
  return useReducerData(
    REDUCER_NAMES.AUTH,
    REDUCER_ATTRIBUTES.AUTH.VALIDATE_INVITE_TOKEN,
    {}
  );
};
// VALIDATE_CLIENT_TOKEN
export const useSelectValidateClientTokenLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.AUTH,
    REDUCER_ATTRIBUTES.AUTH.VALIDATE_CLIENT_TOKEN
  );
};
// CREATE_ACCOUNT
export const useSelectCreateAccountLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.AUTH,
    REDUCER_ATTRIBUTES.AUTH.CREATE_ACCOUNT
  );
};
// CREATE_CLIENT_ACCOUNT
export const useSelectCreateClientAccountLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.AUTH,
    REDUCER_ATTRIBUTES.AUTH.CREATE_CLIENT_ACCOUNT
  );
};
// VALIDATE_PASSWORD
export const useSelectValidatePasswordLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.AUTH,
    REDUCER_ATTRIBUTES.AUTH.VALIDATE_PASSWORD
  );
};
