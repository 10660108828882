import pick from 'lodash/pick';
import get from 'lodash/get';
import { getMomentDate, getBEDate } from 'utils/date';
import { parseStringToNumber } from 'utils/number';
import { getOtherDataValue } from 'utils/customField';
import {
  standardRequiredFieldObj,
  emailFieldObj,
  phoneFieldObj,
  getFileFieldInitialValues,
} from 'utils/form';
import {
  NORMAL_FIELDS,
  NULL_FIELDS,
  DATE_FIELDS,
  BOOLEAN_FIELDS,
  FILE_FIELDS,
  BODY_REQUEST_NORMAL_FIELDS,
  MAILING_ADDRESS_FIELDS,
  ARR_FIELDS,
} from './constants';

const getFileField = field => {
  if (!field) return '';
  return field.replace('documents.', '');
};

export const getInitialValues = (data, isInternalUser) => {
  const initialValues = {
    delete_attachments: [],
    delete_custom_fields: [],
    custom_fields: getOtherDataValue(data.custom_fields || []),
  };
  [...NORMAL_FIELDS, ...NULL_FIELDS].forEach(field => {
    if ((field === 'ein' || field === 'dot_pin') && isInternalUser) {
      initialValues[field] = '******';
    } else {
      initialValues[field] =
        data[field] || (NULL_FIELDS.includes(field) ? null : '');
    }
  });
  DATE_FIELDS.forEach(field => {
    initialValues[field] = data[field]
      ? getMomentDate(data[field], false, true)
      : '';
  });
  BOOLEAN_FIELDS.forEach(field => {
    initialValues[field] = data[field] ? [field] : [];
  });
  ARR_FIELDS.forEach(field => {
    initialValues[field] = data[field] ? data[field].map(v => v.id) : [];
  });

  return {
    ...initialValues,
    ...getFileFieldInitialValues(FILE_FIELDS, data, 'documents'),
  };
};
export const validateObj = {
  legal_name: [standardRequiredFieldObj],
  email: [emailFieldObj],
  phone_number: [phoneFieldObj],
  consortium_email: [emailFieldObj],
  consortium_phone: [phoneFieldObj],
};

export const getCompanyInfoBR = (values = {}) => {
  const {
    phone_number,
    filing_date,
    ein,
    delete_attachments,
    delete_custom_fields,
    consortium_phone,
    custom_fields,
  } = values;
  const isSameAddress =
    values.is_same_as_physical && values.is_same_as_physical.length > 0 ? 1 : 0;
  const bodyRequest = {
    ...pick(values, BODY_REQUEST_NORMAL_FIELDS),
    is_same_as_physical: isSameAddress,
    filing_date: filing_date ? getBEDate(filing_date) : '',
    ein: parseStringToNumber(ein),
    phone_number: parseStringToNumber(phone_number),
    consortium_phone: parseStringToNumber(consortium_phone),
  };
  if (!!ein && ein.includes('*')) {
    delete bodyRequest.ein;
  }
  if (!!values.dot_pin && values.dot_pin.includes('*')) {
    delete bodyRequest.dot_pin;
  }
  if (isSameAddress) {
    MAILING_ADDRESS_FIELDS.map(field => {
      bodyRequest[field] = values[field.replace('mailing_', '')];
      return true;
    });
  }
  const fData = new FormData();
  Object.keys(bodyRequest).map(k => {
    fData.append(k, bodyRequest[k] || '');
    return true;
  });
  FILE_FIELDS.map(originKey => {
    const key = getFileField(originKey);
    if (values[key] && values[key].length > 0) {
      values[key].map(file => {
        if (file.originFileObj && !file.id) {
          fData.append(`${key}[]`, file.originFileObj);
        }
        return true;
      });
    } else {
      fData.append(key, '');
    }
    return true;
  });
  ARR_FIELDS.map(field => {
    const vals = values[field] || [];
    vals.map(val => {
      fData.append(`${field}[]`, val);
      return true;
    });
    if (vals.length === 0) {
      fData.append(`${field}[]`, '');
    }
    return true;
  });
  if (delete_attachments && delete_attachments.length > 0) {
    delete_attachments.map(id => {
      fData.append('delete_attachments[]', id);
      return true;
    });
  }
  if (custom_fields && custom_fields.length > 0) {
    custom_fields.map((field, index) => {
      fData.append(`custom_fields[${index}][field_name]`, field.field_name);
      if (field.originId) {
        fData.append(`custom_fields[${index}][id]`, field.originId);
      }
      const firstDocument = get(field, 'document[0]') || null;
      const existingFileName = get(field, 'originData.document_name', '');
      if (firstDocument && !firstDocument.url) {
        fData.append(
          `custom_fields[${index}][document]`,
          firstDocument.originFileObj
        );
      } else if (!firstDocument && !!existingFileName) {
        fData.append(`custom_fields[${index}][document_delete]`, 1);
      }
      return true;
    });
  } else {
    fData.append('custom_fields', '');
  }
  if (delete_custom_fields && delete_custom_fields.length > 0) {
    delete_custom_fields.map(id => {
      fData.append('delete_custom_fields[]', id);
      return true;
    });
  }
  return fData;
};
