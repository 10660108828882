import pick from 'lodash/pick';
import get from 'lodash/get';
import { parseStringToNumber } from 'utils/number';
import { getMomentDate, getBEDate, getLast5Years } from 'utils/date';
import { getFileFieldInitialValues } from 'utils/form';
import {
  FILE_FIELDS,
  DATE_FIELDS,
  NORMAL_FIELDS,
  NULL_FIELDS,
  BODY_REQUEST_NORMAL_FIELDS,
  NUMBER_FIELDS,
  PORTAL_FIELDS,
} from '../tabConstants/irp';

const getFileField = field => {
  if (!field) return '';
  return field.replace('documents.', '');
};
const getFileYearInitialValues = (keys = [], data) => {
  const initialValues = {};
  const listYears = getLast5Years();
  listYears.forEach(year => {
    keys.forEach(key => {
      const fileObj = get(
        data,
        `year_wise.${year}.documents.${`${key}_year_wise`}`
      )?.[0];
      const dateVal = get(data, `year_wise.${year}.expiration_date`) || '';
      initialValues[`${key}_year_wise_${year}`] = fileObj
        ? fileObj.id
          ? [
              {
                ...fileObj,
                uid: fileObj.id,
              },
            ]
          : fileObj.map(f => ({
              ...f,
              uid: f.id,
            }))
        : null;
      initialValues[`${key}_expiration_date_${year}`] = dateVal
        ? getMomentDate(dateVal, false, true)
        : '';
    });
  });
  return initialValues;
};

export const getInitialValues = data => {
  const initialValues = {
    delete_attachments: [],
  };
  [...NORMAL_FIELDS, ...NULL_FIELDS].map(field => {
    const eValue = data[field];
    initialValues[field] = eValue || (NULL_FIELDS.includes(field) ? null : '');
    return true;
  });
  DATE_FIELDS.map(field => {
    initialValues[field] = data[field]
      ? getMomentDate(data[field], false, true)
      : '';
    return true;
  });
  PORTAL_FIELDS.map(field => {
    initialValues[field] =
      get(data, `online_portals[0].${field}`) ||
      (NULL_FIELDS.includes(field) ? null : '');
    const portalId = get(data, `online_portals[0].id`) || '';
    if (portalId) {
      initialValues.portal_id = portalId;
    }
    return true;
  });

  return {
    ...initialValues,
    ...getFileFieldInitialValues(FILE_FIELDS, data, 'documents'),
    ...getFileYearInitialValues(['irp'], data),
  };
};

export const getIRPBR = (values = {}) => {
  const { delete_attachments } = values;
  const bodyRequest = {
    ...pick(values, BODY_REQUEST_NORMAL_FIELDS),
  };
  DATE_FIELDS.map(field => {
    bodyRequest[`${field}[general]`] = values[field]
      ? getBEDate(values[field])
      : '';
    return true;
  });
  NUMBER_FIELDS.map(field => {
    bodyRequest[field] = parseStringToNumber(values[field]);
    return true;
  });
  const fData = new FormData();
  Object.keys(bodyRequest).map(k => {
    fData.append(
      k,
      bodyRequest[k] || bodyRequest[k] === 0 ? bodyRequest[k] : ''
    );
    return true;
  });
  FILE_FIELDS.map(originKey => {
    const key = getFileField(originKey);
    if (values[key] && values[key].length > 0) {
      values[key].map(file => {
        if (file.originFileObj && !file.id) {
          fData.append(`${key}[]`, file.originFileObj);
        }
        return true;
      });
    } else {
      fData.append(key, '');
    }
    return true;
  });
  PORTAL_FIELDS.map(field => {
    fData.append(`online_portal[0][${field}]`, values[field] || '');
    return true;
  });
  if (values.portal_id) {
    fData.append(`online_portal[0][id]`, values.portal_id);
  }
  if (delete_attachments && delete_attachments.length > 0) {
    delete_attachments.map(id => {
      fData.append('delete_documents[]', id);
      return true;
    });
  }
  return fData;
};
