import React from 'react';
import cn from 'classnames';
import find from 'lodash/find';
import { useOptions, useIsInternalUser } from 'helper/hooks';
import ClassTooltipContent from 'views/pages/ClientDetail/CompanyOfficial/components/ClassTooltipContent';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  InputSearchButton,
  ShouldUpdateWrapper,
} from 'views/pages/ClientDetail/components/Elements';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import {
  PROFILE_OBJ_KEYS,
  AXLES_OPTIONS,
  COMBINED_AXLES_OPTIONS,
} from '../../constants';
import classes from './Section.module.scss';

const Profile = ({
  className,
  isEdit,
  form,
  data,
  showManualVin,
  onSearchVIN,
  vinDecoderLoading,
  vinRef,
  searchedVinSuccess,
  isEId,
  searchFields,
}) => {
  const isValidVin = !!data.valid_vin;
  const { stateOptions, scTruckTypeOptions } = useOptions();
  const { isInternalUser } = useIsInternalUser();

  return (
    <div className={cn(classes.profileSection, className)}>
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            {/* Use it to save delete_attachments for attachment removing */}
            <InputItem name={'delete_attachments'} label="" form={form} hide />
            <InputItem name={'valid_vin'} label="" form={form} hide />
            <div className={classes.vinWrapper}>
              <InputItem
                name={'vin'}
                label="VIN - Vehicle Identification Number"
                form={form}
                required
                fullWidth
                inputRef={vinRef}
                disabled={isEId && !isInternalUser}
              />
              {!isEId && (
                <InputSearchButton
                  onClick={onSearchVIN}
                  loading={vinDecoderLoading}
                  form={form}
                />
              )}
            </div>
            {showManualVin && (
              <>
                <InputItem
                  name={'truck_unit'}
                  label="Truck Unit #"
                  form={form}
                  required
                  addressGroupField
                />
                <InputItem
                  name={'truck_type_id'}
                  label="Type of Truck"
                  form={form}
                  type="select"
                  options={scTruckTypeOptions}
                  required
                  addressGroupField
                />
                <ShouldUpdateWrapper>
                  {() => {
                    const values = form.getFieldsValue();
                    const foundOption =
                      find(scTruckTypeOptions, {
                        value: values.truck_type_id,
                      }) || {};
                    if (foundOption.label !== 'Other') {
                      return <></>;
                    }
                    return (
                      <>
                        <InputItem
                          name={'truck_type_other'}
                          label="Other"
                          form={form}
                        />
                      </>
                    );
                  }}
                </ShouldUpdateWrapper>
                <InputItem
                  name={'color'}
                  label="Color"
                  form={form}
                  addressGroupField
                />
                <InputItem
                  name={'year'}
                  label="Year"
                  form={form}
                  addressGroupField
                  disabled={
                    (searchedVinSuccess && searchFields.year) || isValidVin
                  }
                  required
                />
                <InputItem
                  name={'make'}
                  label="Make"
                  form={form}
                  disabled={
                    (searchedVinSuccess && searchFields.make) || isValidVin
                  }
                  required
                  addressGroupField
                />
                <InputItem
                  name={'model'}
                  label="Model"
                  form={form}
                  required
                  disabled={
                    (searchedVinSuccess && searchFields.model) || isValidVin
                  }
                  addressGroupField
                />
                <InputItem
                  name={'axles'}
                  label="Axles"
                  form={form}
                  addressGroupField
                  type="select"
                  options={AXLES_OPTIONS}
                  // disabled={
                  //   (searchedVinSuccess && searchFields.axles) || isValidVin
                  // }
                  required
                />
                <InputItem
                  name={'combined_axles'}
                  label="Combined Axles"
                  form={form}
                  addressGroupField
                  type="select"
                  options={COMBINED_AXLES_OPTIONS}
                  // disabled={
                  //   (searchedVinSuccess && searchFields.combined_axles) ||
                  //   isValidVin
                  // }
                  labelTooltip={
                    <ClassTooltipContent
                      title="Combined Axles"
                      description={
                        <>Total combination of axles, truck and trailer.</>
                      }
                    />
                  }
                />
                <InputItem
                  name={'unladen_weight'}
                  label="Unladen Weight (lbs.)"
                  placeholder="0,000,000"
                  form={form}
                  addressGroupField
                  // disabled={
                  //   (searchedVinSuccess && searchFields.unladen_weight) ||
                  //   isValidVin
                  // }
                  labelTooltip={
                    <ClassTooltipContent
                      title="Unladen Weight"
                      description={
                        <>Weight of vehicle only, no cargo or trailer.</>
                      }
                    />
                  }
                  required
                />
                <InputItem
                  name={'gvwr'}
                  label="GVWR (lbs.)"
                  form={form}
                  placeholder="0,000,000"
                  addressGroupField
                  // disabled={
                  //   (searchedVinSuccess && searchFields.gvwr) || isValidVin
                  // }
                  labelTooltip={
                    <ClassTooltipContent
                      title="Unladen Weight"
                      description={
                        <>
                          Maximum loaded weight of the vehicle, as determined by
                          the manufacturer.
                        </>
                      }
                    />
                  }
                  required
                />
                <InputItem
                  name={'lp_number'}
                  label="License Plate #"
                  form={form}
                  placeholder="Plate number"
                  addressGroupField
                />
                <InputItem
                  name={'lp_state_id'}
                  label="License Plate State"
                  form={form}
                  addressGroupField
                  type="select"
                  options={stateOptions}
                />
                <InputItem
                  name={'lp_exp_date'}
                  label="Plate Exp. Date"
                  form={form}
                  type="date"
                />
              </>
            )}
          </FormSection>
        ) : (
          <ListInfo objKeys={PROFILE_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default Profile;
