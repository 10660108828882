import Request from './request';

// GET
export const apiGetFormsMSAs = async (companyId, filters) => {
  return Request.call({
    url: `/company/${companyId}/forms-msas/list`,
    method: 'GET',
    params: filters,
  });
};

// POST
export const apiCreateUpdateFormsMSA = async (id, data) => {
  return Request.call({
    url: `/company/${id}/forms-msas`,
    method: 'POST',
    data,
  });
};
export const apiUpdateFormsMSAStatus = async (companyId, msaId, data) => {
  return Request.call({
    url: `/company/${companyId}/forms-msas/${msaId}/status`,
    method: 'POST',
    data,
  });
};
