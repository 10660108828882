import { renderStandardColumn, renderTagColumn } from 'utils/table';
import {
  CLIENT_ORDER_STATUS_MAPPING,
  FORMATS,
  SORT_DIRECTION_MAPPING,
} from 'helper/const';
import { REPORT_FIELD_WIDTH } from 'helper/report';

const getReportColumns = (fields, activeFields) => {
  const tActiveFields = activeFields || [];
  return (fields || [])
    .filter(
      field => tActiveFields.length === 0 || tActiveFields.includes(field.value)
    )
    .map((field, index) => {
      const otherAttrs = {};
      if (index === 0) {
        otherAttrs.defaultSortOrder = 'ascend';
      }
      return {
        title: field.label,
        dataIndex: field.value,
        render: (val, record) => {
          return field.value?.includes('status')
            ? renderTagColumn(val, record, null, CLIENT_ORDER_STATUS_MAPPING)
            : field.value?.includes('phone')
            ? renderStandardColumn(val, FORMATS.TABLE_PHONE)
            : renderStandardColumn(val);
        },
        width: REPORT_FIELD_WIDTH[field.value] || 105,
        sorter: true,
        ...otherAttrs,
      };
    });
};
const getFilterOptionValues = options => {
  return options && options[options.length - 1] === 'all'
    ? []
    : (options || []).filter(option => option !== 'all' && !!option);
};
const getFilters = ({
  page,
  key,
  keyword,
  fields,
  clients,
  download,
  selectedRows,
  sort,
  rFields,
}) => {
  const filters = {
    page,
    per_page: 10,
    type: key,
    search: keyword || '',
    download,
  };
  const filteredFields = getFilterOptionValues(fields);
  if (filteredFields.length > 0) {
    filters.fields =
      rFields && rFields.length > 0
        ? rFields
            .filter(f => filteredFields.includes(f.value))
            .map(f => f.value)
        : filteredFields;
  }
  const filteredClients = getFilterOptionValues(clients);
  if (filteredClients.length > 0) {
    filters.company_ids = filteredClients;
  }
  if (selectedRows && selectedRows.length > 0) {
    filters.ids = selectedRows.map(r => r.id);
  }
  const sortKey = sort?.field || '';
  const sortMethod = sort?.order || '';
  if (!!sortKey && !!sortMethod) {
    filters.sort_by = sortKey;
    filters.dir = SORT_DIRECTION_MAPPING[sortMethod];
  }
  return filters;
};
const getIsDefaultSearchFilter = ({ keyword }) => {
  return keyword === '';
};
const getDisplayActiveFields = (fields = []) => {
  return fields[fields.length - 1] === 'all'
    ? []
    : fields.filter(f => f !== 'all');
};
const getTableWidth = activeFieldKeys => {
  let w = 22;
  activeFieldKeys.map(key => {
    w = w + (REPORT_FIELD_WIDTH[key] || 105);
    return true;
  });
  return w;
};

export {
  getReportColumns,
  getFilters,
  getIsDefaultSearchFilter,
  getDisplayActiveFields,
  getTableWidth,
};
