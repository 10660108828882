import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  conversationDetailTypes,
} from '../types';
import get from 'lodash/get';
import { initialConversationDetailState } from '../states/conversationDetail';

export default (state = initialConversationDetailState, action) => {
  const { attr, value } = action.payload || {};
  switch (action.type) {
    // CUSTOM
    // SYNC_SET_RESET_ACTIVE_CONVERSATION
    case conversationDetailTypes.SYNC_SET_RESET_ACTIVE_CONVERSATION: {
      return {
        ...state,
        resetActiveConversation: action.payload,
      };
    }
    // SYNC_SET_ACTIVE_CONVERSATION
    case conversationDetailTypes.SYNC_SET_ACTIVE_CONVERSATION: {
      return {
        ...state,
        activeConversation: action.payload,
      };
    }
    // SYNC_UPDATE_CONVERSATION_DETAIL
    case conversationDetailTypes.SYNC_UPDATE_CONVERSATION_DETAIL: {
      return {
        ...state,
        conversationDetail: {
          ...state.conversationDetail,
          data: {
            ...state.conversationDetail.data,
            [attr]: value,
          },
        },
      };
    }
    // GET
    // GET_CONVERSATION_DETAIL
    case asyncActionTypeRequest(
      conversationDetailTypes.GET_CONVERSATION_DETAIL
    ): {
      return {
        ...state,
        conversationDetail: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(
      conversationDetailTypes.GET_CONVERSATION_DETAIL
    ): {
      return {
        ...state,
        conversationDetail: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(
      conversationDetailTypes.GET_CONVERSATION_DETAIL
    ): {
      return {
        ...state,
        conversationDetail: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // POST
    // MARK_CHAT_UNREAD
    case asyncActionTypeRequest(conversationDetailTypes.MARK_CHAT_UNREAD): {
      return {
        ...state,
        markChatUnread: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(conversationDetailTypes.MARK_CHAT_UNREAD): {
      return {
        ...state,
        markChatUnread: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(conversationDetailTypes.MARK_CHAT_UNREAD): {
      return {
        ...state,
        markChatUnread: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    default:
      return state;
  }
};
