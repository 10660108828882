import React from 'react';
import cn from 'classnames';
import FormListSection from 'views/pages/ClientDetail/components/FormListSection';
import ViewListSection from 'views/pages/ClientDetail/components/ViewListSection';
import { Title } from 'views/pages/ClientDetail/components/Elements';
import classes from './ListSection.module.scss';

const ListSection = ({
  className,
  isEdit,
  form,
  data,
  title,
  addBtnText,
  parentName,
  fields,
  renderEditForm,
  hideStatus = true,
  displayDelete,
}) => {
  const handleRenderTitle = (obj, index) => {
    return `No. ${index + 1}`;
  };
  return (
    <div className={cn(classes.wrapper, className)}>
      <Title text={title}></Title>
      <FormListSection
        notFoundMessage="No records to display"
        isEdit={isEdit}
        form={form}
        data={data || []}
        titleField=""
        name={parentName}
        addBtnText={addBtnText}
        newLabel="No. "
        hideStatus={hideStatus}
        displayDelete={displayDelete}
        renderFields={index => {
          return renderEditForm(index);
        }}
        renderViews={() => {
          return (
            <ViewListSection
              name={parentName}
              renderTitle={handleRenderTitle}
              data={{
                [parentName]: data,
              }}
              onStatusChange={() => {}}
              changeStatusLoading={{}}
              fields={fields}
              hideStatus
            />
          );
        }}
      />
    </div>
  );
};

export default ListSection;
