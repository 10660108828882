export const NEW_KEY = 'new_truck';
export const NORMAL_FIELDS = [
  'truck_unit',
  'vin',
  'color',
  'year',
  'make',
  'model',
  'unladen_weight',
  'gvwr',
  'lp_number',
  'title_number',
  'purchase_price',
  'factory_price',
  'address',
  'apt',
  'city',
  'county',
  'zip_code',
  'irp_number',
  'ifta_number',
  'insurance_policy_number',
  'or_permit_number',
  'nm_permit_number',
  'kyu_permit_number',
  'ny_hut_permit_number',
  'truck_type_other',
];
export const NULL_FIELDS = [
  'truck_type_id',
  'axles',
  'combined_axles',
  'lp_state_id',
  'truck_state_id',
  'state_id',
  'truck_leased',
  'truck_leased_from_id',
];
export const NUMBER_FIELDS = [
  'truck_unit',
  'lp_number',
  'purchase_price',
  'factory_price',
];
export const ARR_FIELDS = ['assigned_drivers', 'assigned_trailers'];
export const DATE_FIELDS = [
  'lp_exp_date',
  'purchase_date',
  'vehicle_inspection_exp_date',
];
export const FILE_FIELDS = [
  'documents.title_receipt',
  'documents.bill_of_sale',
  'documents.vehicle_inspection',
  'documents.ucr',
  'documents.2290',
  'documents.cab_card',
  'documents.ifta_license',
  'documents.cert_of_insurance',
  'documents.or_permit',
  'documents.nm_permit',
  'documents.kyu_permit',
  'documents.ny_hut_permit',
];
export const BOOLEAN_FIELDS = ['garage_address_same_as_physical'];
export const YES_NO_FIELDS = ['truck_leased'];
export const BODY_REQUEST_NORMAL_FIELDS = [
  'truck_unit',
  'vin',
  'color',
  'year',
  'make',
  'model',
  'unladen_weight',
  'gvwr',
  'lp_number',
  'title_number',
  'purchase_price',
  'factory_price',
  'address',
  'apt',
  'city',
  'county',
  'zip_code',
  'truck_leased_from_id',
  'truck_type_id',
  'axles',
  'combined_axles',
  'lp_state_id',
  'truck_state_id',
  'state_id',
  'assigned_drivers',
  'assigned_trailers',
  'truck_leased',
  'irp_number',
  'ifta_number',
  'insurance_policy_number',
  'or_permit_number',
  'nm_permit_number',
  'kyu_permit_number',
  'ny_hut_permit_number',
  'truck_type_other',
];
export const DEFAULT_TRUCK = {
  key: 'section 1',
  id: 10,
  label: '435',
  status: 'active',
  truck_unit: '435',
  type: 'Semi Truck',
  vin: '1DJF5SFS55688438',
  color: 'Green',
  year: '2015',
  make: 'Western Star',
  model: '1900SF',
  axles: 3,
  combined_axles: 6,
  unladen_weight: '29,452',
  gvwr: '36,680',
  lp_number: 'XG 250365',
  license_state: 'Idaho - ID',
  lp_exp_date: '05/26/2023',
  title_number: '54656585646433',
  truck_state: 'Idaho - ID',
  purchase_price: '76,459.00',
  factory_price: '76,459.00',
  purchase_date: '05/17/2017',
  vehicle_inspection_exp_date: '05/17/2017',
  address: '5701 W. Van Buren St.',
  apt: '',
  city: 'Phoenix',
  county: 'Mesa',
  state: 'Arizona - AZ',
  zip_code: '85043',
  assigned_drivers: [{ id: 'Jones, George' }],
  assigned_trailers: [{ id: 'T - 026' }],
  truck_leased: 0,
  truck_leased_from: 'Market Truck Leasing, LLC',
};
export const DEFAULT_SECTIONS = [];

export const PROFILE_OBJ_KEYS = [
  {
    label: 'Unit',
    nameGroup: true,
    fields: [
      {
        label: 'Truck Unit #',
        key: 'truck_unit',
        type: 'value',
      },
      {
        label: 'Type of Truck',
        key: 'truck_type',
        type: 'value',
      },
    ],
  },
  {
    label: 'VIN - Vehicle Identification Number',
    key: 'vin',
    type: 'value',
  },
  {
    label: 'Other',
    key: 'truck_type_other',
    type: 'value',
    dependField: 'truck_type',
    dependFn: val => val !== 'Other',
    fullWidth: true,
  },
  {
    label: 'Info',
    nameGroup: true,
    fields: [
      {
        label: 'Color',
        key: 'color',
        type: 'value',
      },
      {
        label: 'Year',
        key: 'year',
        type: 'value',
      },
    ],
  },
  {
    label: 'Make',
    nameGroup: true,
    fields: [
      {
        label: 'Make',
        key: 'make',
        type: 'value',
      },
      {
        label: 'Model',
        key: 'model',
        type: 'value',
      },
    ],
  },
  {
    label: 'Unit',
    addressGroup: true,
    fields: [
      {
        label: 'Axles',
        key: 'axles',
        type: 'value',
      },
      {
        label: 'Combined Axles',
        key: 'combined_axles',
        type: 'value',
      },
      {
        label: 'Unladen Weight (lbs.)',
        key: 'unladen_weight',
        type: 'value',
      },
      {
        label: 'GVWR (lbs.)',
        key: 'gvwr',
        type: 'value',
      },
    ],
  },
  {
    label: 'Date',
    nameGroup: true,
    fields: [
      {
        label: 'License Plate #',
        key: 'lp_number',
        type: 'value',
      },
      {
        label: 'License Plate State',
        key: 'lp_state',
        type: 'value',
      },
    ],
  },
  {
    label: 'Plate Exp. Date',
    key: 'lp_exp_date',
    type: 'date',
  },
];
export const TITLE_OBJ_KEYS = [
  {
    label: 'Title / Title Receipt',
    key: 'documents.title_receipt',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Number',
    nameGroup: true,
    fields: [
      {
        label: 'Title Number',
        key: 'title_number',
        type: 'value',
      },
      {
        label: 'State',
        key: 'truck_state',
        type: 'value',
      },
    ],
  },
  {
    label: 'Number',
    nameGroup: true,
    fields: [
      {
        label: 'Purchase Price',
        key: 'purchase_price',
        type: 'price',
      },
      {
        label: 'Factory Price',
        key: 'factory_price',
        type: 'price',
      },
    ],
  },
  {
    label: 'Purchase Date',
    key: 'purchase_date',
    type: 'date',
  },
  {
    label: 'Bill of Sale',
    key: 'documents.bill_of_sale',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Vehicle Inspection',
    key: 'documents.vehicle_inspection',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Vehicle Inspection Expiration Date',
    key: 'vehicle_inspection_exp_date',
    type: 'date',
  },
];
export const BILL_OF_STATE_OBJ_KEYS = [
  {
    label: 'Number',
    nameGroup: true,
    fields: [
      {
        label: 'Purchase Price',
        key: 'purchase_price',
        type: 'price',
      },
      {
        label: 'Factory Price',
        key: 'factory_price',
        type: 'price',
      },
    ],
  },
  {
    label: 'Purchase Date',
    key: 'purchase_date',
    type: 'date',
  },
  {
    label: 'Bill of Sale',
    key: 'documents.bill_of_sale',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Vehicle Inspection',
    key: 'documents.vehicle_inspection',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Vehicle Inspection Expiration Date',
    key: 'vehicle_inspection_exp_date',
    type: 'date',
  },
];
export const ADDRESSES_OBJ_KEYS = [
  {
    label: 'Garage address same as physical address',
    key: 'garage_address_same_as_physical',
    type: 'checkbox',
    fullWidth: true,
  },
  {
    label: 'Street Address',
    key: 'address',
    type: 'value',
    dependField: 'garage_address_same_as_physical',
    dependFn: val => !!val,
  },
  {
    label: 'Apt.',
    key: 'apt',
    type: 'value',
    empty: true,
    dependField: 'garage_address_same_as_physical',
    dependFn: val => !!val,
  },
  {
    label: 'Address',
    addressGroup: true,
    alignLeft: true,
    dependField: 'garage_address_same_as_physical',
    dependFn: val => !!val,
    fields: [
      {
        label: 'City',
        key: 'city',
        type: 'value',
      },
      // {
      //   label: 'County',
      //   key: 'county',
      //   type: 'value',
      // },
      {
        label: 'State',
        key: 'state',
        type: 'value',
      },
      {
        label: 'Zip Code',
        key: 'zip_code',
        type: 'value',
      },
    ],
  },
];
export const EQUIPMENT_OBJ_KEYS = [
  {
    label: 'Assigned Driver',
    key: 'assigned_drivers',
    type: 'multiValue',
    labelKey: 'name',
  },
  {
    label: 'Assigned Trailers',
    key: 'assigned_trailers',
    type: 'multiValue',
    labelKey: 'trailer_unit',
  },
];
export const LEASED_OBJ_KEYS = [
  {
    label: 'Truck Leased?',
    key: 'truck_leased',
    type: 'boolean',
  },
  {
    label: 'Leasor',
    key: 'truck_leased_from',
    type: 'value',
  },
];
export const FILINGS_OBJ_KEYS = [
  {
    label: 'UCR',
    key: 'documents.ucr',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: '2290',
    key: 'documents.2290',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'IRP Number',
    key: 'irp_number',
    type: 'value',
  },
  {
    label: 'Cab Card',
    key: 'documents.cab_card',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'IFTA Number',
    key: 'ifta_number',
    type: 'value',
  },
  {
    label: 'IFTA License',
    key: 'documents.ifta_license',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Ins. Policy Number',
    key: 'insurance_policy_number',
    type: 'value',
  },
  {
    label: 'Cert. of Insurance',
    key: 'documents.cert_of_insurance',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'OR Permit Number',
    key: 'or_permit_number',
    type: 'value',
  },
  {
    label: 'OR Permit',
    key: 'documents.or_permit',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'NM Permit Number',
    key: 'nm_permit_number',
    type: 'value',
  },
  {
    label: 'NM Permit',
    key: 'documents.nm_permit',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'KYU Permit Number',
    key: 'kyu_permit_number',
    type: 'value',
  },
  {
    label: 'KYU Permit',
    key: 'documents.kyu_permit',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'NY HUT Permit Number',
    key: 'ny_hut_permit_number',
    type: 'value',
  },
  {
    label: 'NY HUT Permit',
    key: 'documents.ny_hut_permit',
    type: 'file',
    isSingleFile: true,
  },
];
export const AXLES_OPTIONS = [
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
];
export const COMBINED_AXLES_OPTIONS = [
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
  {
    label: 6,
    value: 6,
  },
  {
    label: 7,
    value: 7,
  },
  {
    label: 8,
    value: 8,
  },
];
export const ASSIGNED_TRAILER_OPTIONS = [
  {
    value: 'T - 024',
    label: 'T - 024',
  },
  {
    value: 'T - 025',
    label: 'T - 025',
  },
  {
    value: 'T - 026',
    label: 'T - 026',
  },
  {
    value: 'T - 027',
    label: 'T - 027',
  },
  {
    value: 'P - 003',
    label: 'P - 003',
  },
  {
    value: 'P - 004',
    label: 'P - 004',
  },
];
export const ASSIGNED_DRIVER_OPTIONS = [
  {
    value: 'Cash, Johnny',
    label: 'Cash, Johnny',
  },
  {
    value: 'Jennings, Waylon',
    label: 'Jennings, Waylon',
  },
  {
    value: 'Jones, George',
    label: 'Jones, George',
  },
];
