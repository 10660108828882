import Request from './request';

// GET
export const apiGetClientDetail = async id => {
  return Request.call({
    url: `/clients/${id}`,
    method: 'GET',
    params: {},
  });
};
export const apiGetClientDetailLog = async (id, filters = {}) => {
  return Request.call({
    url: `/activity-logs/company/${id}`,
    method: 'GET',
    params: filters,
  });
};
export const apiGetCompanyOfficials = async (id, filters = {}) => {
  return Request.call({
    url: `/company/${id}/officials/list`,
    method: 'GET',
    params: filters,
  });
};

// POST
export const apiUpdateSalesInfo = async (id, data) => {
  return Request.call({
    url: `/company/${id}/sales-info`,
    method: 'POST',
    data,
  });
};
export const apiUpdateCompany = async (id, data) => {
  return Request.call({
    url: `/company/${id}`,
    method: 'POST',
    data,
  });
};
const typeMapping = {
  newMexico: 'new_mexico',
  newYork: 'new_york',
};
export const apiUpdateFilling = async (id, type, data) => {
  const url = `/company/${id}/filings${
    type === 'general'
      ? '/general'
      : `/other?type=${typeMapping[type] || type}_filing`
  }`;
  return Request.call({
    url,
    method: 'POST',
    data,
  });
};
export const apiUpdateInsurance = async (id, data) => {
  return Request.call({
    url: `/company/${id}/insurance`,
    method: 'POST',
    data,
  });
};
export const apiManageLogo = async (id, data) => {
  return Request.call({
    url: `/company/${id}/logo`,
    method: 'POST',
    data,
  });
};
export const apiAddLog = async (id, data) => {
  return Request.call({
    url: `/company/${id}/note`,
    method: 'POST',
    data,
  });
};
export const apiCreateUpdateCompanyOfficial = async (id, data) => {
  return Request.call({
    url: `/company/${id}/officials`,
    method: 'POST',
    data,
  });
};
export const apiUpdateCompanyOfficialStatus = async (
  companyId,
  officialId,
  data
) => {
  return Request.call({
    url: `/company/${companyId}/officials/${officialId}/status`,
    method: 'POST',
    data,
  });
};
export const apiSearchSafer = async (filters = {}) => {
  return Request.call({
    url: `/integrations/safer/search`,
    method: 'GET',
    params: filters,
  });
};
export const apiDOTSearchSafer = async (filters = {}) => {
  return Request.call({
    url: `/integrations/safer/dot-search`,
    method: 'GET',
    params: filters,
  });
};
export const apiSaveDOT = async (id, data) => {
  return Request.call({
    url: `/company/${id}/dot-classification`,
    method: 'POST',
    data,
  });
};
export const apiArchiveClient = async data => {
  return Request.call({
    url: `/company/archive`,
    method: 'POST',
    data,
  });
};
export const apiUnArchiveClient = async data => {
  return Request.call({
    url: `/company/unarchive`,
    method: 'POST',
    data,
  });
};
export const apiDeleteClient = async data => {
  return Request.call({
    url: `/company/delete`,
    method: 'DELETE',
    data,
  });
};
export const apiGetDOT = async id => {
  return Request.call({
    url: `/clients/${id}/dot-classification`,
    method: 'GET',
    params: {},
  });
};
export const apiGetCompanyUsers = async (companyId, params) => {
  return Request.call({
    url: `/company/${companyId}/users`,
    method: 'GET',
    params,
  });
};
