import createTypes from 'redux-create-action-types';

export default createTypes(
  'SYNC_UPDATE_REMINDER_DETAIL_STATUS',
  'SYNC_UPDATE_COMPANY_REMINDER_CHECKLISTS',
  'SYNC_UPDATE_COMPANY_REMINDERS',
  'GET_ACTIVE_REMINDERS',
  'GET_INACTIVE_REMINDERS',
  'GET_ACTIVE_COMPANY_REMINDERS',
  'GET_DISMISSED_COMPANY_REMINDERS',
  'GET_COMPLETED_COMPANY_REMINDERS',
  'GET_REMINDER_DETAIL',
  'CREATE_REMINDER',
  'UPDATE_REMINDER',
  'DELETE_REMINDER',
  'CHANGE_REMINDER_STATUS',
  'SUBSCRIBE_REMINDER_EMAIL'
);
