export const TAB_NAME_MAPPING = {
  '1': 'background',
  '2': 'business',
  '3': 'documents',
  '4': 'locations',
  '5': 'buyout',
  '6': 'credit',
  '7': 'ifa',
  '8': 'ucc',
};
export const TAB_DATA_MAPPING = {
  ifa: 'ifa_alerts',
};
