import {
  apiSaveClientOrderChecklist,
  apiMarkClientOrderChecklist,
  apiDeleteClientOrderChecklist,
  apiGetClientOrderFieldValues,
  apiGetClientOrderChecklist,
  apiAddClientOrderNote,
} from 'api';
import { cleanDuplicateOrderFieldValues } from 'utils/order';
import { syncUpdateOrderDetailFields } from '../actions/order';
import { clientOrderDetailTypes } from '../types';

// CUSTOM
// SYNC_UPDATE_CLIENT_ORDER_CHECKLIST
export const syncUpdateClientOrderChecklist = checklists => dispatch => {
  dispatch({
    type: clientOrderDetailTypes.SYNC_UPDATE_CLIENT_ORDER_CHECKLIST,
    payload: checklists,
  });
};

// GET_CLIENT_ORDER_FIELD_VALUES
export const getClientOrderFieldValues = (id, orderId, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientOrderDetailTypes.GET_CLIENT_ORDER_FIELD_VALUES,
    payload: {},
    asyncCall: () => {
      return apiGetClientOrderFieldValues(id, orderId);
    },
    afterSuccessCall: (dispatch, response) => {
      if (cb) cb(response);
      dispatch(
        syncUpdateOrderDetailFields(
          cleanDuplicateOrderFieldValues(response.data || [])
        )
      );
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_CLIENT_ORDER_CHECKLIST
export const getClientOrderChecklist = (
  { id, orderId, emptyPrevState, silent },
  cb
) => {
  return {
    isAsyncCall: true,
    baseType: clientOrderDetailTypes.GET_CLIENT_ORDER_CHECKLIST,
    payload: { emptyPrevState, silent },
    asyncCall: () => {
      return apiGetClientOrderChecklist(id, orderId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// SAVE_CLIENT_ORDER_CHECKLIST
export const saveClientOrderChecklist = (id, orderId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientOrderDetailTypes.SAVE_CLIENT_ORDER_CHECKLIST,
    payload: {},
    asyncCall: () => {
      return apiSaveClientOrderChecklist(id, orderId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// MARK_CLIENT_ORDER_CHECKLIST
export const markClientOrderChecklist = (
  id,
  orderId,
  checklistId,
  isChecked,
  cb
) => {
  return {
    isAsyncCall: true,
    baseType: clientOrderDetailTypes.MARK_CLIENT_ORDER_CHECKLIST,
    payload: {},
    asyncCall: () => {
      return apiMarkClientOrderChecklist(id, orderId, checklistId, isChecked);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// DELETE_CLIENT_ORDER_CHECKLIST
export const deleteClientOrderChecklist = (id, orderId, checklistId, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientOrderDetailTypes.DELETE_CLIENT_ORDER_CHECKLIST,
    payload: {},
    asyncCall: () => {
      return apiDeleteClientOrderChecklist(id, orderId, checklistId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// ADD_CLIENT_ORDER_NOTE
export const addClientOrderNote = (id, orderId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientOrderDetailTypes.ADD_CLIENT_ORDER_NOTE,
    payload: {},
    asyncCall: () => {
      return apiAddClientOrderNote(id, orderId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
