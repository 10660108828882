import React from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import compact from 'lodash/compact';
import { FORMATS } from 'helper/const';
import { getProfileDate } from 'utils/date';
import { getCurrencyValue } from 'utils/number';
import { TooltipBlock } from 'components/Tooltip';
import { getStrWithFormat } from 'utils/textFormat';
import classes from './Elements.module.scss';

export const getCheckbox = (key, data) => {
  return get(data, key) || false;
};
export const getValue = (key, data, { empty, suffix = '', onFilter }) => {
  const val = get(data, key) || '';
  const filteredVal = onFilter ? onFilter(val) : val;
  return filteredVal
    ? compact([filteredVal, suffix]).join(' ')
    : empty
    ? ''
    : '-';
};
export const getMultiValue = (key, data, { empty, labelKey }) => {
  const val = get(data, key) || '';
  if (!val || val.length === 0) return empty ? '' : 'None';
  if (typeof val !== 'object') {
    return val;
  }
  return val.map(v => v[labelKey || 'label']).join('\n');
};
export const getDate = (key, data) => {
  const val = get(data, key);
  return val ? getProfileDate(val, true) : '-';
};
export const getPrice = (key, data) => {
  const val = get(data, key);
  return val ? getCurrencyValue(val, true) : '-';
};
export const getBoolean = (key, data) => {
  const val = get(data, key);
  const valInt = val && typeof val === 'string' ? parseInt(val, 10) : val;
  return valInt === 1 ? 'Yes' : valInt === 0 ? 'No' : '-';
};
export const getFormatValue = (key, data, { empty, format }) => {
  const val = get(data, key);
  return val
    ? getStrWithFormat(val, format || FORMATS.TABLE_PHONE)
    : empty
    ? ''
    : '-';
};
export const getFile = (key, data, { isSingleFile }) => {
  const fileObjs = get(data, key) || null;
  const listFiles = isSingleFile
    ? fileObjs
      ? [fileObjs]
      : []
    : fileObjs || [];
  if (listFiles.length === 0) {
    return 'No uploaded attachments';
  }
  return (
    <span className={classes.listFiles}>
      {(isArray(listFiles) ? listFiles : [listFiles]).map((f, i) => {
        return (
          <TooltipBlock
            key={i}
            tooltip={f.name}
            placement="top"
            tooltipWidth={'100%'}
          >
            <a
              rel="noreferrer"
              href={f.url}
              target={f.url && f.url !== '#' ? '_blank' : '_self'}
            >
              {f.name}
            </a>
          </TooltipBlock>
        );
      })}
    </span>
  );
};
