import React from 'react';
import { UserOutlined } from '@ant-design/icons';

import Dropdown from 'components/Dropdown';
import Space from 'components/Space';

const DropdownComponent = ({ classes }) => {
  const menus = [
    {
      to: '#',
      icon: <UserOutlined />,
      label: 'Menu 1',
      props: {},
    },
    {
      to: '#',
      icon: <UserOutlined />,
      label: 'Menu 2',
      props: {},
      disabled: true,
    },
    {
      subs: {
        label: 'Sub Menu',
        icon: <UserOutlined />,
        items: [
          {
            to: '#',
            icon: <UserOutlined />,
            label: 'Sub Menu Item 1',
            props: {},
            selected: true,
          },
          {
            to: '#',
            icon: <UserOutlined />,
            label: 'Sub Menu Item 2',
            props: {},
            disabled: true,
          },
          {
            to: '#',
            icon: <UserOutlined />,
            label: 'Sub Menu Item 3',
            props: {},
          },
        ],
      },
    },
  ];
  const menus1 = [
    {
      to: '#',
      icon: null,
      label: 'Menu 1',
      props: {},
    },
    {
      to: '#',
      icon: null,
      label: 'Menu 2',
      props: {},
      disabled: true,
    },
    {
      label: 'Menu 3',
      props: {},
      subs: {
        label: 'Sub Menu',
        items: [
          {
            to: '#',
            icon: null,
            label: 'Sub Menu Item 1',
            props: {},
            selected: true,
          },
          {
            to: '#',
            icon: null,
            label: 'Sub Menu Item 2',
            props: {},
            disabled: true,
          },
          {
            to: '#',
            icon: null,
            label: 'Sub Menu Item 3',
            props: {},
          },
        ],
      },
    },
  ];

  return (
    <Space direction={'vertical'}>
      <Space direction={'horizontal'} size={24}>
        <Dropdown menus={menus}>Normal Dropdown</Dropdown>
        <Dropdown trigger={['click']} menus={menus}>
          Trigger
        </Dropdown>
        <Dropdown disabled>Disable</Dropdown>
      </Space>
      <Space direction={'horizontal'} size={24}>
        <Dropdown menus={menus1}>Normal Dropdown</Dropdown>
        <Dropdown trigger={['click']} menus={menus1}>
          Trigger
        </Dropdown>
      </Space>
    </Space>
  );
};

export default DropdownComponent;
