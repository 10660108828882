import React from 'react';
import find from 'lodash/find';
import * as sharedSelectors from 'store/selectors/shared';
import { ColumnActions } from 'components/Table';
import {
  MY_TEAM_DISPLAY_TABS,
  SORT_DIRECTION_MAPPING,
  ORDER_TYPE_HIDDEN_TABS,
} from 'helper/const';
import {
  renderStandardColumn,
  renderTagColumn,
  getPriceValue,
  renderDateColumn,
} from 'utils/table';
import DepartmentTab from './DepartmentTab';

const getAllOrderTypesColumns = ({ onDelete, onEdit }) => {
  return [
    {
      title: 'Order Type',
      dataIndex: 'name',
      render: renderStandardColumn,
      width: 280,
      sorter: true,
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (_, record) => {
        return renderStandardColumn(getPriceValue(record));
      },
      width: 160,
      sorter: true,
    },
    {
      title: 'Date Created',
      dataIndex: 'created_at',
      render: renderDateColumn,
      width: 160,
      sorter: true,
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      render: renderStandardColumn,
      width: 150,
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value, record) => {
        return renderTagColumn(value, record, () => {
          onEdit(record, false);
        });
      },
      width: 100,
    },
    {
      title: 'Action',
      width: 90,
      render: (_value, record) => {
        const actions = [
          {
            label: 'Edit',
            props: {
              onClick: () => {
                onEdit(record, true);
              },
            },
          },
          {
            label: 'Delete',
            props: {
              onClick: () => {
                onDelete(record);
              },
            },
          },
        ];
        return <ColumnActions trigger={['click']} actions={actions} />;
      },
    },
  ];
};
const getDepartmentTabContent = (props, thisTab, tabName, tabSlug) => {
  return (
    <DepartmentTab
      {...props}
      currentTab={thisTab}
      tabName={tabName}
      tabSlug={tabSlug}
    />
  );
};
const getTheRestTabObj = () => {
  const tabsDisplay = MY_TEAM_DISPLAY_TABS;
  return tabsDisplay
    .filter(t => !ORDER_TYPE_HIDDEN_TABS.includes(t))
    .map((t, i) => ({
      slug: t,
      // this is tab index, tab index start from 1, 1 is All Departments, then will start from 2
      index: i + 2,
    }));
};
const getTheRestTabs = ({
  activeTab,
  keyword,
  setKeyword,
  otherSettings = {},
  onSortChange,
  tabSorts,
}) => {
  const props = {
    activeTab,
    keyword,
    setKeyword,
    onSortChange,
    tabSorts,
  };
  const tabObjects = getTheRestTabObj();
  const departments = sharedSelectors.useSelectDepartments();
  return tabObjects.map(t => {
    const tTab = find(departments, { slug: t.slug }) || {};
    return {
      title: <span>{tTab.name}</span>,
      content: getDepartmentTabContent(props, t.index, tTab.name, t.slug),
      ...otherSettings,
    };
  });
};

const getOrderTypeListFilter = values => {
  const returnObj = {
    page: values.page,
    name: values.name,
  };
  if (values.sort && values.sort.field && values.sort.order) {
    returnObj.sort_by = values.sort.field;
    returnObj.dir = SORT_DIRECTION_MAPPING[values.sort.order];
  }
  if (values.department_id) {
    returnObj.department_id = values.department_id;
  }
  return returnObj;
};

export { getAllOrderTypesColumns, getTheRestTabs, getOrderTypeListFilter };
