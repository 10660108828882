import React from 'react';
import PageHeader from 'components/PageHeader';
import ROUTES from 'routes';
import TopBarContent from 'layouts/Main/TopBarContent';
import { useHeaderData } from './selectorData';

const Header = ({ isAdmin }) => {
  const { detailData, clientOrder } = useHeaderData();
  const breadcrumbs = isAdmin
    ? [
        {
          label: 'Client List',
          to: ROUTES.CLIENT_LIST(),
          color: 'blue',
        },
        {
          label: detailData?.legal_name || 'Client detail',
          to: ROUTES.CLIENT_DETAIL({ id: detailData?.id || '0' }),
          color: 'blue',
        },
        {
          label: clientOrder?.order_number || 'Order #',
        },
      ]
    : [
        {
          label: 'My Company',
          to: ROUTES.MY_COMPANY(),
          color: 'blue',
        },
        {
          label: clientOrder?.order_number || 'Order #',
        },
      ];
  return (
    <TopBarContent>
      <PageHeader title={null} breadcrumbs={breadcrumbs} />
    </TopBarContent>
  );
};

export default Header;
