import React from 'react';
import cn from 'classnames';
import {
  EyeInvisibleFilled,
  CarryOutFilled,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { STATUS_VALUES } from 'helper/const';
import { getProfileDate, getReminderTime } from 'utils/date';
import Button, { ButtonColors } from 'components/Button';
import Checklist from 'views/pages/ClientOrderDetail/components/Checklist';
import classes from './ReminderCard.module.scss';

const ReminderCard = ({
  className,
  handleNewChecklist,
  handleOrderChecklist,
  handleRemoveChecklist,
  handleUpdateChecklist,
  handleCheckedChange,
  addChecklistLoading,
  updateChecklistLoading,
  data,
  onDismiss,
  onComplete,
  onStartOrder,
  actionLoading,
}) => {
  const price = data?.price;
  const hasPrice = price || price === 0;
  const alertHasOrderActive = hasPrice && data?.status === STATUS_VALUES.ACTIVE;
  const hideChecklist = true;
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.header}>
        <h2>
          {data?.name}
          {data?.status !== STATUS_VALUES.ACTIVE && (
            <div className={classes.reminderLastChange}>
              {data?.updated_at ? getReminderTime(data.updated_at) : ''}:{' '}
              {data?.updated_by} {data?.status}
              {data?.status === STATUS_VALUES.COMPLETE ? 'd' : 'ed'} this order
            </div>
          )}
        </h2>
        <div>
          {data?.status === STATUS_VALUES.ACTIVE && (
            <Button
              onClick={onDismiss(data)}
              color={ButtonColors.DEFAULT}
              type="ghost"
              leftIcon={<EyeInvisibleFilled />}
              loading={!!actionLoading[data?.id]}
            >
              Dismiss
            </Button>
          )}
          {data?.status !== STATUS_VALUES.COMPLETE && (
            <>
              {!alertHasOrderActive && (
                <Button
                  onClick={onComplete(data)}
                  color={ButtonColors.DEFAULT}
                  type="ghost"
                  leftIcon={<CarryOutFilled />}
                  loading={!!actionLoading[data?.id]}
                >
                  Mark as Complete
                </Button>
              )}
              {hasPrice && (
                <Button
                  onClick={onStartOrder(data)}
                  color={ButtonColors.BLUE}
                  type="primary"
                  htmlType="button"
                  leftIcon={<PlusCircleOutlined />}
                >
                  Order
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.information}>
          <span>{data?.message || '-'}</span>
          <span>
            <span>Due Date</span>
            <br />
            {data?.due_date
              ? getProfileDate(data.due_date.split('T')[0], true)
              : '-'}
          </span>
          {hasPrice && (
            <span>
              <span>Price</span>
              <br />${data?.price}
            </span>
          )}
        </div>
        {!hideChecklist && (
          <Checklist
            data={data?.checklists || []}
            onNew={handleNewChecklist(data)}
            onOrder={handleOrderChecklist(data)}
            onRemove={handleRemoveChecklist(data)}
            onUpdate={handleUpdateChecklist(data)}
            onCheckedChange={handleCheckedChange(data)}
            addChecklistLoading={addChecklistLoading}
            updateChecklistLoading={updateChecklistLoading}
            names={{
              list: 'checklists',
              description: 'description',
              isChecked: 'is_checked',
            }}
            title={<h3 className={classes.checklistTitle}>Checklist</h3>}
            className={classes.checklists}
          />
        )}
      </div>
    </div>
  );
};

export default ReminderCard;
