import pick from 'lodash/pick';
import {
  getFileFieldInitialValues,
  standardRequiredFieldObj,
} from 'utils/form';
import { parseStringToNumber } from 'utils/number';
import { getMomentDate, getBEDate } from 'utils/date';
import {
  NORMAL_FIELDS,
  NULL_FIELDS,
  DATE_FIELDS,
  FILE_FIELDS,
  NUMBER_FIELDS,
  BODY_REQUEST_NORMAL_FIELDS,
  BOOLEAN_FIELDS,
  IS_SAME_ADDRESS_FIELD,
  MAILING_ADDRESS_FIELDS,
} from './constants';

const getFileField = field => {
  if (!field) return '';
  return field.replace('documents.', '');
};
export const validateObj = () => {
  return {
    address: [standardRequiredFieldObj],
  };
};
export const getInitialValues = data => {
  const initialValues = {
    delete_attachments: [],
  };
  [...NORMAL_FIELDS, ...NULL_FIELDS].map(field => {
    const eValue = data[field];
    initialValues[field] = eValue || (NULL_FIELDS.includes(field) ? null : '');
    return true;
  });
  DATE_FIELDS.map(field => {
    initialValues[field] = data[field]
      ? getMomentDate(data[field], false, true)
      : '';
    return true;
  });
  BOOLEAN_FIELDS.map(field => {
    initialValues[field] = data[field] ? [field] : [];
    return true;
  });

  return {
    ...initialValues,
    ...getFileFieldInitialValues(FILE_FIELDS, data, 'documents'),
  };
};
export const getLocationBR = (values = {}, id) => {
  const { delete_attachments } = values;
  let isSameAddress = false;
  const bodyRequest = {
    ...pick(values, BODY_REQUEST_NORMAL_FIELDS),
  };
  if (id) {
    bodyRequest.business_location_id = id;
  }
  DATE_FIELDS.map(field => {
    bodyRequest[field] = values[field] ? getBEDate(values[field]) : '';
    return true;
  });
  NUMBER_FIELDS.map(field => {
    bodyRequest[field] = parseStringToNumber(values[field]);
    return true;
  });
  BOOLEAN_FIELDS.map(field => {
    bodyRequest[field] = values[field] && values[field].length > 0 ? 1 : 0;
    if (field === IS_SAME_ADDRESS_FIELD) {
      isSameAddress = bodyRequest[field];
    }
    return true;
  });
  if (isSameAddress) {
    MAILING_ADDRESS_FIELDS.map(field => {
      bodyRequest[field] = values[field.replace('mailing_', '')];
      return true;
    });
  }
  const fData = new FormData();
  Object.keys(bodyRequest).map(k => {
    fData.append(
      k,
      bodyRequest[k] || bodyRequest[k] === 0 ? bodyRequest[k] : ''
    );
    return true;
  });
  FILE_FIELDS.map(originKey => {
    const key = getFileField(originKey);
    if (values[key] && values[key].length > 0) {
      values[key].map(file => {
        if (file.originFileObj && !file.id) {
          fData.append(`${key}[]`, file.originFileObj);
        }
        return true;
      });
    } else {
      fData.append(key, '');
    }
    return true;
  });
  if (delete_attachments && delete_attachments.length > 0) {
    delete_attachments.map(id => {
      fData.append('delete_documents[]', id);
      return true;
    });
  }
  return fData;
};
