import React from 'react';
import cn from 'classnames';
import Empty from 'components/Empty';
import classes from './NotFound.module.scss';

const NotFound = ({ className, message }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <Empty type="image2">
        <span>{message}</span>
      </Empty>
    </div>
  );
};

export default NotFound;
