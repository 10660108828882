import React from 'react';
import { FilterOutlined } from '@ant-design/icons';
import cn from 'classnames';
import Dropdown from 'components/Dropdown';
import { ReactComponent as SortIcon } from 'assets/icons/sort.svg';
import classes from './Chat.module.scss';

const FilterIcons = ({
  hideFilter,
  filterMenus,
  isClientTab,
  isDefaultFilter,
  sortMenus,
  isDefaultSort,
}) => {
  return (
    <>
      {!hideFilter && (
        <div className={classes.icons}>
          <Dropdown
            trigger={['click']}
            menus={filterMenus}
            hideArrow
            className={cn({
              [classes.iconActive]: !isDefaultFilter,
            })}
            menuClassName={cn(classes.filterSortOverlay, {
              [classes.isClient]: isClientTab,
            })}
          >
            <FilterOutlined />
          </Dropdown>
          <Dropdown
            trigger={['click']}
            menus={sortMenus}
            hideArrow
            className={cn({
              [classes.iconActive]: !isDefaultSort,
            })}
            menuClassName={cn(classes.filterSortOverlay, classes.sortOverlay)}
          >
            <span className="ant-dropdown-trigger anticon anticon-last">
              <SortIcon className={classes.sortIcon} />
            </span>
          </Dropdown>
        </div>
      )}
    </>
  );
};

export default FilterIcons;
