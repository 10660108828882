import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import cn from 'classnames';
import { useModal } from 'components/Modal';
import { confirmModalBlueConfig, getConfirmModal } from 'utils/modal';
import { validateForm, scrollToErrorField } from 'utils/form';
import PageContent from 'components/PageContent';
import ROUTES from 'routes';
import Form, { useForm } from 'components/Form';
import { getUrlParamValueByKey, removeAllSearchParam } from 'utils/routes';
import Activity from 'views/pages/ClientDetail/components/Activity';
import OrderTypeName from './Name';
import Pricing from './Pricing';
import NewOrderTypeFields from './Fields';
import NewOrderTypeFooter from './Footer';
import NewOrderTypeHeader from './Header';
import LoadingSpin from './LoadingSpin';
import DetailTree from './components/DetailTree';
import OtherSection from './components/OtherSection';
import Checklist from './components/Checklist';
import InternalDocument from './components/InternalDocument';
import {
  validateObj,
  makeData,
  getBodyRequest,
  filterTreeData,
  getExistingDataFromTreeData,
  updatedTreeData,
  getInitialValues,
  orderTypeActionCb,
  displayErrMessage,
} from './utils';
import { REQUIRED, OPTIONAL } from './constants';
import { useIndexData, useActions } from './selectorData';
import classes from './NewOrderType.module.scss';

const NewOrderType = ({ isDetail, id }) => {
  const {
    orderTypeDetail,
    fieldCategories,
    orderTypeLogs,
    addOrderTypeNoteLoading,
    orderTypeLogsLoading,
  } = useIndexData();
  const { fields } = orderTypeDetail;
  const {
    getOrderTypeDetail,
    createOrderType,
    updateOrderType,
    deleteOrderType,
    getOrderTypeLogs,
    addOrderTypeNote,
    updateOrderTypeDocument,
  } = useActions();
  const history = useHistory();
  const [modal, contextHolder] = useModal();
  const confirmModal = getConfirmModal(modal, confirmModalBlueConfig);
  const [form] = useForm();
  const [haftKeys, setHaftKeys] = useState([]);
  const [checklistErrors, setChecklistErrors] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isActivityCollapsed, setIsActivityCollapsed] = useState(false);
  const isViewDetail = isDetail && !isEditing;
  const filteredTreeData = filterTreeData(cloneDeep(treeData), fields);
  const initialValues = getInitialValues(orderTypeDetail, isDetail);
  const initialOther = (orderTypeDetail.other_fields || []).map(field => ({
    ...field,
    field_name: field.name,
  }));
  const initTreeData = makeData(fieldCategories);

  const handleCancel = () => {
    if (isEditing) {
      setIsEditing(false);
      form.setFieldsValue({
        checklist: (orderTypeDetail.checklists || []).map(
          (checklist, index) => ({
            originId: checklist.id,
            id: index + 1,
            name: checklist.description,
          })
        ),
      });
      setInitTreeData();
    } else {
      history.push(ROUTES.DASHBOARD());
    }
  };
  const handleDelete = () => {
    deleteOrderType(id, (res, err) => {
      if (res) {
        history.push(ROUTES.DASHBOARD());
      } else {
        displayErrMessage(err);
      }
    });
  };
  const setInitTreeData = tTreeData => {
    const updatedTree = updatedTreeData(
      cloneDeep(tTreeData || makeData(fieldCategories)),
      fields
    );
    const {
      targetKeys: keys,
      halfKeys: hKeys,
      isAllRequired,
      isAllOptional,
    } = getExistingDataFromTreeData(
      filterTreeData(cloneDeep(updatedTree), fields),
      initTreeData,
      fields,
      fieldCategories
    );
    updatedTree[0].add = isAllRequired
      ? REQUIRED
      : isAllOptional
      ? OPTIONAL
      : null;
    setTreeData(updatedTree);
    setTargetKeys(keys);
    setHaftKeys(hKeys);
  };
  useEffect(() => {
    if (isDetail && id) {
      getOrderTypeDetail(id);
      getOrderTypeLogs(id);
      setChecklistErrors([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isDetail]);
  useEffect(() => {
    const editParam = getUrlParamValueByKey(history.location?.search, 'edit');
    if (editParam === 'true') {
      setIsEditing(true);
      removeAllSearchParam(history, 'edit');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (fieldCategories) {
      setTreeData(makeData(fieldCategories));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldCategories]);
  useEffect(() => {
    if (fields && fields.length > 0 && treeData && treeData.length > 0) {
      setInitTreeData(treeData);
    }
    if (form) {
      form.setFieldsValue(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTypeDetail]);
  return (
    <LoadingSpin>
      <div
        className={cn(classes.wrapper, {
          [classes.isDetail]: isDetail,
          [classes.isViewDetail]: isViewDetail,
          [classes.isActivityCollapsed]: isActivityCollapsed,
        })}
      >
        <NewOrderTypeHeader isDetail={isDetail} isEditing={isEditing} />
        <div className={classes.innerWrapper}>
          <PageContent
            initFullHeight
            className={cn(classes.mainContent, {
              [classes.hideNewActivity]: !isDetail,
            })}
          >
            <Form
              onFinish={values => {
                const fieldErrors = validateForm(validateObj(), values);
                if (fieldErrors.length > 0) {
                  form.setFields(fieldErrors);
                  scrollToErrorField();
                } else {
                  const { checklist } = values;
                  const emptyChecklists = checklist.filter(c => !c.name);
                  if (emptyChecklists.length > 0) {
                    setChecklistErrors(emptyChecklists.map(c => c.id));
                    scrollToErrorField();
                  } else {
                    const bodyRequest = getBodyRequest(
                      treeData,
                      targetKeys,
                      values,
                      haftKeys
                    );
                    if (isDetail && isEditing) {
                      updateOrderType(
                        id,
                        bodyRequest,
                        orderTypeActionCb(
                          (res, err) => {
                            if (res) {
                              getOrderTypeDetail(id);
                              getOrderTypeLogs(id);
                              setIsEditing(false);
                            } else {
                              displayErrMessage(err);
                            }
                          },
                          id,
                          values,
                          updateOrderTypeDocument
                        )
                      );
                    } else {
                      createOrderType(
                        bodyRequest,
                        orderTypeActionCb(
                          (res, err) => {
                            if (res) {
                              handleCancel();
                            } else {
                              displayErrMessage(err);
                            }
                          },
                          null,
                          values,
                          updateOrderTypeDocument
                        )
                      );
                    }
                  }
                }
              }}
              initialValues={initialValues}
              form={form}
              className={classes.formContentWrapper}
            >
              <div className={classes.formContent}>
                <OrderTypeName form={form} isViewDetail={isViewDetail} />
                <Pricing form={form} isViewDetail={isViewDetail} />
                <div className={classes.checklistInternalDocumentWrapper}>
                  <Checklist
                    form={form}
                    confirmModal={confirmModal}
                    isViewDetail={isViewDetail}
                    checklistErrors={checklistErrors}
                    setChecklistErrors={setChecklistErrors}
                  />
                  <InternalDocument
                    form={form}
                    isViewDetail={isViewDetail}
                    fileObjs={orderTypeDetail.internal_documentation}
                  />
                </div>
                {isViewDetail ? (
                  <>
                    <DetailTree treeData={filteredTreeData} />
                    <OtherSection
                      className={classes.otherSection}
                      form={form}
                      other={initialOther}
                      confirmModal={confirmModal}
                      isEdit={false}
                    />
                  </>
                ) : (
                  <>
                    <NewOrderTypeFields
                      confirmModal={confirmModal}
                      targetKeys={targetKeys}
                      setTargetKeys={setTargetKeys}
                      treeData={treeData}
                      setTreeData={setTreeData}
                      initTreeData={initTreeData}
                      haftKeys={haftKeys}
                      setHaftKeys={setHaftKeys}
                    />
                    <OtherSection
                      className={classes.otherSection}
                      form={form}
                      other={initialValues.other}
                      confirmModal={confirmModal}
                      isEdit={true}
                    />
                  </>
                )}
              </div>
              <NewOrderTypeFooter
                confirmModal={confirmModal}
                onCancel={handleCancel}
                targetKeys={targetKeys}
                isEditing={isEditing}
                isViewDetail={isViewDetail}
                setIsEditing={setIsEditing}
                orderTypeDetail={orderTypeDetail}
                onDelete={handleDelete}
              />
            </Form>
            {isDetail && (
              <Activity
                isCollapsed={isActivityCollapsed}
                setIsCollapsed={setIsActivityCollapsed}
                className={classes.activity}
                activityLog={orderTypeLogs}
                name={''}
                loading={addOrderTypeNoteLoading || orderTypeLogsLoading}
                onAddNote={(val, cb) => {
                  addOrderTypeNote(
                    id,
                    {
                      note: val,
                    },
                    (res, err) => {
                      if (res) {
                        if (cb) cb();
                        getOrderTypeLogs(id, (res1, err1) => {
                          if (!res1) {
                            displayErrMessage(err1);
                          }
                        });
                      } else {
                        displayErrMessage(err);
                      }
                    }
                  );
                }}
              />
            )}
          </PageContent>
        </div>
        {contextHolder}
      </div>
    </LoadingSpin>
  );
};

export default NewOrderType;
