export const deleteFieldFn = (form, index, cb) => {
  const values = form.getFieldsValue();
  const valueOther = values.other || [];
  const deleteObj = valueOther[index];
  const currentDeleteIds = values.delete_custom_fields || [];
  const newOther = valueOther.filter((o, i) => i !== index);
  form.setFieldsValue({
    other: newOther,
    delete_custom_fields: deleteObj.originId
      ? [...currentDeleteIds, deleteObj.originId]
      : currentDeleteIds,
  });
  if (cb) cb();
};
