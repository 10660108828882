import React from 'react';
import TopBarContent from 'layouts/Main/TopBarContent';
import PageHeader from 'components/PageHeader';

const Header = () => {
  const breadcrumbs = [
    {
      label: 'My Reports',
    },
  ];
  return (
    <TopBarContent>
      <PageHeader title={null} breadcrumbs={breadcrumbs} />
    </TopBarContent>
  );
};

export default Header;
