import React from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import { EditFilled, DeleteOutlined } from '@ant-design/icons';
import { Checkbox, Input } from 'components/FormFields';
import Button, { ButtonColors } from 'components/Button';
import { FormItem } from 'components/Form';
import classes from './ChecklistField.module.scss';

const getIsSubmitDisabled = ({
  form,
  listName,
  fieldIndex,
  fieldName,
  value,
}) => {
  const values = form.getFieldsValue();
  const fieldValue =
    get(values, `${listName}[${fieldIndex}].${fieldName}`) || '';
  return { isDisabled: !fieldValue.trim() || fieldValue === value, fieldValue };
};
const ChecklistField = ({
  className,
  name,
  form,
  value,
  isEdit,
  setIsEdit,
  listName,
  onSave,
  onDelete,
  disabled,
  loading,
  isChecked,
  setIsChecked,
}) => {
  const fieldIndex = name[0];
  const fieldName = name[1];
  return (
    <div className={cn(classes.wrapper, className)}>
      {!isEdit ? (
        <div className={classes.view}>
          <Checkbox
            label={value}
            checked={isChecked}
            onChange={e => {
              setIsChecked(e.target.checked);
            }}
          />
          {!disabled && (
            <div className={cn(classes.action, 'checklist-action')}>
              <EditFilled
                onClick={() => {
                  setIsEdit(!isEdit);
                }}
              />
              <DeleteOutlined onClick={onDelete} />
            </div>
          )}
        </div>
      ) : (
        <div>
          <FormItem name={name} label="">
            <Input
              name={name}
              form={form}
              size="medium"
              isBlue
              placeholder="Add an item"
              autoFocus
              onPressEnter={() => {
                const { isDisabled, fieldValue } = getIsSubmitDisabled({
                  form,
                  listName,
                  fieldIndex,
                  fieldName,
                  value,
                });
                if (!isDisabled) {
                  onSave(fieldValue, () => {
                    setIsEdit(false);
                  });
                }
              }}
            />
          </FormItem>
          <FormItem shouldUpdate className={classes.footerAction}>
            {() => {
              const { isDisabled, fieldValue } = getIsSubmitDisabled({
                form,
                listName,
                fieldIndex,
                fieldName,
                value,
              });
              return (
                <>
                  <Button
                    color={ButtonColors.BLUE}
                    htmlType="button"
                    disabled={isDisabled}
                    onClick={() => {
                      onSave(fieldValue, () => {
                        setIsEdit(false);
                      });
                    }}
                    loading={loading}
                  >
                    Save Changes
                  </Button>
                  <Button
                    htmlType="button"
                    color={ButtonColors.DEFAULT}
                    onClick={() => {
                      const values = form.getFieldsValue();
                      const newArr = [...values[listName]];
                      newArr[fieldIndex][fieldName] = value;
                      form.setFieldsValue({
                        [listName]: newArr,
                      });
                      setIsEdit(false);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              );
            }}
          </FormItem>
        </div>
      )}
    </div>
  );
};

export default ChecklistField;
