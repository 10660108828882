import Request from './request';

export const apiGetTeammateDetail = async id => {
  return Request.call({
    url: `/user/${id}`,
    method: 'GET',
  });
};
export const apiUpdateTeammate = async data => {
  return Request.call({
    url: `/user/edit-account`,
    method: 'POST',
    data,
  });
};
export const apiDoActionTeammate = async data => {
  return Request.call({
    url: `/user/update-status`,
    method: 'POST',
    data,
  });
};
