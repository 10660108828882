import React from 'react';
import { Progress as AntdProgress } from 'antd';
import cx from 'classnames';

import classes from './Progress.module.scss';

const Progress = props => {
  const { className, ...other } = props;
  return <AntdProgress className={cx(classes.wrapper, className)} {...other} />;
};

export default Progress;
