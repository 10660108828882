import React from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import Transfer from 'components/Transfer';
import { generateTree, flatten, getSelectedCount } from '../../utils';
import { ALL_NODE_KEY, DEFAULT_REQUIRED } from '../../constants';
import {
  getAddNodes,
  getAllChildRemove,
  getHalfCheckParent,
  getAllNodeChildren,
} from './utils';
import TreeBox from './TreeBox';

import classes from './TreeTransfer.module.scss';

const TreeTransfer = ({
  dataSource,
  targetKeys,
  onChange,
  onTreeDataChange,
  confirmModal,
  onSelectTree,
  availableSearch,
  setAvailableSearch,
  selectedSearch,
  setSelectedSearch,
  onHaftChange,
  haftKeys,
  initTreeData,
  onSelectAllWithSearch,
  expandKeys,
  setExpandKeys,
  ...restProps
}) => {
  const transferDataSource = flatten(dataSource);
  const onAction = (nodeData, isLeftSide, treeData, isDeSelect) => {
    const thisExpandKeys = [...expandKeys];
    const {
      node: { key, parent, add },
    } = nodeData || {};
    if (isLeftSide) {
      if (key === ALL_NODE_KEY) {
        if (availableSearch) {
          const sideTreeData = generateTree(
            dataSource,
            targetKeys,
            'left',
            null,
            (availableSearch || '').toLowerCase()
          );
          onSelectAllWithSearch(sideTreeData);
        } else {
          onTreeDataChange(
            nodeData.node,
            isDeSelect ? null : DEFAULT_REQUIRED,
            ALL_NODE_KEY
          );
          onHaftChange(ALL_NODE_KEY, true);
          if (!isDeSelect) {
            const parentKeys = treeData.map(t => t.key);
            setExpandKeys(parentKeys);
          }
        }
      } else {
        const addNodes = [];
        // Get all add nodes related to this node (including children)
        // get latest expand keys also
        getAddNodes(addNodes, nodeData, initTreeData, thisExpandKeys);
        // Get latest target keys
        const latestTargetKeys = [...targetKeys, ...addNodes];
        // Get parent half keys, fullchecked keys
        const { halfKeys, fullCheckedKeys } = getHalfCheckParent(
          parent,
          initTreeData,
          latestTargetKeys
        );
        // Update half keys
        onHaftChange(halfKeys, false, [...fullCheckedKeys, key]);
        // Update expand keys
        setExpandKeys(thisExpandKeys);
        // Update target keys
        onChange(latestTargetKeys);
        // Get children of this node (this way can use for checked at left side only)
        const thisCheckedNodes = get(nodeData, 'checkedNodes') || [];
        const thisCheckedNode = find(thisCheckedNodes, { key }) || {};
        // Update node data for required/optional radio
        onTreeDataChange(
          nodeData.node,
          add ? add : DEFAULT_REQUIRED,
          null,
          thisCheckedNode.children || []
        );
      }
    } else {
      const thisNodeData = [];
      // Get init node with children data
      getAllNodeChildren(thisNodeData, initTreeData, key);
      const nodeChildren =
        thisNodeData.length > 0 ? thisNodeData[0].children || [] : [];
      // Add key to remove nodes
      const removeNodes = [key];
      // Add all children node to remove nodes
      getAllChildRemove(removeNodes, nodeChildren);
      // First target keys to check for parents
      const latestTargetKeys = targetKeys.filter(k => !removeNodes.includes(k));
      // Get parent half keys, fullchecked keys, remove keys
      const {
        halfKeys,
        fullCheckedKeys,
        removeParentKeys,
      } = getHalfCheckParent(parent, initTreeData, latestTargetKeys);
      // Exclude ALL_NODE_KEY because we uncheck any node
      if (targetKeys.includes(ALL_NODE_KEY)) removeNodes.push(ALL_NODE_KEY);
      // Final target keys
      const finalTargetKeys = latestTargetKeys.filter(
        k => !removeParentKeys.includes(k)
      );
      // Update half keys
      onHaftChange(halfKeys, false, [
        ...fullCheckedKeys,
        ...removeParentKeys,
        ...removeNodes,
        key,
      ]);
      // Update target keys with final target keys
      onChange(finalTargetKeys);
      // Update node data for required/optional radio
      onTreeDataChange(nodeData.node, null, key, nodeChildren);
    }
  };

  return (
    <Transfer
      {...restProps}
      targetKeys={targetKeys}
      dataSource={transferDataSource}
      className={classes.treeTransfer}
      showSelectAll={false}
      selectedKeys={targetKeys}
    >
      {data => {
        const { direction, selectedKeys } = data || {};
        let selectedFieldsCount = 0;
        const isLeftSide = direction === 'left';
        const checkedKeys = [...selectedKeys, ...targetKeys];
        const searchValue = isLeftSide ? availableSearch : selectedSearch;
        const treeData = generateTree(
          dataSource,
          targetKeys,
          direction,
          null,
          (searchValue || '').toLowerCase()
        );
        if (!isLeftSide) {
          selectedFieldsCount = getSelectedCount(dataSource, targetKeys);
        }
        const treeProps = {
          isLeftSide,
          checkedKeys: {
            checked: checkedKeys.filter(k => !haftKeys.includes(k)),
            halfChecked: haftKeys,
          },
          treeData,
          onAction,
          onSelectTree,
          onTreeDataChange,
          direction,
          expandKeys,
          setExpandKeys,
        };
        const treeBoxProps = {
          isLeftSide,
          setAvailableSearch,
          setSelectedSearch,
          selectedFieldsCount,
          direction,
          treeProps,
        };
        return <TreeBox {...treeBoxProps} />;
      }}
    </Transfer>
  );
};

export default TreeTransfer;
