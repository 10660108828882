export const NEW_KEY = 'new_credit_report';
export const NORMAL_FIELDS = [
  'score',
  'no_of_accounts',
  'no_of_good_standing_accounts',
];
export const NULL_FIELDS = [];
export const NUMBER_FIELDS = [
  'score',
  'no_of_accounts',
  'no_of_good_standing_accounts',
];
export const DATE_FIELDS = ['date'];
export const FILE_FIELDS = ['documents.credit_report'];
export const BOOLEAN_FIELDS = [];
export const SECTION_FIELDS = [
  {
    parentName: 'pulls',
    fields: ['pull_type_id', 'date', 'other'],
  },
];
export const SECTION_NULL_FIELDS = ['pull_type_id'];
export const SECTION_NUMBER_FIELDS = [];
export const SECTION_DATE_FIELDS = ['date'];
export const BODY_REQUEST_NORMAL_FIELDS = [];
export const DEFAULT_TRUCK = {
  key: 'section 1',
  id: 10,
  label: '435',
  status: 'active',
};
export const DEFAULT_SECTIONS = [];

export const PROFILE_OBJ_KEYS = [
  {
    label: 'Credit Report',
    key: 'documents.credit_report',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Info',
    nameGroup: true,
    fields: [
      {
        label: 'Date',
        key: 'date',
        type: 'date',
      },
      {
        label: 'Score',
        key: 'score',
        type: 'value',
      },
    ],
  },
  {
    label: 'Number of Accounts',
    key: 'no_of_accounts',
    type: 'value',
  },
  {
    label: 'Number of Accounts in Good Standing',
    key: 'no_of_good_standing_accounts',
    type: 'value',
  },
];
