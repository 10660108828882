import React from 'react';

import { Checkbox } from 'components/FormFields';
import Space from 'components/Space';

const CheckboxComponent = props => {
  return (
    <>
      <Space direction={'vertical'}>
        <Checkbox />
        <Checkbox indeterminate checked={false} />
        <Checkbox checked />
      </Space>
      <Space direction={'vertical'}>
        <Checkbox disabled />
        <Checkbox indeterminate checked={false} disabled />
        <Checkbox checked disabled />
      </Space>
    </>
  );
};

export default CheckboxComponent;
