import React, { useState } from 'react';
import cn from 'classnames';
import {
  ClockCircleOutlined,
  CheckCircleFilled,
  DoubleRightOutlined,
} from '@ant-design/icons';
import Progress from 'components/Progress';
import Tooltip from 'components/Tooltip';
import { OrderStatus } from 'views/pages/ClientOrderDetail/components/Elements';
import { AvatarText } from 'views/pages/Chat/components/Elements';
import Avatar from 'components/Avatar';
import { getProfileDate } from 'utils/date';
import ChecklistPopover from '../ChecklistPopover';
import classes from './OrderCard.module.scss';

const OrderCard = ({
  className,
  order = {},
  onClick,
  innerRef,
  onStatusChange,
  onLoadChecklist,
  checklists,
  onAddChecklist,
  addChecklistLoading,
  checklistLoading,
  onCheckedChange,
  ...other
}) => {
  const [showChecklist, setShowChecklist] = useState(false);
  const totalChecklist = order?.total_checklists_count || 0;
  const completedChecklist = order?.completed_checklists_count || 0;
  const submittedOn = order?.submitted_on || '';
  const submittedByAvatar = order?.submitted_by_profile_pic || '';
  const submittedByName = order?.submitted_by_name || '';
  return (
    <div {...other} className={cn(classes.wrapper, className)} ref={innerRef}>
      <div className={classes.topContent} onClick={onClick}>
        <div className={classes.header}>
          <h2>{order?.company_name}</h2>
          {order?.is_rush_order && (
            <span>
              <ClockCircleOutlined />
              Expedited
            </span>
          )}
        </div>
        <div className={classes.info}>
          <div>{order?.order_type}</div>
          <div>{order?.order_number}</div>
          <div className={classes.requestedBy}>
            <span>
              Requested Date {submittedOn ? getProfileDate(submittedOn) : ''}
            </span>
            <Tooltip
              title={submittedByName}
              overlayClassName={classes.tooltip}
              placement="topLeft"
            >
              <div>
                {submittedByAvatar ? (
                  <Avatar src={submittedByAvatar} size="small" />
                ) : (
                  <AvatarText name={submittedByName} size="small" />
                )}
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <Progress
          percent={(completedChecklist / totalChecklist) * 100}
          showInfo={false}
          size="small"
        />
        <div className={classes.statusChecklist}>
          <OrderStatus
            status={order?.status}
            className={classes.statusTag}
            onStatusChange={onStatusChange}
          />
          <ChecklistPopover
            visible={showChecklist}
            setVisible={v => {
              if (v && onLoadChecklist) {
                onLoadChecklist(order);
              }
              setShowChecklist(v);
            }}
            checklists={checklists}
            onAddChecklist={onAddChecklist}
            onCheckedChange={onCheckedChange}
            addLoading={addChecklistLoading}
            checklistLoading={checklistLoading}
          >
            <span className={classes.checklist}>
              <CheckCircleFilled />
              <span>
                {completedChecklist} / {totalChecklist}
              </span>
              <DoubleRightOutlined />
            </span>
          </ChecklistPopover>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
