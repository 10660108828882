import { useReducerLoading, useReducerData } from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// UPLOAD_FILE
export const useSelectUploadFileLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.SHARED,
    REDUCER_ATTRIBUTES.SHARED.UPLOAD_FILE
  );
};
// VIN_DECODER
export const useSelectVinDecoderLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.SHARED,
    REDUCER_ATTRIBUTES.SHARED.VIN_DECODER
  );
};
// BANK_NAME
export const useSelectBankNameLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.SHARED,
    REDUCER_ATTRIBUTES.SHARED.BANK_NAME
  );
};
// STATES
export const useSelectStates = () => {
  return useReducerData(REDUCER_NAMES.SHARED, REDUCER_ATTRIBUTES.SHARED.STATES);
};
// FIELD_CATEGORIES
export const useSelectFieldCategories = () => {
  return useReducerData(
    REDUCER_NAMES.SHARED,
    REDUCER_ATTRIBUTES.SHARED.FIELD_CATEGORIES
  );
};
// POSITIONS
export const useSelectPositions = () => {
  return useReducerData(
    REDUCER_NAMES.SHARED,
    REDUCER_ATTRIBUTES.SHARED.POSITIONS
  );
};
// DEPARTMENTS
export const useSelectDepartments = () => {
  return useReducerData(
    REDUCER_NAMES.SHARED,
    REDUCER_ATTRIBUTES.SHARED.DEPARTMENTS
  );
};
// DEPARTMENTS_LIST
export const useSelectDepartmentsList = () => {
  return useReducerData(
    REDUCER_NAMES.SHARED,
    REDUCER_ATTRIBUTES.SHARED.DEPARTMENTS_LIST
  );
};
// SALES_DEPARTMENT_USERS
export const useSelectSalesDepartmentUsers = () => {
  return useReducerData(
    REDUCER_NAMES.SHARED,
    REDUCER_ATTRIBUTES.SHARED.SALES_DEPARTMENT_USERS
  );
};
// TRAILERS_LIST
export const useSelectTrailersList = () => {
  return useReducerData(
    REDUCER_NAMES.SHARED,
    REDUCER_ATTRIBUTES.SHARED.TRAILERS_LIST
  );
};
// DRIVERS_LIST
export const useSelectDriversList = () => {
  return useReducerData(
    REDUCER_NAMES.SHARED,
    REDUCER_ATTRIBUTES.SHARED.DRIVERS_LIST
  );
};
// EMPLOYEE_LIST
export const useSelectEmployeeList = () => {
  return useReducerData(
    REDUCER_NAMES.SHARED,
    REDUCER_ATTRIBUTES.SHARED.EMPLOYEE_LIST
  );
};
// CLIENT_LIST
export const useSelectClientList = () => {
  return useReducerData(
    REDUCER_NAMES.SHARED,
    REDUCER_ATTRIBUTES.SHARED.CLIENT_LIST
  );
};
// STATUS_LIST
export const useSelectStatusList = () => {
  return useReducerData(
    REDUCER_NAMES.SHARED,
    REDUCER_ATTRIBUTES.SHARED.STATUS_LIST
  );
};
// LEASORS_LIST
export const useSelectLeasorsList = () => {
  return useReducerData(
    REDUCER_NAMES.SHARED,
    REDUCER_ATTRIBUTES.SHARED.LEASORS_LIST
  );
};
// PAGINATION_CLIENT_LIST
export const useSelectPClientList = () => {
  return useReducerData(
    REDUCER_NAMES.SHARED,
    REDUCER_ATTRIBUTES.SHARED.PAGINATION_CLIENT_LIST
  );
};
// PAGINATION_ORDER_TYPE_LIST
export const useSelectPOrderTypeList = () => {
  return useReducerData(
    REDUCER_NAMES.SHARED,
    REDUCER_ATTRIBUTES.SHARED.PAGINATION_ORDER_TYPE_LIST
  );
};
// PAGINATION_EMPLOYEE_LIST
export const useSelectPEmployeeList = () => {
  return useReducerData(
    REDUCER_NAMES.SHARED,
    REDUCER_ATTRIBUTES.SHARED.PAGINATION_EMPLOYEE_LIST
  );
};
// ROLES
export const useSelectRoles = () => {
  return useReducerData(REDUCER_NAMES.SHARED, REDUCER_ATTRIBUTES.SHARED.ROLES);
};
// SYSTEM_CODES
export const useSelectSystemCodes = () => {
  return useReducerData(
    REDUCER_NAMES.SHARED,
    REDUCER_ATTRIBUTES.SHARED.SYSTEM_CODES,
    {}
  );
};
export const useSelectSCTitles = () => {
  return useSelectSystemCodes().titles || [];
};
export const useSelectSCProspectSources = () => {
  return useSelectSystemCodes().prospect_sources || [];
};
export const useSelectSCDotNumberStatus = () => {
  return useSelectSystemCodes().dot_number_status || [];
};
export const useSelectSCIndustries = () => {
  return useSelectSystemCodes().industries || [];
};
export const useSelectSCBusinessEntities = () => {
  return useSelectSystemCodes().business_entities || [];
};
export const useSelectSCClass = () => {
  return useSelectSystemCodes().class || [];
};
export const useSelectSCEndorsements = () => {
  return useSelectSystemCodes().endorsements || [];
};
export const useSelectSCRestrictions = () => {
  return useSelectSystemCodes().restrictions || [];
};
export const useSelectSCTruckTypes = () => {
  return useSelectSystemCodes().type_of_trucks || [];
};
export const useSelectSCTrailerTypes = () => {
  return useSelectSystemCodes().type_of_trailers || [];
};
export const useSelectSCOtherFieldTypes = () => {
  return useSelectSystemCodes().other_field_type || [];
};
export const useSelectSCGVWTypes = () => {
  return useSelectSystemCodes().gvw || [];
};
export const useSelectSCBankruptcyStatuses = () => {
  return useSelectSystemCodes().bankruptcy_status || [];
};
export const useSelectSCLienTypes = () => {
  return useSelectSystemCodes().lien_type || [];
};
export const useSelectSCEvictionTypes = () => {
  return useSelectSystemCodes().eviction_type || [];
};
export const useSelectSCTypeOfViolations = () => {
  return useSelectSystemCodes().type_of_violation || [];
};
export const useSelectSCCollateralTypes = () => {
  return useSelectSystemCodes().collateral_type || [];
};
export const useSelectSCIndustryTypes = () => {
  return useSelectSystemCodes().industry_type || [];
};
export const useSelectSCPullTypes = () => {
  return useSelectSystemCodes().pull_type || [];
};
export const useSelectSCReminderTriggerFields = () => {
  return useSelectSystemCodes().reminder_trigger_fields || [];
};
export const useSelectSCReminderRecurrence = () => {
  return useSelectSystemCodes().reminder_recurrence || [];
};
export const useSelectSCReminderFrequency = () => {
  return useSelectSystemCodes().reminder_frequency || [];
};
