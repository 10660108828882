import React from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { WarningOutlined } from '@ant-design/icons';
import PageHeader from 'components/PageHeader';
import ROUTES from 'routes';
import { getClientDate } from 'utils/date';
import toast from 'helper/toast';
import { ReactComponent as EllipsisSvg } from 'assets/icons/Ellipsis.svg';
import ProfilePicture from 'views/pages/MyProfile/ProfilePicture';
import TopBarContent from 'layouts/Main/TopBarContent';
import { ColumnActions } from 'components/Table';
import { STATUS_MAPPING } from 'helper/const';
import { actionCb } from 'utils/action';
import { getErrorMessageFromObj } from 'utils/object';
import PrimaryContact from './components/PrimaryContact';
import { Color } from './components/Elements';
import { useHeaderData, useActions } from './selectorData';
import classes from './ClientDetail.module.scss';

const Header = ({
  confirmModal,
  avatarFinalSrc,
  setAvatarFinalSrc,
  finalFile,
  setFinalFile,
  clientId,
  className,
  ...other
}) => {
  const history = useHistory();
  const isClientMode = !!other.clientMode;
  const { detailData, manageLogoLoading } = useHeaderData();
  const {
    manageLogo,
    getClientDetailLog,
    archiveClient,
    getClientDetail,
    unarchiveClient,
    deleteClient,
  } = useActions();
  const { legal_name, status, created_at } = detailData;
  const statusObj = STATUS_MAPPING[status] || {};
  const breadcrumbs = other.breadcrumbs || [
    {
      label: 'Client List',
      to: ROUTES.CLIENT_LIST(),
      color: 'blue',
    },
    {
      label: legal_name,
    },
  ];
  const handleManageLogo = (file, src, isRemove, cb) => {
    const fData = new FormData();
    if (!isRemove) {
      fData.append('company_logo', file);
    } else {
      fData.append('remove_company_logo', 1);
    }
    manageLogo(clientId, fData, (res, err) => {
      if (res) {
        if (cb) cb();
        setAvatarFinalSrc(src || '');
        getClientDetailLog({ id: clientId });
      } else {
        toast.error(getErrorMessageFromObj(err)[0]);
      }
    });
  };
  const isArchived = detailData?.status === 'archived';
  const actions = isArchived
    ? [
        {
          label: 'Unarchive',
          props: {
            onClick: () => {
              unarchiveClient(
                {
                  client_ids: [detailData.id],
                },
                actionCb(
                  () => {
                    toast.success('Client Activated Successfully');
                    getClientDetail({ id: clientId });
                  },
                  () => {}
                )
              );
            },
          },
        },
        {
          label: 'Permanently Delete',
          props: {
            onClick: () => {
              confirmModal(
                'Permanently Delete Client Record? ',
                <div>
                  Are you sure you want to permanently
                  <br />
                  delete this client record?
                </div>,
                () => {
                  deleteClient(
                    {
                      client_ids: [detailData.id],
                    },
                    actionCb(
                      () => {
                        toast.success('Client Deleted Successfully');
                        history.push('/client-list');
                      },
                      () => {}
                    )
                  );
                },
                {
                  type: 'confirm',
                  onCancel: () => {},
                  okText: 'Yes, Delete',
                  cancelText: 'No, Nevermind',
                  centered: true,
                  className: classes.deleteClientWarningModal,
                  icon: <WarningOutlined />,
                }
              );
            },
          },
        },
      ]
    : [
        {
          label: 'Archive',
          props: {
            onClick: () => {
              archiveClient(
                {
                  client_ids: [detailData.id],
                },
                actionCb(
                  () => {
                    toast.success('Client Archived Successfully');
                    getClientDetail({ id: clientId });
                  },
                  () => {}
                )
              );
            },
          },
        },
      ];

  return (
    <>
      <TopBarContent>
        <PageHeader title={null} breadcrumbs={breadcrumbs} />
      </TopBarContent>
      <div className={cn(classes.header, className)}>
        <div className={classes.headerTitle}>
          <ProfilePicture
            isEdit={true}
            confirmModal={confirmModal}
            avatarFinalSrc={avatarFinalSrc}
            setAvatarFinalSrc={setAvatarFinalSrc}
            finalFile={finalFile}
            setFinalFile={setFinalFile}
            onCropFile={handleManageLogo}
            alowAllFile
            hideButton
            loading={manageLogoLoading}
            onDelete={cb => {
              if (cb) cb();
              handleManageLogo(null, null, true);
            }}
          />
          <div className={classes.clientInfo}>
            <div className={classes.clientInfoTop}>
              <span>{other.clientName || legal_name}</span>
              <ColumnActions
                actions={actions}
                menuClassName={classes.actionBlockMenu}
                trigger={['click']}
              >
                <EllipsisSvg />
              </ColumnActions>
            </div>
            {!isClientMode && (
              <div>
                <Color color="blue">Client</Color>{' '}
                <span>as of {created_at ? getClientDate(created_at) : ''}</span>{' '}
                <Color color={statusObj.color || 'success'}>
                  {statusObj.label || ''}
                </Color>
              </div>
            )}
          </div>
        </div>
        <PrimaryContact
          className={classes.primaryContact}
          data={detailData?.primary_contact || {}}
        />
      </div>
    </>
  );
};

export default Header;
