import React from 'react';
import { parseStringToNumber } from 'utils/number';
import { standardRequiredFieldObj, phoneFieldObj } from 'utils/form';
import {
  FORM_VALIDATION_MESSAGES,
  FILE_SIZE_LIMIT,
  VALID_IMAGE_TYPE,
} from 'helper/const';

export const validateObj = () => {
  return {
    first_name: [
      {
        ...standardRequiredFieldObj,
        message: FORM_VALIDATION_MESSAGES.FIRST_NAME_REQUIRED,
      },
    ],
    last_name: [
      {
        ...standardRequiredFieldObj,
        message: FORM_VALIDATION_MESSAGES.LAST_NAME_REQUIRED,
      },
    ],
    phone_number: [
      {
        ...standardRequiredFieldObj,
        message: FORM_VALIDATION_MESSAGES.PHONE_REQUIRED,
      },
      phoneFieldObj,
    ],
  };
};

export const getInitialValues = data => {
  return {
    first_name: data.first_name || '',
    last_name: data.last_name || '',
    email: data.email || '',
    phone_number: data.phone_number || '',
    company_pin_number: data.pin || '',
    password: '',
    confirmPassword: '',
  };
};

export const getMyProfileBodyRequest = (
  values,
  finalFile,
  currentProfilePic,
  finalProfileSrc
) => {
  const bodyRequest = new FormData();
  Object.keys(values).map(k => {
    if (!['password', 'confirmPassword', 'email'].includes(k)) {
      const val =
        k === 'phone_number' ? parseStringToNumber(values[k]) : values[k];
      bodyRequest.append(k, val);
    }
    return true;
  });
  if (finalFile && finalFile.type && finalProfileSrc) {
    bodyRequest.append('profile_pic', finalFile);
    bodyRequest.append('remove_profile_pic', 0);
  } else if (currentProfilePic && !finalProfileSrc) {
    bodyRequest.append('remove_profile_pic', 1);
  }
  return bodyRequest;
};

export const isValidPicture = (file, confirmModal, classes) => {
  const size = file.size / 1024 / 1024;
  const fileLimit = FILE_SIZE_LIMIT.IMAGE;
  if (size <= fileLimit) {
    if (!VALID_IMAGE_TYPE.includes(file.type)) {
      confirmModal(
        'Upload Failed',
        <div>File format not supported. Please upload JPG and PNG.</div>,
        () => {},
        {
          type: 'error',
          centered: true,
          className: classes.myProfileWarningModal,
        }
      );
    } else {
      return true;
    }
  } else {
    confirmModal(
      'Upload Failed',
      <div>File size cannot exceed {fileLimit}MB. Please try again.</div>,
      () => {},
      {
        type: 'error',
        centered: true,
        className: classes.myProfileWarningModal,
      }
    );
  }
  return false;
};
