import React from 'react';
import get from 'lodash/get';
import NewReminder from 'views/pages/NewReminder';

const ReminderDetail = ({ match }) => {
  const reminderId = get(match, 'params.id');
  return <NewReminder reminderId={reminderId} />;
};

export default ReminderDetail;
