import React from 'react';
import {
  SearchOutlined,
  DeleteOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import Button, { ButtonColors } from 'components/Button';
import { Input } from 'components/FormFields';
import { applySearch } from 'utils';
import classes from './Reminders.module.scss';

const FilterActions = ({
  keyword,
  setKeyword,
  onSearch,
  selectedRows,
  onBulkDelete,
  allReminders,
  bulkDeleteLoading,
}) => {
  const previousSRIds = selectedRows.map(r => r.id);
  const previousSelectedIncludeCurrent =
    allReminders.filter(r => previousSRIds.includes(r.id)).length > 0;
  return (
    <div className={classes.tabContentHeader}>
      <Input
        size={'medium'}
        name="keyword"
        allowClear
        value={keyword}
        placeholder={`Search alerts`}
        onChange={e => {
          const value = e.target.value;
          setKeyword(value);
          if (!value) {
            onSearch('');
          } else {
            applySearch(value, val => {
              onSearch(val);
            });
          }
        }}
        prefix={<SearchOutlined />}
        isBlue
      />
      <div className={classes.actions}>
        <Button
          htmlType="button"
          color={
            selectedRows.length > 0 ? ButtonColors.BLUE : ButtonColors.DEFAULT
          }
          onClick={onBulkDelete}
          disabled={
            selectedRows.length === 0 || !previousSelectedIncludeCurrent
          }
          leftIcon={<DeleteOutlined />}
          className={classes.bulkDeleteButton}
          loading={bulkDeleteLoading}
        >
          Delete
        </Button>
        <span />
        <Button
          htmlType="button"
          color={ButtonColors.DEFAULT}
          iconOnly
          leftIcon={<MoreOutlined />}
        ></Button>
      </div>
    </div>
  );
};

export default FilterActions;
