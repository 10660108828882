import pick from 'lodash/pick';
import {
  NORMAL_FIELDS,
  NULL_FIELDS,
  BODY_REQUEST_NORMAL_FIELDS,
  YES_NO_FIELDS,
} from './constants';

export const validateObj = () => {
  return {};
};
export const getInitialValues = data => {
  const initialValues = {};
  [...NORMAL_FIELDS, ...NULL_FIELDS].map(field => {
    const eValue = data[field];
    initialValues[field] = eValue || (NULL_FIELDS.includes(field) ? null : '');
    if (YES_NO_FIELDS.includes(field) && (eValue || eValue === 0)) {
      initialValues[field] = String(eValue);
    }
    return true;
  });

  return {
    ...initialValues,
  };
};
export const getIFAAlertBR = (values = {}, id) => {
  const bodyRequest = {
    ...pick(values, BODY_REQUEST_NORMAL_FIELDS),
  };
  if (id) {
    bodyRequest.ifa_alert_id = id;
  }
  YES_NO_FIELDS.map(field => {
    const value = values[field];
    bodyRequest[field] = value ? parseInt(value, 10) : '';
    return true;
  });
  return bodyRequest;
};
