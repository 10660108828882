import React from 'react';
import cn from 'classnames';
import Spin from 'components/Spin';
import classes from './SecurityBlock.module.scss';

const SimpleView = ({ className, fields, data, deleteObj }) => {
  return (
    <div className={cn(classes.simpleView, className)}>
      <Spin
        className={classes.simpleViewLoading}
        spinning={!!deleteObj?.loading}
        icon
      />
      <ul>
        {(fields || []).map((field, index) => {
          const { label, name } = field;
          return (
            <li key={index}>
              <span>{label}</span>
              <span>{data[name]}</span>
            </li>
          );
        })}
      </ul>
      {!!deleteObj?.onClick && !!deleteObj?.text && (
        <div className={classes.deleteLink}>
          <span
            onClick={() => {
              if (!deleteObj.loading) {
                deleteObj.onClick();
              }
            }}
          >
            {deleteObj.text}
          </span>
        </div>
      )}
    </div>
  );
};

export default SimpleView;
