import cn from 'classnames';
import {
  standardRequiredFieldObj,
  confirmPasswordDontMatchFieldObj,
} from 'utils/form';
import classes from './RequiredUpdatePassword.module.scss';

export const validateObj = () => {
  return {
    password: [standardRequiredFieldObj],
    confirmPassword: [
      standardRequiredFieldObj,
      confirmPasswordDontMatchFieldObj,
    ],
  };
};

export const getModalProps = ({
  isOpen,
  form,
  handleSubmitForm,
  handleClose,
  loading,
  step,
}) => {
  return {
    isOpen,
    onSubmit: () => {
      const values = form.getFieldsValue();
      handleSubmitForm(values);
    },
    onClose: handleClose,
    title: '',
    maskClosable: false,
    closable: false,
    cancelText: 'Cancel',
    okText: step === 3 ? 'Done' : 'Reset Password',
    width: step === 1 ? 423 : 488,
    isSubmitLoading: loading,
    className: cn(classes.modal, classes[`step_${step}`]),
    closeIcon: null,
    blueColor: true,
    okBtnProps: {
      size: 'medium',
    },
    cancelBtnProps: {
      size: 'medium',
      type: 'default',
    },
    hideCancel: step === 1 || step === 3,
  };
};
