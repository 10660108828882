import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import ROUTES from 'routes';
import PageContent from 'components/PageContent';
import Form, { useForm } from 'components/Form';
import { validateForm, scrollToErrorField } from 'utils/form';
import { getUrlParamValueByKey, removeAllSearchParam } from 'utils/routes';
import { useOptions } from 'helper/hooks';
import { actionCb } from 'utils/action';
import LoadingSpin from './LoadingSpin';
import NewReminderHeader from './Header';
import NewReminderFooter from './Footer';
import Information from './Information';
import FormContent from './FormContent';
import { getInitialValues, validateObj, getBodyRequest } from './utils';
import { useActions, useIndexData } from './selectorData';
import classes from './NewReminder.module.scss';

const NewReminder = ({ reminderId }) => {
  const [isEdit, setIsEdit] = useState(false);
  const isDetail = !!reminderId;
  const history = useHistory();
  const {
    scReminderFrequencyOptions,
    scReminderTriggerFieldOptions,
    scReminderRecurrenceOptions,
  } = useOptions();
  const {
    getAllOrderTypesList,
    createReminder,
    getReminderDetail,
    changeReminderStatus,
    syncUpdateReminderdetailStatus,
    updateReminder,
  } = useActions();
  const { reminderDetail } = useIndexData();
  const [form] = useForm();
  const handleCancel = () => {
    if (isEdit) {
      setIsEdit(false);
    } else {
      history.push(ROUTES.REMINDERS());
    }
  };
  const handleStatusChange = () => {
    const newStatus =
      reminderDetail.status === 'active' ? 'inactive' : 'active';
    changeReminderStatus(
      [reminderDetail.id],
      newStatus,
      null,
      actionCb(() => {
        syncUpdateReminderdetailStatus(newStatus);
      })
    );
  };
  useEffect(() => {
    if (form && isEdit) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);
  useEffect(() => {
    getAllOrderTypesList();
    if (isDetail) {
      getReminderDetail(reminderId, () => {
        const isEdit = getUrlParamValueByKey(history.location?.search, 'edit');
        if (isEdit === 'true') {
          setIsEdit(true);
        }
        if (!!isEdit) {
          removeAllSearchParam(history, ['edit']);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingSpin>
      <div className={cn(classes.wrapper, {})}>
        <NewReminderHeader isDetail={isDetail} />
        <div className={classes.innerWrapper}>
          <PageContent initFullHeight className={cn(classes.mainContent, {})}>
            {isDetail && !isEdit ? (
              <>
                <Information />
                <NewReminderFooter
                  handleCancel={handleCancel}
                  isDetail={isDetail}
                  setIsEdit={setIsEdit}
                  isEdit={isEdit}
                  onStatusChange={handleStatusChange}
                  reminderId={reminderId}
                />
              </>
            ) : (
              <Form
                onFinish={values => {
                  const fieldErrors = validateForm(
                    validateObj(
                      scReminderTriggerFieldOptions,
                      scReminderRecurrenceOptions
                    ),
                    values
                  );
                  if (fieldErrors.length > 0) {
                    form.setFields(fieldErrors);
                    scrollToErrorField();
                  } else {
                    const br = getBodyRequest(
                      values,
                      scReminderFrequencyOptions,
                      scReminderRecurrenceOptions
                    );
                    if (isDetail) {
                      updateReminder(
                        reminderId,
                        {
                          ...br,
                          status: reminderDetail.status,
                        },
                        actionCb(() => {
                          setIsEdit(false);
                        })
                      );
                    } else {
                      createReminder(
                        br,
                        actionCb(() => {
                          history.push(ROUTES.REMINDERS());
                        })
                      );
                    }
                  }
                }}
                initialValues={getInitialValues(reminderDetail, isDetail)}
                form={form}
                className={classes.formContentWrapper}
              >
                <FormContent form={form} isDetail={isDetail} />
                <NewReminderFooter
                  handleCancel={handleCancel}
                  isDetail={isDetail}
                  setIsEdit={setIsEdit}
                  isEdit={isEdit}
                  onStatusChange={handleStatusChange}
                  reminderId={reminderId}
                />
              </Form>
            )}
          </PageContent>
        </div>
      </div>
    </LoadingSpin>
  );
};

export default NewReminder;
