import * as React from 'react';
import cn from 'classnames';
// import LogoPng from 'assets/img/Logo.png';
import { ReactComponent as LogoSvg } from 'assets/icons/Logo.svg';
import PublicLayoutWrapper from 'components/PublicLayoutWrapper';
import Card from 'components/Card';
import classes from './style.module.scss';

const AuthLayout = ({
  component: Component,
  isSSmall,
  isSmall,
  isRegister,
  isConfirmEmail,
  hasFooter,
  emptyPage,
  ...otherProps
}) => {
  return (
    <>
      {!emptyPage && (
        <div className={classes.logo}>
          {/* <img src={LogoPng} alt="logo" title="Road Office" /> */}
          <LogoSvg />
          Road Office
        </div>
      )}
      <PublicLayoutWrapper
        className={cn(classes.container, {
          [classes.isRegister]: isRegister,
          [classes.isConfirmEmail]: isConfirmEmail,
          [classes.hasFooter]: hasFooter,
          [classes.emptyPage]: emptyPage,
        })}
      >
        {emptyPage ? (
          <Component {...otherProps} />
        ) : (
          <Card
            className={cn(classes.authCard, {
              [classes.isSSmall]: isSSmall,
              [classes.isSmall]: isSmall,
              [classes.isRegister]: isRegister,
              [classes.isConfirmEmail]: isConfirmEmail,
            })}
          >
            <Component {...otherProps} />
          </Card>
        )}
        {hasFooter && (
          <div className={classes.authFooter}>
            2021 RoadOffice | PO BOX 546, Grand View, ID 83624
          </div>
        )}
      </PublicLayoutWrapper>
    </>
  );
};

export default AuthLayout;
