import React, { useEffect } from 'react';
import cn from 'classnames';
import { PlusCircleOutlined, UserOutlined } from '@ant-design/icons';
import { getUpdatedMembers, getDisplayMembers } from 'utils/chat';
import { startNewChatFn } from './actionUtils';
import { useActions, useStartNewChatData } from './selectorData';
import classes from './Chat.module.scss';

let addedMembers = true;
let removedMembers = true;

const StartNewChat = ({
  isCollapse,
  isStart,
  selectedContacts,
  isEditContact,
  setIsEditContact,
  setIsStart,
  setSelectedContacts,
  setIsNewContact,
  setDetailObj,
  detailObj,
  onResetKeyword,
  isClientTab,
  searchMemberAction,
  handleReloadConversationActions,
}) => {
  const { conversation_id } = detailObj || {};
  const {
    currentUser,
    internalConversations,
    clientConversations,
    members,
  } = useStartNewChatData();
  const existingChats = isClientTab
    ? clientConversations.individual || []
    : internalConversations.individual || [];
  const {
    checkConversation,
    createChatGroup,
    addMembers,
    removeMembers,
    getConversationDetail,
    getChatMembers,
  } = useActions();
  const finishedUpdateMembers = () => {
    if (addedMembers && removedMembers) {
      onResetKeyword();
      getChatMembers(conversation_id, () => {
        setIsEditContact(false);
      });
    }
  };
  const cbAfterStartChat = obj => {
    handleReloadConversationActions();
    onResetKeyword();
    setIsStart(false);
    setDetailObj(obj);
  };
  useEffect(() => {
    addedMembers = true;
    removedMembers = true;
  }, []);
  return (
    <div
      className={cn(classes.startNewChat, {
        [classes.isStart]: isStart,
      })}
    >
      {isStart && (
        <span
          className={classes.closeStart}
          onClick={() => {
            onResetKeyword();
            if (isEditContact) {
              setIsEditContact(false);
            } else {
              setIsStart(false);
            }
          }}
        >
          Cancel
        </span>
      )}
      <span
        onClick={() => {
          if (
            !isStart ||
            (isStart && selectedContacts.length > 0) ||
            isEditContact
          ) {
            if (isEditContact) {
              const { addMemberIds, removeMemberIds } = getUpdatedMembers(
                getDisplayMembers(members),
                selectedContacts,
                currentUser.id
              );
              if (addMemberIds.length > 0) {
                addedMembers = false;
                addMembers(
                  conversation_id,
                  {
                    members: addMemberIds,
                  },
                  () => {
                    addedMembers = true;
                    finishedUpdateMembers();
                  }
                );
              }
              if (removeMemberIds.length > 0) {
                removedMembers = false;
                removeMembers(
                  conversation_id,
                  {
                    members: removeMemberIds,
                  },
                  () => {
                    removedMembers = true;
                    finishedUpdateMembers();
                  }
                );
              }
            } else {
              if (!isStart) {
                searchMemberAction({
                  search: '',
                });
                setIsStart(true);
                setSelectedContacts([]);
                onResetKeyword();
              } else {
                startNewChatFn({
                  selectedContacts,
                  checkConversation,
                  existingChats,
                  cbAfterStartChat,
                  addMembers,
                  currentUser,
                  getConversationDetail,
                  setIsNewContact,
                  createChatGroup,
                  isClientTab,
                });
              }
            }
          }
        }}
        className={cn({
          [classes.disabled]:
            isStart && selectedContacts.length === 0 && !isEditContact,
        })}
      >
        {isEditContact ? (
          <>
            {!isCollapse && 'Update members'}
            <UserOutlined />
          </>
        ) : (
          <>
            {!isCollapse && 'Start a new chat'}
            <PlusCircleOutlined />
          </>
        )}
      </span>
    </div>
  );
};

export default StartNewChat;
