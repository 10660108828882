export const FILE_FIELDS = ['operating_agreement', 'w_9'];
// Fields for view mode
export const DOCUMENTS_OBJ_KEYS = [
  {
    label: 'Operating Agreement',
    key: 'operating_agreement',
    type: 'file',
  },
  {
    label: 'W-9',
    key: 'w_9',
    type: 'file',
  },
];
