const initialOrderTypeState = {
  // GET
  orderTypes: {
    loading: false,
    data: [],
    error: '',
  },
  allOrderTypes: {
    loading: false,
    data: [],
    totalNo: 0,
    isMore: false,
    error: '',
  },
  orderTypeDetail: {
    loading: false,
    data: {},
    error: '',
  },
  orderTypeLogs: {
    loading: false,
    data: [],
    error: '',
  },

  // POST
  createOrderType: {
    loading: false,
    data: {},
    error: '',
  },
  updateOrderType: {
    loading: false,
    data: {},
    error: '',
  },
  deleteOrderType: {
    loading: false,
    data: {},
    error: '',
  },
  addOrderTypeNote: {
    loading: false,
    data: {},
    error: '',
  },
  updateOrderTypeDocument: {
    loading: false,
    data: {},
    error: '',
  },
};

export { initialOrderTypeState };
