import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as underwritingSelectors from 'store/selectors/underwriting';
// ACTIONS
import * as clientDetailActions from 'store/actions/clientDetail';
import * as underwritingActions from 'store/actions/underwriting';

export const useIndexData = () => {
  return {
    businessAssociations: underwritingSelectors.useSelectBusinessAssociations(),
    updateBALoading: underwritingSelectors.useSelectUpdateUnderwritingRecordLoading(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...clientDetailActions,
    ...underwritingActions,
  });
};
