import React from 'react';
import cn from 'classnames';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  Title,
} from 'views/pages/ClientDetail/components/Elements';
import ClassTooltipContent from 'views/pages/ClientDetail/CompanyOfficial/components/ClassTooltipContent';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { useOptions } from 'helper/hooks';
import { LEASED_OBJ_KEYS, LEASED_OPTIONS } from '../../constants';
import classes from './Section.module.scss';

const Leased = ({ className, isEdit, form, data }) => {
  const { leasorOptions } = useOptions();

  return (
    <div className={cn(classes.leasedSection, className)}>
      <Title text="Leased" />
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            <InputItem
              name={'trailer_leased'}
              label="Trailer Leased?"
              form={form}
              type="select"
              options={LEASED_OPTIONS}
              labelTooltip={
                <ClassTooltipContent
                  title={'Trailer Leased?'}
                  description={
                    <>
                      Trailer owned by someone else and they
                      <br />
                      are running under your authority.
                    </>
                  }
                />
              }
            />
            <InputItem
              name={'trailer_leased_from_id'}
              label="Leasor"
              placeholder="Leasor"
              form={form}
              type="select"
              options={leasorOptions}
            />
          </FormSection>
        ) : (
          <ListInfo objKeys={LEASED_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default Leased;
