import React from 'react';
import cn from 'classnames';
import { FieldArrayBlock, ListInfo } from '../Elements';
import classes from './ViewListSection.module.scss';

const ViewListSection = ({
  className,
  fields,
  data,
  name,
  renderTitle,
  onStatusChange,
  changeStatusLoading,
  hideStatus,
}) => {
  const fieldValues = data[name] || [];
  return (
    <div className={cn(classes.wrapper, className)}>
      {fieldValues.map((fValue, index) => {
        return (
          <FieldArrayBlock
            title={renderTitle(fValue, index)}
            key={index}
            status={hideStatus ? 'active' : fValue.status}
            statusDropdown
            onStatusChange={nextStatus => {
              if (onStatusChange) onStatusChange(nextStatus, fValue.id);
            }}
            statusLoading={changeStatusLoading[fValue.id]}
            hideStatus={hideStatus}
          >
            <ListInfo objKeys={fields} data={fValue} />
          </FieldArrayBlock>
        );
      })}
    </div>
  );
};

export default ViewListSection;
