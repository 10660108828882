import Request from './request';

export const apiGetStates = async () => {
  return Request.call({
    url: '/states',
    method: 'GET',
  });
};
export const apiGetFieldCategories = async () => {
  return Request.call({
    url: '/field-categories',
    method: 'GET',
  });
};
export const apiGetPositions = async filters => {
  return Request.call({
    url: '/positions',
    method: 'GET',
    params: filters,
  });
};
export const apiGetDepartments = async filters => {
  return Request.call({
    url: '/group',
    method: 'GET',
    params: filters,
  });
};
export const apiGetDepartmentsList = async filters => {
  return Request.call({
    url: '/group/list',
    method: 'GET',
    params: filters,
  });
};
export const apiGetSystemCodes = async filters => {
  return Request.call({
    url: '/system-codes',
    method: 'GET',
    params: filters,
  });
};
export const apiGetDepartmentUsers = async filters => {
  return Request.call({
    url: '/user/dept/list',
    method: 'GET',
    params: filters,
  });
};
export const apiGetTrailersList = async companyId => {
  return Request.call({
    url: `/company/${companyId}/trailers`,
    method: 'GET',
  });
};
export const apiGetDriversList = async companyId => {
  return Request.call({
    url: `/company/${companyId}/drivers`,
    method: 'GET',
  });
};
export const apiGetLeasorsList = async companyId => {
  return Request.call({
    url: `/company/${companyId}/leasors/dropdown`,
    method: 'GET',
  });
};
export const apiGetRoles = async () => {
  return Request.call({
    url: `/roles`,
    method: 'GET',
  });
};

// POST
export const apiUploadFile = async data => {
  return Request.call({
    url: '/extract',
    method: 'POST',
    data,
  });
};
export const apiVinDecoder = async vinNumber => {
  return Request.call({
    url: '/vin-decoder',
    method: 'GET',
    params: {
      vin: vinNumber,
    },
  });
};
export const apiGetBankName = async routingNumber => {
  return Request.call({
    url: `/bank-name`,
    method: 'GET',
    params: {
      routing_number: routingNumber,
    },
  });
};
export const apiGetEmployeeList = async filters => {
  return Request.call({
    url: '/employees',
    method: 'GET',
    params: filters,
  });
};
export const apiGetClientList = async filters => {
  return Request.call({
    url: '/clients',
    method: 'GET',
    params: filters,
  });
};
export const apiGetStatusList = async filters => {
  return Request.call({
    url: '/status',
    method: 'GET',
    params: filters,
  });
};
export const apiGetPClientList = async filters => {
  return Request.call({
    url: '/clients/options-list',
    method: 'GET',
    params: filters,
  });
};
export const apiGetPOrderTypeList = async filters => {
  return Request.call({
    url: '/order-type/options-list',
    method: 'GET',
    params: filters,
  });
};
export const apiGetPEmployeeList = async filters => {
  return Request.call({
    url: '/employees/options-list',
    method: 'GET',
    params: filters,
  });
};
export const apiCreatePIN = async data => {
  return Request.call({
    url: '/encryption-pin',
    method: 'POST',
    data,
  });
};
export const apiGetDecryptData = async (pin, data) => {
  return Request.call({
    url: `/company/decrypt-attribute?pin=${pin}`,
    method: 'POST',
    data,
    seeError: true,
  });
};
