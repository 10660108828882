import {
  useReducerData,
  useReducerTotal,
  useReducerLoading,
  useReducerIsMore,
} from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// ALL_CLIENTS
export const useSelectAllClientsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT,
    REDUCER_ATTRIBUTES.CLIENT.ALL_CLIENTS
  );
};
export const useSelectAllClientsTotal = () => {
  return useReducerTotal(
    REDUCER_NAMES.CLIENT,
    REDUCER_ATTRIBUTES.CLIENT.ALL_CLIENTS,
    0
  );
};
export const useSelectAllClientsIsMore = () => {
  return useReducerIsMore(
    REDUCER_NAMES.CLIENT,
    REDUCER_ATTRIBUTES.CLIENT.ALL_CLIENTS
  );
};
export const useSelectAllClients = () => {
  return useReducerData(
    REDUCER_NAMES.CLIENT,
    REDUCER_ATTRIBUTES.CLIENT.ALL_CLIENTS
  );
};
// ARCHIVED_CLIENTS
export const useSelectArchivedClientsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT,
    REDUCER_ATTRIBUTES.CLIENT.ARCHIVED_CLIENTS
  );
};
export const useSelectArchivedClientsTotal = () => {
  return useReducerTotal(
    REDUCER_NAMES.CLIENT,
    REDUCER_ATTRIBUTES.CLIENT.ARCHIVED_CLIENTS,
    0
  );
};
export const useSelectArchivedClientsIsMore = () => {
  return useReducerIsMore(
    REDUCER_NAMES.CLIENT,
    REDUCER_ATTRIBUTES.CLIENT.ARCHIVED_CLIENTS
  );
};
export const useSelectArchivedClients = () => {
  return useReducerData(
    REDUCER_NAMES.CLIENT,
    REDUCER_ATTRIBUTES.CLIENT.ARCHIVED_CLIENTS
  );
};
// CREATE_CLIENT
export const useSelectCreateClientLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT,
    REDUCER_ATTRIBUTES.CLIENT.CREATE_CLIENT
  );
};
