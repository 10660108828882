import React from 'react';
import { getProfileDate } from 'utils/date';
import { hideTriggerInformation } from 'utils/reminder';
import { useInformationData } from './selectorData';
import classes from './NewReminder.module.scss';

const Information = () => {
  const { reminderDetail } = useInformationData();
  const isReminderWithDate = (reminderDetail.trigger_field || '')
    .toLowerCase()
    .includes('date');
  const reminderFrequency = reminderDetail.reminder_frequency || [];
  const reminderFrequencyValues =
    reminderDetail.reminder_frequency_values || [];
  const dueDateField = (
    <div>
      <span>Due Date</span>
      <span>
        {reminderDetail.due_date
          ? getProfileDate(reminderDetail.due_date, true)
          : '-'}
      </span>
    </div>
  );
  return (
    <div className={classes.information}>
      <h2 className={classes.mainTitle}>{reminderDetail.name}</h2>
      <div className={classes.topInformation}>
        <div>
          <span>Alert Name</span>
          <span>{reminderDetail.name}</span>
        </div>
        <div>
          <span>Message Displayed to Client</span>
          <span>{reminderDetail.message || '-'}</span>
        </div>
      </div>
      <div>
        <div>
          <span>Trigger Field</span>
          <span>{reminderDetail.trigger_field || '-'}</span>
        </div>
        <div>
          <span>Order Type</span>
          <span>{reminderDetail.order_type || '-'}</span>
        </div>
      </div>
      <div>
        {isReminderWithDate ? (
          <>
            <div>
              <span>When to Send Alerts</span>
              <span>
                {reminderFrequency.length === 0
                  ? '-'
                  : reminderFrequency.map(id => {
                      return (
                        <React.Fragment key={id}>
                          {reminderFrequencyValues[id]}
                          <br />
                        </React.Fragment>
                      );
                    })}
              </span>
            </div>
            {dueDateField}
          </>
        ) : !hideTriggerInformation(reminderDetail.trigger_field || '') ? (
          <>
            <div>
              <span>Initial Alert Date</span>
              <span>
                {reminderDetail.initial_reminder_date
                  ? getProfileDate(reminderDetail.initial_reminder_date, true)
                  : '-'}
              </span>
            </div>
            <div>
              <span>Recurrence</span>
              <span>{reminderDetail.recurrence || '-'}</span>
            </div>
          </>
        ) : (
          <>{dueDateField}</>
        )}
      </div>
      {!hideTriggerInformation(reminderDetail.trigger_field || '') && (
        <div>{dueDateField}</div>
      )}
    </div>
  );
};

export default Information;
