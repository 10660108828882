import React, { useState } from 'react';
import cn from 'classnames';
import StatusTag from 'views/pages/ClientDetail/CompanyOfficial/components/StatusTag';
import {
  CLIENT_ORDER_STATUS_MAPPING,
  CLIENT_ORDER_STATUS_OPTIONS,
} from 'helper/const';
import { getClientLogDateTime } from 'utils/date';
import Popover from 'components/Popover';
import classes from './Elements.module.scss';

export const Title = ({ className, subTitle, children }) => {
  return (
    <div className={cn(classes.title, className)}>
      <h3>{children}</h3>
      {!!subTitle && <span className={classes.subTitle}>{subTitle}</span>}
    </div>
  );
};
export const Status = ({ className, viewOnly, ...other }) => {
  return (
    <StatusTag
      {...other}
      className={cn(
        classes.statusTag,
        {
          [classes.viewOnly]: viewOnly,
        },
        className
      )}
      statusMapping={CLIENT_ORDER_STATUS_MAPPING}
      size="medium"
    />
  );
};
export const OrderStatus = ({
  className,
  statusLoading,
  status,
  onStatusChange = () => {},
  completedOn,
  viewOnly,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      content={
        <div className={classes.statusTagList}>
          <ul>
            {CLIENT_ORDER_STATUS_OPTIONS.map(orderStatus => {
              return (
                <li
                  key={orderStatus}
                  className={cn({
                    [classes.active]: status === orderStatus,
                  })}
                >
                  <Status
                    status={orderStatus}
                    loading={false}
                    onClick={() => {
                      setVisible(false);
                      onStatusChange(orderStatus);
                    }}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      }
      trigger="click"
      visible={visible}
      onVisibleChange={val => {
        if (!viewOnly) {
          setVisible(val);
        }
      }}
      placement="bottomLeft"
      overlayClassName={classes.statusOverlay}
      className={cn(classes.orderStatus, className)}
    >
      <div className={classes.statusEl}>
        <Status status={status} loading={statusLoading} viewOnly={viewOnly} />
        {status === 'completed' && !!completedOn && (
          <span className={classes.completedOn}>
            {getClientLogDateTime(completedOn)}
          </span>
        )}
      </div>
    </Popover>
  );
};
