import React from 'react';
import { BANK_NUMBER_FIELD } from 'helper/const';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import {
  Section,
  FormSection,
  ListInfo,
} from 'views/pages/ClientDetail/components/Elements';
import { TXDOT_OBJ_KEYS } from '../../tabConstants/general';

const TXDOT = ({ isEdit, form, data }) => {
  return (
    <Section>
      {isEdit ? (
        <FormSection>
          <InputItem
            name={'tx_dot_number'}
            label="TX DOT Number"
            form={form}
            placeholder="00000"
            type="number"
            mask={BANK_NUMBER_FIELD.MASK}
          />
          <div></div>
          <InputItem name={'tx_dot_username'} label="Username" form={form} />
          <InputItem name={'tx_dot_password'} label="Password" form={form} />
        </FormSection>
      ) : (
        <ListInfo objKeys={TXDOT_OBJ_KEYS} data={data} />
      )}
    </Section>
  );
};

export default TXDOT;
