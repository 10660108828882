import moment from 'moment';
import { DATE_TIME_FORMAT } from 'helper/const';

const isValidDate = val => {
  if (!val || typeof val !== 'string') return false;
  return val.split('-').length >= 3 && moment(val).isValid();
};
const cleanDTString = val => String(val || '').split('T')[0] || '';
const getMomentDate = (val, withoutTime, wtBEAndClean) => {
  if (!val) return '';
  if (wtBEAndClean) {
    const tVal = cleanDTString(val);
    if (!moment(tVal).isValid()) return '';
    return tVal ? moment(tVal) : '';
  }
  const tempMoment = moment(val).clone();
  if (!tempMoment.isValid()) return '';
  if (withoutTime) {
    return moment(new Date(val)).utcOffset(0);
  }
  // TODO: timezone
  // return moment(new Date(val)).tz(USER_TIMEZONE);
  return moment(new Date(val));
};
const getMomentDateWithFormat = (val, formatStr, withoutTime) => {
  if (!val || !formatStr) return '';
  const mDate = getMomentDate(val, withoutTime);
  return mDate ? mDate.format(formatStr) : mDate;
};
const getMomentDateWithTime = val => {
  if (!val) return '';
  return val.clone().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};

const getBEDate = (val, withoutTime = false, format = '') => {
  if (!val) return '';
  const mDate = getMomentDate(val, withoutTime);
  return mDate ? mDate.format(format || DATE_TIME_FORMAT.BE_DATE) : '';
};
const getFEDate = (val, withoutTime = false, format) => {
  if (!val) return '';
  const mDate = getMomentDate(val, withoutTime);
  return mDate ? mDate.format(format || DATE_TIME_FORMAT.FE_DATE) : '';
};
const getClientDate = (val, withoutTime = false) => {
  if (!val) return '';
  return getMomentDate(val, withoutTime)?.format(DATE_TIME_FORMAT.CLIENT_DATE);
};
const getProfileDate = (val, withoutTime = false) => {
  if (!val) return '';
  const mDate = getMomentDate(val, withoutTime);
  return mDate ? mDate.format(DATE_TIME_FORMAT.PROFILE_DATE) : '';
};
const getProfileDateTime = (val, withoutTime = false) => {
  if (!val) return '';
  const mDate = getMomentDate(val, withoutTime);
  return mDate ? mDate.format(DATE_TIME_FORMAT.PROFILE_DATE_TIME) : '';
};
const getTeammateProfileDateTime = (val, withoutTime = false) => {
  if (!val) return '';
  const mDate = getMomentDate(val, withoutTime);
  return mDate ? mDate.format(DATE_TIME_FORMAT.TEAMMATE_PROFILE_DATE_TIME) : '';
};
const getOrderLogDateTime = (val, withoutTime = false) => {
  if (!val) return '';
  const mDate = getMomentDate(val, withoutTime);
  return mDate ? mDate.format(DATE_TIME_FORMAT.ORDER_LOG_DATE_TIME) : '';
};
const getClientLogDateTime = (val, withoutTime = false) => {
  if (!val) return '';
  const mDate = getMomentDate(val, withoutTime);
  return mDate ? mDate.format(DATE_TIME_FORMAT.CLIENT_LOG_DATE_TIME) : '';
};
const getReminderTime = (val, withoutTime = false) => {
  if (!val) return '';
  const mDate = getMomentDate(val, withoutTime);
  return mDate ? mDate.format(DATE_TIME_FORMAT.REMINDER_TIME) : '';
};
const getChatMessageTime = (val, withoutTime = false) => {
  if (!val) return '';
  const mDate = getMomentDate(val, withoutTime);
  return mDate ? mDate.format(DATE_TIME_FORMAT.CHAT_MESSAGE_TIME) : '';
};
const getLatestMessageTime = (val, withoutTime = false) => {
  if (!val) return '';
  const today = getMomentDateWithTime(moment());
  const startOfWeek = getMomentDateWithTime(
    moment()
      .clone()
      .startOf('week')
  );
  const endOfWeek = getMomentDateWithTime(
    moment()
      .clone()
      .endOf('week')
  );
  const displayDay = getMomentDate(val, withoutTime).clone();
  const compareDay = getMomentDateWithTime(getMomentDate(val).clone());
  const isThisWeek =
    compareDay.isSameOrAfter(startOfWeek) &&
    compareDay.isSameOrBefore(endOfWeek);
  const isToday = today.isSame(compareDay);
  return isToday
    ? displayDay.format('h:mm A')
    : isThisWeek
    ? displayDay.format('dddd')
    : displayDay.format('MM/DD/YYYY');
};
const getOrderFEDayOfWeek = val => {
  if (!val) return '';
  const mDate = getMomentDate(val, true);
  return mDate ? mDate.format('dddd') : '';
};
const getNowMillisecond = () => {
  return new Date().getTime();
};
const compareDay = (first, second) => {
  const firstDay = getMomentDate(first).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const secondDay = getMomentDate(second).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  return firstDay.diff(secondDay, 'days');
};
const getDateFromMMYY = (val, splitChar = '/', isShort = false) => {
  if (!val) return '';
  const tVal = String(val);
  const valArr = tVal.includes('/') ? tVal.split('/') : [];
  if (valArr.length === 0 || !valArr[0] || !valArr[1]) return '';
  if (isShort) {
    return `${valArr[0]}${splitChar}20${valArr[1]}`;
  }
  const tDateStr = `${valArr[0]}${splitChar}${moment().format(
    'DD'
  )}${splitChar}20${valArr[1]}`;
  return tDateStr;
};
const getMomentDateStr = val => {
  if (!val || typeof val !== 'string') return '';
  // eslint-disable-next-line
  return JSON.stringify(moment(val)).replace(/\"/g, '');
};
const getLast5Years = () => {
  const today = moment();
  const thisYear = Number(today.format('YYYY'));
  const listYears = [thisYear];
  for (let i = 1; i < 6; i++) {
    listYears.push(thisYear - i);
  }
  return listYears;
};

export {
  isValidDate,
  getMomentDate,
  getBEDate,
  getFEDate,
  getProfileDateTime,
  getTeammateProfileDateTime,
  getOrderFEDayOfWeek,
  getNowMillisecond,
  getProfileDate,
  getOrderLogDateTime,
  getClientLogDateTime,
  getChatMessageTime,
  getLatestMessageTime,
  compareDay,
  getClientDate,
  getReminderTime,
  getMomentDateWithFormat,
  cleanDTString,
  getDateFromMMYY,
  getMomentDateStr,
  getLast5Years,
};
