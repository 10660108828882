import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as clientSelectors from 'store/selectors/client';
// ACTIONS
import * as clientActions from 'store/actions/client';
import * as clientDetailActions from 'store/actions/clientDetail';

export const useIndexData = () => {
  return {
    createClientLoading: clientSelectors.useSelectCreateClientLoading(),
  };
};
export const useAllClientsTabData = () => {
  return {
    allClientsLoading: clientSelectors.useSelectAllClientsLoading(),
    allClients: clientSelectors.useSelectAllClients(),
    allClientsTotal: clientSelectors.useSelectAllClientsTotal(),
    allClientsIsMore: clientSelectors.useSelectAllClientsIsMore(),
  };
};
export const useArchivedClientsTabData = () => {
  return {
    archivedClientsLoading: clientSelectors.useSelectArchivedClientsLoading(),
    archivedClients: clientSelectors.useSelectArchivedClients(),
    archivedClientsTotal: clientSelectors.useSelectArchivedClientsTotal(),
    archivedClientsIsMore: clientSelectors.useSelectArchivedClientsIsMore(),
  };
};
export const useLoadingSpinData = () => {
  return {};
};

export const useActions = () => {
  return useStoreActions({
    ...clientActions,
    ...clientDetailActions,
  });
};
