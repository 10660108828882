import { useReducerData, useReducerLoading } from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// LEASORS
export const useSelectLeasorsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.LEASOR,
    REDUCER_ATTRIBUTES.LEASOR.LEASORS
  );
};
export const useSelectLeasors = () => {
  return useReducerData(
    REDUCER_NAMES.LEASOR,
    REDUCER_ATTRIBUTES.LEASOR.LEASORS
  );
};
// LEASOR_ACCOUNT
export const useSelectLeasorAccountLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.LEASOR,
    REDUCER_ATTRIBUTES.LEASOR.LEASOR_ACCOUNT,
    {}
  );
};
export const useSelectLeasorAccount = () => {
  return useReducerData(
    REDUCER_NAMES.LEASOR,
    REDUCER_ATTRIBUTES.LEASOR.LEASOR_ACCOUNT,
    {}
  );
};
// LEASOR_FUEL_CARD
export const useSelectLeasorFuelCardLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.LEASOR,
    REDUCER_ATTRIBUTES.LEASOR.LEASOR_FUEL_CARD,
    {}
  );
};
export const useSelectLeasorFuelCard = () => {
  return useReducerData(
    REDUCER_NAMES.LEASOR,
    REDUCER_ATTRIBUTES.LEASOR.LEASOR_FUEL_CARD,
    {}
  );
};
// CREATE_LEASOR
export const useSelectCreateLeasorLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.LEASOR,
    REDUCER_ATTRIBUTES.LEASOR.CREATE_LEASOR
  );
};
// UPDATE_LEASOR
export const useSelectUpdateLeasorLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.LEASOR,
    REDUCER_ATTRIBUTES.LEASOR.UPDATE_LEASOR,
    {}
  );
};
// UPDATE_LEASOR_STATUS
export const useSelectUpdateLeasorStatusLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.LEASOR,
    REDUCER_ATTRIBUTES.LEASOR.UPDATE_LEASOR_STATUS,
    {}
  );
};
// CREATE_LEASOR_ACCOUNT
export const useSelectCreateLeasorAccountLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.LEASOR,
    REDUCER_ATTRIBUTES.LEASOR.CREATE_LEASOR_ACCOUNT,
    {}
  );
};
// UPDATE_LEASOR_ACCOUNT
export const useSelectUpdateLeasorAccountLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.LEASOR,
    REDUCER_ATTRIBUTES.LEASOR.UPDATE_LEASOR_ACCOUNT,
    {}
  );
};
// CREATE_LEASOR_FUEL_CARD
export const useSelectCreateLeasorFuelCardLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.LEASOR,
    REDUCER_ATTRIBUTES.LEASOR.CREATE_LEASOR_FUEL_CARD,
    {}
  );
};
// UPDATE_LEASOR_FUEL_CARD
export const useSelectUpdateLeasorFuelCardLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.LEASOR,
    REDUCER_ATTRIBUTES.LEASOR.UPDATE_LEASOR_FUEL_CARD,
    {}
  );
};
