import React from 'react';
import cn from 'classnames';
import { PaperClipOutlined, CloseCircleOutlined } from '@ant-design/icons';
import classes from './Elements.module.scss';

const SelectedAttachment = ({ name, className, onRemove = () => {} }) => {
  return (
    <div className={cn(classes.selectedAttachment, className)}>
      <PaperClipOutlined />
      <span className={classes.fileName}>{name}</span>
      <CloseCircleOutlined onClick={onRemove} className={classes.closeIcon} />
    </div>
  );
};

export default SelectedAttachment;
