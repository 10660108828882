import React from 'react';
import cn from 'classnames';
import classes from './BottomMark.module.scss';

const BottomMark = ({ className, text, onClick }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <span onClick={onClick}>{text}</span>
    </div>
  );
};

export default BottomMark;
