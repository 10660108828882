import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import { STATUS_ALL } from 'helper/const';
import {
  DISPLAY_SECTIONS,
  LOADING_MAPPING,
  SUBMIT_ACTION_MAPPING,
  GET_BR_MAPPING,
  SECTION_COUNT_MAPPING,
  SECTION_ITEM_LABEL_MAPPING,
  CUSTOM_EMPTY_SECTION_OBJ,
} from './constants';

export const editChangeFn = (
  editChanged,
  setEditChanged,
  setIsEditing,
  key,
  val,
  eKeys
) => {
  if (editChanged[key] !== val) {
    const newEditChanged = {
      ...editChanged,
      [key]: val,
    };
    setEditChanged(newEditChanged);
    let hasEditChanged = false;
    eKeys.map(k => {
      if (newEditChanged[k]) {
        hasEditChanged = true;
      }
      return true;
    });
    setIsEditing(hasEditChanged);
  }
};
export const filterSectionFn = (sections, search) => {
  const displaySections = DISPLAY_SECTIONS;
  return sections.filter(
    section =>
      displaySections.includes(section.key) &&
      (section.label || '').toLowerCase().includes(search.toLowerCase())
  );
};
const isCustomEmptySection = (sectionData, key) => {
  return isEqual(CUSTOM_EMPTY_SECTION_OBJ[key], sectionData);
};
export const getIsEmptySection = ({
  key,
  sectionData,
  DOTClassifications,
  ...otherCount
}) => {
  if (
    [
      'company_officials',
      'trailer_info',
      'truck_info',
      'company_driver_info',
      'leasor_info',
    ].includes(key)
  ) {
    const count = otherCount[SECTION_COUNT_MAPPING[key]] || 0;
    return {
      empty: !count,
      subTitle:
        count > 0
          ? `${count} ${key === 'company_driver_info' ? 'Active ' : ''}${
              SECTION_ITEM_LABEL_MAPPING[key]
            }${count > 1 ? 's' : ''}`
          : '',
    };
  }
  return {
    empty:
      'dot_classifications' === key
        ? isEmpty(DOTClassifications)
        : ['forms', 'filings', 'underwriting_info'].includes(key)
        ? isCustomEmptySection(sectionData, key)
        : isEmpty(sectionData),
    subTitle: '',
  };
};
export const getEditKeys = (editKeys, edit, key) => {
  return edit ? [...editKeys, key] : editKeys.filter(k => k !== key);
};
export const getIsKey = (keys, key) => {
  return keys.indexOf(key) > -1;
};
export const onKeyChangeFn = (
  keys,
  activeKeys,
  editKeys,
  setActiveKeys,
  expandCb
) => {
  const isCollapse = keys.length < activeKeys.length;
  const collapseKey = isCollapse
    ? activeKeys.filter(k => !keys.includes(k))[0] || ''
    : '';
  if (collapseKey) {
    if (!editKeys.includes(collapseKey)) {
      setActiveKeys(keys);
    }
  } else {
    const expandKey = difference(keys, activeKeys)[0];
    if (expandCb) expandCb(expandKey);
    setActiveKeys(keys);
  }
};
export const getLoading = (key, data) => {
  return data[LOADING_MAPPING[key]] || false;
};
export const submitFn = ({
  key,
  clientId,
  values,
  allClientRecordActions,
  cb,
  childTab,
  brFullKey,
}) => {
  // will use this condition to log BR data for testing
  // Incase you want to check BR before submitting api
  // Please use it for FormData BR only
  const logToCheckBRData = false;
  const action = allClientRecordActions[SUBMIT_ACTION_MAPPING[key]];
  const getBR = GET_BR_MAPPING[key];
  if (getBR && logToCheckBRData) {
    const fData = getBR(values, childTab, brFullKey);
    if (fData) {
      for (var pair of fData.entries()) {
        // log as error for checking code, please do not remove
        console.error(pair[0] + ', ' + pair[1]);
      }
    }
  }
  if (action) {
    action(
      clientId,
      getBR ? getBR(values, childTab, brFullKey) : {},
      cb,
      childTab
    );
  }
};
const getSectionFilter = (sectionStatus, key) => {
  const filters = {};
  const stt = sectionStatus[key] || 'active';
  if (stt !== STATUS_ALL) {
    filters.status = stt;
  }
  return filters;
};
export const sectionUpdatedCallback = (
  clientId,
  key,
  sectionStatus,
  actions
) => {
  const {
    getDrivers,
    getTrailers,
    getTrucks,
    getClientDetail,
    getClientDetailLog,
  } = actions;
  getClientDetail({ id: clientId, silent: true });
  getClientDetailLog({ id: clientId });

  switch (key) {
    case 'company_officials': {
      getDrivers(
        { id: clientId },
        getSectionFilter(sectionStatus, 'company_driver_info')
      );
      break;
    }
    case 'truck_info': {
      getTrailers(
        { id: clientId },
        getSectionFilter(sectionStatus, 'trailer_info')
      );
      getDrivers(
        { id: clientId },
        getSectionFilter(sectionStatus, 'company_driver_info')
      );
      break;
    }
    case 'trailer_info': {
      getTrucks(
        { id: clientId },
        getSectionFilter(sectionStatus, 'truck_info')
      );
      break;
    }
    case 'company_driver_info': {
      getTrucks(
        { id: clientId },
        getSectionFilter(sectionStatus, 'truck_info')
      );
      break;
    }
    default: {
      break;
    }
  }
};
export const getActiveTabName = (activeTab, availableTabs) => {
  return availableTabs?.[activeTab - 1]?.key;
};
