import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as orderTypeSelectors from 'store/selectors/orderType';
import * as sharedSelectors from 'store/selectors/shared';
// ACTIONS
import * as orderTypeActions from 'store/actions/orderType';

export const useIndexData = () => {
  return {
    orderTypeDetail: orderTypeSelectors.useSelectOrderTypeDetail(),
    orderTypeLogs: orderTypeSelectors.useSelectOrderTypeLogs(),
    addOrderTypeNoteLoading: orderTypeSelectors.useSelectAddOrderTypeNoteLoading(),
    orderTypeLogsLoading: orderTypeSelectors.useSelectOrderTypeLogsLoading(),
    fieldCategories: sharedSelectors.useSelectFieldCategories(),
  };
};
export const useLoadingSpinData = () => {
  return {
    detailLoading: orderTypeSelectors.useSelectOrderTypeDetailLoading(),
  };
};
export const useNameData = () => {
  return {
    orderTypeDetail: orderTypeSelectors.useSelectOrderTypeDetail(),
  };
};
export const useHeaderData = () => {
  return {
    orderTypeDetail: orderTypeSelectors.useSelectOrderTypeDetail(),
  };
};
export const useFooterData = () => {
  return {
    createLoading: orderTypeSelectors.useSelectCreateOrderTypeLoading(),
    updateLoading: orderTypeSelectors.useSelectUpdateOrderTypeLoading(),
    deleteLoading: orderTypeSelectors.useSelectDeleteOrderTypeLoading(),
    updateOrderTypeDocumentLoading: orderTypeSelectors.useSelectUpdateOrderTypeDocumentLoading(),
  };
};
export const usePricingData = () => {
  return {
    orderTypeDetail: orderTypeSelectors.useSelectOrderTypeDetail(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...orderTypeActions,
  });
};
