import React, { useState } from 'react';
import {
  DislikeOutlined,
  LikeOutlined,
  DislikeFilled,
  LikeFilled,
} from '@ant-design/icons';
import moment from 'moment';

import Avatar from 'components/Avatar';
import Tooltip from 'components/Tooltip';
import Space from 'components/Space';
import Comment, { CommentEditor } from 'components/Comment';

const CommentComponent = props => {
  const [action, setAction] = useState(null);

  const like = () => {
    setAction('liked');
  };

  const dislike = () => {
    setAction('disliked');
  };

  return (
    <Space direction={'vertical'}>
      <Comment
        actions={[
          <Tooltip key="comment-basic-like" title="Like">
            <span onClick={like}>
              {action === 'liked' ? <LikeFilled /> : <LikeOutlined />}
              <span className="comment-action">{1}</span>
            </span>
          </Tooltip>,
          <Tooltip key="comment-basic-dislike" title="Dislike">
            <span onClick={dislike}>
              {action === 'disliked' ? (
                <DislikeFilled />
              ) : (
                <DislikeOutlined className="anticon-dislike-outline" />
              )}
              <span className="comment-action">{2}</span>
            </span>
          </Tooltip>,
          <span key="comment-basic-reply-to">Reply to</span>,
        ]}
        // eslint-disable-next-line
        author={<a>Han Solo</a>}
        avatar={
          <Avatar
            src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
            alt="Han Solo"
          />
        }
        content={
          <p>
            We supply a series of design principles, practical patterns and high
            quality design resources (Sketch and Axure), to help people create
            their product prototypes beautifully and efficiently.
          </p>
        }
        datetime={
          <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
            <span>{moment().fromNow()}</span>
          </Tooltip>
        }
      />
      <Comment
        avatar={
          <Avatar
            src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
            alt="Han Solo"
          />
        }
        content={<CommentEditor submitting={false} value={''} />}
      />
    </Space>
  );
};

export default CommentComponent;
