import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { PlusCircleFilled } from '@ant-design/icons';
import PageHeader from 'components/PageHeader';
import PageContent from 'components/PageContent';
import TopBarContent from 'layouts/Main/TopBarContent';
import Tabs from 'components/Tabs';
import Button, { ButtonColors } from 'components/Button';
import Spin from 'components/Spin';
import ROUTES from 'routes';
import NotOrderTypesFound from './NotFound';
import AllOrderTypesTab from './AllTab';
import { getTheRestTabs } from './utils';
import { useIndexData } from './selectorData';
import classes from './OrderTypes.module.scss';

const getActiveTabObj = (tabs, activeTab) => {
  return tabs[activeTab - 1] || {};
};

const OrderTypes = () => {
  const { orderTypesLoading, orderTypes } = useIndexData();
  const [keyword, setKeyword] = useState('');
  const [tabSorts, setTabSorts] = useState({});
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(1);
  const isNotOrderTypes =
    orderTypes.length === 0 &&
    !orderTypesLoading &&
    activeTab === 1 &&
    !keyword &&
    isEmpty(tabSorts);
  const breadcrumbs = [
    {
      label: 'Order Types',
    },
  ];
  const tabs = [
    {
      title: <span>All Order Types</span>,
      content: (
        <AllOrderTypesTab
          keyword={keyword}
          setKeyword={setKeyword}
          onSortChange={newSort => {
            setTabSorts({
              ...tabSorts,
              1: newSort,
            });
          }}
          tabSorts={tabSorts}
          activeTab={activeTab}
        />
      ),
      buttonText: 'Create New Order Type',
      buttonIcon: <PlusCircleFilled />,
      buttonOnClick: () => {
        history.push(ROUTES.NEW_ORDER_TYPE());
      },
    },
    ...getTheRestTabs({
      activeTab,
      keyword,
      setKeyword,
      otherSettings: {
        buttonText: 'Create New Order Type',
        buttonIcon: <PlusCircleFilled />,
        buttonOnClick: () => {
          history.push(ROUTES.NEW_ORDER_TYPE());
        },
      },
      onSortChange: (thisTab, newSort) => {
        setTabSorts({
          ...tabSorts,
          [thisTab]: newSort,
        });
      },
      tabSorts,
    }),
  ];
  const activeTabObj = getActiveTabObj(tabs, activeTab);

  return (
    <Spin spinning={false} tip={'Loading...'}>
      <div className={classes.wrapper}>
        <TopBarContent>
          <PageHeader title={null} breadcrumbs={breadcrumbs} />
        </TopBarContent>
        <PageContent
          initFullHeight
          noBackground
          noPadding
          className={cn(classes.mainContent, {
            [classes.hideTabs]: isNotOrderTypes,
          })}
        >
          <Tabs
            tabs={tabs}
            activeKey={activeTab.toString()}
            onChange={activeKey => {
              const iActiveKey = parseInt(activeKey, 10);
              setActiveTab(iActiveKey);
            }}
            spaceTop
          />
          {!!activeTabObj.buttonText && (
            <div className={classes.tabHeaderButton}>
              <Button
                type="primary"
                size="large"
                className={classes.addCButton}
                onClick={activeTabObj.buttonOnClick}
                color={ButtonColors.BLUE}
                leftIcon={activeTabObj.buttonIcon}
                largeIcon
              >
                {activeTabObj.buttonText}
              </Button>
            </div>
          )}
          {isNotOrderTypes && (
            <NotOrderTypesFound
              onClick={() => {
                history.push(ROUTES.NEW_ORDER_TYPE());
              }}
            />
          )}
        </PageContent>
      </div>
    </Spin>
  );
};

export default OrderTypes;
