import React from 'react';
import cx from 'classnames';
import { DownloadOutlined } from '@ant-design/icons';

import Button from 'components/Button';

const ButtonComponent = ({ classes }) => {
  return (
    <div>
      {['solid', 'ghost'].map((mainType, mainTypeIndex) => {
        return (
          <div key={mainTypeIndex}>
            <h2>{mainType}</h2>
            {['large', 'medium', 'small'].map((size, sizeIndex) => {
              return (
                <div key={sizeIndex}>
                  <h2>{size}</h2>
                  {['none-icon', 'left-icon', 'right-icon', 'icon-only'].map(
                    (iconType, iconTypeIndex) => {
                      return (
                        <div key={iconTypeIndex}>
                          {[
                            'primary',
                            'outline',
                            'dashed',
                            'danger',
                            'disabled',
                          ].map((type, typeIndex) => {
                            return (
                              <div
                                className={cx(classes.buttonContainer, {
                                  [classes.background]:
                                    ['outline', 'dashed'].includes(type) &&
                                    mainType === 'ghost',
                                })}
                                key={typeIndex}
                              >
                                <Button
                                  type={type}
                                  size={size}
                                  ghost={mainType === 'ghost'}
                                  disabled={type === 'disabled'}
                                  leftIcon={
                                    ['left-icon', 'icon-only'].includes(
                                      iconType
                                    ) ? (
                                      <DownloadOutlined />
                                    ) : null
                                  }
                                  rightIcon={
                                    iconType === 'right-icon' ? (
                                      <DownloadOutlined />
                                    ) : null
                                  }
                                  iconOnly={iconType === 'icon-only'}
                                >
                                  {iconType === 'icon-only' ? '' : 'Button'}
                                </Button>
                              </div>
                            );
                          })}
                        </div>
                      );
                    }
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default ButtonComponent;
