import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  orderTypes,
} from '../types';
import get from 'lodash/get';
import { initialOrderState } from '../states/order';

export default (state = initialOrderState, action) => {
  switch (action.type) {
    // SYNC_UPDATE_ORDER_DETAIL_FIELDS
    case orderTypes.SYNC_UPDATE_ORDER_DETAIL_FIELDS: {
      return {
        ...state,
        orderDetailFields: {
          ...state.orderDetailFields,
          data: action.payload,
        },
      };
    }
    // SYNC_UPDATE_CLIENT_ORDER_NOTE
    case orderTypes.SYNC_UPDATE_CLIENT_ORDER_NOTE: {
      return {
        ...state,
        clientOrder: {
          ...state.clientOrder,
          data: {
            ...(state.clientOrder?.data || {}),
            order_notes: action.payload,
          },
        },
      };
    }
    // SYNC_UPDATE_CHECKLIST
    case orderTypes.SYNC_UPDATE_CHECKLIST: {
      return {
        ...state,
        clientOrder: {
          ...state.clientOrder,
          data: {
            ...(state.clientOrder?.data || {}),
            checklists: (get(state, 'clientOrder.data.checklists') || []).map(
              checklist => {
                if (checklist.id === action.payload?.id) {
                  checklist[action.payload?.attr] = action.payload?.value;
                }
                return checklist;
              }
            ),
          },
        },
      };
    }
    // SYNC_REMOVE_CHECKLIST
    case orderTypes.SYNC_REMOVE_CHECKLIST: {
      return {
        ...state,
        clientOrder: {
          ...state.clientOrder,
          data: {
            ...(state.clientOrder?.data || {}),
            checklists: (
              get(state, 'clientOrder.data.checklists') || []
            ).filter(checklist => checklist.id !== action.payload),
          },
        },
      };
    }
    // SYNC_ADD_NEW_CHECKLIST
    case orderTypes.SYNC_ADD_NEW_CHECKLIST: {
      return {
        ...state,
        clientOrder: {
          ...state.clientOrder,
          data: {
            ...(state.clientOrder?.data || {}),
            checklists: [
              ...(get(state, 'clientOrder.data.checklists') || []),
              action.payload,
            ],
          },
        },
      };
    }
    // SYNC_UPDATE_CLIENT_ORDER_STATUS
    case orderTypes.SYNC_UPDATE_CLIENT_ORDER_STATUS: {
      return {
        ...state,
        clientOrder: {
          ...state.clientOrder,
          data: {
            ...(state.clientOrder?.data || {}),
            status: action.payload,
          },
        },
      };
    }
    // GET_ORDER_REQUESTER_LIST
    case asyncActionTypeRequest(orderTypes.GET_ORDER_REQUESTER_LIST): {
      return {
        ...state,
        orderRequesters: {
          ...state.orderRequesters,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(orderTypes.GET_ORDER_REQUESTER_LIST): {
      return {
        ...state,
        orderRequesters: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(orderTypes.GET_ORDER_REQUESTER_LIST): {
      return {
        ...state,
        orderRequesters: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_ORDER_DETAIL_FIELDS
    case asyncActionTypeRequest(orderTypes.GET_ORDER_DETAIL_FIELDS): {
      return {
        ...state,
        orderDetailFields: {
          data: [],
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(orderTypes.GET_ORDER_DETAIL_FIELDS): {
      return {
        ...state,
        orderDetailFields: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(orderTypes.GET_ORDER_DETAIL_FIELDS): {
      return {
        ...state,
        orderDetailFields: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_ORDER_LIST
    case asyncActionTypeRequest(orderTypes.GET_ORDER_LIST): {
      return {
        ...state,
        orderList: {
          ...state.orderList,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(orderTypes.GET_ORDER_LIST): {
      const isMore = get(action, 'payload.isMore');
      const data = get(action, 'response.data.data') || [];
      const total = get(action, 'response.data.total') || 0;
      return {
        ...state,
        orderList: {
          data: isMore ? [...state.orderList.data, ...data] : data,
          totalNo: total || 0,
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(orderTypes.GET_ORDER_LIST): {
      return {
        ...state,
        orderList: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_CLIENT_ORDER_DETAIL
    case asyncActionTypeRequest(orderTypes.GET_CLIENT_ORDER_DETAIL): {
      return {
        ...state,
        clientOrderDetail: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(orderTypes.GET_CLIENT_ORDER_DETAIL): {
      return {
        ...state,
        clientOrderDetail: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(orderTypes.GET_CLIENT_ORDER_DETAIL): {
      return {
        ...state,
        clientOrderDetail: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // GET_CLIENT_ORDER
    case asyncActionTypeRequest(orderTypes.GET_CLIENT_ORDER): {
      return {
        ...state,
        clientOrder: {
          data: action.payload.silent ? state.clientOrder?.data || {} : {},
          error: '',
          loading: !action.payload.silent,
        },
      };
    }
    case asyncActionTypeSuccess(orderTypes.GET_CLIENT_ORDER): {
      return {
        ...state,
        clientOrder: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(orderTypes.GET_CLIENT_ORDER): {
      return {
        ...state,
        clientOrder: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // SAVE_ORDER_AS_DRAFT
    case asyncActionTypeRequest(orderTypes.SAVE_ORDER_AS_DRAFT): {
      return {
        ...state,
        saveOrderAsDraft: {
          ...state.saveOrderAsDraft,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(orderTypes.SAVE_ORDER_AS_DRAFT): {
      return {
        ...state,
        saveOrderAsDraft: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(orderTypes.SAVE_ORDER_AS_DRAFT): {
      return {
        ...state,
        saveOrderAsDraft: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // SUBMIT_ORDER
    case asyncActionTypeRequest(orderTypes.SUBMIT_ORDER): {
      return {
        ...state,
        submitOrder: {
          ...state.submitOrder,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(orderTypes.SUBMIT_ORDER): {
      return {
        ...state,
        submitOrder: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(orderTypes.SUBMIT_ORDER): {
      return {
        ...state,
        submitOrder: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // DELETE_ORDER
    case asyncActionTypeRequest(orderTypes.DELETE_ORDER): {
      return {
        ...state,
        deleteOrder: {
          ...state.deleteOrder,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(orderTypes.DELETE_ORDER): {
      return {
        ...state,
        deleteOrder: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(orderTypes.DELETE_ORDER): {
      return {
        ...state,
        deleteOrder: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // UPDATE_CLIENT_ORDER_STATUS
    case asyncActionTypeRequest(orderTypes.UPDATE_CLIENT_ORDER_STATUS): {
      return {
        ...state,
        updateClientOrderStatus: {
          ...state.updateClientOrderStatus,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(orderTypes.UPDATE_CLIENT_ORDER_STATUS): {
      return {
        ...state,
        updateClientOrderStatus: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(orderTypes.UPDATE_CLIENT_ORDER_STATUS): {
      return {
        ...state,
        updateClientOrderStatus: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    default:
      return state;
  }
};
