import React from 'react';
import PageHeader from 'components/PageHeader';
import TopBarContent from 'layouts/Main/TopBarContent';

const Header = ({ departmentName }) => {
  const breadcrumbs = [
    {
      label: `${departmentName || ''} Work Queue`,
    },
  ];
  return (
    <>
      <TopBarContent>
        <PageHeader title={null} breadcrumbs={breadcrumbs} />
      </TopBarContent>
    </>
  );
};

export default Header;
