import {
  apiDoActionTeammate,
  apiUpdateTeammate,
  apiGetTeammateDetail,
} from 'api/teammateDetail';

import { teammateDetailTypes } from '../types';

// GET_TEAMMATE_DETAIL
export const getTeammateDetail = (id, cb) => {
  return {
    isAsyncCall: true,
    baseType: teammateDetailTypes.GET_TEAMMATE_DETAIL,
    payload: {},
    asyncCall: () => {
      return apiGetTeammateDetail(id);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_TEAMMATE
export const updateTeammate = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: teammateDetailTypes.UPDATE_TEAMMATE,
    payload: {},
    asyncCall: () => {
      return apiUpdateTeammate(data);
    },
    afterSuccessCall: (dispatch, response) => {
      if (cb) cb(response);
      if (response?.data) {
        dispatch(updateTeammateDetail(response.data));
      }
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// DO_ACTION
export const doActionTeammate = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: teammateDetailTypes.DO_ACTION,
    payload: {},
    asyncCall: () => {
      return apiDoActionTeammate(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};

// CUSTOM
// SYNC_UPDATE_TEAMMATE_DETAIL
export const updateTeammateDetail = data => dispatch => {
  dispatch({
    type: teammateDetailTypes.SYNC_UPDATE_TEAMMATE_DETAIL,
    payload: data,
  });
};
