// import { FORMATS } from 'helper/const';

// Fields for initialValues
export const NORMAL_FIELDS = [
  'insurance_company',
  'policy_number',
  'username',
  'password',
  'annual_premium',
  'description',
  'trucks',
  'trailers',
  'agent_name',
  'agent_phone',
  'agent_email',
  'agent_fax',
];
export const FILE_FIELDS = [
  'documents.cert_of_previous_years',
  'documents.cert_of_current_year',
];
export const DATE_FIELDS = ['expiration_date'];
export const NUMBER_FIELDS = ['agent_phone', 'agent_fax'];
// Fields for body request
export const BODY_REQUEST_NORMAL_FIELDS = [
  'insurance_company',
  'policy_number',
  'username',
  'password',
  'annual_premium',
  'description',
  'trucks',
  'trailers',
  'agent_name',
  'agent_email',
];
// Fields for view mode
export const GENERAL_INFO_OBJ_KEYS = [
  {
    label: 'Insurance Company Name',
    key: 'insurance_company',
    type: 'value',
  },
  {
    label: 'Insurance Policy Number',
    key: 'policy_number',
    type: 'value',
    // type: 'formatValue',
    // format: FORMATS.POLICY_NUMBER,
  },
  {
    label: 'Username',
    key: 'username',
    type: 'value',
  },
  {
    label: 'Password',
    key: 'password',
    type: 'value',
  },
  // {
  //   label: 'Annual Premium',
  //   key: 'annual_premium',
  //   type: 'price',
  // },
  {
    label: 'Expiration Date',
    key: 'expiration_date',
    type: 'date',
  },
  {
    label: 'TruckTrailer',
    nameGroup: true,
    fields: [
      {
        label: 'Trucks',
        key: 'trucks',
        type: 'value',
      },
      {
        label: 'Trailers',
        key: 'trailers',
        type: 'value',
      },
    ],
  },
  {
    label: 'Description',
    key: 'description',
    type: 'value',
  },
  // {
  //   label: 'Cert. of Ins. for 5 Previous Years',
  //   key: 'documents.cert_of_previous_years',
  //   type: 'file',
  // },
  {
    label: 'Cert. of Ins. for Current Year',
    key: 'documents.cert_of_current_year',
    type: 'file',
  },
];
export const AGENT_INFO_OBJ_KEYS = [
  {
    label: 'Agent Name',
    key: 'agent_name',
    type: 'value',
  },
  {
    label: 'Agent Phone',
    key: 'agent_phone',
    type: 'formatValue',
  },
  {
    label: 'Agent Email',
    key: 'agent_email',
    type: 'value',
  },
  {
    label: 'Agent Fax',
    key: 'agent_fax',
    type: 'formatValue',
  },
];
