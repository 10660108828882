import React from 'react';
import { FORMATS } from 'helper/const';

export const NEW_KEY = 'new_driver';
export const NORMAL_FIELDS = [
  'first_name',
  'middle_name',
  'last_name',
  'suffix',
  'phone',
  'ssn',
  'email',
  'address',
  'apt',
  'city',
  // 'county',
  'zip_code',
  'mailing_address',
  'mailing_apt',
  'mailing_city',
  'mailing_zip_code',
  'dl_number',
  'mc_first_name',
  'mc_last_name',
  'mvr_first_name',
  'mvr_last_name',
  'mc_title',
  'mvr_title',
  'class',
];
export const NULL_FIELDS = ['state_id', 'mailing_state_id', 'dl_state_id'];
export const NUMBER_FIELDS = ['phone', 'ssn'];
export const ARR_FIELDS = ['endorsements', 'restrictions'];
export const DATE_FIELDS = [
  'dob',
  'dl_expiration_date',
  'mc_expiration_date',
  'mc_date',
  'dq_expiration_date',
  'mvr_expiration_date',
  'mvr_date',
];
export const IS_SAME_ADDRESS_FIELD = 'is_same_as_physical';
export const FILE_FIELDS = [
  'documents.driver_license',
  'documents.medical_card',
  'documents.pre_emp_drug_test',
  'documents.other_drug_test',
  'documents.driver_query',
  'documents.dq_current_year',
  'documents.dq_previous_years',
  'documents.mvr_current_year',
  'documents.mvr_previous_years',
  'documents.employment_application',
  'documents.inquiry_to_previous_employers',
  'documents.certification_of_violations',
  'documents.w9',
  'documents.w2',
  'documents.1099',
  'documents.documents_other',
  'documents.employment_other',
];
export const BOOLEAN_FIELDS = [
  IS_SAME_ADDRESS_FIELD,
  'mc_verification',
  'mvr_verification',
];
export const YES_NO_FIELDS = [];
export const MAILING_ADDRESS_FIELDS = [
  'mailing_address',
  'mailing_apt',
  'mailing_city',
  // 'mailing_county',
  'mailing_zip_code',
];
export const BODY_REQUEST_NORMAL_FIELDS = [
  'first_name',
  'middle_name',
  'last_name',
  'suffix',
  'phone',
  'ssn',
  'email',
  'address',
  'apt',
  'city',
  // 'county',
  'zip_code',
  'mailing_address',
  'mailing_apt',
  'mailing_city',
  // 'mailing_county',
  'mailing_zip_code',
  'state_id',
  'mailing_state_id',
  'dl_state_id',
  'dl_number',
  'mc_title',
  'mvr_title',
  'mc_first_name',
  'mc_last_name',
  'mvr_first_name',
  'mvr_last_name',
  'class',
];
export const DEFAULT_SECTIONS = [];

export const PROFILE_OBJ_KEYS = [
  {
    label: 'Profile',
    addressGroup: true,
    fields: [
      {
        label: 'First Name',
        key: 'first_name',
        type: 'value',
      },
      {
        label: 'Middle Name',
        key: 'middle_name',
        type: 'value',
      },
      {
        label: 'Last Name',
        key: 'last_name',
        type: 'value',
      },
      {
        label: 'Suffix',
        key: 'suffix',
        type: 'value',
        empty: true,
      },
    ],
  },
  {
    label: 'Phone',
    key: 'phone',
    type: 'formatValue',
  },
  {
    label: 'Email',
    key: 'email',
    type: 'value',
  },
  {
    label: 'DOB - Date of Birth',
    key: 'dob',
    type: 'date',
  },
  {
    label: 'SSN - Social Security Number',
    key: 'ssn',
    type: 'formatValue',
    format: FORMATS.SSN,
  },
];
export const ADDRESSES_OBJ_KEYS = [
  {
    label: 'Physical Address',
    key: 'address',
    type: 'value',
  },
  {
    label: 'Apt.',
    key: 'apt',
    type: 'value',
    empty: true,
  },
  {
    label: 'Address',
    nameGroup: true,
    fields: [
      {
        label: 'City',
        key: 'city',
        type: 'value',
        fullWidth: true,
      },
    ],
  },
  {
    label: 'state_zipcode',
    nameGroup: true,
    fields: [
      {
        label: 'State',
        key: 'state',
        type: 'value',
      },
      {
        label: 'Zip Code',
        key: 'zip_code',
        type: 'value',
      },
    ],
  },
  {
    label: 'Mailing address same as physical address',
    key: 'is_same_as_physical',
    type: 'checkbox',
    fullWidth: true,
    dependField: 'is_same_as_physical',
    dependFn: val => !val,
  },
  {
    label: 'Mailing Address',
    key: 'mailing_address',
    type: 'value',
    dependField: 'is_same_as_physical',
    dependFn: val => !!val,
  },
  {
    label: 'Apt.',
    key: 'mailing_apt',
    type: 'value',
    empty: true,
    dependField: 'is_same_as_physical',
    dependFn: val => !!val,
  },
  {
    label: 'Address',
    nameGroup: true,
    dependField: 'is_same_as_physical',
    dependFn: val => !!val,
    fields: [
      {
        label: 'City',
        key: 'mailing_city',
        type: 'value',
        fullWidth: true,
      },
    ],
  },
  {
    label: 'mailing_state_zipcode',
    nameGroup: true,
    fields: [
      {
        label: 'State',
        key: 'mailing_state',
        type: 'value',
      },
      {
        label: 'Zip Code',
        key: 'mailing_zip_code',
        type: 'value',
      },
    ],
  },
];
export const EQUIPMENT_OBJ_KEYS = [
  {
    label: 'Assigned Truck',
    childLabel: 'Assigned Trailers',
    key: 'assigned_trucks',
    type: 'list',
    valueFn: val => val.truck_unit,
    childValueFn: val => {
      const trailers = val.assigned_trailers || [];
      if (trailers.length === 0) return 'None';
      return trailers.map((trailer, i) => {
        return (
          <React.Fragment key={i}>
            {trailer.trailer_unit}
            {i < trailers.length - 1 ? <br /> : ''}
          </React.Fragment>
        );
      });
    },
  },
];
export const DRIVER_LICENSE_OBJ_KEYS = [
  {
    label: 'License',
    nameGroup: true,
    fields: [
      {
        label: 'Driver’s License #',
        key: 'dl_number',
        type: 'value',
      },
      {
        label: 'State',
        key: 'dl_state',
        type: 'value',
      },
    ],
  },
  {
    label: 'Driver’s License',
    key: 'documents.driver_license',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Expiration Date',
    key: 'dl_expiration_date',
    type: 'date',
  },
  {
    label: 'Class',
    key: 'class',
    type: 'value',
  },
  {
    label: 'Endorsements',
    key: 'endorsements',
    type: 'multiValue',
  },
  {
    label: 'Restrictions',
    key: 'restrictions',
    type: 'multiValue',
  },
];
export const MEDICAL_CARD_OBJ_KEYS = [
  {
    label: 'Expiration Date',
    key: 'mc_expiration_date',
    type: 'date',
  },
  {
    label: 'Medical Card Certificate',
    key: 'documents.medical_card',
    type: 'file',
    isSingleFile: true,
  },
  {
    label:
      'I hearby verfiy that the medical examiner is listed on the National Registry of Certified Medical Examiners.',
    key: 'mc_verification',
    type: 'checkbox',
    fullWidth: true,
  },
  {
    label: 'Address',
    addressGroup: true,
    alignLeft: true,
    fields: [
      {
        label: 'First Name',
        key: 'mc_first_name',
        type: 'value',
      },
      {
        label: 'Last Name',
        key: 'mc_last_name',
        type: 'value',
      },
      {
        label: 'Title',
        key: 'mc_title',
        type: 'value',
      },
      {
        label: 'Date',
        key: 'mc_date',
        type: 'date',
      },
    ],
  },
];
export const DRUG_TESTS_OBJ_KEYS = [
  {
    label: 'Pre-Employment Drug Test',
    key: 'documents.pre_emp_drug_test',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Random Tests',
    key: 'documents.other_drug_test',
    type: 'file',
  },
];
export const EMPLOYMENT_APPLICATION_OBJ_KEYS = [
  {
    label: 'Employment Application',
    key: 'documents.employment_application',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Inquiry to Pervious Employers',
    key: 'documents.inquiry_to_previous_employers',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Driver’s Certification of Violations',
    key: 'documents.certification_of_violations',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Other',
    key: 'documents.employment_other',
    type: 'file',
    isSingleFile: true,
  },
];
export const DRIVER_QUERY_OBJ_KEYS = [
  {
    label: 'Driver Query Current Year',
    key: 'documents.dq_current_year',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Expiration Date',
    key: 'dq_expiration_date',
    type: 'date',
  },
  {
    label: 'Driver Query Previous Years',
    key: 'documents.dq_previous_years',
    type: 'file',
  },
];
export const MVR_OBJ_KEYS = [
  {
    label: 'MVR Current Year',
    key: 'documents.mvr_current_year',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Expiration Date',
    key: 'mvr_expiration_date',
    type: 'date',
  },
  {
    label:
      'I have hearby reviewed the driving record of the above named driver in accordance with §391.25 and find that the above named driver meets the minimum qualifications.',
    key: 'mvr_verification',
    type: 'checkbox',
    fullWidth: true,
  },
  {
    label: 'Address',
    addressGroup: true,
    alignLeft: true,
    fields: [
      {
        label: 'First Name',
        key: 'mvr_first_name',
        type: 'value',
      },
      {
        label: 'Last Name',
        key: 'mvr_last_name',
        type: 'value',
      },
      {
        label: 'Title',
        key: 'mvr_title',
        type: 'value',
      },
      {
        label: 'Date',
        key: 'mvr_date',
        type: 'date',
      },
    ],
  },
  {
    label: 'MVR Previous Years',
    key: 'documents.mvr_previous_years',
    type: 'file',
  },
];
export const DOCUMENTS_OBJ_KEYS = [
  {
    label: 'W9',
    key: 'documents.w9',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'W2',
    key: 'documents.w2',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: '1099',
    key: 'documents.1099',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Other',
    key: 'documents.documents_other',
    type: 'file',
    isSingleFile: true,
  },
];
export const BUSINESS_PERSONAL_OPTIONS = [
  {
    label: 'Business',
    value: 'Business',
  },
  {
    label: 'Personal',
    value: 'Personal',
  },
];
export const CREDIT_OR_DEBIT_OPTIONS = [
  {
    label: 'Credit',
    value: 'Credit',
  },
  {
    label: 'Debit',
    value: 'Debit',
  },
];
