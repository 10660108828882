import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  notificationTypes,
} from '../types';
import get from 'lodash/get';
import { initialNotificationState } from '../states/notification';

export default (state = initialNotificationState, action) => {
  switch (action.type) {
    // GET_UNREAD_NOTIFICATIONS
    case asyncActionTypeRequest(notificationTypes.GET_UNREAD_NOTIFICATIONS): {
      return {
        ...state,
        unreadNotifications: {
          ...state.unreadNotifications,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(notificationTypes.GET_UNREAD_NOTIFICATIONS): {
      return {
        ...state,
        unreadNotifications: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(notificationTypes.GET_UNREAD_NOTIFICATIONS): {
      return {
        ...state,
        unreadNotifications: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_READ_NOTIFICATIONS
    case asyncActionTypeRequest(notificationTypes.GET_READ_NOTIFICATIONS): {
      return {
        ...state,
        readNotifications: {
          ...state.readNotifications,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(notificationTypes.GET_READ_NOTIFICATIONS): {
      return {
        ...state,
        readNotifications: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(notificationTypes.GET_READ_NOTIFICATIONS): {
      return {
        ...state,
        readNotifications: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_NOTIFICATION_UNREAD_COUNT
    case asyncActionTypeRequest(
      notificationTypes.GET_NOTIFICATION_UNREAD_COUNT
    ): {
      return {
        ...state,
        notificationUnreadCount: {
          ...state.notificationUnreadCount,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(
      notificationTypes.GET_NOTIFICATION_UNREAD_COUNT
    ): {
      return {
        ...state,
        notificationUnreadCount: {
          data: get(action, 'response.data') || 0,
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(
      notificationTypes.GET_NOTIFICATION_UNREAD_COUNT
    ): {
      return {
        ...state,
        notificationUnreadCount: {
          loading: false,
          error: action.payload,
          data: 0,
        },
      };
    }
    // READ_NOTIFICATION
    case asyncActionTypeRequest(notificationTypes.READ_NOTIFICATION): {
      return {
        ...state,
        readNotification: {
          ...state.readNotification,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(notificationTypes.READ_NOTIFICATION): {
      return {
        ...state,
        readNotification: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(notificationTypes.READ_NOTIFICATION): {
      return {
        ...state,
        readNotification: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // DELETE_NOTIFICATION
    case asyncActionTypeRequest(notificationTypes.DELETE_NOTIFICATION): {
      return {
        ...state,
        deleteNotification: {
          ...state.deleteNotification,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(notificationTypes.DELETE_NOTIFICATION): {
      return {
        ...state,
        deleteNotification: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(notificationTypes.DELETE_NOTIFICATION): {
      return {
        ...state,
        deleteNotification: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    default:
      return state;
  }
};
