import React from 'react';
import { MODAL } from 'helper/const';
import {
  standardRequiredFieldObj,
  emailFieldObj,
  phoneFieldObj,
} from 'utils/form';
import { parseStringToNumber } from 'utils/number';
import classes from './InviteUserModal.module.scss';

export const validateObj = () => {
  return {
    first_name: [standardRequiredFieldObj],
    last_name: [standardRequiredFieldObj],
    email: [standardRequiredFieldObj, emailFieldObj],
    phone_number: [phoneFieldObj],
    role_id: [standardRequiredFieldObj],
  };
};
export const getInitialValues = groupId => {
  return {
    first_name: '',
    mi: '',
    last_name: '',
    email: '',
    phone_number: '',
    role_id: null,
  };
};
export const getModalProps = ({
  isOpen,
  form,
  handleSubmitForm,
  handleClose,
  loading,
}) => {
  return {
    isOpen,
    onSubmit: () => {
      const values = form.getFieldsValue();
      handleSubmitForm(values);
    },
    onClose: handleClose,
    title: <>Invite User</>,
    maskClosable: false,
    closable: true,
    cancelText: 'Cancel',
    okText: 'Send Invite',
    width: MODAL.SIZE.SEMI_LARGE + 10,
    isSubmitLoading: loading,
    className: classes.modal,
    closeIcon: null,
    blueColor: true,
    okBtnProps: {
      size: 'medium',
    },
    cancelBtnProps: {
      size: 'medium',
      type: 'default',
    },
  };
};
export const getBR = values => {
  return {
    ...values,
    phone_number: parseStringToNumber(values.phone_number, true),
    is_internal_user_inviting: 1,
  };
};
