import {
  apiGetWorkQueueFilters,
  apiStoreWorkQueueFilters,
  apiMoveOrderToColumn,
  apiGetWorkQueueColumns,
  apiModifyColumn,
  apiDeleteColumn,
  apiGetWorkQueueCCColumns,
} from 'api';
import toast from 'helper/toast';
import { COMPLETED_WORK_QUEUE_NAME } from 'helper/const';
import { workQueueTypes } from '../types';

// SYNC_UPDATE_WORK_QUEUE_FILTER_SETTING
export const syncUpdateWorkQueueFilterSetting = (key, value) => dispatch => {
  dispatch({
    type: workQueueTypes.SYNC_UPDATE_WORK_QUEUE_FILTER_SETTING,
    payload: { key, value },
  });
};
// SYNC_ADD_WORK_QUEUE_COLUMN
export const syncAddWorkQueueColumn = data => dispatch => {
  dispatch({
    type: workQueueTypes.SYNC_ADD_WORK_QUEUE_COLUMN,
    payload: data,
  });
};
// SYNC_UPDATE_WORK_QUEUE_COLUMN
export const syncUpdateWorkQueueColumn = (id, key, value) => dispatch => {
  dispatch({
    type: workQueueTypes.SYNC_UPDATE_WORK_QUEUE_COLUMN,
    payload: { id, key, value },
  });
};

// GET_WORK_QUEUE_CC_COLUMNS
export const getWorkQueueCCColumns = ({ silent, isMore }, filters, cb) => {
  const { page, ...otherFilters } = filters || {};
  return {
    isAsyncCall: true,
    baseType: workQueueTypes.GET_WORK_QUEUE_CC_COLUMNS,
    payload: { silent, isMore },
    asyncCall: () => {
      return apiGetWorkQueueCCColumns(otherFilters, page);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_WORK_QUEUE_COLUMNS
export const getWorkQueueColumns = (
  { departmentId, silent, isMore },
  filters,
  cb
) => {
  const { page, ...otherFilters } = filters || {};
  const isCCWorkQueue = departmentId === COMPLETED_WORK_QUEUE_NAME.NORMAL;
  return {
    isAsyncCall: true,
    baseType: workQueueTypes.GET_WORK_QUEUE_COLUMNS,
    payload: { silent, isMore },
    asyncCall: () => {
      return isCCWorkQueue
        ? apiGetWorkQueueCCColumns(
            {
              ...otherFilters,
            },
            page
          )
        : apiGetWorkQueueColumns(departmentId, otherFilters, page);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_WORK_QUEUE_FILTER_SETTING
export const getWorkQueueFilterSetting = ({ departmentId, silent }, cb) => {
  return {
    isAsyncCall: true,
    baseType: workQueueTypes.GET_WORK_QUEUE_FILTER_SETTING,
    payload: { silent, departmentId },
    asyncCall: () => {
      return apiGetWorkQueueFilters(departmentId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// ADD_WORK_QUEUE_COLUMN
export const addWorkQueueColumn = (departmentId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: workQueueTypes.ADD_WORK_QUEUE_COLUMN,
    payload: {},
    asyncCall: () => {
      return apiModifyColumn(departmentId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_WORK_QUEUE_COLUMN
export const updateWorkQueueColumn = (departmentId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: workQueueTypes.UPDATE_WORK_QUEUE_COLUMN,
    payload: {},
    asyncCall: () => {
      return apiModifyColumn(departmentId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// DELETE_WORK_QUEUE_COLUMN
export const deleteWorkQueueColumn = (departmentId, columnId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: workQueueTypes.DELETE_WORK_QUEUE_COLUMN,
    payload: { columnId },
    asyncCall: () => {
      return apiDeleteColumn(departmentId, columnId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
      toast.success('Column deleted successfully.');
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
      toast.error(err?.message || `Default column can't be deleted.`);
    },
  };
};
// MOVE_WORK_QUEUE_ORDER_TO_COLUMN
export const moveWorkQueueOrderToColumn = (
  departmentId,
  columnId,
  orderId,
  settings,
  cb
) => {
  const { orderNumber, columnData, isMove, sourceColumnId } = settings || {};
  return {
    isAsyncCall: true,
    baseType: workQueueTypes.MOVE_WORK_QUEUE_ORDER_TO_COLUMN,
    payload: { columnData, sourceColumnId, targetColumnId: columnId, isMove },
    asyncCall: () => {
      return apiMoveOrderToColumn(departmentId, columnId, orderId, orderNumber);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// STORE_WORK_QUEUE_FILTER_SETTING
export const storeWorkQueueFilterSetting = (departmentId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: workQueueTypes.STORE_WORK_QUEUE_FILTER_SETTING,
    payload: {},
    asyncCall: () => {
      return apiStoreWorkQueueFilters(departmentId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
