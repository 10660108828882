import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import ROUTES from 'routes';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import Button, { ButtonColors } from 'components/Button';
import { onEditRow } from 'utils/table';
import { Input } from 'components/FormFields';
import { PAGINATION } from 'helper/const';
import { useMyTeamTabIds } from 'helper/hooks';
import { applySearch } from 'utils';
import { NotInitDataContent } from './components/Elements';
import { getMyTeamColumns } from './utils';
import { DEFAULT_SORT } from './constants';
import { useInActiveTabData } from './selectorData';
import classes from './MyTeam.module.scss';

const InActiveTab = props => {
  const history = useHistory();
  const {
    handleGetInactiveTeam,
    handleActive,
    activeTab,
    keyword,
    setKeyword,
    permission,
  } = props;
  const {
    inActiveTeamLoading,
    inActiveTeamTotal,
    inActiveTeam,
    inActiveTeamIsMore,
    doActionLoading,
  } = useInActiveTabData();
  const tabIds = useMyTeamTabIds();
  const [sort, setSort] = useState(DEFAULT_SORT);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const isInitEmpty =
    inActiveTeamTotal === 0 &&
    !inActiveTeamLoading &&
    !keyword &&
    currentPage === 1 &&
    isEqual(sort, DEFAULT_SORT);
  const handleView = record => {
    history.push(ROUTES.TEAMMATE_DETAIL({ id: record.id }));
  };
  const handleEdit = record => {
    history.push(`${ROUTES.TEAMMATE_DETAIL({ id: record.id })}?edit=true`);
  };
  const handleAction = record => {
    handleActive([record], () => {
      handleGetInactiveTeam(currentPage, sort, keyword);
    });
  };
  useEffect(() => {
    if (tabIds[activeTab - 1] === 0) {
      handleGetInactiveTeam(1, DEFAULT_SORT, keyword);
      setCurrentPage(1);
      setSort(DEFAULT_SORT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
  const columns = getMyTeamColumns(
    handleView,
    handleEdit,
    handleAction,
    permission
  );
  return (
    <div
      className={cn(classes.mainContentTab, {
        [classes.isInitEmpty]: isInitEmpty,
      })}
    >
      {isInitEmpty ? (
        <NotInitDataContent message="There are no inactive teammates" />
      ) : (
        <>
          <div className={classes.tabContentHeader}>
            <Input
              size={'medium'}
              name="keyword"
              value={keyword}
              placeholder={`Search teammates`}
              onChange={e => {
                const value = e.target.value;
                setKeyword(value);
                applySearch(value, val => {
                  setCurrentPage(1);
                  handleGetInactiveTeam(1, sort, val);
                });
              }}
              prefix={<SearchOutlined />}
              isBlue
            />
            <div className={classes.actions}>
              <Button
                htmlType="button"
                color={
                  selectedRows.length > 0
                    ? ButtonColors.BLUE
                    : ButtonColors.DEFAULT
                }
                onClick={() => {
                  if (selectedRows.length > 0) {
                    handleActive(selectedRows, () => {
                      setSelectedRows([]);
                      setSelectedRowKeys([]);
                      handleGetInactiveTeam(currentPage, sort, keyword);
                    });
                  }
                }}
                loading={doActionLoading && selectedRows.length > 0}
              >
                Activate
              </Button>
            </div>
          </div>
          <Table
            rowSelection={{
              selectedRowKeys,
              onChange: (sRowKeys, sRows) => {
                setSelectedRowKeys(sRowKeys);
                setSelectedRows(sRows);
              },
            }}
            columns={columns}
            data={inActiveTeam}
            rowKey="id"
            loading={inActiveTeamLoading}
            rowIsClick
            onRow={record => {
              return {
                onClick: event => {
                  onEditRow(event, () => {
                    history.push(ROUTES.TEAMMATE_DETAIL({ id: record.id }));
                  });
                },
              };
            }}
            showSorterTooltip={false}
            onSortChange={sorter => {
              const newSort = {
                field: sorter.field,
                order: sorter.order,
              };
              setSort(newSort);
              handleGetInactiveTeam(currentPage, newSort, keyword);
            }}
            locale={{
              emptyText: `No Inactive teammates found`,
            }}
          />
          {inActiveTeamIsMore && (
            <Pagination
              current={currentPage}
              pageSize={PAGINATION.PER_PAGE}
              total={inActiveTeamTotal}
              right
              onChange={page => {
                setCurrentPage(page);
                handleGetInactiveTeam(page, sort, keyword);
              }}
              className={classes.pagination}
            />
          )}
        </>
      )}
    </div>
  );
};

export default InActiveTab;
