import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import { onEditRow } from 'utils/table';
import { actionCb } from 'utils/action';
import { applySearch, applyFilter } from 'utils';
import toast from 'helper/toast';
import { PAGINATION } from 'helper/const';
import ROUTES from 'routes';
import { getClientColumns, getIsDefaultSearchFilter } from './utils';
import { DEFAULT_SORT, DEFAULT_ALL_FILTERS } from './constants';
import NotFound from './NotFound';
import FilterActions from './FilterActions';
import { useAllClientsTabData, useActions } from './selectorData';
import classes from './ClientList.module.scss';

let allFilters = { ...DEFAULT_ALL_FILTERS };

const AllDepartmentsTab = props => {
  const history = useHistory();
  const { handleGetAllClients, keyword, setKeyword, activeTab } = props;
  const {
    allClientsLoading,
    allClients,
    allClientsTotal,
    allClientsIsMore,
  } = useAllClientsTabData();
  const { archiveClient } = useActions();
  const [sort, setSort] = useState(DEFAULT_SORT);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDefaultFilter, setIsDefaultFilter] = useState(true);
  const handleView = record => {
    history.push(ROUTES.CLIENT_DETAIL({ id: record.id }));
  };
  const columns = getClientColumns(handleView);
  const isNotFound =
    getIsDefaultSearchFilter({ keyword, sort, isDefaultFilter }) &&
    allClientsTotal === 0 &&
    allClients.length === 0 &&
    !allClientsLoading;
  const handleAllFilterChange = (name, value) => {
    allFilters = {
      ...allFilters,
      [name]: value,
    };
    const applyFn = name === 'city' ? applySearch : applyFilter;
    applyFn(value, () => {
      handleGetAllClients(1, DEFAULT_SORT, keyword, allFilters);
      setCurrentPage(1);
      setIsDefaultFilter(isEqual(allFilters, DEFAULT_ALL_FILTERS));
    });
  };
  const filterActionProps = {
    keyword,
    setKeyword,
    onSearch: val => {
      handleGetAllClients(1, sort, val, allFilters);
      setCurrentPage(1);
    },
    selectedRows,
    handleAllFilterChange,
    isDefaultFilter,
    archiveLoading,
    onArchive: () => {
      setArchiveLoading(true);
      archiveClient(
        {
          client_ids: selectedRows.map(obj => obj.id),
        },
        actionCb(
          () => {
            toast.success('Client Archived Successfully');
            setArchiveLoading(false);
            handleGetAllClients(1, sort, keyword, allFilters);
            setCurrentPage(1);
            setSelectedRows([]);
          },
          () => {
            setArchiveLoading(false);
          }
        )
      );
    },
  };

  useEffect(() => {
    allFilters = { ...DEFAULT_ALL_FILTERS };
    if (activeTab === 1) {
      handleGetAllClients(1, DEFAULT_SORT, keyword, allFilters);
      setCurrentPage(1);
      setSort(DEFAULT_SORT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div
      className={cn(classes.mainContentTab, {
        [classes.isNotFound]: isNotFound,
      })}
    >
      {isNotFound ? (
        <NotFound onClick={() => {}} />
      ) : (
        <>
          <FilterActions {...filterActionProps} />
          <Table
            rowSelection={{
              selectedRowKeys,
              onChange: (sRowKeys, sRows) => {
                setSelectedRowKeys(sRowKeys);
                setSelectedRows(sRows);
              },
            }}
            columns={columns}
            data={allClients.map((client, index) => ({
              ...client,
              idIndex: `${client.id}_${index}`,
            }))}
            rowKey="idIndex"
            loading={allClientsLoading}
            rowIsClick
            isScroll
            scroll={{ x: 850 }}
            onRow={record => {
              return {
                onClick: event => {
                  onEditRow(event, () => {
                    handleView(record);
                  });
                },
              };
            }}
            showSorterTooltip={false}
            onSortChange={sorter => {
              const newSort = {
                field: sorter.field,
                order: sorter.order,
              };
              setSort(newSort);
              handleGetAllClients(currentPage, newSort, keyword, allFilters);
            }}
            locale={{
              emptyText: `No clients found`,
            }}
          />
          {allClientsIsMore && (
            <Pagination
              current={currentPage}
              pageSize={PAGINATION.PER_PAGE}
              total={allClientsTotal}
              right
              onChange={page => {
                setCurrentPage(page);
                handleGetAllClients(page, sort, keyword, allFilters);
              }}
              className={classes.pagination}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AllDepartmentsTab;
