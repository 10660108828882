import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as authSelectors from 'store/selectors/auth';
// ACTIONS
import * as authActions from 'store/actions/auth';

export const useIndexData = () => {
  return {
    createUserLoading: authSelectors.useSelectCreateUserLoading(),
    createClientAccountLoading: authSelectors.useSelectCreateClientAccountLoading(),
    validateClientTokenLoading: authSelectors.useSelectValidateClientTokenLoading(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...authActions,
  });
};
