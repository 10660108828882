import { useReducerData, useReducerLoading } from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// DRIVERS
export const useSelectDriversLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.DRIVER,
    REDUCER_ATTRIBUTES.DRIVER.DRIVERS
  );
};
export const useSelectDrivers = () => {
  return useReducerData(
    REDUCER_NAMES.DRIVER,
    REDUCER_ATTRIBUTES.DRIVER.DRIVERS
  );
};
// DRIVER_ACCOUNT
export const useSelectDriverAccountLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.DRIVER,
    REDUCER_ATTRIBUTES.DRIVER.DRIVER_ACCOUNT,
    {}
  );
};
export const useSelectDriverAccount = () => {
  return useReducerData(
    REDUCER_NAMES.DRIVER,
    REDUCER_ATTRIBUTES.DRIVER.DRIVER_ACCOUNT,
    {}
  );
};
// DRIVER_FUEL_CARD
export const useSelectDriverFuelCardLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.DRIVER,
    REDUCER_ATTRIBUTES.DRIVER.DRIVER_FUEL_CARD,
    {}
  );
};
export const useSelectDriverFuelCard = () => {
  return useReducerData(
    REDUCER_NAMES.DRIVER,
    REDUCER_ATTRIBUTES.DRIVER.DRIVER_FUEL_CARD,
    {}
  );
};
// CREATE_DRIVER
export const useSelectCreateDriverLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.DRIVER,
    REDUCER_ATTRIBUTES.DRIVER.CREATE_DRIVER
  );
};
// UPDATE_DRIVER
export const useSelectUpdateDriverLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.DRIVER,
    REDUCER_ATTRIBUTES.DRIVER.UPDATE_DRIVER,
    {}
  );
};
// UPDATE_DRIVER_STATUS
export const useSelectUpdateDriverStatusLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.DRIVER,
    REDUCER_ATTRIBUTES.DRIVER.UPDATE_DRIVER_STATUS,
    {}
  );
};
// CREATE_DRIVER_ACCOUNT
export const useSelectCreateDriverAccountLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.DRIVER,
    REDUCER_ATTRIBUTES.DRIVER.CREATE_DRIVER_ACCOUNT,
    {}
  );
};
// UPDATE_DRIVER_ACCOUNT
export const useSelectUpdateDriverAccountLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.DRIVER,
    REDUCER_ATTRIBUTES.DRIVER.UPDATE_DRIVER_ACCOUNT,
    {}
  );
};
// CREATE_DRIVER_FUEL_CARD
export const useSelectCreateDriverFuelCardLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.DRIVER,
    REDUCER_ATTRIBUTES.DRIVER.CREATE_DRIVER_FUEL_CARD,
    {}
  );
};
// UPDATE_DRIVER_FUEL_CARD
export const useSelectUpdateDriverFuelCardLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.DRIVER,
    REDUCER_ATTRIBUTES.DRIVER.UPDATE_DRIVER_FUEL_CARD,
    {}
  );
};
