import React from 'react';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { FormSection } from 'views/pages/ClientDetail/components/Elements';
import ListSection from '../ListSection';

const Judgements = ({ className, isEdit, form, data }) => {
  return (
    <ListSection
      className={className}
      isEdit={isEdit}
      form={form}
      data={data['judgements'] || []}
      title="Judgements"
      addBtnText="Add Judgement"
      parentName="judgements"
      fields={[
        {
          label: 'Lien Amount',
          key: 'amount',
          type: 'value',
        },
        {
          label: 'Name',
          nameGroup: true,
          fields: [
            {
              label: 'Creditor Name',
              key: 'name',
              type: 'value',
            },
            {
              label: 'Year',
              key: 'year',
              type: 'value',
            },
          ],
        },
      ]}
      renderEditForm={index => {
        return (
          <FormSection>
            <InputItem
              name={[index, 'name']}
              label="Creditor Name"
              form={form}
            />
            <InputItem
              name={[index, 'year']}
              label="Year"
              form={form}
              placeholder="Enter year"
              addressGroupField
            />
            <InputItem
              name={[index, 'amount']}
              label="Lien Amount"
              form={form}
              addressGroupField
              type="price"
              prefix={'$'}
              placeholder="00.00"
            />
          </FormSection>
        );
      }}
    />
  );
};

export default Judgements;
