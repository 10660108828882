import React from 'react';
import cn from 'classnames';
import { Badge as AntBadge } from 'antd';
import classes from './Badge.module.scss';

const Badge = ({ className, ...other }) => {
  return (
    <AntBadge
      overflowCount={99}
      {...other}
      className={cn(classes.wrapper, className)}
    />
  );
};

export default Badge;
