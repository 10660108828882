import get from 'lodash/get';
import find from 'lodash/find';
import { getAllParents } from '../../utils';

export const getAddNodes = (
  addNodes,
  nodeData,
  initTreeData,
  expandKeys = []
) => {
  const {
    node: { key, parent },
  } = nodeData || {};
  if (!addNodes.includes(key)) {
    addNodes.push(key);
  }
  const child = get(nodeData, 'node.children') || [];
  const initChild = (find(initTreeData, { key }) || []).children || [];
  if (child.length > 0) {
    child.map(n => {
      getAddNodes(addNodes, { node: n }, initChild, expandKeys);
      return true;
    });
    if (!expandKeys.includes(key)) {
      expandKeys.push(key);
    }
  }
  if (parent) {
    if (!addNodes.includes(parent)) {
      addNodes.push(parent);
      const parentSplit = parent.split('-');
      let prefix = `${parentSplit[0]}`;
      for (let i = 1; i < parentSplit.length - 1; i++) {
        addNodes.push(`${prefix}-${parentSplit[i]}`);
        prefix = `${prefix}-${parentSplit[i]}`;
      }
    }
  }
  return addNodes;
};
export const getAllChildRemove = (removeNodes, nodes) => {
  nodes.map(node => {
    removeNodes.push(node.key);
    if (node.children && node.children.length > 0) {
      getAllChildRemove(removeNodes, node.children);
    }
    return true;
  });
};
export const getAllNodeChildren = (thisNodeData, nodes, key) => {
  nodes.map(node => {
    if (node.key === key) {
      thisNodeData.push(node);
    } else if (node.children && node.children.length > 0) {
      getAllNodeChildren(thisNodeData, node.children, key);
    }
    return true;
  });
};
export const fetchAllNodes = (keys, nodes) => {
  nodes.map(node => {
    keys.push(node.key);
    if (node.children && node.children.length > 0) {
      fetchAllNodes(keys, node.children);
    }
    return true;
  });
};
export const getAllChildKeys = (childKeys, key, nodes) => {
  nodes.map(node => {
    if (node.key === key && node.children && node.children.length > 0) {
      fetchAllNodes(childKeys, node.children);
    } else if (node.children && node.children.length > 0) {
      getAllChildKeys(childKeys, key, node.children);
    }
    return true;
  });
};
export const getHalfCheckParent = (parent, nodes, targetKeys) => {
  let allParents = [];
  const halfKeys = [];
  const fullCheckedKeys = [];
  const removeParentKeys = [];
  if (parent) {
    allParents = getAllParents(parent);
    allParents.reverse().map(pKey => {
      const allChildKeys = [];
      getAllChildKeys(allChildKeys, pKey, nodes);
      if (allChildKeys.every(key => targetKeys.includes(key))) {
        fullCheckedKeys.push(pKey);
      } else if (allChildKeys.some(key => targetKeys.includes(key))) {
        halfKeys.push(pKey);
      } else {
        removeParentKeys.push(pKey);
        targetKeys.splice(targetKeys.indexOf(pKey), 1);
      }
      return true;
    });
  }

  return { halfKeys, fullCheckedKeys, removeParentKeys };
};
