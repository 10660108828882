import React from 'react';
import {
  EIN_NUMBER_FIELD,
  MC_NUMBER_FIELD,
  DOT_PIN_FIELD,
  DOT_NUMBER_FIELD,
} from 'helper/const';
import SecurityField from 'components/SecurityField';
import { useIsInternalUser } from 'helper/hooks';
import InputItem from '../InputItem';
import { Section, FormSection, ListInfo } from '../Elements';
import { DOCUMENTS_OBJ_KEYS } from './constants';

const Documents = ({ isEdit, form, data, permission, clientId }) => {
  const { isInternalUser, isExternalOwner } = useIsInternalUser();

  return (
    <Section>
      {isEdit ? (
        <FormSection>
          {isInternalUser ? (
            <SecurityField
              fieldObj={{
                label: 'EIN - Employer Identification Number',
                value: '********',
                type_id: data?.id,
                type: 'company',
                company_id: clientId,
                key: 'ein',
              }}
              viewRender={isShow => {
                return (
                  <InputItem
                    name={'ein'}
                    label="EIN - Employer Identification Number"
                    form={form}
                    type="number"
                    mask={EIN_NUMBER_FIELD.MASK}
                    placeholder={EIN_NUMBER_FIELD.PLACEHOLDER}
                    hide={!isShow}
                  />
                );
              }}
              pinSuccessCb={val => {
                form.setFieldsValue({
                  ein: val,
                });
              }}
            />
          ) : isExternalOwner ? (
            <InputItem
              name={'ein'}
              label="EIN - Employer Identification Number"
              form={form}
              type="number"
              mask={EIN_NUMBER_FIELD.MASK}
              placeholder={EIN_NUMBER_FIELD.PLACEHOLDER}
            />
          ) : (
            <div />
          )}
          {/* <InputItem
            name={'ein_letter'}
            label="EIN Letter"
            form={form}
            type="file"
            multiple
          /> */}
          <InputItem
            name={'mc_number'}
            label="MC Number"
            form={form}
            placeholder={MC_NUMBER_FIELD.PLACEHOLDER}
          />
          {/* <InputItem
            name={'mc_certificate'}
            label="MC Certificate"
            form={form}
            type="file"
            multiple
          /> */}
          {isInternalUser ? (
            <SecurityField
              fieldObj={{
                label: 'DOT PIN',
                value: '********',
                type_id: data?.id,
                type: 'company',
                company_id: clientId,
                key: 'dot_pin',
              }}
              viewRender={isShow => {
                return (
                  <InputItem
                    name={'dot_pin'}
                    label="DOT PIN"
                    form={form}
                    placeholder={DOT_PIN_FIELD.PLACEHOLDER}
                    type="number"
                    mask={DOT_PIN_FIELD.MASK}
                    hide={!isShow}
                  />
                );
              }}
              pinSuccessCb={val => {
                form.setFieldsValue({
                  dot_pin: val,
                });
              }}
            />
          ) : isExternalOwner ? (
            <InputItem
              name={'dot_pin'}
              label="DOT PIN"
              form={form}
              placeholder={DOT_PIN_FIELD.PLACEHOLDER}
              type="number"
              mask={DOT_PIN_FIELD.MASK}
            />
          ) : (
            <div />
          )}
          {permission?.companyInfo?.dotNumber && (
            <InputItem
              name={'dot_number'}
              label="DOT #"
              form={form}
              placeholder={DOT_NUMBER_FIELD.PLACEHOLDER}
              type="number"
              mask={DOT_NUMBER_FIELD.MASK}
            />
          )}
          {/* <InputItem
            name={'dot_pin_letter'}
            label="DOT PIN Letter"
            form={form}
            type="file"
            multiple
          /> */}
        </FormSection>
      ) : (
        <ListInfo
          objKeys={DOCUMENTS_OBJ_KEYS.filter(
            o =>
              !!permission?.companyInfo?.dotNumber ||
              (!permission?.companyInfo?.dotNumber && o.key !== 'dot_number')
          )}
          data={data}
          sType="company"
          sCompanyId={clientId}
          needSecure
        />
      )}
    </Section>
  );
};

export default Documents;
