import React from 'react';
import cn from 'classnames';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import {
  FileTextFilled,
  PlusCircleFilled,
  EditFilled,
  PhoneFilled,
  MobileFilled,
  MailFilled,
  ClockCircleOutlined,
  UserOutlined,
  CheckCircleFilled,
} from '@ant-design/icons';
import { getClientLogDateTime, getProfileDate, isValidDate } from 'utils/date';
import { getCurrencyValue } from 'utils/number';
import { capitalizeWholeString } from 'utils/text';
import { ContentBlock, AddNote, TimelineInfo, FieldsBlock } from './Elements';
import { ACTIVITY_KEY_MAPPING } from './constants';
import classes from './Activity.module.scss';

// TODO: activity icon mapping
export const dotIconsMapping = {
  note: <FileTextFilled />,
  created: <PlusCircleFilled />,
  updated: <EditFilled />,
  deleted: <EditFilled />,
  phone: <PhoneFilled />,
  followUp: <MobileFilled />,
  mail: <MailFilled />,
  checked: <CheckCircleFilled />,
};
const getFieldDisplay = field => {
  if (!field && typeof field !== 'string') return '';
  const fieldLabel = ACTIVITY_KEY_MAPPING[field] || '';
  return (
    <span>{fieldLabel || capitalizeWholeString(field.replace(/_/g, ' '))}</span>
  );
};
const getFieldFormat = (value, field, options) => {
  if (field === 'price') return getCurrencyValue(value);
  if (isValidDate(value)) {
    return getProfileDate(value);
  }
  if (field === 'sales_person_id') {
    return (
      find(options?.salesDepartmentUserOptions || [], { value })?.label || value
    );
  }
  return value;
};
const isOrderCheckListField = title => {
  return title.includes('OrderTypeChecklist');
};
const isOrderOtherField = title => {
  return title.includes('OrderTypeOtherField');
};
const shouldBoldMessage = log => {
  return (
    [
      'Checklist Item Added',
      'Checklist Item Deleted',
      'Checklist Item Completed',
      'Checklist Item Unchecked',
    ].filter(t => (log?.title || '').includes(t)).length > 0
  );
};
const getMessage = (log, salesDepartmentUserOptions) => {
  const { message, title, type } = log;
  const orderTypeFields = log.fields || [];
  let returnMessage = '';
  if (orderTypeFields.length > 0) {
    returnMessage = <FieldsBlock fields={orderTypeFields} />;
  } else {
    if (!message) return '';
    if (typeof message !== 'object') return message;
    if (message.attributes) {
      const { attributes, old } = message;
      if (old && !isEmpty(old)) {
        const oldFields = Object.keys(old).map(key => (
          <>
            <b>
              <i>{getFieldDisplay(key)}</i>
            </b>{' '}
            from{' '}
            <b>
              {String(
                getFieldFormat(old[key], key, { salesDepartmentUserOptions })
              )}
            </b>{' '}
            to{' '}
            <b>
              {String(
                getFieldFormat(attributes[key], key, {
                  salesDepartmentUserOptions,
                })
              )}
            </b>
          </>
        ));
        returnMessage = (
          <>
            {oldFields.map((f, i) => {
              return (
                <React.Fragment key={i}>
                  {f}
                  {i < oldFields.length - 1 ? <br /> : ''}
                </React.Fragment>
              );
            })}
          </>
        );
      } else {
        const fields = Object.keys(attributes)
          .filter(key => key !== 'order')
          .map(key => {
            const value = getFieldFormat(String(attributes[key]), key, {
              salesDepartmentUserOptions,
            });
            const boldText =
              isOrderCheckListField(title) || isOrderOtherField(title);
            if (boldText) return <b>{value}</b>;
            return (
              <>
                {type === 'updated' && (
                  <>
                    <b>{getFieldDisplay(key)}</b> to{' '}
                  </>
                )}
                {value}
              </>
            );
          });
        returnMessage = (
          <>
            {fields.map((f, i) => {
              return (
                <React.Fragment key={i}>
                  {f}
                  {i < fields.length - 1
                    ? isOrderOtherField(title)
                      ? ' - '
                      : ', '
                    : ''}
                </React.Fragment>
              );
            })}
          </>
        );
      }
    } else if (isArray(message) && message.length > 0) {
      const shouldBold = shouldBoldMessage(log);
      const messageDisplay = message[0] || '';
      returnMessage = shouldBold ? <b>{messageDisplay}</b> : messageDisplay;
    } else if (orderTypeFields.length > 0) {
      returnMessage = 'order type field';
    }
  }
  return returnMessage;
};
export const getNewFeeds = ({
  name,
  activityLog,
  onAddNote,
  loading,
  salesDepartmentUserOptions,
}) => {
  const newFeeds = [
    {
      content: <AddNote onSubmit={onAddNote} loading={loading} />,
    },
    ...(activityLog || []).map(log => {
      const { created_at } = log;
      const isEntityCreatedLog = [
        'Company Created',
        'OrderType Created',
      ].includes(log.title);
      const logDateTime = created_at ? getClientLogDateTime(created_at) : '';
      const message = getMessage(log, salesDepartmentUserOptions);
      const logTitle = log.title || '';
      const rawLogMessage = log.message || '';
      const rawLogMessageString = isArray(rawLogMessage)
        ? rawLogMessage.join('')
        : rawLogMessage;
      const isChecklistLog = logTitle.includes('Checklist Item');
      const isOrderCompletedStatus =
        logTitle.includes('Status Updated') &&
        logTitle.includes('Order') &&
        rawLogMessageString === 'Completed';
      return {
        content: (
          <ContentBlock
            title={log.title}
            content={
              <>
                <TimelineInfo
                  tooltip={`${logDateTime || ''} | ${log.user || ''}`}
                >
                  <span className={classes.time}>
                    <ClockCircleOutlined />
                    {logDateTime}
                  </span>
                  <span className={classes.separate}>|</span>
                  <span className={classes.user}>
                    <UserOutlined />
                    {log.user}
                  </span>
                </TimelineInfo>
                {(!!message || isEntityCreatedLog) && (
                  <TimelineInfo message>
                    {isEntityCreatedLog ? name || message : message}
                  </TimelineInfo>
                )}
              </>
            }
          />
        ),
        dot: isEntityCreatedLog
          ? null
          : dotIconsMapping[
              isChecklistLog || isOrderCompletedStatus
                ? 'checked'
                : (log.title || '').includes('Note')
                ? 'note'
                : log.type
            ],
        className: cn({
          [classes.defaultDot]: isEntityCreatedLog,
        }),
      };
    }),
  ];
  return newFeeds;
};
export const getFieldName = field => {
  return field?.name || field?.attributes?.name || '';
};
export const getFieldRequired = field => {
  return !!(field?.isRequired || field?.attributes?.is_required);
};
