import React from 'react';
import cx from 'classnames';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown as AntdDropdown } from 'antd';

import Menu from 'components/Menu';

import classes from './Dropdown.module.scss';

const Dropdown = props => {
  const {
    menus,
    menuClassName,
    children,
    className,
    trigger = ['hover'],
    hideArrow,
    arrowIcon,
    ...other
  } = props;
  const overlay =
    (menus || []).length === 0 ? (
      <></>
    ) : (
      <Menu
        menus={menus}
        isDropdown
        className={cx(classes.menu, menuClassName)}
      />
    );

  return (
    <AntdDropdown
      {...other}
      overlay={overlay}
      trigger={trigger}
      className={cx(classes.wrapper, className)}
    >
      {/* eslint-disable-next-line */}
      <a className="ant-dropdown-link" onClick={e => {
          e.preventDefault();
        }}
      >
        {children} {!hideArrow && (arrowIcon || <DownOutlined />)}
      </a>
    </AntdDropdown>
  );
};

export default Dropdown;
