import { useEffect, useState } from 'react';
import { getMobileOperatingSystem } from 'utils/mobile';

const useMedia = query => {
  const [matches, setMatches] = useState(false);
  const windowPath = window.location.href || '';
  const isMobileLockPath = windowPath.endsWith('/mobile-lock');
  const mobileOS = getMobileOperatingSystem();
  const isMobile = mobileOS !== 'unknown' && !isMobileLockPath;

  useEffect(() => {
    if (matches && isMobile) {
      window.location.href = '/mobile-lock';
    }
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches, query]);

  return matches;
};

export default useMedia;
