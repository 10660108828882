import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Form, { useForm } from 'components/Form';
import { validateForm, scrollToErrorField } from 'utils/form';
import { Title, SectionWrapper } from '../Elements';
import OtherIntegrateSection from '../OtherIntegrateSection';
import ButtonActions from '../ButtonActions';
import { getInitialValues, validateObj } from './utils';
import GeneralInfo from './GeneralInfo';
import AgentInfo from './AgentInfo';
import classes from './InsuranceInformation.module.scss';

const InsuranceInformation = ({
  className,
  isEdit,
  setIsEdit,
  rootKey,
  data,
  onFormChange,
  openWarningModal,
  onSubmit,
  loading,
}) => {
  const initialValues = getInitialValues(data);
  const wrapperId = `record_information_${rootKey}`;
  const [form] = useForm();
  const [isFormChange, setIsFormChanged] = useState(false);
  const handleSubmit = values => {
    const fieldErrors = validateForm(validateObj, values);
    if (fieldErrors.length > 0) {
      form.setFields(fieldErrors);
      scrollToErrorField(wrapperId);
    } else {
      onSubmit(values);
    }
  };
  useEffect(() => {
    if (isEdit) {
      setIsFormChanged(false);
      if (form) {
        form.resetFields();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  return (
    <SectionWrapper className={cn(classes.wrapper, className)} id={wrapperId}>
      <Form
        validateMessages={''}
        onFinish={handleSubmit}
        initialValues={initialValues}
        form={form}
        onValuesChange={() => {
          setIsFormChanged(true);
          onFormChange();
        }}
      >
        <div>
          <Title text="General Information">
            <ButtonActions
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              form={form}
              isFormChanged={isFormChange}
              loading={loading}
              className={classes.buttonActions}
              onSubmit={handleSubmit}
              openWarningModal={openWarningModal}
            />
          </Title>
          <GeneralInfo isEdit={isEdit} form={form} data={data} />
        </div>
        <div>
          <Title text="Agent Information" />
          <AgentInfo isEdit={isEdit} form={form} data={data} />
        </div>
        <OtherIntegrateSection
          isEdit={isEdit}
          form={form}
          other={data.custom_fields || []}
        />
      </Form>
    </SectionWrapper>
  );
};

export default InsuranceInformation;
