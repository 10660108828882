import React, { useState } from 'react';
import {
  PlusCircleOutlined,
  RightOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import cn from 'classnames';
import Button, { ButtonColors } from 'components/Button';
import Form, { FormItem, useForm } from 'components/Form';
import { TooltipBlock } from 'components/Tooltip';
import { TextArea } from 'components/FormFields';
import { getFieldName, getFieldRequired } from './utils';
import classes from './Activity.module.scss';

export const ContentBlock = ({ title, content }) => {
  return (
    <div className={cn(classes.contentBlock)}>
      <span>{title}</span>
      {content && <div>{content}</div>}
    </div>
  );
};
export const TimelineInfo = ({ className, children, tooltip, message }) => {
  return (
    <TooltipBlock
      className={cn(
        classes.timelineInfo,
        {
          [classes.message]: !!message,
        },
        className
      )}
      overlayClassName={classes.tooltip}
      tooltip={tooltip}
      placement="topRight"
    >
      {children}
    </TooltipBlock>
  );
};
export const AddNote = ({ note, setNote, onSubmit, loading }) => {
  const [form] = useForm();
  const handleSubmit = note => {
    onSubmit(note, () => {
      form.resetFields();
    });
  };
  return (
    <div className={classes.addNote}>
      <Form
        validateMessages={''}
        onFinish={values => {
          if (!!values.note) {
            handleSubmit(values.note);
          }
        }}
        initialValues={{
          note: '',
        }}
        form={form}
        className={classes.noteForm}
      >
        <FormItem name={'note'}>
          <TextArea name="note" rows={2} placeholder="Start your note" isBlue />
        </FormItem>
      </Form>
      <Button
        leftIcon={<PlusCircleOutlined />}
        onClick={() => {
          const values = form.getFieldsValue();
          if (!!values.note) handleSubmit(values.note);
        }}
        color={ButtonColors.BLUE}
        loading={loading}
        type="ghost"
      >
        Add Note
      </Button>
    </div>
  );
};
const FieldAttributeMessage = ({ field }) => {
  return (
    <>
      {' '}
      as {getFieldRequired(field) ? 'a required field' : 'an optional field'}
    </>
  );
};
const DisplayFields = ({ fields, hideAttributeMessage }) => {
  return (
    <span>
      {fields.map((f, i) => (
        <React.Fragment key={i}>
          <b>{getFieldName(f)}</b>
          {!hideAttributeMessage &&
            (!fields[i + 1] ||
              getFieldRequired(f) !== getFieldRequired(fields[i + 1])) && (
              <FieldAttributeMessage field={f} />
            )}
          {i < fields.length - 1 ? <>, </> : ''}
        </React.Fragment>
      ))}
    </span>
  );
};
export const FieldsBlock = ({ fields = [] }) => {
  const [isShow, setIsShow] = useState(false);
  const fieldsLength = fields.length;
  if (fieldsLength <= 2) {
    return <DisplayFields fields={fields} />;
  }
  const displayFields = fields.slice(0, 2);
  const iconProps = {
    className: classes.fieldNextArrow,
    onClick: () => {
      setIsShow(!isShow);
    },
  };
  return (
    <span>
      <DisplayFields
        fields={isShow ? fields : displayFields}
        hideAttributeMessage={!isShow}
      />{' '}
      {!isShow && (
        <>
          {' '}
          and <b>{fieldsLength - 2} other</b> fields{' '}
        </>
      )}
      {isShow ? (
        <LeftOutlined {...iconProps} title="Show less" />
      ) : (
        <RightOutlined {...iconProps} title="Show more" />
      )}
    </span>
  );
};
