import React, { useState } from 'react';
import cn from 'classnames';
import { PlusCircleOutlined } from '@ant-design/icons';
import Form, { FormItem, useForm } from 'components/Form';
import { Input } from 'components/FormFields';
import Button, { ButtonColors } from 'components/Button';
import classes from './AddColumn.module.scss';

const AddColumn = ({ className, onAdd = () => {}, loading }) => {
  const [isAdd, setIsAdd] = useState(false);
  const [form] = useForm();
  return (
    <div className={cn(classes.wrapper, className)}>
      {isAdd ? (
        <Form
          validateMessages={''}
          onFinish={values => {
            if (onAdd) {
              onAdd(values, () => {
                setIsAdd(false);
                form.resetFields();
              });
            }
          }}
          initialValues={{
            title: '',
          }}
          form={form}
          className={classes.form}
        >
          <FormItem name="title">
            <Input
              name="title"
              size="medium"
              form={form}
              isBlue
              placeholder="Enter column title..."
              autoFocus
            />
          </FormItem>
          <div className={classes.bottomButton}>
            <FormItem shouldUpdate>
              {() => {
                const values = form.getFieldsValue();
                return (
                  <>
                    <Button
                      type={'primary'}
                      htmlType={'button'}
                      onClick={() => {
                        setIsAdd(false);
                        form.resetFields();
                      }}
                      color={ButtonColors.DEFAULT}
                    >
                      Cancel
                    </Button>
                    <Button
                      type={'primary'}
                      htmlType={'submit'}
                      className={classes.fullWidth}
                      loading={loading}
                      color={ButtonColors.BLUE}
                      leftIcon={<PlusCircleOutlined />}
                      disabled={!(values.title || '').trim()}
                    >
                      Add Column
                    </Button>
                  </>
                );
              }}
            </FormItem>
          </div>
        </Form>
      ) : (
        <div
          className={classes.addColumnBlock}
          onClick={() => {
            setIsAdd(true);
          }}
        >
          <span>+ Add Another Column</span>
        </div>
      )}
    </div>
  );
};

export default AddColumn;
