import isEqual from 'lodash/isEqual';
import {
  renderStandardColumn,
  renderTagColumn,
  renderDateColumn,
  // renderPriceColumn,
} from 'utils/table';
import { SORT_DIRECTION_MAPPING, FORMATS, DATE_FIELD } from 'helper/const';
import { SORT_BY_MAPPING, DEFAULT_SORT } from './constants';

const getClientColumns = handleView => {
  return [
    {
      title: 'Company Name',
      dataIndex: 'legal_name',
      render: renderStandardColumn,
      width: 300,
      sorter: true,
      defaultSortOrder: 'ascend',
      fixed: 'left',
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      render: renderStandardColumn,
      width: 150,
      sorter: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      render: renderStandardColumn,
      width: 150,
      sorter: true,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      render: renderStandardColumn,
      width: 150,
      sorter: true,
    },
    {
      title: 'Phone',
      dataIndex: 'phone_number',
      render: value => renderStandardColumn(value, FORMATS.TABLE_PHONE),
      width: 150,
      sorter: true,
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      render: renderStandardColumn,
      width: 150,
      sorter: true,
    },
    {
      title: '# of Trucks',
      dataIndex: 'trucks_count',
      render: renderStandardColumn,
      align: 'right',
      width: 150,
      // sorter: true,
    },
    {
      title: 'GVW (lbs.)',
      dataIndex: 'gvw',
      render: renderStandardColumn,
      width: 150,
      // sorter: true,
    },
    // {
    //   title: 'Credit Amount',
    //   dataIndex: 'credit_amount',
    //   render: renderPriceColumn,
    //   width: 170,
    //   // sorter: true,
    // },
    // {
    //   title: 'Credit Used',
    //   dataIndex: 'credit_used',
    //   render: renderPriceColumn,
    //   width: 150,
    //   // sorter: true,
    // },
    // {
    //   title: 'Amount Over 60',
    //   dataIndex: 'amount_over_60',
    //   render: renderPriceColumn,
    //   width: 180,
    //   // sorter: true,
    // },
    // {
    //   title: 'Escrow Balance',
    //   dataIndex: 'escrow_balance',
    //   render: renderPriceColumn,
    //   width: 180,
    //   // sorter: true,
    // },
    {
      title: 'State',
      dataIndex: 'state',
      render: renderStandardColumn,
      width: 120,
      sorter: true,
    },
    {
      title: 'DOT #',
      dataIndex: 'dot_number',
      render: renderStandardColumn,
      width: 200,
      sorter: true,
    },
    {
      title: 'MC #',
      dataIndex: 'mc_number',
      render: renderStandardColumn,
      width: 200,
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      render: (value, record) => {
        return renderTagColumn(value, record, () => {
          handleView(record);
        });
      },
      width: 150,
      sorter: true,
    },
    {
      title: 'Customer Since',
      dataIndex: 'created_at',
      render: value => renderDateColumn(value, DATE_FIELD.FORMAT),
      width: 175,
      sorter: true,
    },
  ];
};
const getFilters = (page, sort, keyword, otherFilter = {}) => {
  const filters = {
    page,
    search: keyword || '',
    ...otherFilter,
  };
  if (sort.order) {
    filters.sort_by = SORT_BY_MAPPING[sort.field] || sort.field;
    filters.order = SORT_DIRECTION_MAPPING[sort.order] || '';
  }
  return filters;
};
const getOtherFilters = filters => {
  const returnFilters = {
    city: filters.city,
    // TODO: custom per page
    // per_page: 2,
  };
  if (filters.state !== 'all') {
    returnFilters.state_id = filters.state;
  }
  if (filters.status !== 'all') {
    returnFilters.status = filters.status;
  }
  return returnFilters;
};
const getIsDefaultSearchFilter = ({ keyword, sort, isDefaultFilter }) => {
  return keyword === '' && isDefaultFilter && isEqual(sort, DEFAULT_SORT);
};

export {
  getClientColumns,
  getFilters,
  getIsDefaultSearchFilter,
  getOtherFilters,
};
