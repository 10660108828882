import React from 'react';
import cn from 'classnames';
import classes from './NewOrderType.module.scss';

export const Left = ({ title, children, className }) => {
  return (
    <div className={cn(classes.leftBlock, className)}>
      <h2>{title}</h2>
      <div>{children}</div>
    </div>
  );
};
export const Right = ({ title, children, className, headerChild }) => {
  return (
    <div className={cn(classes.rightBlock, className)}>
      <h2>
        {title}
        <div className={classes.headerChild}>{headerChild}</div>
      </h2>
      <div>{children}</div>
    </div>
  );
};
export const Tab = ({ className, tabs, onChange, disabled, activeKey }) => {
  return (
    <div
      className={cn(
        classes.tabBlock,
        {
          [classes.disabled]: disabled,
        },
        className
      )}
    >
      <div className={classes.tabHeader}>
        {tabs.map((t, i) => {
          return (
            <div
              key={i}
              className={cn({
                [classes.active]: activeKey === i,
              })}
              onClick={() => {
                if (i !== activeKey && !disabled) {
                  if (onChange) onChange(i);
                }
              }}
            >
              {t.label}
            </div>
          );
        })}
      </div>
      <div className={classes.tabContent}>
        {tabs.map((t, i) => {
          if (activeKey === i) {
            return <div key={i}>{t.content}</div>;
          }
          return '';
        })}
      </div>
    </div>
  );
};
export const AvailableFieldLine = ({
  className,
  contents = [],
  direction,
  isChild,
  customContents = [],
  childLevel,
  hasChild,
}) => {
  return (
    <div
      className={cn(
        classes.availableFieldLine,
        {
          [classes.dRight]: direction === 'right',
          [classes.isChild]: isChild,
          [classes.hasChild]: hasChild,
          [`child-level-${childLevel}`]: childLevel,
        },
        className
      )}
    >
      {customContents.length > 0 ? (
        customContents.map((c, i) => {
          return <div key={i}>{c}</div>;
        })
      ) : (
        <>
          <div>{contents[0] || 'Category'}</div>
          {direction !== 'right' && (
            <>
              <div>
                {contents[1] || (
                  <div>
                    <label>Required</label>
                    <label>Optional</label>
                  </div>
                )}
              </div>
              <div>{contents[2] || ' '}</div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export const Required = ({ className, children }) => {
  return (
    <span className={cn(classes.required, className)}>{children || '*'}</span>
  );
};

export const Label = ({ className, children }) => {
  return <label className={cn(classes.label, className)}>{children}</label>;
};
