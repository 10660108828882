import React from 'react';
import cn from 'classnames';
import {
  PlusCircleFilled,
  WarningOutlined,
  CheckCircleFilled,
} from '@ant-design/icons';
import { STATUS_VALUES } from 'helper/const';
import Button, { ButtonColors } from 'components/Button';
import { handleConfirm } from './utils';
import { useFooterData } from './selectorData';
import classes from './NewOrderType.module.scss';

const NewOrderTypeFooter = ({
  confirmModal,
  onCancel,
  targetKeys,
  isEditing,
  isViewDetail,
  setIsEditing,
  orderTypeDetail,
  onDelete,
}) => {
  const {
    createLoading,
    updateLoading,
    deleteLoading,
    updateOrderTypeDocumentLoading,
  } = useFooterData();
  return (
    <div className={classes.actions}>
      {isViewDetail ? (
        <>
          <Button
            type={'primary'}
            size={'large'}
            htmlType="button"
            color={ButtonColors.DARK_BLUE}
            largeIcon
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Edit Order Type
          </Button>
        </>
      ) : (
        <>
          {orderTypeDetail.status === STATUS_VALUES.ACTIVE && isEditing && (
            <Button
              size={'large'}
              htmlType="button"
              loading={deleteLoading}
              color={ButtonColors.DARK_RED}
              onClick={() => {
                handleConfirm({ confirmModal, onDelete });
              }}
              className={classes.deleteOrderBtn}
            >
              Delete Order Type
            </Button>
          )}
          <Button
            size={'large'}
            htmlType="button"
            color={ButtonColors.DEFAULT}
            onClick={() => {
              confirmModal(
                isEditing
                  ? 'Do you want to leave without saving?'
                  : 'Cancel order type?',
                isEditing ? (
                  <div>Any unsaved changes will be discarded.</div>
                ) : (
                  <div>
                    Are you sure you want to cancel creating this
                    <br />
                    new order type?
                  </div>
                ),
                () => {
                  onCancel();
                },
                {
                  type: 'confirm',
                  onCancel: () => {},
                  okText: isEditing ? 'Leave Without Saving' : 'Cancel',
                  cancelText: 'Continue Editing',
                  centered: true,
                  revertButton: isEditing,
                  className: cn(classes.confirmationModal, {
                    [classes.redIconModal]: !isEditing,
                  }),
                  icon: !isEditing && <WarningOutlined />,
                }
              );
            }}
          >
            Cancel
          </Button>
          <Button
            type={'primary'}
            size={'large'}
            htmlType="submit"
            color={isEditing ? ButtonColors.DARK_BLUE : ButtonColors.BLUE}
            leftIcon={isEditing ? <CheckCircleFilled /> : <PlusCircleFilled />}
            disabled={targetKeys.length === 0}
            largeIcon
            loading={
              createLoading || updateLoading || updateOrderTypeDocumentLoading
            }
          >
            {isEditing ? 'Save Edits' : 'Create New Order Type'}
          </Button>
        </>
      )}
    </div>
  );
};

export default NewOrderTypeFooter;
