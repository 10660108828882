import Request from './request';

// GET
export const apiGetAllClients = async filters => {
  return Request.call({
    url: '/company/clients',
    method: 'GET',
    params: filters || {},
  });
};

// POST
export const apiCreateClient = async data => {
  return Request.call({
    url: '/clients',
    method: 'POST',
    data,
  });
};
