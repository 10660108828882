import React from 'react';
import cn from 'classnames';
import { MailFilled, PhoneFilled } from '@ant-design/icons';
import { ContactBlock } from '../Elements';
import classes from './PrimaryContact.module.scss';

const isContactEmpty = data => {
  return !data || (!data.name && !data.email && !data.phone && !data.title);
};

const PrimaryContact = ({ className, data }) => {
  if (isContactEmpty(data)) {
    return null;
  }
  const { email, name, phone, title } = data;
  return (
    <div className={cn(classes.wrapper, className)}>
      <span>Primary Contact</span>
      <div className={classes.info}>
        <span>{name}</span>
        <span className={classes.separate}>|</span>
        <span>{title}</span>
      </div>
      <div className={classes.contacts}>
        <ContactBlock href={`mailto:${email}`}>
          <MailFilled />
        </ContactBlock>
        <ContactBlock href={`tel:${phone}`}>
          <PhoneFilled />
        </ContactBlock>
      </div>
    </div>
  );
};

export default PrimaryContact;
