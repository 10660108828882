import React from 'react';
import classes from './FakeAutocompleteField.module.scss';

const FakeAutocompleteField = () => {
  return (
    <>
      <input
        type="text"
        name="fakeusernameremembered"
        className={classes.input}
      />
      <input type="text" name="fakeemailremembered" className={classes.input} />
      <input
        type="password"
        name="fakepasswordremembered"
        className={classes.input}
      />
    </>
  );
};

const FakeListFields = ({ fields }) => {
  return (
    <>
      {(fields || []).map((field, index) => {
        return (
          <input
            type="text"
            name={field}
            className={classes.fakeInput}
            autoComplete="chrome-off"
            key={index}
          />
        );
      })}
    </>
  );
};

export { FakeListFields };
export default FakeAutocompleteField;
