import React from 'react';
import { CollapseLabel, PrimaryTag } from '../components/Elements';

const PanelHeader = ({
  label,
  additionalLabel,
  is_primary,
  isActive,
  isEdit,
  className = '',
  firstIsSmall,
}) => {
  return (
    <CollapseLabel
      title={
        <>
          <span>
            {label}
            {!!is_primary && <PrimaryTag />}
          </span>
          {additionalLabel}
        </>
      }
      big
      section
      normal={!isActive}
      hasAdditional={!!additionalLabel}
      isEdit={isEdit}
      className={className}
      firstIsSmall={firstIsSmall}
    />
  );
};

export default PanelHeader;
