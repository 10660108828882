import React from 'react';
import cx from 'classnames';
import { Spin as AntdSpin } from 'antd';

import classes from './Spin.module.scss';

const Spin = ({ className, icon, ...other }) => {
  return (
    <AntdSpin
      {...other}
      className={cx(
        classes.wrapper,
        {
          [classes.icon]: icon,
        },
        className
      )}
    />
  );
};

export default Spin;
