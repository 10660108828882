import React from 'react';
import get from 'lodash/get';
import {
  PaperClipOutlined,
  UploadOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import cn from 'classnames';
import toast from 'helper/toast';
import { PHONE_FIELD, DATE_FIELD, NUMBER_FIELD } from 'helper/const';
import { onPreviewFile } from 'utils/file';
import { FormItem } from 'components/Form';
import Button from 'components/Button';
import Upload from 'components/Upload';
import {
  Input,
  InputMask,
  DatePicker,
  Select,
  TextArea,
  Checkbox,
  CheckboxGroupWrapper,
  InputPrice,
  InputNumber,
} from 'components/FormFields';
import classes from './InputItem.module.scss';

const InputItem = ({
  name,
  label,
  placeholder,
  required,
  type,
  autoFocus,
  autoComplete,
  form,
  disabled,
  size = 'medium',
  options = [],
  onChange,
  value,
  textProps = {},
  mask,
  fullWidth,
  addressField,
  aptField,
  addressGroupField,
  half,
  className,
  multiple,
  onRemove,
  mode,
  optionLabelProp,
  isMultipleOptionCheckbox,
  hide,
  labelTooltip,
  tooltipWidth,
  prefix,
  customFileName,
  inputType,
  inputRef,
  hidePlaceholder,
  customValidateName,
  isBlue,
  picker,
  format,
  fake,
  maxLength,
  dropdownRender,
  allValue,
  seeFullOption,
  lineAtAllOption,
  limitSize,
  fileNumberLimit,
  accept,
  ...other
}) => {
  const commonProps = { name, required, labelTooltip, tooltipWidth };
  if (type === 'file') {
    return (
      <FormItem shouldUpdate>
        {() => {
          const values = form.getFieldsValue();
          const currentFiles =
            (customFileName ? get(values, customFileName) : values[name]) || [];
          const currentDeleteIds = values.delete_attachments || [];
          return (
            <FormItem
              {...commonProps}
              valuePropName={name}
              label={label}
              labelTooltip={label}
              tooltipDefaultStyle
              placement="top"
              getValueFromEvent={e => {
                if (onChange) onChange(e?.fileList);
                if (!multiple) {
                  const fileList = e?.fileList || [];
                  const fileItem =
                    fileList.length > 1
                      ? fileList[fileList.length - 1]
                      : fileList[0];
                  if (limitSize && fileItem.size / 1024 / 1024 > limitSize) {
                    toast.error(
                      `Attachments size cannot exceed ${limitSize}MB. Please try again.`
                    );
                    return currentFiles;
                  }
                  return fileList.length > 0 ? [fileItem] : undefined;
                }
                if (limitSize) {
                  const fileItems = e ? e.fileList : [];
                  let isOverLimit = false;
                  for (let i = 0; i < fileItems.length; i++) {
                    if (fileItems[i].size / 1024 / 1024 > limitSize) {
                      isOverLimit = true;
                    }
                  }
                  if (isOverLimit) {
                    toast.error(
                      `Attachments size cannot exceed ${limitSize}MB. Please try again.`
                    );
                    return currentFiles;
                  }
                }
                const multipleReturnFiles = e ? e.fileList : undefined;
                if (
                  fileNumberLimit &&
                  typeof fileNumberLimit === 'number' &&
                  fileNumberLimit > 0
                ) {
                  return multipleReturnFiles.slice(-fileNumberLimit);
                }
                return multipleReturnFiles;
              }}
              className={cn(classes.fileField, 'ant-file-field')}
            >
              {disabled ? (
                <>
                  <div className={classes.fileDisable}>
                    {currentFiles.length === 0 && <>&nbsp;</>}
                    {currentFiles.map((f, i) => {
                      return (
                        <React.Fragment key={i}>
                          {f.name}
                          {i < currentFiles.length - 1 ? <br /> : ''}
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <Input
                    name={name}
                    form={form}
                    disabled={disabled}
                    className={classes.hide}
                  />
                </>
              ) : (
                <Upload
                  name={name}
                  multiple={multiple}
                  noAction
                  showUploadList={{
                    removeIcon: <CloseOutlined />,
                  }}
                  onPreview={onPreviewFile}
                  onRemove={file => {
                    if (file.id) {
                      form.setFieldsValue({
                        delete_attachments: [...currentDeleteIds, file.id],
                      });
                    }
                  }}
                  fileList={currentFiles}
                  accept={accept}
                >
                  {currentFiles.length === 0 && (
                    <span className={classes.fileEmptyText}>
                      <PaperClipOutlined />
                      No uploaded attachments
                    </span>
                  )}
                  <Button
                    size="small"
                    type="default"
                    leftIcon={<UploadOutlined />}
                    className={classes.uploadBtn}
                  >
                    Upload
                  </Button>
                </Upload>
              )}
            </FormItem>
          );
        }}
      </FormItem>
    );
  }
  const valueProps = {};
  const otherProps = {};
  const inputProps = {};
  if (value) {
    valueProps.value = value;
  }
  if (inputType) {
    inputProps.type = inputType;
  }
  return (
    <FormItem
      {...other}
      {...commonProps}
      label={type === 'checkbox' ? '' : label}
      className={cn(
        classes.formItem,
        {
          [classes.fullWidth]: fullWidth,
          [classes.addressField]: addressField,
          [classes.aptField]: aptField,
          [classes.addressGroupField]: addressGroupField,
          [classes.half]: half,
          [classes.hide]: hide,
          [classes.fake]: fake,
        },
        className
      )}
    >
      {type === 'date' ? (
        <DatePicker
          size={size}
          name={name}
          form={form}
          placeholder={placeholder || DATE_FIELD.PLACEHOLDER}
          format={format || DATE_FIELD.FORMAT}
          customValidateName={customValidateName}
          disabled={disabled}
          picker={picker}
          {...valueProps}
        />
      ) : type === 'select' ? (
        <Select
          items={options}
          placeholder={
            placeholder ||
            (mode === 'multiple' ? 'Select all that apply' : 'Select one')
          }
          showSearch
          showArrow
          size={size}
          name={name}
          form={form}
          disabled={disabled}
          onChange={onChange}
          mode={mode}
          isMultipleOptionCheckbox={isMultipleOptionCheckbox}
          optionLabelProp={optionLabelProp}
          customValidateName={customValidateName}
          isBlue={isBlue}
          dropdownRender={dropdownRender}
          autoComplete={autoComplete}
          allValue={allValue}
          seeFullOption={seeFullOption}
          lineAtAllOption={lineAtAllOption}
          {...valueProps}
        />
      ) : type === 'phone' ? (
        <InputMask
          size={size}
          mask={mask || PHONE_FIELD.MASK1}
          placeholder={placeholder || PHONE_FIELD.PLACEHOLDER2}
          name={name}
          form={form}
          isBlue
          disabled={disabled}
          inputRef={inputRef}
          {...valueProps}
        />
      ) : type === 'number' ? (
        <InputMask
          size={size}
          mask={mask || NUMBER_FIELD.MASK}
          placeholder={placeholder || NUMBER_FIELD.PLACEHOLDER}
          name={name}
          form={form}
          isBlue
          disabled={disabled}
          onChange={onChange}
          inputRef={inputRef}
          autoComplete={autoComplete}
          customValidateName={customValidateName}
          {...valueProps}
        />
      ) : type === 'textarea' ? (
        <TextArea
          rows={3}
          name={name}
          form={form}
          isBlue
          placeholder={placeholder || label}
          {...otherProps}
          {...valueProps}
        />
      ) : type === 'price' ? (
        <InputPrice
          name={name}
          form={form}
          fullWidth
          suffix={prefix ? '' : '%'}
          prefix={prefix}
          hideHandler
          disabled={disabled}
          placeholder={placeholder || label}
        />
      ) : type === 'integerNumber' ? (
        <InputNumber
          name={name}
          form={form}
          fullWidth
          hideHandler
          isInt
          textLeft
          placeholder={placeholder || label}
          customValidateName={customValidateName}
        />
      ) : type === 'checkbox' ? (
        <CheckboxGroupWrapper>
          <Checkbox label={label} value={name} disabled={disabled} />
        </CheckboxGroupWrapper>
      ) : (
        <Input
          inputRef={inputRef}
          placeholder={hidePlaceholder ? '' : placeholder || label}
          name={name}
          form={form}
          size={size}
          isBlue
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          disabled={disabled}
          customValidateName={customValidateName}
          onChange={onChange}
          maxLength={maxLength}
          {...otherProps}
          {...textProps}
          {...valueProps}
          {...inputProps}
        />
      )}
    </FormItem>
  );
};

export default InputItem;
