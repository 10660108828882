import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import { FormItem } from 'components/Form';
import { standardRequiredFieldObj } from 'utils/form';
import FormListSection from 'views/pages/ClientDetail/components/FormListSection';
import OtherSection from 'views/pages/ClientDetail/components/OtherSection';
import { OtherSectionEditForm } from 'views/pages/ClientDetail/components/OtherSection/Edit';
import { OtherSectionView } from 'views/pages/ClientDetail/components/OtherSection/View';
import { Title } from 'views/pages/ClientDetail/components/Elements';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { deleteFieldFn } from './utils';
import classes from './OtherIntegrateSection.module.scss';

const OtherIntegrateSection = ({
  className,
  isEdit,
  form,
  other,
  otherName = 'other',
}) => {
  const [stateOther, setStateOther] = useState(other || []);
  const handleCUCustomField = (otherObj, index, isUpdate, cb) => {
    if (otherObj.field_name) {
      if (!isUpdate) {
        setStateOther([...stateOther, otherObj]);
      } else if (cb) {
        cb();
      }
    } else {
      form.setFields([
        {
          name: [otherName, index, 'field_name'],
          errors: [standardRequiredFieldObj.message],
        },
      ]);
    }
  };
  useEffect(() => {
    if (!isEqual(other, stateOther)) {
      setStateOther(other);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [other]);

  return (
    <FormItem shouldUpdate>
      {() => {
        const values = form.getFieldsValue();
        const currentFormOther = values[otherName] || [];
        return (
          <div className={cn(classes.wrapper, className)}>
            <Title text="Other" />
            <InputItem
              name={'delete_custom_fields'}
              label=""
              form={form}
              hide
            />
            <FormListSection
              addOnce
              hideNotFound
              notFoundMessage=""
              isEdit={isEdit}
              form={form}
              isOther
              canSave
              saveLoading={false}
              onSave={index => () => {
                handleCUCustomField(currentFormOther[index], index);
              }}
              data={stateOther}
              initialObjFn={arrayLength => {
                return {
                  field_name: '',
                  document: '',
                };
              }}
              titleField="title"
              name={otherName}
              addBtnText="Add Field"
              newLabel="New Field"
              renderFields={(
                index,
                isExisting,
                isEdit,
                setIsEdit,
                nextIsEdit
              ) => {
                if (isExisting) {
                  return (
                    <OtherSection
                      form={form}
                      index={index}
                      isEdit={isEdit}
                      setIsEdit={setIsEdit}
                      parentName={otherName}
                      data={currentFormOther[index] || {}}
                      onDelete={() => {
                        deleteFieldFn(form, index, () => {
                          setStateOther(
                            stateOther.filter((o, i) => i !== index)
                          );
                          if (!nextIsEdit) setIsEdit(false);
                        });
                      }}
                      onUpdate={() => {
                        handleCUCustomField(
                          currentFormOther[index],
                          index,
                          true,
                          () => {
                            setIsEdit(false);
                          }
                        );
                      }}
                    />
                  );
                }
                return (
                  <OtherSectionEditForm
                    form={form}
                    index={index}
                    parentName={otherName}
                  />
                );
              }}
              renderViews={() => {
                return (
                  <>
                    {other.map((o, i) => {
                      return (
                        <OtherSectionView
                          key={i}
                          fields={[
                            { name: 'document', labelName: 'field_name' },
                          ]}
                          data={o}
                        />
                      );
                    })}
                  </>
                );
              }}
            />
          </div>
        );
      }}
    </FormItem>
  );
};

export default OtherIntegrateSection;
