export const NORMAL_FIELDS = ['number'];
export const FILE_FIELDS = [
  'documents.irp_current_year',
  'documents.irp_previous_years',
];
export const NULL_FIELDS = ['state_id'];
export const NUMBER_FIELDS = [];
export const DATE_FIELDS = ['expiration_date'];
export const BODY_REQUEST_NORMAL_FIELDS = ['number'];
export const PORTAL_FIELDS = ['state_id', 'username', 'password'];
// Fields for view mode
export const PROFILE_OBJ_KEYS = [
  {
    label: 'IRP Number',
    key: 'number',
    type: 'value',
  },
  {
    label: 'Expiration Date',
    key: 'expiration_date',
    type: 'date',
  },
];
export const FILES_OBJ_KEYS = year => {
  return [
    {
      label: `IRP File - ${year}`,
      key: `year_wise.${year}.documents.irp_year_wise[0]`,
      type: 'file',
      isSingleFile: true,
    },
    {
      label: 'Expiration Date',
      key: `year_wise.${year}.expiration_date`,
      type: 'date',
    },
  ];
};
