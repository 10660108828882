import Request from './request';

// GET
export const apiGetOrderTypesList = async () => {
  return Request.call({
    url: '/order-type/list',
    method: 'GET',
  });
};
export const apiGetAllOrderTypes = async filters => {
  return Request.call({
    url: '/order-type',
    method: 'GET',
    params: filters,
  });
};
export const apiGetOrderTypeDetail = async id => {
  return Request.call({
    url: `/order-type/${id}`,
    method: 'GET',
  });
};
export const apiGetOrderTypeLogs = async id => {
  return Request.call({
    url: `/activity-logs/order-type/${id}`,
    method: 'GET',
  });
};

// POST
export const apiCreateOrderType = async data => {
  return Request.call({
    url: '/order-type',
    method: 'POST',
    data,
  });
};
export const apiUpdateOrderType = async (id, data) => {
  return Request.call({
    url: `/order-type/${id}`,
    method: 'PUT',
    data,
  });
};
export const apiDeleteOrderType = async id => {
  return Request.call({
    url: `/order-type/${id}`,
    method: 'DELETE',
  });
};
export const apiAddOrderTypeNote = async (id, data) => {
  return Request.call({
    url: `/order-type/${id}/note`,
    method: 'POST',
    data,
  });
};
export const apiUpdateOrderTypeDocument = async (id, data) => {
  return Request.call({
    url: `/order-type/${id}/documents`,
    method: 'POST',
    data,
  });
};
