import { useReducerData, useReducerLoading } from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// BACKGROUNDS
export const useSelectBackgroundsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.UNDERWRITING,
    REDUCER_ATTRIBUTES.UNDERWRITING.BACKGROUNDS
  );
};
export const useSelectBackgrounds = () => {
  return useReducerData(
    REDUCER_NAMES.UNDERWRITING,
    REDUCER_ATTRIBUTES.UNDERWRITING.BACKGROUNDS
  );
};
// UCCS
export const useSelectUccsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.UNDERWRITING,
    REDUCER_ATTRIBUTES.UNDERWRITING.UCCS
  );
};
export const useSelectUccs = () => {
  return useReducerData(
    REDUCER_NAMES.UNDERWRITING,
    REDUCER_ATTRIBUTES.UNDERWRITING.UCCS
  );
};
// BUSINESS_ASSOCIATIONS
export const useSelectBusinessAssociationsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.UNDERWRITING,
    REDUCER_ATTRIBUTES.UNDERWRITING.BUSINESS_ASSOCIATIONS
  );
};
export const useSelectBusinessAssociations = () => {
  return useReducerData(
    REDUCER_NAMES.UNDERWRITING,
    REDUCER_ATTRIBUTES.UNDERWRITING.BUSINESS_ASSOCIATIONS
  );
};
// CREDITS
export const useSelectCreditsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.UNDERWRITING,
    REDUCER_ATTRIBUTES.UNDERWRITING.CREDITS
  );
};
export const useSelectCredits = () => {
  return useReducerData(
    REDUCER_NAMES.UNDERWRITING,
    REDUCER_ATTRIBUTES.UNDERWRITING.CREDITS
  );
};
// ALERTS
export const useSelectAlertsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.UNDERWRITING,
    REDUCER_ATTRIBUTES.UNDERWRITING.ALERTS
  );
};
export const useSelectAlerts = () => {
  return useReducerData(
    REDUCER_NAMES.UNDERWRITING,
    REDUCER_ATTRIBUTES.UNDERWRITING.ALERTS
  );
};
// LOCATIONS
export const useSelectLocationsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.UNDERWRITING,
    REDUCER_ATTRIBUTES.UNDERWRITING.LOCATIONS
  );
};
export const useSelectLocations = () => {
  return useReducerData(
    REDUCER_NAMES.UNDERWRITING,
    REDUCER_ATTRIBUTES.UNDERWRITING.LOCATIONS
  );
};

// CREATE_UNDERWRITING_RECORD
export const useSelectCreateUnderwritingRecordLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.UNDERWRITING,
    REDUCER_ATTRIBUTES.UNDERWRITING.CREATE_UNDERWRITING_RECORD
  );
};
// UPDATE_UNDERWRITING_RECORD
export const useSelectUpdateUnderwritingRecordLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.UNDERWRITING,
    REDUCER_ATTRIBUTES.UNDERWRITING.UPDATE_UNDERWRITING_RECORD
  );
};
// UPDATE_UNDERWRITING_RECORD_STATUS
export const useSelectUpdateUnderwritingRecordStatusLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.UNDERWRITING,
    REDUCER_ATTRIBUTES.UNDERWRITING.UPDATE_UNDERWRITING_RECORD_STATUS
  );
};
