export const NORMAL_FIELDS = [
  'tx_dot_username',
  'tx_dot_password',
  'ca_number',
  'epn_code',
  'tx_dot_number',
  'scac_code',
];
export const FILE_FIELDS = [
  'documents.BOC3.boc3',
  'documents.SCAC.assignment_letter',
];
export const NULL_FIELDS = [];
export const NUMBER_FIELDS = ['ca_number', 'epn_code', 'tx_dot_number'];
export const DATE_FIELDS = [
  'ucr_expiration_date',
  '2290_expiration_date',
  'scac_expiration_date',
];
export const BODY_REQUEST_NORMAL_FIELDS = [
  'tx_dot_username',
  'tx_dot_password',
  'ca_number',
  'epn_code',
  'tx_dot_number',
  'scac_code',
];
// Fields for view mode
export const BOC3_OBJ_KEYS = [
  {
    label: 'BOC3',
    key: 'documents.BOC3.boc3',
    type: 'file',
    isSingleFile: true,
  },
];
export const URC_OBJ_KEYS = year => {
  return [
    {
      label: `UCR File - ${year}`,
      key: `documents.UCR.${year}.ucr_year_wise[0]`,
      type: 'file',
      isSingleFile: true,
    },
  ];
};
export const SCAC_OBJ_KEYS = [
  {
    label: 'Assignment Letter',
    key: 'documents.SCAC.assignment_letter',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Expiration Date',
    key: 'scac_expiration_date',
    type: 'date',
  },
  {
    label: 'SCAC Code',
    key: 'scac_code',
    type: 'value',
  },
];
export const TXDOT_OBJ_KEYS = [
  {
    label: 'TX DOT Number',
    key: 'tx_dot_number',
    type: 'value',
    fullWidth: true,
  },
  {
    label: 'Username',
    key: 'tx_dot_username',
    type: 'value',
  },
  {
    label: 'Password',
    key: 'tx_dot_password',
    type: 'value',
  },
];
export const CA_NUMBER_OBJ_KEYS = [
  {
    label: 'CA Number',
    key: 'ca_number',
    type: 'value',
  },
  {
    label: 'EPN Requestor Code',
    key: 'epn_code',
    type: 'value',
  },
];
export const FILLING_2290_OBJ_KEYS = year => {
  return [
    {
      label: `2290 File - ${year}`,
      key: `documents.2290.${year}.2290_year_wise[0]`,
      type: 'file',
      isSingleFile: true,
    },
  ];
};
