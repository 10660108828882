import React from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import cn from 'classnames';
import classes from './ClassTooltipContent.module.scss';

const ClassTooltipContent = ({ className, title, description }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <div>
        <InfoCircleFilled />
      </div>
      <div className={classes.content}>
        <b>{title}</b>
        <span>{description}</span>
      </div>
    </div>
  );
};

export default ClassTooltipContent;
