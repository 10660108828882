import React from 'react';
// import { FILE_NUMBER_FIELD } from 'helper/const';
import { useOptions } from 'helper/hooks';
import InputItem from '../InputItem';
import { Section, FormSection, ListInfo } from '../Elements';
import { STATE_FILLING_OBJ_KEYS } from './constants';

const StateFilling = ({ isEdit, form, data, permission }) => {
  // TODO: hide client detail fields base on mobile app updated
  // const { stateOptions, scBusinessEntityOptions } = useOptions();
  const { scBusinessEntityOptions } = useOptions();
  const disabled = permission.isClient;
  return (
    <Section>
      {isEdit ? (
        <FormSection>
          <InputItem
            name={'business_entity_id'}
            label="Form of Entity"
            form={form}
            type="select"
            options={scBusinessEntityOptions}
          />
          <InputItem
            name={'articles_of_org'}
            label="Articles of Inc. / Org."
            form={form}
            type="file"
            multiple
          />
          {/* <InputItem
            name={'sos_id'}
            label="Secretary of State Filing"
            form={form}
            type="select"
            options={stateOptions}
          />
          <InputItem
            name={'state_taxpayer_id'}
            label="State Taxpayer ID (*Required in Texas)"
            form={form}
            placeholder="None"
          />
          <InputItem
            name={'file_number'}
            label="File Number"
            form={form}
            type="number"
            mask={FILE_NUMBER_FIELD.MASK}
            placeholder={FILE_NUMBER_FIELD.PLACEHOLDER}
          />
          <InputItem
            name={'filing_date'}
            label="File Date"
            form={form}
            type="date"
            placeholder="01/01/2000"
          /> */}
          <InputItem
            name={'dba'}
            label="DBA - Doing Business As"
            form={form}
            disabled={disabled}
          />
          <InputItem
            name={'dba_form'}
            label="Assumed Name / DBA Certificate"
            form={form}
            type="file"
            multiple
          />
        </FormSection>
      ) : (
        <ListInfo objKeys={STATE_FILLING_OBJ_KEYS} data={data} />
      )}
    </Section>
  );
};

export default StateFilling;
