import React from 'react';
import { UserOutlined } from '@ant-design/icons';

import Avatar from 'components/Avatar';
import Space from 'components/Space';

const AvatarComponent = ({ classes }) => {
  const avatarIcon = (size, type) => {
    return <Avatar size={size} icon={<UserOutlined />} shape={type} />;
  };
  const avatarText = (size, type) => {
    return (
      <Avatar size={size} shape={type}>
        US
      </Avatar>
    );
  };
  const avatarImage = (size, type) => {
    return (
      <Avatar
        src="https://media.sproutsocial.com/uploads/2017/02/10x-featured-social-media-image-size.png"
        size={size}
        shape={type}
      />
    );
  };
  const avatarCard = size => {
    return <Avatar border src={''} card size={size} />;
  };
  return (
    <Space direction={'vertical'}>
      <Space direction={'horizontal'}>
        {['ellipse', 'square'].map((type, typeIndex) => {
          return (
            <div key={typeIndex} className={classes.avatarContainer}>
              {['icon', 'text', 'image', 'card'].map(
                (subType, subTypeIndex) => {
                  if (subType === 'card') {
                    return (
                      <div key={subTypeIndex}>
                        {avatarCard('extra')}
                        {avatarCard('large')}
                        {avatarCard('medium')}
                        {avatarCard('small')}
                      </div>
                    );
                  }
                  if (subType === 'image') {
                    return (
                      <div key={subTypeIndex}>
                        {avatarImage(64, type)}
                        {avatarImage('large', type)}
                        {avatarImage('medium', type)}
                        {avatarImage('small', type)}
                      </div>
                    );
                  }
                  if (subType === 'text') {
                    return (
                      <div key={subTypeIndex}>
                        {avatarText(64, type)}
                        {avatarText('large', type)}
                        {avatarText('medium', type)}
                        {avatarText('small', type)}
                      </div>
                    );
                  }
                  return (
                    <div key={subTypeIndex}>
                      {avatarIcon(64, type)}
                      {avatarIcon('large', type)}
                      {avatarIcon('medium', type)}
                      {avatarIcon('small', type)}
                    </div>
                  );
                }
              )}
            </div>
          );
        })}
      </Space>
    </Space>
  );
};

export default AvatarComponent;
