const initialMyTeamState = {
  // GET
  companyTeamList: {
    loading: false,
    data: [],
    totalNo: 0,
    isMore: false,
    error: '',
  },
  allTeams: {
    loading: false,
    data: [],
    totalNo: 0,
    isMore: false,
    error: '',
  },
  inActiveTeam: {
    loading: false,
    data: [],
    totalNo: 0,
    isMore: false,
    error: '',
  },
  departmentTeam: {
    loading: false,
    data: [],
    totalNo: 0,
    isMore: false,
    error: '',
  },
  // POST
  inviteTeammate: {
    loading: false,
    data: {},
    error: '',
  },
  resendInviteTeammate: {
    loading: false,
    data: {},
    error: '',
  },
  inviteCompanyTeamMember: {
    loading: false,
    data: {},
    error: '',
  },
  updateCompanyTeamMember: {
    loading: false,
    data: {},
    error: '',
  },
  updateCompanyTeamMemberStatus: {
    loading: false,
    data: {},
    error: '',
  },
};

export { initialMyTeamState };
