import get from 'lodash/get';
import { getUpdatedArrayData, getUpdatedDriverAccount } from 'utils/store';
import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  driverTypes,
} from '../types';
import { initialDriverState } from '../states/driver';

export default (state = initialDriverState, action) => {
  const {
    driverId,
    driverData,
    accountId,
    simpleAccountData,
    cardId,
    simpleCardData,
  } = action?.payload || {};
  switch (action.type) {
    // SYNC_UPDATE_DRIVER
    case driverTypes.SYNC_UPDATE_DRIVER: {
      return {
        ...state,
        drivers: {
          ...state.drivers,
          data: getUpdatedArrayData(state.drivers, driverId, driverData),
        },
      };
    }
    // SYNC_UPDATE_DRIVER_SIMPLE_ACCOUNT
    case driverTypes.SYNC_UPDATE_DRIVER_SIMPLE_ACCOUNT: {
      return {
        ...state,
        drivers: {
          ...state.drivers,
          data: getUpdatedArrayData(
            state.drivers,
            driverId,
            getUpdatedDriverAccount(
              state.drivers?.data || [],
              simpleAccountData,
              driverId,
              accountId,
              false,
              'payment_accounts'
            )
          ),
        },
      };
    }
    // SYNC_UPDATE_DRIVER_SIMPLE_FUEL_CARD
    case driverTypes.SYNC_UPDATE_DRIVER_SIMPLE_FUEL_CARD: {
      return {
        ...state,
        drivers: {
          ...state.drivers,
          data: getUpdatedArrayData(
            state.drivers,
            driverId,
            getUpdatedDriverAccount(
              state.drivers?.data || [],
              simpleCardData,
              driverId,
              cardId,
              false,
              'fuel_cards'
            )
          ),
        },
      };
    }
    // GET_DRIVERS
    case asyncActionTypeRequest(driverTypes.GET_DRIVERS): {
      return {
        ...state,
        drivers: {
          ...state.drivers,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(driverTypes.GET_DRIVERS): {
      return {
        ...state,
        drivers: {
          loading: false,
          data: get(action, 'response.data') || [],
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(driverTypes.GET_DRIVERS): {
      return {
        ...state,
        drivers: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    }
    // GET_DRIVER_ACCOUNT
    case asyncActionTypeRequest(driverTypes.GET_DRIVER_ACCOUNT): {
      return {
        ...state,
        driverAccount: {
          ...state.driverAccount,
          loading: {
            ...state.driverAccount?.loading,
            [accountId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(driverTypes.GET_DRIVER_ACCOUNT): {
      return {
        ...state,
        driverAccount: {
          loading: {
            ...state.driverAccount?.loading,
            [accountId]: false,
          },
          data: {
            ...state.driverAccount?.data,
            [accountId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.driverAccount?.error,
            [accountId]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(driverTypes.GET_DRIVER_ACCOUNT): {
      return {
        ...state,
        driverAccount: {
          loading: {
            ...state.driverAccount?.loading,
            [accountId]: false,
          },
          data: {
            ...state.driverAccount?.data,
            [accountId]: {},
          },
          error: {
            ...state.driverAccount?.error,
            [accountId]: action.error,
          },
        },
      };
    }
    // GET_DRIVER_FUEL_CARD
    case asyncActionTypeRequest(driverTypes.GET_DRIVER_FUEL_CARD): {
      return {
        ...state,
        driverFuelCard: {
          ...state.driverFuelCard,
          loading: {
            ...state.driverFuelCard?.loading,
            [cardId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(driverTypes.GET_DRIVER_FUEL_CARD): {
      return {
        ...state,
        driverFuelCard: {
          loading: {
            ...state.driverFuelCard?.loading,
            [cardId]: false,
          },
          data: {
            ...state.driverFuelCard?.data,
            [cardId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.driverFuelCard?.error,
            [cardId]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(driverTypes.GET_DRIVER_FUEL_CARD): {
      return {
        ...state,
        driverFuelCard: {
          loading: {
            ...state.driverFuelCard?.loading,
            [cardId]: false,
          },
          data: {
            ...state.driverFuelCard?.data,
            [cardId]: {},
          },
          error: {
            ...state.driverFuelCard?.error,
            [cardId]: action.error,
          },
        },
      };
    }
    // CREATE_DRIVER
    case asyncActionTypeRequest(driverTypes.CREATE_DRIVER): {
      return {
        ...state,
        createDriver: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(driverTypes.CREATE_DRIVER): {
      return {
        ...state,
        createDriver: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(driverTypes.CREATE_DRIVER): {
      return {
        ...state,
        createDriver: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // UPDATE_DRIVER
    case asyncActionTypeRequest(driverTypes.UPDATE_DRIVER): {
      return {
        ...state,
        updateDriver: {
          ...state.updateDriver,
          loading: {
            ...state.updateDriver.loading,
            [driverId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(driverTypes.UPDATE_DRIVER): {
      return {
        ...state,
        updateDriver: {
          loading: {
            ...state.updateDriver.loading,
            [driverId]: false,
          },
          data: {
            ...state.updateDriver.data,
            [driverId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.updateDriver.error,
            [driverId]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(driverTypes.UPDATE_DRIVER): {
      return {
        ...state,
        updateDriver: {
          loading: {
            ...state.updateDriver.loading,
            [driverId]: false,
          },
          data: {
            ...state.updateDriver.data,
            [driverId]: {},
          },
          error: {
            ...state.updateDriver.error,
            [driverId]: action.error,
          },
        },
      };
    }
    // UPDATE_DRIVER_STATUS
    case asyncActionTypeRequest(driverTypes.UPDATE_DRIVER_STATUS): {
      return {
        ...state,
        updateDriverStatus: {
          ...state.updateDriverStatus,
          loading: {
            ...state.updateDriverStatus.loading,
            [driverId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(driverTypes.UPDATE_DRIVER_STATUS): {
      return {
        ...state,
        updateDriverStatus: {
          loading: {
            ...state.updateDriverStatus.loading,
            [driverId]: false,
          },
          data: {
            ...state.updateDriverStatus.data,
            [driverId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.updateDriverStatus.error,
            [driverId]: {},
          },
        },
      };
    }
    case asyncActionTypeFailure(driverTypes.UPDATE_DRIVER_STATUS): {
      return {
        ...state,
        updateDriverStatus: {
          loading: {
            ...state.updateDriverStatus.loading,
            [driverId]: false,
          },
          data: {
            ...state.updateDriverStatus.data,
            [driverId]: {},
          },
          error: {
            ...state.updateDriverStatus.error,
            [driverId]: action.error,
          },
        },
      };
    }
    // CREATE_DRIVER_ACCOUNT
    case asyncActionTypeRequest(driverTypes.CREATE_DRIVER_ACCOUNT): {
      return {
        ...state,
        createDriverAccount: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(driverTypes.CREATE_DRIVER_ACCOUNT): {
      return {
        ...state,
        createDriverAccount: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
        drivers: {
          ...state.drivers,
          data: getUpdatedArrayData(
            state.drivers,
            driverId,
            getUpdatedDriverAccount(
              state.drivers?.data || [],
              get(action, 'response.data') || [],
              driverId,
              null,
              true,
              'payment_accounts'
            )
          ),
        },
      };
    }
    case asyncActionTypeFailure(driverTypes.CREATE_DRIVER_ACCOUNT): {
      return {
        ...state,
        createDriverAccount: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // UPDATE_DRIVER_ACCOUNT
    case asyncActionTypeRequest(driverTypes.UPDATE_DRIVER_ACCOUNT): {
      return {
        ...state,
        updateDriverAccount: {
          ...state.updateDriverAccount,
          loading: {
            ...state.updateDriverAccount.loading,
            [driverId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(driverTypes.UPDATE_DRIVER_ACCOUNT): {
      return {
        ...state,
        updateDriverAccount: {
          loading: {
            ...state.updateDriverAccount.loading,
            [driverId]: false,
          },
          data: {
            ...state.updateDriverAccount.data,
            [driverId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.updateDriverAccount.error,
            [driverId]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(driverTypes.UPDATE_DRIVER_ACCOUNT): {
      return {
        ...state,
        updateDriverAccount: {
          loading: {
            ...state.updateDriverAccount.loading,
            [driverId]: false,
          },
          data: {
            ...state.updateDriverAccount.data,
            [driverId]: {},
          },
          error: {
            ...state.updateDriverAccount.error,
            [driverId]: action.error,
          },
        },
      };
    }
    // CREATE_DRIVER_FUEL_CARD
    case asyncActionTypeRequest(driverTypes.CREATE_DRIVER_FUEL_CARD): {
      return {
        ...state,
        createDriverFuelCard: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(driverTypes.CREATE_DRIVER_FUEL_CARD): {
      return {
        ...state,
        createDriverFuelCard: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
        drivers: {
          ...state.drivers,
          data: getUpdatedArrayData(
            state.drivers,
            driverId,
            getUpdatedDriverAccount(
              state.drivers?.data || [],
              get(action, 'response.data') || [],
              driverId,
              null,
              true,
              'fuel_cards'
            )
          ),
        },
      };
    }
    case asyncActionTypeFailure(driverTypes.CREATE_DRIVER_FUEL_CARD): {
      return {
        ...state,
        createDriverFuelCard: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // UPDATE_DRIVER_FUEL_CARD
    case asyncActionTypeRequest(driverTypes.UPDATE_DRIVER_FUEL_CARD): {
      return {
        ...state,
        updateDriverFuelCard: {
          ...state.updateDriverFuelCard,
          loading: {
            ...state.updateDriverFuelCard.loading,
            [driverId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(driverTypes.UPDATE_DRIVER_FUEL_CARD): {
      return {
        ...state,
        updateDriverFuelCard: {
          loading: {
            ...state.updateDriverFuelCard.loading,
            [driverId]: false,
          },
          data: {
            ...state.updateDriverFuelCard.data,
            [driverId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.updateDriverFuelCard.error,
            [driverId]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(driverTypes.UPDATE_DRIVER_FUEL_CARD): {
      return {
        ...state,
        updateDriverFuelCard: {
          loading: {
            ...state.updateDriverFuelCard.loading,
            [driverId]: false,
          },
          data: {
            ...state.updateDriverFuelCard.data,
            [driverId]: {},
          },
          error: {
            ...state.updateDriverFuelCard.error,
            [driverId]: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
};
