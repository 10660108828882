import pick from 'lodash/pick';
import get from 'lodash/get';
import { QUARTERS } from 'helper/const';
import { parseStringToNumber } from 'utils/number';
import { getMomentDate, getBEDate, getLast5Years } from 'utils/date';
import { getFileFieldInitialValues } from 'utils/form';
import {
  FILE_FIELDS,
  DATE_FIELDS,
  NORMAL_FIELDS,
  NULL_FIELDS,
  BODY_REQUEST_NORMAL_FIELDS,
  NUMBER_FIELDS,
  PORTAL_1_FIELDS,
  PORTAL_2_FIELDS,
} from '../tabConstants/newYork';

const getFileField = field => {
  if (!field) return '';
  return field.replace('documents.', '');
};
const getFileYearInitialValues = data => {
  const keys = ['new_york_permit'];
  QUARTERS.forEach(obj => {
    keys.push(`new_york_q${obj.value}_return`);
    keys.push(`new_york_q${obj.value}_receipt`);
  });

  const initialValues = {};
  const listYears = getLast5Years();
  listYears.forEach(year => {
    keys.forEach(key => {
      const fileObj = get(
        data,
        `year_wise.${year}.documents.${`${key}_year_wise`}`
      )?.[0];
      initialValues[`${key}_year_wise_${year}`] = fileObj
        ? fileObj.id
          ? [
              {
                ...fileObj,
                uid: fileObj.id,
              },
            ]
          : fileObj.map(f => ({
              ...f,
              uid: f.id,
            }))
        : null;
    });
  });
  return initialValues;
};

export const getInitialValues = data => {
  const initialValues = {
    delete_attachments: [],
  };
  [...NORMAL_FIELDS, ...NULL_FIELDS].map(field => {
    const eValue = data[field];
    initialValues[field] = eValue || (NULL_FIELDS.includes(field) ? null : '');
    return true;
  });
  DATE_FIELDS.map(field => {
    initialValues[field] = data[field]
      ? getMomentDate(data[field], false, true)
      : '';
    return true;
  });
  PORTAL_1_FIELDS.map(field => {
    const dataField = field.replace('hut_', '');
    initialValues[field] =
      get(data, `online_portals[0].${dataField}`) ||
      (NULL_FIELDS.includes(field) ? null : '');
    const portalId = get(data, `online_portals[0].id`) || '';
    if (portalId) {
      initialValues.hut_portal_id = portalId;
    }
    return true;
  });
  PORTAL_2_FIELDS.map(field => {
    const dataField = field.replace('oscar_', '');
    initialValues[field] =
      get(data, `online_portals[1].${dataField}`) ||
      (NULL_FIELDS.includes(field) ? null : '');
    const portalId = get(data, `online_portals[1].id`) || '';
    if (portalId) {
      initialValues.oscar_portal_id = portalId;
    }
    return true;
  });

  return {
    ...initialValues,
    ...getFileFieldInitialValues(FILE_FIELDS, data, 'documents'),
    ...getFileYearInitialValues(data),
  };
};

export const getNewYorkBR = (values = {}) => {
  const { delete_attachments } = values;
  const bodyRequest = {
    ...pick(values, BODY_REQUEST_NORMAL_FIELDS),
  };
  DATE_FIELDS.map(field => {
    bodyRequest[field] = values[field] ? getBEDate(values[field]) : '';
    return true;
  });
  NUMBER_FIELDS.map(field => {
    bodyRequest[field] = parseStringToNumber(values[field]);
    return true;
  });
  const fData = new FormData();
  Object.keys(bodyRequest).map(k => {
    fData.append(
      k,
      bodyRequest[k] || bodyRequest[k] === 0 ? bodyRequest[k] : ''
    );
    return true;
  });
  FILE_FIELDS.map(originKey => {
    const key = getFileField(originKey);
    if (values[key] && values[key].length > 0) {
      values[key].map(file => {
        if (file.originFileObj && !file.id) {
          fData.append(`${key}[]`, file.originFileObj);
        }
        return true;
      });
    } else {
      fData.append(key, '');
    }
    return true;
  });
  PORTAL_1_FIELDS.map(field => {
    const dataField = field.replace('hut_', '');
    fData.append(`online_portal[0][${dataField}]`, values[field] || '');
    return true;
  });
  fData.append(`online_portal[0][type]`, 'ny_hut');
  if (values.hut_portal_id) {
    fData.append(`online_portal[0][id]`, values.hut_portal_id);
  }
  PORTAL_2_FIELDS.map(field => {
    const dataField = field.replace('oscar_', '');
    fData.append(`online_portal[1][${dataField}]`, values[field] || '');
    return true;
  });
  fData.append(`online_portal[1][type]`, 'oscar');
  if (values.oscar_portal_id) {
    fData.append(`online_portal[1][id]`, values.oscar_portal_id);
  }
  if (delete_attachments && delete_attachments.length > 0) {
    delete_attachments.map(id => {
      fData.append('delete_documents[]', id);
      return true;
    });
  }
  return fData;
};

export const newYorkTransformData = (data, hutPortalLink, oscarPortalLink) => {
  const onlinePortal1 = get(data, 'online_portals[0]') || {};
  const onlinePortal2 = get(data, 'online_portals[1]') || {};
  return {
    ...data,
    hut_username: onlinePortal1.username,
    hut_password: onlinePortal1.password,
    hut_portal_link: hutPortalLink || onlinePortal1.portal_link,
    oscar_username: onlinePortal2.username,
    oscar_password: onlinePortal2.password,
    oscar_portal_link: oscarPortalLink || onlinePortal2.portal_link,
  };
};
