import * as React from 'react';
import cn from 'classnames';
import { Timeline as AntTimeline } from 'antd';
import classes from './Timeline.module.scss';

const { Item: TimelineItem } = AntTimeline;

const Timeline = ({ items, className, ...extraProps }) => {
  return (
    <AntTimeline {...extraProps} className={cn(classes.wrapper, className)}>
      {items.map((item, index) => {
        const { content, ...extraItemProps } = item;
        return (
          <TimelineItem key={index} {...extraItemProps}>
            {content || ''}
          </TimelineItem>
        );
      })}
    </AntTimeline>
  );
};
export { TimelineItem };
export default Timeline;
