import { getCheckAddItems, getFieldNameWithIndex } from '../../utils';
import { SUB_RECORD_PREFIX } from '../../constants';

// Handle primary contact checkbox because it is checked only once time
export const updateOtherPrimaryContact = ({
  form,
  addItems,
  nameIndex,
  inputField,
}) => {
  // Set form values for the rest primary contact checkboxes to false
  form.setFields(
    getCheckAddItems(addItems)
      .filter(aItem => aItem.id !== nameIndex)
      .map(aItem => {
        return {
          name: getFieldNameWithIndex(inputField, aItem.id),
          value: false,
          errors: null,
        };
      })
  );
};
// Filter option for Select subrecord dropdown to make sure don't duplicate for every sub-records
export const filterMainOptions = (options, form, name, addItems) => {
  // Get values of form
  const values = form.getFieldsValue();
  // Split name string to get nameIndex(id)
  const thisNameArr = (name || '').split(SUB_RECORD_PREFIX) || [];
  const thisId = thisNameArr[1];
  // Get list multiple field nameIndex(id)
  const listMultipleIds = getCheckAddItems(addItems).map(item => item.id);
  // array to collect value of this selectbox at other cloned sections
  const otherSelectValues = [];
  listMultipleIds.map(id => {
    const thisValueForId = values[`${thisNameArr[0]}${SUB_RECORD_PREFIX}${id}`];
    // Exclude value is '0' because it is create new option
    if (
      String(id) !== String(thisId) &&
      !!thisValueForId &&
      thisValueForId !== '0'
    ) {
      otherSelectValues.push(thisValueForId);
    }
    return true;
  });
  // Filter options to exclude value of this selectbox at other cloned sections
  return options.filter(option => !otherSelectValues.includes(option.value));
};
