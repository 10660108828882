const initialFormsMSAState = {
  // GET
  formsMSAs: {
    loading: false,
    data: [],
    error: '',
  },

  // POST
  updateFormsMSA: {
    loading: false,
    data: {},
    error: '',
  },
  updateFormsMSAStatus: {
    loading: {},
    data: {},
    error: {},
  },
};

export { initialFormsMSAState };
