import React from 'react';
import cx from 'classnames';
import { List as AntdList } from 'antd';

import classes from './List.module.scss';

const { Item: ListItem } = AntdList;
const List = props => {
  const {
    notItemBorder,
    notItemPadding,
    paddingSize,
    className,
    ...other
  } = props;
  return (
    <AntdList
      locale={{
        emptyText: <></>,
      }}
      {...other}
      className={cx(
        classes.wrapper,
        {
          [classes.notItemBorder]: notItemBorder,
          [classes.notItemPadding]: notItemPadding,
          [classes.paddingMedium]: paddingSize === 'medium',
        },
        className
      )}
    />
  );
};
export { ListItem };
export default List;
