import React from 'react';
import get from 'lodash/get';
import NewOrderType from '../NewOrderType';

const OrderTypeDetail = ({ match }) => {
  const orderTypeId = get(match, 'params.id', '');
  if (orderTypeId && !isNaN(parseInt(orderTypeId, 10))) {
    return <NewOrderType isDetail id={orderTypeId} />;
  }
  return '';
};

export default OrderTypeDetail;
