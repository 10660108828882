import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { Input, Select } from 'components/FormFields';
import { STATUS_VALUES } from 'helper/const';
import classes from './ClientList.module.scss';

export const getFilterMenus = ({ onFilterChange, stateOptions }) => {
  const menuLabel = [
    {
      subs: {
        label: (
          <div className={cn(classes.filterLabelWrapper, {})}>
            Filter Options
          </div>
        ),
      },
    },
  ];
  const menus = [
    ...menuLabel,
    {
      subs: {
        label: (
          <div className={classes.filterForm}>
            <span>Status</span>
            <Select
              items={[
                {
                  value: 'all',
                  label: 'All',
                },
                {
                  value: STATUS_VALUES.ACTIVE,
                  label: 'Active',
                },
                {
                  value: STATUS_VALUES.IN_ACTIVE,
                  label: 'Inactive',
                },
              ]}
              placeholder={'All'}
              showSearch
              size="medium"
              name={'status'}
              onChange={val => {
                onFilterChange('status', val);
              }}
              defaultValue="all"
            />
            <span>State</span>
            <Select
              items={[
                {
                  value: 'all',
                  label: 'All',
                },
                ...stateOptions,
              ]}
              placeholder={'All'}
              showSearch
              size="medium"
              name={'state'}
              onChange={val => {
                onFilterChange('state', val);
              }}
              defaultValue="all"
            />
            <span>City</span>
            <Input
              name="city"
              size="medium"
              prefix={<SearchOutlined />}
              isBlue
              allowClear
              placeholder="Filter by city"
              onChange={e => {
                onFilterChange('city', e.target.value);
              }}
            />
          </div>
        ),
      },
    },
  ];
  return menus;
};
