import React from 'react';
import { Card as AntdCard } from 'antd';
import cx from 'classnames';

import classes from './Card.module.scss';

const Card = ({ className, ...other }) => {
  return <AntdCard {...other} className={cx(classes.wrapper, className)} />;
};

export default Card;
