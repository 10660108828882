import React from 'react';
import findIndex from 'lodash/findIndex';
import cn from 'classnames';
import { getSearchedChatMembers } from 'utils/chat';
import { Contact } from './components/Elements';
import { useNewChatContentData } from './selectorData';
import classes from './Chat.module.scss';

const NewChatContent = ({
  isCollapse,
  selectedContacts,
  handleSelectContact,
  handleDeselectContact,
  isClientTab,
  permission,
}) => {
  const {
    internalSearchedMembers,
    clientSearchedMembers,
  } = useNewChatContentData();
  const isAddDisabled = isClientTab && selectedContacts.length > 0;
  const existingContacts = (isClientTab
    ? clientSearchedMembers
    : internalSearchedMembers
  )
    .map(m =>
      getSearchedChatMembers(
        m,
        isClientTab && !permission.showDepartmentAtIndividual
      )
    )
    .filter(m => findIndex(selectedContacts, { user_id: m.user_id }) < 0);
  const existingContactLength = existingContacts.length;
  return (
    <div
      className={cn(classes.newChatContent, {
        [classes.existingLength0]: existingContactLength === 0,
        [classes.existingLength1]: existingContactLength === 1,
      })}
    >
      <div className={classes.existingContacts}>
        {existingContacts.map((c, i) => {
          const contact = {
            ...c,
            checked: false,
          };
          return (
            <Contact
              {...contact}
              key={i}
              isCollapse={isCollapse}
              isAddContact
              onClick={() => {
                if (!isAddDisabled) {
                  handleSelectContact(contact);
                }
              }}
              isClient={isClientTab && !permission.showDepartmentAtIndividual}
              isAddDisabled={isAddDisabled}
              hideUnread
            />
          );
        })}
      </div>
      {selectedContacts.length > 0 && (
        <Contact
          isCollapse={isCollapse}
          className={classes.selectedLabel}
          hideUnread
        >
          <span>Selected Contacts</span>
        </Contact>
      )}
      <div className={classes.selectedContacts}>
        <div>
          {selectedContacts.map((c, i) => {
            const contact = {
              ...getSearchedChatMembers(c, isClientTab),
              checked: true,
            };
            return (
              <Contact
                {...contact}
                key={i}
                isCollapse={isCollapse}
                isAddContact
                onClick={() => {
                  handleDeselectContact(contact);
                }}
                isClient={isClientTab}
                hideUnread
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NewChatContent;
