import React from 'react';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import {
  Section,
  FormSection,
  ListInfo,
} from 'views/pages/ClientDetail/components/Elements';
import { PROFILE_OBJ_KEYS } from '../../tabConstants/irp';
import classes from './IRP.module.scss';

const Profile = ({ isEdit, form, data }) => {
  return (
    <Section className={classes.profile}>
      {isEdit ? (
        <FormSection>
          {/* Use it to save delete_attachments for attachment removing */}
          <InputItem name={'delete_attachments'} label="" form={form} hide />
          <InputItem name={'portal_id'} label="" form={form} hide />
          <InputItem
            name={'number'}
            label="IRP Number"
            form={form}
            placeholder="000-000000"
          />
          <InputItem
            name={'expiration_date'}
            label="Expiration Date"
            form={form}
            type="date"
            placeholder="01/01/2000"
          />
        </FormSection>
      ) : (
        <ListInfo objKeys={PROFILE_OBJ_KEYS} data={data} />
      )}
    </Section>
  );
};

export default Profile;
