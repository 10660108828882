import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as authSelectors from 'store/selectors/auth';
import * as notificationSelectors from 'store/selectors/notification';
// ACTIONS
import * as authActions from 'store/actions/auth';

export const useIndexData = () => {
  return {
    currentUser: authSelectors.useSelectCurrentUser(),
    isLoggedIn: !!authSelectors.useSelectAccessToken(),
    notificationUnreadCount: notificationSelectors.useSelectNotificationUnreadCount(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...authActions,
  });
};
