import * as React from 'react';
import cn from 'classnames';
import { ReactComponent as LogoSvg } from 'assets/icons/Logo.svg';
import PublicLayoutWrapper from 'components/PublicLayoutWrapper';
import classes from './Public.module.scss';

const PublicLayout = ({
  component: Component,
  printCard,
  isWhiteBg,
  hideFooter,
  ...otherProps
}) => {
  const goToHomePage = () => {
    window.location.href = '/';
  };
  const footer = () => {
    return (
      <div className={classes.footer}>
        <span>©</span>2021 RoadOffice | PO BOX 546, Grand View, ID 83624
      </div>
    );
  };
  return (
    <PublicLayoutWrapper
      className={cn(classes.wrapper, {
        [classes.isWhiteBg]: isWhiteBg,
      })}
    >
      <div className={classes.header}>
        <LogoSvg onClick={goToHomePage} className={classes.logo} />{' '}
        <span onClick={goToHomePage}>RoadOffice</span>
      </div>
      <div
        className={cn(classes.publicCard, {
          [classes.printCard]: printCard,
        })}
      >
        <Component {...otherProps} />
      </div>
      {!hideFooter ? footer() : ''}
    </PublicLayoutWrapper>
  );
};

export default PublicLayout;
