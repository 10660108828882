import Request from './request';

export const apiGetAccounts = async (id, filters = {}) => {
  return Request.call({
    url: `/company/${id}/accounts/list`,
    method: 'GET',
    params: filters,
  });
};
export const apiGetAccount = async (companyId, accountId) => {
  return Request.call({
    url: `/company/${companyId}/accounts/${accountId}`,
    method: 'GET',
  });
};
export const apiGetFuelCards = async (id, filters = {}) => {
  return Request.call({
    url: `/company/${id}/payment-cards/list`,
    method: 'GET',
    params: filters,
  });
};
export const apiGetFuelCardDetail = async (id, cardId, filters = {}) => {
  return Request.call({
    url: `/company/${id}/payment-cards/${cardId}`,
    method: 'GET',
    params: filters,
  });
};

export const apiCreatePaymentAccount = async (companyId, data) => {
  return Request.call({
    url: `/company/${companyId}/accounts`,
    method: 'POST',
    data,
  });
};
export const apiUpdatePaymentAccount = async (companyId, accountId, data) => {
  return Request.call({
    url: `/company/${companyId}/accounts/${accountId}`,
    method: 'POST',
    data,
  });
};
export const apiCreateFuelCard = async (companyId, data) => {
  return Request.call({
    url: `/company/${companyId}/payment-cards`,
    method: 'POST',
    data,
  });
};
export const apiUpdateFuelCardDetail = async (id, cardId, data = {}) => {
  return Request.call({
    url: `/company/${id}/payment-cards/${cardId}`,
    method: 'POST',
    data,
  });
};
export const apiDeleteFuelCard = async (id, cardId) => {
  return Request.call({
    url: `/company/${id}/payment-cards/${cardId}`,
    method: 'DELETE',
    data: {},
  });
};
