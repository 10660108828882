import debounce from 'lodash/debounce';

const applySearch = debounce((val, cb) => {
  cb(val);
}, 300);
const applyFilter = debounce((val, cb) => {
  cb(val);
}, 500);
const searchBy = (arr, value, key = 'name') => {
  const lowerCaseValue = (value || '').toLowerCase().trim();
  return (arr || []).filter(obj =>
    (obj[key] || '').toLowerCase().includes(lowerCaseValue)
  );
};

const scrollChatContentToBottom = () => {
  setTimeout(() => {
    const chatContentClient = document.getElementById(
      'chat-messages-wrapper-client'
    );
    const chatContentInternal = document.getElementById(
      'chat-messages-wrapper-internal'
    );
    if (chatContentClient || chatContentInternal) {
      if (chatContentClient) {
        chatContentClient.scrollTop = chatContentClient.scrollHeight;
      }
      if (chatContentInternal) {
        chatContentInternal.scrollTop = chatContentInternal.scrollHeight;
      }
    }
  }, 0);
};

export { applySearch, applyFilter, searchBy, scrollChatContentToBottom };
