// TODO: need to define the rest field widths
export const REPORT_FIELD_WIDTH = {
  truck_unit: 105,
  vin: 155,
  gvwr: 105,
  lp_exp_date: 180,
  driver: 145,
  trailer: 145,
  year: 95,
  make: 145,
  dl_expiration_date: 155,
  mc_expiration_date: 155,
  mvr_expiration_date: 155,
  first_name: 155,
  last_name: 155,
  order_name: 155,
  id: 125,
};
