import React, { useRef, useState, useEffect } from 'react';
import get from 'lodash/get';
import { DownloadOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { downloadFile } from 'utils/file';
import { getChatMessageTime } from 'utils/date';
import Tooltip from 'components/Tooltip';
import ChatAvatar from './ChatAvatar';
import MessageLike from './MessageLike';
import { departmentColorMapping } from './utils';
import classes from './Elements.module.scss';

const Attachment = ({ name, className, url }) => {
  let timeoutTooltip = null;
  const containerRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(true);
  timeoutTooltip = setTimeout(() => {
    const scrollWidth = get(containerRef, 'current.scrollWidth', 0);
    const clientWidth = get(containerRef, 'current.clientWidth', 0);
    if (scrollWidth <= clientWidth && scrollWidth !== 0 && clientWidth !== 0) {
      setShowTooltip(false);
    } else {
      if (!showTooltip) {
        setShowTooltip(true);
      }
    }
    clearTimeout(timeoutTooltip);
    timeoutTooltip = null;
  }, 100);
  const tooltipProps = {};
  if (!showTooltip) {
    tooltipProps.visible = false;
  }
  useEffect(() => {
    return () => {
      clearTimeout(timeoutTooltip);
    };
  }, [timeoutTooltip]);

  return (
    <Tooltip
      key="attachment-name"
      title={name}
      overlayClassName={classes.attachmentTooltip}
      placement="topLeft"
      {...tooltipProps}
    >
      <div
        className={cn(classes.messageAttachment, className)}
        ref={containerRef}
      >
        <DownloadOutlined
          onClick={() => {
            downloadFile(`${url}?download`, name);
          }}
        />
        <span
          onClick={() => {
            window.open(url, '_blank');
          }}
        >
          {name}
        </span>
      </div>
    </Tooltip>
  );
};

const Message = ({
  avatar,
  name,
  isMe,
  departmentColor,
  likeCount,
  unLikeCount,
  attachments,
  message,
  created_at,
  group,
}) => {
  const hasAttachment = !!get(attachments, '[0].id', '');
  return (
    <div
      className={cn(classes.messageBlock, {
        [classes.isMe]: isMe,
        [classes.isAttachmentOnly]: hasAttachment && !message,
        [classes.hasActionCount]: likeCount > 0 || unLikeCount > 0,
        [classes.isClient]: !group,
      })}
    >
      <ChatAvatar
        avatar={avatar}
        name={isMe ? 'ME' : name || 'Sender'}
        departmentColor={departmentColor}
        isMe={isMe || !group}
      />
      <div className={classes.messageContentBlock}>
        <div className={classes.messageInfo}>
          {!isMe && `${name || 'Sender'}, `}
          {created_at ? getChatMessageTime(created_at) : ''}
        </div>
        <div
          className={cn(
            classes.messageContent,
            classes[departmentColorMapping[departmentColor]] || classes.other
          )}
        >
          {message}
          <MessageLike likeCount={likeCount} unLikeCount={unLikeCount} />
        </div>
        {hasAttachment && (
          <div
            className={cn(classes.messageAttachments, {
              [classes.isMe]: isMe,
            })}
          >
            {attachments.map((attachment, i) => {
              return <Attachment {...attachment} key={i} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Message;
