import React from 'react';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { standardRequiredFieldObj } from 'utils/form';
import { getMomentDate, getBEDate, getDateFromMMYY } from 'utils/date';
import { MODAL, DATE_TIME_FORMAT } from 'helper/const';
import Button from 'components/Button';
import classes from './NewOrderModal.module.scss';
import {
  SUB_RECORD_PREFIX,
  MULTIPLE_FIELD_PREFIX,
  ARRAY_FIELD_CATEGORY,
  steps,
} from './constants';

// Check the field is primary contact checkbox
export const isPrimaryContactField = field => {
  return field?.target_column === 'is_primary' && field?.type === 'checkbox';
};
// Get sub-records including main record, main record always has id is 0.
export const getCheckAddItems = addItems => {
  if (addItems && addItems.length > 0) {
    return [{ id: 0 }, ...addItems];
  }
  return [{ id: 0 }];
};
// Get correct field name with nameIndex(id) from sub-records
export const getFieldNameWithIndex = (inputField, nameIndex) => {
  return `field-${inputField.field_id}${`${SUB_RECORD_PREFIX}${nameIndex}`}`;
};
// Check section is multiple sub-records
export const getIsArrayField = cat => {
  return ARRAY_FIELD_CATEGORY.includes(cat);
};
// Check field is Select sub-record (Main field - without id but has prefix)
export const getIsMainSelectField = name => {
  let isManField = false;
  MULTIPLE_FIELD_PREFIX.map(f => {
    if ((name || '').includes(`field-${f}${SUB_RECORD_PREFIX}`)) {
      isManField = true;
    }
    return true;
  });
  return isManField;
};
// Get Field prefix from category data
export const getFieldPrefixFromCategory = category => {
  return get(category, 'fields[0].fields[0].org_field_id');
};
// Get Required attribute for Primary Contact, it can be required at only once record
export const getIsPCRequired = ({
  form,
  addItems,
  inputField,
  isRequired,
  nameIndex,
}) => {
  const values = form.getFieldsValue();
  let thisRequired = !!isRequired;
  const listPCValues = getCheckAddItems(addItems).map(aItem => {
    return {
      id: aItem.id,
      value: values[getFieldNameWithIndex(inputField, aItem.id)],
    };
  });
  const checkedItem = find(listPCValues, { value: true }) || null;
  if (checkedItem && checkedItem.id !== nameIndex) {
    thisRequired = false;
  }
  return thisRequired;
};
// Get other field prefix
export const getOtherFieldPrefix = cat => (cat === 'Other' ? 'other-' : '');
export const getModalProps = ({
  isOpen,
  form,
  handleNextClick,
  saveDraft,
  closeModal,
  loading,
  currentStep,
  navigateToPreviousStep,
}) => {
  return {
    isOpen,
    title: <>Create New Order</>,
    maskClosable: false,
    closable: true,
    onClose: closeModal,
    width: MODAL.SIZE.SEMI_LARGE + 10,
    className: classes.modal,
    closeIcon: null,
    blueColor: true,
    footer: (
      <>
        <div>
          <Button onClick={saveDraft} size="medium" type="default">
            Save as Draft
          </Button>
        </div>
        <div className={classes.footer}>
          {currentStep === 0 ? (
            <Button onClick={closeModal} size="medium" type="default">
              Cancel
            </Button>
          ) : (
            <Button
              onClick={() => {
                navigateToPreviousStep();
              }}
              size="medium"
              type="default"
            >
              Back
            </Button>
          )}
          <Button
            type="primary"
            onClick={() => {
              const values = form.getFieldsValue();
              handleNextClick(values);
            }}
            loading={loading}
            size="medium"
          >
            {currentStep === steps.length - 1 ? 'Submit' : 'Next'}
          </Button>
        </div>
      </>
    ),
  };
};

export const getInitialValues = orderData => {
  const { payment_method, is_rush_order, payment_method_id } = orderData || {};
  return {
    order_type_id: orderData?.order_type_id || null,
    order_type: null,
    requested_by: orderData?.requested_by || null,
    notes: orderData?.notes || '',
    payment_method: payment_method || 'card',
    payment_method_id: payment_method_id || null,
    is_rush_order,
    rush_order: is_rush_order ? ['rush_order'] : [],
    order_fields: [],
    price: !!orderData?.price ? parseFloat(orderData.price) : 0,
  };
};
export const orderTypeValidateObj = {
  order_type_id: [standardRequiredFieldObj],
  requested_by: [standardRequiredFieldObj],
};
export const orderDetailsValidateObj = (orderDetailFields, addItems, form) => {
  const validationObj = {};
  orderDetailFields.map(field => {
    return field.fields.map(category => {
      const tFieldPrefix = getFieldPrefixFromCategory(field);
      const tAddItems = addItems[tFieldPrefix] || [];
      return category.fields.map(inputField => {
        if (inputField.is_required) {
          const isArrayField = getIsArrayField(
            field.parent_category,
            category.category
          );
          // If field is multiple fields, need to append all cloned field at cloned sub-records at validation
          if (isArrayField) {
            getCheckAddItems(tAddItems).map(aItem => {
              const isPrimaryContact = isPrimaryContactField(inputField);
              // If field is Primary contact, only check require if all checkboxes are not checked
              if (
                (isPrimaryContact &&
                  getIsPCRequired({
                    form,
                    addItems: tAddItems,
                    inputField,
                    isRequired: inputField.is_required,
                    nameIndex: aItem.id,
                  })) ||
                !isPrimaryContact
              ) {
                validationObj[getFieldNameWithIndex(inputField, aItem.id)] = [
                  standardRequiredFieldObj,
                ];
              }

              return true;
            });
          } else {
            validationObj[getFieldNameWithIndex(inputField, 0)] = [
              standardRequiredFieldObj,
            ];
          }
        }
        return inputField;
      });
    });
  });
  return validationObj;
};
export const paymentValidateObj = (values, permission) => {
  const validateArray = [
    {
      name: ['payment_method_id'],
      rules: [standardRequiredFieldObj],
    },
  ];
  if (permission.editOrderPrice) {
    validateArray.push({
      name: ['price'],
      rules: [standardRequiredFieldObj],
    });
  }
  if (values.payment_method === 'account' && values.payment_method_id === 0) {
    validateArray.push({
      name: ['account', 'name_on_account'],
      rules: [standardRequiredFieldObj],
    });
    validateArray.push({
      name: ['account', 'routing_number_ach'],
      rules: [standardRequiredFieldObj],
    });
    validateArray.push({
      name: ['account', 'account_number'],
      rules: [standardRequiredFieldObj],
    });
    validateArray.push({
      name: ['account', 'type'],
      rules: [standardRequiredFieldObj],
    });
  } else if (
    values.payment_method === 'card' &&
    values.payment_method_id === 0
  ) {
    validateArray.push({
      name: ['card', 'card_number'],
      rules: [standardRequiredFieldObj],
    });
    validateArray.push({
      name: ['card', 'cvv'],
      rules: [standardRequiredFieldObj],
    });
    validateArray.push({
      name: ['card', 'expiration_date'],
      rules: [standardRequiredFieldObj],
    });
    validateArray.push({
      name: ['card', 'zip_code'],
      rules: [standardRequiredFieldObj],
    });
    validateArray.push({
      name: ['card', 'address'],
      rules: [standardRequiredFieldObj],
    });
    validateArray.push({
      name: ['card', 'city'],
      rules: [standardRequiredFieldObj],
    });
    validateArray.push({
      name: ['card', 'state_id'],
      rules: [standardRequiredFieldObj],
    });
  }
  return validateArray;
};
export const ACCOUNTS_LIST = accounts => {
  const accountsList = [];
  accounts.map(account => {
    const accountNumber = account.account_number
      ? account.account_number.substr(account.account_number.length - 4)
      : '';
    const label = `${account.bank_name} ****${accountNumber}`;
    return accountsList.push({ label: label, value: account.account_id });
  });
  return accountsList;
};
export const CARDS_LIST = cards => {
  const cardsList = [];
  cards.map(card => {
    const cardNumber = card.last4 || '';
    const label = `${
      card.name_on_card ? `${card.name_on_card} ` : ''
    }****${cardNumber}`;
    return cardsList.push({ label: label, value: card.card_id || card.id });
  });
  return cardsList;
};
// Get all field-id which can be multiple fields
const getMultipleFieldIds = values => {
  const ids = [];
  Object.keys(values).map(k => {
    if (k.startsWith('field-') && k.includes(SUB_RECORD_PREFIX)) {
      const id = (k.split('|')[1] || '').split(SUB_RECORD_PREFIX)[0] || '';
      const intId = !!id ? parseInt(id, 10) : '';
      if (!ids.includes(id) && !!intId) {
        ids.push(intId);
      }
    }
    return true;
  });
  return ids;
};
export const prepareFormData = (
  form,
  orderDetailData,
  fromReminder,
  addItems,
  ignoreCard = false
) => {
  const fData = new FormData();
  const formData = form.getFieldsValue();
  const multipleFieldIds = getMultipleFieldIds(formData);
  Object.keys(formData).map(k => {
    const val = formData[k];
    const isDateField =
      typeof val === 'object' && !!val && val.isValid && val.isValid();
    const isOtherField = k.startsWith('other-field-');
    if (val !== undefined && val !== null) {
      // Is Normal field type object
      if (typeof val === 'object' && !isOtherField && !k.startsWith('field-')) {
        if (val[0] && (val[0].constructor.name === 'File' || val[0].url)) {
          val.forEach((file, index) => {
            if (file.originFileObj) {
              fData.append(`${k}-${index}`, file.originFileObj);
            } else {
              fData.append(`${k}-${index}`, JSON.stringify(file));
            }
          });
        } else {
          if (!ignoreCard || (ignoreCard && k !== 'card')) {
            if (k === 'card') {
              val.expiration_date = val.expiration_date
                ? val.expiration_date.format('MM/YYYY')
                : '';
            }
            fData.append(
              k,
              isDateField && !!val
                ? val.format(DATE_TIME_FORMAT.BE_DATE)
                : JSON.stringify(val)
            );
          }
        }
        // Is Normal field type not object
      } else if (!isOtherField && !k.startsWith('field-')) {
        const isIgnorePayment = ignoreCard && k === 'payment_method_id';
        if (!isIgnorePayment) {
          fData.append(k, val);
        }
      }
      // Is field of section
      if (k.startsWith('field-')) {
        // Clean unneeded prefix
        const fieldId = k.replace('field-', '');
        // Split by SUB_RECORD_PREFIX to have nameIndex(id) and trimFieldId
        const trimFieldArr = fieldId.split(SUB_RECORD_PREFIX);
        const trimFieldId = trimFieldArr[0] || '';
        const trimFieldIndex = trimFieldArr[1] || '';
        // Split trimfieldId to get field prefix and field-id
        const fieldIdArr = trimFieldId.split('|');
        const fieldIdOnly = fieldIdArr[1];
        const fiedIdPrefix = fieldIdArr[0];
        // Check field is Main field for special condition for main field because it doesn't have field-id
        const mainField =
          !fieldIdOnly && MULTIPLE_FIELD_PREFIX.includes(fiedIdPrefix);
        // Check field is Array Field
        const isArrayField =
          mainField ||
          (!!fieldIdOnly && multipleFieldIds.includes(parseInt(fieldIdOnly)));
        // Get correct fieldIndex by nameIndex and addItems
        const tAddItems = addItems[fiedIdPrefix] || [];
        const thisFieldIndex = findIndex(tAddItems, {
          id: parseInt(trimFieldIndex, 10),
        });
        const fieldPrefix = fieldIdArr[0];
        // Create field id which we can append to all cases
        const appendFieldId = isArrayField
          ? `[${fieldPrefix}][${
              trimFieldIndex === 0 ? trimFieldIndex : thisFieldIndex + 1
            }]${mainField ? `[id]` : !!fieldIdOnly ? `[${fieldIdOnly}]` : ''}`
          : `[${trimFieldId}]`;
        if (typeof val === 'object') {
          if (val[0] && val[0].constructor.name === 'File') {
            val.forEach((file, index) => {
              fData.append(
                `order_fields${appendFieldId}[]`,
                file.originFileObj
              );
              // Append 1 more attr for file field to let BE know index of file
              fData.append(
                `field-${(fieldIdOnly || trimFieldId || '')
                  .replace('[', '')
                  .replace(']', '')}-${index}`,
                file.originFileObj
              );
            });
          } else {
            // Handle format for date field
            fData.append(
              `order_fields${appendFieldId}`,
              !!val.format ? getBEDate(val) : JSON.stringify(val)
            );
          }
        } else {
          // Exclude main field with value is '0' (it is create new option)
          if (!(mainField && val === '0')) {
            fData.append(`order_fields${appendFieldId}`, val);
          }
        }
        // Is Other fields
      } else if (isOtherField) {
        const fieldId = k.replace('other-field-', '');
        if (typeof val === 'object') {
          if (val[0] && val[0].constructor.name === 'File') {
            val.forEach((file, index) => {
              fData.append(
                `order_other_fields[${fieldId}]`,
                file.originFileObj
              );
              fData.append(`other_fields-${fieldId}`, file.originFileObj);
            });
          } else {
            fData.append(
              `other_fields-${fieldId}`,
              isDateField && !!val
                ? val.format(DATE_TIME_FORMAT.BE_DATE)
                : JSON.stringify(val)
            );
            fData.append(
              `order_other_fields[${fieldId}]`,
              isDateField && !!val
                ? val.format(DATE_TIME_FORMAT.BE_DATE)
                : JSON.stringify(val)
            );
          }
        } else {
          fData.append(`other_fields-${fieldId}`, val);
          fData.append(`order_other_fields[${fieldId}]`, val);
        }
      }
    }
    return true;
  });
  fData.append('is_rush_order', formData.rush_order.length > 0 ? '1' : '0');
  if (orderDetailData?.id) {
    fData.append('id', orderDetailData?.id);
  }
  if (fromReminder) {
    fData.append('is_order_from_reminder', 1);
  }
  // for (const pair of fData.entries()) {
  //   console.log(`${pair[0]}, ${pair[1]}`);
  // }
  return fData;
};
const setSubSectionFormValues = (categories, form, nameIndex, fieldPrefix) => {
  categories.fields.map(fields => {
    fields.fields.map(inputField => {
      let value = inputField.field_value;
      if (value) {
        const fieldType = inputField.type;
        const isMultiSelect = inputField.multiselect;
        if (fieldType === 'select') {
          if (value) {
            if (isMultiSelect) {
              if (typeof value === 'object' && isArray(value)) {
                value = value.map(v =>
                  !isNaN(v) && typeof v === 'string' ? parseInt(v, 10) : v
                );
              }
            } else {
              if (!isNaN(value) && typeof value === 'string') {
                value = parseInt(value, 10);
              } else if (typeof value === 'object') {
                value = null;
              }
            }
          }
        } else if (fieldType === 'file') {
          if (value && isArray(value)) {
            value = value.map(f => ({
              ...f,
              name: f.document_name || '',
            }));
          }
        } else if (fieldType === 'date') {
          value =
            value && typeof value === 'string'
              ? getMomentDate(value, true)
              : null;
        } else {
          if (typeof value === 'object') {
            value = null;
          }
        }
      } else if (inputField.field_id === fieldPrefix) {
        value = '0';
      }
      const tName = `${getOtherFieldPrefix(
        fields.category
      )}${getFieldNameWithIndex(inputField, nameIndex)}`;
      form.setFields([
        {
          name: tName,
          value: value,
        },
        {
          name: `field-forms|${inputField.field_id}`,
          value: value,
        },
      ]);
      return true;
    });
    return true;
  });
};
export const prefillFormEditMode = (fieldValues, form, setAddItems) => {
  const addItems = {};
  fieldValues.map(categories => {
    const parentCat = (categories.parent_category || '')
      .toLowerCase()
      .replace(' ', '-');
    const thisDuplicateCat = addItems[parentCat];
    if (!thisDuplicateCat) {
      addItems[parentCat] = [];
      setSubSectionFormValues(categories, form, 0, parentCat);
    } else {
      const newLength = thisDuplicateCat.length + 1;
      setSubSectionFormValues(categories, form, newLength, parentCat);
      addItems[parentCat].push({
        id: newLength,
      });
    }
    return true;
  });
  setAddItems(addItems);
};
export const getCardBR = (card = {}) => {
  return {
    ...card,
    expiration_date: card.expiration_date
      ? getDateFromMMYY(card.expiration_date, '/', true)
      : '',
    is_primary: 0,
  };
};
