import React from 'react';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import {
  Section,
  FormSection,
  ListInfo,
} from 'views/pages/ClientDetail/components/Elements';
import { SCAC_OBJ_KEYS } from '../../tabConstants/general';

const SCAC = ({ isEdit, form, data }) => {
  return (
    <Section>
      {isEdit ? (
        <FormSection>
          <InputItem
            name={'assignment_letter'}
            label="Assignment Letter"
            form={form}
            type="file"
          />
          <InputItem
            name={'scac_expiration_date'}
            label="Expiration Date"
            form={form}
            type="date"
            placeholder="01/01/2000"
            disabled
          />
          <InputItem
            name={'scac_code'}
            label="SCAC Code"
            form={form}
            placeholder="0000"
            type="number"
            mask="****"
          />
        </FormSection>
      ) : (
        <ListInfo objKeys={SCAC_OBJ_KEYS} data={data} />
      )}
    </Section>
  );
};

export default SCAC;
