import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Button, { ButtonColors } from 'components/Button';
import DragDrop from 'components/DragDrop';
import Form, { useForm, FormList, FormItem } from 'components/Form';
import { Title } from '../Elements';
import NewForm from './components/NewForm';
import ChecklistField from './components/ChecklistField';
import classes from './Checklist.module.scss';

const Checklist = ({
  className,
  data,
  onNew,
  addChecklistLoading,
  onOrder,
  onRemove,
  onUpdate,
  onCheckedChange,
  updateChecklistLoading,
  names = {},
  title = null,
}) => {
  const [form] = useForm();
  const [newChecklist, setNewChecklist] = useState({});
  const [editKey, setEditKey] = useState({});
  const isNewChecklist = !isEmpty(newChecklist);
  const displayData = data || [];
  const listName = names.list || 'checklists';
  const fieldName = names.description || 'description';
  const checkedName = names.isChecked || 'is_checked';
  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        checklists: displayData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayData]);

  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.innerContent}>
        <Form
          onFinish={values => {}}
          initialValues={{
            [listName]: displayData,
          }}
          form={form}
          className={classes.checklists}
        >
          <FormItem shouldUpdate className={classes.titleWrapper}>
            {() => {
              const values = form.getFieldsValue();
              const checkedItems = (values[listName] || []).filter(
                c => !!c[checkedName]
              );
              return (
                title || (
                  <Title
                    subTitle={
                      <>
                        Completed <span>{checkedItems.length}</span> of{' '}
                        <span>{(values[listName] || []).length}</span>
                      </>
                    }
                  >
                    Checklist
                  </Title>
                )
              );
            }}
          </FormItem>
          <FormList name={listName}>
            {(fields, { add, remove, move }) => {
              const values = form.getFieldsValue();
              const items = fields.map((field, index) => {
                const obj = get(values, `${listName}[${index}]`) || {};
                const isEdit = editKey[obj.id];
                return {
                  id: obj.id || index,
                  content: (
                    <ChecklistField
                      name={[index, fieldName]}
                      form={form}
                      value={obj[fieldName] || ''}
                      isEdit={isEdit}
                      setIsEdit={newIsEdit => {
                        setEditKey({
                          ...editKey,
                          [obj.id]: newIsEdit,
                        });
                      }}
                      listName={listName}
                      onSave={(value, cb) => {
                        onUpdate(obj, value, cb);
                      }}
                      onDelete={() => {
                        remove(index);
                        onRemove(obj);
                      }}
                      loading={updateChecklistLoading[obj.id]}
                      isChecked={obj[checkedName]}
                      setIsChecked={newChecked => {
                        onCheckedChange(obj, newChecked);
                        const values = form.getFieldsValue();
                        const newArr = [...values[listName]];
                        newArr[index][checkedName] = newChecked;
                        form.setFieldsValue({
                          [listName]: newArr,
                        });
                      }}
                    />
                  ),
                  disabled: isEdit,
                };
              });
              return (
                <DragDrop
                  items={items}
                  onIndexChange={(oldIndex, newIndex) => {
                    move(oldIndex, newIndex);
                  }}
                  onDropCard={() => {
                    setTimeout(() => {
                      const values = form.getFieldsValue();
                      onOrder(values[listName] || []);
                    }, 0);
                  }}
                  cardClassName={classes.dndCard}
                  wholeHandler
                />
              );
            }}
          </FormList>
        </Form>
        {isNewChecklist ? (
          <NewForm
            className={classes.newBlock}
            onCancel={() => {
              setNewChecklist({});
            }}
            onSubmit={values => {
              onNew(values, () => {
                setNewChecklist({});
              });
            }}
            loading={addChecklistLoading}
          />
        ) : (
          <Button
            color={ButtonColors.BLUE}
            isLink
            className={classes.addItemBtn}
            onClick={() => {
              setNewChecklist({
                id: new Date().getTime(),
              });
            }}
          >
            + Add Item
          </Button>
        )}
      </div>
    </div>
  );
};

export default Checklist;
