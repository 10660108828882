import get from 'lodash/get';
import { FILE_FIELDS } from './constants';
import { getFileFieldInitialValues } from 'utils/form';
import { getOtherDataValue } from 'utils/customField';

export const parseMSAs = formsMSAs => {
  return (formsMSAs || []).map(msa => {
    const msaFileObj = msa.documents?.msa;
    return {
      ...msa,
      msa: msaFileObj
        ? [
            {
              ...msaFileObj,
              uid: msaFileObj.id,
            },
          ]
        : null,
    };
  });
};

export const getInitialValues = (data, formsMSAs) => {
  const initialValues = {
    delete_attachments: [],
    customers: parseMSAs(formsMSAs),
    delete_custom_fields: [],
    other: getOtherDataValue(data.custom_fields || []),
  };
  return {
    ...initialValues,
    ...getFileFieldInitialValues(FILE_FIELDS, data),
  };
};
export const validateObj = {};

export const getFormsBR = (values = {}) => {
  const { delete_attachments, customers, other, delete_custom_fields } = values;
  const fData = new FormData();
  if (customers && customers.length) {
    customers.map((customer, index) => {
      fData.append(`msas[${index}][customer_name]`, customer.customer_name);
      const { msa } = customer;
      if (msa && msa.length && msa[0].originFileObj) {
        fData.append(`msas[${index}][msa]`, msa[0].originFileObj);
      }
      if (customer.id) {
        fData.append(`msas[${index}][msa_id]`, customer.id);
      }
      if (customer.status) {
        fData.append(`msas[${index}][status]`, customer.status);
      }
      return true;
    });
  }
  FILE_FIELDS.map(originKey => {
    const key = originKey;
    if (values[key] && values[key].length > 0) {
      values[key].map(file => {
        if (file.originFileObj && !file.id) {
          fData.append(`${key}[]`, file.originFileObj);
        }
        return true;
      });
    } else {
      fData.append(key, '');
    }
    return true;
  });
  if (delete_attachments && delete_attachments.length > 0) {
    delete_attachments.map(id => {
      fData.append('delete_documents[]', id);
      return true;
    });
  }
  if (other && other.length > 0) {
    other.map((field, index) => {
      fData.append(`custom_fields[${index}][field_name]`, field.field_name);
      if (field.originId) {
        fData.append(`custom_fields[${index}][id]`, field.originId);
      }
      const firstDocument = get(field, 'document[0]') || null;
      const existingFileName = get(field, 'originData.document_name', '');
      if (firstDocument && !firstDocument.url) {
        fData.append(
          `custom_fields[${index}][document]`,
          firstDocument.originFileObj
        );
      } else if (!firstDocument && !!existingFileName) {
        fData.append(`custom_fields[${index}][document_delete]`, 1);
      }
      return true;
    });
  } else {
    fData.append('custom_fields', '');
  }
  if (delete_custom_fields && delete_custom_fields.length > 0) {
    delete_custom_fields.map(id => {
      fData.append('delete_custom_fields[]', id);
      return true;
    });
  }
  return fData;
};
