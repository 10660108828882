import React, { useState, useEffect } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import Modal from 'components/Modal';
import Form, { useForm, FormItem } from 'components/Form';
import { InputPassword, InputPasswordStrong } from 'components/FormFields';
import { actionCb } from 'utils/action';
import { validateForm, scrollToErrorField } from 'utils/form';
import { validateObj, getModalProps } from './utils';
import { useActions, useIndexData } from './selectorData';
import classes from './RequiredUpdatePassword.module.scss';

const RequiredUpdatePassword = ({ isOpen, handleClose }) => {
  const [step, setStep] = useState(1);
  const { currentPassword } = useIndexData();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [strongPassword, setStrongPassword] = useState([]);
  const [form] = useForm();
  const { changePassword } = useActions();
  const handleSubmitForm = values => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      const fieldErrors = validateForm(validateObj(), values);
      if (fieldErrors.length > 0) {
        form.setFields(fieldErrors);
        scrollToErrorField();
      } else {
        if (strongPassword.length === 3) {
          setSubmitLoading(true);
          changePassword(
            {
              password: currentPassword,
              new_password: values.password,
              confirm_new_password: values.confirmPassword,
            },
            actionCb(
              () => {
                setSubmitLoading(false);
                setStep(3);
              },
              () => {
                setSubmitLoading(false);
              }
            )
          );
        }
      }
    } else {
      window.location.href = '/';
    }
  };
  const modalProps = getModalProps({
    isOpen: true,
    form,
    handleSubmitForm,
    handleClose,
    loading: submitLoading,
    step,
  });
  useEffect(() => {
    if (isOpen && form) {
      form.resetFields();
      form.validateFields();
    }
  }, [form, isOpen]);

  return (
    <Modal {...modalProps}>
      <Form
        submitHidden
        onFinish={() => {}}
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        form={form}
        className={classes.form}
      >
        <div className="ant-modal-body-inner">
          {step === 1 ? (
            <div className={classes.step1}>
              <span>
                <InfoCircleOutlined />
              </span>
              <p>
                <span>Set Your Permanent Password</span>
                <span>
                  In order to proceed, you must replace your temporary password.
                </span>
              </p>
            </div>
          ) : (
            <div className={classes.step2}>
              <h2>{step === 3 ? 'Success!' : 'Password Reset'}</h2>
              <p>
                {step === 3
                  ? 'Your password has been reset.'
                  : 'Enter your new password below.'}
              </p>
              {step !== 3 && (
                <div className={classes.formInner}>
                  <InputPasswordStrong
                    passwordStrongChange={val => {
                      setStrongPassword(val);
                    }}
                    placeholder="New Password"
                    label="New Password"
                    form={form}
                    autoFocus={true}
                  />
                  <FormItem name={'confirmPassword'} label="Confirm Password">
                    <InputPassword
                      size={'large'}
                      placeholder={'Confirm Password'}
                      name="confirmPassword"
                      form={form}
                    />
                  </FormItem>
                </div>
              )}
            </div>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default RequiredUpdatePassword;
