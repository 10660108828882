import Request from './request';

// GET
export const apiGetClientOrderFieldValues = async (id, orderId) => {
  return Request.call({
    url: `/company/${id}/order/${orderId}/order-fields`,
    method: 'GET',
  });
};
export const apiGetClientOrderChecklist = async (id, orderId) => {
  return Request.call({
    url: `/company/${id}/order/${orderId}/checklists`,
    method: 'GET',
  });
};
// POST
export const apiSaveClientOrderChecklist = async (id, orderId, data) => {
  return Request.call({
    url: `/company/${id}/order/${orderId}/checklists`,
    method: 'POST',
    data,
  });
};
export const apiMarkClientOrderChecklist = async (
  id,
  orderId,
  checklistId,
  isChecked
) => {
  return Request.call({
    url: `/company/${id}/order/${orderId}/checklists/${checklistId}/check-uncheck`,
    method: 'POST',
    data: {
      is_checked: isChecked,
    },
  });
};
export const apiDeleteClientOrderChecklist = async (
  id,
  orderId,
  checklistId
) => {
  return Request.call({
    url: `/company/${id}/order/${orderId}/checklists/${checklistId}`,
    method: 'DELETE',
  });
};
export const apiAddClientOrderNote = async (id, orderId, data) => {
  return Request.call({
    url: `/company/${id}/order/${orderId}/notes`,
    method: 'POST',
    data,
  });
};
