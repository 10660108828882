import React from 'react';
import { BANK_NUMBER_FIELD } from 'helper/const';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import {
  Section,
  FormSection,
  ListInfo,
} from 'views/pages/ClientDetail/components/Elements';
import { CA_NUMBER_OBJ_KEYS } from '../../tabConstants/general';

const CANumber = ({ isEdit, form, data }) => {
  return (
    <Section>
      {isEdit ? (
        <FormSection>
          <InputItem
            name={'ca_number'}
            label="CA Number"
            form={form}
            type="number"
            placeholder="00000000"
            mask={BANK_NUMBER_FIELD.MASK}
          />
          <InputItem
            name={'epn_code'}
            label="EPN Requestor Code"
            form={form}
            type="number"
            placeholder="00000000"
            mask={BANK_NUMBER_FIELD.MASK}
          />
        </FormSection>
      ) : (
        <ListInfo objKeys={CA_NUMBER_OBJ_KEYS} data={data} />
      )}
    </Section>
  );
};

export default CANumber;
