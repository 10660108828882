import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { FormItem } from 'components/Form';
import { PHONE_FIELD, FORMATS } from 'helper/const';
import { useIsSuperAdmin } from 'helper/hooks';
import { Input, InputMask, InputPassword } from 'components/FormFields';
import { getStrWithFormat } from 'utils/textFormat';
import { getMarkText } from 'utils/string';
import ChangePasswordModal from './ChangePasswordModal';
import classes from './MyProfile.module.scss';

const InformationForm = ({
  form,
  isEdit,
  currentUser,
  onDeleteAccountClick,
}) => {
  const isSuperAdmin = useIsSuperAdmin();
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [isViewPin, setIsViewPin] = useState(false);
  const currentUserName = `${currentUser.first_name ||
    ''} ${currentUser.last_name || ''}`;
  const changePasswordLink = (
    <div
      className={cn(classes.link, classes.changePasswordLink)}
      onClick={() => {
        setIsChangePassword(true);
      }}
    >
      Change Password
    </div>
  );

  useEffect(() => {
    setIsChangePassword(false);
    setIsViewPin(false);
  }, []);

  return (
    <div
      className={cn(classes.fields, {
        [classes.isView]: !isEdit,
      })}
    >
      {isEdit ? (
        <>
          <FormItem name={'first_name'} label="First Name" required>
            <Input size={'medium'} name="first_name" form={form} />
          </FormItem>
          <FormItem name={'last_name'} label="Last Name" required>
            <Input size={'medium'} name="last_name" form={form} />
          </FormItem>
          <FormItem name={'email'} label="Email Address">
            <Input size={'medium'} name="email" form={form} disabled />
          </FormItem>
          <FormItem name="phone_number" label="Phone Number" required>
            <InputMask
              size={'medium'}
              mask={PHONE_FIELD.MASK}
              name="phone_number"
              form={form}
            />
          </FormItem>
          <div className={classes.iFormPasswordSection}>
            <div>Password</div>
            <div>************</div>
            {changePasswordLink}
          </div>
          {isSuperAdmin && (
            <FormItem name="company_pin_number" label="Company Pin Number">
              <InputPassword
                size={'medium'}
                name="company_pin_number"
                form={form}
              />
            </FormItem>
          )}
        </>
      ) : (
        <>
          <h2>{currentUserName}</h2>
          <ul>
            <li>
              <span>Email Address:</span>
              <span>{currentUser.email || ''}</span>
            </li>
            <li>
              <span>Phone Number:</span>
              <span>
                {getStrWithFormat(
                  currentUser.phone_number || '',
                  FORMATS.PHONE
                )}
              </span>
            </li>
            <li>
              <span>Password:</span>
              <span>************</span>
            </li>
          </ul>
          {changePasswordLink}
          <div
            className={classes.deleteAccount}
            onClick={() => {
              if (onDeleteAccountClick) onDeleteAccountClick();
            }}
          >
            <span>Delete Account</span>
          </div>
          {isSuperAdmin && (
            <ul
              className={cn(classes.companyPinNumber, {
                [classes.isView]: isViewPin,
              })}
            >
              <li>
                <span>Company Pin Number</span>
                <span>
                  <span>
                    {isViewPin
                      ? currentUser.pin
                      : getMarkText(currentUser.pin?.length)}
                  </span>
                  {isViewPin ? (
                    <EyeInvisibleFilled
                      onClick={() => {
                        setIsViewPin(!isViewPin);
                      }}
                    />
                  ) : (
                    <EyeFilled
                      onClick={() => {
                        setIsViewPin(!isViewPin);
                      }}
                    />
                  )}
                </span>
              </li>
            </ul>
          )}
        </>
      )}
      <ChangePasswordModal
        isOpen={isChangePassword}
        handleClose={() => {
          setIsChangePassword(false);
        }}
      />
    </div>
  );
};

export default InformationForm;
