import {
  apiCreateUpdateFormsMSA,
  apiUpdateFormsMSAStatus,
  apiGetFormsMSAs,
} from 'api';
import { syncUpdateClientDetailByKey } from './clientDetail';

import { formsMSATypes } from '../types';

// SYNC_UPDATE_MSAS
export const syncUpdateMSAs = data => dispatch => {
  dispatch({
    type: formsMSATypes.SYNC_UPDATE_MSAS,
    payload: data,
  });
};

// GET_FORMS_MSAS
export const getFormsMSAs = ({ id, silent }, filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: formsMSATypes.GET_FORMS_MSAS,
    payload: { silent },
    asyncCall: () => {
      return apiGetFormsMSAs(id, filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_FORMS_MSA
export const updateFormsMSA = (clientId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: formsMSATypes.UPDATE_FORMS_MSA,
    payload: {},
    asyncCall: () => {
      return apiCreateUpdateFormsMSA(clientId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(syncUpdateClientDetailByKey('forms', response.data || {}));
      dispatch(syncUpdateMSAs(response.data?.msas || []));
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_FORMS_MSA_STATUS
export const updateFormsMSAStatus = (clientId, msaId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: formsMSATypes.UPDATE_FORMS_MSA_STATUS,
    payload: { msaId },
    asyncCall: () => {
      return apiUpdateFormsMSAStatus(clientId, msaId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
