import React from 'react';
import cn from 'classnames';
import { DeleteOutlined } from '@ant-design/icons';
import { Checkbox, Input } from 'components/FormFields';
import classes from './Checklist.module.scss';

const InputField = ({
  className,
  listName,
  index,
  form,
  values,
  value,
  onRemove,
  isViewDetail,
  id,
  onChangeCb,
}) => {
  return (
    <div className={cn(classes.inputField, className)}>
      {isViewDetail ? (
        <>
          <Checkbox
            checked={false}
            label=""
            onChange={() => {}}
            className={classes.checkbox}
          />
          <span>{value}</span>
        </>
      ) : (
        <>
          <Input
            size={'medium'}
            form={form}
            placeholder="Add an item"
            isBlue
            value={value}
            customValidateName={[listName, index, 'name']}
            onChange={e => {
              form.setFieldsValue({
                [listName]: (values[listName] || []).map((item, i) => {
                  if (i === index) {
                    item.name = e.target.value;
                  }
                  return item;
                }),
              });
              if (onChangeCb) onChangeCb();
            }}
            id={id}
            maxLength={500}
          />
          <DeleteOutlined className={classes.deleteIcon} onClick={onRemove} />
        </>
      )}
    </div>
  );
};

export default InputField;
