import React from 'react';
import cn from 'classnames';
import ButtonActions from 'views/pages/ClientDetail/components/ButtonActions';
import {
  StatusTagControl,
  PrimaryTag,
} from 'views/pages/ClientDetail/components/Elements';
import classes from './HeaderActions.module.scss';

const HeaderActions = ({
  className,
  isEdit,
  setIsEdit,
  form,
  isFormChange,
  loading,
  handleSubmit,
  openWarningModal,
  statusLoading,
  onStatusChange,
  status,
  inSection,
  hideStatus = false,
  is_primary,
  hideEdit,
  hideStatusEdit,
}) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.inSection]: inSection,
        },
        className
      )}
    >
      {!isEdit && !hideStatus && (
        <>
          {!!is_primary && <PrimaryTag isBig />}
          <StatusTagControl
            status={status}
            statusLoading={statusLoading}
            onStatusChange={onStatusChange}
            dropdown={!hideStatusEdit}
          />
        </>
      )}
      <ButtonActions
        setIsEdit={setIsEdit}
        isEdit={isEdit}
        form={form}
        isFormChanged={isFormChange}
        loading={loading}
        className={classes.buttonActions}
        onSubmit={handleSubmit}
        openWarningModal={openWarningModal}
        hideEdit={hideEdit}
      />
    </div>
  );
};

export default HeaderActions;
