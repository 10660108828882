import Request from './request';

export const apiGetLeasors = async (id, filters = {}) => {
  return Request.call({
    url: `/company/${id}/leasors/list`,
    method: 'GET',
    params: filters,
  });
};
export const apiGetLeasorAccount = async (companyId, leasorId, accountId) => {
  return Request.call({
    url: `/company/${companyId}/leasors/${leasorId}/accounts/${accountId}`,
    method: 'GET',
  });
};
export const apiGetLeasorFuelCard = async (companyId, leasorId, cardId) => {
  return Request.call({
    url: `/company/${companyId}/fuel-cards/${leasorId}/${cardId}?type=leasor`,
    method: 'GET',
  });
};

export const apiCreateUpdateLeasor = async (id, data) => {
  return Request.call({
    url: `/company/${id}/leasors`,
    method: 'POST',
    data,
  });
};
export const apiUpdateLeasorStatus = async (companyId, leasorId, data) => {
  return Request.call({
    url: `/company/${companyId}/leasors/${leasorId}/status`,
    method: 'POST',
    data,
  });
};
export const apiCreateLeasorAccount = async (companyId, leasorId, data) => {
  return Request.call({
    url: `/company/${companyId}/leasors/${leasorId}/accounts`,
    method: 'POST',
    data,
  });
};
export const apiUpdateLeasorAccount = async (
  companyId,
  leasorId,
  accountId,
  data
) => {
  return Request.call({
    url: `/company/${companyId}/leasors/${leasorId}/accounts/${accountId}`,
    method: 'POST',
    data,
  });
};
export const apiCreateLeasorFuelCard = async (companyId, leasorId, data) => {
  return Request.call({
    url: `/company/${companyId}/fuel-cards/${leasorId}`,
    method: 'POST',
    data,
  });
};
export const apiUpdateLeasorFuelCard = async (
  companyId,
  leasorId,
  cardId,
  data
) => {
  return Request.call({
    url: `/company/${companyId}/fuel-cards/${leasorId}/${cardId}`,
    method: 'POST',
    data,
  });
};
