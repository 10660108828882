import { useReducerData, useReducerLoading } from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// TRAILERS
export const useSelectTrailersLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.TRAILER,
    REDUCER_ATTRIBUTES.TRAILER.TRAILERS
  );
};
export const useSelectTrailers = () => {
  return useReducerData(
    REDUCER_NAMES.TRAILER,
    REDUCER_ATTRIBUTES.TRAILER.TRAILERS
  );
};
// CREATE_TRAILER
export const useSelectCreateTrailerLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.TRAILER,
    REDUCER_ATTRIBUTES.TRAILER.CREATE_TRAILER
  );
};
// UPDATE_TRAILER
export const useSelectUpdateTrailerLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.TRAILER,
    REDUCER_ATTRIBUTES.TRAILER.UPDATE_TRAILER,
    {}
  );
};
// UPDATE_TRAILER_STATUS
export const useSelectUpdateTrailerStatusLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.TRAILER,
    REDUCER_ATTRIBUTES.TRAILER.UPDATE_TRAILER_STATUS,
    {}
  );
};
