import React from 'react';

import Space from 'components/Space';
import NavigationComponent from './components/Navigation';
import DataEntryComponent from './components/DataEntry';
import DataDisplayComponent from './components/DataDisplay';
import RestComponent from './components/Rest';
import classes from './DevComponents.module.scss';

const Index = () => {
  return (
    <Space size={48} direction={'vertical'} style={{ padding: 50 }}>
      <NavigationComponent classes={classes} />
      <DataEntryComponent classes={classes} />
      <DataDisplayComponent classes={classes} />
      <h1>Rest Antd Component</h1>
      <RestComponent classes={classes} />
    </Space>
  );
};

export default Index;
