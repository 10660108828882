import React from 'react';
import find from 'lodash/find';
import * as sharedSelectors from 'store/selectors/shared';
import { renderStandardColumn, renderTagColumn } from 'utils/table';
import { ColumnActions, ActionItemColors } from 'components/Table';
import { STATUS_VALUES, SORT_DIRECTION_MAPPING, FORMATS } from 'helper/const';
import { parseStringToNumber } from 'utils/number';
import { getBEDate } from 'utils/date';
import { isAdminFilter } from 'utils/role';
import DepartmentsTab from 'views/pages/MyTeam/DepartmentsTab';
import { SORT_BY_MAPPING } from './constants';

const actionLabelMapping = {
  [STATUS_VALUES.INVITE_SENT]: 'Resend Invite',
  [STATUS_VALUES.ACTIVE]: 'Deactivate',
  [STATUS_VALUES.IN_ACTIVE]: 'Activate',
};
const actionColorMapping = {
  [STATUS_VALUES.ACTIVE]: ActionItemColors.RED,
  [STATUS_VALUES.IN_ACTIVE]: ActionItemColors.BLUE,
};
const getMyTeamColumns = (
  onView,
  onEdit,
  onAction,
  permission,
  currentUser
) => {
  const isClientColumns = permission.isClientColumns || false;
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      render: renderStandardColumn,
      width: 200,
      sorter: true,
      defaultSortOrder: 'ascend',
    },
    {
      title: isClientColumns ? 'Email Address' : 'Department',
      dataIndex: isClientColumns ? 'email' : 'group_name',
      render: renderStandardColumn,
      width: 150,
      sorter: true,
    },
    {
      title: isClientColumns ? 'Phone Number' : 'Position',
      dataIndex: isClientColumns ? 'phone' : 'role',
      render: isClientColumns
        ? value => renderStandardColumn(value, FORMATS.TABLE_PHONE)
        : renderStandardColumn,
      width: 150,
      sorter: true,
    },
    {
      title: isClientColumns ? 'Role' : 'Employee ID',
      dataIndex: isClientColumns ? 'role' : 'employee_id',
      render: renderStandardColumn,
      width: 150,
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value, record) => {
        return renderTagColumn(value, record, () => {
          onView(record);
        });
      },
      width: 100,
      sorter: true,
    },
    {
      title: '',
      width: 50,
      render: (_value, record) => {
        const actions = [
          {
            label: 'View',
            props: {
              onClick: () => {
                onView(record);
              },
            },
          },
        ];
        if (permission.teamAction) {
          actions.push({
            label: 'Edit',
            props: {
              onClick: () => {
                onEdit(record);
              },
            },
          });
          if (record.id !== currentUser?.id) {
            actions.push({
              label: <span>{actionLabelMapping[record.status]}</span>,
              props: {
                onClick: () => {
                  onAction(record);
                },
              },
            });
          }
        }
        return (
          <ColumnActions
            trigger={['click']}
            actions={actions}
            isBorderLastItem={permission.teamAction}
            lastItemColor={
              permission.teamAction && record.id !== currentUser?.id
                ? actionColorMapping[record.status]
                : ''
            }
          />
        );
      },
    },
  ];
};
const onInactiveTeammate = (fn, confirmModal) => {
  confirmModal(
    'Inactivate Teammate(s)?',
    <div>
      Are you sure you want to deactivate teammate(s)?
      <br />
      Any associated records will be saved.
    </div>,
    () => {
      if (fn) fn();
    },
    {
      type: 'confirm',
      onCancel: () => {},
      okText: 'Inactivate',
      centered: true,
    }
  );
};
const onActiveTeammate = (fn, confirmModal) => {
  confirmModal(
    'Activate Teammate(s)?',
    <div>
      Are you sure you want to activate teammate(s)?
      <br />
      Any associated records will be saved.
    </div>,
    () => {
      if (fn) fn();
    },
    {
      type: 'confirm',
      onCancel: () => {},
      okText: 'Activate',
      centered: true,
    }
  );
};
const onResendInvite = fn => {
  if (fn) fn();
};
const getInviteTeammateBodyRequest = values => {
  const bodyRequest = {};
  Object.keys(values).map(k => {
    if (['start_date', 'dob'].includes(k)) {
      if (values[k]) {
        bodyRequest[k] = getBEDate(values[k]);
      }
    } else if (['phone_number'].includes(k)) {
      if (values[k]) {
        bodyRequest[k] = parseStringToNumber(values[k]);
      }
    } else if (values[k]) {
      bodyRequest[k] = values[k];
    }
    return true;
  });
  return bodyRequest;
};
const getDoActionBR = (records, status) => {
  return {
    users: records.map(r => r.id),
    status,
  };
};
const getFilters = (page, sort, keyword, otherFilter) => {
  const filters = {
    page,
    search: keyword || '',
    ...otherFilter,
  };
  if (sort.order) {
    filters.sort_by = SORT_BY_MAPPING[sort.field] || sort.field;
    filters.dir = SORT_DIRECTION_MAPPING[sort.order] || '';
  }
  return filters;
};
const getDepartmentTabContent = (props, thisTab, tabName) => {
  return <DepartmentsTab {...props} currentTab={thisTab} tabName={tabName} />;
};
const getTheRestTabObj = (
  isAdmin,
  tabNames = [],
  showAdditionalTab,
  tabNameFromDepartment
) => {
  const departments = sharedSelectors.useSelectDepartments();
  return tabNames
    .filter(t => isAdminFilter(isAdmin, t))
    .map((t, i) => ({
      name: tabNameFromDepartment
        ? find(departments, { slug: t })?.name || ''
        : t,
      // this is tab index, tab index start from 1, 1 is All Departments, then will start from 2
      index: i + (showAdditionalTab ? 2 : 1),
    }));
};
const getTheRestTabs = ({
  handleGetDepartmentTeam,
  handleInactiveTeammate,
  handleResendInvite,
  handleActiveTeammate,
  activeTab,
  resetList,
  setResetList,
  keyword,
  setKeyword,
  isAdmin,
  permission,
  handleGetCompanyTeamList,
}) => {
  const props = {
    getDepartmentTeam: handleGetDepartmentTeam,
    handleInactive: handleInactiveTeammate,
    handleResendInvite: handleResendInvite,
    handleActive: handleActiveTeammate,
    activeTab,
    resetList,
    setResetList,
    keyword,
    setKeyword,
    permission,
    handleGetCompanyTeamList,
  };
  const tabObjects = getTheRestTabObj(
    isAdmin,
    permission.tabNames,
    permission.showAdditionalTab,
    permission.tabNameFromDepartment
  );
  return tabObjects.map(t => ({
    title: <span>{t.name}</span>,
    content: getDepartmentTabContent(props, t.index, t.name),
  }));
};

export {
  getMyTeamColumns,
  onInactiveTeammate,
  onActiveTeammate,
  onResendInvite,
  getInviteTeammateBodyRequest,
  getDoActionBR,
  getFilters,
  getTheRestTabs,
};
