import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_DRIVERS',
  'GET_DRIVER_ACCOUNT',
  'GET_DRIVER_FUEL_CARD',
  'CREATE_DRIVER',
  'UPDATE_DRIVER',
  'UPDATE_DRIVER_STATUS',
  'CREATE_DRIVER_ACCOUNT',
  'UPDATE_DRIVER_ACCOUNT',
  'CREATE_DRIVER_FUEL_CARD',
  'UPDATE_DRIVER_FUEL_CARD',
  'SYNC_UPDATE_DRIVER',
  'SYNC_UPDATE_DRIVER_SIMPLE_ACCOUNT',
  'SYNC_UPDATE_DRIVER_SIMPLE_FUEL_CARD'
);
