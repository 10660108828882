import React, { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'components/FormFields';
import { applySearch, applyFilter } from 'utils';
import { CHAT_SORT_MAPPING, CHAT_ORDER_MAPPING } from 'helper/const';
import {
  getDefaultDepartmentFilters,
  getDefaultCompanyFilters,
  getFilteredIds,
  getIsDFilter,
  getIsDSort,
} from './utils';
import { getFilterMenus, getSortMenus } from './uiUtils';
import FilterIcons from './FilterIcons';
import BackToChat from './BackToChat';
import { useSearchBoxData, useActions } from './selectorData';
import classes from './Chat.module.scss';

const ChatSearchBox = ({
  placeholder,
  hideFilter,
  isDetail,
  handleBack,
  isEdit,
  keyword,
  setKeyword,
  isStart,
  isEditContact,
  searchMemberAction,
  isClientTab,
  internalSearch,
  setInternalSearch,
  clientSearch,
  setClientSearch,
}) => {
  const {
    syncSetChatInformation,
    getInternalConversations,
    getClientConversations,
  } = useActions();
  const getConversationActions = isClientTab
    ? getClientConversations
    : getInternalConversations;
  const defaultInternalSort = 'newest';
  const defaultClientSort = 'newest';
  const [previousCompanies, setPreviousCompanies] = useState([]);
  const [internalFilters, setInternalFilters] = useState({});
  const [clientFilters, setClientFilters] = useState({});
  const [internalSort, setInternalSort] = useState(defaultInternalSort);
  const [clientSort, setClientSort] = useState(defaultClientSort);
  const [filterKeyword, setFilterKeyword] = useState('');
  const threadsSearch = isClientTab ? clientSearch : internalSearch;
  const { departments, companies } = useSearchBoxData();
  const defaultDepartmentFilters = getDefaultDepartmentFilters(departments);
  const defaultCompanyFilters = getDefaultCompanyFilters(companies);
  const activeFilters = isClientTab ? clientFilters : internalFilters;
  const activeSorts = isClientTab ? clientSort : internalSort;
  const handleApplyFilterSort = (nFilters, nSorts) => {
    const isDefaultFilter = isClientTab
      ? getIsDFilter(defaultCompanyFilters, nFilters)
      : getIsDFilter(defaultDepartmentFilters, nFilters);
    const isDefaultSort = isClientTab
      ? getIsDSort(defaultClientSort, nSorts)
      : getIsDSort(defaultInternalSort, nSorts);
    const filterKey = isClientTab ? 'company_ids' : 'group_ids';
    const filterParams = {};
    if (!isDefaultFilter) {
      filterParams[filterKey] = getFilteredIds(nFilters);
    }
    if (!isDefaultSort) {
      filterParams['sort_by'] = CHAT_SORT_MAPPING[nSorts];
      filterParams['order'] = CHAT_ORDER_MAPPING[nSorts];
    }
    getConversationActions(filterParams);
  };
  const handleSetFilter = nFilters => {
    const isDefaultFilter = isClientTab
      ? getIsDFilter(defaultCompanyFilters, nFilters)
      : getIsDFilter(defaultDepartmentFilters, nFilters);
    const setStateAction = isClientTab ? setClientFilters : setInternalFilters;
    setStateAction(nFilters);
    syncSetChatInformation(
      `filter${isClientTab ? 'Client' : 'Internal'}Ids`,
      isDefaultFilter ? [] : getFilteredIds(nFilters)
    );
    applyFilter(nFilters, val => {
      handleApplyFilterSort(val, activeSorts);
    });
  };
  const handleSetSort = nSorts => {
    const isDefaultSort = isClientTab
      ? getIsDSort(defaultClientSort, nSorts)
      : getIsDSort(defaultInternalSort, nSorts);
    const setStateAction = isClientTab ? setClientSort : setInternalSort;
    setStateAction(nSorts);
    syncSetChatInformation(
      `sort${isClientTab ? 'Client' : 'Internal'}`,
      isDefaultSort ? '' : nSorts
    );
    applyFilter(nSorts, val => {
      handleApplyFilterSort(activeFilters, val);
    });
  };
  const handleSearch = nSearch => {
    const setStateAction = isClientTab ? setClientSearch : setInternalSearch;
    setStateAction(nSearch);
  };
  const filterMenus = getFilterMenus({
    arr: isClientTab ? companies : departments,
    filters: activeFilters,
    setFilters: handleSetFilter,
    isClientTab,
    keyword: filterKeyword,
    setKeyword: setFilterKeyword,
  });
  const sortMenus = getSortMenus({
    sort: activeSorts,
    setSort: handleSetSort,
    isClientTab,
  });
  const filterProps = {
    hideFilter,
    filterMenus,
    sortMenus,
    isClientTab,
    isDefaultFilter: isClientTab
      ? getIsDFilter(defaultCompanyFilters, clientFilters)
      : getIsDFilter(defaultDepartmentFilters, internalFilters),
    isDefaultSort: isClientTab
      ? getIsDSort(defaultClientSort, clientSort)
      : getIsDSort(defaultInternalSort, internalSort),
  };
  const backProps = {
    isDetail,
    handleBack: () => {
      if (!isEdit) {
        handleApplyFilterSort(activeFilters, activeSorts);
      }
      handleBack();
    },
    isEdit,
  };
  useEffect(() => {
    setInternalFilters(defaultDepartmentFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departments]);
  useEffect(() => {
    if (!isEqual(companies, previousCompanies)) {
      setPreviousCompanies(companies);
      setClientFilters(defaultCompanyFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  return (
    <div className={classes.searchBox}>
      <BackToChat {...backProps} />
      {!isDetail && (
        <>
          <Input
            size={'medium'}
            value={isStart || isEditContact ? keyword : threadsSearch}
            placeholder={placeholder}
            onChange={e => {
              const value = e.target.value;
              if (isStart || isEditContact) {
                setKeyword(value);
                applySearch(value, val => {
                  if (!val || val.length >= 2) {
                    searchMemberAction({
                      search: val,
                    });
                  }
                });
              } else {
                handleSearch(value);
              }
            }}
            prefix={<SearchOutlined />}
            isBlue
            allowClear
          />
          <FilterIcons {...filterProps} />
        </>
      )}
    </div>
  );
};

export default ChatSearchBox;
