import React from 'react';
import { Pagination as AntdPagination } from 'antd';
import cx from 'classnames';

import classes from './Pagination.module.scss';

const Pagination = props => {
  const { className, center, right, ...other } = props;
  return (
    <AntdPagination
      {...other}
      showSizeChanger={false}
      className={cx(
        classes.wrapper,
        {
          [classes.center]: center,
          [classes.right]: right,
        },
        className
      )}
    />
  );
};

export default Pagination;
