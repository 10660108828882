import React from 'react';
import cn from 'classnames';
import { useOptions } from 'helper/hooks';
import { FieldSingleBlock, FormSection, ListInfo } from '../Elements';
import InputItem from '../InputItem';
import classes from './OnlinePortal.module.scss';

const OnlinePortal = ({
  className,
  title,
  form,
  fields,
  isEdit,
  data,
  stateName = 'state_id',
  portalLinkName = 'portal_link',
  portalLinkReadOnly = false,
}) => {
  const { stateOptions } = useOptions();
  const additionalTitleEl = (
    <div className={classes.additionalTitle}>
      {isEdit && !portalLinkReadOnly ? (
        <InputItem
          name={stateName}
          label="State"
          form={form}
          type="select"
          options={stateOptions}
          required
        />
      ) : (
        <>
          {!data[portalLinkName] ? (
            <span>Portal Link</span>
          ) : (
            <a
              href={data[portalLinkName]}
              target="_blank"
              rel="noopener noreferrer"
            >
              Portal Link
            </a>
          )}
        </>
      )}
    </div>
  );
  return (
    <FieldSingleBlock
      title={title}
      className={cn(classes.wrapper, className)}
      additionalTitle={additionalTitleEl}
    >
      {isEdit ? (
        <FormSection>
          {(fields || []).map((f, i) => {
            return (
              <InputItem name={f.name} label={f.label} form={form} key={i} />
            );
          })}
        </FormSection>
      ) : (
        <ListInfo objKeys={fields} data={data} />
      )}
    </FieldSingleBlock>
  );
};

export default OnlinePortal;
