import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Collapse, { Panel } from 'components/Collapse';
import toast from 'helper/toast';
import { getErrorMessageFromObj } from 'utils/object';
import {
  CollapseTable,
  ExpandIcon,
  EditLink,
  CollapseLabel,
} from './components/Elements';
import TabContentTop from './components/TabContentTop';
import {
  editChangeFn,
  filterSectionFn,
  getEditKeys,
  getIsKey,
  onKeyChangeFn,
  getLoading,
  submitFn,
  getIsEmptySection,
  sectionUpdatedCallback,
} from './utils';
import { elementMapping, settingMapping } from './uiUtils';
import { useActions, useClientRecordTabData } from './selectorData';
import classes from './ClientDetail.module.scss';

const ClientRecordTab = ({
  className,
  search,
  setSearch,
  confirmModal,
  setIsEditing,
  openWarningModal,
  activeTab,
  clientId,
  permission,
}) => {
  const { ...allClientRecordStoreData } = useClientRecordTabData();
  const detailData = allClientRecordStoreData.detailData;
  const sections = detailData.sections || [];
  const { ...allClientRecordActions } = useActions();
  const [sectionStatus, setSectionStatus] = useState({});
  const [activeKeys, setActiveKeys] = useState([]);
  const [editKeys, setEditKeys] = useState([]);
  const [editChanged, setEditChanged] = useState({});
  const filteredSections = filterSectionFn(sections, search);
  const handleExpandAll = () => {
    setActiveKeys(sections.map(section => section.key));
  };
  const handleCollapseAll = () => {
    setActiveKeys(editKeys);
  };
  const handleEditChange = (...params) =>
    editChangeFn(editChanged, setEditChanged, setIsEditing, ...params);
  const handleAfterEdit = (key, cb, errCb) => (res, err) => {
    if (res) {
      setIsEditing(false);
      setEditKeys(editKeys.filter(k => k !== key));
      const { getClientDetail, getClientDetailLog } = allClientRecordActions;
      getClientDetail({
        id: clientId,
        silent: true,
      });
      getClientDetailLog({ id: clientId });
      if (cb) cb();
    } else {
      toast.error(getErrorMessageFromObj(err)[0]);
      if (errCb) errCb();
    }
  };
  const handleMSAStatusFilter = (status, cb) => {
    const { getFormsMSAs } = allClientRecordActions;
    const statusFilter = {};
    if (status !== 'all') {
      statusFilter.status = status;
    }
    getFormsMSAs({ id: clientId }, statusFilter, cb);
  };
  const handleSectionUpdated = key => () => {
    sectionUpdatedCallback(
      clientId,
      key,
      sectionStatus,
      allClientRecordActions
    );
  };
  useEffect(() => {
    if (activeTab === 1) {
      setEditKeys([]);
      setEditChanged({});
    }
  }, [activeTab]);

  return (
    <div className={cn(classes.clientRecordTab, className)}>
      <TabContentTop
        search={search}
        setSearch={setSearch}
        handleExpandAll={handleExpandAll}
        handleCollapseAll={handleCollapseAll}
      />
      <CollapseTable>
        <Collapse
          destroyInactivePanel
          onChange={keys =>
            onKeyChangeFn(keys, activeKeys, editKeys, setActiveKeys)
          }
          activeKey={activeKeys}
          expandIcon={({ isActive }) => <ExpandIcon isActive={isActive} />}
        >
          {filteredSections.map(section => {
            const {
              key,
              label,
              officials_count,
              trucks_count,
              trailers_count,
              drivers_count,
              leasor_count,
              custom_fields,
              dot_saved,
            } = section;
            const isCompanyInformationSection = key === 'company_info';
            const sectionData = section.data || {};
            const { empty: isNotStart, subTitle } = getIsEmptySection({
              key,
              sectionData,
              officials_count,
              trailers_count,
              trucks_count,
              drivers_count,
              leasor_count,
              DOTClassifications: allClientRecordStoreData.DOTClassifications,
            });
            const isActive = getIsKey(activeKeys, key);
            const isEdit = getIsKey(editKeys, key);
            const sectionSetting = settingMapping[key] || {};
            const sectionInnerProps = {
              rootKey: key,
              isEdit,
              confirmModal,
              setIsEdit: edit => {
                const newEditKeys = getEditKeys(editKeys, edit, key);
                setEditKeys(newEditKeys);
                if (!edit) {
                  handleEditChange(key, false, newEditKeys);
                }
              },
              data: sectionData,
              trailers_count,
              trucks_count,
              officials_count,
              customField: custom_fields || [],
              onFormChange: () => {
                handleEditChange(key, true, editKeys);
              },
              openWarningModal,
              clientId,
              loading: getLoading(key, allClientRecordStoreData),
              onSubmit: (values, cb, childTab, brFullKey, errCb) => {
                submitFn({
                  key,
                  clientId,
                  values,
                  allClientRecordActions,
                  cb: handleAfterEdit(key, cb, errCb),
                  childTab,
                  brFullKey,
                });
              },
              setIsEditing,
              activeTab,
              status: sectionStatus[key] || 'active',
              setStatus: newStatus => {
                setSectionStatus({
                  ...sectionStatus,
                  [key]: newStatus,
                });
              },
              onUpdated: handleSectionUpdated(key),
              permission,
              dot_saved,
            };
            if (key === 'forms') {
              sectionInnerProps.formsMSAs = allClientRecordStoreData.formsMSAs;
              sectionInnerProps.onStatusFilter = handleMSAStatusFilter;
              sectionInnerProps.updateFormsMSAStatus =
                allClientRecordActions.updateFormsMSAStatus;
            }
            return (
              <Panel
                forceRender={true}
                header={
                  <CollapseLabel
                    title={label}
                    notStart={isNotStart}
                    subTitle={subTitle ? <>{subTitle}</> : ''}
                  />
                }
                key={key}
                extra={
                  !isActive &&
                  !sectionSetting.hideEdit &&
                  !(isCompanyInformationSection && permission.isDriver) && (
                    <EditLink
                      onClick={event => {
                        event.stopPropagation();
                        setActiveKeys([...activeKeys, key]);
                        setEditKeys([...editKeys, key]);
                      }}
                    />
                  )
                }
              >
                {elementMapping[key] && elementMapping[key](sectionInnerProps)}
              </Panel>
            );
          })}
        </Collapse>
      </CollapseTable>
    </div>
  );
};

export default ClientRecordTab;
