import React from 'react';
import cn from 'classnames';
import { getMomentDate, compareDay } from 'utils/date';
import Message from './Message';
import MessagesGroupTime from './MessagesGroupTime';
import classes from './Elements.module.scss';

const getMessageTimeString = time => {
  if (compareDay(new Date(), time) >= 1) {
    return getMomentDate(time)?.format('MMMM DD, YYYY');
  }
  return 'Today';
};

const Messages = ({ messages = [], className, renderTab }) => {
  let messageTime = null;
  let prevItem = {};
  return (
    <div
      className={cn(classes.messagesWrapper, className)}
      id={`chat-messages-wrapper-${renderTab}`}
    >
      {messages.map((m, i) => {
        const currentTimeWithFormat = getMessageTimeString(m.created_at);
        if (i === 0) {
          messageTime = currentTimeWithFormat;
        } else if (prevItem) {
          if (compareDay(m.created_at, prevItem.created_at) >= 1) {
            messageTime = currentTimeWithFormat;
          } else {
            messageTime = null;
          }
        }
        prevItem = { ...m };
        return (
          <React.Fragment key={i}>
            {!!messageTime && (
              <MessagesGroupTime>{messageTime}</MessagesGroupTime>
            )}
            <Message {...m} />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Messages;
