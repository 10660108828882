import React from 'react';
import cn from 'classnames';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import {
  FormSection,
  FieldArrayBlock,
} from 'views/pages/ClientDetail/components/Elements';
import classes from './OtherSection.module.scss';

export const OtherSectionEditForm = ({ form, index, parentName }) => {
  return (
    <FormSection>
      <InputItem
        name={[index, 'field_name']}
        label="Field Name"
        form={form}
        placeholder="Field Name"
        required
        customValidateName={[parentName, index, 'field_name']}
      />
      <InputItem
        name={[index, 'document']}
        label="Upload Attachment"
        form={form}
        placeholder="Upload Attachment"
        type="file"
        customFileName={`${parentName}.[${index}].document`}
      />
    </FormSection>
  );
};

const Edit = ({ className, form, index, parentName, data }) => {
  return (
    <FieldArrayBlock
      className={cn(classes.edit, className)}
      title={data.field_name}
      hideHeader
    >
      <OtherSectionEditForm form={form} index={index} parentName={parentName} />
    </FieldArrayBlock>
  );
};

export default Edit;
