import React from 'react';
import find from 'lodash/find';
import cn from 'classnames';
import * as sharedSelectors from 'store/selectors/shared';
import { selectAvatarAsWords } from 'utils/text';
import { departmentColorMapping } from './utils';
import classes from './Elements.module.scss';

const DEPARTMENT_AVATAR_MAPPING = {
  factoring: 'FD',
  underwriting: 'UD',
  compliance: 'CD',
  accounting: 'AD',
  sales: 'SD',
};

const AvatarText = ({
  name,
  department,
  className,
  size = 'large',
  isDisplayFullName,
  isMe,
}) => {
  const departments = sharedSelectors.useSelectDepartments();
  const departmentSlug = find(departments, { name })?.slug;
  return (
    <span
      className={cn(
        `ant-avatar ${size === 'large' ? 'ant-avatar-lg' : ''}`,
        classes.avatarText,
        classes[departmentColorMapping[department]],
        {
          [classes.isMe]: isMe,
          [classes.isDisplayFullName]: isDisplayFullName,
        },
        className
      )}
    >
      <span>
        {!!departmentSlug
          ? DEPARTMENT_AVATAR_MAPPING[departmentSlug] ||
            selectAvatarAsWords(name)
          : isDisplayFullName
          ? name
          : selectAvatarAsWords(name)}
      </span>
    </span>
  );
};

export default AvatarText;
