import {
  standardRequiredFieldObj,
  confirmPasswordDontMatchFieldObj,
  phoneFieldObj,
  emailFieldObj,
} from 'utils/form';
import { getBEDate } from 'utils/date';
import { FORM_VALIDATION_MESSAGES } from 'helper/const';
import { parseStringToNumber } from 'utils/number';

export const validateObj = () => {
  return {
    first_name: [standardRequiredFieldObj],
    last_name: [standardRequiredFieldObj],
    alternate_email: [emailFieldObj],
    phone_number: [standardRequiredFieldObj, phoneFieldObj],
    dob: [standardRequiredFieldObj],
    password: [
      {
        ...standardRequiredFieldObj,
        message: FORM_VALIDATION_MESSAGES.PASSWORD_REQUIRED,
      },
    ],
    confirm_password: [
      {
        ...standardRequiredFieldObj,
        message: FORM_VALIDATION_MESSAGES.PASSWORD_REQUIRED,
      },
      confirmPasswordDontMatchFieldObj,
    ],
  };
};
export const getBodyRequest = values => {
  const bodyRequest = {};
  Object.keys(values).map(k => {
    if (['dob'].includes(k)) {
      bodyRequest[k] = getBEDate(values[k]);
    } else if (['phone_number'].includes(k)) {
      bodyRequest[k] = parseStringToNumber(values[k]);
    } else if (values[k]) {
      bodyRequest[k] = values[k];
    }
    return true;
  });
  return bodyRequest;
};
