import React, { useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { PlusCircleFilled } from '@ant-design/icons';
import cn from 'classnames';
import { FormList } from 'components/Form';
import Empty from 'components/Empty';
import { FieldArrayBlock } from '../Elements';
import Button, { ButtonColors } from 'components/Button';
import classes from './FormListSection.module.scss';

const FormListSection = ({
  className,
  name,
  renderFields,
  renderViews,
  addBtnText,
  isEdit,
  data,
  notFoundMessage = '',
  newLabel = '',
  form,
  titleField = '',
  initialObjFn = null,
  additionalTitleFn = null,
  isSecurity = false,
  hideNotFound = false,
  isOther = false,
  canSave,
  onSave = () => () => {},
  saveLoading,
  addOnce,
  titleClassName,
  addSettings = {},
  actionButtonsClassName,
  setIsEditing,
  isEditing,
  addCb,
  hideStatus,
  displayDelete,
}) => {
  const [fieldEdit, setFieldEdit] = useState({});
  const existingDataLength = data ? data.length : 0;
  const hasData = existingDataLength > 0;
  const notFoundEl = (
    <Empty type="image1" className={classes.notFound}>
      <span>{notFoundMessage}</span>
    </Empty>
  );
  const handleFieldEdit = (val, id) => {
    const newFieldEdit = {
      ...fieldEdit,
      [id]: val,
    };
    setFieldEdit(newFieldEdit);
    const currentIsEditing =
      Object.keys(newFieldEdit).filter(key => !!newFieldEdit[key]).length > 0;
    if (isEditing !== currentIsEditing && setIsEditing) {
      setIsEditing(currentIsEditing);
    }
  };
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.viewEmpty]: !isEdit && !hasData,
        },
        className
      )}
    >
      {isEdit || isSecurity ? (
        <FormList name={name}>
          {(fields, { add, remove }) => {
            const values = form.getFieldsValue();
            const isAdding = fields.length > data.length;
            return (
              <>
                {fields.map((field, index) => {
                  const formStatusValue = get(
                    values,
                    `${name}[${index}].status`
                  );
                  const fieldData = data[index] || {};
                  const nextFieldData = data[index + 1] || {};
                  const arrayValues = values[name] || [];
                  const isExisting = !isEmpty(fieldData);
                  const mapId = fieldData?.id || `${index}_${index}`;
                  const nextMapId = nextFieldData?.id || '';
                  const nextIsEdit = !!fieldEdit[nextMapId];
                  return (
                    <FieldArrayBlock
                      buttonRevert={addSettings.saveButtonRevert}
                      saveBtnText={addSettings.saveBtnText}
                      titleClassName={titleClassName}
                      hideHeader={(isSecurity || isOther) && !!isExisting}
                      hideTitle={
                        (isOther && !!isExisting) || addSettings.hideTitleAdding
                      }
                      unBox={
                        (isOther && !!isExisting) || addSettings.unBoxAdding
                      }
                      additionalTitle={
                        additionalTitleFn
                          ? additionalTitleFn(arrayValues[index] || {}, index)
                          : null
                      }
                      key={mapId}
                      title={
                        fieldData[titleField] || `${newLabel} ${index + 1}`
                      }
                      onCancel={() => remove(field.name)}
                      className={classes.fieldBlock}
                      status={
                        displayDelete
                          ? ''
                          : hideStatus && isExisting
                          ? 'active'
                          : formStatusValue
                      }
                      canSave={canSave}
                      onSave={onSave(index, () => {
                        handleFieldEdit(false, mapId);
                      })}
                      saveLoading={saveLoading}
                      hideStatus={hideStatus}
                      cancelBtn={
                        displayDelete && isExisting ? { text: 'Delete' } : {}
                      }
                    >
                      {/* params: index, isExiting, isEdit, setIsEdit, nextIsEdit */}
                      {renderFields(
                        index,
                        isExisting,
                        !!fieldEdit[mapId],
                        val => {
                          handleFieldEdit(!!val, mapId);
                        },
                        nextIsEdit
                      )}
                    </FieldArrayBlock>
                  );
                })}
                {fields.length === 0 && !hideNotFound && notFoundEl}
                {isEdit && !(addSettings.showAddOnce && isAdding) && (
                  <div
                    className={cn(
                      classes.actionButtons,
                      {
                        [classes.empty]: fields.length === 0,
                        [classes.hideNotFound]: hideNotFound,
                      },
                      actionButtonsClassName
                    )}
                  >
                    <Button
                      onClick={() => {
                        if (!addOnce || (addOnce && !isAdding)) {
                          add(
                            initialObjFn ? initialObjFn(fields.length) : {},
                            fields.length
                          );
                          if (addCb) addCb(fields.length);
                        }
                      }}
                      leftIcon={
                        !addSettings.addBtnAsLink && <PlusCircleFilled />
                      }
                      color={ButtonColors.BLUE}
                      isLink={addSettings.addBtnAsLink}
                      disabled={addOnce && isAdding}
                    >
                      {addBtnText}
                    </Button>
                  </div>
                )}
              </>
            );
          }}
        </FormList>
      ) : (
        <div>
          {!hasData
            ? hideNotFound
              ? ''
              : notFoundEl
            : renderViews && renderViews()}
        </div>
      )}
    </div>
  );
};

export default FormListSection;
