import { FORMATS } from 'helper/const';
import { parseNumberWithComma } from 'utils/number';

export const DOT_CLARIFICATION_FIELDS = [
  {
    label: 'Company Name',
    key: 'legalName',
    type: 'value',
  },
  {
    label: 'DBA - Doing Business As',
    key: 'dbaName',
    type: 'value',
  },
  {
    label: 'EIN - Employer Identification Number',
    key: 'ein',
    type: 'formatValue',
    format: FORMATS.EIN,
  },
  {
    label: 'MC Number',
    key: 'mcNumber',
    type: 'value',
  },
  {
    label: 'Street Address',
    key: 'phyStreet',
    type: 'value',
  },
  {
    label: 'Apt.',
    key: 'phyApt',
    type: 'value',
  },
  {
    label: 'Address',
    addressGroup: true,
    alignLeft: true,
    fields: [
      {
        label: 'City',
        key: 'phyCity',
        type: 'value',
      },
      // {
      //   label: 'County',
      //   key: 'phyCounty',
      //   type: 'value',
      // },
      {
        label: 'State',
        key: 'phyState',
        type: 'value',
      },
      {
        label: 'Zip Code',
        key: 'phyZipcode',
        type: 'value',
      },
    ],
  },
  {
    label: 'Entity Type',
    key: 'entityType',
    type: 'value',
  },
  {
    label: 'Carrier Operations',
    key: 'carrierOperation',
    type: 'value',
  },
  {
    label: 'Operating Status',
    key: 'operatingStatus',
    type: 'multiValue',
  },
  {
    label: 'Operating Classifications',
    key: 'operatingClassifications',
    type: 'multiValue',
  },
  {
    label: 'Total Number of Power Units',
    key: 'totalPowerUnits',
    type: 'value',
    valueFn: val => parseNumberWithComma(val),
  },
  {
    label: 'Total Number of Drivers',
    key: 'totalDrivers',
    type: 'value',
    valueFn: val => parseNumberWithComma(val),
  },
  {
    label: 'Cargo Carried',
    key: 'cargoCarried',
    type: 'multiValue',
  },
  {
    label: 'Type of Specialized Cargo',
    key: 'specializedCargo',
    type: 'value',
  },
  {
    label: 'Hazmat Classifications',
    key: 'hazmatClassifications',
    type: 'value',
  },
  {
    label: 'Ins. on File with FMCSA',
    key: 'insOnFileWithFMCSA',
    type: 'value',
  },
  {
    label: 'Out of Service Reason',
    key: 'oosReason',
    type: 'value',
  },
  {
    label: 'Out of Service Since',
    key: 'oosDate',
    type: 'value',
  },
];
export const SEARCH_DOT_CLARIFICATION_FIELDS = [
  {
    label: 'Company Name',
    key: 'legalName',
    type: 'value',
  },
  {
    label: 'DBA - Doing Business As',
    key: 'dbaName',
    type: 'value',
  },
  {
    label: 'EIN - Employer Identification Number',
    key: 'ein',
    type: 'formatValue',
    format: FORMATS.EIN,
  },
  {
    label: 'MC Number',
    key: 'mcNumber',
    type: 'value',
  },
  {
    label: 'Street Address',
    key: 'phyStreet',
    type: 'value',
  },
  {
    label: 'Apt.',
    key: 'phyApt',
    type: 'value',
  },
  {
    label: 'Address',
    addressGroup: true,
    alignLeft: true,
    fields: [
      {
        label: 'City',
        key: 'phyCity',
        type: 'value',
      },
      // {
      //   label: 'County',
      //   key: 'phyCounty',
      //   type: 'value',
      // },
      {
        label: 'State',
        key: 'phyState',
        type: 'value',
      },
      {
        label: 'Zip Code',
        key: 'phyZipcode',
        type: 'value',
      },
    ],
  },
  {
    label: 'Entity Type',
    key: 'entityType',
    type: 'value',
  },
  {
    label: 'Carrier Operations',
    key: 'carrierOperation',
    type: 'value',
  },
  {
    label: 'Operating Status',
    key: 'operatingStatus',
    type: 'multiValue',
  },
  {
    label: 'Operating Classifications',
    key: 'operatingClassifications',
    type: 'multiValue',
  },
  {
    label: 'Total Number of Power Units',
    key: 'totalPowerUnits',
    type: 'value',
    valueFn: val => parseNumberWithComma(val),
  },
  {
    label: 'Total Number of Drivers',
    key: 'totalDrivers',
    type: 'value',
    valueFn: val => parseNumberWithComma(val),
  },
  {
    label: 'Cargo Carried',
    key: 'cargoCarried',
    type: 'multiValue',
  },
  {
    label: 'Type of Specialized Cargo',
    key: 'specializedCargo',
    type: 'value',
  },
  {
    label: 'Hazmat Classifications',
    key: 'hazmatClassifications',
    type: 'value',
  },
  {
    label: 'Ins. on File with FMCSA',
    key: 'insOnFileWithFMCSA',
    type: 'value',
  },
  {
    label: 'Out of Service Reason',
    key: 'oosReason',
    type: 'value',
  },
  {
    label: 'Out of Service Since',
    key: 'oosDate',
    type: 'value',
  },
];
