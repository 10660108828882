import ROUTES from 'routes';
// import { QUEUE_DEPARTMENTS } from 'helper/const';

// Common urls that can be accessed by every logged users.
const commonUrls = [
  ROUTES.INDEX(),
  ROUTES.HOME(),
  ROUTES.MY_COMPANY(),
  ROUTES.COMPANY_ORDER(),
  ROUTES.MY_PROFILE(),
  ROUTES.MY_TEAM(),
  ROUTES.TEAMMATE_DETAIL(),
  ROUTES.MY_REPORTS(),
];
// QUEUE_DEPARTMENTS.map(({ upperText }) => {
//   const routerUrl = ROUTES[`${upperText}_WORK_QUEUE`]();
//   commonUrls.push(routerUrl);
//   return true;
// });
export default commonUrls;
