import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
// plugins styles from node_modules
import 'react-perfect-scrollbar/dist/css/styles.css';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'store';
import './App.less';
import Views from './views';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Views />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
