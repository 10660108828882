import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import Tabs from 'components/Tabs';
import {
  handleLoadInternalConversation,
  handleLoadClientConversation,
} from 'utils/socket';
import ChatSearchBox from './SearchBox';
import StartNewChat from './StartNewChat';
import NewChatContent from './NewChatContent';
import ChatDetail from './ChatDetail';
import { getTabs } from './uiUtils';
import { useLoadChatData } from './hooks';
import { useMainContentData, useActions } from './selectorData';
import classes from './Chat.module.scss';

const MainContent = ({
  isCollapse,
  handleExpandChat,
  confirmModal,
  permission,
}) => {
  const { currentUser, internalCount, clientCount } = useMainContentData();
  const {
    markChatUnread,
    searchClientMembers,
    searchInternalMembers,
    markRead,
  } = useActions();
  const [keyword, setKeyword] = useState('');
  const [activeTab, setActiveTab] = useState(1);
  const isClientTab = activeTab === 2;
  const searchMemberAction = isClientTab
    ? searchClientMembers
    : searchInternalMembers;
  const handleReloadConversationActions = isClientTab
    ? handleLoadInternalConversation
    : handleLoadClientConversation;
  const [detailObj, setDetailObj] = useState({});
  useLoadChatData({ detailObj, setDetailObj });
  const isDetail = detailObj && !isEmpty(detailObj);
  const [isStart, setIsStart] = useState(false);
  const [isInitEdit, setIsInitEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const isEditMode = isInitEdit || isEditing;
  const [selectedContacts, setSelectedContacts] = useState([]);
  const filteredSelectedContacts = selectedContacts.filter(
    c => c.user_id !== currentUser.id
  );
  const [isNewContact, setIsNewContact] = useState(false);
  const [isEditContact, setIsEditContact] = useState(false);
  const [internalSearch, setInternalSearch] = useState('');
  const [clientSearch, setClientSearch] = useState('');
  const searchPlaceholder =
    isStart || isEditContact
      ? `Search ${!isClientTab ? 'internal' : 'client'} contacts`
      : `Search chat${isDetail ? '' : 's'}`;
  const searchBox = (
    <ChatSearchBox
      placeholder={searchPlaceholder}
      hideFilter={isStart || isDetail || isEditContact}
      isDetail={isDetail && !isEditContact}
      handleBack={() => {
        if (isEditMode) {
          setIsEditing(false);
          setIsInitEdit(false);
        } else {
          setDetailObj({});
        }
      }}
      isEdit={isEditMode && !isEditContact}
      keyword={keyword}
      setKeyword={setKeyword}
      isStart={isStart}
      isEditContact={isEditContact}
      searchMemberAction={searchMemberAction}
      isClientTab={isClientTab}
      internalSearch={internalSearch}
      setInternalSearch={setInternalSearch}
      clientSearch={clientSearch}
      setClientSearch={setClientSearch}
    />
  );
  const newChatContent = (
    <NewChatContent
      isCollapse={isCollapse}
      selectedContacts={filteredSelectedContacts}
      handleSelectContact={c => {
        setSelectedContacts([...filteredSelectedContacts, c]);
      }}
      handleDeselectContact={c => {
        setSelectedContacts(
          filteredSelectedContacts.filter(sc => sc.id !== c.id)
        );
      }}
      isClientTab={isClientTab}
      permission={permission}
    />
  );
  const handleMarkUnread = (chatObj, isDepartment) => () => {
    if (chatObj.message_id) {
      markChatUnread(chatObj.conversation_id, { isClientTab, isDepartment });
    }
  };
  const renderChatDetail = renderTab => {
    return (
      <ChatDetail
        isCollapse={isCollapse}
        isInitEdit={isInitEdit}
        setIsInitEdit={setIsInitEdit}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        isNewContact={isNewContact}
        handleResetIsNewContact={() => {
          setIsNewContact(false);
        }}
        detailObj={detailObj}
        onSaveContactName={val => {
          setDetailObj({
            ...detailObj,
            name: val,
          });
        }}
        confirmModal={confirmModal}
        isClientTab={isClientTab}
        setIsEditContact={setIsEditContact}
        setSelectedContacts={setSelectedContacts}
        setDetailObj={setDetailObj}
        searchMemberAction={searchMemberAction}
        renderTab={renderTab}
        permission={permission}
      />
    );
  };
  const onResetKeyword = () => {
    setKeyword('');
  };
  const handleViewDetail = c => {
    if (c.message_id && c.unread_message_count > 0) {
      markRead(
        c.conversation_id,
        {
          message_id: c.message_id,
        },
        () => {
          handleReloadConversationActions();
        }
      );
    }
    setIsNewContact(false);
    setDetailObj(c);
    onResetKeyword();
  };
  const tabProps = {
    handleViewDetail,
    permission,
    isCollapse,
    isStart: isStart || isEditContact,
    isDetail,
    internalCount,
    clientCount,
    setDetailObj,
    detailObj,
    handleMarkUnread,
  };
  const tabs = getTabs({
    tabProps,
    searchBox,
    newChatContent,
    renderChatDetail,
    internalSearch,
    setInternalSearch,
    clientSearch,
    setClientSearch,
    permission,
  });
  const hideStartNewChatForClientDepartment =
    permission.isClient && activeTab === 1;
  return (
    <>
      <Tabs
        tabs={tabs}
        type="card"
        activeKey={activeTab.toString()}
        onChange={activeKey => {
          if (!(isStart || isEditContact)) {
            handleExpandChat();
            const iActiveKey = parseInt(activeKey, 10);
            if (iActiveKey !== activeTab) {
              const getChatActions =
                iActiveKey === 1
                  ? handleLoadInternalConversation
                  : handleLoadClientConversation;
              getChatActions();
            }
            setActiveTab(iActiveKey);
            onResetKeyword();
            setDetailObj({});
            setIsInitEdit(false);
            setIsEditing(false);
          }
        }}
        className={cn(classes.tabs, {
          [classes.isClientActive]: isClientTab,
          [classes.isCollapse]: isCollapse,
          [classes.isChatClientView]: permission.isClient,
        })}
      />
      {(isEditContact || (!isDetail && !isEditMode)) &&
        !hideStartNewChatForClientDepartment && (
          <StartNewChat
            isCollapse={isCollapse}
            isStart={isStart || isEditContact}
            selectedContacts={filteredSelectedContacts}
            isEditContact={isEditContact}
            setIsEditContact={setIsEditContact}
            setIsStart={setIsStart}
            setSelectedContacts={setSelectedContacts}
            setIsNewContact={setIsNewContact}
            setDetailObj={setDetailObj}
            detailObj={detailObj}
            onResetKeyword={onResetKeyword}
            isClientTab={isClientTab}
            searchMemberAction={searchMemberAction}
            handleReloadConversationActions={handleReloadConversationActions}
          />
        )}
    </>
  );
};

export default MainContent;
