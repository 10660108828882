import React from 'react';
import { ZIP_CODE_NUMBER_FIELD } from 'helper/const';
import { useOptions } from 'helper/hooks';
import { FakeListFields } from 'components/FormFields/FakeAutocompleteField';
import InputItem from '../InputItem';
import {
  Section,
  FormSection,
  ListInfo,
  MailingAddressWrapper,
} from '../Elements';
import { ADDRESSES_OBJ_KEYS } from './constants';

const Addresses = ({ isEdit, form, data, permission }) => {
  const { stateOptions } = useOptions();
  const disabled = !!permission.isClient;
  return (
    <Section>
      {isEdit ? (
        <FormSection>
          <FakeListFields
            fields={[
              'street_address',
              'apt_suite',
              'city',
              'county',
              'state_id',
              'zip_code',
              'mailing_street_address',
              'mailing_apt_suite',
              'mailing_city',
              'mailing_county',
              'mailing_state_id',
              'mailing_zip_code',
            ]}
          />
          <InputItem
            name={'street_address'}
            label="Physical Address"
            form={form}
            addressField
            autoComplete="chrome-off"
            disabled={disabled}
          />
          <InputItem
            name={'apt_suite'}
            label="Apt."
            form={form}
            aptField
            autoComplete="chrome-off"
            disabled={disabled}
          />
          <InputItem
            name={'city'}
            label="City"
            form={form}
            addressGroupField
            autoComplete="chrome-off"
            disabled={disabled}
          />
          {/* <InputItem
            name={'county'}
            label="County"
            form={form}
            addressGroupField
            autoComplete="chrome-off"
            disabled={disabled}
          /> */}
          <InputItem
            name={'state_id'}
            label="State"
            form={form}
            type="select"
            options={stateOptions}
            addressGroupField
            autoComplete="chrome-off"
            disabled={disabled}
          />
          <InputItem
            name={'zip_code'}
            label="Zip Code"
            form={form}
            type="number"
            mask={ZIP_CODE_NUMBER_FIELD.MASK}
            placeholder={ZIP_CODE_NUMBER_FIELD.PLACEHOLDER}
            addressGroupField
            autoComplete="chrome-off"
            disabled={disabled}
          />
          <InputItem
            name={'is_same_as_physical'}
            label="Mailing address same as physical address"
            form={form}
            type="checkbox"
            fullWidth
            disabled={disabled}
          />
          <MailingAddressWrapper>
            {() => {
              const values = form.getFieldsValue();
              if ((values.is_same_as_physical || []).length > 0) {
                return <></>;
              }
              return (
                <>
                  <InputItem
                    name={'mailing_street_address'}
                    label="Mailing Address"
                    form={form}
                    addressField
                    autoComplete="chrome-off"
                    disabled={disabled}
                  />
                  <InputItem
                    name={'mailing_apt_suite'}
                    label="Apt."
                    form={form}
                    aptField
                    autoComplete="chrome-off"
                    disabled={disabled}
                  />
                  <InputItem
                    name={'mailing_city'}
                    label="City"
                    form={form}
                    addressGroupField
                    autoComplete="chrome-off"
                    disabled={disabled}
                  />
                  {/* <InputItem
                    name={'mailing_county'}
                    label="County"
                    form={form}
                    addressGroupField
                    autoComplete="chrome-off"
                    disabled={disabled}
                  /> */}
                  <InputItem
                    name={'mailing_state_id'}
                    label="State"
                    form={form}
                    type="select"
                    options={stateOptions}
                    addressGroupField
                    autoComplete="chrome-off"
                    disabled={disabled}
                  />
                  <InputItem
                    name={'mailing_zip_code'}
                    label="Zip Code"
                    form={form}
                    type="number"
                    mask={ZIP_CODE_NUMBER_FIELD.MASK}
                    placeholder={ZIP_CODE_NUMBER_FIELD.PLACEHOLDER}
                    addressGroupField
                    autoComplete="chrome-off"
                    disabled={disabled}
                  />
                </>
              );
            }}
          </MailingAddressWrapper>
        </FormSection>
      ) : (
        <ListInfo objKeys={ADDRESSES_OBJ_KEYS} data={data} />
      )}
    </Section>
  );
};

export default Addresses;
