import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as reminderSelectors from 'store/selectors/reminder';
import * as checklistSelectors from 'store/selectors/checklist';
// ACTIONS
import * as reminderActions from 'store/actions/reminder';
import * as checklistActions from 'store/actions/checklist';
import * as notificationActions from 'store/actions/notification';

export const useIndexData = () => {
  return {
    activeReminders: reminderSelectors.useSelectActiveCompanyReminders(),
    activeRemindersLoading: reminderSelectors.useSelectActiveCompanyRemindersLoading(),
    dismissedReminders: reminderSelectors.useSelectDismissedCompanyReminders(),
    dismissedRemindersLoading: reminderSelectors.useSelectDismissedCompanyRemindersLoading(),
    completedReminders: reminderSelectors.useSelectCompletedCompanyReminders(),
    completedRemindersLoading: reminderSelectors.useSelectCompletedCompanyRemindersLoading(),
    changeReminderStatusLoading: reminderSelectors.useSelectChangeReminderStatusLoading(),
    createUpdateChecklistLoading: checklistSelectors.useSelectCreateUpdateChecklistLoading(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...reminderActions,
    ...checklistActions,
    ...notificationActions,
  });
};
