import React, { memo, useCallback } from 'react';
import update from 'immutability-helper';
import { useDrop } from 'react-dnd';
import Card from './Card';
import classes from './DragDrop.module.scss';

const Container = props => {
  const {
    items,
    setItems,
    onIndexChange,
    disabled,
    cardClassName,
    wholeHandler,
    onDropCard,
  } = props;

  const findCard = useCallback(
    id => {
      const card = items.filter(c => String(c.id) === id)[0];
      return {
        card,
        index: items.indexOf(card),
      };
    },
    [items]
  );

  const moveCard = useCallback(
    (id, atIndex) => {
      const { card, index } = findCard(id);
      if (setItems) {
        setItems(
          update(items, {
            $splice: [
              [index, 1],
              [atIndex, 0, card],
            ],
          })
        );
      }
      if (onIndexChange) onIndexChange(index, atIndex);
    },
    [findCard, items, setItems, onIndexChange]
  );

  const [, drop] = useDrop(() => ({ accept: 'card' }));

  return (
    <div ref={drop} className={classes.container}>
      {items.map(card => (
        <Card
          key={card.id}
          id={String(card.id)}
          content={card.content}
          moveCard={moveCard}
          onDropCard={onDropCard}
          findCard={findCard}
          disabled={disabled || card.disabled}
          className={cardClassName}
          wholeHandler={wholeHandler}
        />
      ))}
    </div>
  );
};

export default memo(Container);
