import React from 'react';
import find from 'lodash/find';
import get from 'lodash/get';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import {
  FormSection,
  ShouldUpdateWrapper,
} from 'views/pages/ClientDetail/components/Elements';
import { useOptions } from 'helper/hooks';
import ListSection from '../ListSection';

const Lien = ({ className, isEdit, form, data }) => {
  const { stateOptions, scLienTypeOptions } = useOptions();
  return (
    <ListSection
      className={className}
      isEdit={isEdit}
      form={form}
      data={data['liens'] || []}
      title="Liens - Not Released"
      addBtnText="Add Lien"
      parentName="liens"
      fields={[
        {
          label: 'Address',
          addressGroup: true,
          alignLeft: true,
          fields: [
            {
              label: 'Lien Type',
              key: 'lien_type',
              type: 'value',
            },
            {
              label: 'Year',
              key: 'year',
              type: 'value',
            },
            {
              label: 'Lien Amount',
              key: 'amount',
              type: 'value',
            },
            {
              label: 'State',
              key: 'state',
              type: 'value',
            },
          ],
        },
        {
          label: 'Other',
          key: 'other',
          type: 'value',
          dependField: 'lien_type',
          dependFn: val => val !== 'Other',
        },
      ]}
      renderEditForm={index => {
        return (
          <FormSection>
            <InputItem
              name={[index, 'lien_type_id']}
              label="Type of Violation"
              form={form}
              type="select"
              options={scLienTypeOptions}
              addressGroupField
            />
            <InputItem
              name={[index, 'year']}
              label="Year"
              form={form}
              placeholder="Enter year"
              addressGroupField
            />
            <InputItem
              name={[index, 'amount']}
              label="Lien Amount"
              form={form}
              addressGroupField
              type="price"
              prefix={'$'}
              placeholder="00.00"
            />
            <InputItem
              name={[index, 'state_id']}
              label="State"
              form={form}
              type="select"
              options={stateOptions}
              addressGroupField
            />
            <ShouldUpdateWrapper>
              {() => {
                const values = form.getFieldsValue();
                const valueObj = get(values, `liens[${index}]`) || {};
                const foundType =
                  find(scLienTypeOptions, {
                    value: valueObj.lien_type_id,
                  }) || {};
                if (foundType.label !== 'Other') {
                  return <></>;
                }
                return (
                  <>
                    <InputItem
                      name={[index, 'other']}
                      label="Other"
                      form={form}
                    />
                  </>
                );
              }}
            </ShouldUpdateWrapper>
          </FormSection>
        );
      }}
    />
  );
};

export default Lien;
