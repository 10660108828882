import {
  useReducerData,
  useReducerTotal,
  useReducerLoading,
  useReducerIsMore,
} from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// ALL_ORDER_TYPES
export const useSelectAllOrderTypesList = () => {
  return useReducerData(
    REDUCER_NAMES.ORDER_TYPE,
    REDUCER_ATTRIBUTES.ORDER_TYPE.ALL_ORDER_TYPE_LIST,
    {}
  );
};
export const useSelectAllOrderTypesLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.ORDER_TYPE,
    REDUCER_ATTRIBUTES.ORDER_TYPE.ALL_ORDER_TYPES
  );
};
export const useSelectAllOrderTypesTotal = () => {
  return useReducerTotal(
    REDUCER_NAMES.ORDER_TYPE,
    REDUCER_ATTRIBUTES.ORDER_TYPE.ALL_ORDER_TYPES,
    0
  );
};
export const useSelectAllOrderTypesIsMore = () => {
  return useReducerIsMore(
    REDUCER_NAMES.ORDER_TYPE,
    REDUCER_ATTRIBUTES.ORDER_TYPE.ALL_ORDER_TYPES
  );
};
export const useSelectAllOrderTypes = () => {
  return useReducerData(
    REDUCER_NAMES.ORDER_TYPE,
    REDUCER_ATTRIBUTES.ORDER_TYPE.ALL_ORDER_TYPES
  );
};
// ORDER_TYPE_DETAIL
export const useSelectOrderTypeDetail = () => {
  return useReducerData(
    REDUCER_NAMES.ORDER_TYPE,
    REDUCER_ATTRIBUTES.ORDER_TYPE.ORDER_TYPE_DETAIL,
    {}
  );
};
export const useSelectOrderTypeDetailLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.ORDER_TYPE,
    REDUCER_ATTRIBUTES.ORDER_TYPE.ORDER_TYPE_DETAIL
  );
};
// ORDER_TYPE_LOGS
export const useSelectOrderTypeLogs = () => {
  return useReducerData(
    REDUCER_NAMES.ORDER_TYPE,
    REDUCER_ATTRIBUTES.ORDER_TYPE.ORDER_TYPE_LOGS,
    []
  );
};
export const useSelectOrderTypeLogsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.ORDER_TYPE,
    REDUCER_ATTRIBUTES.ORDER_TYPE.ORDER_TYPE_LOGS
  );
};
// CREATE_ORDER_TYPE
export const useSelectCreateOrderTypeLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.ORDER_TYPE,
    REDUCER_ATTRIBUTES.ORDER_TYPE.CREATE_ORDER_TYPE
  );
};
// UPDATE_ORDER_TYPE
export const useSelectUpdateOrderTypeLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.ORDER_TYPE,
    REDUCER_ATTRIBUTES.ORDER_TYPE.UPDATE_ORDER_TYPE
  );
};
// DELETE_ORDER_TYPE
export const useSelectDeleteOrderTypeLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.ORDER_TYPE,
    REDUCER_ATTRIBUTES.ORDER_TYPE.DELETE_ORDER_TYPE
  );
};
// ADD_ORDER_TYPE_NOTE
export const useSelectAddOrderTypeNoteLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.ORDER_TYPE,
    REDUCER_ATTRIBUTES.ORDER_TYPE.ADD_ORDER_TYPE_NOTE
  );
};
// UPDATE_ORDER_TYPE_DOCUMENT
export const useSelectUpdateOrderTypeDocumentLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.ORDER_TYPE,
    REDUCER_ATTRIBUTES.ORDER_TYPE.UPDATE_ORDER_TYPE_DOCUMENT
  );
};
