import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as clientDetailSelectors from 'store/selectors/clientDetail';
import * as orderSelectors from 'store/selectors/order';

// ACTIONS
import * as orderActions from 'store/actions/order';

export const useIndexData = () => {
  return {
    detailData: clientDetailSelectors.useSelectClientDetail(),
    orderList: orderSelectors.useSelectOrderList(),
    orderListLoading: orderSelectors.useSelectOrderListLoading(),
    orderTotal: orderSelectors.useSelectOrderListTotal(),
    deleteOrderLoading: orderSelectors.useSelectDeleteOrderLoading(),
    clientOrderDetail: orderSelectors.useSelectClientOrderDetail(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...orderActions,
  });
};
