import { getFileFieldInitialValues } from 'utils/form';
import { FILE_FIELDS } from './constants';

const getFileField = field => {
  if (!field) return '';
  return field.replace('documents.', '');
};
export const validateObj = () => {
  return {};
};
export const getInitialValues = data => {
  return {
    delete_attachments: [],
    ...getFileFieldInitialValues(FILE_FIELDS, data, 'documents'),
  };
};

export const getDocumentBR = (values = {}) => {
  const { delete_attachments } = values;
  const fData = new FormData();
  FILE_FIELDS.map(originKey => {
    const key = getFileField(originKey);
    if (values[key] && values[key].length > 0) {
      values[key].map(file => {
        if (file.originFileObj && !file.id) {
          fData.append(`${key}[]`, file.originFileObj);
        }
        return true;
      });
    } else {
      fData.append(key, '');
    }
    return true;
  });
  if (delete_attachments && delete_attachments.length > 0) {
    delete_attachments.map(id => {
      fData.append('delete_documents[]', id);
      return true;
    });
  }
  return fData;
};
