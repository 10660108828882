import { useReducerData, useReducerLoading } from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// TRUCKS
export const useSelectTrucksLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.TRUCK,
    REDUCER_ATTRIBUTES.TRUCK.TRUCKS
  );
};
export const useSelectTrucks = () => {
  return useReducerData(REDUCER_NAMES.TRUCK, REDUCER_ATTRIBUTES.TRUCK.TRUCKS);
};
// CREATE_TRUCK
export const useSelectCreateTruckLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.TRUCK,
    REDUCER_ATTRIBUTES.TRUCK.CREATE_TRUCK
  );
};
// UPDATE_TRUCK
export const useSelectUpdateTruckLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.TRUCK,
    REDUCER_ATTRIBUTES.TRUCK.UPDATE_TRUCK,
    {}
  );
};
// UPDATE_TRUCK_STATUS
export const useSelectUpdateTruckStatusLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.TRUCK,
    REDUCER_ATTRIBUTES.TRUCK.UPDATE_TRUCK_STATUS,
    {}
  );
};
