import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  chatTypes,
} from '../types';
import get from 'lodash/get';
import { initialChatState } from '../states/chat';

export default (state = initialChatState, action) => {
  const conversationsData = get(action, 'response.data') || {};
  switch (action.type) {
    // CUSTOM
    // SYNC_SET_MESSAGES
    case chatTypes.SYNC_SET_MESSAGES: {
      return {
        ...state,
        messages: {
          ...state.messages,
          data: action.replaceAll
            ? action.payload
            : [action.payload, ...state.messages.data],
        },
      };
    }
    // SYNC_SET_CHAT_MEMBERS
    case chatTypes.SYNC_SET_CHAT_MEMBERS: {
      return {
        ...state,
        chatMembers: {
          ...state.chatMembers,
          data: action.replaceAll
            ? action.payload
            : [...state.chatMembers.data, action.payload],
        },
      };
    }
    // SYNC_SET_INTERNAL_COUNT
    case chatTypes.SYNC_SET_INTERNAL_COUNT: {
      return {
        ...state,
        internalCount: action.payload,
      };
    }
    // SYNC_SET_CLIENT_COUNT
    case chatTypes.SYNC_SET_CLIENT_COUNT: {
      return {
        ...state,
        clientCount: action.payload,
      };
    }
    // SYNC_SET_COMPANIES
    case chatTypes.SYNC_SET_COMPANIES: {
      return {
        ...state,
        companies: action.payload,
      };
    }
    // SYNC_INCREASE_UNREAD_CHAT
    case chatTypes.SYNC_INCREASE_UNREAD_CHAT: {
      return {
        ...state,
        [action.parentKey]: {
          ...state[action.parentKey],
          data: {
            ...state[action.parentKey].data,
            [action.key]: state[action.parentKey].data[action.key].map(c => {
              if (c.conversation_id === action.payload) {
                c.unread_message_count = 1;
              }
              return c;
            }),
          },
        },
      };
    }
    // SYNC_INCREASE_UNREAD_CHATS
    case chatTypes.SYNC_INCREASE_UNREAD_CHATS: {
      return {
        ...state,
        [action.key]: state[action.key] + 1,
      };
    }
    // GET_INTERNAL_CONVERSATIONS
    case asyncActionTypeRequest(chatTypes.GET_INTERNAL_CONVERSATIONS): {
      return {
        ...state,
        internalConversations: {
          ...state.internalConversations,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(chatTypes.GET_INTERNAL_CONVERSATIONS): {
      return {
        ...state,
        internalConversations: {
          data: {
            ...state.internalConversations.data,
            department: conversationsData.department,
            individual: conversationsData.individual,
          },
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(chatTypes.GET_INTERNAL_CONVERSATIONS): {
      return {
        ...state,
        internalConversations: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // GET_CLIENT_CONVERSATIONS
    case asyncActionTypeRequest(chatTypes.GET_CLIENT_CONVERSATIONS): {
      return {
        ...state,
        clientConversations: {
          ...state.clientConversations,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(chatTypes.GET_CLIENT_CONVERSATIONS): {
      return {
        ...state,
        clientConversations: {
          data: {
            ...state.clientConversations.data,
            department: conversationsData.department,
            individual: conversationsData.individual,
          },
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(chatTypes.GET_CLIENT_CONVERSATIONS): {
      return {
        ...state,
        clientConversations: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // GET_CHAT_DEPARTMENTS
    case asyncActionTypeRequest(chatTypes.GET_CHAT_DEPARTMENTS): {
      return {
        ...state,
        chatDepartments: {
          ...state.chatDepartments,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(chatTypes.GET_CHAT_DEPARTMENTS): {
      return {
        ...state,
        chatDepartments: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(chatTypes.GET_CHAT_DEPARTMENTS): {
      return {
        ...state,
        chatDepartments: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_CHAT_DEPARTMENTS_LIST
    case asyncActionTypeRequest(chatTypes.GET_CHAT_DEPARTMENTS_LIST): {
      return {
        ...state,
        chatDepartmentsList: {
          ...state.chatDepartmentsList,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(chatTypes.GET_CHAT_DEPARTMENTS_LIST): {
      return {
        ...state,
        chatDepartmentsList: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(chatTypes.GET_CHAT_DEPARTMENTS_LIST): {
      return {
        ...state,
        chatDepartmentsList: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_MESSAGES
    case asyncActionTypeRequest(chatTypes.GET_MESSAGES): {
      return {
        ...state,
        messages: {
          ...state.messages,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(chatTypes.GET_MESSAGES): {
      return {
        ...state,
        messages: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(chatTypes.GET_MESSAGES): {
      return {
        ...state,
        messages: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_ATTACHMENT
    case asyncActionTypeRequest(chatTypes.GET_ATTACHMENT): {
      return {
        ...state,
        attachment: {
          ...state.attachment,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(chatTypes.GET_ATTACHMENT): {
      return {
        ...state,
        attachment: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(chatTypes.GET_ATTACHMENT): {
      return {
        ...state,
        attachment: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // SEARCH_INTERNAL_MEMBERS
    case asyncActionTypeRequest(chatTypes.SEARCH_INTERNAL_MEMBERS): {
      return {
        ...state,
        searchInternalMembers: {
          ...state.searchInternalMembers,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(chatTypes.SEARCH_INTERNAL_MEMBERS): {
      return {
        ...state,
        searchInternalMembers: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(chatTypes.SEARCH_INTERNAL_MEMBERS): {
      return {
        ...state,
        searchInternalMembers: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // SEARCH_CLIENT_MEMBERS
    case asyncActionTypeRequest(chatTypes.SEARCH_CLIENT_MEMBERS): {
      return {
        ...state,
        searchClientMembers: {
          ...state.searchClientMembers,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(chatTypes.SEARCH_CLIENT_MEMBERS): {
      return {
        ...state,
        searchClientMembers: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(chatTypes.SEARCH_CLIENT_MEMBERS): {
      return {
        ...state,
        searchClientMembers: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_CHAT_MEMBERS
    case asyncActionTypeRequest(chatTypes.GET_CHAT_MEMBERS): {
      return {
        ...state,
        chatMembers: {
          ...state.chatMembers,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(chatTypes.GET_CHAT_MEMBERS): {
      return {
        ...state,
        chatMembers: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(chatTypes.GET_CHAT_MEMBERS): {
      return {
        ...state,
        chatMembers: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // POST
    // SEND_MESSAGE
    case asyncActionTypeRequest(chatTypes.SEND_MESSAGE): {
      return {
        ...state,
        sendMessage: {
          ...state.sendMessage,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(chatTypes.SEND_MESSAGE): {
      return {
        ...state,
        sendMessage: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(chatTypes.SEND_MESSAGE): {
      return {
        ...state,
        sendMessage: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // MARK_READ
    case asyncActionTypeRequest(chatTypes.MARK_READ): {
      return {
        ...state,
        markRead: {
          ...state.markRead,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(chatTypes.MARK_READ): {
      return {
        ...state,
        markRead: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(chatTypes.MARK_READ): {
      return {
        ...state,
        markRead: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // UPDATE_CHAT_SETTINGS
    case asyncActionTypeRequest(chatTypes.UPDATE_CHAT_SETTINGS): {
      return {
        ...state,
        updateChatSettings: {
          ...state.updateChatSettings,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(chatTypes.UPDATE_CHAT_SETTINGS): {
      return {
        ...state,
        updateChatSettings: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(chatTypes.UPDATE_CHAT_SETTINGS): {
      return {
        ...state,
        updateChatSettings: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // ADD_MEMBERS
    case asyncActionTypeRequest(chatTypes.ADD_MEMBERS): {
      return {
        ...state,
        addMembers: {
          ...state.addMembers,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(chatTypes.ADD_MEMBERS): {
      return {
        ...state,
        addMembers: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(chatTypes.ADD_MEMBERS): {
      return {
        ...state,
        addMembers: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // REMOVE_MEMBERS
    case asyncActionTypeRequest(chatTypes.REMOVE_MEMBERS): {
      return {
        ...state,
        removeMembers: {
          ...state.removeMembers,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(chatTypes.REMOVE_MEMBERS): {
      return {
        ...state,
        removeMembers: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(chatTypes.REMOVE_MEMBERS): {
      return {
        ...state,
        removeMembers: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // CHECK_CONVERSATION
    case asyncActionTypeRequest(chatTypes.CHECK_CONVERSATION): {
      return {
        ...state,
        checkConversation: {
          ...state.checkConversation,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(chatTypes.CHECK_CONVERSATION): {
      return {
        ...state,
        checkConversation: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(chatTypes.CHECK_CONVERSATION): {
      return {
        ...state,
        checkConversation: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // CREATE_CHAT_GROUP
    case asyncActionTypeRequest(chatTypes.CREATE_CHAT_GROUP): {
      return {
        ...state,
        createChatGroup: {
          ...state.createChatGroup,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(chatTypes.CREATE_CHAT_GROUP): {
      return {
        ...state,
        createChatGroup: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(chatTypes.CREATE_CHAT_GROUP): {
      return {
        ...state,
        createChatGroup: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    default:
      return state;
  }
};
