import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as teammateDetailSelectors from 'store/selectors/teammateDetail';
import * as myTeamSelectors from 'store/selectors/myTeam';
import * as sharedSelectors from 'store/selectors/shared';
import * as authSelectors from 'store/selectors/auth';
// ACTIONS
import * as myTeamActions from 'store/actions/myTeam';
import * as teammateDetailActions from 'store/actions/teammateDetail';
import * as authActions from 'store/actions/auth';

export const useIndexData = () => {
  return {
    currentUser: authSelectors.useSelectCurrentUser(),
    detailData: teammateDetailSelectors.useSelectTeammateDetail(),
  };
};
export const useInformationData = () => {
  return {
    currentUser: authSelectors.useSelectCurrentUser(),
    departments: sharedSelectors.useSelectDepartments(),
    detailData: teammateDetailSelectors.useSelectTeammateDetail(),
    doActionLoading: teammateDetailSelectors.useSelectDoActionLoading(),
    resendInviteLoading: myTeamSelectors.useSelectResendInviteTeammateLoading(),
    updateCompanyTeamMemberStatusLoading: myTeamSelectors.useSelectUpdateCompanyTeamMemberStatusLoading(),
  };
};
export const useHeaderData = () => {
  return {
    detailData: teammateDetailSelectors.useSelectTeammateDetail(),
  };
};
export const useEditFormData = () => {
  return {
    departments: sharedSelectors.useSelectDepartments(),
    detailData: teammateDetailSelectors.useSelectTeammateDetail(),
  };
};
export const useButtonActionsData = () => {
  return {
    updateLoading: teammateDetailSelectors.useSelectUpdateTeammateLoading(),
    updateCompanyTeamMemberLoading: myTeamSelectors.useSelectUpdateCompanyTeamMemberLoading(),
  };
};
export const useLoadingSpinData = () => {
  return {
    detailLoading: teammateDetailSelectors.useSelectTeammateDetailLoading(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...myTeamActions,
    ...teammateDetailActions,
    ...authActions,
  });
};
