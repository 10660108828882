import {
  apiGetNotificationUnreadCount,
  apiReadNotification,
  apiGetNotifications,
  apiDeleteNotification,
} from 'api';

import { notificationTypes } from '../types';

// GET_UNREAD_NOTIFICATIONS
export const getUnreadNotifications = cb => {
  return {
    isAsyncCall: true,
    baseType: notificationTypes.GET_UNREAD_NOTIFICATIONS,
    payload: {},
    asyncCall: () => {
      return apiGetNotifications({
        is_read: 0,
      });
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_READ_NOTIFICATIONS
export const getReadNotifications = cb => {
  return {
    isAsyncCall: true,
    baseType: notificationTypes.GET_READ_NOTIFICATIONS,
    payload: {},
    asyncCall: () => {
      return apiGetNotifications({
        is_read: 1,
      });
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_NOTIFICATION_UNREAD_COUNT
export const getNotificationUnreadCount = cb => {
  return {
    isAsyncCall: true,
    baseType: notificationTypes.GET_NOTIFICATION_UNREAD_COUNT,
    payload: {},
    asyncCall: () => {
      return apiGetNotificationUnreadCount();
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// READ_NOTIFICATION
export const readNotification = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: notificationTypes.READ_NOTIFICATION,
    payload: {},
    asyncCall: () => {
      return apiReadNotification(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// DELETE_NOTIFICATION
export const deleteNotification = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: notificationTypes.DELETE_NOTIFICATION,
    payload: {},
    asyncCall: () => {
      return apiDeleteNotification(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
