import React from 'react';
import cn from 'classnames';
import classes from './Header.module.scss';

const Header = ({ className, title = 'DOT Classifications' }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <h1>{title}</h1>
    </div>
  );
};

export default Header;
