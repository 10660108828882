import { ROLES } from 'helper/const';
import { adminPermission } from './admin';
import { otherUsersPermission } from './otherUsers';

const permissions = {
  [ROLES.ADMIN]: adminPermission,
  [ROLES.SUPER_ADMIN]: adminPermission,
  [ROLES.CLIENT]: otherUsersPermission,
  [ROLES.CLIENT_DRIVER]: otherUsersPermission,
};

const getPermission = (role, currentUser = {}) => {
  return (
    permissions[role] ||
    (!!currentUser.group_id ? adminPermission : otherUsersPermission)
  );
};

export default getPermission;
