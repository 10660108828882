import React from 'react';
import find from 'lodash/find';
import { useOptions } from 'helper/hooks';
import InputItem from '../InputItem';
import {
  Section,
  FormSection,
  ListInfo,
  ShouldUpdateWrapper,
} from '../Elements';
import { COMPANY_INFO_OBJ_KEYS } from './constants';

const CompanyInfo = ({ isEdit, form, data, permission }) => {
  const { scIndustryOptions, scTruckTypeOptions, scGVWOptions } = useOptions();
  const disabled = permission.isClient;
  return (
    <Section>
      {isEdit ? (
        <FormSection>
          {/* Use it to save delete_attachments for attachment removing */}
          <InputItem name={'delete_attachments'} label="" form={form} hide />
          <InputItem
            name={'legal_name'}
            label="Legal Company Name"
            form={form}
            required
            disabled={disabled}
          />
          <InputItem
            name={'industry_id'}
            label="Industry"
            form={form}
            type="select"
            options={scIndustryOptions}
          />
          <ShouldUpdateWrapper>
            {() => {
              const values = form.getFieldsValue();
              const foundOption =
                find(scIndustryOptions, {
                  value: values.industry_id,
                }) || {};
              if (foundOption.label !== 'Other') {
                return <></>;
              }
              return (
                <>
                  <InputItem name={'other'} label="Other" form={form} />
                </>
              );
            }}
          </ShouldUpdateWrapper>
          <InputItem
            name={'phone_number'}
            label="Phone"
            form={form}
            type="phone"
          />
          <InputItem
            name={'email'}
            label="Email"
            form={form}
            placeholder="example@emailaddress.com"
          />
          <InputItem
            name={'type_of_trucks'}
            label="Type of Truck(s)"
            form={form}
            type="select"
            options={scTruckTypeOptions}
            mode="multiple"
            isMultipleOptionCheckbox
            isBlue
          />
          <InputItem
            name={'gvw_id'}
            label="GVW (lbs.)"
            form={form}
            type="select"
            options={scGVWOptions}
          />
        </FormSection>
      ) : (
        <ListInfo objKeys={COMPANY_INFO_OBJ_KEYS} data={data} />
      )}
    </Section>
  );
};

export default CompanyInfo;
