import get from 'lodash/get';

const departmentColorMapping = {
  Accounting: 'pink',
  Authority: 'red',
  Compliance: 'red',
  Dispatch: 'orange',
  Factoring: 'blue',
  'Sales & Marketing': 'lightGreen',
  Sales: 'lightGreen',
  'Tech Support': 'purple',
  Underwriting: 'violet',
};
const isMarkUnreadClick = e => {
  const classList = get(e.target, 'classList.value');
  const isSpan = e && e.target.tagName === 'SPAN';
  return isSpan && classList.includes('ant-blank-circle-icon');
};

export { departmentColorMapping, isMarkUnreadClick };
