export const NEW_KEY = 'new_background';
export const NORMAL_FIELDS = ['first_name', 'last_name', 'ownership', 'other'];
export const NULL_FIELDS = ['owner_id'];
export const NUMBER_FIELDS = ['ownership'];
export const DATE_FIELDS = ['dob'];
export const FILE_FIELDS = ['documents.background_check'];
export const BOOLEAN_FIELDS = ['is_5_addresses_changes'];
export const SECTION_FIELDS = [
  {
    parentName: 'criminal_records',
    fields: ['type_of_violation_id', 'year', 'other'],
  },
  {
    parentName: 'bankruptcies',
    fields: ['bankruptcy_status_id', 'year', 'other'],
  },
  {
    parentName: 'liens',
    fields: ['lien_type_id', 'year', 'amount', 'state_id', 'other'],
  },
  {
    parentName: 'judgements',
    fields: ['name', 'year', 'amount'],
  },
  {
    parentName: 'evictions',
    fields: ['eviction_type_id', 'year', 'other'],
  },
];
export const SECTION_NULL_FIELDS = [
  'type_of_violation_id',
  'bankruptcy_status_id',
  'lien_type_id',
  'state_id',
  'eviction_type_id',
];
export const SECTION_NUMBER_FIELDS = ['amount'];
export const BODY_REQUEST_NORMAL_FIELDS = [
  'first_name',
  'last_name',
  'owner_id',
  'other',
];
export const DEFAULT_TRUCK = {
  key: 'section 1',
  id: 10,
  label: '435',
  status: 'active',
};
export const DEFAULT_SECTIONS = [];

export const PROFILE_OBJ_KEYS = [
  {
    label: 'Owner',
    key: 'owner',
    type: 'value',
  },
  {
    label: 'Other',
    key: 'other',
    type: 'value',
    dependField: 'owner',
    dependFn: val => val !== 'Other',
  },
  {
    label: 'Ownership %',
    key: 'ownership',
    type: 'value',
    suffix: '%',
    onFilter: val => (val ? String(val).replace('%', '') : val),
  },
  {
    label: 'DOB - Date of Birth',
    key: 'dob',
    type: 'date',
  },
  {
    label: 'Background Check',
    key: 'documents.background_check',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'More than 5 address changes in the last 5 years?',
    key: 'is_5_addresses_changes',
    type: 'checkbox',
    fullWidth: true,
  },
];
