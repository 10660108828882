import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as authSelectors from 'store/selectors/auth';
import * as notificationSelectors from 'store/selectors/notification';
// ACTIONS
import * as notificationActions from 'store/actions/notification';

export const useIndexData = () => {
  return {
    currentUser: authSelectors.useSelectCurrentUser(),
    unreadNotifications: notificationSelectors.useSelectUnreadNotifications(),
    readNotifications: notificationSelectors.useSelectReadNotifications(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...notificationActions,
  });
};
