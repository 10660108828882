import union from 'lodash/union';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

function getDiffObject(obj1Data, obj2Data) {
  const obj1 = obj1Data || {};
  const obj2 = obj2Data || {};
  const newObj = { ...obj2 };
  var keys = union(Object.keys(obj1), Object.keys(obj2));
  // eslint-disable-next-line
  keys.map(k => {
    if (isArray(obj2[k])) {
      newObj[k] = newObj[k].map((obj, i) => {
        let newArrObj = { ...obj };
        newArrObj = getDiffObject(obj1[k][i], newArrObj);
        return newArrObj;
      });
    } else if (isObject(obj2[k])) {
      newObj[k] = getDiffObject(obj1[k], obj2[k]);
    } else if (obj1[k] === obj2[k]) {
      delete newObj[k];
    }
  });
  return newObj;
}

const getErrorMessageFromObj = err => {
  const errObj =
    get(err, 'message') || get(err, 'details') || get(err, 'error') || {};
  const defaultMessage = 'Something went wrong. Please try again!';
  if (typeof errObj === 'string') return [errObj];
  const keyMaps = Object.keys(errObj) || [];
  const messages =
    typeof errObj === 'string'
      ? errObj
      : keyMaps.length > 0
      ? keyMaps.map(
          k =>
            (typeof errObj[k] === 'string' ? errObj[k] : errObj[k][0]) ||
            defaultMessage
        )
      : [defaultMessage];
  return messages;
};

export { getDiffObject, getErrorMessageFromObj };
