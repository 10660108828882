export const TAB_NAME_MAPPING = {
  '1': 'general',
  '2': 'irp',
  '3': 'ifta',
  '4': 'oregon',
  '5': 'newMexico',
  '6': 'kentucky',
  '7': 'newYork',
};
export const TAB_DATA_MAPPING = {
  general: 'general_filing',
  irp: 'irp_filing',
  ifta: 'ifta_filing',
  oregon: 'oregon_filing',
  newMexico: 'new_mexico_filing',
  kentucky: 'kentucky_filing',
  newYork: 'new_york_filing',
};
