import { ROLES } from 'helper/const';
import { adminPermission } from './admin';
import { clientPermission } from './client';
import { clientDriverPermission } from './clientDriver';

const permissions = {
  [ROLES.ADMIN]: adminPermission,
  [ROLES.SUPER_ADMIN]: adminPermission,
  [ROLES.CLIENT]: clientPermission,
  [ROLES.CLIENT_DRIVER]: clientDriverPermission,
};

const getPermission = (role = ROLES.CLIENT, currentUser = {}) => {
  return (
    permissions[role] ||
    (!!currentUser.group_id ? adminPermission : clientPermission)
  );
};

export default getPermission;
