import React from 'react';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';

import Button from 'components/Button';
import Space from 'components/Space';
import Upload, { Dragger } from 'components/Upload';

const UploadComponent = () => {
  const fileList = [
    {
      uid: '1',
      name: 'image.png',
      status: 'done',
      url:
        'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
    {
      uid: '2',
      name: 'image.png',
      status: 'done',
      url:
        'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
    {
      uid: '3',
      name: 'image.png',
      status: 'done',
      url:
        'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
    {
      uid: '4',
      name: 'image.png',
      status: 'done',
      url:
        'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
  ];

  const onChange = ({ fileList: newFileList }) => {};

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const fileList1 = [
    {
      uid: '-1',
      name: 'xxx.png',
      status: 'done',
      url:
        'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      thumbUrl:
        'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
    {
      uid: '-2',
      name: 'yyy.png',
      status: 'error',
    },
  ];

  const props = {
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    listType: 'picture',
    defaultFileList: [...fileList1],
  };

  return (
    <Space direction={'vertical'}>
      <h3>Normal Upload</h3>
      <Upload
        defaultFileList={[
          {
            uid: '1',
            name: 'xxx.png',
            status: 'done',
            response: 'Server Error 500', // custom error message to show
            url: 'http://www.baidu.com/xxx.png',
          },
          {
            uid: '2',
            name: 'yyy.png',
            status: 'done',
            url: 'http://www.baidu.com/yyy.png',
          },
          {
            uid: '3',
            name: 'zzz.png',
            status: 'error',
            response: 'Server Error 500', // custom error message to show
            url: 'http://www.baidu.com/zzz.png',
          },
        ]}
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        showUploadList={{
          showDownloadIcon: true,
          showRemoveIcon: true,
          removeIcon: <CloseOutlined />,
        }}
      >
        <Button type={'ghost'}>Upload</Button>
      </Upload>
      <h3>Drag And Drop</h3>
      <Dragger />
      <h3>File Image</h3>
      <Upload
        type="img-crop"
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        onPreview={onPreview}
        showChild={fileList.length < 5}
      />
      <Upload
        {...props}
        showUploadList={{
          showRemoveIcon: true,
          removeIcon: <CloseOutlined />,
        }}
      >
        <Button>
          <UploadOutlined /> Upload
        </Button>
      </Upload>
    </Space>
  );
};

export default UploadComponent;
