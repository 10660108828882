import React from 'react';
import cn from 'classnames';
import { getLatestMessageTime } from 'utils/date';
import Button, { ButtonColors } from 'components/Button';
import classes from './NotificationItem.module.scss';

const NotificationItem = ({
  className,
  readNotification,
  onItemClick,
  onStartOrder,
  data,
  hideStartOrder,
}) => {
  const hasStartOrder = !!data?.order_type_id && !hideStartOrder;
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.hasStartOrder]: hasStartOrder,
          [classes.readNotification]: readNotification,
        },
        className
      )}
      onClick={onItemClick}
    >
      <div className={classes.title}>
        <div>
          <h3>{data?.title || ''}</h3>
        </div>
        <span>
          {data?.created_at ? getLatestMessageTime(data.created_at) : ''}
        </span>
      </div>
      <p>{data?.message || '-'}</p>
      {!!data?.order_type_id && !hideStartOrder && (
        <div
          className={classes.startOrder}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Button
            size="small"
            htmlType="button"
            color={ButtonColors.BLUE}
            type="primary"
            onClick={e => {
              if (onStartOrder) onStartOrder();
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            Start Order
          </Button>
        </div>
      )}
    </div>
  );
};

export default NotificationItem;
