import toast from 'helper/toast';
import { getErrorMessageFromObj } from 'utils/object';

export const actionCb = (cb, errCb, hideToast = false) => (res, err) => {
  if (res) {
    if (cb) cb(res);
  } else {
    if (!hideToast) {
      toast.error(getErrorMessageFromObj(err)[0]);
    }
    if (errCb) errCb(err);
  }
};
