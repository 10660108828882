import React from 'react';
import { Switch as AntdSwitch } from 'antd';
import cx from 'classnames';

import classes from './Switch.module.scss';

const Switch = props => {
  const { className, label, ...other } = props;
  return (
    <div className={cx(classes.wrapper, className)}>
      <AntdSwitch {...other} />
      {!!label && <label>{label}</label>}
    </div>
  );
};

export default Switch;
