import { apiCreateUpdateTruck, apiGetTrucks, apiUpdateTruckStatus } from 'api';
import { truckTypes } from '../types';

// SYNC_UPDATE_TRUCK
export const syncUpdateTruck = (truckId, truckData) => dispatch => {
  dispatch({
    type: truckTypes.SYNC_UPDATE_TRUCK,
    payload: { truckId, truckData },
  });
};

// GET_TRUCKS
export const getTrucks = ({ id }, filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: truckTypes.GET_TRUCKS,
    payload: {},
    asyncCall: () => {
      return apiGetTrucks(id, filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};

// CREATE_TRUCK
export const createTruck = (clientId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: truckTypes.CREATE_TRUCK,
    payload: {},
    asyncCall: () => {
      return apiCreateUpdateTruck(clientId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_TRUCK
export const updateTruck = (clientId, data, cb, truckId) => {
  return {
    isAsyncCall: true,
    baseType: truckTypes.UPDATE_TRUCK,
    payload: { truckId },
    asyncCall: () => {
      return apiCreateUpdateTruck(clientId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(syncUpdateTruck(truckId, response?.data || {}));
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_TRUCK_STATUS
export const updateTruckStatus = (clientId, truckId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: truckTypes.UPDATE_TRUCK_STATUS,
    payload: { truckId },
    asyncCall: () => {
      return apiUpdateTruckStatus(clientId, truckId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
