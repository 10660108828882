import React, { useState, useEffect } from 'react';
import Modal from 'components/Modal';
import { MODAL, FORM_VALIDATION_MESSAGES } from 'helper/const';
import { InputPassword, InputPasswordStrong } from 'components/FormFields';
import Form, { FormItem, useForm } from 'components/Form';
import { getErrorMessageFromObj } from 'utils/object';
import { useChangePasswordData, useActions } from './selectorData';
import {
  standardRequiredFieldObj,
  confirmPasswordDontMatchFieldObj,
  validateForm,
  scrollToErrorField,
} from 'utils/form';
import toast from 'helper/toast';
import classes from './MyProfile.module.scss';

export const validateObj = () => {
  return {
    currentPassword: [
      {
        ...standardRequiredFieldObj,
        message: FORM_VALIDATION_MESSAGES.PASSWORD_REQUIRED,
      },
    ],
    password: [
      {
        ...standardRequiredFieldObj,
        message: FORM_VALIDATION_MESSAGES.PASSWORD_REQUIRED,
      },
    ],
    confirmPassword: [
      {
        ...standardRequiredFieldObj,
        message: FORM_VALIDATION_MESSAGES.PASSWORD_REQUIRED,
      },
      confirmPasswordDontMatchFieldObj,
    ],
  };
};

const ChangePasswordModal = ({ isOpen, handleClose }) => {
  const { changePassword } = useActions();
  const { changePasswordLoading } = useChangePasswordData();
  const [form] = useForm();
  const [strongPassword, setStrongPassword] = useState([]);
  const handleSubmitForm = values => {
    const fieldErrors = validateForm(validateObj(), values);
    if (fieldErrors.length > 0) {
      form.setFields(fieldErrors);
      scrollToErrorField();
    } else if (strongPassword.length === 3) {
      changePassword(
        {
          password: values.currentPassword,
          new_password: values.password,
          confirm_new_password: values.confirmPassword,
        },
        (res, err) => {
          if (res) {
            handleClose();
            toast.success('Change Password Successfully!');
          } else {
            toast.error(
              getErrorMessageFromObj(err)[0] || 'Change Password Failed!'
            );
          }
        }
      );
    }
  };
  const modalProps = {
    isOpen,
    onSubmit: () => {
      const values = form.getFieldsValue();
      handleSubmitForm(values);
    },
    onClose: handleClose,
    title: <>Change Password</>,
    maskClosable: false,
    closable: true,
    cancelText: 'Cancel',
    okText: 'Save',
    width: MODAL.SIZE.SEMI_LARGE,
    className: classes.changePasswordModal,
    isSubmitLoading: changePasswordLoading,
    closeIcon: null,
    okBtnProps: {
      size: 'large',
    },
    cancelBtnProps: {
      size: 'large',
    },
    blueColor: true,
  };
  useEffect(() => {
    if (isOpen && form) {
      form.resetFields();
    }
  }, [form, isOpen]);
  return (
    <Modal {...modalProps}>
      <Form
        submitHidden
        onFinish={values => {
          handleSubmitForm(values);
        }}
        initialValues={{
          currentPassword: '',
          password: '',
          confirmPassword: '',
        }}
        form={form}
        className={classes.changePasswordForm}
      >
        <p>To change your password, please enter your current password:</p>
        <FormItem name={'currentPassword'} label="Current Password" required>
          <InputPassword
            size={'medium'}
            name="currentPassword"
            form={form}
            autoFocus={true}
          />
        </FormItem>
        <div className={classes.newPasswordSection}>
          <p>Enter your new password:</p>
          <InputPasswordStrong
            passwordStrongChange={val => {
              setStrongPassword(val);
            }}
            label="New Password"
            form={form}
            size={'medium'}
            className={classes.strongPasswordField}
            confirmPasswordName="confirmPassword"
            required
          />
          <FormItem name={'confirmPassword'} label="Re-Enter Password" required>
            <InputPassword size={'medium'} name="confirmPassword" form={form} />
          </FormItem>
        </div>
      </Form>
    </Modal>
  );
};
export default ChangePasswordModal;
