import React from 'react';
import cn from 'classnames';
import { Title } from '../Elements';
import { getFieldValue } from './utils';
import classes from './OrderDetail.module.scss';

const OrderDetail = ({ className, values = [] }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <Title>Order Details</Title>
      <div className={classes.innerContent}>
        {values.map((section, sIndex) => {
          return (
            <div className={classes.section} key={sIndex}>
              {section.fields.length > 0 && (
                <>
                  {section.fields.map((field, fIndex) => {
                    return (
                      <React.Fragment key={fIndex}>
                        <h4>{field.category}</h4>
                        {field.fields.length > 0 && (
                          <ul>
                            {field.fields.map((sField, sIndex) => {
                              return (
                                <li key={sIndex}>
                                  <span>{sField.name}</span>
                                  <span>{getFieldValue(sField)}</span>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </React.Fragment>
                    );
                  })}
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OrderDetail;
