import React from 'react';
import cn from 'classnames';
import Empty from 'components/Empty';
import Button from 'components/Button';
import { SectionWrapper } from '../../../components/Elements';
import classes from './NotFound.module.scss';

const NotFound = ({ className, text, isConnectionIssue, onClick }) => {
  return (
    <SectionWrapper className={cn(classes.wrapper, className)}>
      {isConnectionIssue ? (
        <div className={classes.connectionIssue}>
          <h5>SAFER CONNECTION ISSUE</h5>
          <p>
            Uh oh! Looks like our connection with SAFER is currently down.
            <br />
            Please try again later.
          </p>
          <Button onClick={onClick} htmlType="button" color="blue">
            Try Again Later
          </Button>
        </div>
      ) : (
        <Empty type="image1">
          <span>{text || 'No records to display'}</span>
        </Empty>
      )}
    </SectionWrapper>
  );
};

export default NotFound;
