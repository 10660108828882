import {
  apiCreateUpdateTrailer,
  apiGetTrailers,
  apiUpdateTrailerStatus,
} from 'api';
import { getTrailersList } from '../actions/shared';
import { trailerTypes } from '../types';

// SYNC_UPDATE_TRAILER
export const syncUpdateTrailer = (trailerId, trailerData) => dispatch => {
  dispatch({
    type: trailerTypes.SYNC_UPDATE_TRAILER,
    payload: { trailerId, trailerData },
  });
};

// GET_TRAILERS
export const getTrailers = ({ id }, filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: trailerTypes.GET_TRAILERS,
    payload: {},
    asyncCall: () => {
      return apiGetTrailers(id, filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};

// CREATE_TRAILER
export const createTrailer = (clientId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: trailerTypes.CREATE_TRAILER,
    payload: {},
    asyncCall: () => {
      return apiCreateUpdateTrailer(clientId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(getTrailersList(clientId));
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_TRAILER
export const updateTrailer = (clientId, data, cb, trailerId) => {
  return {
    isAsyncCall: true,
    baseType: trailerTypes.UPDATE_TRAILER,
    payload: { trailerId },
    asyncCall: () => {
      return apiCreateUpdateTrailer(clientId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(getTrailersList(clientId));
      dispatch(syncUpdateTrailer(trailerId, response?.data || {}));
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_TRAILER_STATUS
export const updateTrailerStatus = (clientId, trailerId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: trailerTypes.UPDATE_TRAILER_STATUS,
    payload: { trailerId },
    asyncCall: () => {
      return apiUpdateTrailerStatus(clientId, trailerId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(getTrailersList(clientId));
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
