import React from 'react';
import cn from 'classnames';
import { getLatestMessageTime } from 'utils/date';
import { getLatestMessageText } from 'utils/message';
import { searchBy } from 'utils/index';
import { CHAT_TYPES } from 'helper/const';
import { Contact, NotContactFound } from './components/Elements';
import { useInternalChatData, useActions } from './selectorData';
import classes from './Chat.module.scss';

const InternalChat = ({
  isCollapse,
  permission,
  handleViewDetail,
  setDetailObj,
  detailObj,
  handleMarkUnread,
  internalSearch,
}) => {
  const { conversations } = useInternalChatData();
  const { createChatGroup, getInternalConversations } = useActions();
  const departmentChats = conversations.department || [];
  const internalChats = conversations.individual || [];
  const searchedDepartmentChats = searchBy(departmentChats, internalSearch);
  const searchedInternalChats = searchBy(internalChats, internalSearch);
  const getConversationData = (conversationId, detailData) => {
    if (!conversationId) {
      const { id, group, type } = detailData || {};
      createChatGroup(
        {
          name: group,
          members: id,
          type,
        },
        res => {
          if (res) {
            getInternalConversations();
            setDetailObj({
              ...detailObj,
              type: CHAT_TYPES.INTERNAL_DEPARTMENT,
              conversation_id: res.data,
              name: group,
              group,
            });
          }
        }
      );
    }
  };

  return (
    <div className={cn(classes.chatContent, classes.internalChat)}>
      <Contact isCollapse={isCollapse} hideUnread>
        <span>Department{!isCollapse && ' Chats'}</span>
      </Contact>
      {searchedDepartmentChats.map((d, i) => {
        return (
          <Contact
            key={`${d.conversation_id}_${i}`}
            name={d.name}
            departmentColor={d.name}
            isCollapse={isCollapse}
            message={getLatestMessageText(d, true)}
            dateStr={d.created_at ? getLatestMessageTime(d.created_at) : ''}
            unread={d.unread_message_count}
            onClick={() => {
              const detailData = {
                ...d,
                type: permission.internalType,
                group: d.name,
              };
              handleViewDetail(detailData);
              getConversationData(detailData.conversation_id, detailData);
            }}
            onMarkUnread={handleMarkUnread(d, true)}
            hideUnread={!d.message_id}
          />
        );
      })}
      {!permission.isClient && (
        <>
          <Contact isCollapse={isCollapse} hideUnread>
            <span>Internal{!isCollapse && ' Chats'}</span>
          </Contact>
          {internalChats.length === 0 ? (
            <NotContactFound
              message="You don’t have any internal chats yet. Start a new one below!"
              isCollapse={isCollapse}
            />
          ) : (
            <>
              {searchedInternalChats.map((chat, i) => {
                return (
                  <Contact
                    {...chat}
                    key={`${chat.conversation_id}_${i}`}
                    isCollapse={isCollapse}
                    avatar={
                      chat.type === CHAT_TYPES.INTERNAL_CUSTOM
                        ? 'group'
                        : chat.profile_pic || ''
                    }
                    message={getLatestMessageText(
                      chat,
                      chat.type === CHAT_TYPES.INTERNAL_CUSTOM
                    )}
                    unread={chat.unread_message_count}
                    department={chat.group}
                    departmentColor={chat.group}
                    onClick={() => {
                      getConversationData(chat.conversation_id);
                      handleViewDetail({
                        ...chat,
                        isIndividual:
                          chat.type === CHAT_TYPES.INTERNAL_INDIVIDUAL,
                      });
                    }}
                    dateStr={
                      chat.created_at
                        ? getLatestMessageTime(chat.created_at)
                        : ''
                    }
                    onMarkUnread={handleMarkUnread(chat)}
                    hideUnread={!chat.message_id}
                  />
                );
              })}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default InternalChat;
