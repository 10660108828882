import sound from 'assets/files/sound.mp3';

const getDisplayMembers = members => {
  return (members || []).map(m => ({
    ...m,
    department: m.group,
    departmentColor: m.group,
  }));
};
const getSearchedChatMembers = (member, isClient) => {
  const returnedMembers = {
    ...member,
    avatar: member.profile_pic || '',
    name: member.full_name,
  };
  if (isClient) {
    returnedMembers.role = member.company_name || '';
  } else {
    if (!returnedMembers.department) {
      returnedMembers.department = member.name;
    }
    if (!returnedMembers.departmentColor) {
      returnedMembers.departmentColor = member.name;
    }
  }
  return returnedMembers;
};
const getMessageAttachments = message => {
  return message.attachment_name
    ? [
        {
          id: message.id,
          name: message.attachment_name,
          url: message.attachment_path,
        },
      ]
    : (message.attachments || []).length > 0
    ? message.attachments.map(a => ({
        id: a.id,
        name: a.attachment_name,
        url: a.attachment_path,
      }))
    : [];
};
const getDisplayMessages = ({ messages, currentUser }) => {
  return (messages || []).map(m => ({
    ...m,
    message: m.text || '',
    name: m.owner_name || '',
    isMe: m.owner === currentUser.id,
    departmentColor: m.group,
    avatar: m.owner_profile_pic || '',
    attachments: getMessageAttachments(m),
  }));
};
const getExistingMembers = members => {
  return (members || []).map(m => ({
    ...m,
    user_id: m.id,
  }));
};
const getUpdatedMembers = (oldMembers, newMembers, currentUserId) => {
  const oldIds = oldMembers.map(m => m.user_id);
  const newIds = newMembers.map(m => m.user_id);
  const addMemberIds = [];
  const removeMemberIds = [];
  oldIds.map(id => {
    if (!newIds.includes(id) && id !== currentUserId) {
      removeMemberIds.push(id);
    }
    return true;
  });
  newIds.map(id => {
    if (!oldIds.includes(id)) {
      addMemberIds.push(id);
    }
    return true;
  });
  return { addMemberIds, removeMemberIds };
};
const getDisplayUnreadCount = c => {
  // will do some logic for unread count later
  // because Antd already had overflowCount
  return c;
};

const playSound = url => {
  const audio = new Audio(url || sound);
  audio.play();
};

export {
  getDisplayMembers,
  getExistingMembers,
  getUpdatedMembers,
  getDisplayMessages,
  getSearchedChatMembers,
  getDisplayUnreadCount,
  playSound,
};
