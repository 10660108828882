import React from 'react';
import cn from 'classnames';
import FormListSection from 'views/pages/ClientDetail/components/FormListSection';
import SecurityBlock from 'views/pages/ClientDetail/components/SecurityBlock';
import {
  Title,
  PrimaryTag,
} from 'views/pages/ClientDetail/components/Elements';
import CheckingAccountEditForm from '../CheckingAccountEditForm';
import {
  getAccountBR,
  setFullAccount,
  validateCheckingAccountObj,
} from './utils';
import classes from './Section.module.scss';

const CheckingAccount = ({
  className,
  isEdit,
  form,
  accounts,
  onEdit,
  onValidatePassword,
  validatePasswordLoading,
  getDriverAccountLoading,
  wrapperId,
}) => {
  const accountsData = accounts || [];

  return (
    <div className={cn(classes.cardSection, className)}>
      <Title text="Checking Account" />
      <FormListSection
        notFoundMessage="Currently there is no account information available."
        isEdit={isEdit}
        form={form}
        data={accountsData}
        initialObjFn={arrayLength => {
          return {
            name_on_account: '',
            bank_name: '',
            routing_number_ach: '',
            routing_number_wire: '',
            account_number: '',
            type: null,
            is_default_account: arrayLength === 0,
          };
        }}
        additionalTitleFn={(d, index) => {
          const obj = isEdit ? d : accountsData[index] || {};
          return obj.is_default_account ? <PrimaryTag isDefault /> : '';
        }}
        titleField="title"
        name="accounts"
        addBtnText="Add Account"
        newLabel="No. "
        isSecurity
        renderFields={(index, isExisting) => {
          if (isExisting) {
            const currentAccountData = accountsData[index] || {};
            const simpleFields = [
              { name: 'name_on_account', label: 'Name on Account' },
              { name: 'simple_account_type', label: 'Account Type' },
              { name: 'simple_account_number', label: 'Account Number' },
            ];
            const fullFields = [
              { name: [index, 'name_on_account'], label: 'Name on Account' },
              { name: [index, 'bank_name'], label: 'Bank Name' },
              {
                name: [index, 'routing_number_ach'],
                label: 'Routing Number ACH',
              },
              {
                name: [index, 'routing_number_wire'],
                label: 'Routing Number WIRE',
              },
              { name: [index, 'account_number'], label: 'Account Number' },
              { name: [index, 'type'], label: 'Business or Personal' },
              {
                name: 'is_default_account',
                label: 'Default Account',
                type: 'checkbox',
              },
            ];
            return (
              <SecurityBlock
                form={form}
                isEdit={isEdit}
                index={index}
                data={currentAccountData}
                parentName="accounts"
                validateObj={validateCheckingAccountObj()}
                wrapperId={wrapperId}
                renderEdit={() => {
                  return (
                    <CheckingAccountEditForm
                      index={index}
                      form={form}
                      parentName="accounts"
                    />
                  );
                }}
                onSubmit={onEdit}
                onValidatePassword={onValidatePassword(
                  currentAccountData.id,
                  res => {
                    setFullAccount(res.data || {}, currentAccountData.id, form);
                  }
                )}
                submitLoading={
                  validatePasswordLoading ||
                  !!getDriverAccountLoading[currentAccountData.id]
                }
                getBR={getAccountBR}
                simpleFields={simpleFields}
                fullFields={fullFields}
                title={<>Secured Information</>}
              />
            );
          }
          return (
            <CheckingAccountEditForm
              index={index}
              form={form}
              parentName="accounts"
            />
          );
        }}
        renderViews={() => {
          return '';
        }}
      />
    </div>
  );
};

export default CheckingAccount;
