import get from 'lodash/get';
import * as chatApi from 'api/chat';
import { store } from 'store';
import { chatTypes } from '../types';

const handleUpdateTabCount = (response, dispatch) => {
  const clientCount = get(response, 'data.client_unread_count') || 0;
  const internalCount = get(response, 'data.internal_unread_count') || 0;
  const companies = get(response, 'data.companies') || [];
  dispatch(syncSetInternalCount(internalCount));
  dispatch(syncSetClientCount(clientCount));
  dispatch(syncSetCompanies(companies));
};

// GET
// GET_INTERNAL_CONVERSATIONS
export const getInternalConversations = (filters = {}, cb) => {
  const storeData = store.getState();
  const currentUser = get(storeData, 'auth.currentUser.data') || {};
  return {
    isAsyncCall: true,
    baseType: chatTypes.GET_INTERNAL_CONVERSATIONS,
    asyncCall: () => {
      return chatApi.apiGetChatConversations({
        ...filters,
        type:
          currentUser.role !== 'Admin' && !currentUser.group_id
            ? 'client'
            : 'internal',
      });
    },
    afterSuccessCall: (dispatch, response) => {
      handleUpdateTabCount(response, dispatch);
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_CLIENT_CONVERSATIONS
export const getClientConversations = (filters = {}, cb) => {
  return {
    isAsyncCall: true,
    baseType: chatTypes.GET_CLIENT_CONVERSATIONS,
    asyncCall: () => {
      return chatApi.apiGetChatConversations({
        ...filters,
        type: 'client',
      });
    },
    afterSuccessCall: (dispatch, response) => {
      handleUpdateTabCount(response, dispatch);
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_CHAT_DEPARTMENTS
export const getChatDepartments = cb => {
  return {
    isAsyncCall: true,
    baseType: chatTypes.GET_CHAT_DEPARTMENTS,
    asyncCall: () => {
      return chatApi.apiGetChatDepartments();
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_CHAT_DEPARTMENTS_LIST
export const getChatDepartmentsList = cb => {
  return {
    isAsyncCall: true,
    baseType: chatTypes.GET_CHAT_DEPARTMENTS_LIST,
    asyncCall: () => {
      return chatApi.apiGetChatDepartmentsList();
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_MESSAGES
export const getMessages = (conversationId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: chatTypes.GET_MESSAGES,
    asyncCall: () => {
      return chatApi.apiGetMessages(conversationId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_ATTACHMENT
export const getAttachment = (attachmentId, cb) => {
  return {
    isAsyncCall: true,
    baseType: chatTypes.GET_ATTACHMENT,
    asyncCall: () => {
      return chatApi.apiGetAttachment(attachmentId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// SEARCH_INTERNAL_MEMBERS
export const searchInternalMembers = (filters = {}, cb) => {
  return {
    isAsyncCall: true,
    baseType: chatTypes.SEARCH_INTERNAL_MEMBERS,
    asyncCall: () => {
      return chatApi.apiSearchMembers({
        ...filters,
        type: 'internal',
      });
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// SEARCH_CLIENT_MEMBERS
export const searchClientMembers = (filters = {}, cb) => {
  const storeData = store.getState();
  const currentUser = get(storeData, 'auth.currentUser.data') || {};
  const otherFilters = {};
  if (!(currentUser.role !== 'Admin' && !currentUser.group_id)) {
    otherFilters.type = 'client';
  }
  return {
    isAsyncCall: true,
    baseType: chatTypes.SEARCH_CLIENT_MEMBERS,
    asyncCall: () => {
      return chatApi.apiSearchMembers({
        ...filters,
        ...otherFilters,
      });
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_CHAT_MEMBERS
export const getChatMembers = (conversationId, cb) => {
  return {
    isAsyncCall: true,
    baseType: chatTypes.GET_CHAT_MEMBERS,
    asyncCall: () => {
      return chatApi.apiGetChatMembers(conversationId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// POST
// SEND_MESSAGE
export const sendMessage = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: chatTypes.SEND_MESSAGE,
    asyncCall: () => {
      return chatApi.apiSendMessage(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// MARK_READ
export const markRead = (conversationId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: chatTypes.MARK_READ,
    asyncCall: () => {
      return chatApi.apiMarkRead(conversationId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_CHAT_SETTINGS
export const updateChatSettings = (conversationId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: chatTypes.UPDATE_CHAT_SETTINGS,
    asyncCall: () => {
      return chatApi.apiUpdateChatSettings(conversationId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// ADD_MEMBERS
export const addMembers = (conversationId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: chatTypes.ADD_MEMBERS,
    asyncCall: () => {
      return chatApi.apiAddMembers(conversationId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// REMOVE_MEMBERS
export const removeMembers = (conversationId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: chatTypes.REMOVE_MEMBERS,
    asyncCall: () => {
      return chatApi.apiRemoveMembers(conversationId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CHECK_CONVERSATION
export const checkConversation = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: chatTypes.CHECK_CONVERSATION,
    asyncCall: () => {
      return chatApi.apiCheckConversation(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CREATE_CHAT_GROUP
export const createChatGroup = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: chatTypes.CREATE_CHAT_GROUP,
    asyncCall: () => {
      return chatApi.apiCreateChatGroup(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};

// CUSTOM
// SYNC_SET_MESSAGES
export const syncSetMessages = (data, additionalData) => dispatch => {
  dispatch({
    type: chatTypes.SYNC_SET_MESSAGES,
    payload: data,
    ...additionalData,
  });
};
// SYNC_SET_CHAT_MEMBERS
export const syncSetChatMembers = (data, additionalData) => dispatch => {
  dispatch({
    type: chatTypes.SYNC_SET_CHAT_MEMBERS,
    payload: data,
    ...additionalData,
  });
};
// SYNC_SET_INTERNAL_COUNT
export const syncSetInternalCount = data => dispatch => {
  dispatch({
    type: chatTypes.SYNC_SET_INTERNAL_COUNT,
    payload: data,
  });
};
// SYNC_SET_CLIENT_COUNT
export const syncSetClientCount = data => dispatch => {
  dispatch({
    type: chatTypes.SYNC_SET_CLIENT_COUNT,
    payload: data,
  });
};
// SYNC_SET_COMPANIES
export const syncSetCompanies = data => dispatch => {
  dispatch({
    type: chatTypes.SYNC_SET_COMPANIES,
    payload: data,
  });
};
// SYNC_INCREASE_UNREAD_CHAT
export const increaseUnreadChat = (
  conversationId,
  parentKey,
  key
) => dispatch => {
  dispatch({
    type: chatTypes.SYNC_INCREASE_UNREAD_CHAT,
    payload: conversationId,
    parentKey,
    key,
  });
};
// SYNC_INCREASE_UNREAD_CHATS
export const increaseUnreadChats = key => dispatch => {
  dispatch({
    type: chatTypes.SYNC_INCREASE_UNREAD_CHATS,
    key,
  });
};
