export const ACTIVITY_KEY_MAPPING = {
  title_id: 'Title',
  ownership: 'Ownership %',
  dob: 'DOB - Date of Birth',
  ssn: 'SSN - Social Security Number',
  is_same_as_physical: 'Mailing address same as physical address',
  dl_number: `Driver’s License #`,
  dl_state: `State`,
  dl_expiration_date: `Expiration Date`,
  mc_expiration_date: `Expiration Date`,
  industry_id: `Industry`,
  state_taxpayer_id: `State Taxpayer ID (*Required in Texas)`,
  mc_number: `MC Number`,
  dot_pin: `DOT PIN`,
  apt_suite: `Apt`,
  drug_alcohol_consortium_name: `Drug & Alcohol Consortium Name`,
  consortium_contact_name: `Contact Name`,
  consortium_phone: `Phone`,
  consortium_email: `Email`,
  business_entity_id: `Form of Entity`,
  sos_id: `Secretary of State Filing`,
  state_id: `State`,
  mailing_state_id: `State`,
  gvw_id: `GVW (lbs.)`,
  type_of_trucks: `Type of Truck(s)`,
  dl_state_id: `State`,
  class_id: `Class`,
  phone_number: `Phone`,
  ein: `EIN - Employer Identification Number`,
  tx_dot_username: `Username`,
  tx_dot_password: `Password`,
  ca_number: `CA Number`,
  epn_code: `EPN Requestor Code`,
  tx_dot_number: `TX DOT Number`,
  dot_number: `DOT Number`,
  dot_number_status_id: `DOT Preference`,
  ucr_expiration_date: `Expiration Date`,
  '2290_expiration_date': `Expiration Date`,
  scac_expiration_date: `Expiration Date`,
  scac_code: `1234`,
  insurance_company: `Insurance Company Name`,
  policy_number: `Insurance Policy Number`,
  date_of_application: `Date of Application`,
  prospect_source_id: `Prospect Source`,
  sales_person_id: `Sales Person`,
  mc_first_name: `First Name`,
  mc_last_name: `Last Name`,
  mvr_first_name: `First Name`,
  mvr_last_name: `Last Name`,
  mc_title: `Title`,
  mvr_title: `Title`,
  mc_date: `Date`,
  dq_expiration_date: `Expiration Date`,
  mvr_expiration_date: `Expiration Date`,
  mvr_date: `Date`,
  legal_name: `Company Legal Name`,
  leasor_due: `Due to Leasor (%)`,
  trailer_unit: `Trailer Unit #`,
  vin: `VIN - Vehicle Identification Number`,
  unladen_weight: `Unladen Weight (lbs.)`,
  gvwr: `GVWR (lbs.)`,
  trailer_length: `Trailer Length (ft/in)`,
  lp_number: `License Plate #`,
  trailer_leased_from: `Leasor`,
  trailer_type_id: `Type of Trailer`,
  lp_state_id: `License Plate State`,
  trailer_state_id: `State`,
  lp_exp_date: `Plate Exp. Date`,
  truck_unit: `Truck Unit #`,
  truck_leased_from: `Leasor`,
  truck_type_id: `Type of Truck`,
  irp_number: `IRP Number`,
  ifta_number: `IFTA Number`,
  insurance_policy_number: `Ins. Policy Number`,
  or_permit_number: `OR Permit Number`,
  nm_permit_number: `NM Permit Number`,
  kyu_permit_number: `KYU Permit Number`,
  ny_hut_permit_number: `NY HUT Permit Number`,
  truck_state_id: `State`,
  garage_address_same_as_physical: `Garage address same as physical address`,
  owner_id: `Owner`,
  is_5_addresses_changes: `More than 5 address changes in the last 5 years`,
  ifa_watchlist: `IFA Watchlist`,
  contact_first_name: `First Name`,
  contact_last_name: `Last Name`,
  contact_phone: `Phone`,
  remit_account: `Remit to Account`,
  contact_other: `Other`,
  is_buyout: `Is this a buyout`,
  contact_title_id: `Title`,
  no_of_accounts: `Number of Accounts`,
  no_of_good_standing_accounts: `Number of Accounts in Good Standing`,
  is_primary: `Primary Address`,
};
