import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_COMPANY_TEAM_LIST',
  'GET_ALL_TEAMS',
  'GET_IN_ACTIVE_TEAM',
  'GET_DEPARTMENT_TEAM',
  'INVITE_TEAMMATE',
  'RESEND_INVITE_TEAMMATE',
  'INVITE_COMPANY_TEAM_MEMBER',
  'UPDATE_COMPANY_TEAM_MEMBER',
  'UPDATE_COMPANY_TEAM_MEMBER_STATUS'
);
