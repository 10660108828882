import React from 'react';
import find from 'lodash/find';
import cn from 'classnames';
import { useOptions, useIsInternalUser, useIsOwnerRecord } from 'helper/hooks';
import { SSN_FIELD } from 'helper/const';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  ShouldUpdateWrapper,
} from 'views/pages/ClientDetail/components/Elements';
import { getMomentDate } from 'utils/date';
import SecurityField from 'components/SecurityField';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { PROFILE_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const Profile = ({
  className,
  isEdit,
  form,
  data,
  onTitleChange,
  clientId,
  isNew,
}) => {
  const { scTitleWithoutDriverOptions } = useOptions();
  const { isInternalUser, isExternalOwner } = useIsInternalUser();
  const isOwnerRecord = useIsOwnerRecord(data?.id);

  return (
    <div className={cn(classes.profileSection, className)}>
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection className={classes.elForm}>
            {/* Use it to save delete_attachments for attachment removing */}
            <InputItem name={'delete_attachments'} label="" form={form} hide />
            <InputItem
              name={'first_name'}
              label="First Name"
              form={form}
              required
              addressGroupField
            />
            <InputItem
              name={'middle_name'}
              label="Middle Name"
              form={form}
              addressGroupField
            />
            <InputItem
              name={'last_name'}
              label="Last Name"
              form={form}
              required
              addressGroupField
            />
            <InputItem
              name={'suffix'}
              label="Suffix"
              form={form}
              addressGroupField
            />
            <InputItem
              name={'title_id'}
              label="Title"
              form={form}
              addressGroupField
              type="select"
              options={scTitleWithoutDriverOptions}
              required
              onChange={onTitleChange}
            />
            <InputItem
              name={'phone'}
              label="Phone"
              form={form}
              type="phone"
              required
              addressGroupField
            />
            <InputItem name={'email'} label="Email" form={form} />
            <ShouldUpdateWrapper>
              {() => {
                const values = form.getFieldsValue();
                const foundOption =
                  find(scTitleWithoutDriverOptions, {
                    value: values.title_id,
                  }) || {};
                if (foundOption.label !== 'Other') {
                  return <></>;
                }
                return (
                  <>
                    <InputItem
                      name={'other'}
                      label="Other"
                      form={form}
                      className={classes.otherField}
                    />
                  </>
                );
              }}
            </ShouldUpdateWrapper>
            <InputItem
              name={'ownership'}
              label="Ownership %"
              form={form}
              type="price"
              addressGroupField
            />
            {isInternalUser ? (
              <SecurityField
                fieldObj={{
                  label: 'DOB - Date of Birth',
                  value: '********',
                  type_id: data?.id,
                  type: 'official',
                  company_id: clientId,
                  key: 'dob',
                  alwaysDisplayView: isNew,
                }}
                className={classes.securityField1}
                viewRender={isShow => {
                  return (
                    <InputItem
                      name={'dob'}
                      label="DOB - Date of Birth"
                      type="date"
                      form={form}
                      addressGroupField
                      hide={!isShow}
                    />
                  );
                }}
                pinSuccessCb={val => {
                  form.setFieldsValue({
                    dob: val ? getMomentDate(val, false, true) : '',
                  });
                }}
              />
            ) : isExternalOwner || isOwnerRecord ? (
              <InputItem
                name={'dob'}
                label="DOB - Date of Birth"
                type="date"
                form={form}
                addressGroupField
              />
            ) : (
              <div />
            )}
            {isInternalUser ? (
              <SecurityField
                fieldObj={{
                  label: 'SSN - Social Security Number',
                  value: '********',
                  type_id: data?.id,
                  type: 'official',
                  company_id: clientId,
                  key: 'ssn',
                  alwaysDisplayView: isNew,
                }}
                viewRender={isShow => {
                  return (
                    <InputItem
                      name={'ssn'}
                      label="SSN - Social Security Number"
                      type="number"
                      mask={SSN_FIELD.MASK}
                      placeholder={SSN_FIELD.PLACEHOLDER}
                      form={form}
                      hide={!isShow}
                    />
                  );
                }}
                pinSuccessCb={val => {
                  form.setFieldsValue({
                    ssn: val,
                  });
                }}
              />
            ) : isExternalOwner || isOwnerRecord ? (
              <InputItem
                name={'ssn'}
                label="SSN - Social Security Number"
                type="number"
                mask={SSN_FIELD.MASK}
                placeholder={SSN_FIELD.PLACEHOLDER}
                form={form}
              />
            ) : (
              <div />
            )}
            <InputItem
              name={'is_primary'}
              label="Primary Contact"
              form={form}
              type="checkbox"
              fullWidth
            />
          </FormSection>
        ) : (
          <ListInfo
            objKeys={PROFILE_OBJ_KEYS}
            data={data}
            sType="official"
            sCompanyId={clientId}
            needSecure
          />
        )}
      </ElementSection>
    </div>
  );
};

export default Profile;
