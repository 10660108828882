import get from 'lodash/get';
import { getBEDate } from 'utils/date';

export const filingTransformData = (data, portalLink = '') => {
  const onlinePortal = get(data, 'online_portals[0]') || {};
  return {
    ...data,
    username: onlinePortal.username,
    password: onlinePortal.password,
    portal_link: portalLink || onlinePortal.portal_link,
  };
};
export const getFilingYearBR = values => {
  const fData = new FormData();
  Object.keys(values || {}).forEach(key => {
    if (key === 'delete_attachments') {
      if (values.delete_attachments?.length > 0) {
        values.delete_attachments.forEach(id => {
          fData.append('delete_documents[]', id);
        });
      }
    } else {
      if (values[key]?.length > 0 && key.includes('_year_wise_')) {
        const keySplit = key.split('_year_wise_');
        const originalKey = keySplit[0];
        const year = keySplit[1];
        values[key].forEach(tFile => {
          if (tFile.originFileObj) {
            fData.append(
              `${originalKey}_year_wise[${year}]`,
              tFile.originFileObj
            );
          }
        });
      } else if (key.includes('_expiration_date_')) {
        const tVal = values[key];
        const keySplit = key.split('_expiration_date_');
        const year = keySplit[1];
        fData.append(`expiration_date[${year}]`, getBEDate(tVal));
      }
    }
  });
  return fData;
};
