import get from 'lodash/get';
import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  formsMSATypes,
} from '../types';
import { initialFormsMSAState } from '../states/formsMSA';

export default (state = initialFormsMSAState, action) => {
  const { msaId } = action?.payload || {};
  switch (action.type) {
    // SYNC_UPDATE_MSAS
    case formsMSATypes.SYNC_UPDATE_MSAS: {
      return {
        ...state,
        formsMSAs: {
          ...state.formsMSAs,
          data: action?.payload,
        },
      };
    }
    // GET_FORMS_MSAS
    case asyncActionTypeRequest(formsMSATypes.GET_FORMS_MSAS): {
      return {
        ...state,
        formsMSAs: {
          ...state.formsMSAs,
          loading: action?.payload?.silent ? false : true,
        },
      };
    }
    case asyncActionTypeSuccess(formsMSATypes.GET_FORMS_MSAS): {
      return {
        ...state,
        formsMSAs: {
          loading: false,
          data: get(action, 'response.data') || [],
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(formsMSATypes.GET_FORMS_MSAS): {
      return {
        ...state,
        formsMSAs: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    }
    // UPDATE_FORMS_MSA
    case asyncActionTypeRequest(formsMSATypes.UPDATE_FORMS_MSA): {
      return {
        ...state,
        updateFormsMSA: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(formsMSATypes.UPDATE_FORMS_MSA): {
      return {
        ...state,
        updateFormsMSA: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(formsMSATypes.UPDATE_FORMS_MSA): {
      return {
        ...state,
        updateFormsMSA: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // UPDATE_FORMS_MSA_STATUS
    case asyncActionTypeRequest(formsMSATypes.UPDATE_FORMS_MSA_STATUS): {
      return {
        ...state,
        updateFormsMSAStatus: {
          ...state.updateFormsMSAStatus,
          loading: {
            ...state.updateFormsMSAStatus.loading,
            [msaId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(formsMSATypes.UPDATE_FORMS_MSA_STATUS): {
      return {
        ...state,
        updateFormsMSAStatus: {
          loading: {
            ...state.updateFormsMSAStatus.loading,
            [msaId]: false,
          },
          data: {
            ...state.updateFormsMSAStatus.data,
            [msaId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.updateFormsMSAStatus.error,
            [msaId]: {},
          },
        },
      };
    }
    case asyncActionTypeFailure(formsMSATypes.UPDATE_FORMS_MSA_STATUS): {
      return {
        ...state,
        updateFormsMSAStatus: {
          loading: {
            ...state.updateFormsMSAStatus.loading,
            [msaId]: false,
          },
          data: {
            ...state.updateFormsMSAStatus.data,
            [msaId]: {},
          },
          error: {
            ...state.updateFormsMSAStatus.error,
            [msaId]: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
};
