import React from 'react';
import Spin from 'components/Spin';
import { useLoadingSpinData } from './selectorData';

const LoadingSpin = ({ children }) => {
  const {
    workQueueColumnsLoading,
    filterSettingLoading,
  } = useLoadingSpinData();
  return (
    <Spin
      spinning={workQueueColumnsLoading || filterSettingLoading}
      tip={'Loading...'}
    >
      {children}
    </Spin>
  );
};

export default LoadingSpin;
