import React from 'react';
import cn from 'classnames';
import Empty from 'components/Empty';
import { SectionWrapper } from '../../../components/Elements';
import classes from './NotFound.module.scss';

const NotFound = ({ className }) => {
  return (
    <SectionWrapper className={cn(classes.wrapper, className)}>
      <Empty type="image1">
        <span>No records to display</span>
      </Empty>
    </SectionWrapper>
  );
};

export default NotFound;
