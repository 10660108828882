export const NORMAL_FIELDS = [
  'factoring_company_name',
  'contact_first_name',
  'contact_last_name',
  'contact_phone',
  'email',
  'address',
  'apt',
  'city',
  'county',
  'zip_code',
  'remit_account',
  'buyout_amount',
  'summary',
  'contact_other',
];
export const FILE_FIELDS = [
  'documents.aging_report',
  'documents.buyout_agreement',
];
export const NULL_FIELDS = ['is_buyout', 'contact_title_id', 'state_id'];
export const NUMBER_FIELDS = ['contact_phone', 'buyout_amount'];
export const YES_NO_FIELDS = ['is_buyout'];
export const DATE_FIELDS = [];
export const BODY_REQUEST_NORMAL_FIELDS = [
  'factoring_company_name',
  'contact_first_name',
  'contact_last_name',
  'contact_phone',
  'contact_title_id',
  'email',
  'address',
  'apt',
  'city',
  'county',
  'zip_code',
  'state_id',
  'remit_account',
  'buyout_amount',
  'summary',
  'contact_other',
];
// Fields for view mode
export const PROFILE_OBJ_KEYS = [
  {
    label: 'Is this a buyout?',
    key: 'is_buyout',
    type: 'boolean',
  },
  {
    label: 'Factoring Company Name',
    key: 'factoring_company_name',
    type: 'value',
  },
];
export const CONTACT_OBJ_KEYS = [
  {
    label: 'Name',
    key: 'contact_first_name',
    type: 'value',
    nameGroup: true,
    fields: [
      {
        label: 'First Name',
        key: 'contact_first_name',
        type: 'value',
      },
      {
        label: 'Last Name',
        key: 'contact_last_name',
        type: 'value',
      },
    ],
  },
  {
    label: 'Title',
    key: 'contact_title',
    type: 'value',
  },
  {
    label: 'Other',
    key: 'contact_other',
    type: 'value',
    dependField: 'contact_title',
    dependFn: val => val !== 'Other',
    fullWidth: true,
  },
  {
    label: 'Phone',
    key: 'contact_phone',
    type: 'formatValue',
  },
  {
    label: 'Email',
    key: 'email',
    type: 'value',
  },
];
export const ADDRESSES_OBJ_KEYS = [
  {
    label: 'Street Address',
    key: 'address',
    type: 'value',
  },
  {
    label: 'Apt.',
    key: 'apt',
    type: 'value',
    empty: true,
  },
  {
    label: 'Address',
    addressGroup: true,
    alignLeft: true,
    fields: [
      {
        label: 'City',
        key: 'city',
        type: 'value',
      },
      // {
      //   label: 'County',
      //   key: 'county',
      //   type: 'value',
      // },
      {
        label: 'State',
        key: 'state',
        type: 'value',
      },
      {
        label: 'Zip Code',
        key: 'zip_code',
        type: 'value',
      },
    ],
  },
];
export const AGREEMENT_INFORMATION_OBJ_KEYS = [
  {
    label: 'Remit to Account',
    key: 'remit_account',
    type: 'value',
  },
  {
    label: 'Aging Report',
    key: 'documents.aging_report',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Buyout Amount',
    key: 'buyout_amount',
    type: 'price',
  },
  {
    label: 'Buyout Agreement',
    key: 'documents.buyout_agreement',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Summary',
    key: 'summary',
    type: 'value',
    fullWidth: true,
  },
];
