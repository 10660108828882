import {
  apiGetChecklists,
  apiCreateUpdateChecklist,
  apiCheckUncheckChecklist,
  apiDeleteChecklist,
} from 'api';

import { checklistTypes } from '../types';

// CUSTOM
// SYNC_UPDATE_CHECKLISTS
export const syncUpdateChecklists = checklists => dispatch => {
  dispatch({
    type: checklistTypes.SYNC_UPDATE_CHECKLISTS,
    payload: checklists,
  });
};

// GET_CHECKLISTS
export const getChecklists = (filters, cb) => {
  const { emptyPrevState, silent, ...other } = filters;
  return {
    isAsyncCall: true,
    baseType: checklistTypes.GET_CHECKLISTS,
    payload: { emptyPrevState, silent },
    asyncCall: () => {
      return apiGetChecklists(other);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CREATE_UPDATE_CHECKLIST
export const createUpdateChecklist = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: checklistTypes.CREATE_UPDATE_CHECKLIST,
    payload: {},
    asyncCall: () => {
      return apiCreateUpdateChecklist(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CHECK_UNCHECK_CHECKLIST
export const checkUncheckChecklist = (id, checked, cb) => {
  return {
    isAsyncCall: true,
    baseType: checklistTypes.CHECK_UNCHECK_CHECKLIST,
    payload: {},
    asyncCall: () => {
      return apiCheckUncheckChecklist(id, checked);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// DELETE_CHECKLIST
export const deleteChecklist = (id, cb) => {
  return {
    isAsyncCall: true,
    baseType: checklistTypes.DELETE_CHECKLIST,
    payload: {},
    asyncCall: () => {
      return apiDeleteChecklist(id);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
