import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as myTeamSelectors from 'store/selectors/myTeam';
import * as teammateDetailSelectors from 'store/selectors/teammateDetail';
import * as sharedSelectors from 'store/selectors/shared';
import * as authSelectors from 'store/selectors/auth';
// ACTIONS
import * as myTeamActions from 'store/actions/myTeam';
import * as teammateDetailActions from 'store/actions/teammateDetail';
import * as authActions from 'store/actions/auth';

export const useIndexData = () => {
  return {
    currentUser: authSelectors.useSelectCurrentUser(),
    departments: sharedSelectors.useSelectDepartments(),
    inviteTeammateLoading: myTeamSelectors.useSelectInviteTeammateLoading(),
    inviteCompanyTeamMemberLoading: myTeamSelectors.useSelectInviteCompanyTeamMemberLoading(),
  };
};
export const useAllDepartmentsTabData = () => {
  return {
    allTeamsLoading: myTeamSelectors.useSelectAllTeamsLoading(),
    allTeams: myTeamSelectors.useSelectAllTeams(),
    allTeamsTotal: myTeamSelectors.useSelectAllTeamsTotal(),
    allTeamsIsMore: myTeamSelectors.useSelectAllTeamsIsMore(),
    doActionLoading: teammateDetailSelectors.useSelectDoActionLoading(),
  };
};
export const useDepartmentsTabData = () => {
  return {
    currentUser: authSelectors.useSelectCurrentUser(),
    departmentTeamLoading: myTeamSelectors.useSelectDepartmentTeamLoading(),
    departmentTeam: myTeamSelectors.useSelectDepartmentTeam(),
    departmentTeamTotal: myTeamSelectors.useSelectDepartmentTeamTotal(),
    departmentTeamIsMore: myTeamSelectors.useSelectDepartmentTeamIsMore(),
    doActionLoading: teammateDetailSelectors.useSelectDoActionLoading(),
    updateCompanyTeamMemberStatusLoading: myTeamSelectors.useSelectUpdateCompanyTeamMemberStatusLoading(),
    companyTeamList: myTeamSelectors.useSelectCompanyTeamList(),
    companyTeamListLoading: myTeamSelectors.useSelectCompanyTeamListLoading(),
    companyTeamListTotal: myTeamSelectors.useSelectCompanyTeamListTotal(),
    companyTeamListIsMore: myTeamSelectors.useSelectCompanyTeamListIsMore(),
  };
};
export const useInActiveTabData = () => {
  return {
    inActiveTeamLoading: myTeamSelectors.useSelectInActiveTeamLoading(),
    inActiveTeam: myTeamSelectors.useSelectInActiveTeam(),
    inActiveTeamTotal: myTeamSelectors.useSelectInActiveTeamTotal(),
    inActiveTeamIsMore: myTeamSelectors.useSelectInActiveTeamIsMore(),
    doActionLoading: teammateDetailSelectors.useSelectDoActionLoading(),
  };
};
export const useLoadingSpinData = () => {
  return {};
};

export const useActions = () => {
  return useStoreActions({
    ...myTeamActions,
    ...teammateDetailActions,
    ...authActions,
  });
};
