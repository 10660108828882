import Request from './request';

// GET
export const apiGetOrderRequesterList = async id => {
  return Request.call({
    url: `/company/${id}/order/requester`,
    method: 'GET',
  });
};

// GET
export const apiGetOrderDetailFields = async (id, orderTypeId) => {
  return Request.call({
    url: `/company/${id}/order/order-type-fields/${orderTypeId}`,
    method: 'GET',
  });
};

// GET
export const apiGetOrderDetailFieldSubRecords = async (
  id,
  orderTypeId,
  fieldId,
  category
) => {
  return Request.call({
    url: `/company/${id}/order/order-type-fields/${orderTypeId}/sub-records`,
    method: 'GET',
    params: {
      field_id: fieldId,
      category,
    },
  });
};

// GET
export const apiGetOrderList = async (id, params = {}) => {
  return Request.call({
    url: `/company/${id}/order`,
    method: 'GET',
    params,
  });
};

export const apiGetClientOrderDetail = async (clientId, orderId) => {
  return Request.call({
    url: `/company/${clientId}/order/${orderId}`,
    method: 'GET',
  });
};
export const apiGetClientOrder = async (clientId, orderId) => {
  return Request.call({
    url: `/company/${clientId}/order/${orderId}/details`,
    method: 'GET',
  });
};

// POST
export const apiSaveOrderAsDraft = async (id, data) => {
  return Request.call({
    url: `/company/${id}/order/save-draft`,
    method: 'POST',
    data,
  });
};

// POST
export const apiSubmitOrder = async (id, data) => {
  return Request.call({
    url: `/company/${id}/order/submit`,
    method: 'POST',
    data,
  });
};

// POST
export const apiDeleteOrder = async (id, orderId) => {
  return Request.call({
    url: `/company/${id}/order/${orderId}`,
    method: 'DELETE',
  });
};
export const apiUpdateClientOrderStatus = async (id, orderId, status) => {
  return Request.call({
    url: `/company/${id}/order/${orderId}/status`,
    method: 'POST',
    data: { status },
  });
};
