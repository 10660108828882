import React from 'react';
import cn from 'classnames';
import { Input as AntdInput } from 'antd';

import classes from './TextArea.module.scss';

const { TextArea: AntdTextArea } = AntdInput;

const TextArea = props => {
  const {
    caption,
    label,
    className,
    isBlue,
    captionAsCount,
    innerRef,
    ...other
  } = props;
  return (
    <div
      className={cn(classes.textareaWrapper, {
        [classes.captionAsCount]: captionAsCount,
      })}
    >
      {!!label && <label className="ant-textarea-label">{label}</label>}
      <AntdTextArea
        rows={4}
        {...other}
        className={cn(
          classes.wrapper,
          {
            'ant-input-is-blue': isBlue,
          },
          className
        )}
        ref={innerRef}
      />
      {!!caption && <span className="ant-textarea-caption">{caption}</span>}
    </div>
  );
};

export default TextArea;
