import React from 'react';
import cn from 'classnames';
import { getClientLogDateTime } from 'utils/date';
import { OrderStatus } from '../Elements';
import classes from './Profile.module.scss';

const Profile = ({
  className,
  data,
  onStatusChange,
  statusLoading,
  isAdmin,
}) => {
  const payment = data?.payment || {};
  const isCard = data?.payment_method === 'card';
  const internalDocument = data?.order_type?.internal_documentation || [];
  return (
    <div className={cn(classes.wrapper, className)}>
      <h1>{data?.order_number || '-'}</h1>
      <span className={classes.companyName}>{data?.company_name || '-'}</span>
      <OrderStatus
        className={classes.orderStatus}
        status={data?.status || ''}
        onStatusChange={onStatusChange}
        statusLoading={statusLoading}
        completedOn={data?.completed_on}
        viewOnly={!isAdmin}
      />
      <ul className={classes.list3Columns}>
        <li>
          <span>Order Type</span>
          <span>{data?.order_type?.name || '-'}</span>
        </li>
        <li>
          <span>Order Requested By</span>
          <span>{data?.requested_by_name || '-'}</span>
        </li>
        <li>
          <span>Order Submitted By</span>
          <span>{data?.submitted_by_name || '-'}</span>
        </li>
        <li>
          <span>Order Created On</span>
          <span>
            {data?.created_at ? getClientLogDateTime(data?.created_at) : '-'}
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Payment Method</span>
          <span className={classes.paymentDisplay}>
            <span>{isCard ? 'Credit/Debit Card' : 'ACH'}</span>
            <span>{`${isCard ? 'Visa ' : ''}****${payment.last4 || ''}`}</span>
          </span>
        </li>
        <li>
          <span>Notes</span>
          <span>{data?.notes || '-'}</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Internal Documentation</span>
          {internalDocument.length > 0 ? (
            <div className={classes.fileList}>
              {internalDocument.map((d, i) => {
                return (
                  <span
                    key={i}
                    className={classes.link}
                    onClick={() => {
                      const win = window.open(d.url, '_blank');
                      win.focus();
                    }}
                  >
                    {d.name}
                  </span>
                );
              })}
            </div>
          ) : (
            <span>-</span>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Profile;
