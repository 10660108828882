import { useReducerData, useReducerLoading } from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// ALL_TEAMS
export const useSelectTeammateDetailLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.TEAMMATE_DETAIL,
    REDUCER_ATTRIBUTES.TEAMMATE_DETAIL.DETAIL
  );
};
export const useSelectTeammateDetail = () => {
  return useReducerData(
    REDUCER_NAMES.TEAMMATE_DETAIL,
    REDUCER_ATTRIBUTES.TEAMMATE_DETAIL.DETAIL,
    {}
  );
};
// UPDATE_TEAMMATE
export const useSelectUpdateTeammateLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.TEAMMATE_DETAIL,
    REDUCER_ATTRIBUTES.TEAMMATE_DETAIL.UPDATE
  );
};
// DO_ACTION
export const useSelectDoActionLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.TEAMMATE_DETAIL,
    REDUCER_ATTRIBUTES.TEAMMATE_DETAIL.DO_ACTION
  );
};
