import get from 'lodash/get';
import { getUpdatedArrayData, getUpdatedDriverAccount } from 'utils/store';
import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  leasorTypes,
} from '../types';
import { initialLeasorState } from '../states/leasor';

export default (state = initialLeasorState, action) => {
  const {
    leasorId,
    leasorData,
    accountId,
    simpleAccountData,
    cardId,
    simpleCardData,
  } = action?.payload || {};
  switch (action.type) {
    // SYNC_UPDATE_LEASOR
    case leasorTypes.SYNC_UPDATE_LEASOR: {
      return {
        ...state,
        leasors: {
          ...state.leasors,
          data: getUpdatedArrayData(state.leasors, leasorId, leasorData),
        },
      };
    }
    // SYNC_UPDATE_LEASOR_SIMPLE_ACCOUNT
    case leasorTypes.SYNC_UPDATE_LEASOR_SIMPLE_ACCOUNT: {
      return {
        ...state,
        leasors: {
          ...state.leasors,
          data: getUpdatedArrayData(
            state.leasors,
            leasorId,
            getUpdatedDriverAccount(
              state.leasors?.data || [],
              simpleAccountData,
              leasorId,
              accountId,
              false,
              'payment_accounts'
            )
          ),
        },
      };
    }
    // SYNC_UPDATE_LEASOR_SIMPLE_FUEL_CARD
    case leasorTypes.SYNC_UPDATE_LEASOR_SIMPLE_FUEL_CARD: {
      return {
        ...state,
        leasors: {
          ...state.leasors,
          data: getUpdatedArrayData(
            state.leasors,
            leasorId,
            getUpdatedDriverAccount(
              state.leasors?.data || [],
              simpleCardData,
              leasorId,
              cardId,
              false,
              'fuel_cards'
            )
          ),
        },
      };
    }
    // GET_LEASORS
    case asyncActionTypeRequest(leasorTypes.GET_LEASORS): {
      return {
        ...state,
        leasors: {
          ...state.leasors,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(leasorTypes.GET_LEASORS): {
      return {
        ...state,
        leasors: {
          loading: false,
          data: get(action, 'response.data') || [],
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(leasorTypes.GET_LEASORS): {
      return {
        ...state,
        leasors: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    }
    // GET_LEASOR_ACCOUNT
    case asyncActionTypeRequest(leasorTypes.GET_LEASOR_ACCOUNT): {
      return {
        ...state,
        leasorAccount: {
          ...state.leasorAccount,
          loading: {
            ...state.leasorAccount?.loading,
            [accountId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(leasorTypes.GET_LEASOR_ACCOUNT): {
      return {
        ...state,
        leasorAccount: {
          loading: {
            ...state.leasorAccount?.loading,
            [accountId]: false,
          },
          data: {
            ...state.leasorAccount?.data,
            [accountId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.leasorAccount?.error,
            [accountId]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(leasorTypes.GET_LEASOR_ACCOUNT): {
      return {
        ...state,
        leasorAccount: {
          loading: {
            ...state.leasorAccount?.loading,
            [accountId]: false,
          },
          data: {
            ...state.leasorAccount?.data,
            [accountId]: {},
          },
          error: {
            ...state.leasorAccount?.error,
            [accountId]: action.error,
          },
        },
      };
    }
    // GET_LEASOR_FUEL_CARD
    case asyncActionTypeRequest(leasorTypes.GET_LEASOR_FUEL_CARD): {
      return {
        ...state,
        leasorFuelCard: {
          ...state.leasorFuelCard,
          loading: {
            ...state.leasorFuelCard?.loading,
            [cardId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(leasorTypes.GET_LEASOR_FUEL_CARD): {
      return {
        ...state,
        leasorFuelCard: {
          loading: {
            ...state.leasorFuelCard?.loading,
            [cardId]: false,
          },
          data: {
            ...state.leasorFuelCard?.data,
            [cardId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.leasorFuelCard?.error,
            [cardId]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(leasorTypes.GET_LEASOR_FUEL_CARD): {
      return {
        ...state,
        leasorFuelCard: {
          loading: {
            ...state.leasorFuelCard?.loading,
            [cardId]: false,
          },
          data: {
            ...state.leasorFuelCard?.data,
            [cardId]: {},
          },
          error: {
            ...state.leasorFuelCard?.error,
            [cardId]: action.error,
          },
        },
      };
    }
    // CREATE_LEASOR
    case asyncActionTypeRequest(leasorTypes.CREATE_LEASOR): {
      return {
        ...state,
        createLeasor: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(leasorTypes.CREATE_LEASOR): {
      return {
        ...state,
        createLeasor: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(leasorTypes.CREATE_LEASOR): {
      return {
        ...state,
        createLeasor: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // UPDATE_LEASOR
    case asyncActionTypeRequest(leasorTypes.UPDATE_LEASOR): {
      return {
        ...state,
        updateLeasor: {
          ...state.updateLeasor,
          loading: {
            ...state.updateLeasor.loading,
            [leasorId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(leasorTypes.UPDATE_LEASOR): {
      return {
        ...state,
        updateLeasor: {
          loading: {
            ...state.updateLeasor.loading,
            [leasorId]: false,
          },
          data: {
            ...state.updateLeasor.data,
            [leasorId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.updateLeasor.error,
            [leasorId]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(leasorTypes.UPDATE_LEASOR): {
      return {
        ...state,
        updateLeasor: {
          loading: {
            ...state.updateLeasor.loading,
            [leasorId]: false,
          },
          data: {
            ...state.updateLeasor.data,
            [leasorId]: {},
          },
          error: {
            ...state.updateLeasor.error,
            [leasorId]: action.error,
          },
        },
      };
    }
    // UPDATE_LEASOR_STATUS
    case asyncActionTypeRequest(leasorTypes.UPDATE_LEASOR_STATUS): {
      return {
        ...state,
        updateLeasorStatus: {
          ...state.updateLeasorStatus,
          loading: {
            ...state.updateLeasorStatus.loading,
            [leasorId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(leasorTypes.UPDATE_LEASOR_STATUS): {
      return {
        ...state,
        updateLeasorStatus: {
          loading: {
            ...state.updateLeasorStatus.loading,
            [leasorId]: false,
          },
          data: {
            ...state.updateLeasorStatus.data,
            [leasorId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.updateLeasorStatus.error,
            [leasorId]: {},
          },
        },
      };
    }
    case asyncActionTypeFailure(leasorTypes.UPDATE_LEASOR_STATUS): {
      return {
        ...state,
        updateLeasorStatus: {
          loading: {
            ...state.updateLeasorStatus.loading,
            [leasorId]: false,
          },
          data: {
            ...state.updateLeasorStatus.data,
            [leasorId]: {},
          },
          error: {
            ...state.updateLeasorStatus.error,
            [leasorId]: action.error,
          },
        },
      };
    }
    // CREATE_LEASOR_ACCOUNT
    case asyncActionTypeRequest(leasorTypes.CREATE_LEASOR_ACCOUNT): {
      return {
        ...state,
        createLeasorAccount: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(leasorTypes.CREATE_LEASOR_ACCOUNT): {
      return {
        ...state,
        createLeasorAccount: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
        leasors: {
          ...state.leasors,
          data: getUpdatedArrayData(
            state.leasors,
            leasorId,
            getUpdatedDriverAccount(
              state.leasors?.data || [],
              get(action, 'response.data') || [],
              leasorId,
              null,
              true,
              'payment_accounts'
            )
          ),
        },
      };
    }
    case asyncActionTypeFailure(leasorTypes.CREATE_LEASOR_ACCOUNT): {
      return {
        ...state,
        createLeasorAccount: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // UPDATE_LEASOR_ACCOUNT
    case asyncActionTypeRequest(leasorTypes.UPDATE_LEASOR_ACCOUNT): {
      return {
        ...state,
        updateLeasorAccount: {
          ...state.updateLeasorAccount,
          loading: {
            ...state.updateLeasorAccount.loading,
            [leasorId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(leasorTypes.UPDATE_LEASOR_ACCOUNT): {
      return {
        ...state,
        updateLeasorAccount: {
          loading: {
            ...state.updateLeasorAccount.loading,
            [leasorId]: false,
          },
          data: {
            ...state.updateLeasorAccount.data,
            [leasorId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.updateLeasorAccount.error,
            [leasorId]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(leasorTypes.UPDATE_LEASOR_ACCOUNT): {
      return {
        ...state,
        updateLeasorAccount: {
          loading: {
            ...state.updateLeasorAccount.loading,
            [leasorId]: false,
          },
          data: {
            ...state.updateLeasorAccount.data,
            [leasorId]: {},
          },
          error: {
            ...state.updateLeasorAccount.error,
            [leasorId]: action.error,
          },
        },
      };
    }
    // CREATE_LEASOR_FUEL_CARD
    case asyncActionTypeRequest(leasorTypes.CREATE_LEASOR_FUEL_CARD): {
      return {
        ...state,
        createLeasorFuelCard: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(leasorTypes.CREATE_LEASOR_FUEL_CARD): {
      return {
        ...state,
        createLeasorFuelCard: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
        leasors: {
          ...state.leasors,
          data: getUpdatedArrayData(
            state.leasors,
            leasorId,
            getUpdatedDriverAccount(
              state.leasors?.data || [],
              get(action, 'response.data') || [],
              leasorId,
              null,
              true,
              'fuel_cards'
            )
          ),
        },
      };
    }
    case asyncActionTypeFailure(leasorTypes.CREATE_LEASOR_FUEL_CARD): {
      return {
        ...state,
        createLeasorFuelCard: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // UPDATE_LEASOR_FUEL_CARD
    case asyncActionTypeRequest(leasorTypes.UPDATE_LEASOR_FUEL_CARD): {
      return {
        ...state,
        updateLeasorFuelCard: {
          ...state.updateLeasorFuelCard,
          loading: {
            ...state.updateLeasorFuelCard.loading,
            [leasorId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(leasorTypes.UPDATE_LEASOR_FUEL_CARD): {
      return {
        ...state,
        updateLeasorFuelCard: {
          loading: {
            ...state.updateLeasorFuelCard.loading,
            [leasorId]: false,
          },
          data: {
            ...state.updateLeasorFuelCard.data,
            [leasorId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.updateLeasorFuelCard.error,
            [leasorId]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(leasorTypes.UPDATE_LEASOR_FUEL_CARD): {
      return {
        ...state,
        updateLeasorFuelCard: {
          loading: {
            ...state.updateLeasorFuelCard.loading,
            [leasorId]: false,
          },
          data: {
            ...state.updateLeasorFuelCard.data,
            [leasorId]: {},
          },
          error: {
            ...state.updateLeasorFuelCard.error,
            [leasorId]: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
};
