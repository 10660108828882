import React from 'react';
import cn from 'classnames';
import find from 'lodash/find';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  Title,
  ShouldUpdateWrapper,
} from 'views/pages/ClientDetail/components/Elements';
import { useOptions } from 'helper/hooks';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { CONTACT_OBJ_KEYS } from './constants';
import classes from './Buyout.module.scss';

const Contact = ({ className, isEdit, form, data, disabled }) => {
  const { scTitleOptions } = useOptions();

  return (
    <div className={cn(classes.contactSection, className)}>
      <Title text="Contact" />
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            <InputItem
              name={'contact_first_name'}
              label="First Name"
              form={form}
              addressGroupField
              disabled={disabled}
            />
            <InputItem
              name={'contact_last_name'}
              label="Last Name"
              form={form}
              addressGroupField
              disabled={disabled}
            />
            <InputItem
              name={'contact_title_id'}
              label="Title"
              form={form}
              type="select"
              options={scTitleOptions}
            />
            <ShouldUpdateWrapper>
              {() => {
                const values = form.getFieldsValue();
                const foundOption =
                  find(scTitleOptions, {
                    value: values.contact_title_id,
                  }) || {};
                if (foundOption.label !== 'Other') {
                  return <></>;
                }
                return (
                  <>
                    <InputItem
                      name={'contact_other'}
                      label="Other"
                      form={form}
                    />
                  </>
                );
              }}
            </ShouldUpdateWrapper>
            <InputItem
              name={'contact_phone'}
              label="Phone"
              form={form}
              type="phone"
              disabled={disabled}
            />
            <InputItem
              name={'email'}
              label="Email"
              form={form}
              disabled={disabled}
            />
          </FormSection>
        ) : (
          <ListInfo objKeys={CONTACT_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default Contact;
