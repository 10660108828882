import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import { WarningOutlined } from '@ant-design/icons';
import Form, { useForm } from 'components/Form';
import toast from 'helper/toast';
import { getErrorMessageFromObj } from 'utils/object';
import { validateForm } from 'utils/form';
import { ROLES } from 'helper/const';
import Spin from 'components/Spin';
import { SectionWrapper } from 'views/pages/ClientDetail/components/Elements';
import {
  parseCheckingAccountsData,
  parseFuelCardsData,
} from 'views/pages/ClientDetail/Driver/utils';
import { getLastCharOfText } from 'utils/text';
import { getDateFromMMYY, getMomentDateStr } from 'utils/date';
import Header from './components/Header';
import CheckingAccount from './components/Section/CheckingAccount';
import FuelCard from './components/Section/FuelCard';
import { getInitialValues, getValidateArray } from './utils';
import { updateAccountCardIds } from 'views/pages/ClientDetail/Driver/components/Section/utils';
import getPermission from './permissions';
import { useIndexData, useActions } from './selectorData';
import classes from './PaymentSource.module.scss';
import { actionCb } from 'utils/action';

const PaymentSourceTab = ({
  className,
  clientId,
  paymentSourceFormChange,
  confirmModal,
}) => {
  const hideCheckingAccount = true;
  const {
    getCheckingAccountLoading,
    getFuelCardLoading,
    validatePasswordLoading,
    accounts,
    accountsLoading,
    fuelCards,
    fuelCardsLoading,
    currentUser,
  } = useIndexData();
  const {
    getAccounts,
    getAccount,
    getFuelCards,
    updateAccount,
    createAccount,
    createFuelCard,
    validatePassword,
    getFuelCard,
    updateFuelCard,
    syncUpdateFuelCard,
    deleteFuelCard,
  } = useActions();
  const currentRole = currentUser.role || '';
  const isAdmin =
    currentRole === ROLES.ADMIN || currentRole === ROLES.SUPER_ADMIN;
  const permission = getPermission(currentRole);
  const [deleteLoading, setDeleteLoading] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);
  const [form] = useForm();
  const wrapperId = `payment_source_${clientId}`;
  const data = getInitialValues({
    accounts: accounts,
    cards: fuelCards,
  });
  const handleSaveCheckingAccount = (index, cb) => () => {
    const values = form.getFieldsValue();
    const fieldArrayErrors = validateForm(
      getValidateArray(values),
      values,
      true
    );
    if (fieldArrayErrors.length > 0) {
      form.setFields(fieldArrayErrors);
    } else {
      const accounts = values.accounts;
      if (!accounts[index]) {
        if (cb) cb();
        toast.error('Something went wrong');
        return;
      }
      let currentAccount = accounts[index];
      currentAccount.is_primary = currentAccount.is_default_account ? 1 : 0;
      currentAccount.ach = currentAccount.routing_number_ach;
      currentAccount.wire = currentAccount.routing_number_wire;
      createAccount(clientId, { accounts: [currentAccount] }, (res, err) => {
        if (res) {
          getAccounts(clientId, {}, response => {
            updateAccountCardIds(form, response.data, []);
            if (cb) cb(response);
          });
        } else {
          toast.error(getErrorMessageFromObj(err)[0]);
        }
      });
    }
  };
  const onEditCheckingAccount = (accountId, accountBR, cb, errCb) => {
    updateAccount(clientId, accountId, accountBR, (res, err) => {
      if (res) {
        getAccounts(clientId, {}, response => {
          updateAccountCardIds(form, response.data, []);
          if (cb) cb(response);
        });
      } else {
        if (errCb) errCb();
        toast.error(getErrorMessageFromObj(err)[0]);
      }
    });
  };
  const handleSaveFuelCard = (index, cb) => () => {
    const values = form.getFieldsValue();
    const fieldArrayErrors = validateForm(
      getValidateArray(values),
      values,
      true
    );
    if (fieldArrayErrors.length > 0) {
      form.setFields(fieldArrayErrors);
    } else {
      const cards = values.cards;
      if (!cards[index]) {
        if (cb) cb();
        toast.error('Something went wrong');
        return;
      }
      let currentFuelCard = cards[index];
      currentFuelCard.is_primary = currentFuelCard.is_primary ? 1 : 0;
      currentFuelCard.expiration_date = getMomentDateStr(
        getDateFromMMYY(currentFuelCard.expiration_date, '/')
      );
      setSaveLoading(true);
      createFuelCard(
        clientId,
        { payment_cards: [currentFuelCard] },
        (res, err) => {
          if (res) {
            getFuelCards(clientId, {}, response => {
              updateAccountCardIds(form, [], response.data);
              const newCardId = get(response, 'data[0].id');
              if (newCardId) {
                syncUpdateFuelCard(newCardId, {
                  last4: getLastCharOfText(currentFuelCard.card_number, 4),
                });
              }
              if (cb) cb(response);
              setSaveLoading(false);
            });
          } else {
            setSaveLoading(false);
            toast.error(getErrorMessageFromObj(err)[0]);
          }
        }
      );
    }
  };
  const onValidatePassword = type => (id, caCb) => (password, cb) => {
    if (type === 'fuel_cards') {
      getFuelCard(clientId, id, { pin: password }, (res, err) => {
        if (res) {
          if (caCb) caCb(res);
          if (cb) cb();
        } else {
          toast.error(getErrorMessageFromObj(err)[0]);
        }
      });
    } else {
      validatePassword({ password }, (res, err) => {
        if (res) {
          getAccount(clientId, id, (res1, err1) => {
            if (res1) {
              if (caCb) caCb(res1);
              if (cb) cb();
            } else {
              toast.error(getErrorMessageFromObj(err1)[0]);
            }
          });
        } else {
          toast.error(getErrorMessageFromObj(err)[0]);
        }
      });
    }
  };
  const handleConfirmEditCard = (tCardId, tCardBR, cb, errCb) => {
    updateFuelCard(clientId, tCardId, tCardBR, (res, err) => {
      if (res) {
        syncUpdateFuelCard(tCardId, {
          last4: getLastCharOfText(tCardBR.card_number, 4),
        });
        if (cb) cb(res);
      } else {
        if (errCb) errCb();
        toast.error(getErrorMessageFromObj(err)[0]);
      }
    });
  };

  useEffect(() => {
    paymentSourceFormChange(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <div className={cn(classes.paymentSourceTab, className)}>
      <Header className={classes.header} />
      <SectionWrapper className={cn(classes.wrapper, className)}>
        {fuelCardsLoading && (
          <Spin spinning icon className={classes.mainLoading} />
        )}
        <Form
          validateMessages={''}
          onFinish={() => {}}
          initialValues={data}
          form={form}
          onValuesChange={() => {}}
        >
          <FuelCard
            isEdit={true}
            form={form}
            cards={parseFuelCardsData({ fuel_cards: data.cards })}
            onSave={handleSaveFuelCard}
            onValidatePassword={onValidatePassword('fuel_cards')}
            validatePasswordLoading={validatePasswordLoading}
            getFuelCardLoading={getFuelCardLoading}
            wrapperId={wrapperId}
            fuelCardsLoading={fuelCardsLoading}
            onConfirmEditCard={handleConfirmEditCard}
            saveLoading={saveLoading}
            hideActions={!permission?.funelCardAction}
            deleteLoading={deleteLoading}
            onDeleteCard={
              !isAdmin
                ? undefined
                : tCard => {
                    confirmModal(
                      'Permanently Delete Client Card?',
                      <div>
                        Are you sure you want to permanently delete this client
                        card?
                      </div>,
                      () => {
                        const tValues = form.getFieldsValue();
                        setDeleteLoading({
                          ...deleteLoading,
                          [tCard.id]: true,
                        });
                        deleteFuelCard(
                          clientId,
                          tCard.id,
                          actionCb(
                            () => {
                              form.setFieldsValue({
                                cards: (tValues.cards || []).filter(
                                  obj => obj.originId !== tCard.id
                                ),
                              });
                              setDeleteLoading({
                                ...deleteLoading,
                                [tCard.id]: false,
                              });
                              getFuelCards(clientId);
                            },
                            () => {
                              setDeleteLoading({
                                ...deleteLoading,
                                [tCard.id]: false,
                              });
                            }
                          )
                        );
                      },
                      {
                        type: 'confirm',
                        onCancel: () => {},
                        okText: 'Yes, Delete',
                        cancelText: 'No, Nevermind',
                        centered: true,
                        className: classes.deleteWarningModal,
                        icon: <WarningOutlined />,
                      }
                    );
                  }
            }
          />
          {!hideCheckingAccount && (
            <CheckingAccount
              isEdit={true}
              form={form}
              accounts={parseCheckingAccountsData({
                payment_accounts: data.accounts,
              })}
              onEdit={onEditCheckingAccount}
              onSave={handleSaveCheckingAccount}
              onValidatePassword={onValidatePassword('accounts')}
              validatePasswordLoading={validatePasswordLoading}
              getDriverAccountLoading={getCheckingAccountLoading}
              wrapperId={wrapperId}
              accountsLoading={accountsLoading}
            />
          )}
        </Form>
      </SectionWrapper>
    </div>
  );
};

export default PaymentSourceTab;
