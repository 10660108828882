import React from 'react';
import cn from 'classnames';
import { CheckCircleFilled, EditFilled } from '@ant-design/icons';
import Button, { ButtonColors } from 'components/Button';

import classes from './OtherSection.module.scss';

const Actions = ({
  isEdit,
  setIsEdit,
  onEdit,
  className,
  onSubmit,
  onDelete,
  loading,
  deleteLoading,
}) => {
  return (
    <div className={cn(classes.actionButtons, className)}>
      {isEdit ? (
        <>
          <Button
            type={'ghost'}
            htmlType="button"
            onClick={onDelete}
            color={ButtonColors.DEFAULT}
            loading={deleteLoading}
          >
            Delete
          </Button>
          <Button
            type={'primary'}
            htmlType="button"
            color={ButtonColors.BLUE}
            loading={loading}
            onClick={() => {
              if (!isEdit) {
                onEdit();
              } else {
                onSubmit();
              }
            }}
            leftIcon={isEdit ? <CheckCircleFilled /> : <EditFilled />}
          >
            {isEdit ? 'Save' : 'Edit'}
          </Button>
        </>
      ) : (
        <Button
          type={'ghost'}
          htmlType="button"
          onClick={() => {
            setIsEdit(true);
          }}
          color={ButtonColors.DEFAULT}
          loading={deleteLoading}
        >
          Edit
        </Button>
      )}
    </div>
  );
};

export default Actions;
