import createTypes from 'redux-create-action-types';

export default createTypes(
  'SYNC_UPDATE_CLIENT_ORDER_CHECKLIST',
  'GET_CLIENT_ORDER_CHECKLIST',
  'GET_CLIENT_ORDER_FIELD_VALUES',
  'SAVE_CLIENT_ORDER_CHECKLIST',
  'MARK_CLIENT_ORDER_CHECKLIST',
  'DELETE_CLIENT_ORDER_CHECKLIST',
  'ADD_CLIENT_ORDER_NOTE'
);
