import React from 'react';
import PageHeader from 'components/PageHeader';
import TopBarContent from 'layouts/Main/TopBarContent';
import { useHeaderData } from './selectorData';
import ROUTES from 'routes';

const NewOrderTypeHeader = ({ isDetail, isEditing }) => {
  const { orderTypeDetail } = useHeaderData();
  const orderTypeName = orderTypeDetail.name || '';
  const breadcrumbs = [
    {
      label: 'Order Types',
      to: ROUTES.DASHBOARD(),
      color: 'blue',
    },
    {
      label: isEditing
        ? 'Edit Order Type'
        : isDetail
        ? orderTypeName
        : 'Create New Order Type',
    },
  ];
  return (
    <TopBarContent>
      <PageHeader title={null} breadcrumbs={breadcrumbs} />
    </TopBarContent>
  );
};

export default NewOrderTypeHeader;
