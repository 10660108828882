import React from 'react';
import cx from 'classnames';
import { Empty as AntdEmpty } from 'antd';
import EmptyImage1 from 'assets/icons/empty/image1.svg';
import EmptyImage2 from 'assets/icons/empty/image2.svg';
import EmptyImage3 from 'assets/icons/empty/image3.svg';
import EmptyImage4 from 'assets/icons/empty/image4.svg';
import EmptyImage5 from 'assets/icons/empty/image5.svg';

import classes from './Empty.module.scss';

const typeImageMapping = {
  image1: EmptyImage1,
  image2: EmptyImage2,
  image3: EmptyImage3,
  image4: EmptyImage4,
  image5: EmptyImage5,
};
export const EMPTY_SIZE = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
};

const Empty = props => {
  const {
    children,
    className,
    type = 'image1',
    image,
    description,
    size = EMPTY_SIZE.MEDIUM,
    ...other
  } = props;
  return (
    <AntdEmpty
      {...other}
      className={cx(
        classes.wrapper,
        {
          [classes.large]: size === EMPTY_SIZE.LARGE,
          [classes.medium]: size === EMPTY_SIZE.MEDIUM,
          [classes.small]: size === EMPTY_SIZE.SMALL,
        },
        className
      )}
      image={image ? image : typeImageMapping[type]}
      description={description || ''}
    >
      {children || ''}
    </AntdEmpty>
  );
};

export default Empty;
