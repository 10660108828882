import get from 'lodash/get';
import {
  getUpdateClientDetailData,
  getUpdateCDWithTotal,
  getUpdatedArrayData,
  getUpdatedCompanyOfficialsWithPC,
} from 'utils/store';
import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  clientDetailTypes,
} from '../types';
import { initialClientDetailState } from '../states/clientDetail';

export default (state = initialClientDetailState, action) => {
  const {
    key: sectionKey,
    totalKey,
    total,
    data: sectionData,
    officialId,
    coData,
    type,
  } = action?.payload || {};
  const resData = get(action, 'response.data') || {};
  const { next_page_url, current_page, data = [] } = resData;
  const resTotal = resData?.total;
  const isMore = get(action, 'payload.isMore');
  const isBEMore = !!next_page_url || current_page > 1;
  switch (action.type) {
    // SYNC_UPDATE_CLIENT_DETAIL_SECTION
    case clientDetailTypes.SYNC_UPDATE_CLIENT_DETAIL_SECTION: {
      return {
        ...state,
        clientDetail: {
          ...state.clientDetail,
          data: getUpdateClientDetailData(state, sectionKey, sectionData, type),
        },
      };
    }
    // SYNC_SET_SECTION_TOTAL
    case clientDetailTypes.SYNC_SET_SECTION_TOTAL: {
      return {
        ...state,
        clientDetail: {
          ...state.clientDetail,
          data: getUpdateCDWithTotal(state, total, sectionKey, totalKey),
        },
      };
    }
    // SYNC_UPDATE_COMPANY_OFFICIAL
    case clientDetailTypes.SYNC_UPDATE_COMPANY_OFFICIAL: {
      return {
        ...state,
        companyOfficials: {
          ...state.companyOfficials,
          data: getUpdatedArrayData(state.companyOfficials, officialId, coData),
        },
      };
    }
    // SYNC_SET_CO_PRIMARY_CONTACT
    case clientDetailTypes.SYNC_SET_CO_PRIMARY_CONTACT: {
      return {
        ...state,
        companyOfficials: {
          ...state.companyOfficials,
          data: getUpdatedCompanyOfficialsWithPC(
            state.companyOfficials,
            officialId,
            coData
          ),
        },
      };
    }
    // GET_COMPANY_USERS
    case asyncActionTypeRequest(clientDetailTypes.GET_COMPANY_USERS): {
      return {
        ...state,
        companyUsers: {
          ...state.companyUsers,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(clientDetailTypes.GET_COMPANY_USERS): {
      return {
        ...state,
        companyUsers: {
          data: isMore ? [...state.companyUsers.data, ...data] : data,
          totalNo: resTotal || 0,
          loading: false,
          isMore: isBEMore,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(clientDetailTypes.GET_COMPANY_USERS): {
      return {
        ...state,
        companyUsers: {
          loading: false,
          error: action.payload,
          data: [],
          totalNo: 0,
          isMore: false,
        },
      };
    }
    // GET_CLIENT_DETAIL
    case asyncActionTypeRequest(clientDetailTypes.GET_CLIENT_DETAIL): {
      return {
        ...state,
        clientDetail: {
          ...state.clientDetail,
          loading: action?.payload?.silent ? false : true,
        },
      };
    }
    case asyncActionTypeSuccess(clientDetailTypes.GET_CLIENT_DETAIL): {
      return {
        ...state,
        clientDetail: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(clientDetailTypes.GET_CLIENT_DETAIL): {
      return {
        ...state,
        clientDetail: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // GET_CLIENT_DETAIL_LOG
    case asyncActionTypeRequest(clientDetailTypes.GET_CLIENT_DETAIL_LOG): {
      return {
        ...state,
        clientDetailLog: {
          ...state.clientDetailLog,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(clientDetailTypes.GET_CLIENT_DETAIL_LOG): {
      return {
        ...state,
        clientDetailLog: {
          loading: false,
          data: get(action, 'response.data') || [],
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(clientDetailTypes.GET_CLIENT_DETAIL_LOG): {
      return {
        ...state,
        clientDetailLog: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    }
    // GET_COMPANY_OFFICIALS
    case asyncActionTypeRequest(clientDetailTypes.GET_COMPANY_OFFICIALS): {
      return {
        ...state,
        companyOfficials: {
          ...state.companyOfficials,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(clientDetailTypes.GET_COMPANY_OFFICIALS): {
      return {
        ...state,
        companyOfficials: {
          loading: false,
          data: get(action, 'response.data') || [],
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(clientDetailTypes.GET_COMPANY_OFFICIALS): {
      return {
        ...state,
        companyOfficials: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    }
    // UPDATE_SALES_INFO
    case asyncActionTypeRequest(clientDetailTypes.UPDATE_SALES_INFO): {
      return {
        ...state,
        updateSalesInfo: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(clientDetailTypes.UPDATE_SALES_INFO): {
      return {
        ...state,
        updateSalesInfo: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(clientDetailTypes.UPDATE_SALES_INFO): {
      return {
        ...state,
        updateSalesInfo: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // UPDATE_COMPANY
    case asyncActionTypeRequest(clientDetailTypes.UPDATE_COMPANY): {
      return {
        ...state,
        updateCompany: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(clientDetailTypes.UPDATE_COMPANY): {
      return {
        ...state,
        updateCompany: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(clientDetailTypes.UPDATE_COMPANY): {
      return {
        ...state,
        updateCompany: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // UPDATE_FILLING
    case asyncActionTypeRequest(clientDetailTypes.UPDATE_FILLING): {
      return {
        ...state,
        updateFilling: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(clientDetailTypes.UPDATE_FILLING): {
      return {
        ...state,
        updateFilling: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(clientDetailTypes.UPDATE_FILLING): {
      return {
        ...state,
        updateFilling: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // UPDATE_INSURANCE
    case asyncActionTypeRequest(clientDetailTypes.UPDATE_INSURANCE): {
      return {
        ...state,
        updateInsurance: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(clientDetailTypes.UPDATE_INSURANCE): {
      return {
        ...state,
        updateInsurance: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(clientDetailTypes.UPDATE_INSURANCE): {
      return {
        ...state,
        updateInsurance: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // MANAGE_LOGO
    case asyncActionTypeRequest(clientDetailTypes.MANAGE_LOGO): {
      return {
        ...state,
        manageLogo: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(clientDetailTypes.MANAGE_LOGO): {
      return {
        ...state,
        manageLogo: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(clientDetailTypes.MANAGE_LOGO): {
      return {
        ...state,
        manageLogo: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // ADD_LOG
    case asyncActionTypeRequest(clientDetailTypes.ADD_LOG): {
      return {
        ...state,
        addLog: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(clientDetailTypes.ADD_LOG): {
      return {
        ...state,
        addLog: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(clientDetailTypes.ADD_LOG): {
      return {
        ...state,
        addLog: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // CREATE_COMPANY_OFFICIAL
    case asyncActionTypeRequest(clientDetailTypes.CREATE_COMPANY_OFFICIAL): {
      return {
        ...state,
        createCompanyOfficial: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(clientDetailTypes.CREATE_COMPANY_OFFICIAL): {
      return {
        ...state,
        createCompanyOfficial: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(clientDetailTypes.CREATE_COMPANY_OFFICIAL): {
      return {
        ...state,
        createCompanyOfficial: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // UPDATE_COMPANY_OFFICIAL
    case asyncActionTypeRequest(clientDetailTypes.UPDATE_COMPANY_OFFICIAL): {
      return {
        ...state,
        updateCompanyOfficial: {
          ...state.updateCompanyOfficial,
          loading: {
            ...state.updateCompanyOfficial.loading,
            [officialId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(clientDetailTypes.UPDATE_COMPANY_OFFICIAL): {
      return {
        ...state,
        updateCompanyOfficial: {
          loading: {
            ...state.updateCompanyOfficial.loading,
            [officialId]: false,
          },
          data: {
            ...state.updateCompanyOfficial.data,
            [officialId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.updateCompanyOfficial.error,
            [officialId]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(clientDetailTypes.UPDATE_COMPANY_OFFICIAL): {
      return {
        ...state,
        updateCompanyOfficial: {
          loading: {
            ...state.updateCompanyOfficial.loading,
            [officialId]: false,
          },
          data: {
            ...state.updateCompanyOfficial.data,
            [officialId]: {},
          },
          error: {
            ...state.updateCompanyOfficial.error,
            [officialId]: action.error,
          },
        },
      };
    }
    // UPDATE_COMPANY_OFFICIAL_STATUS
    case asyncActionTypeRequest(
      clientDetailTypes.UPDATE_COMPANY_OFFICIAL_STATUS
    ): {
      return {
        ...state,
        updateCompanyOfficialStatus: {
          ...state.updateCompanyOfficialStatus,
          loading: {
            ...state.updateCompanyOfficialStatus.loading,
            [officialId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(
      clientDetailTypes.UPDATE_COMPANY_OFFICIAL_STATUS
    ): {
      return {
        ...state,
        updateCompanyOfficialStatus: {
          loading: {
            ...state.updateCompanyOfficialStatus.loading,
            [officialId]: false,
          },
          data: {
            ...state.updateCompanyOfficialStatus.data,
            [officialId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.updateCompanyOfficialStatus.error,
            [officialId]: {},
          },
        },
      };
    }
    case asyncActionTypeFailure(
      clientDetailTypes.UPDATE_COMPANY_OFFICIAL_STATUS
    ): {
      return {
        ...state,
        updateCompanyOfficialStatus: {
          loading: {
            ...state.updateCompanyOfficialStatus.loading,
            [officialId]: false,
          },
          data: {
            ...state.updateCompanyOfficialStatus.data,
            [officialId]: {},
          },
          error: {
            ...state.updateCompanyOfficialStatus.error,
            [officialId]: action.error,
          },
        },
      };
    }
    // SEARCH_SAFER
    case asyncActionTypeRequest(clientDetailTypes.SEARCH_SAFER): {
      return {
        ...state,
        searchSafer: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(clientDetailTypes.SEARCH_SAFER): {
      return {
        ...state,
        searchSafer: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(clientDetailTypes.SEARCH_SAFER): {
      return {
        ...state,
        searchSafer: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // DOT_SEARCH_SAFER
    case asyncActionTypeRequest(clientDetailTypes.DOT_SEARCH_SAFER): {
      return {
        ...state,
        dotSearchSafer: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(clientDetailTypes.DOT_SEARCH_SAFER): {
      return {
        ...state,
        dotSearchSafer: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(clientDetailTypes.DOT_SEARCH_SAFER): {
      return {
        ...state,
        dotSearchSafer: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // SAVE_DOT
    case asyncActionTypeRequest(clientDetailTypes.SAVE_DOT): {
      return {
        ...state,
        saveDOT: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(clientDetailTypes.SAVE_DOT): {
      return {
        ...state,
        saveDOT: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(clientDetailTypes.SAVE_DOT): {
      return {
        ...state,
        saveDOT: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // GET_DOT
    case asyncActionTypeRequest(clientDetailTypes.GET_DOT): {
      return {
        ...state,
        DOTClassifications: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(clientDetailTypes.GET_DOT): {
      return {
        ...state,
        DOTClassifications: {
          loading: false,
          data: get(action, 'response.data.section') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(clientDetailTypes.GET_DOT): {
      return {
        ...state,
        DOTClassifications: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    default:
      return state;
  }
};
