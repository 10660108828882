import React from 'react';
import cn from 'classnames';
import { Transfer as AntTransfer, Tree } from 'antd';

import classes from './Transfer.module.scss';

const Transfer = ({ className, ...other }) => {
  return <AntTransfer {...other} className={cn(classes.wrapper, className)} />;
};

export { Tree };
export default Transfer;
