import {
  apiCreateUpdateLeasor,
  apiGetLeasors,
  apiUpdateLeasorStatus,
  apiGetLeasorAccount,
  apiCreateLeasorAccount,
  apiUpdateLeasorAccount,
  apiGetLeasorFuelCard,
  apiCreateLeasorFuelCard,
  apiUpdateLeasorFuelCard,
} from 'api';
import { getLeasorsList } from '../actions/shared';
import { leasorTypes } from '../types';

// SYNC_UPDATE_LEASOR
export const syncUpdateLeasor = (leasorId, leasorData) => dispatch => {
  dispatch({
    type: leasorTypes.SYNC_UPDATE_LEASOR,
    payload: { leasorId, leasorData },
  });
};
// SYNC_UPDATE_LEASOR_SIMPLE_ACCOUNT
export const syncUpdateLeasorSimpleAccount = (
  leasorId,
  accountId,
  simpleAccountData
) => dispatch => {
  dispatch({
    type: leasorTypes.SYNC_UPDATE_LEASOR_SIMPLE_ACCOUNT,
    payload: { leasorId, accountId, simpleAccountData },
  });
};
// SYNC_UPDATE_LEASOR_SIMPLE_FUEL_CARD
export const syncUpdateLeasorSimpleCard = (
  leasorId,
  cardId,
  simpleCardData
) => dispatch => {
  dispatch({
    type: leasorTypes.SYNC_UPDATE_LEASOR_SIMPLE_FUEL_CARD,
    payload: { leasorId, cardId, simpleCardData },
  });
};

// GET_LEASORS
export const getLeasors = ({ id }, filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: leasorTypes.GET_LEASORS,
    payload: {},
    asyncCall: () => {
      return apiGetLeasors(id, filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_LEASOR_ACCOUNT
export const getLeasorAccount = (clientId, leasorId, accountId, cb) => {
  return {
    isAsyncCall: true,
    baseType: leasorTypes.GET_LEASOR_ACCOUNT,
    payload: { leasorId, accountId },
    asyncCall: () => {
      return apiGetLeasorAccount(clientId, leasorId, accountId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_LEASOR_FUEL_CARD
export const getLeasorFuelCard = (clientId, leasorId, cardId, cb) => {
  return {
    isAsyncCall: true,
    baseType: leasorTypes.GET_LEASOR_FUEL_CARD,
    payload: { leasorId, cardId },
    asyncCall: () => {
      return apiGetLeasorFuelCard(clientId, leasorId, cardId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};

// CREATE_LEASOR
export const createLeasor = (clientId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: leasorTypes.CREATE_LEASOR,
    payload: {},
    asyncCall: () => {
      return apiCreateUpdateLeasor(clientId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(getLeasorsList(clientId));
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_LEASOR
export const updateLeasor = (clientId, data, cb, leasorId) => {
  return {
    isAsyncCall: true,
    baseType: leasorTypes.UPDATE_LEASOR,
    payload: { leasorId },
    asyncCall: () => {
      return apiCreateUpdateLeasor(clientId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(getLeasorsList(clientId));
      dispatch(syncUpdateLeasor(leasorId, response?.data || {}));
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_LEASOR_STATUS
export const updateLeasorStatus = (clientId, leasorId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: leasorTypes.UPDATE_LEASOR_STATUS,
    payload: { leasorId },
    asyncCall: () => {
      return apiUpdateLeasorStatus(clientId, leasorId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(getLeasorsList(clientId));
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CREATE_LEASOR_ACCOUNT
export const createLeasorAccount = (clientId, leasorId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: leasorTypes.CREATE_LEASOR_ACCOUNT,
    payload: { leasorId },
    asyncCall: () => {
      return apiCreateLeasorAccount(clientId, leasorId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_LEASOR_ACCOUNT
export const updateLeasorAccount = (
  clientId,
  leasorId,
  accountId,
  data,
  cb
) => {
  return {
    isAsyncCall: true,
    baseType: leasorTypes.UPDATE_LEASOR_ACCOUNT,
    payload: { leasorId },
    asyncCall: () => {
      return apiUpdateLeasorAccount(clientId, leasorId, accountId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(
        syncUpdateLeasorSimpleAccount(leasorId, accountId, response.data)
      );
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CREATE_LEASOR_FUEL_CARD
export const createLeasorFuelCard = (clientId, leasorId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: leasorTypes.CREATE_LEASOR_FUEL_CARD,
    payload: { leasorId },
    asyncCall: () => {
      return apiCreateLeasorFuelCard(clientId, leasorId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_LEASOR_FUEL_CARD
export const updateLeasorFuelCard = (clientId, leasorId, cardId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: leasorTypes.UPDATE_LEASOR_FUEL_CARD,
    payload: { leasorId },
    asyncCall: () => {
      return apiUpdateLeasorFuelCard(clientId, leasorId, cardId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(syncUpdateLeasorSimpleCard(leasorId, cardId, response.data));
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
