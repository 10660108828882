import React from 'react';
import cn from 'classnames';
import { SyncOutlined } from '@ant-design/icons';
import { STATUS_MAPPING } from 'helper/const';
import Tag from 'components/Tag';
import classes from './StatusTag.module.scss';

const StatusTag = ({
  status,
  loading,
  className,
  statusMapping,
  size = 'large',
  onClick,
}) => {
  const statusObj = (statusMapping || STATUS_MAPPING)[status] || {
    label: status,
  };
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.loading]: loading,
          [classes.clickable]: !!onClick,
        },
        className
      )}
      onClick={onClick}
    >
      <Tag
        borderSameColor
        color={statusObj.color}
        size={size}
        icon={loading ? <SyncOutlined spin /> : null}
      >
        {!!statusObj.icon && !loading && statusObj.icon}
        {statusObj.label}
      </Tag>
    </div>
  );
};

export default StatusTag;
