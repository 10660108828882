import React from 'react';
import { UpOutlined, CloseCircleFilled } from '@ant-design/icons';
import { Tree as TreeCom } from 'components/Transfer';
import { RadioGroup } from 'components/FormFields';

import { AvailableFieldLine, Required } from '../../Elements';
import { getRadioInputValue } from '../../utils';
import { ALL_NODE_KEY, REQUIRED, OPTIONAL } from '../../constants';

import classes from './TreeTransfer.module.scss';

const Tree = ({
  isLeftSide,
  checkedKeys,
  treeData,
  onAction,
  onSelectTree,
  onTreeDataChange,
  direction,
  hideCloseRightSide,
  renderCustomContents,
  treeClassName,
  expandKeys = [],
  setExpandKeys = () => {},
}) => {
  const expandProps = {};
  if (isLeftSide) {
    expandProps.expandedKeys = [...expandKeys];
    expandProps.onExpand = keys => {
      setExpandKeys(keys);
    };
  }
  return (
    <TreeCom
      {...expandProps}
      className={treeClassName}
      blockNode
      checkable={isLeftSide}
      checkStrictly
      filterTreeNode={node => {
        return (!node.parent || node.hasChild) && node.key !== ALL_NODE_KEY;
      }}
      checkedKeys={checkedKeys}
      treeData={treeData}
      onCheck={(_, nodeData) => {
        if (isLeftSide) {
          const { checked, checkedNodes } = nodeData;
          const isDeSelectAll = !checked && nodeData.node.key === ALL_NODE_KEY;
          const isLeft = checked || isDeSelectAll;
          onAction(
            nodeData,
            isLeft,
            isLeft ? treeData : [...checkedNodes, nodeData.node],
            isDeSelectAll
          );
        }
      }}
      onSelect={(_, nodeData) => {
        const input = getRadioInputValue(nodeData);
        if (input) {
          onSelectTree(nodeData.node, input.value, () => {
            onTreeDataChange(
              nodeData.node,
              input.value,
              nodeData.node.key,
              nodeData.node.children
            );
          });
        }
      }}
      switcherIcon={<UpOutlined />}
      titleRender={item => {
        return (
          <AvailableFieldLine
            isChild={item.isChild}
            childLevel={item.childLevel}
            hasChild={item.hasChild}
            contents={[
              !isLeftSide ? (
                <>
                  {!hideCloseRightSide && (
                    <>
                      <CloseCircleFilled
                        onClick={() => {
                          onAction({ node: item }, false, treeData);
                        }}
                        className={classes.nodeCloseIcon}
                      />{' '}
                    </>
                  )}
                  {item.title}
                  {item.add === REQUIRED && !!item.parent && (
                    <Required>*</Required>
                  )}
                </>
              ) : (
                <span
                  onClick={() => {
                    const { checked = [], halfChecked = [] } =
                      checkedKeys || {};
                    const isChecked =
                      checked.includes(item.key) &&
                      !halfChecked.includes(item.key);
                    const isDeSelectAll =
                      isChecked && item.key === ALL_NODE_KEY;
                    const isLeft = !isChecked || isDeSelectAll;
                    onAction(
                      { node: item },
                      isLeft,
                      isLeft ? treeData : item.children,
                      isDeSelectAll
                    );
                  }}
                >
                  {item.title}
                </span>
              ),
              <RadioGroup
                key={item.key}
                value={item.add}
                items={[{ value: REQUIRED }, { value: OPTIONAL }]}
              />,
            ]}
            direction={direction}
            customContents={
              renderCustomContents ? renderCustomContents(item) : []
            }
          />
        );
      }}
    />
  );
};

export default Tree;
