export const NEW_KEY = 'new_leasor';
export const NORMAL_FIELDS = [
  'legal_name',
  'address',
  'apt',
  'city',
  'county',
  'zip_code',
  'mailing_address',
  'mailing_apt',
  'mailing_city',
  'mailing_zip_code',
  'mailing_county',
  'leasor_due',
  'dot_number',
  'mc_number',
  'phone',
  'ein',
];
export const NULL_FIELDS = ['state_id', 'mailing_state_id', 'title_id'];
export const NUMBER_FIELDS = ['leasor_due', 'dot_number', 'mc_number', 'phone'];
export const ARR_FIELDS = [];
export const DATE_FIELDS = [];
export const IS_SAME_ADDRESS_FIELD = 'is_same_as_physical';
export const FILE_FIELDS = ['documents.lease_agreement'];
export const BOOLEAN_FIELDS = [IS_SAME_ADDRESS_FIELD];
export const YES_NO_FIELDS = [];
export const MAILING_ADDRESS_FIELDS = [
  'mailing_address',
  'mailing_apt',
  'mailing_city',
  'mailing_county',
  'mailing_zip_code',
  'mailing_state_id',
];
export const CONTACT_FIELDS = [
  'first_name',
  'last_name',
  'title_id',
  'phone',
  'email',
  'other',
];
export const BODY_REQUEST_NORMAL_FIELDS = [
  'legal_name',
  'address',
  'apt',
  'city',
  'county',
  'zip_code',
  'mailing_address',
  'mailing_apt',
  'mailing_city',
  'mailing_zip_code',
  'mailing_county',
  'state_id',
  'mailing_state_id',
  'ein',
];
export const DEFAULT_SECTIONS = [];

export const PROFILE_OBJ_KEYS = [
  {
    label: 'Company Legal Name',
    key: 'legal_name',
    type: 'value',
  },
  {
    label: 'Due to Leasor (%)',
    key: 'leasor_due',
    type: 'value',
    suffix: '%',
  },
  {
    label: 'EIN - Employer Identification Number',
    key: 'ein',
    type: 'value',
  },
  {
    label: 'Lease Agreement',
    key: 'documents.lease_agreement',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'DOT Number',
    key: 'dot_number',
    type: 'value',
  },
  {
    label: 'MC Number',
    key: 'mc_number',
    type: 'value',
  },
];
export const ADDRESSES_OBJ_KEYS = [
  {
    label: 'Physical Address',
    key: 'address',
    type: 'value',
  },
  {
    label: 'Apt.',
    key: 'apt',
    type: 'value',
    empty: true,
  },
  {
    label: 'Address',
    addressGroup: true,
    alignLeft: true,
    fields: [
      {
        label: 'City',
        key: 'city',
        type: 'value',
      },
      // {
      //   label: 'County',
      //   key: 'county',
      //   type: 'value',
      // },
      {
        label: 'State',
        key: 'state',
        type: 'value',
      },
      {
        label: 'Zip Code',
        key: 'zip_code',
        type: 'value',
      },
    ],
  },
  {
    label: 'Mailing address same as physical address',
    key: 'is_same_as_physical',
    type: 'checkbox',
    fullWidth: true,
    dependField: 'is_same_as_physical',
    dependFn: val => !val,
  },
  {
    label: 'Mailing Address',
    key: 'mailing_address',
    type: 'value',
    dependField: 'is_same_as_physical',
    dependFn: val => !!val,
  },
  {
    label: 'Apt.',
    key: 'mailing_apt',
    type: 'value',
    empty: true,
    dependField: 'is_same_as_physical',
    dependFn: val => !!val,
  },
  {
    label: 'Address',
    addressGroup: true,
    alignLeft: true,
    dependField: 'is_same_as_physical',
    dependFn: val => !!val,
    fields: [
      {
        label: 'City',
        key: 'mailing_city',
        type: 'value',
      },
      // {
      //   label: 'County',
      //   key: 'mailing_county',
      //   type: 'value',
      // },
      {
        label: 'State',
        key: 'mailing_state',
        type: 'value',
      },
      {
        label: 'Zip Code',
        key: 'mailing_zip_code',
        type: 'value',
      },
    ],
  },
];
export const CONTACT_OBJ_KEYS = [
  {
    label: 'Name',
    key: 'first_name',
    type: 'value',
    nameGroup: true,
    fields: [
      {
        label: 'First Name',
        key: 'contact.first_name',
        type: 'value',
      },
      {
        label: 'Last Name',
        key: 'contact.last_name',
        type: 'value',
      },
    ],
  },
  {
    label: 'Title',
    key: 'contact.title',
    type: 'value',
  },
  {
    label: 'Other',
    key: 'contact.other',
    type: 'value',
    dependField: 'contact.title',
    dependFn: val => val !== 'Other',
    fullWidth: true,
  },
  {
    label: 'Phone',
    key: 'contact.phone',
    type: 'formatValue',
  },
  {
    label: 'Email',
    key: 'contact.email',
    type: 'value',
  },
];
