import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import Collapse, { Panel } from 'components/Collapse';
import toast from 'helper/toast';
import { getErrorMessageFromObj } from 'utils/object';
import Header from 'views/pages/ClientDetail/CompanyOfficial/components/Header';
import NotFound from 'views/pages/ClientDetail/CompanyOfficial/components/NotFound';
import StatusTag from 'views/pages/ClientDetail/CompanyOfficial/components/StatusTag';
import PanelHeader from 'views/pages/ClientDetail/CompanyOfficial/PanelHeader';
import { ALL_OPTION } from 'views/pages/ClientDetail/CompanyOfficial/constants';
import { TooltipBlock } from 'components/Tooltip';
import {
  afterCbFn,
  isAlreadyNew,
} from 'views/pages/ClientDetail/CompanyOfficial/utils';
import Section from './components/Section';
import {
  getCheckingAccountBR,
  parseCheckingAccountsData,
  parseFuelCardsData,
  getFuelCardsBR,
} from 'views/pages/ClientDetail/Driver/utils';
import { getLeasorBR, getLeasorContact } from './utils';
import { DEFAULT_SECTIONS, NEW_KEY } from './constants';
import {
  CollapseTable,
  CollapseAdditionalLabel,
  LabelWithAvatar,
} from '../components/Elements';
import { onKeyChangeFn, getEditKeys, editChangeFn, getIsKey } from '../utils';
import { useIndexData, useActions } from './selectorData';
import classes from './Leasor.module.scss';

let isAddedAccount = false;
let isAddedCard = false;

const Leasor = ({
  className,
  confirmModal,
  openWarningModal,
  setIsEditing,
  activeTab,
  clientId,
  setIsEdit,
  status,
  setStatus,
  onUpdated,
}) => {
  const setIsEditParent = setIsEdit;
  const {
    leasors,
    updateLeasorLoading,
    getLeasorAccountLoading,
    validatePasswordLoading,
    getFuelCardLoading,
    createAccountsLoading,
    createFuelCardsLoading,
  } = useIndexData();
  const {
    getLeasors,
    updateLeasor,
    createLeasor,
    updateLeasorStatus,
    getLeasorAccount,
    createLeasorAccount,
    updateLeasorAccount,
    validatePassword,
    createLeasorFuelCard,
    updateLeasorFuelCard,
    getLeasorFuelCard,
  } = useActions();
  const [createLoading, setCreateLoading] = useState(false);
  const [finalFile, setFinalFile] = useState({});
  const [avatarFinalSrc, setAvatarFinalSrc] = useState('');
  const [statusLoading, setStatusLoading] = useState({});
  const [sections, setSections] = useState(DEFAULT_SECTIONS);
  const [activeKeys, setActiveKeys] = useState([]);
  const [editKeys, setEditKeys] = useState([]);
  const [editChanged, setEditChanged] = useState({});
  const notFound = sections.length === 0 && leasors.length === 0;
  const handleEditChange = (...params) =>
    editChangeFn(editChanged, setEditChanged, setIsEditing, ...params);
  const handleAddCA = (leasorId, values, cb) => {
    const checkingAccountsBR = getCheckingAccountBR(values.accounts || []);
    if (checkingAccountsBR.length > 0) {
      createLeasorAccount(
        clientId,
        leasorId,
        { accounts: checkingAccountsBR },
        (res, err) => {
          if (res) {
            if (cb) cb(res);
          } else {
            toast.error(getErrorMessageFromObj(err)[0]);
          }
        }
      );
    } else if (cb) {
      cb();
    }
  };
  const handleAddCard = (leasorId, values, cb) => {
    const cardBR = getFuelCardsBR(values.cards || []);
    if (cardBR.length > 0) {
      createLeasorFuelCard(
        clientId,
        leasorId,
        { fuel_cards: cardBR, type: 'leasor' },
        (res, err) => {
          if (res) {
            if (cb) cb(res);
          } else {
            toast.error(getErrorMessageFromObj(err)[0]);
          }
        }
      );
    } else if (cb) {
      cb();
    }
  };
  const handleEditCA = leasorId => (accountId, accountBR, cb, errCb) => {
    updateLeasorAccount(
      clientId,
      leasorId,
      accountId,
      accountBR,
      (res, err) => {
        if (res) {
          if (cb) cb(res);
        } else {
          if (errCb) errCb();
          toast.error(getErrorMessageFromObj(err)[0]);
        }
      }
    );
  };
  const handleEditCard = leasorId => (cardId, cardBR, cb, errCb) => {
    updateLeasorFuelCard(clientId, leasorId, cardId, cardBR, (res, err) => {
      if (res) {
        if (cb) cb(res);
      } else {
        if (errCb) errCb();
        toast.error(getErrorMessageFromObj(err)[0]);
      }
    });
  };
  const handleValidatePassword = leasorId => type => (id, caCb) => (
    password,
    cb
  ) => {
    validatePassword({ password }, (res, err) => {
      if (res) {
        const actionFn =
          type === 'accounts' ? getLeasorAccount : getLeasorFuelCard;
        actionFn(clientId, leasorId, id, (res1, err1) => {
          if (res1) {
            if (caCb) caCb(res1);
            if (cb) cb();
          } else {
            toast.error(getErrorMessageFromObj(err1)[0]);
          }
        });
      } else {
        toast.error(getErrorMessageFromObj(err)[0]);
      }
    });
  };
  const handleSubmitCb = (leasorId, cb, res) => {
    if (isAddedAccount && isAddedCard) {
      isAddedAccount = false;
      isAddedCard = false;
      if (!leasorId) {
        handleLoadLeasor(status, () => {
          setCreateLoading(false);
          if (cb) cb();
        });
      } else if (cb) cb(res);
    }
  };
  const handleSubmit = id => (values, cb) => {
    const leasorId = id && id !== NEW_KEY ? id : '';
    const actionFn = leasorId ? updateLeasor : createLeasor;
    if (!leasorId) {
      setCreateLoading(true);
    }
    isAddedAccount = false;
    isAddedCard = false;
    actionFn(
      clientId,
      getLeasorBR(values, leasorId, finalFile),
      afterCbFn(
        res => {
          if (onUpdated) onUpdated();
          const newLeasorId = get(res, 'data.id', '');
          handleAddCA(newLeasorId, values, () => {
            isAddedAccount = true;
            handleSubmitCb(leasorId, cb, res);
          });
          handleAddCard(newLeasorId, values, () => {
            isAddedCard = true;
            handleSubmitCb(leasorId, cb, res);
          });
        },
        () => {
          if (!leasorId) setCreateLoading(false);
        }
      ),
      leasorId
    );
  };
  const handleLoadLeasor = (val, cb) => {
    const filters = {};
    if (val !== ALL_OPTION.value) {
      filters.status = val;
    }
    getLeasors({ id: clientId }, filters, afterCbFn(cb));
  };
  const handleSetStatusLoading = (id, val) => {
    setStatusLoading({
      ...statusLoading,
      [id]: val,
    });
  };
  const handleStatusChange = id => newStatus => {
    handleSetStatusLoading(id, true);
    updateLeasorStatus(
      clientId,
      id,
      { status: newStatus },
      afterCbFn(
        () => {
          if (onUpdated) onUpdated();
          handleLoadLeasor(status, () => {
            handleSetStatusLoading(id, false);
            setActiveKeys(activeKeys.filter(key => String(key) !== String(id)));
          });
        },
        () => handleSetStatusLoading(id, false)
      )
    );
  };

  useEffect(() => {
    if (activeTab === 1) {
      setSections(DEFAULT_SECTIONS);
      setEditKeys([]);
      setEditChanged({});
    }
  }, [activeTab]);
  useEffect(() => {
    setIsEditParent(sections.length > 0 || editKeys.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections, editKeys]);

  return (
    <div className={cn(classes.wrapper, className)}>
      <Header
        title="Leasor Information"
        status={status}
        setStatus={val => {
          setStatus(val);
          handleLoadLeasor(val);
        }}
        onAdd={() => {
          if (!isAlreadyNew(sections)) {
            setSections([
              { label: 'New Leasor', id: NEW_KEY, isNew: true },
              ...sections,
            ]);
            setEditKeys([...editKeys, NEW_KEY]);
            setActiveKeys([...activeKeys, NEW_KEY]);
            setFinalFile({});
            setAvatarFinalSrc('');
          }
        }}
        className={classes.header}
      />
      {notFound ? (
        <NotFound />
      ) : (
        <div className={classes.content}>
          <CollapseTable hideHeader section className={classes.sectionTable}>
            <Collapse
              destroyInactivePanel
              onChange={keys => {
                onKeyChangeFn(keys, activeKeys, editKeys, setActiveKeys);
              }}
              activeKey={activeKeys}
            >
              {[...sections, ...leasors].map(section => {
                const { isNew, id, profile_pic } = section;
                const key = String(id);
                const label = section.label || section.legal_name || '';
                const isActive = getIsKey(activeKeys, key);
                const isEdit = getIsKey(editKeys, key);
                const sectionInnerProps = {
                  isNew,
                  clientId,
                  rootKey: key,
                  isEdit,
                  isActive,
                  confirmModal,
                  setIsEdit: edit => {
                    const newEditKeys = getEditKeys(editKeys, edit, key);
                    setEditKeys(newEditKeys);
                    if (!edit) {
                      handleEditChange(key, false, newEditKeys);
                    }
                    if (isNew && !edit) {
                      setSections(DEFAULT_SECTIONS);
                    }
                    setFinalFile({});
                    setAvatarFinalSrc(profile_pic);
                  },
                  data: {
                    ...section,
                    accounts: parseCheckingAccountsData(section),
                    cards: parseFuelCardsData(section),
                  },
                  onFormChange: () => {
                    handleEditChange(key, true, editKeys);
                  },
                  openWarningModal,
                  loading:
                    (isNew ? createLoading : updateLeasorLoading[id]) ||
                    createAccountsLoading ||
                    createFuelCardsLoading,
                  statusLoading: statusLoading[id],
                  onSubmit: handleSubmit(id),
                  onStatusChange: handleStatusChange(id),
                  onEditCheckingAccount: handleEditCA(id),
                  onEditFuelCard: handleEditCard(id),
                  onValidatePassword: handleValidatePassword(id),
                  validatePasswordLoading,
                  getLeasorAccountLoading,
                  getFuelCardLoading,
                };
                const avatarProps = {
                  confirmModal,
                  avatarFinalSrc,
                  setAvatarFinalSrc,
                  finalFile,
                  setFinalFile,
                  profilePic: profile_pic,
                };
                const {
                  nameDisplay,
                  phoneDisplay,
                  dotDisplay,
                } = getLeasorContact(section);
                const pHeaderProps = {
                  label: (
                    <LabelWithAvatar
                      {...avatarProps}
                      label={label}
                      isEdit={isEdit}
                    />
                  ),
                  isActive,
                  firstIsSmall: true,
                  additionalLabel: (
                    <CollapseAdditionalLabel
                      isActive={isActive}
                      has3Items
                      className={classes.additionalLabel}
                    >
                      {!isNew && (
                        <>
                          <span></span>
                          <span>
                            <span>
                              Contact:
                              <TooltipBlock tooltip={nameDisplay}>
                                {nameDisplay}
                              </TooltipBlock>
                            </span>
                            <span>
                              Phone:
                              <TooltipBlock tooltip={phoneDisplay}>
                                {phoneDisplay}
                              </TooltipBlock>
                            </span>
                            <span>
                              DOT:
                              <TooltipBlock tooltip={dotDisplay}>
                                {dotDisplay}
                              </TooltipBlock>
                            </span>
                          </span>
                        </>
                      )}
                    </CollapseAdditionalLabel>
                  ),
                  isEdit,
                  className: cn(classes.panelHeader, {
                    [classes.isActive]: isActive,
                    [classes.isEdit]: isEdit,
                  }),
                };
                return (
                  <Panel
                    className={classes.panel}
                    forceRender={true}
                    header={<PanelHeader {...pHeaderProps} />}
                    key={key}
                    extra={
                      !isActive ? <StatusTag status={section.status} /> : null
                    }
                  >
                    <Section {...sectionInnerProps} />
                  </Panel>
                );
              })}
            </Collapse>
          </CollapseTable>
        </div>
      )}
    </div>
  );
};

export default Leasor;
