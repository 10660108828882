import React from 'react';
import cn from 'classnames';
import Avatar5Img from 'assets/img/avatars/Avatar_5.png';
import Avatar from 'components/Avatar';
import AvatarText from './AvatarText';
import classes from './Elements.module.scss';

const ChatAvatar = ({
  avatar,
  name,
  departmentColor,
  className,
  size = 'large',
  isDisplayFullName,
  isMe,
}) => {
  return (
    <>
      {avatar ? (
        <Avatar
          src={avatar !== 'group' ? avatar : Avatar5Img}
          className={cn(classes.avatar, className)}
          size={size}
        />
      ) : (
        <AvatarText
          isDisplayFullName={isDisplayFullName}
          name={name}
          className={cn(
            classes.avatar,
            {
              [classes.isLarge]: size === 'large',
            },
            className
          )}
          department={departmentColor}
          size={size}
          isMe={isMe}
        />
      )}
    </>
  );
};

export default ChatAvatar;
