import find from 'lodash/find';

const getOptions = ({ departments, selectedDepartment }) => {
  let positionOptions = [];
  const departmentOptions = (departments || []).map(d => ({
    value: d.id,
    label: d.name,
  }));
  if (selectedDepartment) {
    const foundDepartment =
      find(departments || [], { id: selectedDepartment }) || {};
    positionOptions = (foundDepartment.roles || []).map(r => ({
      value: r.id,
      label: r.name,
    }));
  }
  return { departmentOptions, positionOptions };
};

export { getOptions };
