import React from 'react';
import cn from 'classnames';
import { useOptions, useIsInternalUser, useIsOwnerRecord } from 'helper/hooks';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  Title,
} from 'views/pages/ClientDetail/components/Elements';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import InputItemEmpty from 'views/pages/ClientDetail/components/InputItem/Empty';
import SecurityField from 'components/SecurityField';
// import { filteredClassOptions } from 'views/pages/ClientDetail/CompanyOfficial/utils';
import { DRIVER_LICENSE_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const DriverLicense = ({
  className,
  isEdit,
  form,
  data,
  disabled,
  clientId,
  isNew,
}) => {
  const {
    stateOptions,
    // scClassOptions,
    scEndorsementOptions,
    scRestrictionOptions,
  } = useOptions();
  const { isInternalUser, isExternalOwner } = useIsInternalUser();
  const isOwnerRecord = useIsOwnerRecord(data?.id);

  return (
    <div className={cn(classes.driverLicenseSection, className)}>
      <Title text="Driver’s License" />
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection className={classes.elForm}>
            {isInternalUser ? (
              <SecurityField
                fieldObj={{
                  label: 'Driver’s License #',
                  value: '********',
                  type_id: data?.id,
                  type: 'driver',
                  company_id: clientId,
                  key: 'dl_number',
                  alwaysDisplayView: isNew,
                }}
                className={classes.securityField1}
                viewRender={isShow => {
                  return (
                    <InputItem
                      name={'dl_number'}
                      label="Driver’s License #"
                      form={form}
                      addressGroupField
                      disabled={disabled}
                      hide={!isShow}
                    />
                  );
                }}
                pinSuccessCb={val => {
                  form.setFieldsValue({
                    dl_number: val,
                  });
                }}
              />
            ) : isExternalOwner || isOwnerRecord ? (
              <InputItem
                name={'dl_number'}
                label="Driver’s License #"
                form={form}
                addressGroupField
                disabled={disabled}
              />
            ) : (
              <InputItemEmpty addressGroupField />
            )}
            <InputItem
              name={'dl_state_id'}
              label="State"
              form={form}
              type="select"
              options={stateOptions}
              addressGroupField
              disabled={disabled}
            />
            <InputItem
              name={'driver_license'}
              label="Driver’s License"
              form={form}
              type="file"
              disabled={disabled}
            />
            <InputItem
              name={'dl_expiration_date'}
              label="Expiration Date"
              form={form}
              type="date"
              disabled={disabled}
            />
            <InputItem
              name={'class'}
              label="Class"
              form={form}
              disabled={disabled}
            />
            <InputItem
              name={'endorsements'}
              label="Endorsements"
              form={form}
              type="select"
              options={scEndorsementOptions}
              mode="multiple"
              isMultipleOptionCheckbox
              disabled={disabled}
            />
            <InputItem
              name={'restrictions'}
              label="Restrictions"
              form={form}
              type="select"
              options={scRestrictionOptions}
              mode="multiple"
              isMultipleOptionCheckbox
              disabled={disabled}
            />
          </FormSection>
        ) : (
          <ListInfo
            objKeys={DRIVER_LICENSE_OBJ_KEYS}
            data={data}
            sType="driver"
            sCompanyId={clientId}
            needSecure
          />
        )}
      </ElementSection>
    </div>
  );
};

export default DriverLicense;
