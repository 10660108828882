import get from 'lodash/get';
import toast from 'helper/toast';
import {
  COMPLETED_WORK_QUEUE_NAME,
  CANCELLED_WORK_QUEUE_NAME,
} from 'helper/const';
import { getErrorMessageFromObj } from 'utils/object';

export const handleCb = (cb, errCb) => (res, err) => {
  if (res) {
    if (cb) cb(res);
  } else {
    if (errCb) errCb();
    toast.error(getErrorMessageFromObj(err)[0]);
  }
};
export const getNewColumn = res => {
  return {
    ...(res.data || {}),
    orders: [],
    count: 0,
    is_default: 0,
  };
};

export const reorder = (source, destination, columns) => {
  const newColumns = [...columns];
  const sourceColumnOrders = Array.from(
    get(columns, `[${source.droppableId}].orders.data`) || []
  );
  const [removed] = sourceColumnOrders.splice(source.index, 1);
  sourceColumnOrders.splice(destination.index, 0, removed);
  if (!newColumns[source.droppableId].orders) {
    newColumns[source.droppableId].orders = {
      data: [...sourceColumnOrders],
    };
  } else {
    newColumns[source.droppableId].orders.data = [...sourceColumnOrders];
  }
  return newColumns;
};

/**
 * Moves an item from one list to another list.
 */
export const move = (source, destination, columns) => {
  const newColumns = [...columns];
  const sourceColumnOrders = Array.from(
    get(columns, `[${source.droppableId}].orders.data`) || []
  );
  const destinationColumnOrders = Array.from(
    get(columns, `[${destination.droppableId}].orders.data`) || []
  );
  const [removed] = sourceColumnOrders.splice(source.index, 1);
  destinationColumnOrders.splice(destination.index, 0, removed);
  if (!newColumns[source.droppableId].orders) {
    newColumns[source.droppableId].orders = {
      data: [...sourceColumnOrders],
    };
  } else {
    newColumns[source.droppableId].orders.data = [...sourceColumnOrders];
  }
  const previousSourceTotal = newColumns[source.droppableId].orders.total || 0;
  if (previousSourceTotal > 0) {
    newColumns[source.droppableId].orders.total = previousSourceTotal - 1;
  }
  if (!newColumns[destination.droppableId].orders) {
    newColumns[destination.droppableId].orders = {
      data: [...destinationColumnOrders],
    };
  } else {
    newColumns[destination.droppableId].orders.data = [
      ...destinationColumnOrders,
    ];
  }
  const previousTargetOrders =
    newColumns[destination.droppableId].orders.total || 0;
  newColumns[destination.droppableId].orders.total = previousTargetOrders + 1;
  return newColumns;
};

export const getItemStyle = (isDragging, draggableStyle) => {
  // const transform = isDragging ? style.transform : "none !important";
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // styles we need to apply on draggables
    ...draggableStyle,
  };
};
export const getListStyle = isDraggingOver => ({});
export const getDraggableIdFn = ({
  initial,
  dragSourceId,
  setDragSourceId,
  dragTargetId,
  setDragTargetId,
}) => {
  const sourceId = get(initial, 'source.droppableId');
  const targetId = get(initial, 'destination.droppableId');
  if (sourceId !== dragSourceId) {
    setDragSourceId(sourceId);
  }
  if (targetId !== dragTargetId) {
    setDragTargetId(targetId);
  }
};
export const getUpdatedColumns = (columns, columnId, orderId, key, value) => {
  return columns.map(c => {
    if (c.id === columnId) {
      const newOrders = (c.orders?.data || []).map(o => {
        if (String(o.id) === String(orderId)) {
          o[key] = value;
        }
        return o;
      });
      if (!c.orders) {
        c.orders = {
          data: [...newOrders],
        };
      } else {
        c.orders.data = [...newOrders];
      }
      if (
        key === 'status' &&
        [
          COMPLETED_WORK_QUEUE_NAME.NORMAL,
          CANCELLED_WORK_QUEUE_NAME.NORMAL,
        ].includes(value) &&
        c.orders.total > 0
      ) {
        c.orders.total = c.orders.total - 1;
      }
    }
    return c;
  });
};
export const getNewArrayObjects = (arr, id, key, value) => {
  return arr.map(obj => {
    if (obj.id === id) {
      obj[key] = typeof value !== 'function' ? value : value(obj[key]);
    }
    return obj;
  });
};
export const getIsDefaultFilter = filter => {
  return (
    !filter ||
    filter.length === 0 ||
    (filter.length === 1 && filter[0] === 'all') ||
    (filter.length > 1 && filter[filter.length - 1] === 'all')
  );
};
export const getIsDefaultFilters = filters => {
  let isDefault = true;
  Object.keys(filters || {}).map(k => {
    if (!getIsDefaultFilter(filters[k])) {
      isDefault = false;
    }
    return true;
  });
  return isDefault;
};
const getEachFilterValue = filter => {
  return (filter || []).filter(val => val !== 'all');
};
export const getAppliedFilters = filters => {
  const employees = getEachFilterValue(filters.employee);
  const clients = getEachFilterValue(filters.client);
  const orderTypes = getEachFilterValue(filters.order_type);
  const orderStatuses = getEachFilterValue(filters.order_status);
  return { employees, clients, orderTypes, orderStatuses };
};
export const getFiltersRequest = filters => {
  if (getIsDefaultFilters(filters)) {
    return {};
  }
  const request = {};
  const { employees, clients, orderTypes, orderStatuses } = getAppliedFilters(
    filters
  );
  if (employees.length > 0 && !getIsDefaultFilter(filters.employee)) {
    request.employee_ids = employees;
  }
  if (clients.length > 0 && !getIsDefaultFilter(filters.client)) {
    request.client_ids = clients;
  }
  if (orderTypes.length > 0 && !getIsDefaultFilter(filters.order_type)) {
    request.order_type_ids = orderTypes;
  }
  if (orderStatuses.length > 0 && !getIsDefaultFilter(filters.order_status)) {
    request.status = orderStatuses;
  }
  return request;
};
export const getDisplaySortLabel = val => {
  if (!val || typeof val !== 'string') return '';
  return val.split(' (')[0];
};
export const getSortParams = sorts => {
  return Object.keys(sorts || {}).map(key => {
    const sortVal = sorts[key] || '';
    return {
      work_queue_id: key,
      sort_by: sortVal.replace('_a_z', '').replace('_z_a', ''),
      order: sortVal.includes('_a_z') ? 'asc' : 'desc',
    };
  });
};
export const makeDDArea = () => {
  setTimeout(() => {
    const el = document.getElementById('work-queue-inner-content');
    const childEl = document.getElementById('work-queue-inner-content-child');
    if (el && childEl) {
      const realHeight = el.scrollHeight;
      childEl.style.height = `${realHeight}px`;
    }
  }, 0);
};
