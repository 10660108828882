import React from 'react';
import cn from 'classnames';

import classes from './PageContent.module.scss';

const PageContent = props => {
  const {
    className,
    initFullHeight,
    noBackground,
    noPadding,
    children,
    hasMargin,
  } = props;
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.initFullHeight]: initFullHeight,
          [classes.noBackground]: noBackground,
          [classes.noPadding]: noPadding,
          [classes.hasMargin]: hasMargin,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default PageContent;
