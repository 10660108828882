import React from 'react';
import { useOptions } from 'helper/hooks';
import { FormItem } from 'components/Form';
import { Input, TextArea, Select } from 'components/FormFields';
import { WORDS_LIMIT } from 'helper/const';
import { Left, Label } from './Elements';
import { getWordLength } from 'utils/text';
import { useNameData } from './selectorData';
import classes from './NewOrderType.module.scss';

const OrderTypeName = ({ form, isViewDetail }) => {
  const { orderTypeDepartmentOptions } = useOptions();
  const { orderTypeDetail } = useNameData();
  const orderTypeName = orderTypeDetail.name || '';
  const orderTypeDes = orderTypeDetail.description || '';
  const departmentName = orderTypeDetail.department_name || '';

  return (
    <Left
      className={classes.orderTypeName}
      title={isViewDetail ? orderTypeName : 'Order Type Name'}
    >
      <FormItem
        name={'name'}
        label={'Order Type Name'}
        required={!isViewDetail}
      >
        {isViewDetail ? (
          <Label>{orderTypeName}</Label>
        ) : (
          <Input
            size={'medium'}
            name="name"
            form={form}
            placeholder="Input a name for this order type"
            autoFocus={true}
            isBlue
          />
        )}
      </FormItem>
      <FormItem
        name={'department_id'}
        label="Department"
        required={!isViewDetail}
      >
        {isViewDetail ? (
          <Label>{departmentName}</Label>
        ) : (
          <Select
            name="department_id"
            placeholder="Select Department"
            form={form}
            items={orderTypeDepartmentOptions}
            size="medium"
          />
        )}
      </FormItem>
      <FormItem label="Description" shouldUpdate>
        {() => {
          const values = form.getFieldsValue();
          return (
            <FormItem
              name="description"
              onChange={e => {
                const thisValue = e.target.value;
                const wordLength = getWordLength(thisValue);
                form.setFieldsValue({
                  description:
                    wordLength <= WORDS_LIMIT.COMMON
                      ? thisValue
                      : values.description,
                });
              }}
            >
              {isViewDetail ? (
                <Label>{orderTypeDes}</Label>
              ) : (
                <TextArea
                  rows={4}
                  name="description"
                  isBlue
                  placeholder="Add an optional description of this order type"
                  caption={`${getWordLength(values.description)} / ${
                    WORDS_LIMIT.COMMON
                  }`}
                  captionAsCount
                />
              )}
            </FormItem>
          );
        }}
      </FormItem>
    </Left>
  );
};

export default OrderTypeName;
