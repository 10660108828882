import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_CHECKING_ACCOUNTS',
  'GET_CHECKING_ACCOUNT',
  'CREATE_CHECKING_ACCOUNT',
  'UPDATE_CHECKING_ACCOUNT',
  'SYNC_UPDATE_CHECKING_ACCOUNT',
  'GET_FUEL_CARDS',
  'GET_FUEL_CARD',
  'CREATE_FUEL_CARD',
  'UPDATE_FUEL_CARD',
  'DELETE_FUEL_CARD',
  'SYNC_UPDATE_FUEL_CARD'
);
