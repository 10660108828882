// import React from 'react';
// import { ColumnActions } from 'components/Table';
// import { STATUS_VALUES, FORMATS } from 'helper/const';
import { FORMATS } from 'helper/const';
import {
  renderStandardColumn,
  renderTagColumn,
  renderNameColumn,
} from 'utils/table';

const orderColumns = () => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      render: renderNameColumn,
      width: 160,
      sorter: true,
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      render: renderStandardColumn,
      width: 160,
      sorter: true,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      render: value => renderStandardColumn(value, FORMATS.TABLE_PHONE),
      width: 160,
      sorter: true,
    },
    {
      title: 'Role',
      dataIndex: 'role_name',
      render: renderStandardColumn,
      width: 150,
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value, record) => {
        return renderTagColumn(value, record, () => {});
      },
      width: 130,
      sorter: true,
    },
    // {
    //   title: '',
    //   width: 30,
    //   render: (_value, record) => {
    //     const actions = [
    //       {
    //         label: 'View',
    //         props: {
    //           onClick: () => {
    //             onView(record);
    //           },
    //         },
    //       },
    //       {
    //         label: 'Edit',
    //         props: {
    //           onClick: () => {
    //             if (record.status === STATUS_VALUES.DRAFT) {
    //               onEditDraft(record);
    //             }
    //           },
    //         },
    //       },
    //     ];
    //     if (record.status === STATUS_VALUES.DRAFT) {
    //       actions.push({
    //         label: 'Delete',
    //         props: {
    //           onClick: () => {
    //             onDelete(record);
    //           },
    //         },
    //       });
    //     }
    //     return <ColumnActions trigger={['click']} actions={[]} />;
    //   },
    // },
  ];
};
export { orderColumns };
