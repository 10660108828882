import React from 'react';
import cn from 'classnames';
import classes from './InputItem.module.scss';

const InputItemEmpty = ({
  fullWidth,
  addressField,
  aptField,
  addressGroupField,
  half,
  className,
  hide,
  fake,
}) => {
  return (
    <div
      className={cn(
        classes.formItem,
        {
          [classes.fullWidth]: fullWidth,
          [classes.addressField]: addressField,
          [classes.aptField]: aptField,
          [classes.addressGroupField]: addressGroupField,
          [classes.half]: half,
          [classes.hide]: hide,
          [classes.fake]: fake,
        },
        className
      )}
    ></div>
  );
};

export default InputItemEmpty;
