import React, { useState } from 'react';
import { Input } from 'components/FormFields';
import TreeBox from '../TreeTransfer/TreeBox';
import { Required } from '../../Elements';
import { FieldsHeader } from '../../Fields';
import { generateTree } from '../../utils';
import { REQUIRED } from '../../constants';
import classes from './DetailTree.module.scss';

const getTargetKeys = (targetKeys, treeData) => {
  treeData.map(n => {
    if (!targetKeys.includes(n.key)) {
      targetKeys.push(n.key);
    }
    (n.children || []).map(c => {
      if (!targetKeys.includes(c.key)) {
        targetKeys.push(c.key);
      }
      getTargetKeys(targetKeys, c.children || []);
      return true;
    });
    return true;
  });
};
const DetailTree = ({ treeData }) => {
  const [keyword, setKeyword] = useState('');
  const targetKeys = [];
  getTargetKeys(targetKeys, treeData);
  const treeDataSource = generateTree(
    treeData,
    targetKeys,
    'right',
    null,
    (keyword || '').toLowerCase()
  );
  const treeProps = {
    isLeftSide: false,
    checkedKeys: [],
    treeData: treeDataSource,
    onAction: () => {},
    onSelectTree: () => {},
    onTreeDataChange: () => {},
    direction: 'right',
    hideCloseRightSide: true,
    renderCustomContents: item => {
      return [
        <>
          <span>{item.title}</span>
          {item.add === REQUIRED && !!item.parent && <Required>*</Required>}
        </>,
        item.parent ? (
          <Input size={'medium'} placeholder={`Input information`} isBlue />
        ) : (
          ''
        ),
      ];
    },
    treeClassName: classes.treeWrapper,
  };
  const treeBoxProps = {
    isLeftSide: false,
    setAvailableSearch: () => {},
    setSelectedSearch: val => {
      setKeyword(val);
    },
    selectedFieldsCount: 0,
    direction: 'right',
    treeProps,
    className: 'ant-transfer-list',
    customHeaderContents: ['Category', 'Client Information'],
    headerClassName: classes.headerRow,
    treeHeaderClassName: classes.treeHeader,
    searchPlaceholder: 'Search information fields',
  };
  return (
    <div className={classes.treeWrapper}>
      <FieldsHeader>
        <h2>Information Fields</h2>
      </FieldsHeader>
      <TreeBox {...treeBoxProps} />
    </div>
  );
};

export default DetailTree;
