import { apiGetReportFields, apiGetReports } from 'api';

import { reportTypes } from '../types';

// GET_REPORTS
export const getReports = (filters = {}, cb, isMore) => {
  return {
    isAsyncCall: true,
    baseType: reportTypes.GET_REPORTS,
    payload: { isMore, type: filters.type },
    asyncCall: () => {
      return apiGetReports(filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_REPORT_FIELDS
export const getReportFields = cb => {
  return {
    isAsyncCall: true,
    baseType: reportTypes.GET_REPORT_FIELDS,
    payload: {},
    asyncCall: () => {
      return apiGetReportFields();
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// DOWNLOAD_REPORTS
export const downloadReports = (filters = {}, cb) => {
  return {
    isAsyncCall: true,
    baseType: reportTypes.DOWNLOAD_REPORTS,
    payload: {},
    asyncCall: () => {
      return apiGetReports(filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
