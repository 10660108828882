import React, { useState } from 'react';
import cn from 'classnames';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { apiGetDecryptData } from 'api';
import toast from 'helper/toast';
import Modal from 'components/Modal';
import { MODAL } from 'helper/const';
import { getMarkText } from 'utils/string';
import { InputPassword, FakeAutocompleteField } from 'components/FormFields';
import { getMethodMapping } from 'views/pages/ClientDetail/components/Elements';
import classes from './SecurityField.module.scss';

const SecurityField = ({
  className,
  title,
  fieldObj,
  viewRender = null,
  pinSuccessCb,
}) => {
  const fieldType = fieldObj?.otherSettings?.type;
  const alwaysDisplayView = fieldObj?.alwaysDisplayView;
  const valueMethod = getMethodMapping[fieldType];
  const markValue = getMarkText(fieldObj?.value?.length);
  const [password, setPassword] = useState('');
  const [isView, setIsView] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewValue, setViewValue] = useState('');
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleValidatePassword = async () => {
    if (
      fieldObj?.key &&
      // fieldObj?.company_id &&
      fieldObj?.type
      // fieldObj?.type_id
    ) {
      try {
        setLoading(true);
        const res = await apiGetDecryptData(password, {
          attribute: fieldObj.key,
          company_id: fieldObj.company_id,
          type: fieldObj.type,
          type_id: fieldObj.type_id,
        });
        const isError = res?.status === 500;
        if (isError) {
          toast.error('Api calling failed. Please try later!');
        } else {
          const tValue = res?.data?.[fieldObj.key] || '';
          if (pinSuccessCb) pinSuccessCb(tValue);
          const errMess = res?.data?.message || '';
          if (!!errMess) {
            toast.error(errMess);
          } else {
            setViewValue(tValue);
            setIsView(true);
            setIsOpen(false);
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error('Failed action. Please try later!');
        setIsOpen(false);
      }
    } else {
      toast.error('Something went wrong. Please try later!');
      setIsOpen(false);
    }
  };
  const modalProps = {
    isOpen,
    onSubmit: () => {
      handleValidatePassword();
    },
    onClose: handleClose,
    title: title || 'Secured Information',
    maskClosable: false,
    closable: true,
    hideCancel: true,
    okText: 'Submit',
    width: MODAL.SIZE.SMALL,
    className: classes.unlockModal,
    isSubmitLoading: false,
    closeIcon: null,
    okBtnProps: {
      size: 'large',
      disabled: !password,
      loading,
    },
    blueColor: true,
  };
  return (
    <>
      {!!viewRender && viewRender(isView || alwaysDisplayView)}
      {(!viewRender || (viewRender && !isView)) && !alwaysDisplayView && (
        <div
          className={cn(classes.wrapper, className, 'security-field-wrapper')}
        >
          <div className={classes.field}>
            <span>{fieldObj?.label}</span>
            <span>
              <span>
                {isView ? (
                  <>
                    {valueMethod
                      ? valueMethod(
                          fieldObj?.key,
                          { [fieldObj?.key]: viewValue },
                          { ...(fieldObj?.otherSettings || {}) }
                        )
                      : viewValue || '-'}
                  </>
                ) : (
                  markValue
                )}
              </span>
              {isView ? (
                <EyeInvisibleFilled
                  onClick={() => {
                    setIsOpen(false);
                    setIsView(false);
                  }}
                />
              ) : (
                <EyeFilled
                  onClick={() => {
                    setIsOpen(true);
                    setLoading(false);
                    setPassword('');
                  }}
                />
              )}
            </span>
          </div>
          <Modal {...modalProps}>
            <FakeAutocompleteField />
            <p className={classes.description}>
              In order to view or edit this information, you must provide the
              security password.
            </p>
            <label>Pin Code</label>
            <InputPassword
              size={'medium'}
              name="pwd"
              isBlue
              autoFocus={true}
              value={password}
              onChange={e => {
                setPassword(e.target.value);
              }}
              onPressEnter={e => {
                handleValidatePassword();
              }}
            />
          </Modal>
        </div>
      )}
    </>
  );
};

export default SecurityField;
