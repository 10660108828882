import React from 'react';
import cn from 'classnames';
import FormListSection from 'views/pages/ClientDetail/components/FormListSection';
import SecurityBlock from 'views/pages/ClientDetail/components/SecurityBlock';
import {
  Title,
  PrimaryTag,
} from 'views/pages/ClientDetail/components/Elements';
import {
  getCardBR,
  validateFuelCardObj,
} from 'views/pages/ClientDetail/Driver/components/Section/utils';
import FuelCardEditForm from '../FuelCardEditForm';
import classes from './Section.module.scss';

const FuelCard = ({ className, isEdit, form, cards, wrapperId }) => {
  const cardsData = cards || [];

  return (
    <div className={cn(classes.cardSection, className)}>
      <Title text="Fuel Card" />
      <FormListSection
        notFoundMessage="Currently there is no fuel card available."
        isEdit={isEdit}
        form={form}
        data={cardsData}
        initialObjFn={arrayLength => {
          return {
            card_number: '',
            carrier_id: '',
            pin: '',
            cvv: '',
            expiration_date: '',
            is_primary: false,
          };
        }}
        additionalTitleFn={d => {
          return d.is_primary ? <PrimaryTag isDefault /> : '';
        }}
        titleField="title"
        name="cards"
        addBtnText="Add Card"
        newLabel="No. "
        isSecurity
        renderFields={(index, isExisting) => {
          if (isExisting) {
            const currentCardData = cardsData[index] || {};
            const simpleFields = [
              { name: 'simple_card_number', label: 'Card Number' },
            ];
            return (
              <SecurityBlock
                form={form}
                isEdit={isEdit}
                index={index}
                data={currentCardData}
                parentName="cards"
                validateObj={validateFuelCardObj()}
                wrapperId={wrapperId}
                getBR={getCardBR}
                simpleFields={simpleFields}
                title={<>Secured Information</>}
                hideActions
              />
            );
          }
          return (
            <FuelCardEditForm index={index} form={form} parentName="cards" />
          );
        }}
        renderViews={() => {
          return '';
        }}
      />
    </div>
  );
};

export default FuelCard;
