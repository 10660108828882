import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import Space from 'components/Space';
import Spin from 'components/Spin';
import PlaceholderIcon from 'assets/icons/Placeholder.svg';
import ROUTES from 'routes';
import { useIndexData, useActions } from './selectorData';
import classes from './ConfirmEmail.module.scss';

const ConfirmEmail = ({ match }) => {
  const token = get(match, 'params.token', '');
  const history = useHistory();
  const { confirmRegisterLoading } = useIndexData();
  const { confirmRegister } = useActions();
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    confirmRegister({ token }, (_res, err) => {
      if (err) {
        setIsError(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin spinning={confirmRegisterLoading} tip={'Confirm...'}>
      <Space
        direction={'vertical'}
        size={'large'}
        className={cn(classes.wrapper, {})}
      >
        <img
          src={PlaceholderIcon}
          alt="confirm-email"
          className={classes.successImg}
        />
        {!confirmRegisterLoading && (
          <p>
            {isError
              ? 'Token is expired!'
              : 'Thank you for confirming your email!'}
            <br />
            <br />
            Please{' '}
            <span
              onClick={() => {
                history.push(ROUTES.LOGIN());
              }}
            >
              click here
            </span>{' '}
            {isError ? 'to go to Login page.' : 'to sign in to RoadOffice.'}
          </p>
        )}
      </Space>
    </Spin>
  );
};

export default ConfirmEmail;
