import React from 'react';
import { STATUS_VALUES, FORMATS } from 'helper/const';
import ClassTooltipContent from './components/ClassTooltipContent';

export const NEW_KEY = 'new_official';
export const NORMAL_FIELDS = [
  'first_name',
  'last_name',
  'middle_name',
  'suffix',
  'phone',
  'email',
  'dob',
  'ssn',
  'address',
  'apt',
  'city',
  'county',
  'zip_code',
  'mailing_address',
  'mailing_apt',
  'mailing_city',
  'mailing_zip_code',
  'dl_number',
  'other',
];
export const NULL_FIELDS = [
  'title_id',
  'state_id',
  'mailing_state_id',
  'dl_state_id',
  'class_id',
];
export const ARR_FIELDS = ['endorsements', 'restrictions'];
export const NUMBER_FIELDS = ['phone', 'ssn'];
export const DATE_FIELDS = ['dob', 'dl_expiration_date', 'mc_expiration_date'];
export const IS_SAME_ADDRESS_FIELD = 'is_same_as_physical';
export const BOOLEAN_FIELDS = ['is_primary', IS_SAME_ADDRESS_FIELD];
export const FILE_FIELDS = [
  'documents.driver_license',
  'documents.medical_card',
];
export const BODY_REQUEST_NORMAL_FIELDS = [
  'first_name',
  'last_name',
  'middle_name',
  'suffix',
  'email',
  'ownership',
  'address',
  'apt',
  'county',
  'city',
  'zip_code',
  'mailing_address',
  'mailing_apt',
  'mailing_city',
  'mailing_county',
  'mailing_zip_code',
  'dl_number',
  'title_id',
  'state_id',
  'mailing_state_id',
  'dl_state_id',
  'class_id',
  'other',
];
export const MAILING_ADDRESS_FIELDS = [
  'mailing_address',
  'mailing_apt',
  'mailing_city',
  'mailing_county',
  'mailing_zip_code',
];
export const DEFAULT_SECTIONS = [];

export const ALL_OPTION = {
  value: 'all',
  label: 'All',
};
export const STATUS_OPTIONS = [
  ALL_OPTION,
  { value: STATUS_VALUES.ACTIVE, label: 'Active' },
  { value: STATUS_VALUES.IN_ACTIVE, label: 'Inactive' },
];
export const CLASS_TOOLTIP_MAPPING = {
  'Class A (CDL)': (
    <ClassTooltipContent
      title="Class A (CDL)"
      description={
        <>
          GVW over 26,000 lbs,
          <br />
          truck and trailer
        </>
      }
    />
  ),
  'Class B (CDL)': (
    <ClassTooltipContent
      title="Class B (CDL)"
      description={
        <>
          GVW over 26,000 lbs,
          <br />
          single vehicle no trailer
        </>
      }
    />
  ),
  'Class C (CDL)': (
    <ClassTooltipContent
      title="Class C (CDL)"
      description={
        <>
          Commercial vehicle, GVW
          <br />
          less than 26,000 lbs
        </>
      }
    />
  ),
  'Class D (Operator)': (
    <ClassTooltipContent
      title="Class D (Operator)"
      description={
        <>
          Non-Commercial,
          <br />
          motorcycles and autos
        </>
      }
    />
  ),
};
export const PROFILE_OBJ_KEYS = [
  {
    label: 'Profile',
    addressGroup: true,
    fields: [
      {
        label: 'First Name',
        key: 'first_name',
        type: 'value',
      },
      {
        label: 'Middle Name',
        key: 'middle_name',
        type: 'value',
      },
      {
        label: 'Last Name',
        key: 'last_name',
        type: 'value',
      },
      {
        label: 'Suffix',
        key: 'suffix',
        type: 'value',
        empty: true,
      },
    ],
  },
  {
    label: 'PhoneTitle',
    nameGroup: true,
    fields: [
      {
        label: 'Title',
        key: 'title',
        type: 'value',
      },
      {
        label: 'Phone',
        key: 'phone',
        type: 'formatValue',
      },
    ],
  },
  {
    label: 'Email',
    key: 'email',
    type: 'value',
  },
  {
    label: 'Other',
    key: 'other',
    type: 'value',
    dependField: 'title',
    dependFn: val => val !== 'Other',
    fullWidth: true,
  },
  {
    label: 'Owner',
    nameGroup: true,
    fields: [
      {
        label: 'Ownership %',
        key: 'ownership',
        type: 'value',
        suffix: '%',
        onFilter: val => (val ? val.replace('%', '') : val),
      },
      {
        label: 'DOB - Date of Birth',
        key: 'dob',
        type: 'date',
      },
    ],
  },
  {
    label: 'SSN - Social Security Number',
    key: 'ssn',
    type: 'formatValue',
    format: FORMATS.SSN,
  },
];
export const ADDRESSES_OBJ_KEYS = [
  {
    label: 'Physical Address',
    key: 'address',
    type: 'value',
  },
  {
    label: 'Apt.',
    key: 'apt',
    type: 'value',
    empty: true,
  },
  {
    label: 'Address',
    addressGroup: true,
    alignLeft: true,
    fields: [
      {
        label: 'City',
        key: 'city',
        type: 'value',
      },
      // {
      //   label: 'County',
      //   key: 'county',
      //   type: 'value',
      // },
      {
        label: 'State',
        key: 'state',
        type: 'value',
      },
      {
        label: 'Zip Code',
        key: 'zip_code',
        type: 'value',
      },
    ],
  },
  {
    label: 'Mailing address same as physical address',
    key: 'is_same_as_physical',
    type: 'checkbox',
    fullWidth: true,
    dependField: 'is_same_as_physical',
    dependFn: val => !val,
  },
  {
    label: 'Mailing Address',
    key: 'mailing_address',
    type: 'value',
    dependField: 'is_same_as_physical',
    dependFn: val => !!val,
  },
  {
    label: 'Apt.',
    key: 'mailing_apt',
    type: 'value',
    empty: true,
    dependField: 'is_same_as_physical',
    dependFn: val => !!val,
  },
  {
    label: 'Address',
    addressGroup: true,
    alignLeft: true,
    dependField: 'is_same_as_physical',
    dependFn: val => !!val,
    fields: [
      {
        label: 'City',
        key: 'mailing_city',
        type: 'value',
      },
      // {
      //   label: 'County',
      //   key: 'mailing_county',
      //   type: 'value',
      // },
      {
        label: 'State',
        key: 'mailing_state',
        type: 'value',
      },
      {
        label: 'Zip Code',
        key: 'mailing_zip_code',
        type: 'value',
      },
    ],
  },
];
export const DRIVER_LICENSE_OBJ_KEYS = [
  {
    label: 'License',
    nameGroup: true,
    fields: [
      {
        label: 'Driver’s License #',
        key: 'dl_number',
        type: 'value',
      },
      {
        label: 'State',
        key: 'dl_state',
        type: 'value',
      },
    ],
  },
  {
    label: 'Driver’s License',
    key: 'documents.driver_license',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Expiration Date',
    key: 'dl_expiration_date',
    type: 'date',
  },
  {
    label: 'Class',
    key: 'class',
    type: 'value',
  },
  {
    label: 'Endorsements',
    key: 'endorsements',
    type: 'multiValue',
  },
  {
    label: 'Restrictions',
    key: 'restrictions',
    type: 'multiValue',
  },
];
export const MEDICAL_CARD_OBJ_KEYS = [
  {
    label: 'Expiration Date',
    key: 'mc_expiration_date',
    type: 'date',
  },
  {
    label: 'Medical Card Certificate',
    key: 'documents.medical_card',
    type: 'file',
    isSingleFile: true,
  },
];
