const initialDriverState = {
  // GET
  drivers: {
    loading: false,
    data: [],
    error: '',
  },
  driverAccount: {
    loading: {},
    data: {},
    error: {},
  },
  driverFuelCard: {
    loading: {},
    data: {},
    error: {},
  },
  // POST
  createDriver: {
    loading: false,
    data: {},
    error: '',
  },
  updateDriver: {
    loading: {},
    data: {},
    error: {},
  },
  updateDriverStatus: {
    loading: {},
    data: {},
    error: {},
  },
  createDriverAccount: {
    loading: false,
    data: {},
    error: '',
  },
  updateDriverAccount: {
    loading: {},
    data: {},
    error: {},
  },
  createDriverFuelCard: {
    loading: false,
    data: {},
    error: '',
  },
  updateDriverFuelCard: {
    loading: {},
    data: {},
    error: {},
  },
};

export { initialDriverState };
