import get from 'lodash/get';

const dataURLtoFile = (dataUrl, filename) => {
  var arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
const downloadFile = (url, fileName) => {
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('download', fileName);
  element.style.display = 'none';

  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};
const onPreviewFile = async file => {
  let src = file.url;
  // If file doesn't have url
  // will read file and open to view file
  // else, will open to view url
  if (!src) {
    src = await new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj || file);
      reader.onload = () => resolve(reader.result);
    });
    const el = document.createElement('iframe');
    el.src = src;
    el.style.width = '100%';
    el.style.height = '100%';
    const win = window.open('', '_blank');
    win.document.write(el.outerHTML);
    win.focus();
  } else {
    const win = window.open(src, '_blank');
    win.focus();
  }
};
const parseSingleFile = (data, fileFields, fileNamePrefix = '') => {
  const newData = { ...data };
  if (fileNamePrefix) {
    newData[fileNamePrefix] = {};
  }
  (fileFields || []).map(field => {
    const originalKey = field.replace(`${fileNamePrefix}.`, '');
    const fileObj = get(data, field, null);
    if (fileNamePrefix) {
      newData[fileNamePrefix][originalKey] = fileObj
        ? fileObj.id
          ? [fileObj]
          : fileObj
        : null;
    } else {
      newData[originalKey] = fileObj
        ? fileObj.id
          ? [fileObj]
          : fileObj
        : null;
    }
    return true;
  });
  return newData;
};
// data is string
const downloadCSVFile = (data, fileName = new Date().getTime()) => {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', `${fileName}.csv`);
  a.click();
};

export {
  dataURLtoFile,
  downloadFile,
  onPreviewFile,
  parseSingleFile,
  downloadCSVFile,
};
