import { TAB_KEYS } from '../constants';
export const clientDriverPermission = {
  isDriver: true,
  tabs: [TAB_KEYS.CLIENT_RECORD],
  newOrder: false,
  addRecord: false,
  dotNumber: {
    searchDOT: false,
    emptyText: 'No DOT Number Available',
  },
  editOrderPrice: false,
  companyInfo: {
    dotNumber: true,
  },
};
