import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as clientDetailSelectors from 'store/selectors/clientDetail';
import * as orderSelectors from 'store/selectors/order';
import * as clientOrderDetailSelectors from 'store/selectors/clientOrderDetail';
import * as authSelectors from 'store/selectors/auth';
// ACTIONS
import * as clientDetailActions from 'store/actions/clientDetail';
import * as orderActions from 'store/actions/order';
import * as clientOrderDetailActions from 'store/actions/clientOrderDetail';
import * as checklistActions from 'store/actions/checklist';

export const useIndexData = () => {
  return {
    detailData: clientDetailSelectors.useSelectClientDetail(),
    activityLog: clientDetailSelectors.useSelectClientDetailLog(),
    activityLogLoading: clientDetailSelectors.useSelectClientDetailLogLoading(),
    addLogLoading: clientDetailSelectors.useSelectAddLogLoading(),
    currentUser: authSelectors.useSelectCurrentUser(),
  };
};
export const useMainContentData = () => {
  return {
    detailData: clientDetailSelectors.useSelectClientDetail(),
    clientOrder: orderSelectors.useSelectClientOrder(),
    updateStatusLoading: orderSelectors.useSelectUpdateClientOrderStatusLoading(),
    saveChecklistLoading: clientOrderDetailSelectors.useSelectSaveClientOrderChecklistLoading(),
    orderDetailFields: orderSelectors.useSelectOrderDetailFields(),
    orderDetailFieldValues: clientOrderDetailSelectors.useSelectSaveClientOrderFieldValues(),
  };
};
export const useHeaderData = () => {
  return {
    detailData: clientDetailSelectors.useSelectClientDetail(),
    clientOrder: orderSelectors.useSelectClientOrder(),
  };
};

export const useLoadingSpinData = () => {
  return {
    clientOrderLoading: orderSelectors.useSelectClientOrderLoading(),
    detailLoading: clientDetailSelectors.useSelectClientDetailLoading(),
    orderDetailFieldValuesLoading: clientOrderDetailSelectors.useSelectSaveClientOrderFieldValuesLoading(),
    orderDetailFieldsLoading: orderSelectors.useSelectOrderDetailFieldsLoading(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...clientDetailActions,
    ...orderActions,
    ...clientOrderDetailActions,
    ...checklistActions,
  });
};
