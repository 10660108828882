import { useEffect } from 'react';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { refreshToken } from 'store/actions/auth';
import { TIME_OUT_REFRESH_TOKEN, SOON_REFRESH_TOKEN_TIME } from 'helper/const';

let timeOutRefreshToken = null;

export const useRefreshToken = () => {
  const dispatch = useDispatch();
  const tokenAddedAt = useSelector(state =>
    get(state, 'auth.additionalAttribute.tokenAddedAt', 0)
  );
  const tokenExpireAt = useSelector(state =>
    get(state, 'auth.additionalAttribute.tokenExpireAt', 0)
  );

  const clearIntervalRefreshToken = doActionRefreshToken => {
    clearInterval(timeOutRefreshToken);
    timeOutRefreshToken = null;
    if (doActionRefreshToken) {
      dispatch(refreshToken());
    }
  };

  const triggerRefreshToken = () => {
    if (tokenAddedAt) {
      const todayTime = new Date().getTime();
      const timeOutExpired = todayTime - tokenAddedAt;
      if (timeOutExpired + SOON_REFRESH_TOKEN_TIME > tokenExpireAt) {
        clearIntervalRefreshToken(true);
      }
    }
  };

  useEffect(() => {
    if (!timeOutRefreshToken && tokenAddedAt && tokenExpireAt) {
      timeOutRefreshToken = setInterval(() => {
        triggerRefreshToken();
      }, TIME_OUT_REFRESH_TOKEN);
    } else if (!tokenAddedAt) {
      clearIntervalRefreshToken();
    }
    return () => {
      clearIntervalRefreshToken();
    };
    // eslint-disable-next-line
  }, [tokenAddedAt, tokenExpireAt]);
};
