import React from 'react';
import cx from 'classnames';
import { Form as AntdForm } from 'antd';
import FormItem from './FormItem';
import ItemInline from './ItemInline';
import FormList from './FormList';
import SectionLabel from './SectionLabel';
import classes from './Form.module.scss';

const { useForm } = AntdForm;

const Form = props => {
  const { children, className, submitHidden, ...other } = props;
  return (
    <AntdForm
      {...other}
      layout="vertical"
      className={cx(classes.wrapper, className)}
    >
      {children}
      {submitHidden && <button type="submit" className={classes.hide}></button>}
    </AntdForm>
  );
};

export { FormItem, useForm, ItemInline, FormList, SectionLabel };
export default Form;
