import React, { useRef, useState, useEffect } from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import Tooltip from './index';

import classes from './Tooltip.module.scss';

const TooltipBlock = ({
  children,
  className,
  tooltip,
  overlayClassName,
  placement = 'bottom',
  tooltipWidth,
}) => {
  let timeoutTooltip = null;
  const containerRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(true);
  timeoutTooltip = setTimeout(() => {
    const scrollWidth = get(containerRef, 'current.scrollWidth', 0);
    const clientWidth = get(containerRef, 'current.clientWidth', 0);
    if (scrollWidth <= clientWidth && scrollWidth !== 0 && clientWidth !== 0) {
      setShowTooltip(false);
    } else {
      if (!showTooltip) {
        setShowTooltip(true);
      }
    }
    clearTimeout(timeoutTooltip);
    timeoutTooltip = null;
  }, 100);
  const tooltipProps = {};
  if (!showTooltip) {
    tooltipProps.visible = false;
  }
  useEffect(() => {
    return () => {
      clearTimeout(timeoutTooltip);
    };
  }, [timeoutTooltip]);
  return (
    <Tooltip
      placement={placement}
      title={tooltip}
      overlayClassName={overlayClassName}
      tooltipWidth={tooltipWidth}
      {...tooltipProps}
    >
      <span className={cx(classes.tooltipBlock, className)} ref={containerRef}>
        {children}
      </span>
    </Tooltip>
  );
};

export default TooltipBlock;
