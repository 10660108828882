import React from 'react';
import get from 'lodash/get';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import {
  Section,
  FormSection,
  ListInfo,
  Title,
} from 'views/pages/ClientDetail/components/Elements';
import { QUARTERS } from 'helper/const';
import CollapseSection from 'views/pages/ClientDetail/components/CollapseSection';
import { checkTabHasFile } from 'views/pages/ClientDetail/components/Filling/utils';
import { PERMIT_OBJ_KEYS, PERMIT2_OBJ_KEYS } from '../../tabConstants/kentucky';

const Permit = ({ years, form, data, handleYearSubmit, initialValues }) => {
  const tYears = years || [];
  return (
    <Section flexBox>
      {tYears.map(year => {
        const mainName = `kentucky_permit_year_wise_${year}`;
        return (
          <CollapseSection
            onEdit={() => {
              const fieldObj = {
                delete_attachments: [],
                [mainName]: get(initialValues, mainName),
              };
              QUARTERS.forEach(obj => {
                const returnName = `kentucky_q${obj.value}_return_year_wise_${year}`;
                const receiptName = `kentucky_q${obj.value}_receipt_year_wise_${year}`;
                fieldObj[returnName] = get(initialValues, returnName);
                fieldObj[receiptName] = get(initialValues, receiptName);
              });
              form.setFieldsValue(fieldObj);
            }}
            hasFile={checkTabHasFile(
              [...PERMIT_OBJ_KEYS(year), ...PERMIT2_OBJ_KEYS(year)],
              data
            )}
            key={year}
            form={form}
            title={year}
            onSubmit={(values, cb) => {
              if (handleYearSubmit) handleYearSubmit(values, cb);
            }}
            renderContent={isEdit => {
              return (
                <>
                  {isEdit ? (
                    <>
                      <FormSection>
                        <InputItem
                          name={'delete_attachments'}
                          label=""
                          form={form}
                          hide
                        />
                        <InputItem
                          name={mainName}
                          label="KYU Permit"
                          form={form}
                          type="file"
                        />
                      </FormSection>
                      <Title text="Quarterly Filings" />
                      <FormSection>
                        {QUARTERS.map(obj => {
                          return (
                            <React.Fragment key={obj.value}>
                              <InputItem
                                name={`kentucky_q${obj.value}_return_year_wise_${year}`}
                                label={`${obj.label} Return`}
                                form={form}
                                type="file"
                              />
                              <InputItem
                                name={`kentucky_q${obj.value}_receipt_year_wise_${year}`}
                                label={`${obj.label} Receipt`}
                                form={form}
                                type="file"
                              />
                            </React.Fragment>
                          );
                        })}
                      </FormSection>
                    </>
                  ) : (
                    <>
                      <ListInfo
                        objKeys={PERMIT_OBJ_KEYS(year)}
                        data={data}
                        noPadding
                      />
                      <Title text="Quarterly Filings" />
                      <ListInfo
                        objKeys={PERMIT2_OBJ_KEYS(year)}
                        data={data}
                        noPadding
                      />
                    </>
                  )}
                </>
              );
            }}
          />
        );
      })}
    </Section>
  );
};

export default Permit;
