import { useStoreActions } from 'store/hooks';
// SELECTORS
// import * as sharedSelectors from 'store/selectors/shared';
import * as authSelectors from 'store/selectors/auth';
// ACTIONS
import * as sharedActions from 'store/actions/shared';
import * as authActions from 'store/actions/auth';

export const useIndexData = () => {
  return {
    currentUser: authSelectors.useSelectCurrentUser(),
    updateProfileLoading: authSelectors.useSelectUpdateProfileLoading(),
    changePasswordLoading: authSelectors.useSelectChangePasswordLoading(),
  };
};

export const useChangePasswordData = () => {
  return {
    changePasswordLoading: authSelectors.useSelectChangePasswordLoading(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...sharedActions,
    ...authActions,
  });
};
