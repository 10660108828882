import React, { useEffect } from 'react';
import cn from 'classnames';
import Form, { useForm } from 'components/Form';
import { validateForm, scrollToErrorField } from 'utils/form';
import {
  Title,
  SectionWrapper,
} from 'views/pages/ClientDetail/components/Elements';
import toast from 'helper/toast';
import { getErrorMessageFromObj } from 'utils/object';
import ButtonActions from 'views/pages/ClientDetail/components/ButtonActions';
import Profile from './Profile';
import { getInitialValues, validateObj, getDocumentBR } from './utils';
import { useActions, useIndexData } from './selectorData';
import classes from './Documents.module.scss';

const Documents = ({
  className,
  wrapperId,
  isEdit,
  setIsEdit,
  isFormChange,
  openWarningModal,
  data,
  setIsFormChanged,
  onFormChange,
  clientId,
  tabName,
  onUpdated,
}) => {
  const { createLoading } = useIndexData();
  const {
    createUnderwritingRecord,
    syncUpdateClientDetailByKey,
  } = useActions();
  const [form] = useForm();
  const handleSubmit = values => {
    const fieldErrors = validateForm(validateObj(), values);
    if (fieldErrors.length > 0) {
      form.setFields(fieldErrors);
      scrollToErrorField(wrapperId);
    } else {
      createUnderwritingRecord(
        clientId,
        getDocumentBR(values),
        (res, err) => {
          if (res) {
            if (onUpdated) onUpdated();
            syncUpdateClientDetailByKey(
              'underwriting_info',
              res.data || {},
              tabName
            );
            setIsEdit(false);
          } else {
            toast.error(getErrorMessageFromObj(err)[0]);
          }
        },
        tabName
      );
    }
  };
  useEffect(() => {
    if (isEdit) {
      setIsFormChanged(false);
      if (form) {
        form.resetFields();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  return (
    <SectionWrapper className={cn(classes.wrapper, className)} id={wrapperId}>
      <Form
        validateMessages={''}
        onFinish={handleSubmit}
        initialValues={getInitialValues(data)}
        form={form}
        onValuesChange={() => {
          setIsFormChanged(true);
          onFormChange();
        }}
      >
        <div>
          <Title text="Additional Documents">
            <ButtonActions
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              form={form}
              isFormChanged={isFormChange}
              loading={createLoading}
              className={classes.buttonActions}
              onSubmit={handleSubmit}
              openWarningModal={openWarningModal}
            />
          </Title>
          <Profile isEdit={isEdit} form={form} data={data} />
        </div>
      </Form>
    </SectionWrapper>
  );
};

export default Documents;
