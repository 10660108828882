import React, { useEffect } from 'react';
import Modal from 'components/Modal';
import { PHONE_FIELD, DATE_FIELD } from 'helper/const';
import { useOptions, useMyTeamTabIds } from 'helper/hooks';
import Form, { FormItem, useForm } from 'components/Form';
import { Input, InputMask, DatePicker, Select } from 'components/FormFields';
import { getOptions } from 'utils/options';
import { validateForm, scrollToErrorField } from 'utils/form';
import {
  getIsDisplayField,
  getFieldLabel,
} from 'views/pages/MyTeam/Detail/EditForm';
import { getInitialValues, validateObj, getModalProps } from './utils';
import classes from './InviteTeammateModal.module.scss';

export const Item = ({
  name,
  label,
  placeholder,
  required,
  type,
  autoFocus,
  form,
  disabled,
  options = [],
  onChange,
  value,
  textProps = {},
  ...other
}) => {
  const valueProps = {};
  const otherProps = {};
  if (value) {
    valueProps.value = value;
  }
  if (name === 'mi') {
    otherProps.maxLength = 1;
  }
  return (
    <FormItem {...other} name={name} label={label} required={required}>
      {type === 'date' ? (
        <DatePicker
          size={'medium'}
          name={name}
          form={form}
          placeholder={placeholder || DATE_FIELD.PLACEHOLDER}
          format={DATE_FIELD.FORMAT}
          disabled={disabled}
          {...valueProps}
        />
      ) : type === 'select' ? (
        <Select
          items={options}
          placeholder={placeholder || 'Select'}
          showSearch
          size="medium"
          name={name}
          form={form}
          disabled={disabled}
          onChange={onChange}
          {...valueProps}
        />
      ) : type === 'phone' ? (
        <InputMask
          size={'medium'}
          mask={PHONE_FIELD.MASK1}
          placeholder={PHONE_FIELD.PLACEHOLDER1}
          name={name}
          form={form}
          isBlue
          disabled={disabled}
          {...valueProps}
        />
      ) : (
        <Input
          placeholder={placeholder || label}
          name={name}
          form={form}
          size={'medium'}
          isBlue
          autoFocus={autoFocus}
          disabled={disabled}
          {...otherProps}
          {...textProps}
          {...valueProps}
        />
      )}
    </FormItem>
  );
};

const InviteTeammateModal = ({
  isOpen,
  handleClose,
  onSubmit,
  departments,
  loading,
  activeIndexTab,
  permission,
}) => {
  const inviteTeamAsClient = permission.inviteTeamAsClient || false;
  const isDisplayField = key => {
    return getIsDisplayField(permission)(key);
  };
  const fieldLabel = (key, defaultVal) => {
    return getFieldLabel(permission)(key, defaultVal);
  };
  const tabIds = useMyTeamTabIds();
  const { departmentOptions, roleOptions } = useOptions();
  const [form] = useForm();
  const handleSubmitForm = values => {
    const fieldErrors = validateForm(validateObj(permission), values);
    if (fieldErrors.length > 0) {
      form.setFields(fieldErrors);
      scrollToErrorField();
    } else {
      onSubmit(values);
    }
  };
  const modalProps = getModalProps({
    isOpen,
    form,
    handleSubmitForm,
    handleClose,
    loading,
  });
  useEffect(() => {
    if (isOpen && form) {
      form.resetFields();
      form.validateFields();
    }
  }, [form, isOpen]);
  return (
    <Modal {...modalProps}>
      <Form
        submitHidden
        onFinish={values => {
          handleSubmitForm(values);
        }}
        initialValues={getInitialValues(tabIds[activeIndexTab])}
        form={form}
        className={classes.form}
      >
        <div className="ant-modal-body-inner">
          <p>Fill out the form below to invite a new teammate.</p>
          <div className={classes.formInner}>
            <Item
              name={'first_name'}
              label="First Name"
              required
              autoFocus={true}
              form={form}
            />
            <div className={classes.customItem}>
              <Item name={'mi'} label="M.I" form={form} />
              <Item name={'last_name'} label="Last Name" required form={form} />
            </div>
            {isDisplayField('email') && (
              <Item
                name={'email'}
                label={fieldLabel('email', 'Email Address')}
                placeholder="example@email.com"
                required
                form={form}
              />
            )}
            {isDisplayField('alternate_email') && (
              <Item
                name={'alternate_email'}
                label={fieldLabel(
                  'alternate_email',
                  'Alternative / Personal Email'
                )}
                placeholder="example@email.com"
                form={form}
              />
            )}
            {isDisplayField('phone_number') && (
              <Item
                name={'phone_number'}
                label={fieldLabel('phone_number', 'Phone Number')}
                type="phone"
                form={form}
              />
            )}
            {isDisplayField('dob') && (
              <Item
                name={'dob'}
                label={fieldLabel('dob', 'Date of Birth')}
                type="date"
                form={form}
              />
            )}
            {isDisplayField('group_id') && (
              <Item
                name={'group_id'}
                label={fieldLabel('group_id', 'Department')}
                type="select"
                required
                form={form}
                options={departmentOptions}
                onChange={() => {
                  form.setFieldsValue({
                    role_id: null,
                  });
                }}
              />
            )}
            {isDisplayField('role_id') && (
              <FormItem shouldUpdate>
                {() => {
                  const values = form.getFieldsValue();
                  const { positionOptions } = getOptions({
                    departments,
                    selectedDepartment: values.group_id,
                  });
                  return (
                    <Item
                      name={'role_id'}
                      label={fieldLabel('role_id', 'Position')}
                      type="select"
                      required
                      form={form}
                      options={
                        inviteTeamAsClient ? roleOptions : positionOptions
                      }
                    />
                  );
                }}
              </FormItem>
            )}
            {isDisplayField('start_date') && (
              <Item
                name={'start_date'}
                label={fieldLabel('start_date', 'Start Date')}
                type="date"
                required
                form={form}
              />
            )}
            {isDisplayField('employee_id') && (
              <Item
                name={'employee_id'}
                label={fieldLabel('employee_id', 'Employee ID')}
                form={form}
              />
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
};
export default InviteTeammateModal;
