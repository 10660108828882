import Request from './request';

// GET
export const apiGetNotifications = async filters => {
  return Request.call({
    url: '/notifications',
    method: 'GET',
    params: filters,
  });
};
export const apiGetNotificationUnreadCount = async filters => {
  return Request.call({
    url: '/notifications/unread-count',
    method: 'GET',
    params: filters,
  });
};
export const apiReadNotification = async data => {
  return Request.call({
    url: '/notifications/read',
    method: 'POST',
    data,
  });
};
export const apiDeleteNotification = async data => {
  return Request.call({
    url: '/notifications/delete',
    method: 'POST',
    data,
  });
};
