const initialOrderState = {
  // GET
  orderRequesters: {
    loading: false,
    data: [],
    error: '',
  },
  orderDetailFields: {
    loading: false,
    data: [],
    error: '',
  },
  orderList: {
    loading: false,
    data: [],
    totalNo: 0,
    isMore: false,
    error: '',
  },
  clientOrderDetail: {
    loading: false,
    data: {},
    error: '',
  },
  clientOrder: {
    loading: false,
    data: {},
    error: '',
  },
  saveOrderAsDraft: {
    loading: false,
    data: [],
    error: '',
  },
  submitOrder: {
    loading: false,
    data: [],
    error: '',
  },
  deleteOrder: {
    loading: false,
    data: {},
    error: '',
  },
  updateClientOrderStatus: {
    loading: false,
    data: {},
    error: '',
  },
};

export { initialOrderState };
