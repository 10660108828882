import * as React from 'react';
import cn from 'classnames';
import Steps, { Step } from 'components/Steps';
import Start from './Steps/Start';
import CompanyInfo from './Steps/CompanyInfo';
import Services from './Steps/Services';
import Payment from './Steps/Payment';
import Confirm from './Steps/Confirm';
import classes from './IntakeForm.module.scss';

const steps = [
  {
    title: 'Start',
    content: Start,
  },
  {
    title: 'Company Info',
    content: CompanyInfo,
  },
  {
    title: 'Services',
    content: Services,
  },
  {
    title: 'Payment',
    content: Payment,
  },
  {
    title: 'Confirm',
    content: Confirm,
  },
];
const Home = () => {
  const [current, setCurrent] = React.useState(0);

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const Component = steps[current].content;
  return (
    <div className={cn(classes.wrapper)}>
      <Steps labelPlacement={'vertical'} current={current}>
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div>
        <Component next={next} prev={prev} />
      </div>
    </div>
  );
};

export default Home;
