import React from 'react';
import { ColumnActions } from 'components/Table';
import {
  STATUS_VALUES,
  CLIENT_ORDER_STATUS_MAPPING,
  DATE_FIELD,
} from 'helper/const';
import {
  renderStandardColumn,
  renderPriceColumn,
  renderTagColumn,
  renderDateColumn,
} from 'utils/table';

const orderColumns = ({ onView, onDelete, onEditDraft }) => {
  return [
    {
      title: 'Order Number/Type',
      dataIndex: 'order_number',
      render: (value, record) => {
        return (
          <>
            <b>{record.order_number}</b>
            <br />
            {record.order_type}
          </>
        );
      },
      width: 160,
    },
    {
      title: 'Payment Method',
      dataIndex: 'payment_method',
      render: renderStandardColumn,
      width: 160,
    },
    {
      title: 'Amount',
      dataIndex: 'total',
      render: renderPriceColumn,
      width: 100,
    },
    {
      title: 'Date Requested',
      dataIndex: 'submitted_on',
      render: value => renderDateColumn(value, DATE_FIELD.FORMAT),
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value, record) => {
        return renderTagColumn(
          value,
          record,
          () => {
            if (record.status === STATUS_VALUES.DRAFT) {
              onEditDraft(record);
            }
          },
          CLIENT_ORDER_STATUS_MAPPING
        );
      },
      width: 130,
    },
    {
      title: 'Date Completed',
      dataIndex: 'completed_on',
      render: value => renderDateColumn(value, DATE_FIELD.FORMAT),
      width: 150,
    },
    {
      title: '',
      width: 30,
      render: (_value, record) => {
        const actions = [
          {
            label: 'View',
            props: {
              onClick: () => {
                onView(record);
              },
            },
          },
          {
            label: 'Edit',
            props: {
              onClick: () => {
                if (record.status === STATUS_VALUES.DRAFT) {
                  onEditDraft(record);
                }
              },
            },
          },
        ];
        if (record.status === STATUS_VALUES.DRAFT) {
          actions.push({
            label: 'Delete',
            props: {
              onClick: () => {
                onDelete(record);
              },
            },
          });
        }
        return <ColumnActions trigger={['click']} actions={actions} />;
      },
    },
  ];
};
export { orderColumns };
