import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as reminderSelectors from 'store/selectors/reminder';
// ACTIONS
import * as reminderActions from 'store/actions/reminder';

export const useLoadingSpinData = () => {
  return {
    subscribeReminderEmailLoading: reminderSelectors.useSelectSubscribeReminderEmailLoading(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...reminderActions,
  });
};
