import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as paymentSourceSelectors from 'store/selectors/paymentSource';
import * as authSelectors from 'store/selectors/auth';
// ACTIONS
import * as authActions from 'store/actions/auth';
import * as paymentSourceActions from 'store/actions/paymentSource';

export const useIndexData = () => {
  return {
    currentUser: authSelectors.useSelectCurrentUser(),
    getCheckingAccountLoading: paymentSourceSelectors.useSelectCheckingAccountLoading(),
    getFuelCardLoading: paymentSourceSelectors.useSelectFuelCardLoading(),
    validatePasswordLoading: authSelectors.useSelectValidatePasswordLoading(),
    accounts: paymentSourceSelectors.useSelectCheckingAccounts(),
    accountsLoading: paymentSourceSelectors.useSelectCheckingAccountsLoading(),
    fuelCards: paymentSourceSelectors.useSelectFuelCards(),
    fuelCardsLoading: paymentSourceSelectors.useSelectFuelCardsLoading(),
    account: paymentSourceSelectors.useSelectCheckingAccount(),
    fuelCard: paymentSourceSelectors.useSelectFuelCard(),
    createAccountsLoading: paymentSourceSelectors.useSelectCreateCheckingAccountLoading(),
    createFuelCardsLoading: paymentSourceSelectors.useSelectCreateFuelCardLoading(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...authActions,
    ...paymentSourceActions,
  });
};
