import React from 'react';
import cn from 'classnames';
import omit from 'lodash/omit';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import classes from './modal.module.scss';

const confirmModalConfig = ({
  title = 'Title',
  description = 'Description',
  onSubmit = () => {},
  onCancel,
  otherConfigs,
}) => {
  const restConfigs = otherConfigs || {};
  return {
    width: 529,
    title,
    content: <>{description}</>,
    okButtonProps: {
      type: otherConfigs.revertButton ? 'outline' : 'primary',
    },
    cancelButtonProps: {
      type: otherConfigs.revertButton ? 'primary' : 'outline',
    },
    onCancel: onCancel ? onCancel : () => {},
    onOk: onSubmit,
    icon: <CheckCircleOutlined />,
    className: cn(
      {
        [classes.hideCancel]: !onCancel,
        [classes.blueColor]: otherConfigs.blueColor,
        [classes.revertButton]: otherConfigs.revertButton,
      },
      restConfigs.className
    ),
    ...omit(restConfigs, ['className']),
  };
};

const confirmModalBlueConfig = data => {
  return confirmModalConfig({
    ...data,
    otherConfigs: {
      blueColor: true,
      ...data.otherConfigs,
    },
  });
};

const scrollModalToBottom = () => {
  const modalBodyEl = (document.getElementsByClassName('ant-modal-body') ||
    [])[0];
  if (modalBodyEl) {
    setTimeout(() => {
      modalBodyEl.scrollTop = modalBodyEl.scrollHeight;
    }, 10);
  }
};

const getConfirmModal = (modal, confirmModalConfigFn) => (
  title,
  description,
  onSubmit,
  otherConfigs = {}
) => {
  const modalConfig = confirmModalConfigFn({
    title,
    description,
    onSubmit,
    onCancel: otherConfigs.onCancel || null,
    otherConfigs,
  });
  const { type } = otherConfigs;
  if (type === 'confirm') {
    modal.confirm({
      ...modalConfig,
      icon: otherConfigs.icon || <QuestionCircleOutlined />,
    });
  } else if (type === 'warning') {
    modal.warning({
      ...modalConfig,
      icon: <QuestionCircleOutlined />,
    });
  } else if (type === 'error') {
    modal.error({
      ...modalConfig,
      icon: otherConfigs.icon || <CloseCircleOutlined />,
    });
  } else {
    modal.success(modalConfig);
  }
};

export {
  confirmModalConfig,
  confirmModalBlueConfig,
  scrollModalToBottom,
  getConfirmModal,
};
