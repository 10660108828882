import React from 'react';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import {
  Section,
  FormSection,
  ListInfo,
} from 'views/pages/ClientDetail/components/Elements';
import { LEASED_OPTIONS } from 'views/pages/ClientDetail/Trailer/constants';
import { PROFILE_OBJ_KEYS } from './constants';

const Profile = ({ isEdit, form, data }) => {
  return (
    <Section>
      {isEdit ? (
        <FormSection>
          {/* Use it to save delete_attachments for attachment removing */}
          <InputItem name={'delete_attachments'} label="" form={form} hide />
          <InputItem
            name={'is_buyout'}
            label="Is this a buyout?"
            form={form}
            type="select"
            options={LEASED_OPTIONS}
          />
          <InputItem
            name={'factoring_company_name'}
            label="Factoring Company Name"
            form={form}
          />
        </FormSection>
      ) : (
        <ListInfo objKeys={PROFILE_OBJ_KEYS} data={data} />
      )}
    </Section>
  );
};

export default Profile;
