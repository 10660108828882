import React, { useState } from 'react';
import { validateForm, scrollToErrorField } from 'utils/form';
import Tabs from 'components/Tabs';
import { Title } from '../components/Elements';
import { validateObj } from './utils';
import { TAB_NAME_MAPPING, TAB_DATA_MAPPING } from './constants';
import Background from './tabs/Background';
import UCCFiling from './tabs/UCCFiling';
import BusinessAssociations from './tabs/BusinessAssociations';
import CreditReports from './tabs/CreditReports';
import IFAAlerts from './tabs/IFAAlerts';
import Buyout from './tabs/Buyout';
import Documents from './tabs/Documents';
import Locations from './tabs/Locations';
import classes from './UnderwritingInfo.module.scss';

const UnderwritingInfo = ({
  // className,
  // isEdit,
  clientId,
  setIsEditing,
  rootKey,
  data,
  onFormChange,
  openWarningModal,
  onSubmit,
  loading,
  onUpdated,
}) => {
  const wrapperId = `record_information_${rootKey}`;
  const [activeTab, setActiveTab] = useState('1');
  const [tabStatus, setTabStatus] = useState({});
  const [isFormChange, setIsFormChanged] = useState(false);
  const [editTab, setEditTab] = useState({});
  const handleSubmit = (values, form) => {
    const fieldErrors = validateForm(
      validateObj(TAB_NAME_MAPPING[activeTab]),
      values
    );
    if (fieldErrors.length > 0) {
      form.setFields(fieldErrors);
      scrollToErrorField(`${wrapperId}_${TAB_NAME_MAPPING[activeTab]}`);
    } else {
      onSubmit(
        values,
        () => {
          setEditTab({});
          setIsFormChanged(false);
        },
        TAB_NAME_MAPPING[activeTab]
      );
    }
  };
  const handleGlobalEdit = newEditTab => {
    setIsEditing(
      Object.keys(newEditTab).filter(k => !!newEditTab[k]).length > 0
    );
  };
  const getTabProps = tabIndex => {
    const tabName = TAB_NAME_MAPPING[tabIndex];
    const isEdit = !!editTab[tabName];
    const setIsEdit = val => {
      const newEditTab = {
        ...editTab,
        [tabName]: !!val,
      };
      setEditTab(newEditTab);
      handleGlobalEdit(newEditTab);
      if (!val) {
        setIsFormChanged(false);
      }
    };
    return {
      wrapperId: `${wrapperId}_${tabName}`,
      handleSubmit,
      isEdit,
      setIsEdit,
      isFormChange,
      loading,
      openWarningModal,
      setIsFormChanged,
      onFormChange,
      activeTab,
      tabIndex,
      clientId,
      tabName,
      data: data[TAB_DATA_MAPPING[tabName] || tabName] || {},
      dataName: TAB_DATA_MAPPING[tabName],
      status: tabStatus[tabName] || 'active',
      setStatus: newStatus => {
        setTabStatus({
          ...tabStatus,
          [tabName]: newStatus,
        });
      },
      onUpdated,
    };
  };

  return (
    <>
      <Tabs
        activeKey={activeTab}
        onChange={activeKey => {
          const changeTabFn = () => {
            setActiveTab(activeKey);
            setEditTab({});
            setIsFormChanged(false);
          };
          if (isFormChange) {
            openWarningModal(() => {
              changeTabFn();
            });
          } else {
            changeTabFn();
          }
        }}
        tabs={[
          {
            title: 'Background Checks',
            content: (
              <>
                <Background {...getTabProps('1')} />
              </>
            ),
          },
          {
            title: 'Business Associations',
            content: (
              <>
                <BusinessAssociations {...getTabProps('2')} />
              </>
            ),
          },
          {
            title: 'Documents',
            content: (
              <>
                <Title text="Documents" nonBorder big />
                <Documents {...getTabProps('3')} />
              </>
            ),
          },
          {
            title: 'Locations',
            content: (
              <>
                <Locations {...getTabProps('4')} />
              </>
            ),
          },
          {
            title: 'Buyout',
            content: (
              <>
                <Title text="Buyout" nonBorder big />
                <Buyout {...getTabProps('5')} />
              </>
            ),
          },
          {
            title: 'Credit Reports',
            content: (
              <>
                <CreditReports {...getTabProps('6')} />
              </>
            ),
          },
          {
            title: 'IFA Alerts',
            content: (
              <>
                <Title text="IFA Alerts" nonBorder big />
                <IFAAlerts {...getTabProps('7')} />
              </>
            ),
          },
          {
            title: 'UCC Filings',
            content: (
              <>
                <UCCFiling {...getTabProps('8')} />
              </>
            ),
          },
        ]}
        className={classes.tabs}
      />
    </>
  );
};

export default UnderwritingInfo;
