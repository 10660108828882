import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as driverSelectors from 'store/selectors/driver';
import * as authSelectors from 'store/selectors/auth';
// ACTIONS
import * as authActions from 'store/actions/auth';
import * as clientDetailActions from 'store/actions/clientDetail';
import * as driverActions from 'store/actions/driver';

export const useIndexData = () => {
  return {
    drivers: driverSelectors.useSelectDrivers(),
    driverLoading: driverSelectors.useSelectDriversLoading(),
    updateDriverLoading: driverSelectors.useSelectUpdateDriverLoading(),
    getDriverAccountLoading: driverSelectors.useSelectDriverAccountLoading(),
    getFuelCardLoading: driverSelectors.useSelectDriverFuelCardLoading(),
    validatePasswordLoading: authSelectors.useSelectValidatePasswordLoading(),
    driverAccount: driverSelectors.useSelectDriverAccount(),
    driverFuelCard: driverSelectors.useSelectDriverFuelCard(),
    createAccountsLoading: driverSelectors.useSelectCreateDriverAccountLoading(),
    createFuelCardsLoading: driverSelectors.useSelectCreateDriverFuelCardLoading(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...clientDetailActions,
    ...authActions,
    ...driverActions,
  });
};
