import React, { useState, useRef, useEffect } from 'react';
import { Select as AntSelect } from 'antd';
import cx from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Checkbox } from 'components/FormFields';
import Tooltip from 'components/Tooltip';
import Button from 'components/Button';
import { applySearch } from 'utils';
import classes from './Select.module.scss';

const customTagRender = (values, multipleText) => props => {
  const { label } = props;
  const valuesLength = (values || []).length;
  if (valuesLength > 1) {
    return (
      <span className="custom-selected-tag">{`(${valuesLength}) ${multipleText ||
        'Selected'}`}</span>
    );
  }
  return <span className="custom-selected-tag">{label}</span>;
};
const getCurrentValues = (value, items, allValue) => {
  const values = String(value || '').includes(allValue) ? [allValue] : value;
  return values;
};
const renderOption = (option, index) => {
  const { tooltip, placement, tooltipWidth } = option;
  const label = option.label || '';
  return (
    <AntSelect.Option
      value={option.value}
      label={option.label}
      key={index}
      className={cx({
        [classes.optionHasTooltip]: !!tooltip,
      })}
    >
      {tooltip ? (
        <Tooltip
          title={tooltip}
          tooltipWidth={tooltipWidth}
          placement={placement || 'leftTop'}
          overlayClassName={classes.tooltipOverlay}
        >
          <span className={classes.tooltipOptionContent}>{label}</span>
        </Tooltip>
      ) : (
        label
      )}
    </AntSelect.Option>
  );
};

let isFetching = false;

const Select = ({
  items,
  fixedItems,
  className,
  selectWrapperClassName,
  dropdownClassName,
  size = 'large',
  mode,
  isCustomMultipleSelected,
  isCustomMultipleSelectedText,
  isMultipleOptionCheckbox,
  allValue,
  prefixIcon,
  disabled,
  dropdownRender,
  addOption = {},
  placeholder,
  placeholderFocus,
  hideArrow,
  innerRef,
  hideAfterSelected,
  customValidateName,
  isBlue,
  seeFullOption,
  lineAtAllOption,
  borderFirstOption,
  asyncLoadMore,
  onDropdownVisibleChange,
  metadata,
  syncLoadMoreData,
  syncLoadData,
  ...extraProps
}) => {
  const selectRef = useRef(null);
  const [isFocus, setIsFocus] = useState(false);
  const [search, setSearch] = useState('');
  const otherProps = {};
  if (isMultipleOptionCheckbox) {
    otherProps.menuItemSelectedIcon = ({ isSelected }) => {
      return <Checkbox checked={isSelected} label="" />;
    };
  }
  if (isCustomMultipleSelected) {
    otherProps.tagRender = customTagRender(
      getCurrentValues(extraProps.value, items, allValue),
      isCustomMultipleSelectedText
    );
  }
  const isHasAllSingle = String(extraProps.value || '').includes(allValue);
  const valMultiple = extraProps.value || [];
  if (allValue) {
    const isHasAllAtLastMultiple =
      valMultiple[valMultiple.length - 1] === allValue;
    const isHasAllAtFirstMultiple = valMultiple[0] === allValue;
    if (mode === 'multiple') {
      if (isHasAllAtLastMultiple) {
        otherProps.value = [allValue];
      }
      if (isHasAllAtFirstMultiple && valMultiple.length > 1) {
        otherProps.value = valMultiple.filter(v => v !== allValue);
      }
    } else {
      if (isHasAllSingle) {
        otherProps.value = [allValue];
      }
    }
  }
  const handleLoadMore = async event => {
    var target = event.target;
    if (
      !isFetching &&
      target.scrollTop + target.offsetHeight > target.scrollHeight - 150 &&
      metadata &&
      metadata.current_page < metadata.last_page
    ) {
      if (syncLoadMoreData) {
        isFetching = true;
        syncLoadMoreData(search, metadata.current_page + 1, () => {
          isFetching = false;
        });
      }
    }
  };
  const handleSearchChange = v => {
    setSearch(v);
    if (syncLoadData) {
      applySearch(v, syncLoadData);
    }
  };
  if (asyncLoadMore) {
    otherProps.filterOption = false;
    otherProps.onSearch = handleSearchChange;
    otherProps.onPopupScroll = handleLoadMore;
  }
  const isHasOnlyAllMultiple =
    (otherProps.value || []).length === 1 &&
    (otherProps.value || [])[0] === allValue;

  useEffect(() => {
    isFetching = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={cx(
        classes.selectWrapper,
        {
          [classes.disabled]: disabled,
          [classes.hideArrow]: hideArrow,
          [classes.isBlue]: isBlue,
          [classes.seeFullOption]: seeFullOption,
          [classes.isAllValue]: isHasOnlyAllMultiple && mode === 'multiple',
        },
        selectWrapperClassName
      )}
    >
      <AntSelect
        filterOption={(inputValue, option) => {
          const childrenContent = get(option, 'children');
          const tChildContent =
            childrenContent || childrenContent >= 0
              ? String(childrenContent)
              : '';
          return (
            tChildContent
              .toLowerCase()
              .includes((inputValue || '').toLowerCase()) ||
            tChildContent === 'All'
          );
        }}
        dropdownRender={
          dropdownRender
            ? dropdownRender
            : !isEmpty(addOption)
            ? menu => (
                <div>
                  <Button
                    type="link"
                    className={classes.addOption}
                    onClick={addOption.onClick}
                  >
                    {addOption.label}
                  </Button>
                  {menu}
                </div>
              )
            : null
        }
        {...extraProps}
        {...otherProps}
        placeholder={
          !!placeholderFocus && isFocus ? placeholderFocus : placeholder
        }
        virtual={false}
        size={size}
        disabled={disabled}
        className={cx(
          classes.wrapper,
          {
            [classes.isMultipleOptionCheckbox]: isMultipleOptionCheckbox,
            [classes.isCustomMultipleSelected]: isCustomMultipleSelected,
            [classes.hasPrefixIcon]: !!prefixIcon,
          },
          className
        )}
        dropdownClassName={cx(dropdownClassName, {
          [classes.dropdownIsMultipleOptionCheckbox]: isMultipleOptionCheckbox,
          [classes.dropdownIsBlue]: isBlue,
          [classes.dropdownSeeFullOption]: seeFullOption,
          [classes.lineAtAllOption]: !!allValue && lineAtAllOption,
          [classes.borderFirstOption]: borderFirstOption,
        })}
        mode={isCustomMultipleSelected ? 'multiple' : mode || ''}
        onChange={val => {
          if (hideAfterSelected && selectRef && selectRef.current) {
            selectRef.current.blur();
          }
          if (extraProps.onChange) {
            extraProps.onChange(val);
          }
          if (val && extraProps.form) {
            extraProps.form.setFields([
              {
                name: customValidateName || extraProps.name,
                errors: null,
              },
            ]);
          }
        }}
        onFocus={e => {
          if (extraProps.onFocus) {
            extraProps.onFocus(e);
          }
          setIsFocus(true);
        }}
        onBlur={e => {
          if (extraProps.onBlur) {
            extraProps.onBlur(e);
          }
          setIsFocus(false);
        }}
        ref={innerRef ? innerRef : selectRef}
        onDropdownVisibleChange={o => {
          if (onDropdownVisibleChange) onDropdownVisibleChange(o);
          if (asyncLoadMore && search) {
            handleSearchChange('');
          }
          if (!o) {
            isFetching = false;
          }
        }}
      >
        {items.map((option, index) => {
          if (option.isGroup) {
            return (
              <AntSelect.OptGroup
                label={option.label}
                value={option.value}
                key={index}
              >
                {option.options.map((childOption, childIndex) => {
                  return renderOption(childOption, childIndex);
                })}
              </AntSelect.OptGroup>
            );
          }
          return renderOption(option, index);
        })}
      </AntSelect>
      <span className="ant-select-prefix-icon">{prefixIcon}</span>
    </div>
  );
};

export default Select;
