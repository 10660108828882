import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import cn from 'classnames';
import { WarningOutlined } from '@ant-design/icons';
import FormListSection from 'views/pages/ClientDetail/components/FormListSection';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import EditBlock from './EditBlock';
import ViewBlock, { ViewHeader } from './ViewBlock';
import classes from './OtherSection.module.scss';

const OtherSection = ({ className, form, other, confirmModal, isEdit }) => {
  const [stateOther, setStateOther] = useState(other || []);
  const [isEditing, setIsEditing] = useState(false);
  const hasData = stateOther.length > 0;
  const handleDelete = i => () => {
    const values = form.getFieldsValue();
    const selectedFieldName = get(values, `other[${i}].field_name`, '');
    confirmModal(
      `Delete ${
        selectedFieldName ? `"${selectedFieldName}"` : 'current field'
      }?`,
      <div>
        Are you sure you want to delete? This item will be permanently deleted
        from {values?.name ? `"${values.name}"` : 'current order type'}.
      </div>,
      () => {
        const values = form.getFieldsValue();
        const valueOther = values.other || [];
        const currentOtherObj = valueOther[i] || {};
        const currentDeleteIds = values.delete_other_fields || [];
        form.setFieldsValue({
          other: valueOther.filter((obj, index) => index !== i),
          delete_other_fields: currentOtherObj.originId
            ? [...currentDeleteIds, currentOtherObj.originId]
            : currentDeleteIds,
        });
        setStateOther(stateOther.filter((obj, index) => index !== i));
      },
      {
        type: 'confirm',
        onCancel: () => {},
        okText: 'Yes, Delete',
        cancelText: 'No Keep',
        centered: true,
        revertButton: true,
        className: classes.confirmationModal,
        icon: <WarningOutlined />,
      }
    );
  };
  useEffect(() => {
    if (!isEqual(other, stateOther)) {
      setStateOther(other);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [other]);
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isEditing]: isEditing,
          [classes.viewMode]: !isEdit,
        },
        className
      )}
    >
      <h2>Other</h2>
      {isEdit && (
        <p>
          You can add additional fields not listed in the Availble Fields
          section.
        </p>
      )}
      {hasData && isEdit && <ViewHeader />}
      <div>
        <InputItem name={'delete_other_fields'} label="" form={form} hide />
        <FormListSection
          setIsEditing={setIsEditing}
          className={classes.formListSection}
          actionButtonsClassName={classes.actionButtons}
          addSettings={{
            showAddOnce: true,
            hideTitleAdding: true,
            unBoxAdding: true,
            addBtnAsLink: true,
            saveButtonRevert: true,
            saveBtnText: 'Add Field',
          }}
          titleClassName={classes.formBlockHeader}
          addOnce
          hideNotFound
          notFoundMessage=""
          isEdit={isEdit}
          form={form}
          isOther
          canSave
          saveLoading={false}
          onSave={(index, cb) => () => {
            const values = form.getFieldsValue();
            const otherObj = get(values, `other[${index}]`) || {};
            const { field_name, category_id, field_type_id } = otherObj;
            if (!field_name || !category_id || !field_type_id) {
              form.setFields([
                {
                  name: ['other', index, 'field_name'],
                  errors: !field_name ? [''] : null,
                },
                {
                  name: ['other', index, 'category_id'],
                  errors: !category_id ? [''] : null,
                },
                {
                  name: ['other', index, 'field_type_id'],
                  errors: !field_type_id ? [''] : null,
                },
              ]);
            } else {
              setStateOther([...stateOther, otherObj]);
              if (cb) cb();
            }
          }}
          data={stateOther || []}
          initialObjFn={arrayLength => {
            return {
              field_name: '',
              category_id: null,
              field_type_id: null,
            };
          }}
          titleField="title"
          name="other"
          addBtnText={
            <>
              <span className={classes.plusIcon}>+</span>Add Field
            </>
          }
          newLabel="New Field"
          renderFields={(index, isExisting, isEdit, setIsEdit, nextIsEdit) => {
            if (isExisting) {
              return (
                <ViewBlock
                  data={stateOther[index]}
                  canEdit
                  isEdit={true}
                  setIsEdit={setIsEdit}
                  nextIsEdit={nextIsEdit}
                  onDelete={handleDelete(index)}
                  index={index}
                  form={form}
                  // onCancel={() => {
                  //   const values = form.getFieldsValue();
                  //   const valueOther = values.other || [];
                  //   form.setFieldsValue({
                  //     other: valueOther.map((otherObj, i) => {
                  //       if (i === index) {
                  //         otherObj.field_name = stateOther[index].field_name;
                  //         otherObj.category_id = stateOther[index].category_id;
                  //         otherObj.field_type_id =
                  //           stateOther[index].field_type_id;
                  //       }
                  //       return otherObj;
                  //     }),
                  //   });
                  // }}
                  // onSave={() => {
                  //   const values = form.getFieldsValue();
                  //   const valueOther = values.other || [];
                  //   const newOtherObj = valueOther[index] || {};
                  //   setStateOther(
                  //     stateOther.map((otherObj, i) => {
                  //       if (i === index) {
                  //         otherObj.field_name = newOtherObj.field_name;
                  //         otherObj.category_id = newOtherObj.category_id;
                  //         otherObj.field_type_id = newOtherObj.field_type_id;
                  //       }
                  //       return otherObj;
                  //     })
                  //   );
                  // }}
                />
              );
            }
            return (
              <EditBlock
                index={index}
                form={form}
                onDelete={handleDelete(index)}
                className={classes.newBlock}
              />
            );
          }}
          renderViews={() => {
            return (
              <div className={classes.viewWrapper}>
                <ViewHeader />
                {stateOther.map((o, i) => {
                  return <ViewBlock key={i} data={o} />;
                })}
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default OtherSection;
