import React from 'react';
import cn from 'classnames';
import { getLatestMessageTime } from 'utils/date';
import { getLatestMessageText } from 'utils/message';
import { searchBy } from 'utils/index';
import { Contact, NotContactFound } from './components/Elements';
import { useClientChatData } from './selectorData';
import classes from './Chat.module.scss';

const ClientChat = ({
  isCollapse,
  handleViewDetail,
  handleMarkUnread,
  clientSearch,
  permission,
}) => {
  const { conversations } = useClientChatData();
  const clientChats = conversations.individual || [];
  const departmentChats = conversations.department || [];
  const searchedClientChats = searchBy(clientChats, clientSearch);
  const searchedDepartmentChats = searchBy(
    departmentChats,
    clientSearch,
    'client_name'
  );
  const noClientContact =
    clientChats.length === 0 && departmentChats.length === 0;
  return (
    <div className={cn(classes.chatContent, classes.clientChat)}>
      {noClientContact ? (
        <NotContactFound
          message="You don’t have any client chats yet. 
Start a new one below!"
          isCollapse={isCollapse}
        />
      ) : (
        <>
          {!permission.isClient && (
            <>
              <Contact isCollapse={isCollapse} hideUnread>
                <span>Department{!isCollapse && ' Chats'}</span>
              </Contact>
              {searchedDepartmentChats.map((d, i) => {
                return (
                  <Contact
                    {...d}
                    key={`${d.conversation_id}_${i}`}
                    isClient
                    unread={d.unread_message_count}
                    message={getLatestMessageText(d, true)}
                    name={d.client_name}
                    avatar={d.client_profile_pic}
                    dateStr={
                      d.created_at ? getLatestMessageTime(d.created_at) : ''
                    }
                    department={d.name}
                    departmentColor={d.name}
                    role={d.company_name || ''}
                    isMultipleAvatar
                    onClick={() => {
                      handleViewDetail({
                        ...d,
                        isIndividual: true,
                        department: d.name,
                      });
                    }}
                    onMarkUnread={handleMarkUnread(d, true)}
                    hideUnread={!d.message_id}
                  />
                );
              })}
              <Contact isCollapse={isCollapse} hideUnread>
                <span>Client{!isCollapse && ' Chats'}</span>
              </Contact>
            </>
          )}

          {clientChats.length === 0 ? (
            <NotContactFound
              message="You don’t have any client chats yet. 
        Start a new one below!"
              isCollapse={isCollapse}
            />
          ) : (
            <>
              {searchedClientChats.map((sClientChat, i) => {
                const sClientChatObj = {
                  ...sClientChat,
                };
                if (permission.showDepartmentAtIndividual) {
                  sClientChatObj.department = sClientChat.group;
                  sClientChatObj.departmentColor = sClientChat.group;
                }
                return (
                  <Contact
                    {...sClientChatObj}
                    key={`${sClientChatObj.conversation_id}_${i}`}
                    isClient={!permission.showDepartmentAtIndividual}
                    avatar={sClientChatObj.profile_pic}
                    unread={sClientChatObj.unread_message_count}
                    message={getLatestMessageText(sClientChatObj)}
                    dateStr={
                      sClientChatObj.created_at
                        ? getLatestMessageTime(sClientChatObj.created_at)
                        : ''
                    }
                    role={sClientChatObj.company_name || ''}
                    onClick={() => {
                      handleViewDetail({
                        ...sClientChatObj,
                        isIndividual: true,
                      });
                    }}
                    onMarkUnread={handleMarkUnread(sClientChatObj)}
                    hideUnread={!sClientChatObj.message_id}
                    isCollapse={isCollapse}
                  />
                );
              })}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ClientChat;
