import React from 'react';
import cn from 'classnames';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
} from 'views/pages/ClientDetail/components/Elements';
import { useIsInternalUser } from 'helper/hooks';
import SecurityField from 'components/SecurityField';
import { BANK_NUMBER_FIELD } from 'helper/const';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { PROFILE_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const Profile = ({
  className,
  isEdit,
  form,
  data,
  disabled,
  isNew,
  clientId,
}) => {
  const { isInternalUser, isExternalOwner } = useIsInternalUser();

  return (
    <div className={cn(classes.profileSection, className)}>
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            {/* Use it to save delete_attachments for attachment removing */}
            <InputItem name={'delete_attachments'} label="" form={form} hide />
            <InputItem
              name={'legal_name'}
              label="Company Legal Name"
              form={form}
              required
              disabled={disabled}
              addressField
            />
            <InputItem
              name={'leasor_due'}
              label="Due to Leasor (%)"
              placeholder="00.00"
              form={form}
              disabled={disabled}
              aptField
              type="price"
            />
            {isInternalUser ? (
              <SecurityField
                fieldObj={{
                  label: 'EIN - Employer Identification Number',
                  value: '********',
                  type_id: data?.id,
                  type: 'leasor',
                  company_id: clientId,
                  key: 'ein',
                  alwaysDisplayView: isNew,
                }}
                viewRender={isShow => {
                  return (
                    <InputItem
                      name={'ein'}
                      label="EIN - Employer Identification Number"
                      placeholder="00-0000000"
                      form={form}
                      disabled={disabled}
                      hide={!isShow}
                    />
                  );
                }}
                pinSuccessCb={val => {
                  form.setFieldsValue({
                    ein: val,
                  });
                }}
              />
            ) : isExternalOwner ? (
              <InputItem
                name={'ein'}
                label="EIN - Employer Identification Number"
                placeholder="00-0000000"
                form={form}
                disabled={disabled}
              />
            ) : (
              <div />
            )}
            <InputItem
              name={'lease_agreement'}
              label="Lease Agreement"
              form={form}
              disabled={disabled}
              type="file"
            />
            <InputItem
              name={'dot_number'}
              label="DOT Number"
              type="number"
              placeholder="00000000"
              mask={BANK_NUMBER_FIELD.MASK}
              form={form}
              disabled={disabled}
            />
            <InputItem
              name={'mc_number'}
              label="MC Number"
              type="number"
              placeholder="000000"
              mask={BANK_NUMBER_FIELD.MASK}
              form={form}
              disabled={disabled}
            />
          </FormSection>
        ) : (
          <ListInfo
            objKeys={PROFILE_OBJ_KEYS}
            data={data}
            sType="leasor"
            sCompanyId={clientId}
            needSecure
          />
        )}
      </ElementSection>
    </div>
  );
};

export default Profile;
