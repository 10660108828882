import React from 'react';
import { ReactComponent as LockLogoSvg } from 'assets/icons/LockLogo.svg';
import { ReactComponent as IOSSvg } from 'assets/icons/IOS.svg';
import { ReactComponent as AndroidSvg } from 'assets/icons/Android.svg';
import classes from './MobileLock.module.scss';
import { getMobileOperatingSystem } from 'utils/mobile';

const MobileLock = ({ match }) => {
  const mobileOS = getMobileOperatingSystem();
  const isIOS = mobileOS === 'iOS';
  const mobileAppLink = isIOS
    ? 'https://apps.apple.com/us/app/roadoffice/id6470522307'
    : 'https://play.google.com/store/apps/details?id=com.integrity_factoring ';

  return (
    <div className={classes.wrapper}>
      <div className={classes.inner}>
        <LockLogoSvg className={classes.logo} />
        <p>Welcome!</p>
        <span>
          For the best experience,
          <br />
          please download our mobile app.
        </span>
        <div className={classes.mobileAppWrapper}>
          {isIOS ? <IOSSvg /> : <AndroidSvg />}
          <a href={mobileAppLink} rel="noreferrer">
            Download Mobile App
          </a>
        </div>
      </div>
    </div>
  );
};

export default MobileLock;
