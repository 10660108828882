import React from 'react';

import { Select } from 'components/FormFields';
import Space from 'components/Space';

const SelectComponent = () => {
  return (
    <Space direction="vertical">
      <Space direction={'horizontal'} size={32}>
        <Select
          defaultValue="3"
          size={'large'}
          items={[
            { value: '1', label: 'Large Select' },
            { value: '2', label: 'Large Select Two', disabled: true },
            { value: '3', label: 'Large Select Three', disabled: true },
            { value: '4', label: 'Large Select Four' },
          ]}
        />
        <Select
          defaultValue="3"
          size={'large'}
          items={[
            {
              isGroup: true,
              label: 'Group 1 option',
              options: [{ value: '1', label: 'Large Select' }],
            },
            { value: '2', label: 'Large Select Two' },
            { value: '3', label: 'Large Select Three' },
            { value: '4', label: 'Large Select Four' },
          ]}
        />
        <Select
          defaultValue="1"
          items={[
            { value: '1', label: 'Medium Select' },
            { value: '2', label: 'Medium Select Two' },
            { value: '3', label: 'Medium Select Three' },
            { value: '4', label: 'Medium Select Four' },
          ]}
        />
        <Select
          defaultValue="1"
          size={'small'}
          items={[
            { value: '1', label: 'Small Select' },
            { value: '2', label: 'Small Select Two' },
            { value: '3', label: 'Small Select Three' },
            { value: '4', label: 'Small Select Four' },
          ]}
        />
      </Space>
      <Space direction={'horizontal'} size={32}>
        <Select
          defaultValue="1"
          size={'large'}
          disabled
          items={[
            { value: '1', label: 'Large Select' },
            { value: '2', label: 'Large Select Two' },
            { value: '3', label: 'Large Select Three' },
            { value: '4', label: 'Large Select Four' },
          ]}
        />
        <Select
          defaultValue="1"
          disabled
          items={[
            { value: '1', label: 'Medium Select' },
            { value: '2', label: 'Medium Select Two' },
            { value: '3', label: 'Medium Select Three' },
            { value: '4', label: 'Medium Select Four' },
          ]}
        />
        <Select
          defaultValue="1"
          disabled
          size={'small'}
          items={[
            { value: '1', label: 'Small Select' },
            { value: '2', label: 'Small Select Two' },
            { value: '3', label: 'Small Select Three' },
            { value: '4', label: 'Small Select Four' },
          ]}
        />
      </Space>
    </Space>
  );
};

export default SelectComponent;
