import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import { getStandardNameFromObj } from 'utils/text';
import { CHAT_TYPES } from 'helper/const';

export const isMatchedContactSearch = (name, keyword) => {
  const keywordLowerCase = (keyword || '').toLowerCase();
  const nameLowerCase = (name || '').toLowerCase();
  return (
    keywordLowerCase.length < 2 ||
    (keywordLowerCase.length >= 2 && nameLowerCase.includes(keywordLowerCase))
  );
};
export const getDetailObjFromStartNew = ({
  selectedContacts,
  currentUser,
  isClientTab,
}) => {
  const currentSelectedContact = [...selectedContacts, currentUser];
  const isMoreThan3Contacts = currentSelectedContact.length > 3;
  const name =
    selectedContacts.length === 1
      ? getStandardNameFromObj(selectedContacts[0])
      : `${currentSelectedContact
          .filter((c, i) => i <= 2)
          .map(c => c.first_name)
          .join(', ')}${isMoreThan3Contacts ? '...' : ''}`;
  const isIndividual = selectedContacts.length === 1;
  const type = isClientTab
    ? 'client_individual'
    : isIndividual
    ? CHAT_TYPES.INTERNAL_INDIVIDUAL
    : CHAT_TYPES.INTERNAL_CUSTOM;
  const individualMember = selectedContacts[0];
  const individualInfo = {
    group: individualMember.department,
    profile_pic: individualMember.profile_pic,
    role: individualMember.company_name || '',
  };
  return {
    isIndividual,
    name,
    contacts: currentSelectedContact,
    ids: currentSelectedContact.map(c => c.id),
    type,
    ...(isIndividual ? individualInfo : {}),
    createChatData: {
      members: isIndividual
        ? selectedContacts[0]?.id || ''
        : selectedContacts.map(c => c.id),
      name,
      type,
      // text: 'Hello! Welcome to this chat',
    },
  };
};
export const getCheckConversationBD = (selectedContact, isClient) => {
  return {
    members: (selectedContact || []).map(u => u.id),
    type: isClient
      ? 'client_individual'
      : selectedContact.length > 1
      ? CHAT_TYPES.INTERNAL_CUSTOM
      : 'internal_individual',
  };
};
export const getDefaultDepartmentFilters = (departments, forceFalse) => {
  const defaultFilters = {};
  departments.map(({ id }) => {
    defaultFilters[id] = forceFalse ? false : true;
    return true;
  });
  return defaultFilters;
};
export const getDefaultCompanyFilters = (departments, forceFalse) => {
  const defaultFilters = {};
  departments.map(({ id }) => {
    defaultFilters[id] = forceFalse ? false : true;
    return true;
  });
  return defaultFilters;
};
export const getFilteredIds = obj => {
  return Object.keys(obj)
    .filter(k => obj[k])
    .map(k => k);
};
export const getIsDFilter = (defaultVal, currentVal) => {
  return isEqual(defaultVal, currentVal);
};
export const getIsDSort = (defaultVal, currentVal) => {
  return defaultVal === currentVal;
};
export const filterDepartments = departments => {
  if (departments && departments.length > 0) {
    const adminDepartmentNameSlug = 'administration';
    const adminDepartmentObj =
      find(departments, { slug: adminDepartmentNameSlug }) || {};
    return [
      adminDepartmentObj,
      ...orderBy(
        departments.filter(d => d.slug !== adminDepartmentNameSlug),
        'name',
        'asc'
      ),
    ];
  }
  return [];
};
