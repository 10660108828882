import {
  useReducerData,
  useReducerTotal,
  useReducerLoading,
  useReducerIsMore,
} from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// COMPANY_TEAM_LIST
export const useSelectCompanyTeamListLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.COMPANY_TEAM_LIST
  );
};
export const useSelectCompanyTeamListTotal = () => {
  return useReducerTotal(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.COMPANY_TEAM_LIST,
    0
  );
};
export const useSelectCompanyTeamListIsMore = () => {
  return useReducerIsMore(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.COMPANY_TEAM_LIST
  );
};
export const useSelectCompanyTeamList = () => {
  return useReducerData(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.COMPANY_TEAM_LIST
  );
};
// ALL_TEAMS
export const useSelectAllTeamsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.ALL_TEAMS
  );
};
export const useSelectAllTeamsTotal = () => {
  return useReducerTotal(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.ALL_TEAMS,
    0
  );
};
export const useSelectAllTeamsIsMore = () => {
  return useReducerIsMore(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.ALL_TEAMS
  );
};
export const useSelectAllTeams = () => {
  return useReducerData(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.ALL_TEAMS
  );
};
// IN_ACTIVE_TEAM
export const useSelectInActiveTeamLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.IN_ACTIVE_TEAM
  );
};
export const useSelectInActiveTeamTotal = () => {
  return useReducerTotal(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.IN_ACTIVE_TEAM,
    0
  );
};
export const useSelectInActiveTeamIsMore = () => {
  return useReducerIsMore(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.IN_ACTIVE_TEAM
  );
};
export const useSelectInActiveTeam = () => {
  return useReducerData(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.IN_ACTIVE_TEAM
  );
};
// GET_DEPARTMENT_TEAM
export const useSelectDepartmentTeamLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.DEPARTMENT_TEAM
  );
};
export const useSelectDepartmentTeamTotal = () => {
  return useReducerTotal(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.DEPARTMENT_TEAM,
    0
  );
};
export const useSelectDepartmentTeamIsMore = () => {
  return useReducerIsMore(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.DEPARTMENT_TEAM
  );
};
export const useSelectDepartmentTeam = () => {
  return useReducerData(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.DEPARTMENT_TEAM
  );
};

// INVITE_TEAMMATE
export const useSelectInviteTeammateLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.INVITE_TEAMMATE
  );
};
export const useSelectResendInviteTeammateLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.RESEND_INVITE_TEAMMATE
  );
};
// INVITE_COMPANY_TEAM_MEMBER
export const useSelectInviteCompanyTeamMemberLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.INVITE_COMPANY_TEAM_MEMBER
  );
};
// UPDATE_COMPANY_TEAM_MEMBER
export const useSelectUpdateCompanyTeamMemberLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.UPDATE_COMPANY_TEAM_MEMBER
  );
};
// UPDATE_COMPANY_TEAM_MEMBER_STATUS
export const useSelectUpdateCompanyTeamMemberStatusLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.MY_TEAM,
    REDUCER_ATTRIBUTES.MY_TEAM.UPDATE_COMPANY_TEAM_MEMBER_STATUS
  );
};
