import get from 'lodash/get';

/**
 * Example action usage
  export const dummyAsyncReduxEvent = params => {
    return {
      type: "DUMMY_REDUX_ASYNC",
      payload: params,
      asyncCall: () => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({ a: 'hello', b: 'world' });
          }, 2000);
        });
      },
      afterSuccessCall: () => {},
    };
  };
 *
 */
const asyncActionCreator = ({ dispatch }) => next => async action => {
  const {
    isAsyncCall,
    baseType,
    asyncCall,
    payload = {},
    afterSuccessCall = () => {},
    afterFailureCall = () => {},
  } = action;

  if (!isAsyncCall) {
    return next(action);
  }

  if (typeof asyncCall !== 'function') {
    throw new Error('Expected asyncCall to be a function.');
  }

  try {
    dispatch({
      type: `${baseType}_REQUEST`,
      payload,
    });
    const response = await asyncCall(dispatch);
    const { status } = response || {};
    if (status) {
      dispatch({
        type: `${baseType}_SUCCESS`,
        payload,
        response: response || {},
      });
      afterSuccessCall(dispatch, response);
    } else {
      dispatch({
        type: `${baseType}_FAILURE`,
        payload,
        error: response,
      });
      afterFailureCall(dispatch, response);
    }
  } catch (error) {
    const errorObj = get(error, 'response.data') || {};
    dispatch({
      type: `${baseType}_FAILURE`,
      payload,
      error: errorObj,
    });
    afterFailureCall(dispatch, errorObj, get(error, 'response'));
  }
};

export default asyncActionCreator;
