import appTypes from './app';
import authTypes from './auth';
import sharedTypes from './shared';
import orderTypes from './order';
import orderTypeTypes from './orderType';
import myTeamTypes from './myTeam';
import teammateDetailTypes from './teammateDetail';
import chatTypes from './chat';
import conversationDetailTypes from './conversationDetail';
import chatInformationTypes from './chatInformation';
import clientTypes from './client';
import clientDetailTypes from './clientDetail';
import formsMSATypes from './formsMSA';
import truckTypes from './truck';
import trailerTypes from './trailer';
import driverTypes from './driver';
import leasorTypes from './leasor';
import customFieldTypes from './customField';
import paymentSourceTypes from './paymentSource';
import underwritingTypes from './underwriting';
import intakeFormTypes from './intakeForm';
import clientOrderDetailTypes from './clientOrderDetail';
import workQueueTypes from './workQueue';
import reminderTypes from './reminder';
import checklistTypes from './checklist';
import notificationTypes from './notification';
import reportTypes from './report';

export const asyncActionTypeRequest = baseAction => `${baseAction}_REQUEST`;
export const asyncActionTypeSuccess = baseAction => `${baseAction}_SUCCESS`;
export const asyncActionTypeFailure = baseAction => `${baseAction}_FAILURE`;

export {
  appTypes,
  authTypes,
  sharedTypes,
  orderTypeTypes,
  myTeamTypes,
  teammateDetailTypes,
  chatTypes,
  conversationDetailTypes,
  chatInformationTypes,
  clientTypes,
  clientDetailTypes,
  formsMSATypes,
  truckTypes,
  trailerTypes,
  driverTypes,
  leasorTypes,
  customFieldTypes,
  paymentSourceTypes,
  underwritingTypes,
  intakeFormTypes,
  orderTypes,
  clientOrderDetailTypes,
  workQueueTypes,
  reminderTypes,
  checklistTypes,
  notificationTypes,
  reportTypes,
};
