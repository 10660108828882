import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import {
  InfoCircleFilled,
  QuestionCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import cn from 'classnames';
import { Divider } from 'antd';
import { RadioGroup, Select } from 'components/FormFields';
import { FormItem } from 'components/Form';
import Popover from 'components/Popover';
import { getCurrencyValue } from 'utils/number';
import { scrollToErrorField, validateForm } from 'utils/form';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { FormSection } from 'views/pages/ClientDetail/components/Elements';
import NewCardForm from './NewCardForm';
import NewAccountForm from './NewAccountForm';
import classes from '../../NewOrderModal.module.scss';
import { paymentValidateObj, ACCOUNTS_LIST, CARDS_LIST } from '../../utils';
import { PAYMENT_METHODS } from '../../constants';
import { useNewOrderModalData } from '../../../../selectorData';

const PaymentsStep = forwardRef(
  ({ form, style, getBankName, currentStep, permission, cardError }, ref) => {
    const formPaymentMethod = form.getFieldValue('payment_method');
    const [paymentMethod, setPaymentMethod] = useState(formPaymentMethod);
    const { accounts, cards, orderTypeDetail } = useNewOrderModalData();
    const paymentMethodList = tPaymentMethod => {
      const paymentMethods =
        tPaymentMethod === 'account'
          ? ACCOUNTS_LIST(accounts)
          : CARDS_LIST(cards);
      paymentMethods.push({
        label: (
          <>
            Add a New Account <PlusCircleOutlined />
          </>
        ),
        value: 0,
      });
      return paymentMethods;
    };
    useImperativeHandle(ref, () => ({
      validateStep(cb) {
        const values = form.getFieldsValue();
        const fieldErrors = validateForm(
          paymentValidateObj(values, permission),
          values,
          true
        );
        if (fieldErrors.length > 0) {
          form.setFields(fieldErrors);
          scrollToErrorField();
        } else {
          cb(true);
        }
      },
    }));

    useEffect(() => {
      const formPaymentMethodId = form.getFieldsValue()['payment_method_id'];
      if (formPaymentMethodId === null || formPaymentMethodId === undefined) {
        const initialPaymentMethod = paymentMethodList(paymentMethod);
        if (initialPaymentMethod.length) {
          form.setFieldsValue({
            payment_method_id: initialPaymentMethod[0].value,
          });
        }
      }
      const previousPrice = form.getFieldsValue()?.price;
      if (currentStep === 2 && orderTypeDetail.price && !previousPrice) {
        form.setFieldsValue({
          price: orderTypeDetail.price,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep]);
    return (
      <div
        style={style}
        className={cn(classes.stepContent, classes.paymentWrapper)}
      >
        <div className={classes.paymentInnerContent}>
          <div className={classes.paymentInputs}>
            <FormItem
              name={'payment_method'}
              label="Select your payment method."
              form={form}
            >
              <RadioGroup
                name={'payment_method'}
                items={PAYMENT_METHODS}
                onChange={e => {
                  setPaymentMethod(e.target.value);
                  form.setFieldsValue({
                    payment_method: e.target.value,
                  });
                  const paymentMethod = paymentMethodList(e.target.value);
                  if (paymentMethod.length) {
                    form.setFieldsValue({
                      payment_method_id: paymentMethod[0].value,
                    });
                  }
                }}
                value={paymentMethod}
              />
            </FormItem>
            <FormItem shouldUpdate noMargin>
              {() => {
                const thisPaymentMethod = form.getFieldsValue()[
                  'payment_method'
                ];
                return (
                  <FormItem
                    className={classes.paymentMethod}
                    name={'payment_method_id'}
                    label="Payment Method"
                    required
                  >
                    <Select
                      name="payment_method_id"
                      placeholder="Payment Method"
                      form={form}
                      className={classes.paymentMethodDropDown}
                      dropdownClassName={classes.paymentMethodDropDown}
                      size="medium"
                      items={paymentMethodList(thisPaymentMethod)}
                    />
                  </FormItem>
                );
              }}
            </FormItem>
          </div>
          <div className={classes.paymentOverview}>
            <div className={classes.paymentOverviewInnerContent}>
              <div className={classes.subtotal}>
                <label>Order Price</label>
                <InputItem
                  name="price"
                  className={classes.orderPriceInput}
                  label=""
                  type="price"
                  prefix=" "
                  disabled={!permission.editOrderPrice}
                  form={form}
                  placeholder="Order Price"
                />
              </div>
              <div className={classes.rushOrder}>
                <InputItem
                  name={'rush_order'}
                  className={classes.rushOrderInput}
                  type={'checkbox'}
                  label={
                    <>
                      Rush Order (+ $
                      {orderTypeDetail.rush_amount
                        ? orderTypeDetail.rush_amount.toFixed(2)
                        : '0.00'}
                      )
                    </>
                  }
                />
                <Popover
                  placement={'bottomLeft'}
                  overlayClassName={classes.rushOrderInfo}
                  content={
                    <>
                      <InfoCircleFilled />
                      <p>
                        We start all orders within 24 hours. If you would like
                        us to start on your order within an hour please select
                        the Rush Order option.
                      </p>
                    </>
                  }
                  trigger={'hover'}
                >
                  <QuestionCircleOutlined
                    className={classes.infoIcon}
                    size={'large'}
                  />
                </Popover>
              </div>
              <div className={classes.total}>
                <span>Total</span>
                <span>
                  <FormItem shouldUpdate>
                    {() => {
                      const values = form.getFieldsValue();
                      const orderPrice = Number(values.price);
                      const rushAmount = !!values.rush_order?.length
                        ? orderTypeDetail.rush_amount
                        : 0;
                      return <>{getCurrencyValue(orderPrice + rushAmount)}</>;
                    }}
                  </FormItem>
                </span>
              </div>
            </div>
          </div>
        </div>
        <FormItem shouldUpdate noMargin>
          {() => {
            const thisPaymentMethod = form.getFieldsValue()['payment_method'];
            const thisPaymentMethodId = form.getFieldsValue()[
              'payment_method_id'
            ];
            return (
              <>
                {thisPaymentMethodId === 0 && (
                  <div className={classes.newPaymentMethodWrapper}>
                    <Divider />
                    {thisPaymentMethod === 'account' ? (
                      <NewAccountForm
                        getBankName={getBankName}
                        form={form}
                        index={'account'}
                      />
                    ) : (
                      <NewCardForm
                        form={form}
                        index={'card'}
                        cardError={cardError}
                      />
                    )}
                  </div>
                )}
              </>
            );
          }}
        </FormItem>
        <Divider />
        <FormSection className={classes.noteField}>
          <InputItem
            name={'notes'}
            label="Notes"
            form={form}
            fullWidth
            type="textarea"
          />
        </FormSection>
      </div>
    );
  }
);

export default PaymentsStep;
