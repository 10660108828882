import { useEffect } from 'react';
import moment from 'moment';
import { scrollChatContentToBottom } from 'utils/index';
import { useActions, useChatData } from './selectorData';

const useLoadChatData = ({ detailObj, setDetailObj }) => {
  const { conversationDetail, resetConversationDetail } = useChatData();
  const {
    syncSetMessages,
    syncSetChatMembers,
    getConversationDetail,
    getChatMembers,
    getMessages,
    syncSetActiveConversation,
    syncSetResetActiveConversation,
  } = useActions();
  const conversationId = detailObj?.conversation_id || '';
  useEffect(() => {
    syncSetMessages([], { replaceAll: true });
    syncSetChatMembers([], { replaceAll: true });
    syncSetActiveConversation(conversationId ? detailObj : {});
    syncSetResetActiveConversation(null);
    if (conversationId) {
      getConversationDetail(conversationId);
      getChatMembers(conversationId);
      getMessages(
        conversationId,
        {
          conversation_id: conversationId,
        },
        res => {
          scrollChatContentToBottom();
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);
  useEffect(() => {
    if (
      detailObj.name !== conversationDetail.name &&
      detailObj.conversation_id === conversationDetail.id &&
      conversationDetail.name &&
      detailObj.name
    ) {
      setDetailObj({
        ...detailObj,
        name: conversationDetail.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationDetail]);
  useEffect(() => {
    if (resetConversationDetail === false || resetConversationDetail === true) {
      setDetailObj({});
      syncSetResetActiveConversation(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetConversationDetail]);
};

/**
 * useNewMessage hook to get new message object base on text
 * @param text
 * @returns new message object
 */
const useNewMessage = ({ text }) => {
  const { conversationDetail, currentUser, messages } = useChatData();
  return {
    id: ((messages[messages.length - 1] || {}).id || new Date().getTime()) + 1,
    owner: currentUser.id,
    owner_profile_pic: currentUser.profile_pic,
    owner_name: `${currentUser.first_name} ${currentUser.last_name}`,
    text,
    attachments: [],
    group: currentUser.group_name,
    conversation_id: conversationDetail.id,
    created_at: moment().format(),
  };
};

export { useLoadChatData, useNewMessage };
