import React, { useEffect, useState, useRef } from 'react';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { WarningOutlined, InfoCircleFilled } from '@ant-design/icons';
import Modal, { useModal } from 'components/Modal';
import Form, { useForm } from 'components/Form';
import Steps, { Step } from 'components/Steps';
import toast from 'helper/toast';
import { confirmModalBlueConfig, getConfirmModal } from 'utils/modal';
import OrderTypeStep from './steps/OrderType';
import OrderDetailsStep from './steps/OrderDetails';
import PaymentsStep from './steps/Payments';
import ConfirmStep from './steps/Confirm';
import { useActions, useNewOrderModalData } from '../../selectorData';
import {
  getModalProps,
  getInitialValues,
  prepareFormData,
  orderTypeValidateObj,
  prefillFormEditMode,
  getCardBR,
} from './utils';
import { steps } from './constants';
import classes from './NewOrderModal.module.scss';
import { scrollToErrorField, validateForm } from '../../../../../utils/form';
import { actionCb } from 'utils/action';

const NewOrderModal = ({
  clientId,
  isOpen,
  setActiveTab,
  handleClose,
  orderDetailData,
  isEditOrder,
  orderDetailFieldValues,
  onSubmitOrderCb,
  onSaveDraft,
  onSubmitOrder,
  onSaveDraftCb,
  onSubmitOrderICb,
  // prefillData is fields array of ant-form which can use form.setFields
  prefillData,
  fromReminder,
  permission,
}) => {
  const [form] = useForm();
  const {
    getAccounts,
    getFuelCards,
    saveOrderAsDraft,
    submitOrder,
    getOrderList,
    getBankName,
    createFuelCard,
  } = useActions();
  const { detailData } = useNewOrderModalData();
  const [addItems, setAddItems] = useState({});
  const [cardError, setCardError] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [orderTypeId, setOrderTypeId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formDetailsFieldPopulated, setFormDetailsFieldPopulated] = useState(
    false
  );
  const [modal, contextHolder] = useModal();
  const confirmModal = getConfirmModal(modal, confirmModalBlueConfig);
  const orderTypeStep = useRef();
  const orderDetailsStep = useRef();
  const paymentStep = useRef();
  const confirmStep = useRef();
  const handleCardError = (err, onSubmit, onClose) => {
    const tMessage = err?.details || '';
    setCardError(tMessage);
    const messageArr = tMessage.split(': ');
    confirmModal(
      messageArr[0] || '',
      <div>{messageArr[1] || ''}</div>,
      () => {
        if (onSubmit) onSubmit();
      },
      {
        width: 295,
        type: 'confirm',
        visible: true,
        okText: 'Update',
        cancelText: 'Close',
        onCancel: () => {
          if (onClose) onClose();
        },
        centered: true,
        icon: <InfoCircleFilled />,
        className: classes.cardDeclineModal,
      }
    );
  };
  const navigateToPreviousStep = step => {
    if (step === undefined) {
      step = currentStep - 1;
    }
    setCurrentStep(step);
  };
  const saveDraft = () => {
    const values = form.getFieldsValue();
    const fieldErrors = validateForm(orderTypeValidateObj, values);
    if (fieldErrors.length > 0) {
      form.setFields(fieldErrors);
      scrollToErrorField();
    } else {
      const formData = prepareFormData(
        form,
        isEditOrder ? orderDetailData : {},
        false,
        addItems
      );
      if (onSaveDraft) {
        onSaveDraft(formData, () => {});
      } else {
        setLoading(true);
        saveOrderAsDraft(detailData.id, formData, (response, error) => {
          setOrderTypeId(null);
          setStepDescription(0, '');
          form.resetFields();
          setLoading(false);
          if (!response) {
            toast.error(error.error);
            handleClose();
            form.resetFields();
            return;
          }
          if (onSaveDraftCb) onSaveDraftCb();
          getOrderList(detailData.id, { highlight: response.data.id }, () => {
            toast.success('Draft saved successfully.');
            handleClose();
            setActiveTab(2);
            getAccounts(clientId);
            getFuelCards(clientId);
          });
        });
      }
    }
  };
  const submitOrderFn = tPayload => {
    submitOrder(detailData.id, tPayload, (response, error) => {
      setOrderTypeId(null);
      setStepDescription(0, '');
      form.resetFields();
      setLoading(false);
      if (!response) {
        toast.error(error.message || error.title);
        handleClose();
        form.resetFields();
        return;
      }
      if (onSubmitOrderICb) onSubmitOrderICb();
      getOrderList(detailData.id, { highlight: response.data.id }, () => {
        toast.success('Order submitted successfully.');
        handleClose();
        setActiveTab(2);
        getAccounts(clientId, {}, accountRes => {
          getFuelCards(clientId, {}, cardRes => {
            onSubmitOrderCb({
              accounts: accountRes?.data || [],
              cards: cardRes?.data || [],
            });
          });
        });
      });
    });
  };
  const submitForm = () => {
    const formData = prepareFormData(
      form,
      isEditOrder ? orderDetailData : {},
      fromReminder,
      addItems,
      true
    );
    if (onSubmitOrder) {
      onSubmitOrder(formData, () => {});
    } else {
      setLoading(true);
      const values = form.getFieldsValue();
      const isNewCard = !values.payment_method_id && !isEmpty(values.card);
      setCardError('');
      if (isNewCard) {
        createFuelCard(
          detailData.id,
          { payment_cards: [getCardBR(values.card)] },
          actionCb(
            res => {
              const tPaymentId = get(res, 'data[0].id');
              formData.append('payment_method_id', tPaymentId);
              submitOrderFn(formData);
            },
            err => {
              setLoading(false);
              handleCardError(
                err,
                () => {
                  navigateToPreviousStep();
                },
                () => {}
              );
            },
            true
          )
        );
      } else {
        formData.append('payment_method_id', values.payment_method_id);
        submitOrderFn(formData);
      }
    }
  };
  const handleNextClick = () => {
    const modalSteps = [
      orderTypeStep,
      orderDetailsStep,
      paymentStep,
      confirmStep,
    ];
    if (
      modalSteps[currentStep].current &&
      modalSteps[currentStep].current.validateStep
    ) {
      modalSteps[currentStep].current.validateStep(
        (isValidated, isLastStep) => {
          if (!isValidated) {
            return;
          }
          if (!isLastStep) {
            navigateToNextStep();
          } else {
            submitForm();
          }
        }
      );
    }
  };
  const closeModal = () => {
    confirmModal(
      `Leave Creating New Order?`,
      <div>
        You have started creating a new order. Are you sure you want to leave
        this page?
      </div>,
      () => {
        setOrderTypeId(null);
        setStepDescription(0, '');
        form.resetFields();
        handleClose();
      },
      {
        width: 423,
        type: 'confirm',
        visible: true,
        onCancel: () => {},
        okText: 'Yes, Leave',
        cancelText: 'No, Stay',
        centered: true,
        revertButton: true,
        icon: <WarningOutlined />,
      }
    );
  };
  useEffect(() => {
    if (isOpen && form) {
      setCardError('');
      setAddItems({});
      setCurrentStep(0);
      setStepDescription(0, '');
      form.resetFields();
      form.validateFields();
      setFormDetailsFieldPopulated(false);
      if (!!prefillData && isArray(prefillData)) {
        form.setFields(prefillData);
      }
    }
    if (isOpen) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  useEffect(() => {
    if (isOpen && form && isEditOrder) {
      // TODO: calculate for addItems and prepopulate data
      prefillFormEditMode(orderDetailFieldValues, form, setAddItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetailFieldValues]);
  const navigateStep = step => {
    setCurrentStep(step);
  };
  const navigateToNextStep = step => {
    if (step === undefined) {
      step = currentStep + 1;
    }
    setCurrentStep(step);
  };
  const setStepDescription = (step, description) => {
    steps[step].description = description;
  };
  const modalProps = getModalProps({
    isOpen,
    form,
    handleNextClick,
    saveDraft,
    closeModal,
    loading,
    currentStep,
    navigateToPreviousStep,
  });
  const stepProps = {
    form,
    setStepDescription,
    currentStep,
    orderTypeId,
    setOrderTypeId,
    getBankName,
    formDetailsFieldPopulated,
    setFormDetailsFieldPopulated,
    permission,
    addItems,
    setAddItems,
  };
  return (
    <Modal {...modalProps}>
      <Form
        submitHidden
        initialValues={getInitialValues(isEditOrder ? orderDetailData : {})}
        form={form}
        className={classes.form}
      >
        <div className="ant-modal-body-inner">
          <Steps onChange={navigateStep} current={currentStep}>
            {steps.map((item, index) => (
              <Step
                className={classes.orderModalStep}
                key={item.title}
                title={item.title}
                description={item.description}
                disabled={index >= currentStep}
              />
            ))}
          </Steps>
          <OrderTypeStep
            style={{ display: currentStep === 0 ? 'block' : 'none' }}
            ref={orderTypeStep}
            {...stepProps}
            isEditOrder={isEditOrder}
          />
          <OrderDetailsStep
            style={{ display: currentStep === 1 ? 'block' : 'none' }}
            ref={orderDetailsStep}
            {...stepProps}
            isEditOrder={isEditOrder}
          />
          <PaymentsStep
            style={{ display: currentStep === 2 ? 'block' : 'none' }}
            ref={paymentStep}
            cardError={cardError}
            {...stepProps}
          />
          <ConfirmStep
            style={{ display: currentStep === 3 ? 'block' : 'none' }}
            ref={confirmStep}
            {...stepProps}
          />
        </div>
      </Form>
      {contextHolder}
    </Modal>
  );
};
export default NewOrderModal;
