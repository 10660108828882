import Request from './request';

export const apiGetDrivers = async (id, filters = {}) => {
  return Request.call({
    url: `/company/${id}/drivers/list`,
    method: 'GET',
    params: filters,
  });
};
export const apiGetDriverAccount = async (companyId, driverId, accountId) => {
  return Request.call({
    url: `/company/${companyId}/drivers/${driverId}/accounts/${accountId}`,
    method: 'GET',
  });
};
export const apiGetDriverFuelCard = async (companyId, driverId, cardId) => {
  return Request.call({
    url: `/company/${companyId}/fuel-cards/${driverId}/${cardId}?type=driver`,
    method: 'GET',
  });
};

export const apiCreateUpdateDriver = async (id, data) => {
  return Request.call({
    url: `/company/${id}/drivers`,
    method: 'POST',
    data,
  });
};
export const apiUpdateDriverStatus = async (companyId, driverId, data) => {
  return Request.call({
    url: `/company/${companyId}/drivers/${driverId}/status`,
    method: 'POST',
    data,
  });
};
export const apiCreateDriverAccount = async (companyId, driverId, data) => {
  return Request.call({
    url: `/company/${companyId}/drivers/${driverId}/accounts`,
    method: 'POST',
    data,
  });
};
export const apiUpdateDriverAccount = async (
  companyId,
  driverId,
  accountId,
  data
) => {
  return Request.call({
    url: `/company/${companyId}/drivers/${driverId}/accounts/${accountId}`,
    method: 'POST',
    data,
  });
};
export const apiCreateDriverFuelCard = async (companyId, driverId, data) => {
  return Request.call({
    url: `/company/${companyId}/fuel-cards/${driverId}`,
    method: 'POST',
    data,
  });
};
export const apiUpdateDriverFuelCard = async (
  companyId,
  driverId,
  cardId,
  data
) => {
  return Request.call({
    url: `/company/${companyId}/fuel-cards/${driverId}/${cardId}`,
    method: 'POST',
    data,
  });
};
