import React from 'react';
import { Menu as AntdMenu } from 'antd';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';

import Link from 'components/Link';
import { isValidPath } from 'utils/routes';

import classes from './Menu.module.scss';

const MenuItem = ({ children, ...other }) => {
  return <AntdMenu.Item {...other}>{children}</AntdMenu.Item>;
};

const mainMenuItem = (item, key, className) => {
  const itemEl = (
    <>
      {item.icon}
      <span className="item-child-text">{item.label}</span>
    </>
  );
  return (
    <MenuItem {...item.props} key={key} className={className}>
      {!!item.to ? <Link to={item.to}>{itemEl}</Link> : itemEl}
    </MenuItem>
  );
};

const subMenuItem = (subs, i, item) => {
  return (
    <AntdMenu.SubMenu
      title={subs.label}
      icon={subs.icon}
      key={subs.key ? subs.key : isValidPath(item.to) ? item.to : `${i}`}
      className={cx(classes.subMenu)}
    >
      {(subs.items || []).map((sub, subIndex) => {
        if (sub.subs) {
          return subMenuItem(sub.subs, subIndex, sub);
        }
        if (sub.title) {
          return (
            <AntdMenu.ItemGroup key={`g${i}${subIndex}`} title={sub.title}>
              {(sub.subItems || []).map((subItem, subItemIdex) => {
                return mainMenuItem(
                  subItem,
                  isValidPath(subItem.to)
                    ? subItem.to
                    : `${i}${subIndex}${subItemIdex}`,
                  cx(classes.subMenuItem, {
                    'sub-menu-selected': subItem.selected,
                    'item-disabled': subItem.disabled,
                  })
                );
              })}
            </AntdMenu.ItemGroup>
          );
        }
        return mainMenuItem(
          sub,
          isValidPath(sub.to) ? sub.to : `${i}${subIndex}`,
          cx(classes.subMenuItem, {
            'sub-menu-selected': sub.selected,
            'item-disabled': sub.disabled,
          })
        );
      })}
    </AntdMenu.SubMenu>
  );
};

const Menu = props => {
  const { menus, className, isDropdown, isCollapsed, ...other } = props;

  return (
    <AntdMenu
      {...other}
      className={cx(classes.wrapper, className)}
      mode={isDropdown ? 'vertical' : 'inline'}
    >
      {menus.map((item, i) => {
        const { subs = {} } = item;
        if (!isEmpty(subs)) {
          return subMenuItem(subs, i, item);
        } else {
          return mainMenuItem(
            item,
            isValidPath(item.to) ? item.to : i,
            cx({
              'menu-selected': item.selected,
              'item-disabled': item.disabled,
              'item-collapsed': isCollapsed,
            })
          );
        }
      })}
    </AntdMenu>
  );
};

Menu.defaultProps = {
  isCollapsed: false,
  menus: [
    {
      to: '#',
      icon: null,
      label: 'Menu 1',
      props: {},
    },
    {
      to: '#',
      icon: null,
      label: 'Menu 2',
      props: {},
      disabled: true,
    },
    {
      subs: {
        label: 'Sub Menu',
        items: [
          {
            to: '#',
            icon: null,
            label: 'Sub Menu Item 1',
            props: {},
            selected: true,
          },
          {
            to: '#',
            icon: null,
            label: 'Sub Menu Item 2',
            props: {},
            disabled: true,
          },
          {
            to: '#',
            icon: null,
            label: 'Sub Menu Item 3',
            props: {},
          },
        ],
      },
    },
  ],
  className: '',
};

Menu.propTypes = {
  menus: PropTypes.array,
  className: PropTypes.any,
  isCollapsed: PropTypes.bool,
};

export default Menu;
