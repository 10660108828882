import React, { forwardRef, useImperativeHandle } from 'react';
import { WarningOutlined } from '@ant-design/icons';
import cn from 'classnames';
import find from 'lodash/find';
import { useModal } from 'components';
import { scrollToErrorField, validateForm } from 'utils/form';
import { FormItem } from 'components/Form';
import { confirmModalBlueConfig, getConfirmModal } from 'utils/modal';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { FormSection } from 'views/pages/ClientDetail/components/Elements';
import { useNewOrderModalData, useActions } from '../../../../selectorData';
import { orderTypeValidateObj } from '../../utils';
import classes from '../../NewOrderModal.module.scss';
import styles from './OrderType.module.scss';

const OrderTypeStep = forwardRef(
  (
    {
      form,
      style,
      setStepDescription,
      orderTypeId,
      setOrderTypeId,
      setFormDetailsFieldPopulated,
      setAddItems,
      isEditOrder,
    },
    ref
  ) => {
    const { orderTypes, orderRequesters, detailData } = useNewOrderModalData();
    const { getOrderDetailFields, getOrderTypeDetail } = useActions();
    const [modal, contextHolder] = useModal();
    const confirmModal = getConfirmModal(modal, confirmModalBlueConfig);
    const confirmChangeOrderType = () => {
      confirmModal(
        `Change Order Type?`,
        <div>
          Are you sure you want to change the order type? Saved inputs will be
          discarded.
        </div>,
        () => {},
        {
          width: 423,
          type: 'confirm',
          visible: true,
          onCancel: () => {
            form.setFieldsValue({
              order_type_id: orderTypeId,
            });
          },
          onOk: () => {
            setAddItems({});
          },
          okText: 'Yes, Change',
          cancelText: 'No, Cancel',
          centered: true,
          revertButton: true,
          icon: <WarningOutlined />,
        }
      );
    };
    useImperativeHandle(ref, () => ({
      validateStep(cb) {
        const values = form.getFieldsValue();
        const fieldErrors = validateForm(orderTypeValidateObj, values);
        if (fieldErrors.length > 0) {
          form.setFields(fieldErrors);
          scrollToErrorField();
        } else {
          if (orderTypeId !== values.order_type_id) {
            getOrderTypeDetail(values.order_type_id, response => {
              if (!response) {
                return;
              }
              const nextCb = () => {
                setOrderTypeId(values.order_type_id);
                setFormDetailsFieldPopulated(false);
                const orderType = find(orderTypes, {
                  value: values.order_type_id,
                });
                setStepDescription(0, orderType.label);
                cb(true);
              };
              if (
                !isEditOrder ||
                (orderTypeId && orderTypeId !== values.order_type_id)
              ) {
                getOrderDetailFields(
                  detailData.id,
                  values.order_type_id,
                  response => {
                    if (!response) {
                      return;
                    }
                    nextCb();
                  }
                );
              } else {
                nextCb();
              }
            });
          } else {
            cb(true);
          }
        }
      },
    }));
    return (
      <div style={style} className={cn(classes.stepContent)}>
        <p>Select the order type you’d like to create.</p>
        <div className={styles.orderTypeSection}>
          <div>
            <FormSection>
              <InputItem
                name={'order_type_id'}
                label="Order Type"
                form={form}
                type="select"
                options={orderTypes}
                required
                onChange={() => {
                  const values = form.getFieldsValue();
                  if (orderTypeId && orderTypeId !== values.order_type_id) {
                    confirmChangeOrderType();
                  } else {
                    setAddItems({});
                  }
                }}
              />
            </FormSection>
            <FormSection>
              <InputItem
                name={'requested_by'}
                label="Order Requested By"
                form={form}
                type="select"
                options={orderRequesters}
                required
              />
            </FormSection>
          </div>
          <div>
            <FormItem shouldUpdate>
              {() => {
                const values = form.getFieldsValue();
                const foundOrderType =
                  find(orderTypes, { value: values.order_type_id }) || {};
                return (
                  <span className={styles.description}>
                    {!!values.order_type_id &&
                      (foundOrderType.description || '')}
                  </span>
                );
              }}
            </FormItem>
          </div>
        </div>
        {/* <FormSection>
          <InputItem
            name={'notes'}
            label="Notes"
            form={form}
            fullWidth
            type="textarea"
          />
        </FormSection> */}
        {contextHolder}
      </div>
    );
  }
);

export default OrderTypeStep;
