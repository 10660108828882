import React from 'react';
import moment from 'moment';
import Form, { FormItem } from 'components/Form';
import SecurityField from 'components/SecurityField';
import { Item } from 'views/pages/MyTeam/components/InviteTeammateModal';
import ProfilePicture from 'views/pages/MyProfile/ProfilePicture';
import { getOptions } from 'utils/options';
import { useOptions, useIsInternalUser } from 'helper/hooks';
import { useEditFormData } from './selectorData';
import classes from './Detail.module.scss';

export const getIsDisplayField = permission => key => {
  return permission.detailFields.includes(key);
};
export const getFieldLabel = permission => (key, defaultVal) => {
  return (
    permission.detailFieldLabels[permission.detailFields.indexOf(key)] ||
    defaultVal
  );
};

const EditForm = ({
  onSubmit,
  initialValues,
  form,
  isEdit,
  confirmModal,
  avatarFinalSrc,
  setAvatarFinalSrc,
  finalFile,
  setFinalFile,
  setIsFormChanged,
  permission,
}) => {
  const inviteTeamAsClient = permission.inviteTeamAsClient || false;
  const isDisplayField = key => {
    return getIsDisplayField(permission)(key);
  };
  const fieldLabel = (key, defaultVal) => {
    return getFieldLabel(permission)(key, defaultVal);
  };
  const { departments, detailData } = useEditFormData();
  const { departmentOptions, roleOptions } = useOptions();
  const { isInternalUser, isExternalOwner } = useIsInternalUser();
  return (
    <div className={classes.editForm}>
      <Form
        onFinish={onSubmit}
        initialValues={initialValues}
        form={form}
        className={classes.form}
        submitHidden
        onValuesChange={() => {
          setIsFormChanged(true);
        }}
      >
        <ProfilePicture
          isEdit={isEdit}
          confirmModal={confirmModal}
          avatarFinalSrc={avatarFinalSrc}
          setAvatarFinalSrc={setAvatarFinalSrc}
          finalFile={finalFile}
          setFinalFile={setFinalFile}
          alowAllFile
          onDelete={cb => {
            setAvatarFinalSrc('');
            if (cb) cb();
          }}
        />
        <div className={classes.formInner}>
          <Item
            name={'first_name'}
            label="First Name"
            required
            autoFocus={true}
            form={form}
          />
          <div className={classes.customItem}>
            <Item name={'mi'} label="M.I" form={form} />
            <Item name={'last_name'} label="Last Name" required form={form} />
          </div>
          {isDisplayField('email') && (
            <Item
              name={'email'}
              label={fieldLabel('email', 'Email Address')}
              placeholder="example@email.com"
              required
              form={form}
            />
          )}
          {isDisplayField('alternate_email') && (
            <Item
              name={'alternate_email'}
              label={fieldLabel(
                'alternate_email',
                'Alternative / Personal Email'
              )}
              placeholder="example@email.com"
              form={form}
            />
          )}
          {isDisplayField('phone_number') && (
            <Item
              name={'phone_number'}
              label={fieldLabel('phone_number', 'Phone Number')}
              type="phone"
              required
              form={form}
            />
          )}
          {isDisplayField('dob') && (
            <>
              {isInternalUser ? (
                <SecurityField
                  fieldObj={{
                    label: fieldLabel('dob', 'Date of Birth'),
                    value: '********',
                    type_id: detailData?.id,
                    type: 'official',
                    key: 'dob',
                  }}
                  viewRender={isShow => {
                    if (isShow) {
                      return (
                        <Item
                          name={'dob'}
                          label={fieldLabel('dob', 'Date of Birth')}
                          type="date"
                          form={form}
                        />
                      );
                    }
                    return <></>;
                  }}
                  pinSuccessCb={val => {
                    const tVal = !!val && moment(val).isValid() ? val : '';
                    form.setFieldsValue({
                      dob: tVal,
                    });
                  }}
                />
              ) : isExternalOwner ? (
                <Item
                  name={'dob'}
                  label={fieldLabel('dob', 'Date of Birth')}
                  type="date"
                  form={form}
                />
              ) : (
                <div />
              )}
            </>
          )}
          {isDisplayField('group_id') && (
            <Item
              name={'group_id'}
              label={fieldLabel('group_id', 'Department')}
              type="select"
              required
              form={form}
              options={departmentOptions}
              onChange={() => {
                form.setFieldsValue({
                  role_id: null,
                });
              }}
            />
          )}
          {isDisplayField('role_id') && (
            <FormItem shouldUpdate className={classes.shouldUpdateItem}>
              {() => {
                const values = form.getFieldsValue();
                const { positionOptions } = getOptions({
                  departments,
                  selectedDepartment: inviteTeamAsClient
                    ? initialValues.group_id
                    : values.group_id,
                });
                return (
                  <Item
                    name={'role_id'}
                    label={fieldLabel('role_id', 'Position')}
                    type="select"
                    required
                    form={form}
                    options={
                      inviteTeamAsClient && !initialValues.group_id
                        ? roleOptions
                        : positionOptions
                    }
                  />
                );
              }}
            </FormItem>
          )}
          {isDisplayField('start_date') && (
            <Item
              name={'start_date'}
              label={fieldLabel('start_date', 'Start Date')}
              type="date"
              required
              form={form}
            />
          )}
          {isDisplayField('employee_id') && (
            <Item
              name={'employee_id'}
              label={fieldLabel('employee_id', 'Employee ID')}
              form={form}
            />
          )}
        </div>
      </Form>
    </div>
  );
};

export default EditForm;
