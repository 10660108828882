const initialTrailerState = {
  // GET
  trailers: {
    loading: false,
    data: [],
    error: '',
  },
  // POST
  createTrailer: {
    loading: false,
    data: {},
    error: '',
  },
  updateTrailer: {
    loading: {},
    data: {},
    error: {},
  },
  updateTrailerStatus: {
    loading: {},
    data: {},
    error: {},
  },
};

export { initialTrailerState };
