import React from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { StandardTooltipColumn } from 'components/Table';
import Tag from 'components/Tag';
import { getStrWithFormat } from 'utils/textFormat';
import { getFEDate } from 'utils/date';
import { getStandardNameFromObj } from 'utils/text';
import { getCurrencyValue } from 'utils/number';
import { STATUS_MAPPING } from 'helper/const';

// Some tables we will have custom dropdown at the last column
// So we need to check if we click into td of table, we will trigger onRowClick as cb
// Otherwise we should not trigger anything, it will trigger custom dropdown of last column.
const onEditRow = (e, cb) => {
  const classList = get(e.target, 'classList.value');
  if (
    e &&
    (e.target.tagName === 'TD' ||
      e.target.tagName === 'B' ||
      classList.includes('standard-column') ||
      classList.includes('clickable') ||
      classList.includes('format-column')) &&
    !classList.includes('ant-table-selection-column') &&
    cb
  ) {
    cb();
  }
};
// standard render function of table have 3 params (value, record, index)
// So to custom with format, we need to check typeof format is string, because record always be a object
// Because it is standard render function, so we do not need to use record object, just need to use value
const renderStandardColumn = (value, format) => {
  if (typeof format === 'string' && format && value) {
    const displayValue = getStrWithFormat(value, format);
    return (
      <StandardTooltipColumn tooltip={displayValue}>
        {displayValue}
      </StandardTooltipColumn>
    );
  }
  const displayValue = isArray(value)
    ? value.length > 0
      ? value.join(', ')
      : '-'
    : (value || value === 0) && value !== 'null'
    ? value
    : '-';
  return (
    <StandardTooltipColumn tooltip={displayValue}>
      {displayValue}
    </StandardTooltipColumn>
  );
};

// render Tag column
const renderTagColumn = (_, record, onClick, statusMapping) => {
  const { status } = record;
  if (!status) return '-';
  const statusObj = (statusMapping || STATUS_MAPPING)[status] || {
    label: status,
  };
  if (!isEmpty(statusObj)) {
    return (
      <Tag
        borderSameColor
        color={statusObj.color}
        onClick={typeof onClick === 'function' ? onClick : null}
      >
        {statusObj.label}
      </Tag>
    );
  }
  return status || '-';
};

// render name with 3 attrs: first_name, last_name, middle_name
const renderNameColumn = (_, record) => {
  const value = getStandardNameFromObj(record);
  return <StandardTooltipColumn tooltip={value}>{value}</StandardTooltipColumn>;
};
// render date with date format
const renderDateColumn = (value, formatParam) => {
  if (!value) return '-';
  const format = typeof formatParam === 'string' ? formatParam : '';
  return (
    <StandardTooltipColumn tooltip={getFEDate(value, false, format)}>
      {getFEDate(value, false, format)}
    </StandardTooltipColumn>
  );
};
// render price column
const renderPriceColumn = value => {
  if (typeof value === 'object' || (!value && value !== 0)) {
    return '-';
  }
  let val = getCurrencyValue(value);
  return <StandardTooltipColumn tooltip={val}>{val}</StandardTooltipColumn>;
};

const isAvailablePrice = val => {
  return val === 0 || val > 0;
};
const getPriceValue = record => {
  const { price_type, price_low, price, price_high } = record || {};
  if (
    price_type === 'range' &&
    isAvailablePrice(price_low) &&
    isAvailablePrice(price_high)
  ) {
    return `$${price_low || 0} - $${price_high || 0}`;
  }
  const singlePrice = price || 0;
  if (isAvailablePrice(singlePrice)) return `$${singlePrice}`;
  return '';
};

export {
  onEditRow,
  renderStandardColumn,
  renderPriceColumn,
  renderNameColumn,
  renderDateColumn,
  renderTagColumn,
  getPriceValue,
};
