const getInvitedUserCopyString = user => {
  return `Name: ${user.name}\nURL: ${user.url || ''}\nUsername: ${
    user.username
  }\nPassword: ${user.password}`;
};
const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};
const getRandomString = (charset, length) => {
  let returnChar = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    returnChar += charset.charAt(Math.floor(Math.random() * n));
  }
  return returnChar;
};
const generatePassword = () => {
  var upperChar = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var lowerChar = 'abcdefghijklmnopqrstuvwxyz';
  var numberChar = '0123456789';
  var specialChar = '!@#$%&';
  return `${getRandomString(upperChar, 1)}${getRandomString(
    lowerChar,
    5
  )}${getRandomString(numberChar, 1)}${getRandomString(specialChar, 1)}`;
};
const getStandardNameFromObj = obj => {
  const { first_name, last_name, middle_name } = obj;
  return `${first_name ? `${first_name} ` : ''}${
    middle_name ? `${middle_name} ` : ''
  }${last_name ? `${last_name}` : ''}`;
};
const trimStr = val => {
  if (!val) return '';
  return val.trim();
};
const selectAvatarAsWords = name => {
  const nameArr = (name || '').split(' ');
  let returnName = '';
  const first = nameArr[0];
  const second = nameArr[1];
  if (!second) {
    returnName = `${first[0]}${first[1] || first[0]}`;
  } else if (first) {
    returnName = `${first[0]}${second[0]}`;
  }
  return returnName.toUpperCase();
};
const capitalizeFirstLetter = string => {
  return (string || '').charAt(0).toUpperCase() + string.slice(1);
};
const capitalizeWholeString = val => {
  if (!val && typeof val !== 'string') return val || '';
  const valArr = val.split(' ').map(v => capitalizeFirstLetter(v));
  return valArr.join(' ');
};
const getWordLength = val => {
  if (!val) return 0;
  return val
    .trim()
    .replace(/  +/g, ' ')
    .split(' ').length;
};
const getLastCharOfText = (val, n = 1) => {
  if (!val || typeof val !== 'string') return '';
  return val.substr(val.length - n);
};

export {
  copyToClipboard,
  getInvitedUserCopyString,
  generatePassword,
  getStandardNameFromObj,
  trimStr,
  selectAvatarAsWords,
  capitalizeFirstLetter,
  capitalizeWholeString,
  getWordLength,
  getLastCharOfText,
};
