import React from 'react';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import {
  Section,
  FormSection,
  ListInfo,
} from 'views/pages/ClientDetail/components/Elements';
import { PROFILE_OBJ_KEYS } from './constants';

const Profile = ({ isEdit, form, data }) => {
  return (
    <Section>
      {isEdit ? (
        <FormSection>
          {/* Use it to save delete_attachments for attachment removing */}
          <InputItem name={'delete_attachments'} label="" form={form} hide />
          <InputItem name={'fsa'} label="FSA" form={form} type="file" />
          <InputItem
            name={'transportation_rider'}
            label="Transportation Rider"
            form={form}
            type="file"
          />
          <InputItem
            name={'8821_form'}
            label="8821 Form"
            form={form}
            type="file"
          />
          <InputItem
            name={'construction_rider'}
            label="Construction Rider"
            form={form}
            type="file"
          />
          <InputItem
            name={'ucc_filing'}
            label="UCC Filing"
            form={form}
            type="file"
          />
          <InputItem
            name={'non_recourse_rider'}
            label="Non Recourse Rider"
            form={form}
            type="file"
          />
        </FormSection>
      ) : (
        <ListInfo objKeys={PROFILE_OBJ_KEYS} data={data} />
      )}
    </Section>
  );
};

export default Profile;
