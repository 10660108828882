import { useReducerData, useReducerLoading } from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// CLIENT_DETAIL
export const useSelectClientDetailLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.CLIENT_DETAIL
  );
};
export const useSelectClientDetail = () => {
  return useReducerData(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.CLIENT_DETAIL,
    {}
  );
};
// CLIENT_DETAIL_LOG
export const useSelectClientDetailLogLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.CLIENT_DETAIL_LOG
  );
};
export const useSelectClientDetailLog = () => {
  return useReducerData(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.CLIENT_DETAIL_LOG,
    {}
  );
};
// COMPANY_OFFICIALS
export const useSelectCompanyOfficialsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.COMPANY_OFFICIALS
  );
};
export const useSelectCompanyOfficials = () => {
  return useReducerData(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.COMPANY_OFFICIALS,
    []
  );
};
// UPDATE_SALES_INFO
export const useSelectUpdateSalesInfoLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.UPDATE_SALES_INFO
  );
};
// UPDATE_COMPANY
export const useSelectUpdateCompanyLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.UPDATE_COMPANY
  );
};
// UPDATE_FILLING
export const useSelectUpdateFillingLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.UPDATE_FILLING
  );
};
// UPDATE_INSURANCE
export const useSelectUpdateInsuranceLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.UPDATE_INSURANCE
  );
};
// MANAGE_LOGO
export const useSelectManageLogoLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.MANAGE_LOGO
  );
};
// ADD_LOG
export const useSelectAddLogLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.ADD_LOG
  );
};
// CREATE_COMPANY_OFFICIAL
export const useSelectCreateCompanyOfficialLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.CREATE_COMPANY_OFFICIAL
  );
};
// UPDATE_COMPANY_OFFICIAL
export const useSelectUpdateCompanyOfficialLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.UPDATE_COMPANY_OFFICIAL,
    {}
  );
};
// UPDATE_COMPANY_OFFICIAL_STATUS
export const useSelectUpdateCOStatusLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.UPDATE_COMPANY_OFFICIAL_STATUS,
    {}
  );
};
// SEARCH_SAFER
export const useSelectSearchSaferLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.SEARCH_SAFER,
    {}
  );
};
// DOT_SEARCH_SAFER
export const useSelectDOTSearchSaferLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.DOT_SEARCH_SAFER,
    {}
  );
};
// SAVE_DOT
export const useSelectSaveDOTLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.SAVE_DOT,
    {}
  );
};
// DOT_CLASSIFICATIONS
export const useSelectDOTClassificationsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.DOT_CLASSIFICATIONS,
    false
  );
};
export const useSelectDOTClassifications = () => {
  return useReducerData(
    REDUCER_NAMES.CLIENT_DETAIL,
    REDUCER_ATTRIBUTES.CLIENT_DETAIL.DOT_CLASSIFICATIONS,
    {}
  );
};
