import React from 'react';
import { WarningOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import cn from 'classnames';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import toast from 'helper/toast';
import { getErrorMessageFromObj } from 'utils/object';
import { standardRequiredFieldObj } from 'utils/form';
import { FORM_VALIDATION_MESSAGES } from 'helper/const';
import {
  ALL_NODE_KEY,
  REQUIRED,
  OPTIONAL,
  DEFAULT_REQUIRED,
} from './constants';
import classes from './NewOrderType.module.scss';

const titleHasSearch = (title, search) => {
  return (title || '').toLowerCase().includes(search);
};
const makeRightNotes = (
  rightNodes,
  treeNodes,
  checkedKeys,
  searchValueLC,
  forceChild,
  forceParentKey,
  childLevel
) => {
  treeNodes
    .filter(d => checkedKeys.includes(d.key) && d.key !== ALL_NODE_KEY)
    .map(({ children, key, title, add, isSub }) => {
      const childNodes = (children || []).filter(d =>
        checkedKeys.includes(d.key)
      );
      const childNodesSearch = (children || []).filter(
        d =>
          checkedKeys.includes(d.key) && titleHasSearch(d.title, searchValueLC)
      );
      const matchedChild = childNodesSearch.length > 0;
      const matchParent = titleHasSearch(title, searchValueLC);
      if (matchParent || matchedChild) {
        const thisChildNode = {
          key,
          title,
          add,
          isSub,
        };
        if (forceChild) {
          thisChildNode.isChild = true;
          thisChildNode.childLevel = childLevel;
        }
        if (forceParentKey) {
          thisChildNode.parent = forceParentKey;
        }
        const filteredChildNodes =
          matchedChild && !matchParent ? childNodesSearch : childNodes;
        if (filteredChildNodes && filteredChildNodes.length > 0) {
          thisChildNode.hasChild = true;
        }
        if (findIndex(rightNodes, { key }) < 0) {
          rightNodes.push(thisChildNode);
        }
        filteredChildNodes.map(
          ({ key: keyC, title: titleC, add: addC, children: cChildren }) => {
            if (cChildren && cChildren.length > 0) {
              makeRightNotes(
                rightNodes,
                filteredChildNodes,
                checkedKeys,
                searchValueLC,
                true,
                key,
                key.split('-').length - 1
              );
            } else {
              if (findIndex(rightNodes, { key: keyC }) < 0) {
                rightNodes.push({
                  key: keyC,
                  title: titleC,
                  isChild: true,
                  childLevel: key.split('-').length - 1,
                  parent: key,
                  add: addC,
                });
              }
            }
            return true;
          }
        );
      }
      return true;
    });
  return rightNodes;
};
export const generateTree = (
  treeNodes = [],
  checkedKeys = [],
  direction,
  parentKey,
  searchValueLC
) => {
  if (direction === 'left') {
    const leftNodes = [];
    const selectAllNode = {
      ...treeNodes[findIndex(treeNodes, { key: ALL_NODE_KEY })],
    };
    treeNodes
      .filter(n => n.key !== ALL_NODE_KEY)
      .map(({ children, key, title, add }) => {
        const childNodes = generateTree(
          children,
          checkedKeys,
          direction,
          key,
          ''
        );
        const childNodesSearch = generateTree(
          children,
          checkedKeys,
          direction,
          key,
          searchValueLC
        );
        const matchedChild = childNodesSearch.length > 0;
        const matchParent = titleHasSearch(title, searchValueLC);
        if (matchParent || matchedChild) {
          leftNodes.push({
            key,
            title,
            add,
            children:
              matchedChild && !matchParent ? childNodesSearch : childNodes,
            parent: parentKey,
          });
        }
        return true;
      });
    if (
      !isEmpty(selectAllNode) &&
      (!searchValueLC || (searchValueLC && leftNodes.length > 0))
    ) {
      leftNodes.unshift({
        key: selectAllNode.key,
        title: selectAllNode.title,
        add: selectAllNode.add,
      });
    }
    return leftNodes;
  }
  const rightNodes = [];
  makeRightNotes(rightNodes, treeNodes, checkedKeys, searchValueLC);
  return rightNodes;
};
export const getSelectedCount = (dataSource, targetKeys) => {
  const totalTrees = generateTree(dataSource, targetKeys, 'right', '', '');
  return totalTrees.filter(n => n.isChild && !n.isSub).length;
};
export const flatten = (list = []) => {
  const transferDataSource = [];
  list.forEach(item => {
    transferDataSource.push(item);
    flatten(item.children);
  });
  return transferDataSource;
};
export const getRadioInputValue = nodeData => {
  let input = '';
  const parentElement = get(nodeData, 'nativeEvent.target.parentElement');
  const targetClasslist = get(
    nodeData,
    'nativeEvent.target.classList.value',
    ''
  );
  if (
    targetClasslist.includes('ant-radio') &&
    parentElement &&
    !targetClasslist.includes('ant-radio-wrapper')
  ) {
    input = parentElement.querySelector('input');
  }

  return input;
};
export const validateObj = () => {
  return {
    name: [
      {
        ...standardRequiredFieldObj,
        message: FORM_VALIDATION_MESSAGES.REQUIRED,
      },
    ],
    department_id: [
      {
        ...standardRequiredFieldObj,
        message: FORM_VALIDATION_MESSAGES.REQUIRED,
      },
    ],
    price: [
      {
        ...standardRequiredFieldObj,
        message: FORM_VALIDATION_MESSAGES.REQUIRED,
      },
    ],
  };
};
const getFieldsData = (prefix, fields) => {
  return (fields || []).map(c => {
    return {
      key: `${prefix}${c.id}`,
      title: c.name,
      add: null,
      id: c.id,
      isChild: true,
    };
  });
};
export const makeData = (fieldCategories, prefixParam, isSub) => {
  const treeData = fieldCategories.map(fc => {
    const prefix = prefixParam || '0-';
    const childKey = `${prefix}${fc.id}`;
    return {
      key: childKey,
      id: fc.id,
      title: fc.name,
      add: null,
      isSub,
      children: [
        ...getFieldsData(`${childKey}-`, fc.fields),
        ...makeData(fc.sub_categories || [], `${childKey}-`, true),
      ],
    };
  });
  if (!prefixParam) {
    treeData.unshift({
      key: ALL_NODE_KEY,
      title: 'Select/Deselect All',
      add: null,
    });
  }
  return treeData;
};
export const getIdFromKey = key => {
  const keyArr = (key || '').split('-');
  return keyArr[keyArr.length - 1] || '';
};
export const getBodyRequest = (treeData, targetKeys, values, halfKeys) => {
  const rightTree = generateTree(treeData, targetKeys, 'right', null, '');
  const {
    checklist,
    delete_checklist,
    other,
    delete_other_fields,
    delete_attachments,
    ...otherAttrs
  } = values || {};
  const otherFields = other || [];
  const bodyRequest = {
    ...omit(otherAttrs, ['price', 'internal_documentation']),
    selected_fields: rightTree
      .filter(
        t =>
          !halfKeys.includes(t.key) &&
          getIdFromKey(t.key) &&
          !t.isSub &&
          !t.hasChild
      )
      .map(t => {
        return {
          field_id: getIdFromKey(t.key),
          is_required: t.add === REQUIRED ? 1 : 0,
        };
      }),
    checklist: (checklist || []).map((checklist, i) => {
      const returnObj = {
        description: checklist.name || '',
        order: i + 1,
      };
      if (checklist.originId) {
        returnObj.id = checklist.originId;
      }
      return returnObj;
    }),
    delete_checklist: delete_checklist || [],
    other_fields: otherFields.map(obj => {
      const returnObj = {
        name: obj.field_name,
        category_id: obj.category_id,
        field_type_id: obj.field_type_id,
      };
      if (!!obj.originId) {
        returnObj.id = obj.originId;
      }
      return returnObj;
    }),
    delete_other_fields: delete_other_fields || [],
    delete_documents: delete_attachments || [],
  };
  bodyRequest.price = values.price || 0;
  return bodyRequest;
};
const findHasChild = (nodes, hasChild, fieldIds) => {
  nodes.map(node => {
    if (fieldIds.includes(node.id) && !node.isSub && hasChild[0] !== true) {
      hasChild[0] = true;
    }
    if (node.children && node.children.length > 0) {
      findHasChild(node.children, hasChild, fieldIds);
    }
    return true;
  });
};
const treeHasChild = (treeNode = {}, fieldIds = []) => {
  const hasChild = [];
  if (treeNode.children && treeNode.children.length > 0) {
    findHasChild(treeNode.children, hasChild, fieldIds);
  }
  return !!hasChild[0];
};
const updateAllChildren = (nodes, fieldIds, fields) => {
  return nodes.map(node => {
    if (node.isChild && !node.isSub && fieldIds.includes(node.id)) {
      const thisField = fields[fieldIds.indexOf(node.id)] || {};
      node.add = thisField.is_required ? REQUIRED : OPTIONAL;
    } else if (node.children && node.children.length > 0) {
      updateAllChildren(node.children, fieldIds, fields);
    }
    return node;
  });
};
const filterAllChildren = (nodes, fieldIds, fields) => {
  return nodes
    .filter(cNode => {
      return cNode.children
        ? treeHasChild(cNode, fieldIds)
        : fieldIds.includes(cNode.id);
    })
    .map(node => {
      if (node.children && node.children.length > 0) {
        node.children = filterAllChildren(node.children, fieldIds, fields);
      }
      if (fieldIds.includes(node.id)) {
        node.add = fields[fieldIds.indexOf(node.id)].is_required
          ? REQUIRED
          : OPTIONAL;
      }
      return node;
    });
};
export const filterTreeData = (treeData = [], fields = []) => {
  const newTrees = [];
  const fieldIds = fields.map(f => f.field_id);
  treeData.map(tree => {
    if (tree.id) {
      const isTreeHasChild = treeHasChild(tree, fieldIds);
      if (isTreeHasChild) {
        const newTree = { ...tree };
        if (newTree.children) {
          newTree.children = filterAllChildren(
            newTree.children,
            fieldIds,
            fields
          );
        }
        newTrees.push(newTree);
      }
    }
    return true;
  });
  return newTrees;
};
export const updatedTreeData = (treeData = [], fields = []) => {
  const fieldIds = fields.map(f => f.field_id);
  return treeData.map(tree => {
    if (tree.id) {
      tree.children = updateAllChildren(tree.children, fieldIds, fields);
      // TODO: update for parent
      // if (isAllRequired || isAllOptional) {
      //   tree.add = isAllRequired ? REQUIRED : OPTIONAL;
      // }
    }
    return tree;
  });
};
const getAllInitFields = (fieldCategories, allInitFields) => {
  fieldCategories.map(cat => {
    if (cat.fields) {
      cat.fields.map(f => {
        allInitFields.push(f);
        return true;
      });
    } else if (cat.sub_categories && cat.sub_categories.length > 0) {
      getAllInitFields(cat.sub_categories, allInitFields);
    }
    return true;
  });
};
const getHalfKeyOfOneNode = (node, rootKey, targetKeys, halfKeys) => {
  const childNodes = node.children || [];
  if (childNodes.length > 0) {
    const allChildChecked =
      childNodes.filter(node => !targetKeys.includes(node.key)).length === 0;
    if (!allChildChecked) {
      halfKeys.push(rootKey);
    }
    childNodes.map(cNode => {
      getHalfKeyOfOneNode(cNode, rootKey, targetKeys, halfKeys);
      return true;
    });
  }
};
const getHalfKeysOfAllNodes = (nodes, targetKeys, halfKeys) => {
  nodes.map(node => {
    if (targetKeys.includes(node.key)) {
      getHalfKeyOfOneNode(node, node.key, targetKeys, halfKeys);
      if (node.children && node.children.length > 0) {
        getHalfKeysOfAllNodes(node.children, targetKeys, halfKeys);
      }
    }
    return true;
  });
};
const getTargetForChildKeys = (targetKeys, nodes, halfKeys) => {
  nodes.map(node => {
    targetKeys.push(node.key);
    if (node.children && node.children.length > 0) {
      getTargetForChildKeys(targetKeys, node.children, halfKeys);
    }
    return true;
  });
};
export const getExistingDataFromTreeData = (
  treeData = [],
  initTreeData,
  fields,
  fieldCategories
) => {
  const allInitFields = [];
  getAllInitFields(fieldCategories, allInitFields);
  const targetKeys = [];
  const halfKeys = [];
  let isAllSelected = false;
  let isAllRequired = false;
  let isAllOptional = false;
  if (allInitFields.length !== fields.length - 1) {
    isAllSelected = false;
    isAllRequired = false;
    isAllOptional = false;
  } else {
    isAllSelected = true;
    isAllRequired = fields.filter(f => f.is_required).length === fields.length;
    isAllOptional = fields.filter(f => !f.is_required).length === fields.length;
  }
  treeData.map(tree => {
    targetKeys.push(tree.key);
    const initTreeNode = find(initTreeData, { key: tree.key }) || {};
    if (tree.children && tree.children.length > 0) {
      getTargetForChildKeys(targetKeys, tree.children, halfKeys, initTreeNode);
    }
    getHalfKeyOfOneNode(initTreeNode, tree.key, targetKeys, halfKeys);
    if (initTreeNode.children) {
      getHalfKeysOfAllNodes(initTreeNode.children, targetKeys, halfKeys);
    }
    return true;
  });
  if (isAllSelected) {
    targetKeys.push(ALL_NODE_KEY);
  }
  return { targetKeys, halfKeys, isAllRequired, isAllOptional };
};
export const getInitialValues = (orderTypeDetail, isDetail) => {
  const editObj = isDetail ? orderTypeDetail : {};
  const {
    name,
    description,
    price,
    department_id,
    checklists,
    other_fields,
    internal_documentation,
  } = editObj;
  const initialValues = {
    delete_checklist: [],
    delete_other_fields: [],
    name: name || '',
    department_id: department_id || null,
    description: description || '',
    price: price || null,
    checklist: (checklists || []).map((checklist, index) => ({
      originId: checklist.id,
      id: index + 1,
      name: checklist.description,
    })),
    other: (other_fields || []).map((field, index) => ({
      originId: field.id,
      id: index + 1,
      field_name: field.name,
      category_id: field.category_id,
      field_type_id: field.field_type_id,
    })),
    delete_attachments: [],
    internal_documentation: (internal_documentation || []).map(d => ({
      ...d,
      uid: d.id,
    })),
  };
  return initialValues;
};
export const getCheckedDataOfSelectAllWithSearch = (
  currentTreeData,
  initTreeData,
  stateTreeData,
  currentTargetKeys
) => {
  const oldStateTreeData = [...stateTreeData];
  const targetKeys = [];
  const halfKeys = [];
  currentTreeData
    .filter(n => n.key !== ALL_NODE_KEY)
    .map(n => {
      const initChild =
        (find(initTreeData, { key: n.key }) || {}).children || [];
      targetKeys.push(n.key);
      const isInitChildChecked =
        initChild.filter(c => !currentTargetKeys.includes(c.key)).length === 0;
      if (initChild.length !== n.children.length && !isInitChildChecked) {
        halfKeys.push(n.key);
      }
      const thisNodeIndex = findIndex(oldStateTreeData, { key: n.key });
      (n.children || []).map(c => {
        if (thisNodeIndex >= 0) {
          const thisChildIndex = findIndex(
            oldStateTreeData[thisNodeIndex].children,
            { key: c.key }
          );
          if (thisChildIndex >= 0) {
            oldStateTreeData[thisNodeIndex].children[
              thisChildIndex
            ].add = REQUIRED;
          }
        }
        targetKeys.push(c.key);
        return true;
      });
      return true;
    });
  return { tKeys: targetKeys, hKeys: halfKeys, oTreeData: oldStateTreeData };
};
export const handleConfirm = ({ confirmModal, onDelete }) => {
  confirmModal(
    'Delete Order Type?',
    <div>
      Are you sure you want to delete this order type?
      <br />
      All activity history will be lost.
    </div>,
    () => {
      onDelete();
    },
    {
      type: 'confirm',
      onCancel: () => {},
      okText: 'Delete',
      cancelText: 'Keep',
      centered: true,
      className: cn(classes.confirmationModal, classes.redIconModal),
      icon: <WarningOutlined />,
    }
  );
};
export const getAllSelectedKeys = (keys, treeData, val) => {
  const oldTree = treeData.map(t => {
    t.add = val === DEFAULT_REQUIRED ? REQUIRED : val;
    keys.push(t.key);
    if (t.children && t.children.length > 0) {
      t.children = getAllSelectedKeys(keys, t.children, val);
    }
    return t;
  });
  return oldTree;
};
export const getSelectedKeyWithOption = (
  oldTree,
  key,
  initTreeData,
  uniqSelectChild,
  val
) => {
  const foundNodeIndex = findIndex(oldTree, { key });
  const initChild = (find(initTreeData, { key }) || {}).children || [];
  if (
    foundNodeIndex >= 0 &&
    (uniqSelectChild === 'all' || uniqSelectChild.length === initChild.length)
  ) {
    oldTree[foundNodeIndex].add = val === DEFAULT_REQUIRED ? REQUIRED : val;
  }
  const thisChildren = get(oldTree[foundNodeIndex], 'children') || [];
  if (thisChildren.length > 0) {
    for (let i = 0; i < thisChildren.length; i++) {
      const childKey = thisChildren[i]?.key || '';
      getSelectedKeyWithOption(thisChildren, childKey, initChild, 'all', val);
    }
  }
  return oldTree;
};
export const getUpdatedNodeWithParent = (nodes, key, val, isChild) => {
  return nodes.map(node => {
    if (node.key === key) {
      if (isChild && node.children && node.children.length > 0) {
        node.children = getUpdatedNodeWithChild(node.children, val);
      } else {
        node.add = val === DEFAULT_REQUIRED ? REQUIRED : val;
      }
    } else if (node.children && node.children.length > 0) {
      node.children = getUpdatedNodeWithParent(
        node.children,
        key,
        val,
        isChild
      );
    }
    return node;
  });
};
export const getUpdatedNodeWithChild = (children, val) => {
  return children.map(node => {
    node.add = val === DEFAULT_REQUIRED ? REQUIRED : val;
    if (node.children && node.children.length > 0) {
      node.children = getUpdatedNodeWithChild(node.children, val);
    }
    return node;
  });
};
export const getAllParents = (parent, updatedKeys) => {
  const parents = [];
  const parentSplit = parent.split('-');
  let parentKey = `${parentSplit[0]}-${parentSplit[1]}`;
  parents.push(parentKey);
  if (updatedKeys && !updatedKeys.includes(parentKey)) {
    updatedKeys.push(parentKey);
  }
  for (let i = 2; i < parentSplit.length; i++) {
    parentKey = `${parentKey}-${parentSplit[i]}`;
    parents.push(parentKey);
    if (updatedKeys && !updatedKeys.includes(parentKey)) {
      updatedKeys.push(parentKey);
    }
  }
  return parents;
};
export const displayErrMessage = err => {
  const errorMessage = getErrorMessageFromObj(err)[0];
  toast.error(errorMessage);
};
export const orderTypeActionCb = (cb, id, values, updateOrderTypeDocument) => (
  res,
  err
) => {
  const orderTypeId = id || get(res, 'data') || '';
  const attachmentFiles = (values.internal_documentation || []).filter(
    f => !f.id
  );
  const isNewDocument = attachmentFiles.length > 0;
  if (isNewDocument) {
    const formData = new FormData();
    for (let i = 0; i < attachmentFiles.length; i++) {
      formData.append(
        'internal_documentation[]',
        attachmentFiles[i].originFileObj
      );
    }
    updateOrderTypeDocument(orderTypeId, formData, (res1, err1) => {
      if (res1) {
        cb(res, err);
      } else {
        displayErrMessage(err1);
      }
    });
  } else {
    cb(res, err);
  }
};
