import React from 'react';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { FormItem } from 'components/Form';
import { TextArea } from 'components/FormFields';
import { WORDS_LIMIT } from 'helper/const';
import { useOptions } from 'helper/hooks';
import { getWordLength } from 'utils/text';
import { hideTriggerInformation } from 'utils/reminder';
import { useFormContentData } from './selectorData';
import { getSelectedTrigger } from './utils';
import classes from './NewReminder.module.scss';

const FormContent = ({ form, isDetail }) => {
  const { reminderDetail } = useFormContentData();
  const {
    allOrderTypeOptions,
    scReminderTriggerFieldOptions,
    scReminderFrequencyOptions,
    scReminderRecurrenceOptions,
  } = useOptions();
  return (
    <div className={classes.formContent}>
      <h2 className={classes.mainTitle}>
        {isDetail ? reminderDetail.name : 'Alert Details'}
      </h2>
      <div>
        <InputItem
          name={'name'}
          label="Alert Name"
          required
          form={form}
          placeholder="Enter a descriptive name for this alert"
        />
        <FormItem label="Message Displayed to Client" shouldUpdate>
          {() => {
            const values = form.getFieldsValue();
            return (
              <FormItem
                name="message"
                onChange={e => {
                  const thisValue = e.target.value;
                  const wordLength = getWordLength(thisValue);
                  form.setFieldsValue({
                    message:
                      wordLength <= WORDS_LIMIT.COMMON
                        ? thisValue
                        : values.message,
                  });
                }}
              >
                <TextArea
                  rows={4}
                  name="message"
                  isBlue
                  placeholder="Enter a message to be displayed to client"
                  caption={`${getWordLength(values.message)} / ${
                    WORDS_LIMIT.COMMON
                  }`}
                  captionAsCount
                />
              </FormItem>
            );
          }}
        </FormItem>
      </div>
      <div>
        <InputItem
          name={'trigger_field_id'}
          label="Trigger Field"
          required
          form={form}
          type="select"
          options={scReminderTriggerFieldOptions}
          placeholder="Select trigger"
        />
        <InputItem
          name={'order_type_id'}
          label="Order Type"
          form={form}
          type="select"
          options={allOrderTypeOptions}
          placeholder="Select order type"
        />
      </div>
      <FormItem shouldUpdate className={classes.customLine}>
        {() => {
          const values = form.getFieldsValue();
          const { selectedTrigger, triggerWithDate } = getSelectedTrigger(
            values.trigger_field_id,
            scReminderTriggerFieldOptions
          );
          return (
            <>
              {!!selectedTrigger.label && (
                <>
                  {!hideTriggerInformation(selectedTrigger.label) && (
                    <>
                      {triggerWithDate ? (
                        <>
                          <InputItem
                            name={'reminder_frequency'}
                            label="When to Send Alerts"
                            form={form}
                            type="select"
                            options={[
                              {
                                value: 'all',
                                label: 'Select all that apply',
                              },
                              ...scReminderFrequencyOptions,
                            ]}
                            mode="multiple"
                            required
                            isMultipleOptionCheckbox
                            allValue="all"
                            seeFullOption
                            lineAtAllOption
                          />
                        </>
                      ) : (
                        <>
                          <InputItem
                            name={'initial_reminder_date'}
                            label="Initial Alert Date"
                            form={form}
                            type="date"
                            required
                          />
                          <InputItem
                            name={'recurrence_id'}
                            label="Recurrence"
                            form={form}
                            type="select"
                            options={scReminderRecurrenceOptions}
                            placeholder="Select event recurrence"
                            required
                          />
                        </>
                      )}
                    </>
                  )}
                  <InputItem
                    name={'due_date'}
                    label="Due Date"
                    form={form}
                    type="date"
                  />
                </>
              )}
            </>
          );
        }}
      </FormItem>
    </div>
  );
};

export default FormContent;
