import React, { useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import ROUTES from 'routes';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import Button, { ButtonColors } from 'components/Button';
import { onEditRow } from 'utils/table';
import { Input } from 'components/FormFields';
import { applySearch } from 'utils';
import { PAGINATION, STATUS_VALUES } from 'helper/const';
import { getMyTeamColumns } from './utils';
import { DEFAULT_SORT } from './constants';
import { useAllDepartmentsTabData } from './selectorData';
import classes from './MyTeam.module.scss';

const AllDepartmentsTab = props => {
  const history = useHistory();
  const {
    handleGetAllTeams,
    handleInactive,
    handleResendInvite,
    handleActive,
    activeTab,
    resetList,
    setResetList,
    keyword,
    setKeyword,
    permission,
  } = props;
  const {
    allTeamsLoading,
    allTeams,
    allTeamsTotal,
    doActionLoading,
    allTeamsIsMore,
  } = useAllDepartmentsTabData();
  const [sort, setSort] = useState(DEFAULT_SORT);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const handleGetAllTeamsFn = (page, sort, keyword) => {
    handleGetAllTeams(page, sort, keyword, { group_id: '' });
  };
  const handleView = record => {
    history.push(ROUTES.TEAMMATE_DETAIL({ id: record.id }));
  };
  const handleEdit = record => {
    history.push(`${ROUTES.TEAMMATE_DETAIL({ id: record.id })}?edit=true`);
  };
  const handleAction = record => {
    const { status } = record;
    const fnMapping = {
      [STATUS_VALUES.ACTIVE]: handleInactive,
      [STATUS_VALUES.INVITE_SENT]: handleResendInvite,
      [STATUS_VALUES.IN_ACTIVE]: handleActive,
    };
    if (fnMapping[status]) {
      fnMapping[status]([record], () => {
        handleGetAllTeamsFn(currentPage, sort, keyword);
      });
    }
  };
  const columns = getMyTeamColumns(
    handleView,
    handleEdit,
    handleAction,
    permission
  );
  useEffect(() => {
    if (activeTab === 1) {
      handleGetAllTeamsFn(1, DEFAULT_SORT, keyword);
      setCurrentPage(1);
      setSort(DEFAULT_SORT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
  useEffect(() => {
    if (resetList === true || resetList === false) {
      handleGetAllTeamsFn(1, DEFAULT_SORT, '');
      setCurrentPage(1);
      setSort(DEFAULT_SORT);
      setResetList(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetList]);

  return (
    <div className={classes.mainContentTab}>
      <div className={classes.tabContentHeader}>
        <Input
          size={'medium'}
          name="keyword"
          allowClear
          value={keyword}
          placeholder={`Search teammates`}
          onChange={e => {
            const value = e.target.value;
            setKeyword(value);
            applySearch(value, val => {
              setCurrentPage(1);
              handleGetAllTeamsFn(1, sort, val);
            });
          }}
          prefix={<SearchOutlined />}
          isBlue
        />
        <div className={classes.actions}>
          {permission.teamAction && (
            <Button
              htmlType="button"
              color={
                selectedRows.length > 0
                  ? ButtonColors.BLUE
                  : ButtonColors.DEFAULT
              }
              disabled={selectedRows.length === 0}
              onClick={() => {
                if (selectedRows.length > 0) {
                  handleInactive(selectedRows, () => {
                    setSelectedRows([]);
                    setSelectedRowKeys([]);
                    handleGetAllTeamsFn(currentPage, sort, keyword);
                  });
                }
              }}
              loading={doActionLoading && selectedRows.length > 0}
            >
              Deactivate
            </Button>
          )}
        </div>
      </div>
      <Table
        rowSelection={{
          selectedRowKeys,
          onChange: (sRowKeys, sRows) => {
            setSelectedRowKeys(sRowKeys);
            setSelectedRows(sRows);
          },
        }}
        columns={columns}
        data={allTeams}
        rowKey="id"
        loading={allTeamsLoading}
        rowIsClick
        isScroll
        scroll={{ x: 850 }}
        onRow={record => {
          return {
            onClick: event => {
              onEditRow(event, () => {
                history.push(ROUTES.TEAMMATE_DETAIL({ id: record.id }));
              });
            },
          };
        }}
        showSorterTooltip={false}
        onSortChange={sorter => {
          const newSort = {
            field: sorter.field,
            order: sorter.order,
          };
          setSort(newSort);
          handleGetAllTeamsFn(currentPage, newSort, keyword);
        }}
        locale={{
          emptyText: `No teammates found`,
        }}
      />
      {allTeamsIsMore && (
        <Pagination
          current={currentPage}
          pageSize={PAGINATION.PER_PAGE}
          total={allTeamsTotal}
          right
          onChange={page => {
            setCurrentPage(page);
            handleGetAllTeamsFn(page, sort, keyword);
          }}
          className={classes.pagination}
        />
      )}
    </div>
  );
};

export default AllDepartmentsTab;
