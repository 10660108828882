import React from 'react';
import cn from 'classnames';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { Label } from 'views/pages/NewOrderType/Elements';
import { FILE_SIZE_LIMIT } from 'helper/const';
import classes from './InternalDocument.module.scss';

const InternalDocument = ({ className, form, isViewDetail, fileObjs }) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isViewDetail]: isViewDetail,
        },
        className
      )}
    >
      <h2>Internal Documentation</h2>
      <InputItem name={'delete_attachments'} label="" form={form} hide />
      <InputItem
        name={'internal_documentation'}
        label="Attachment"
        form={form}
        type="file"
        disabled={isViewDetail}
        limitSize={FILE_SIZE_LIMIT.COMMON_ATTACHMENT}
        multiple
        fileNumberLimit={5}
        accept={[
          'image/png',
          'image/jpg',
          'image/jpeg',
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ]}
      />
      {isViewDetail && (
        <>
          {(fileObjs || []).length > 0 ? (
            <div className={classes.fileList}>
              {(fileObjs || []).map((f, i) => {
                return (
                  <a
                    href={f.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={i}
                  >
                    {f.name}
                  </a>
                );
              })}
            </div>
          ) : (
            <Label>None</Label>
          )}
        </>
      )}
    </div>
  );
};

export default InternalDocument;
