import { useStoreActions, useReducerAttribute } from 'store/hooks';
import { REDUCER_NAMES } from 'helper/store';
// ACTIONS
import * as authActions from 'store/actions/auth';

export const useIndexData = () => {
  const currentPassword = useReducerAttribute(
    REDUCER_NAMES.AUTH,
    'currentPassword',
    ''
  );
  return {
    currentPassword,
  };
};

export const useActions = () => {
  return useStoreActions({
    ...authActions,
  });
};
