import get from 'lodash/get';
import { getUpdatedArrayData } from 'utils/store';
import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  truckTypes,
} from '../types';
import { initialTruckState } from '../states/truck';

export default (state = initialTruckState, action) => {
  const { truckId, truckData } = action?.payload || {};
  switch (action.type) {
    // SYNC_UPDATE_TRUCK
    case truckTypes.SYNC_UPDATE_TRUCK: {
      return {
        ...state,
        trucks: {
          ...state.trucks,
          data: getUpdatedArrayData(state.trucks, truckId, truckData),
        },
      };
    }
    // GET_TRUCKS
    case asyncActionTypeRequest(truckTypes.GET_TRUCKS): {
      return {
        ...state,
        trucks: {
          ...state.trucks,
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(truckTypes.GET_TRUCKS): {
      return {
        ...state,
        trucks: {
          loading: false,
          data: get(action, 'response.data') || [],
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(truckTypes.GET_TRUCKS): {
      return {
        ...state,
        trucks: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    }
    // CREATE_TRUCK
    case asyncActionTypeRequest(truckTypes.CREATE_TRUCK): {
      return {
        ...state,
        createTruck: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(truckTypes.CREATE_TRUCK): {
      return {
        ...state,
        createTruck: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(truckTypes.CREATE_TRUCK): {
      return {
        ...state,
        createTruck: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // UPDATE_TRUCK
    case asyncActionTypeRequest(truckTypes.UPDATE_TRUCK): {
      return {
        ...state,
        updateTruck: {
          ...state.updateTruck,
          loading: {
            ...state.updateTruck.loading,
            [truckId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(truckTypes.UPDATE_TRUCK): {
      return {
        ...state,
        updateTruck: {
          loading: {
            ...state.updateTruck.loading,
            [truckId]: false,
          },
          data: {
            ...state.updateTruck.data,
            [truckId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.updateTruck.error,
            [truckId]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(truckTypes.UPDATE_TRUCK): {
      return {
        ...state,
        updateTruck: {
          loading: {
            ...state.updateTruck.loading,
            [truckId]: false,
          },
          data: {
            ...state.updateTruck.data,
            [truckId]: {},
          },
          error: {
            ...state.updateTruck.error,
            [truckId]: action.error,
          },
        },
      };
    }
    // UPDATE_TRUCK_STATUS
    case asyncActionTypeRequest(truckTypes.UPDATE_TRUCK_STATUS): {
      return {
        ...state,
        updateTruckStatus: {
          ...state.updateTruckStatus,
          loading: {
            ...state.updateTruckStatus.loading,
            [truckId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(truckTypes.UPDATE_TRUCK_STATUS): {
      return {
        ...state,
        updateTruckStatus: {
          loading: {
            ...state.updateTruckStatus.loading,
            [truckId]: false,
          },
          data: {
            ...state.updateTruckStatus.data,
            [truckId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.updateTruckStatus.error,
            [truckId]: {},
          },
        },
      };
    }
    case asyncActionTypeFailure(truckTypes.UPDATE_TRUCK_STATUS): {
      return {
        ...state,
        updateTruckStatus: {
          loading: {
            ...state.updateTruckStatus.loading,
            [truckId]: false,
          },
          data: {
            ...state.updateTruckStatus.data,
            [truckId]: {},
          },
          error: {
            ...state.updateTruckStatus.error,
            [truckId]: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
};
