import React, { useState } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import Popover from 'components/Popover';
import Badge from 'components/Badge';
import Tabs from 'components/Tabs';
import TabContent from './components/TabContent';
import { useIndexData, useActions } from './selectorData';
import classes from './Notification.module.scss';

const Notification = ({ className, children }) => {
  const { unreadNotifications, readNotifications } = useIndexData();
  const {
    readNotification,
    getUnreadNotifications,
    getReadNotifications,
    getNotificationUnreadCount,
    deleteNotification,
  } = useActions();
  const unreadIds = unreadNotifications.map(n => n.id);
  const readIds = readNotifications.map(n => n.id);
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const handleReadNotification = (ids, cb) => {
    setVisible(false);
    readNotification(
      {
        notifications_ids: ids,
      },
      () => {
        getUnreadNotifications();
        getReadNotifications();
        getNotificationUnreadCount();
      }
    );
    if (cb) cb();
  };
  const tabs = [
    {
      title: (
        <span>
          Unread
          <Badge count={unreadNotifications.length || 0} size="small"></Badge>
        </span>
      ),
      content: (
        <TabContent
          notFoundMessage="No new notifications"
          markText="Mark all as read"
          onMark={() => {
            handleReadNotification(unreadIds);
          }}
          onStartOrder={notification => () => {
            handleReadNotification([notification.id], () => {
              history.push(
                `/my-company?tab=reminders${
                  notification.reminder_trigger_id
                    ? `&startOrderId=${notification.reminder_trigger_id}`
                    : ''
                }`
              );
            });
          }}
          notifications={unreadNotifications}
          onItemClick={notification => () => {
            handleReadNotification([notification.id], () => {
              history.push(`/my-company?tab=reminders`);
            });
          }}
        />
      ),
    },
    {
      title: <span>Read</span>,
      content: (
        <TabContent
          notFoundMessage="No read notifications"
          markText="Clear all notifications"
          onMark={() => {
            setVisible(false);
            deleteNotification(
              {
                notifications_ids: readIds,
              },
              () => {
                getUnreadNotifications();
                getReadNotifications();
                getNotificationUnreadCount();
              }
            );
          }}
          notifications={readNotifications}
          hideStartOrder
          onItemClick={() => () => {
            setVisible(false);
            history.push(`/my-company?tab=reminders`);
          }}
        />
      ),
    },
  ];

  return (
    <Popover
      content={
        <div className={classes.content}>
          <Tabs
            tabs={tabs}
            type="card"
            activeKey={activeTab.toString()}
            onChange={activeKey => {
              const iActiveKey = parseInt(activeKey, 10);
              setActiveTab(iActiveKey);
            }}
            spaceTop
          />
        </div>
      }
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
      placement="bottom"
      overlayClassName={classes.wrapperOverlay}
      className={cn(classes.wrapper, className)}
    >
      <div className={classes.statusEl}>{children}</div>
    </Popover>
  );
};

export default Notification;
