import React, { useState } from 'react';
import { Prompt } from 'react-router-dom';
import get from 'lodash/get';

const RouterLeavingGuard = ({ navigate, when, handleOpenModal }) => {
  const [confirmed, setConfirmed] = useState(false);
  const triggerModal = nextLocation => {
    handleOpenModal(() => {
      setConfirmed(true);
      setTimeout(() => {
        const locationSearch = get(nextLocation, 'search', '');
        const pathUrl = `${nextLocation.pathname}${
          locationSearch ? locationSearch : ''
        }`;
        navigate(pathUrl);
      }, 0);
    });
  };

  return (
    <>
      <Prompt
        when={when}
        message={nextLocation => {
          if (!confirmed) {
            triggerModal(nextLocation);
            return false;
          }
          return true;
        }}
      />
    </>
  );
};

export default RouterLeavingGuard;
