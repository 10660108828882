import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { SectionWrapper } from 'views/pages/ClientDetail/components/Elements';
import NewOrderModal from 'views/pages/ClientDetail/components/NewOrderModal';
import Header from 'views/pages/ClientDetail/CompanyOfficial/components/Header';
import Pagination from 'components/Pagination';
import Spin from 'components/Spin';
import { PAGINATION, STATUS_VALUES } from 'helper/const';
import { actionCb } from 'utils/action';
import Empty from 'components/Empty';
import { getUrlParamValueByKey, removeAllSearchParam } from 'utils/routes';
import TopTitle from './components/TopTitle';
import NotFound from './components/NotFound';
import ReminderCard from './components/ReminderCard';
import { STATUS_OPTIONS, REMINDER_STORE_KEY } from './constants';
import { getChecklistBR } from './utils';
import { useIndexData, useActions } from './selectorData';
import classes from './ReminderTab.module.scss';

const ReminderTab = ({
  className,
  clientId,
  activeTab,
  currentTab,
  permission,
}) => {
  const history = useHistory();
  const preStartOrderId = getUrlParamValueByKey(
    history?.location?.search,
    'startOrderId'
  );
  const [status, setStatus] = useState(STATUS_VALUES.ACTIVE);
  const [reminderStartOrder, setReminderStartOrder] = useState({});
  const [currentActivePage, setCurrentActivePage] = useState(1);
  const [currentDismissPage, setCurrentDismissPage] = useState(1);
  const [currentCompletePage, setCurrentCompletePage] = useState(1);
  const {
    activeReminders,
    dismissedReminders,
    completedReminders,
    changeReminderStatusLoading,
    activeRemindersLoading,
    dismissedRemindersLoading,
    completedRemindersLoading,
    createUpdateChecklistLoading,
  } = useIndexData();
  const {
    getActiveCompanyReminders,
    getDismissedCompanyReminders,
    getCompletedCompanyReminders,
    changeReminderStatus,
    createUpdateChecklist,
    syncUpdateCompanyReminderChecklists,
    checkUncheckChecklist,
    deleteChecklist,
    getUnreadNotifications,
    getReadNotifications,
    getNotificationUnreadCount,
  } = useActions();
  const preStartOrder = actionCb(res => {
    const foundReminder =
      find(res.data || [], { id: parseInt(preStartOrderId, 10) }) || {};
    if (!isEmpty(foundReminder)) {
      setReminderStartOrder(foundReminder);
    }
    removeAllSearchParam(history);
  });
  const handleReloadData = (key, page, cb) => {
    switch (key) {
      case STATUS_VALUES.ACTIVE:
        getActiveCompanyReminders(
          clientId,
          {
            page,
          },
          cb
        );
        break;
      case STATUS_VALUES.DISMISS:
        getDismissedCompanyReminders(
          clientId,
          {
            page,
          },
          cb
        );
        break;
      case STATUS_VALUES.COMPLETE:
        getCompletedCompanyReminders(clientId, {
          page,
        });
        break;
      default:
        break;
    }
  };
  const handleSyncChecklists = (id, newChecklists) => {
    syncUpdateCompanyReminderChecklists(
      id,
      newChecklists,
      REMINDER_STORE_KEY[status]
    );
  };
  const remindersData = {
    [STATUS_VALUES.ACTIVE]: activeReminders,
    [STATUS_VALUES.DISMISS]: dismissedReminders,
    [STATUS_VALUES.COMPLETE]: completedReminders,
  };
  const remindersLoading = {
    [STATUS_VALUES.ACTIVE]: activeRemindersLoading,
    [STATUS_VALUES.DISMISS]: dismissedRemindersLoading,
    [STATUS_VALUES.COMPLETE]: completedRemindersLoading,
  };
  const handleNewChecklist = eReminder => (values, cb) => {
    const br = getChecklistBR(values, eReminder);
    createUpdateChecklist(
      br,
      actionCb(res => {
        if (cb) cb();
        const newChecklists = res.data || [];
        handleSyncChecklists(eReminder.id, newChecklists);
      })
    );
  };
  const handleOrderChecklist = eReminder => checklists => {
    createUpdateChecklist(
      {
        checklist_type_id: eReminder.id,
        checklist_type: 'Reminder',
        checklist: checklists.map((c, i) => ({
          ...c,
          order: i + 1,
        })),
      },
      actionCb(res => {
        const newChecklists = res.data || [];
        handleSyncChecklists(eReminder.id, newChecklists);
      })
    );
  };
  const handleRemoveChecklist = eReminder => checklist => {
    deleteChecklist(
      checklist.id,
      actionCb(res => {
        const newChecklists = (eReminder.checklists || []).filter(
          c => c.id !== checklist.id
        );
        handleSyncChecklists(eReminder.id, newChecklists);
      })
    );
  };
  const handleUpdateChecklist = eReminder => (checklist, value, cb) => {
    const br = getChecklistBR({ text: value }, eReminder, checklist.id);
    createUpdateChecklist(
      br,
      actionCb(res => {
        if (cb) cb();
        const newChecklists = res.data || [];
        handleSyncChecklists(eReminder.id, newChecklists);
      })
    );
  };
  const handleCheckedChange = eReminder => (checklist, checked) => {
    checkUncheckChecklist(
      checklist.id,
      checked,
      actionCb(res => {
        const newChecklists = (eReminder.checklists || []).map(c => {
          if (c.id === checklist.id) {
            c.is_checked = checked;
          }
          return c;
        });
        handleSyncChecklists(eReminder.id, newChecklists);
      })
    );
  };
  const displayReminders = get(remindersData, `${status}.data`) || [];
  const displayRemindersTotal = get(remindersData, `${status}.total`) || 0;
  const currentPage =
    status === STATUS_VALUES.COMPLETE
      ? currentCompletePage
      : status === STATUS_VALUES.DISMISS
      ? currentDismissPage
      : currentActivePage;
  const setCurrentPage =
    status === STATUS_VALUES.COMPLETE
      ? setCurrentCompletePage
      : status === STATUS_VALUES.DISMISS
      ? setCurrentDismissPage
      : setCurrentActivePage;
  const handleSyncReminders = id => {
    getUnreadNotifications();
    getReadNotifications();
    getNotificationUnreadCount();
    const reloadPage =
      displayReminders.length === 1 ? currentPage - 1 : currentPage;
    setCurrentPage(reloadPage);
    handleReloadData(status, reloadPage);
  };
  const handleDismiss = eReminder => () => {
    changeReminderStatus(
      [eReminder.id],
      STATUS_VALUES.DISMISS,
      clientId,
      actionCb(res => {
        handleSyncReminders(eReminder.id);
      })
    );
  };
  const handleComplete = eReminder => () => {
    changeReminderStatus(
      [eReminder.id],
      STATUS_VALUES.COMPLETE,
      clientId,
      actionCb(res => {
        handleSyncReminders(eReminder.id);
      })
    );
  };
  const handleStartOrder = eReminder => () => {
    setReminderStartOrder(eReminder);
  };
  const activeData =
    get(remindersData, `${STATUS_OPTIONS[0].value}.data`) || [];
  const dismissData =
    get(remindersData, `${STATUS_OPTIONS[1].value}.data`) || [];
  const completeData =
    get(remindersData, `${STATUS_OPTIONS[2].value}.data`) || [];
  const noData =
    activeData.length === 0 &&
    dismissData.length === 0 &&
    completeData.length === 0;
  useEffect(() => {
    if (currentTab === activeTab) {
      handleReloadData(status, 1, preStartOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, currentTab]);
  useEffect(() => {
    if (currentTab === activeTab && preStartOrderId) {
      handleReloadData(status, 1, preStartOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preStartOrderId]);

  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.noData]: noData,
        },
        className
      )}
    >
      {noData ? (
        <NotFound className={classes.notFound} />
      ) : (
        <>
          <Header
            status={status}
            setStatus={val => {
              setStatus(val);
              setCurrentPage(1);
              handleReloadData(val, 1);
            }}
            className={classes.header}
            title="Alerts"
            hideAdd
            statusOptions={STATUS_OPTIONS}
          />
          <SectionWrapper className={cn(classes.content, className)}>
            <TopTitle title={find(STATUS_OPTIONS, { value: status })?.label} />
            <div className={classes.remindersList}>
              {remindersLoading[status] && (
                <Spin icon className={classes.loading} />
              )}
              {displayReminders.length === 0 && !remindersLoading[status] && (
                <Empty type="image1">
                  <span>No alerts to display</span>
                </Empty>
              )}
              {displayReminders.map((r, i) => {
                const reminderCardProps = {
                  handleNewChecklist,
                  handleOrderChecklist,
                  handleRemoveChecklist,
                  handleUpdateChecklist,
                  handleCheckedChange,
                  addChecklistLoading: createUpdateChecklistLoading,
                  updateChecklistLoading: createUpdateChecklistLoading,
                  data: r,
                  onDismiss: handleDismiss,
                  onComplete: handleComplete,
                  onStartOrder: handleStartOrder,
                  actionLoading: changeReminderStatusLoading,
                };

                return (
                  <ReminderCard {...reminderCardProps} key={`${r.id}_${i}`} />
                );
              })}
              <Pagination
                current={currentPage}
                pageSize={PAGINATION.PER_PAGE}
                total={displayRemindersTotal}
                right
                onChange={page => {
                  setCurrentPage(page);
                  handleReloadData(status, page);
                }}
                className={classes.pagination}
                hideOnSinglePage={true}
              />
            </div>
          </SectionWrapper>
        </>
      )}
      <NewOrderModal
        clientId={clientId}
        isOpen={!isEmpty(reminderStartOrder)}
        setActiveTab={() => {}}
        handleClose={() => {
          setReminderStartOrder({});
        }}
        orderDetailData={{}}
        isEditOrder={false}
        orderDetailFieldValues={[]}
        onSubmitOrderCb={() => {}}
        onSaveDraftCb={() => {
          // TODO: onDraft cb
        }}
        onSubmitOrderICb={() => {
          handleComplete({ id: reminderStartOrder.id })();
          setReminderStartOrder({});
        }}
        prefillData={[
          {
            name: 'order_type_id',
            value: reminderStartOrder.order_type_id,
          },
        ]}
        fromReminder
        permission={permission}
      />
    </div>
  );
};

export default ReminderTab;
