const initialWorkQueueState = {
  // GET
  ccColumns: {
    loading: false,
    data: [],
    error: '',
  },
  columns: {
    loading: false,
    data: [],
    error: '',
  },
  filterSetting: {
    data: {},
    loading: false,
    error: '',
  },
  // POST
  addColumn: {
    loading: false,
    data: {},
    error: '',
  },
  updateColumn: {
    loading: false,
    data: {},
    error: '',
  },
  deleteColumn: {
    loading: false,
    data: {},
    error: '',
  },
  moveOrderToColumn: {
    loading: false,
    data: {},
    error: '',
  },
  storeFilterSetting: {
    loading: false,
    data: {},
    error: '',
  },
};

export { initialWorkQueueState };
