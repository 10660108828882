import { MY_TEAM_DISPLAY_TABS } from 'helper/const';

export const adminPermission = {
  teamAction: true,
  teamInvite: true,
  tabNames: MY_TEAM_DISPLAY_TABS,
  tabNameFromDepartment: true,
  showAdditionalTab: true,
  tabIdFromDepartment: true,
  detailFields: [
    'email',
    'alternate_email',
    'phone_number',
    'dob',
    'group_id',
    'role_id',
    'start_date',
    'employee_id',
  ],
  detailFieldLabels: [],
  tabTypes: {},
  departmentDataAttrPrefix: 'departmentTeam',
  isClientColumns: false,
  inviteTeamAsClient: false,
  isDetailCompanyTeam: false,
};
