import React from 'react';
import { parseNumberWithComma } from 'utils/number';

export const NEW_KEY = 'new_trailer';
export const NORMAL_FIELDS = [
  'trailer_unit',
  'vin',
  'color',
  'year',
  'make',
  'model',
  'unladen_weight',
  'gvwr',
  'trailer_length',
  'lp_number',
  'title_number',
  'trailer_type_other',
];
export const NULL_FIELDS = [
  'trailer_type_id',
  'axles',
  'lp_state_id',
  'trailer_state_id',
  'trailer_leased',
  'trailer_leased_from_id',
];
export const NUMBER_FIELDS = ['trailer_unit', 'lp_number'];
export const DATE_FIELDS = ['lp_exp_date'];
export const YES_NO_FIELDS = ['trailer_leased'];
export const FILE_FIELDS = ['documents.title_receipt'];
export const BODY_REQUEST_NORMAL_FIELDS = [
  'trailer_unit',
  'vin',
  'color',
  'year',
  'make',
  'model',
  'unladen_weight',
  'gvwr',
  'trailer_length',
  'lp_number',
  'title_number',
  'trailer_leased_from_id',
  'trailer_type_id',
  'axles',
  'lp_state_id',
  'trailer_state_id',
  'trailer_leased',
  'trailer_type_other',
];
export const DEFAULT_TRAILER = {
  key: 'section 1',
  label: 'T - 026',
  status: 'active',
  trailer_unit: 'T - 026',
  type: 'Lowboy',
  vin: '1DJF5SFS55688438',
  color: 'Black',
  year: '2020',
  make: 'Liddell',
  model: 'C-40',
  axles: '2',
  unladen_weight: '29,550',
  gvwr: '97,770',
  trailer_length: '44 / 07',
  lp_number: 'XG 250365',
  lp_state: 'Idaho - ID',
  lp_exp_date: '',
  title_number: '424046546',
  trailer_state: 'Idaho - ID',
  trailer_leased: 0,
  trailer_leased_from: '',
};
export const DEFAULT_SECTIONS = [];

export const PROFILE_OBJ_KEYS = [
  {
    label: 'Unit',
    nameGroup: true,
    fields: [
      {
        label: 'Trailer Unit #',
        key: 'trailer_unit',
        type: 'value',
      },
      {
        label: 'Type of Trailer',
        key: 'trailer_type',
        type: 'value',
      },
    ],
  },
  {
    label: 'VIN - Vehicle Identification Number',
    key: 'vin',
    type: 'value',
  },
  {
    label: 'Other',
    key: 'trailer_type_other',
    type: 'value',
    dependField: 'trailer_type',
    dependFn: val => val !== 'Other',
    fullWidth: true,
  },
  {
    label: 'Info',
    nameGroup: true,
    fields: [
      {
        label: 'Color',
        key: 'color',
        type: 'value',
      },
      {
        label: 'Year',
        key: 'year',
        type: 'value',
      },
    ],
  },
  {
    label: 'Make',
    nameGroup: true,
    fields: [
      {
        label: 'Make',
        key: 'make',
        type: 'value',
      },
      {
        label: 'Model',
        key: 'model',
        type: 'value',
      },
    ],
  },

  {
    label: 'Unit',
    nameGroup: true,
    fields: [
      {
        label: 'Axles',
        key: 'axles',
        type: 'value',
      },
      {
        label: 'Unladen Weight (lbs.)',
        key: 'unladen_weight',
        type: 'value',
        valueFn: val => parseNumberWithComma(val),
      },
    ],
  },
  {
    label: 'GVWR',
    nameGroup: true,
    fields: [
      {
        label: 'GVWR (lbs.)',
        key: 'gvwr',
        type: 'value',
        valueFn: val => parseNumberWithComma(val),
      },
      {
        label: 'Trailer Length (ft/in)',
        key: 'trailer_length',
        type: 'value',
      },
    ],
  },
  {
    label: 'License',
    nameGroup: true,
    fields: [
      {
        label: 'License Plate #',
        key: 'lp_number',
        type: 'value',
      },
      {
        label: 'License Plate State',
        key: 'lp_state',
        type: 'value',
      },
    ],
  },
  {
    label: 'Plate Exp. Date',
    key: 'lp_exp_date',
    type: 'date',
  },
];
export const TITLE_OBJ_KEYS = [
  {
    label: 'Title / Title Receipt',
    key: 'documents.title_receipt',
    type: 'file',
    isSingleFile: true,
  },
  {
    label: 'Number',
    nameGroup: true,
    fields: [
      {
        label: 'Title Number',
        key: 'title_number',
        type: 'value',
      },
      {
        label: 'State',
        key: 'trailer_state',
        type: 'value',
      },
    ],
  },
];
export const EQUIPMENT_OBJ_KEYS = [
  {
    label: 'Assigned Driver',
    childLabel: 'Assigned Truck',
    key: 'assigned_trucks',
    type: 'list',
    valueFn: val => {
      const drivers = val.assigned_drivers || [];
      if (drivers.length === 0) return 'None';
      return drivers.map((driver, i) => {
        return (
          <React.Fragment key={i}>
            {driver.name}
            {i < drivers.length - 1 ? <br /> : ''}
          </React.Fragment>
        );
      });
    },
    childValueFn: val => val.truck_unit,
  },
];
export const LEASED_OBJ_KEYS = [
  {
    label: 'Trailer Leased?',
    key: 'trailer_leased',
    type: 'boolean',
  },
  {
    label: 'Leasor',
    key: 'trailer_leased_from',
    type: 'value',
  },
];
export const AXLES_OPTIONS = [
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 'Other',
    value: 'Other',
  },
];
export const LEASED_OPTIONS = [
  {
    label: 'No',
    value: '0',
  },
  {
    label: 'Yes',
    value: '1',
  },
];
export const LEASED_STATUS_OPTIONS = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
];
