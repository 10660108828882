import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_LEASORS',
  'GET_LEASOR_ACCOUNT',
  'GET_LEASOR_FUEL_CARD',
  'CREATE_LEASOR',
  'UPDATE_LEASOR',
  'UPDATE_LEASOR_STATUS',
  'CREATE_LEASOR_ACCOUNT',
  'UPDATE_LEASOR_ACCOUNT',
  'CREATE_LEASOR_FUEL_CARD',
  'UPDATE_LEASOR_FUEL_CARD',
  'SYNC_UPDATE_LEASOR',
  'SYNC_UPDATE_LEASOR_SIMPLE_ACCOUNT',
  'SYNC_UPDATE_LEASOR_SIMPLE_FUEL_CARD'
);
