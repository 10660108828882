import React from 'react';
import PageHeader from 'components/PageHeader';
import TopBarContent from 'layouts/Main/TopBarContent';
import ROUTES from 'routes';
import { useHeaderData } from './selectorData';

const NewReminderHeader = ({ isDetail }) => {
  const { reminderDetail } = useHeaderData();
  const breadcrumbs = [
    {
      label: 'Alerts',
      to: ROUTES.REMINDERS(),
      color: 'blue',
    },
    {
      label: isDetail ? reminderDetail.name : 'Create New Alert',
    },
  ];
  return (
    <TopBarContent>
      <PageHeader title={null} breadcrumbs={breadcrumbs} />
    </TopBarContent>
  );
};

export default NewReminderHeader;
