const initialChecklistState = {
  // GET
  checklists: {
    loading: false,
    data: [],
    error: '',
  },

  // POST
  createUpdateChecklist: {
    loading: false,
    data: {},
    error: '',
  },
  checkUncheckChecklist: {
    loading: false,
    data: {},
    error: '',
  },
  deleteChecklist: {
    loading: false,
    data: {},
    error: '',
  },
};

export { initialChecklistState };
