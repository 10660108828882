import React from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import Checkbox from 'components/FormFields/Checkbox';
import { useOptions } from 'helper/hooks';
import {
  MONTH_FIELD,
  BANK_NUMBER_FIELD,
  ZIP_CODE_NUMBER_FIELD,
  CARD_NUMBER_FIELD,
} from 'helper/const';
import { isAMEXCardNumber } from 'utils/card';
import { FormItem } from 'components/Form';
import { FormSection } from 'views/pages/ClientDetail/components/Elements';
import { CREDIT_OR_DEBIT_OPTIONS } from '../../constants';
import classes from './FuelCardEditForm.module.scss';

const FuelCardEditForm = ({
  form,
  index,
  parentName,
  hideFields = [],
  fromPaymentSource,
}) => {
  const { stateOptions } = useOptions();

  return (
    <FormSection className={classes.fuel_card_form}>
      {!hideFields.includes('name_on_card') && (
        <InputItem
          name={[index, 'name_on_card']}
          label="Name on Card"
          form={form}
          placeholder="Name on Card"
          customValidateName={[parentName, index, 'name_on_card']}
        />
      )}
      <FormItem shouldUpdate>
        {() => {
          const values = form.getFieldsValue();
          const tCardNumber = get(values, `cards[${index}].card_number`);
          const isAMEX = isAMEXCardNumber(tCardNumber);
          return (
            <InputItem
              name={[index, 'card_number']}
              label="Card Number"
              form={form}
              placeholder={
                isAMEX
                  ? CARD_NUMBER_FIELD.AMEX.PLACEHOLDER
                  : CARD_NUMBER_FIELD.NORMAL.PLACEHOLDER
              }
              customValidateName={[parentName, index, 'card_number']}
              type="number"
              mask={
                isAMEX
                  ? CARD_NUMBER_FIELD.AMEX.MASK
                  : CARD_NUMBER_FIELD.NORMAL.MASK
              }
            />
          );
        }}
      </FormItem>
      {!hideFields.includes('carrier_id') && (
        <InputItem
          name={[index, 'carrier_id']}
          label="Carrier ID"
          form={form}
          placeholder="000000"
          customValidateName={[parentName, index, 'carrier_id']}
          type="number"
          mask={BANK_NUMBER_FIELD.MASK}
        />
      )}
      {!hideFields.includes('pin') && (
        <InputItem
          name={[index, 'pin']}
          label="PIN"
          form={form}
          placeholder="0000"
          customValidateName={[parentName, index, 'pin']}
          type="number"
          mask={BANK_NUMBER_FIELD.MASK}
        />
      )}
      {!hideFields.includes('card_type') && (
        <InputItem
          className={classes.field_one_fourth}
          name={[index, 'card_type']}
          label="Card Type"
          form={form}
          placeholder="Discover"
          customValidateName={[parentName, index, 'card_type']}
        />
      )}
      {fromPaymentSource && <div className={classes.block} />}
      <InputItem
        className={classes.field_one_fourth}
        name={[index, 'expiration_date']}
        label="Expiration Date"
        form={form}
        placeholder={MONTH_FIELD.PLACEHOLDER}
        customValidateName={[parentName, index, 'expiration_date']}
        type="number"
        mask={MONTH_FIELD.MASK}
      />
      <FormItem shouldUpdate>
        {() => {
          const values = form.getFieldsValue();
          const tCardNumber = get(values, `cards[${index}].card_number`);
          const isAMEX = isAMEXCardNumber(tCardNumber);
          return (
            <InputItem
              className={classes.field_one_fourth}
              name={[index, 'cvv']}
              label="CVV"
              form={form}
              placeholder={isAMEX ? '0000' : '000'}
              customValidateName={[parentName, index, 'cvv']}
              type="number"
              mask={isAMEX ? '9999' : '999'}
            />
          );
        }}
      </FormItem>
      {!hideFields.includes('credit_or_debit') && (
        <InputItem
          className={classes.field_one_fourth}
          name={[index, 'credit_or_debit']}
          label="Credit or Debit"
          form={form}
          placeholder="Select one"
          type="select"
          options={CREDIT_OR_DEBIT_OPTIONS}
          customValidateName={[parentName, index, 'credit_or_debit']}
        />
      )}
      {!hideFields.includes('zip_code') && !fromPaymentSource && (
        <InputItem
          className={classes.field_one_fourth}
          name={[index, 'zip_code']}
          label="Zip Code"
          form={form}
          placeholder={ZIP_CODE_NUMBER_FIELD.PLACEHOLDER}
          customValidateName={[parentName, index, 'zip_code']}
          type="number"
          mask={ZIP_CODE_NUMBER_FIELD.MASK}
        />
      )}
      <div className={classes.line}>
        <FormItem shouldUpdate>
          {() => {
            const values = form.getFieldsValue();
            return (
              <Checkbox
                checked={get(values, `${parentName}.[${index}].is_primary`)}
                label="Default Card"
                onChange={e => {
                  const checked = e.target.checked;
                  const oldCards = (get(values, parentName) || []).map(
                    (card, i) => {
                      if (i === index) {
                        card.is_primary = checked;
                      }
                      if (checked && i !== index) {
                        card.is_primary = false;
                      }
                      return card;
                    }
                  );
                  form.setFieldsValue({
                    [parentName]: oldCards,
                  });
                }}
              />
            );
          }}
        </FormItem>
      </div>
      {fromPaymentSource && (
        <div className={cn(classes.line, classes.billingAddress)}>
          <div className={classes.title}>Billing Address</div>
          <FormSection className={classes.addressForm}>
            <InputItem
              name={[index, 'address']}
              label="Street Address"
              form={form}
              addressField
              autoComplete="chrome-off"
              customValidateName={[parentName, index, 'address']}
            />
            <InputItem
              name={[index, 'apt']}
              label="Apt."
              form={form}
              aptField
              autoComplete="chrome-off"
            />
            <InputItem
              name={[index, 'city']}
              label="City"
              form={form}
              addressGroupField
              autoComplete="chrome-off"
              customValidateName={[parentName, index, 'city']}
            />
            <InputItem
              name={[index, 'state_id']}
              label="State"
              form={form}
              type="select"
              options={stateOptions}
              addressGroupField
              autoComplete="chrome-off"
              customValidateName={[parentName, index, 'state_id']}
            />
            <InputItem
              name={[index, 'zip_code']}
              label="Zip Code"
              form={form}
              type="number"
              mask={ZIP_CODE_NUMBER_FIELD.MASK}
              placeholder={ZIP_CODE_NUMBER_FIELD.PLACEHOLDER}
              addressGroupField
              autoComplete="chrome-off"
              customValidateName={[parentName, index, 'zip_code']}
            />
          </FormSection>
        </div>
      )}
    </FormSection>
  );
};

export default FuelCardEditForm;
