import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { PlusCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import Button, { ButtonColors } from 'components/Button';
import { TextArea } from 'components/FormFields';
import classes from './OrderNote.module.scss';

const OrderNote = ({ className, onSubmit, existingNote }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState('');
  const addBtnProps = {};
  if (!!existingNote && !isEdit) {
    addBtnProps.type = 'ghost';
  }

  useEffect(() => {
    if (existingNote !== note) {
      setNote(existingNote || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingNote]);

  return (
    <div className={cn(classes.wrapper, className)}>
      <h2>Order Notes</h2>
      {existingNote && !isEdit ? (
        <p>{note}</p>
      ) : (
        <TextArea
          name="note"
          placeholder="Notes"
          isBlue
          value={note}
          onChange={e => {
            setNote(e.target.value);
          }}
        />
      )}

      <div className={classes.footer}>
        {existingNote && isEdit && (
          <Button
            onClick={() => {
              setIsEdit(false);
              setNote(existingNote);
            }}
            color={ButtonColors.DEFAULT}
          >
            Cancel
          </Button>
        )}
        <Button
          leftIcon={
            !existingNote ? (
              <PlusCircleOutlined />
            ) : existingNote && isEdit ? (
              <CheckCircleFilled />
            ) : (
              ''
            )
          }
          onClick={() => {
            if (existingNote && !isEdit) {
              setIsEdit(true);
            } else if (!!note) {
              setLoading(true);
              onSubmit(note, () => {
                setLoading(false);
                if (isEdit) {
                  setIsEdit(false);
                }
              });
            }
          }}
          color={ButtonColors.BLUE}
          loading={loading}
          disabled={!(note || '').trim()}
          {...addBtnProps}
        >
          {existingNote ? (isEdit ? 'Save Edits' : 'Edit Note') : 'Add Note'}
        </Button>
      </div>
    </div>
  );
};

export default OrderNote;
