import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';
import { FormItem } from 'components/Form';
import {
  Input,
  InputPassword,
  InputMask,
  InputPasswordStrong,
} from 'components/FormFields';
import { PHONE_FIELD } from 'helper/const';
import ROUTES from 'routes';
import classes from './Registration.module.scss';

const FormContent = ({
  hasToken,
  form,
  setStrongPassword,
  createUserLoading,
}) => {
  const history = useHistory();
  return (
    <>
      <FormItem name={'firstName'} label="First Name" required>
        <Input
          size={'large'}
          name="firstName"
          form={form}
          disabled={hasToken}
        />
      </FormItem>
      <FormItem name={'lastName'} label="Last Name" required>
        <Input size={'large'} name="lastName" form={form} disabled={hasToken} />
      </FormItem>
      <FormItem name={'email'} label="Email" required>
        <Input size={'large'} name="email" form={form} disabled={hasToken} />
      </FormItem>
      <FormItem name="phone" label="Phone Number" required>
        <InputMask
          mask={PHONE_FIELD.MASK}
          name="phone"
          form={form}
          disabled={hasToken}
        />
      </FormItem>
      <InputPasswordStrong
        passwordStrongChange={val => {
          setStrongPassword(val);
        }}
        required
        label="Password"
        form={form}
      />
      <FormItem name={'confirmPassword'} label="Confirm Password" required>
        <InputPassword size={'large'} name="confirmPassword" form={form} />
      </FormItem>
      <div className={classes.bottomButton}>
        <FormItem>
          <Button
            type={'primary'}
            size={'large'}
            htmlType={'submit'}
            className={classes.fullWidth}
            loading={createUserLoading}
          >
            Create Account
          </Button>
        </FormItem>
        <Button
          type={'primary'}
          size={'large'}
          htmlType={'button'}
          isLink
          className={classes.fullWidth}
          onClick={() => {
            history.push(ROUTES.LOGIN());
          }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default FormContent;
