import React from 'react';
import PageHeader from 'components/PageHeader';
import TopBarContent from 'layouts/Main/TopBarContent';
import ROUTES from 'routes';
import { getFullName } from 'utils/name';
import { useHeaderData } from './selectorData';

const Header = () => {
  const { detailData } = useHeaderData();
  const breadcrumbs = [
    {
      label: 'My Team',
      to: ROUTES.MY_TEAM(),
      color: 'blue',
    },
    {
      label: getFullName(detailData),
    },
  ];
  return (
    <>
      <TopBarContent>
        <PageHeader title={null} breadcrumbs={breadcrumbs} />
      </TopBarContent>
    </>
  );
};

export default Header;
