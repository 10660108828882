const initialSharedState = {
  // GET
  states: {
    loading: false,
    data: [],
    error: '',
  },
  fieldCategories: {
    loading: false,
    data: [],
    error: '',
  },
  positions: {
    loading: false,
    data: [],
    error: '',
  },
  departments: {
    loading: false,
    data: [],
    error: '',
  },
  departmentsList: {
    loading: false,
    data: [],
    error: '',
  },
  systemCodes: {
    loading: false,
    data: {},
    error: '',
  },
  salesDepartmentUser: {
    loading: false,
    data: [],
    error: '',
  },
  trailersList: {
    loading: false,
    data: [],
    error: '',
  },
  driversList: {
    loading: false,
    data: [],
    error: '',
  },
  leasorsList: {
    loading: false,
    data: [],
    error: '',
  },
  roles: {
    loading: false,
    data: [],
    error: '',
  },
  vinDecoder: {
    loading: false,
    data: {},
    error: '',
  },
  bankName: {
    loading: false,
    data: {},
    error: '',
  },
  employeeList: {
    loading: false,
    data: [],
    error: '',
  },
  clientList: {
    loading: false,
    data: [],
    error: '',
  },
  statusList: {
    loading: false,
    data: [],
    error: '',
  },
  paginationClientList: {
    loading: false,
    data: {},
    error: '',
  },
  paginationOrderTypeList: {
    loading: false,
    data: {},
    error: '',
  },
  paginationEmployeeList: {
    loading: false,
    data: {},
    error: '',
  },
  // POST
  uploadFile: {
    loading: false,
    data: {},
    error: '',
  },
};

export { initialSharedState };
