import { useStoreActions, useReducerData } from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';
// SELECTORS
import * as authSelectors from 'store/selectors/auth';
import * as workQueueSelectors from 'store/selectors/workQueue';
import * as clientOrderDetailSelectors from 'store/selectors/clientOrderDetail';
import * as checklistSelectors from 'store/selectors/checklist';
// ACTIONS
import * as workQueueActions from 'store/actions/workQueue';
import * as clientOrderDetailActions from 'store/actions/clientOrderDetail';
import * as orderActions from 'store/actions/order';
import * as sharedActions from 'store/actions/shared';
import * as orderTypeActions from 'store/actions/orderType';
import * as checklistActions from 'store/actions/checklist';

export const useIndexData = () => {
  return {
    currentUser: authSelectors.useSelectCurrentUser(),
    filterSetting: workQueueSelectors.useSelectFilterSetting(),
    clientMeta: useReducerData(
      REDUCER_NAMES.SHARED,
      REDUCER_ATTRIBUTES.SHARED.PAGINATION_CLIENT_LIST
    ),
    orderTypeMeta: useReducerData(
      REDUCER_NAMES.SHARED,
      REDUCER_ATTRIBUTES.SHARED.PAGINATION_ORDER_TYPE_LIST
    ),
    employeeMeta: useReducerData(
      REDUCER_NAMES.SHARED,
      REDUCER_ATTRIBUTES.SHARED.PAGINATION_EMPLOYEE_LIST
    ),
  };
};
export const useMainContentData = () => {
  return {
    workQueueColumns: workQueueSelectors.useSelectColumns(),
    addWorkQueueColumnLoading: workQueueSelectors.useSelectAddColumnLoading(),
    orderChecklist: checklistSelectors.useSelectChecklists(),
    orderChecklistLoading: checklistSelectors.useSelectChecklistsLoading(),
    addChecklistLoading: clientOrderDetailSelectors.useSelectSaveClientOrderChecklistLoading(),
  };
};
export const useFilterData = () => {
  return {
    clientMeta: useReducerData(
      REDUCER_NAMES.SHARED,
      REDUCER_ATTRIBUTES.SHARED.PAGINATION_CLIENT_LIST
    ),
    orderTypeMeta: useReducerData(
      REDUCER_NAMES.SHARED,
      REDUCER_ATTRIBUTES.SHARED.PAGINATION_ORDER_TYPE_LIST
    ),
    employeeMeta: useReducerData(
      REDUCER_NAMES.SHARED,
      REDUCER_ATTRIBUTES.SHARED.PAGINATION_EMPLOYEE_LIST
    ),
  };
};
export const useLoadingSpinData = () => {
  return {
    workQueueColumnsLoading: workQueueSelectors.useSelectColumnsLoading(),
    filterSettingLoading: workQueueSelectors.useSelectFilterSettingLoading(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...workQueueActions,
    ...orderActions,
    ...clientOrderDetailActions,
    ...sharedActions,
    ...orderTypeActions,
    ...checklistActions,
  });
};
