import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as authSelectors from 'store/selectors/auth';
// ACTIONS
import * as authActions from 'store/actions/auth';

export const useIndexData = () => {
  return {
    forgotPasswordLoading: authSelectors.useSelectForgotPasswordLoading(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...authActions,
  });
};
