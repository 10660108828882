import React from 'react';
import Modal from 'components/Modal';
import { MODAL } from 'helper/const';
import classes from './ConfirmDeleteAvatarModal.module.scss';

const ConfirmDeleteAvatarModal = ({
  isOpen,
  avatarFinalSrc,
  onClose,
  onSubmit,
}) => {
  const modalProps = {
    isOpen,
    onSubmit,
    onClose,
    title: <>Remove Profile Photo</>,
    maskClosable: false,
    closable: true,
    cancelText: 'Cancel',
    okText: 'Yes, Remove Photo',
    width: MODAL.SIZE.SEMI_NORMAL,
    className: classes.modalWrapper,
    isSubmitLoading: false,
    closeIcon: null,
    okBtnProps: {
      size: 'large',
    },
    cancelBtnProps: {
      size: 'large',
    },
    blueColor: true,
  };
  return (
    <Modal {...modalProps}>
      <div className={classes.inner}>
        <img src={avatarFinalSrc} alt="avatar-profile" />
        <p>
          Are you sure you want to remove your photo?
          <br />
          We'll replace it with a default RoadOffice avatar.
        </p>
      </div>
    </Modal>
  );
};
export default ConfirmDeleteAvatarModal;
