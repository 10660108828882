import React from 'react';
import InputItem from '../InputItem';
import { Section, FormSection, ListInfo } from '../Elements';
import { DOCUMENTS_OBJ_KEYS } from './constants';

const Documents = ({ isEdit, form, data }) => {
  return (
    <Section>
      {isEdit ? (
        <FormSection>
          {/* Use it to save delete_attachments for attachment removing */}
          <InputItem name={'delete_attachments'} label="" form={form} hide />
          <InputItem
            name={'operating_agreement'}
            label="Operating Agreement"
            form={form}
            type="file"
          />
          <InputItem name={'w_9'} label="W-9" form={form} type="file" />
        </FormSection>
      ) : (
        <ListInfo objKeys={DOCUMENTS_OBJ_KEYS} data={data} />
      )}
    </Section>
  );
};

export default Documents;
