import React from 'react';
import { Input as AntdInput } from 'antd';
import cx from 'classnames';

import { PATTERN } from 'helper/const';
import classes from './Input.module.scss';

const { Password, Search: InputSearch } = AntdInput;

const InputPassword = ({ form, className, isBlue, ...other }) => {
  return (
    <Password
      {...other}
      onChange={e => {
        if (other.onChange) {
          other.onChange(e);
        }
        if (e.target.value && form) {
          form.setFields([
            {
              name: other.name,
              errors: null,
            },
          ]);
        }
      }}
      className={cx(
        classes.inputPassword,
        {
          'ant-input-is-blue': isBlue,
        },
        className
      )}
    />
  );
};

const Input = props => {
  const {
    className,
    onlyNumber,
    size = 'large',
    caption,
    form,
    isBlue,
    inputRef,
    customValidateName,
    ...other
  } = props;
  const addProps = onlyNumber
    ? {
        onKeyPress: e => {
          const specialCharRegex = new RegExp(PATTERN.NUMBER);
          const pressedKey = String.fromCharCode(
            !e.charCode ? e.which : e.charCode
          );
          if (!specialCharRegex.test(pressedKey)) {
            e.preventDefault();
            return false;
          }
        },
      }
    : {};
  return (
    <>
      <AntdInput
        {...other}
        ref={inputRef}
        size={size}
        className={cx(
          classes.wrapper,
          {
            'ant-input-is-blue': isBlue,
          },
          className
        )}
        {...addProps}
        onChange={e => {
          if (props.onChange) {
            props.onChange(e);
          }
          if (e.target.value && form) {
            form.setFields([
              {
                name: customValidateName || props.name,
                errors: null,
              },
            ]);
          }
        }}
      />
      {!!caption && <span className={classes.caption}>{caption}</span>}
    </>
  );
};

export { InputPassword, InputSearch };
export default Input;
