import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import ROUTES from 'routes';
import Tabs from 'components/Tabs';
import MainButton from 'views/pages/ClientList/MainButton';
import ReminderTab from './ReminderTab';
import Header from './Header';
import Content from './Content';
import LoadingSpin from './LoadingSpin';
import { getFilters } from './utils';
import { useIndexData, useActions } from './selectorData';
import classes from './Reminders.module.scss';

const Reminders = () => {
  const history = useHistory();
  const {
    activeRemindersLoading,
    activeReminders,
    activeRemindersTotal,
    activeRemindersIsMore,
    inactiveRemindersLoading,
    inactiveReminders,
    inactiveRemindersTotal,
    inactiveRemindersIsMore,
  } = useIndexData();
  const { getActiveReminders, getInactiveReminders } = useActions();
  const [activeTab, setActiveTab] = useState(1);
  const [keyword, setKeyword] = useState('');
  const isNotFound =
    keyword === '' &&
    activeRemindersTotal === 0 &&
    inactiveRemindersTotal === 0;
  const handleGetReminders = key => (page, keyword) => {
    const filters = getFilters(page, keyword);
    switch (key) {
      case 'active':
        getActiveReminders(filters);
        break;
      case 'inactive':
        getInactiveReminders(filters);
        break;
      default:
        break;
    }
  };
  const handleCreateReminder = () => {
    history.push(ROUTES.NEW_REMINDER());
  };
  const commonProps = {
    keyword,
    setKeyword,
    handleCreateReminder,
    activeTab,
    isNoData:
      isNotFound && !inactiveRemindersLoading && !activeRemindersLoading,
  };
  const tabs = [
    {
      title: <span>Active</span>,
      content: (
        <ReminderTab
          handleGetReminders={handleGetReminders('active')}
          allRemindersLoading={activeRemindersLoading}
          allReminders={activeReminders}
          allRemindersTotal={activeRemindersTotal}
          allRemindersIsMore={activeRemindersIsMore}
          currentTab={1}
          updateStatusCb={() => {
            handleGetReminders('inactive')(1, '');
          }}
          {...commonProps}
        />
      ),
    },
    {
      title: <span>Inactive</span>,
      content: (
        <ReminderTab
          handleGetReminders={handleGetReminders('inactive')}
          allRemindersLoading={inactiveRemindersLoading}
          allReminders={inactiveReminders}
          allRemindersTotal={inactiveRemindersTotal}
          allRemindersIsMore={inactiveRemindersIsMore}
          currentTab={2}
          updateStatusCb={() => {
            handleGetReminders('active')(1, '');
          }}
          {...commonProps}
        />
      ),
    },
  ];
  useEffect(() => {
    handleGetReminders('inactive')(1, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingSpin>
      <div
        className={cn(classes.wrapper, {
          [classes.isNotFound]: isNotFound,
        })}
      >
        <Header />
        <Content>
          <Tabs
            tabs={tabs}
            activeKey={activeTab.toString()}
            onChange={activeKey => {
              const iActiveKey = parseInt(activeKey, 10);
              setActiveTab(iActiveKey);
            }}
            spaceTop
          />
          <MainButton
            onClick={handleCreateReminder}
            buttonText="Create New Alert"
            className={classes.mainButton}
          />
        </Content>
      </div>
    </LoadingSpin>
  );
};

export default Reminders;
