const initialIntakeFormState = {
  // GET
  dotNumberDetails: {
    loading: false,
    data: {},
    error: '',
  },
};

export { initialIntakeFormState };
