export const NEW_KEY = 'new_ucc_filing';
export const NORMAL_FIELDS = ['business_name', 'notes', 'other', 'industry'];
export const NULL_FIELDS = ['ifa_watchlist'];
export const NUMBER_FIELDS = [];
export const DATE_FIELDS = ['business_start_date'];
export const FILE_FIELDS = [];
export const BOOLEAN_FIELDS = [];
export const YES_NO_FIELDS = ['ifa_watchlist'];
export const BODY_REQUEST_NORMAL_FIELDS = [
  'business_name',
  'industry',
  'notes',
  'other',
];
export const DEFAULT_TRUCK = {
  key: 'section 1',
  id: 10,
  label: '435',
  status: 'active',
};
export const DEFAULT_SECTIONS = [];

export const PROFILE_OBJ_KEYS = [
  {
    label: 'Business Name',
    key: 'business_name',
    type: 'value',
  },
  {
    label: 'IFA Watchlist',
    key: 'ifa_watchlist',
    type: 'boolean',
  },
  {
    label: 'Business Start Date',
    key: 'business_start_date',
    type: 'date',
  },
  {
    label: 'Industry',
    key: 'industry',
    type: 'value',
  },
  // {
  //   label: 'Other',
  //   key: 'other',
  //   type: 'value',
  //   dependField: 'industry_type',
  //   dependFn: val => val !== 'Other',
  //   fullWidth: true,
  // },
  {
    label: 'Notes',
    key: 'notes',
    type: 'value',
    fullWidth: true,
  },
];
