import {
  apiGetCompanyOfficials,
  apiGetClientDetail,
  apiUpdateSalesInfo,
  apiGetClientDetailLog,
  apiManageLogo,
  apiAddLog,
  apiUpdateCompany,
  apiCreateUpdateCompanyOfficial,
  apiUpdateCompanyOfficialStatus,
  apiUpdateInsurance,
  apiUpdateFilling,
  apiSearchSafer,
  apiDOTSearchSafer,
  apiSaveDOT,
  apiArchiveClient,
  apiUnArchiveClient,
  apiDeleteClient,
  apiGetDOT,
  apiGetCompanyUsers,
} from 'api';
import { TAB_DATA_MAPPING } from 'views/pages/ClientDetail/components/Filling/constants';

import { clientDetailTypes } from '../types';

// SYNC_UPDATE_CLIENT_DETAIL_SECTION
export const syncUpdateClientDetailByKey = (key, data, type) => dispatch => {
  dispatch({
    type: clientDetailTypes.SYNC_UPDATE_CLIENT_DETAIL_SECTION,
    payload: { key, data, type },
  });
};
// SYNC_UPDATE_COMPANY_OFFICIAL
export const syncUpdateCompanyOfficial = (officialId, coData) => dispatch => {
  dispatch({
    type: clientDetailTypes.SYNC_UPDATE_COMPANY_OFFICIAL,
    payload: { officialId, coData },
  });
};
// SYNC_SET_SECTION_TOTAL
export const syncSetSectionTotal = (total, key, totalKey) => dispatch => {
  dispatch({
    type: clientDetailTypes.SYNC_SET_SECTION_TOTAL,
    payload: { total, key, totalKey },
  });
};
// SYNC_SET_CO_PRIMARY_CONTACT
export const syncSetCOPrimaryContact = officialId => dispatch => {
  dispatch({
    type: clientDetailTypes.SYNC_SET_CO_PRIMARY_CONTACT,
    payload: { officialId },
  });
};

// GET_COMPANY_USERS
export const getCompanyUsers = (id, filters, cb, isMore) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.GET_COMPANY_USERS,
    payload: { isMore },
    asyncCall: () => {
      return apiGetCompanyUsers(id, filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_CLIENT_DETAIL
export const getClientDetail = ({ id, silent }, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.GET_CLIENT_DETAIL,
    payload: { silent },
    asyncCall: () => {
      return apiGetClientDetail(id);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_CLIENT_DETAIL_LOG
export const getClientDetailLog = ({ id }, filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.GET_CLIENT_DETAIL_LOG,
    payload: {},
    asyncCall: () => {
      return apiGetClientDetailLog(id, filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_COMPANY_OFFICIALS
export const getCompanyOfficials = ({ id }, filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.GET_COMPANY_OFFICIALS,
    payload: {},
    asyncCall: () => {
      return apiGetCompanyOfficials(id, filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_SALES_INFO
export const updateSalesInfo = (clientId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.UPDATE_SALES_INFO,
    payload: {},
    asyncCall: () => {
      return apiUpdateSalesInfo(clientId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(syncUpdateClientDetailByKey('sales_info', response.data || {}));
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_COMPANY
export const updateCompany = (clientId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.UPDATE_COMPANY,
    payload: {},
    asyncCall: () => {
      return apiUpdateCompany(clientId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(
        syncUpdateClientDetailByKey('company_info', response.data || {})
      );
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_FILLING
export const updateFilling = (clientId, data, cb, type) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.UPDATE_FILLING,
    payload: {},
    asyncCall: () => {
      return apiUpdateFilling(clientId, type, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(
        syncUpdateClientDetailByKey(
          'filings',
          response.data || {},
          TAB_DATA_MAPPING[type]
        )
      );
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_INSURANCE
export const updateInsurance = (clientId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.UPDATE_INSURANCE,
    payload: {},
    asyncCall: () => {
      return apiUpdateInsurance(clientId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(syncUpdateClientDetailByKey('insurance', response.data || {}));
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// MANAGE_LOGO
export const manageLogo = (clientId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.MANAGE_LOGO,
    payload: {},
    asyncCall: () => {
      return apiManageLogo(clientId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// ADD_LOG
export const addLog = (clientId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.ADD_LOG,
    payload: {},
    asyncCall: () => {
      return apiAddLog(clientId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CREATE_COMPANY_OFFICIAL
export const createCompanyOfficial = (clientId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.CREATE_COMPANY_OFFICIAL,
    payload: {},
    asyncCall: () => {
      return apiCreateUpdateCompanyOfficial(clientId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_COMPANY_OFFICIAL
export const updateCompanyOfficial = (clientId, data, cb, officialId) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.UPDATE_COMPANY_OFFICIAL,
    payload: { officialId },
    asyncCall: () => {
      return apiCreateUpdateCompanyOfficial(clientId, data);
    },
    afterSuccessCall: (dispatch, response) => {
      dispatch(syncUpdateCompanyOfficial(officialId, response?.data || {}));
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_COMPANY_OFFICIAL_STATUS
export const updateCOStatus = (clientId, officialId, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.UPDATE_COMPANY_OFFICIAL_STATUS,
    payload: { officialId },
    asyncCall: () => {
      return apiUpdateCompanyOfficialStatus(clientId, officialId, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// SEARCH_SAFER
export const searchSafer = (filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.SEARCH_SAFER,
    payload: {},
    asyncCall: () => {
      return apiSearchSafer(filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// DOT_SEARCH_SAFER
export const dotSearchSafer = (filters, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.DOT_SEARCH_SAFER,
    payload: {},
    asyncCall: () => {
      return apiDOTSearchSafer(filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err, errObj) => {
      if (cb) cb(false, err, errObj);
    },
  };
};
// SAVE_DOT
export const saveDOT = (id, data, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.SAVE_DOT,
    payload: {},
    asyncCall: () => {
      return apiSaveDOT(id, data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// ARCHIVE_CLIENT
export const archiveClient = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.ARCHIVE_CLIENT,
    payload: {},
    asyncCall: () => {
      return apiArchiveClient(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UNARCHIVE_CLIENT
export const unarchiveClient = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.UNARCHIVE_CLIENT,
    payload: {},
    asyncCall: () => {
      return apiUnArchiveClient(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// DELETE_CLIENT
export const deleteClient = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.DELETE_CLIENT,
    payload: {},
    asyncCall: () => {
      return apiDeleteClient(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_DOT
export const getDOTClassifications = (id, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientDetailTypes.GET_DOT,
    payload: {},
    asyncCall: () => {
      return apiGetDOT(id);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
