import React from 'react';
import cn from 'classnames';
import find from 'lodash/find';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  ShouldUpdateWrapper,
} from 'views/pages/ClientDetail/components/Elements';
import { useOptions } from 'helper/hooks';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { PROFILE_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const Profile = ({ className, isEdit, form, data }) => {
  const { scTitleOptions } = useOptions();

  return (
    <div className={cn(classes.profileSection, className)}>
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            {/* Use it to save delete_attachments for attachment removing */}
            <InputItem name={'delete_attachments'} label="" form={form} hide />
            <InputItem
              name={'owner_id'}
              label="Owner"
              form={form}
              type="select"
              options={scTitleOptions}
            />
            <InputItem
              name={'first_name'}
              label="First Name"
              form={form}
              addressGroupField
            />
            <InputItem
              name={'last_name'}
              label="Last Name"
              form={form}
              addressGroupField
            />
            <ShouldUpdateWrapper>
              {() => {
                const values = form.getFieldsValue();
                const foundOption =
                  find(scTitleOptions, {
                    value: values.owner_id,
                  }) || {};
                if (foundOption.label !== 'Other') {
                  return <></>;
                }
                return (
                  <>
                    <InputItem name={'other'} label="Other" form={form} />
                  </>
                );
              }}
            </ShouldUpdateWrapper>
            <InputItem
              name={'ownership'}
              label="Ownership %"
              form={form}
              type="price"
              addressGroupField
            />
            <InputItem
              name={'dob'}
              label="DOB - Date of Birth"
              form={form}
              type="date"
              addressGroupField
            />
            <InputItem
              name={'background_check'}
              label="Background Check"
              form={form}
              type="file"
            />
            <InputItem
              name={'is_5_addresses_changes'}
              label="More than 5 address changes in the last 5 years?"
              form={form}
              type="checkbox"
              fullWidth
            />
          </FormSection>
        ) : (
          <ListInfo objKeys={PROFILE_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default Profile;
