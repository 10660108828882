import React from 'react';
import { getCurrencyValue } from 'utils/number';
import { FormItem } from 'components/Form';
import { InputPrice } from 'components/FormFields';
import { Right, Label } from './Elements';
import { usePricingData } from './selectorData';
import classes from './NewOrderType.module.scss';

const Pricing = ({ form, isViewDetail }) => {
  const { orderTypeDetail } = usePricingData();
  return (
    <Right className={classes.pricing} title="Pricing" headerChild={null}>
      <FormItem name={'price'} label="Price" required={!isViewDetail}>
        {isViewDetail ? (
          <Label>{getCurrencyValue(orderTypeDetail.price)}</Label>
        ) : (
          <InputPrice name={'price'} form={form} fullWidth />
        )}
      </FormItem>
    </Right>
  );
};

export default Pricing;
