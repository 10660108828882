import React from 'react';
import { HomeOutlined } from '@ant-design/icons';

import Menu from 'components/Menu';
import Breadcrumb from 'components/Breadcrumb';

const BreadcrumbComponent = ({ classes }) => {
  const menu = <Menu isDropdown />;
  const firstBreadcrumb = [
    {
      to: '#',
      label: 'Breadcrumb Link',
    },
    {
      to: '#',
      label: 'Breadcrumb Link',
    },
    {
      label: 'Breadcrumb Link',
    },
  ];
  const secondBreadcrumb = [
    {
      to: '#',
      icon: <HomeOutlined />,
    },
    {
      to: '#',
      label: 'Breadcrumb Link',
      icon: <HomeOutlined />,
    },
    {
      label: 'Breadcrumb Link',
    },
  ];
  const thirdBreadcrumb = [
    {
      to: '#',
      label: 'Breadcrumb Link',
    },
    {
      to: '#',
      label: 'Breadcrumb Link',
    },
    {
      to: '#',
      label: 'Breadcrumb Link',
      overlay: menu,
    },
    {
      label: 'Breadcrumb Link',
    },
  ];
  const fourthBreadcrumb = [
    {
      to: '#',
      icon: <HomeOutlined />,
    },
    {
      to: '#',
      icon: <HomeOutlined />,
      label: 'Breadcrumb Link',
    },
    {
      to: '#',
      label: 'Breadcrumb Link',
    },
    {
      to: '#',
      label: 'Breadcrumb Link',
    },
  ];

  return (
    <>
      {[
        firstBreadcrumb,
        secondBreadcrumb,
        thirdBreadcrumb,
        fourthBreadcrumb,
      ].map((b, i) => {
        return <Breadcrumb key={i} links={b} />;
      })}
    </>
  );
};

export default BreadcrumbComponent;
