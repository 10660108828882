import Request from './request';

// GET
export const apiGetReminders = async filters => {
  return Request.call({
    url: '/reminders',
    method: 'GET',
    params: filters,
  });
};
export const apiGetCompanyReminders = async (id, filters = {}) => {
  return Request.call({
    url: `/company/${id}/reminders`,
    method: 'GET',
    params: filters,
  });
};
export const apiGetReminderDetail = async id => {
  return Request.call({
    url: `/reminders/detail/${id}`,
    method: 'GET',
  });
};

// POST
export const apiCreateReminder = async data => {
  return Request.call({
    url: '/reminders',
    method: 'POST',
    data,
  });
};
export const apiUpdateReminder = async (id, data) => {
  return Request.call({
    url: `/reminders/${id}`,
    method: 'PUT',
    data,
  });
};
export const apiDeleteReminder = async ids => {
  return Request.call({
    url: `/reminders`,
    method: 'DELETE',
    data: {
      ids,
    },
  });
};
export const apiChangeReminderStatus = async (ids, status, companyId) => {
  return Request.call({
    url: companyId
      ? `/company/${companyId}/reminders/status`
      : `/reminders/status`,
    method: 'POST',
    data: {
      ids,
      status,
    },
  });
};
export const apiSubscribeReminderEmail = async (companyId, memberId, data) => {
  return Request.call({
    url: `/company/${companyId}/team/${memberId}/reminder-subscription`,
    method: 'POST',
    data,
  });
};
