import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import ROUTES from 'routes';
import Spin from 'components/Spin';
import Space from 'components/Space';
import { useForm } from 'components/Form';
import toast from 'helper/toast';
import { validateForm, scrollToErrorField } from 'utils/form';
import { getErrorMessageFromObj } from 'utils/object';
import { getMomentDate } from 'utils/date';
import FormContent from './FormContent';
import { validateObj, getBodyRequest } from './utils';
import { useActions, useIndexData } from './selectorData';
import classes from './CreateAccount.module.scss';

const CreateAccount = ({ match }) => {
  const token = get(match, 'params.token', '');
  const history = useHistory();
  const [form] = useForm();
  const { validateInviteTokenLoading, accountData } = useIndexData();
  const { doLogout, validateInviteToken, createAccount } = useActions();
  const [strongPassword, setStrongPassword] = useState([]);
  const initialValues = {
    first_name: '',
    mi: '',
    last_name: '',
    alternate_email: '',
    password: '',
    confirm_password: '',
    phone_number: '',
    dob: '',
  };
  const onSubmit = values => {
    const fieldErrors = validateForm(validateObj(), values);
    if (fieldErrors.length > 0) {
      form.setFields(fieldErrors);
      scrollToErrorField();
    } else {
      if (strongPassword.length === 3) {
        const bodyRequest = getBodyRequest(values);
        bodyRequest.token = token;
        createAccount(bodyRequest, (res, err) => {
          if (res) {
            history.push(ROUTES.CREATE_ACCOUNT_SUCCESS());
          } else {
            toast.error(getErrorMessageFromObj(err)[0]);
          }
        });
      }
    }
  };
  useEffect(() => {
    doLogout();
    if (token) {
      validateInviteToken({ token }, res => {
        if (res) {
          const newAccountData = res.data || {};
          form.setFieldsValue({
            ...newAccountData,
            dob: newAccountData.dob
              ? getMomentDate(newAccountData.dob, false, true)
              : '',
          });
        } else {
          toast.error('Token is invalid. Please try again!');
          history.push(ROUTES.LOGIN());
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin spinning={validateInviteTokenLoading} tip={'Verifying...'}>
      <p>
        Hello {accountData.first_name}, we’re excited to have you join
        RoadOffice!
      </p>
      {!validateInviteTokenLoading && (
        <Space
          direction={'vertical'}
          size={'large'}
          className={classes.wrapper}
        >
          <p>Please fill out the form below to create your account.</p>
          <FormContent
            onSubmit={onSubmit}
            initialValues={initialValues}
            form={form}
            setStrongPassword={setStrongPassword}
            data={accountData}
          />
        </Space>
      )}
    </Spin>
  );
};

export default CreateAccount;
