import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useHistory, Redirect } from 'react-router-dom';
import { getErrorMessageFromObj } from 'utils/object';
import Button, { ButtonColors } from 'components/Button';
import Space from 'components/Space';
import {
  Checkbox,
  FakeAutocompleteField,
  Input,
  InputPassword,
} from 'components/FormFields';
import Form, { FormItem, useForm } from 'components/Form';
import {
  standardRequiredFieldObj,
  validateForm,
  scrollToErrorField,
  emailFieldObj,
} from 'utils/form';
import ROUTES from 'routes';
import { MOBILE_APP_STORE_LINK, MOBILE_GOOGLE_PLAY_LINK } from 'helper/const';
import { useModal } from 'components/Modal';
import MobileAppPng from 'assets/img/MobileApp.png';
import AppStorePng from 'assets/img/AppStore.png';
import GooglePlayPng from 'assets/img/GooglePlay.png';
import { confirmModalConfig, getConfirmModal } from 'utils/modal';
import getPermission from './permissions';
import { useActions, useIndexData } from './selectorData';
import classes from './Login.module.scss';

const validateObj = () => {
  return {
    email: [standardRequiredFieldObj, emailFieldObj],
    password: [standardRequiredFieldObj],
  };
};

const getRole = currentUser => {
  return currentUser.role || 'admin';
};

const Login = () => {
  const { doLoginLoading, isLoggedIn, currentUser } = useIndexData();
  const { doLogin } = useActions();
  const [modal, contextHolder] = useModal();
  const history = useHistory();
  const prevHistoryState = get(history, 'location.state') || {};
  const [isKeepMe, setIsKeepMe] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [form] = useForm();
  const confirmModal = getConfirmModal(modal, confirmModalConfig);

  const onSubmit = values => {
    const fieldErrors = validateForm(validateObj(), values);
    if (fieldErrors.length > 0) {
      form.setFields(fieldErrors);
      scrollToErrorField();
    } else {
      const { keep_me_signed_in, email, password } = values;
      doLogin(
        {
          email,
          password,
          keep_me_signed_in: keep_me_signed_in ? 7 : 1,
        },
        (res, err) => {
          if (res) {
            const permission = getPermission(getRole(res.data));
            const prevUrl = !isEmpty(prevHistoryState)
              ? `${prevHistoryState.prevLocation}${prevHistoryState.search}`
              : '';
            history.push(prevUrl || permission.redirectUrl);
          } else {
            const errorMessage = getErrorMessageFromObj(err)[0];
            setLoginError(errorMessage);
            form.setFields([
              {
                name: 'email',
                errors: [''],
              },
              {
                name: 'password',
                errors: [''],
              },
            ]);
            if (errorMessage === 'Email not verified') {
              confirmModal(
                'A confirmation link has been re-sent to your email account',
                <div>
                  Please click on the link that has been re-sent to your email
                  account to verify your email.
                </div>,
                () => {}
              );
            }
          }
        }
      );
    }
  };

  const initialValues = {
    email: '',
    password: '',
  };
  if (isLoggedIn) {
    const permission = getPermission(getRole(currentUser));
    return <Redirect to={permission.redirectUrl} />;
  }

  return (
    <Space direction={'vertical'} size={'large'} className={classes.wrapper}>
      <div className={classes.heading}>Sign In</div>
      {loginError && <div className={classes.errorMessage}>{loginError}</div>}
      <Form
        validateMessages={''}
        onFinish={onSubmit}
        initialValues={initialValues}
        form={form}
      >
        <FormItem name={'email'} label="Email">
          <Input size={'large'} name="email" form={form} autoComplete="off" />
        </FormItem>
        <FormItem name={'password'} label="Password">
          <InputPassword
            size={'large'}
            name="password"
            form={form}
            autoComplete="off"
          />
        </FormItem>
        <FakeAutocompleteField />
        <div className={classes.forgotPassword}>
          <FormItem
            name="keep_me_signed_in"
            label=""
            className={classes.keepMeSignedIn}
          >
            <Checkbox
              checked={isKeepMe}
              label="Keep me signed in"
              onChange={e => {
                setIsKeepMe(e.target.checked);
                form.setFieldsValue({
                  keep_me_signed_in: e.target.checked,
                });
              }}
            />
          </FormItem>
          <span
            onClick={() => {
              history.push(ROUTES.FORGOT_PASSWORD());
            }}
          >
            Forgot Password?
          </span>
        </div>
        <div className={classes.bottomButton}>
          <Button
            type={'primary'}
            size={'large'}
            htmlType={'submit'}
            className={classes.fullWidth}
            loading={doLoginLoading}
            color={ButtonColors.DARK_RED1}
          >
            Sign In
          </Button>
        </div>
        <div className={classes.dontHaveAccount}>
          <div className={classes.mobileApp}>
            <img src={MobileAppPng} alt="road-office" />
          </div>
          <div className={classes.howToDownload}>
            <span>Don't have an account?</span>
            <p>
              To sign up, download
              <br />
              the Road Office Mobile App
            </p>
            <div>
              <a
                href={MOBILE_APP_STORE_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={AppStorePng} alt="app-store" />
              </a>
              <a
                href={MOBILE_GOOGLE_PLAY_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={GooglePlayPng} alt="google-play" />
              </a>
            </div>
          </div>
        </div>
      </Form>
      {contextHolder}
    </Space>
  );
};

export default Login;
