import React from 'react';
import cn from 'classnames';
import Empty from 'components/Empty';
import classes from './NotFound.module.scss';

const NotFound = ({ className }) => {
  return (
    <Empty type="image2" className={cn(classes.wrapper, className)}>
      <span>No alerts have been triggered yet</span>
    </Empty>
  );
};

export default NotFound;
