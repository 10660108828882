import React, { useEffect } from 'react';
import cn from 'classnames';
import Form, { useForm } from 'components/Form';
import {
  Title,
  SectionWrapper,
} from 'views/pages/ClientDetail/components/Elements';
import OtherIntegrateSection from 'views/pages/ClientDetail/components/OtherIntegrateSection';
import ButtonActions from 'views/pages/ClientDetail/components/ButtonActions';
import BOC3 from './BOC3';
import UCR from './UCR';
import SCAC from './SCAC';
import TXDOT from './TXDOT';
import CANumber from './CANumber';
import Filling2290 from './Filling2290';
import { getInitialValues } from '../../tabUtils/general';
import classes from './General.module.scss';

const General = ({
  className,
  wrapperId,
  handleSubmit,
  isEdit,
  setIsEdit,
  isFormChange,
  loading,
  openWarningModal,
  data,
  setIsFormChanged,
  onFormChange,
  years,
  handleYearSubmit,
}) => {
  const [form] = useForm();
  const initialValues = getInitialValues(data);
  useEffect(() => {
    if (isEdit) {
      if (form) {
        form.resetFields();
        setIsFormChanged(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  return (
    <SectionWrapper className={cn(classes.wrapper, className)} id={wrapperId}>
      <Form
        validateMessages={''}
        onFinish={values => {
          handleSubmit(values, form);
        }}
        initialValues={initialValues}
        form={form}
        onValuesChange={() => {
          setIsFormChanged(true);
          onFormChange();
        }}
      >
        <div>
          <Title text="BOC3 - Blanket of Coverage">
            <ButtonActions
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              form={form}
              isFormChanged={isFormChange}
              loading={loading}
              className={classes.buttonActions}
              onSubmit={values => {
                handleSubmit(values, form);
              }}
              openWarningModal={openWarningModal}
            />
          </Title>
          <BOC3 isEdit={isEdit} form={form} data={data} />
        </div>
        <div>
          <Title text="UCR - Unified Carrier Registration" />
          <UCR
            isEdit={isEdit}
            form={form}
            data={data}
            years={years}
            handleYearSubmit={handleYearSubmit}
            initialValues={initialValues}
          />
        </div>
        <div>
          <Title text="SCAC - Standard Carrier Alpha Code" />
          <SCAC isEdit={isEdit} form={form} data={data} />
        </div>
        <div>
          <Title text="TX DOT Number" />
          <TXDOT isEdit={isEdit} form={form} data={data} />
        </div>
        <div>
          <Title text="CA Number" />
          <CANumber isEdit={isEdit} form={form} data={data} />
        </div>
        <div>
          <Title text="2290 Filings" />
          <Filling2290
            isEdit={isEdit}
            form={form}
            data={data}
            years={years}
            handleYearSubmit={handleYearSubmit}
            initialValues={initialValues}
          />
        </div>
        <OtherIntegrateSection
          isEdit={isEdit}
          form={form}
          other={data.custom_fields || []}
          className={classes.other}
        />
      </Form>
    </SectionWrapper>
  );
};

export default General;
