import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import find from 'lodash/find';
import { validateForm, scrollToErrorField } from 'utils/form';
import Form, { useForm } from 'components/Form';
import OtherIntegrateSection from 'views/pages/ClientDetail/components/OtherIntegrateSection';
import { useOptions, useIsInternalUser } from 'helper/hooks';
import HeaderActions from '../HeaderActions';
import Profile from './Profile';
import Address from './Address';
import DriverLicense from './DriverLicense';
import MedicalCard from './MedicalCard';
import {
  isDriverLicenseSection,
  isMedicalCardSection,
  validateObj,
  getInitialValues,
} from '../../utils';
import classes from './Section.module.scss';

const Section = ({
  className,
  isActive,
  isEdit,
  setIsEdit,
  onFormChange,
  openWarningModal,
  rootKey,
  data,
  onSubmit,
  onStatusChange,
  statusLoading,
  loading,
  clientId,
  isNew,
}) => {
  const { isInternalUser } = useIsInternalUser();
  const wrapperId = `company_officials_${rootKey}`;
  const [title, setTitle] = useState('');
  const { scTitleWithoutDriverOptions } = useOptions();
  const [isFormChange, setIsFormChanged] = useState(false);
  const [form] = useForm();
  const selectedTitle =
    find(scTitleWithoutDriverOptions, {
      value: isEdit ? title : data.title_id,
    }) || null;
  const handleTitleChange = val => {
    setTitle(val);
  };
  const handleSubmit = values => {
    const fieldErrors = validateForm(validateObj, values);
    if (fieldErrors.length > 0) {
      form.setFields(fieldErrors);
      scrollToErrorField(wrapperId);
    } else {
      onSubmit(values, () => {
        setIsEdit(false);
      });
    }
  };

  useEffect(() => {
    if (isEdit) {
      setIsFormChanged(false);
      setTitle(data.title_id || '');
      if (form) {
        form.resetFields();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  return (
    <div className={cn(classes.wrapper, className)} id={wrapperId}>
      <Form
        validateMessages={''}
        onFinish={handleSubmit}
        initialValues={getInitialValues(data, isInternalUser)}
        form={form}
        onValuesChange={() => {
          setIsFormChanged(true);
          onFormChange();
        }}
      >
        {isActive && (
          <HeaderActions
            className={classes.headerActions}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            form={form}
            isFormChange={isFormChange}
            loading={loading}
            handleSubmit={handleSubmit}
            openWarningModal={openWarningModal}
            status={data?.status}
            onStatusChange={onStatusChange}
            statusLoading={statusLoading}
            inSection
          />
        )}
        <Profile
          isEdit={isEdit}
          form={form}
          data={data}
          onTitleChange={handleTitleChange}
          clientId={clientId}
          isNew={isNew}
        />
        <Address isEdit={isEdit} form={form} data={data} />
        {isDriverLicenseSection(selectedTitle) && (
          <DriverLicense
            isEdit={isEdit}
            form={form}
            data={data}
            clientId={clientId}
            isNew={isNew}
          />
        )}
        {isMedicalCardSection(selectedTitle) && (
          <MedicalCard isEdit={isEdit} form={form} data={data} />
        )}
        <OtherIntegrateSection
          isEdit={isEdit}
          form={form}
          other={data.custom_fields || []}
          otherName="custom_fields"
        />
      </Form>
    </div>
  );
};

export default Section;
