import React from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES from 'routes';
import PlaceholderIcon from 'assets/icons/Placeholder.svg';
import classes from './Success.module.scss';

const CreateAccountSuccess = () => {
  const history = useHistory();
  return (
    <div className={classes.wrapper}>
      <img
        src={PlaceholderIcon}
        alt="confirm-email"
        className={classes.successImg}
      />
      <p>
        Your account was created successfully!
        <br />
        <br />
        Please{' '}
        <span
          onClick={() => {
            history.push(ROUTES.LOGIN());
          }}
        >
          click here
        </span>{' '}
        {'to sign in to RoadOffice.'}
      </p>
    </div>
  );
};

export default CreateAccountSuccess;
