import Request from './request';

// GET
export const apiSearchDotNumber = async data => {
  return Request.call({
    url: `/search-dot-number`,
    method: 'GET',
    params: data,
  });
};
