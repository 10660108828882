import React from 'react';
import cn from 'classnames';
import Tag from 'components/Tag';
import { departmentColorMapping } from './utils';
import classes from './Elements.module.scss';

const DepartmentTag = ({ department, departmentColor }) => {
  return (
    <Tag
      size="small"
      className={cn(
        classes.departmentTag,
        classes[departmentColorMapping[departmentColor]]
      )}
    >
      {department ? department.replace(' Department', '') : ''}
    </Tag>
  );
};

export default DepartmentTag;
