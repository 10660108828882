import React from 'react';
import { PlusCircleFilled } from '@ant-design/icons';
import Empty, { EMPTY_SIZE } from 'components/Empty';
import Button, { ButtonColors } from 'components/Button';

import classes from './OrderTypes.module.scss';

const NotOrderTypesFound = ({ onClick }) => {
  return (
    <div className={classes.notOrderTypesFound}>
      <Empty type="image2" size={EMPTY_SIZE.LARGE}>
        <h3>No order types have been created yet.</h3>
        <Button
          type="primary"
          size="large"
          className={classes.addCButton}
          onClick={onClick}
          color={ButtonColors.BLUE}
          leftIcon={<PlusCircleFilled />}
          largeIcon
        >
          Create New Order Type
        </Button>
      </Empty>
    </div>
  );
};

export default NotOrderTypesFound;
