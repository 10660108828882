import React, { useState } from 'react';
import { validateForm, scrollToErrorField } from 'utils/form';
import Tabs from 'components/Tabs';
import { getLast5Years } from 'utils/date';
import { Title } from '../Elements';
import General from './tabs/General';
import IRP from './tabs/IRP';
import IFTA from './tabs/IFTA';
import Kentucky from './tabs/Kentucky';
import NewMexico from './tabs/NewMexico';
import NewYork from './tabs/NewYork';
import Oregon from './tabs/Oregon';
import { TAB_NAME_MAPPING, TAB_DATA_MAPPING } from './constants';
import { validateObj } from './utils';
import classes from './Filling.module.scss';

const Filling = ({
  // className,
  // isEdit,
  setIsEdit: sectionSetIsEdit,
  rootKey,
  data,
  onFormChange,
  openWarningModal,
  onSubmit,
  loading,
}) => {
  const tYears = getLast5Years();
  const tData = data;
  const wrapperId = `record_information_${rootKey}`;
  const [activeTab, setActiveTab] = useState('1');
  const [isFormChange, setIsFormChanged] = useState(false);
  const [editTab, setEditTab] = useState({});
  const handleSubmit = (values, form) => {
    const fieldErrors = validateForm(
      validateObj(TAB_NAME_MAPPING[activeTab]),
      values
    );
    if (fieldErrors.length > 0) {
      form.setFields(fieldErrors);
      scrollToErrorField(`${wrapperId}_${TAB_NAME_MAPPING[activeTab]}`);
    } else {
      onSubmit(
        values,
        () => {
          setEditTab({});
          setIsFormChanged(false);
        },
        TAB_NAME_MAPPING[activeTab]
      );
    }
  };
  const handleYearSubmit = (values, cb) => {
    onSubmit(
      values,
      () => {
        if (cb) cb();
        setIsFormChanged(false);
      },
      TAB_NAME_MAPPING[activeTab],
      `${TAB_NAME_MAPPING[activeTab]}_year`,
      cb
    );
  };
  const handleGlobalEdit = newEditTab => {
    sectionSetIsEdit(
      Object.keys(newEditTab).filter(k => !!newEditTab[k]).length > 0
    );
  };
  const getTabProps = tabName => {
    const isEdit = !!editTab[tabName];
    const setIsEdit = val => {
      const newEditTab = {
        ...editTab,
        [tabName]: !!val,
      };
      setEditTab(newEditTab);
      handleGlobalEdit(newEditTab);
      if (!val) {
        setIsFormChanged(false);
      }
    };
    return {
      wrapperId: `${wrapperId}_${tabName}`,
      handleSubmit,
      isEdit,
      setIsEdit,
      isFormChange,
      loading,
      openWarningModal,
      data: tData[TAB_DATA_MAPPING[tabName]] || {},
      setIsFormChanged,
      onFormChange,
      years: tYears,
      handleYearSubmit,
    };
  };

  return (
    <>
      <Tabs
        activeKey={activeTab}
        onChange={activeKey => {
          const changeTabFn = () => {
            setActiveTab(activeKey);
            setEditTab({});
            setIsFormChanged(false);
          };
          if (isFormChange) {
            openWarningModal(() => {
              changeTabFn();
            });
          } else {
            changeTabFn();
          }
        }}
        tabs={[
          {
            title: 'General Filings',
            content: (
              <>
                <Title text="General Filings" nonBorder big />
                <General {...getTabProps(TAB_NAME_MAPPING['1'])} />
              </>
            ),
          },
          {
            title: 'IRP Information',
            content: (
              <>
                <Title
                  text="IRP - International Registration Plan"
                  nonBorder
                  big
                />
                <IRP {...getTabProps(TAB_NAME_MAPPING['2'])} />
              </>
            ),
          },
          {
            title: 'IFTA Information',
            content: (
              <>
                <Title
                  text="IFTA - International Fuel Tax Agreement"
                  nonBorder
                  big
                />
                <IFTA {...getTabProps(TAB_NAME_MAPPING['3'])} />
              </>
            ),
          },
          {
            title: 'Oregon Permit',
            content: (
              <>
                <Title text="Oregon Weight Distance Taxes" nonBorder big />
                <Oregon {...getTabProps(TAB_NAME_MAPPING['4'])} />
              </>
            ),
          },
          {
            title: 'New Mexico Permit',
            content: (
              <>
                <Title text="New Mexico Weight Distance Taxes" nonBorder big />
                <NewMexico {...getTabProps(TAB_NAME_MAPPING['5'])} />
              </>
            ),
          },
          {
            title: 'Kentucky Permit',
            content: (
              <>
                <Title text="Kentucky Weight Distance Taxes" nonBorder big />
                <Kentucky {...getTabProps(TAB_NAME_MAPPING['6'])} />
              </>
            ),
          },
          {
            title: 'New York Permit',
            content: (
              <>
                <Title text="New York Weight Distance Taxes" nonBorder big />
                <NewYork {...getTabProps(TAB_NAME_MAPPING['7'])} />
              </>
            ),
          },
        ]}
        className={classes.tabs}
      />
    </>
  );
};

export default Filling;
