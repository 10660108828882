const initialClientState = {
  // GET
  allClients: {
    loading: false,
    data: [],
    totalNo: 0,
    isMore: false,
    error: '',
  },
  archivedClients: {
    loading: false,
    data: [],
    totalNo: 0,
    isMore: false,
    error: '',
  },
  // POST
  createClient: {
    loading: false,
    data: {},
    error: '',
  },
};

export { initialClientState };
