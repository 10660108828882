import React, { forwardRef, useImperativeHandle } from 'react';
import cn from 'classnames';
import find from 'lodash/find';
import { Divider } from 'antd';
import { FormItem } from 'components/Form';
import classes from '../../NewOrderModal.module.scss';
import { ListInfo, Title } from 'views/pages/ClientDetail/components/Elements';
import { ACCOUNTS_LIST, CARDS_LIST } from '../../utils';
import { ORDER_SUMMARY_OBJ_KEYS } from '../../constants';
import { useNewOrderModalData } from '../../../../selectorData';
import { getCurrencyValue } from '../../../../../../../utils/number';

const ConfirmStep = forwardRef(({ form, style }, ref) => {
  const data = form.getFieldsValue();
  const {
    orderTypes,
    orderRequesters,
    detailData,
    accounts,
    cards,
    currentUser,
    orderTypeDetail,
  } = useNewOrderModalData();
  const CONFIRM_STEP_DATA = () => {
    const formData = {
      notes: data.notes,
      company_name: detailData.legal_name,
      order_submitted_by: `${currentUser.first_name ||
        ''} ${currentUser.last_name || ''}`.trim(),
    };
    const orderType = find(orderTypes, {
      value: data.order_type_id,
    });
    if (orderType) {
      formData.order_type = orderType.label;
    }
    const orderRequester = find(orderRequesters, {
      value: data.requested_by,
    });
    if (orderRequester) {
      formData.order_requested_by = orderRequester.label;
    }
    if (data.payment_method_id === 0) {
      if (data.payment_method === 'account') {
        const account = data.account;
        if (account.bank_name && account.account_number) {
          formData.payment_method = [
            {
              label: 'Account',
            },
            {
              label:
                account.bank_name +
                ' ****' +
                account.account_number.substr(
                  account.account_number.length - 4
                ),
            },
          ];
        }
      } else {
        const card = data.card;
        if (card && card.card_number) {
          formData.payment_method = [
            {
              label: 'Credit/Debit Card',
            },
            {
              label: `${
                card.name_on_card ? card.name_on_card : ''
              } ****${card.card_number.substr(card.card_number.length - 4)}`,
            },
          ];
        }
      }
    } else {
      const paymentMethods =
        data.payment_method === 'account'
          ? ACCOUNTS_LIST(accounts)
          : CARDS_LIST(cards);
      const paymentMethod = find(paymentMethods, {
        value: data.payment_method_id,
      });
      if (paymentMethod) {
        formData.payment_method = [
          {
            label:
              data.payment_method === 'account'
                ? 'Account'
                : 'Credit/Debit Card',
          },
          { label: paymentMethod.label },
        ];
      }
    }
    return formData;
  };
  useImperativeHandle(ref, () => ({
    validateStep(cb) {
      cb(true, true);
    },
  }));
  return (
    <div
      style={style}
      className={cn(classes.stepContent, classes.paymentWrapper)}
    >
      <div className={classes.paymentInnerContent}>
        <div className={classes.orderSummary}>
          <p>Let’s confirm the order summary before submitting the order.</p>
          <Title text="Order Summary" />
          <ListInfo
            className={classes.summaryList}
            objKeys={ORDER_SUMMARY_OBJ_KEYS}
            data={CONFIRM_STEP_DATA()}
          />
        </div>
        <div className={classes.paymentOverview}>
          <div className={classes.paymentOverviewInnerContent}>
            <FormItem shouldUpdate>
              {() => {
                const values = form.getFieldsValue();
                return (
                  <>
                    {values.rush_order && values.rush_order.length > 0 ? (
                      <>
                        <div className={classes.subtotal}>
                          <h4>Order Price</h4>
                          <h4>{getCurrencyValue(Number(values.price))}</h4>
                        </div>
                        <div className={classes.subtotal}>
                          <h4>Rush Order</h4>
                          <h4>
                            + $
                            {orderTypeDetail.rush_amount
                              ? orderTypeDetail.rush_amount.toFixed(2)
                              : '0.00'}
                          </h4>
                        </div>
                        <Divider />
                        <div className={classes.grandTotal}>
                          <h4>Total</h4>
                          <h4>
                            {getCurrencyValue(
                              Number(values.price) +
                                Number(orderTypeDetail.rush_amount)
                            )}
                          </h4>
                        </div>
                      </>
                    ) : (
                      <div className={classes.grandTotal}>
                        <h4>Total</h4>
                        <h4>{getCurrencyValue(values.price)}</h4>
                      </div>
                    )}
                  </>
                );
              }}
            </FormItem>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ConfirmStep;
