import { useReducerAttribute } from 'store/hooks';
import { capitalizeFirstLetter } from 'utils/text';

export const useReportTabs = () => {
  const reportFields = useReducerAttribute('report', 'reportFields.data', {});
  const reportTabs = Object.keys(reportFields).map((key, i) => ({
    intKey: i + 1,
    key,
    label: capitalizeFirstLetter(key),
    fields: (reportFields[key] || []).map(field => ({
      value: field.field_id,
      label: field.label,
    })),
  }));
  return { reportTabs };
};
