import React from 'react';
import cx from 'classnames';
import { Breadcrumb as AntdBreadcrumb } from 'antd';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import Link from 'components/Link';

import classes from './Breadcrumb.module.scss';

const Breadcrumb = props => {
  const { className, links, ...other } = props;
  return (
    <AntdBreadcrumb {...other} className={cx(classes.wrapper, className)}>
      {links.map((l, i) => {
        return (
          <AntdBreadcrumb.Item
            key={i}
            overlay={l.overlay}
            className={cx(classes.breadcrumbItem, {
              [classes.isBlue]: l.color === 'blue',
            })}
          >
            {l.to ? (
              <Link to={l.to}>
                {l.icon}
                {l.label}
              </Link>
            ) : (
              <>
                {l.icon}
                {l.label}
              </>
            )}
          </AntdBreadcrumb.Item>
        );
      })}
    </AntdBreadcrumb>
  );
};

Breadcrumb.defaultProps = {
  links: [
    {
      to: '#',
      icon: <HomeOutlined />,
    },
    {
      to: '#',
      label: 'Second Link',
      icon: <UserOutlined />,
    },
    {
      label: 'Current Page',
    },
  ],
};

Breadcrumb.propTypes = {
  links: PropTypes.array,
};

export default Breadcrumb;
