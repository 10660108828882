import React from 'react';

import Empty from 'components/Empty';
import Space from 'components/Space';

const EmptyComponent = ({ classes }) => {
  return (
    <Space>
      <Empty type="image1" />
      <Empty type="image2" />
      <Empty type="image3" />
      <Empty type="image4" />
      <Empty type="image5" />
    </Space>
  );
};

export default EmptyComponent;
