import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'components/FormFields';
import Table from 'components/Table';
import ROUTES from 'routes';
import { getErrorMessageFromObj } from 'utils/object';
import Pagination from 'components/Pagination';
import { PAGINATION, STATUS_VALUES } from 'helper/const';
import { onEditRow } from 'utils/table';
import { applySearch } from 'utils';
import toast from 'helper/toast';
import { useActions, useIndexData } from './selectorData';
import { orderColumns } from './utils';
import classes from './Orders.module.scss';

const OrdersTab = ({ clientId, onEditDraftOrder, permission }) => {
  const notAdmin = !permission.isAdmin;
  const history = useHistory();
  const [keyword, setKeyword] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  // Ignoring for future API
  // eslint-disable-next-line
  const [checkedRows, setCheckedRows] = useState([]);
  const {
    orderList,
    orderListLoading,
    orderTotal,
    deleteOrderLoading,
  } = useIndexData();
  const { getOrderList, deleteOrder } = useActions();
  const reloadData = (val, page) => {
    setCurrentPage(page);
    getOrderList(clientId, {
      page,
      search: val,
    });
  };
  const handleSearch = val => {
    reloadData(val, 1);
  };
  const goToOrderDetailPage = id => {
    if (notAdmin) {
      history.push(
        ROUTES.COMPANY_ORDER({
          id: clientId,
          orderId: id,
        })
      );
    } else {
      history.push(
        ROUTES.CLIENT_ORDER_DETAIL({
          id: clientId,
          orderId: id,
        })
      );
    }
  };
  const onView = record => {
    goToOrderDetailPage(record.id);
  };
  const onDelete = record => {
    deleteOrder(clientId, record.id, (res, err) => {
      if (res) {
        reloadData(keyword, 1);
        toast.success('Order deleted successfully.');
      } else {
        toast.error(getErrorMessageFromObj(err)[0]);
      }
    });
  };
  const onEditDraft = record => {
    onEditDraftOrder(record);
  };
  const columns = orderColumns({
    onView,
    onDelete,
    onEditDraft,
  });
  return (
    <div className={classes.ordersTab}>
      <div className={classes.tabContentHeader}>
        <Input
          size={'medium'}
          name="keyword"
          value={keyword}
          placeholder={`Search orders`}
          allowClear
          onChange={e => {
            const value = e.target.value;
            setKeyword(value);
            if (!value) {
              handleSearch('');
            } else {
              applySearch(value, val => {
                handleSearch(val);
              });
            }
          }}
          prefix={<SearchOutlined />}
          isBlue
        />
      </div>
      <Table
        rowSelection={{
          onChange: selectedRowKeys => {
            setCheckedRows(selectedRowKeys);
          },
        }}
        columns={columns}
        data={orderList}
        rowKey="id"
        loading={orderListLoading || deleteOrderLoading}
        isScroll
        scroll={{ x: 850 }}
        rowIsClick
        onRow={record => {
          return {
            className: record.highlight ? classes.highlightRow : '',
            onClick: event => {
              onEditRow(event, () => {
                if (record.status === STATUS_VALUES.DRAFT) {
                  onEditDraft(record);
                } else {
                  goToOrderDetailPage(record.id);
                }
              });
            },
          };
        }}
      />
      {orderTotal > 0 && (
        <Pagination
          current={currentPage}
          pageSize={PAGINATION.PER_PAGE}
          total={orderTotal}
          right
          onChange={page => {
            reloadData(keyword, page);
          }}
          className={classes.pagination}
        />
      )}
    </div>
  );
};

export default OrdersTab;
