import Request from './request';

// GET
export const apiGetChatConversations = async filters => {
  return Request.call({
    url: '/chats',
    method: 'GET',
    params: filters,
  });
};
export const apiGetChatDepartments = async () => {
  return Request.call({
    url: '/chats/departments',
    method: 'GET',
  });
};
export const apiGetChatDepartmentsList = async () => {
  return Request.call({
    url: '/chats/departments-list',
    method: 'GET',
  });
};
export const apiGetMessages = async (conversationId, data = {}) => {
  return Request.call({
    url: `/chats/messages/${conversationId}`,
    method: 'POST',
    data,
  });
};
export const apiGetAttachment = async id => {
  return Request.call({
    url: `/chats/attachment/${id}`,
    method: 'GET',
  });
};
export const apiSearchMembers = async (filters = {}) => {
  return Request.call({
    url: `/chats/members`,
    method: 'GET',
    params: filters,
  });
};
export const apiGetChatMembers = async conversationId => {
  return Request.call({
    url: `/chats/members/${conversationId}`,
    method: 'GET',
  });
};

// POST
export const apiSendMessage = async data => {
  return Request.call({
    url: '/chats/send',
    method: 'POST',
    data,
  });
};
export const apiMarkRead = async (conversationId, data) => {
  return Request.call({
    url: `/chats/mark-read/${conversationId}`,
    method: 'POST',
    data,
  });
};
export const apiUpdateChatSettings = async (conversationId, data) => {
  return Request.call({
    url: `/chats/settings/${conversationId}`,
    method: 'POST',
    data,
  });
};
export const apiAddMembers = async (conversationId, data) => {
  return Request.call({
    url: `/chats/members/add/${conversationId}`,
    method: 'POST',
    data,
  });
};
export const apiRemoveMembers = async (conversationId, data) => {
  return Request.call({
    url: `/chats/members/remove/${conversationId}`,
    method: 'POST',
    data,
  });
};
export const apiCheckConversation = async data => {
  return Request.call({
    url: `/chats/check-conversation`,
    method: 'POST',
    data,
  });
};
export const apiCreateChatGroup = async data => {
  return Request.call({
    url: `/chats/create-group`,
    method: 'POST',
    data,
  });
};
