export const NEW_KEY = 'new_ucc_filing';
export const NORMAL_FIELDS = [
  'company_name',
  'filing_number',
  'notes',
  'other',
];
export const NULL_FIELDS = [];
export const NUMBER_FIELDS = ['filing_number'];
export const DATE_FIELDS = ['expiration_date', 'effective_date'];
export const ARR_FIELDS = ['collateral'];
export const FILE_FIELDS = ['documents.ucc_filing'];
export const BOOLEAN_FIELDS = [];
export const BODY_REQUEST_NORMAL_FIELDS = ['company_name', 'notes', 'other'];
export const DEFAULT_TRUCK = {
  key: 'section 1',
  id: 10,
  label: '435',
  status: 'active',
};
export const DEFAULT_SECTIONS = [];

export const PROFILE_OBJ_KEYS = [
  {
    label: 'Company Name',
    key: 'company_name',
    type: 'value',
  },
  {
    label: 'UCC Filing',
    key: 'documents.ucc_filing',
    type: 'file',
  },
  {
    label: 'Effective Date',
    key: 'effective_date',
    type: 'date',
  },
  {
    label: 'Expiration Date',
    key: 'expiration_date',
    type: 'date',
  },
  {
    label: 'Collateral',
    key: 'collateral',
    type: 'multiValue',
  },
  {
    label: 'Filing Number',
    key: 'filing_number',
    type: 'value',
  },
  {
    label: 'Other',
    key: 'other',
    type: 'value',
    dependField: 'collateral',
    dependFn: val => {
      return !((val || []).filter(v => v.label === 'Other').length > 0);
    },
    fullWidth: true,
  },
  {
    label: 'Notes',
    key: 'notes',
    type: 'value',
    fullWidth: true,
  },
];
