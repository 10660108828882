import React from 'react';
import { MODAL } from 'helper/const';
import {
  standardRequiredFieldObj,
  emailFieldObj,
  phoneFieldObj,
} from 'utils/form';
import classes from './InviteTeammateModal.module.scss';

export const validateObj = permission => {
  const detailFields = permission.detailFields || [];
  return {
    first_name: [standardRequiredFieldObj],
    last_name: [standardRequiredFieldObj],
    email: detailFields.includes('email')
      ? [standardRequiredFieldObj, emailFieldObj]
      : [emailFieldObj],
    alternate_email: [emailFieldObj],
    phone_number: [phoneFieldObj],
    group_id: detailFields.includes('group_id')
      ? [standardRequiredFieldObj]
      : [],
    role_id: detailFields.includes('role_id') ? [standardRequiredFieldObj] : [],
    start_date: detailFields.includes('start_date')
      ? [standardRequiredFieldObj]
      : [],
  };
};
export const getInitialValues = groupId => {
  return {
    first_name: '',
    mi: '',
    last_name: '',
    email: '',
    alternate_email: '',
    phone_number: '',
    dob: '',
    group_id: groupId || null,
    role_id: null,
    start_date: '',
    employee_id: '',
  };
};
export const getModalProps = ({
  isOpen,
  form,
  handleSubmitForm,
  handleClose,
  loading,
}) => {
  return {
    isOpen,
    onSubmit: () => {
      const values = form.getFieldsValue();
      handleSubmitForm(values);
    },
    onClose: handleClose,
    title: <>Invite Teammate</>,
    maskClosable: false,
    closable: true,
    cancelText: 'Cancel',
    okText: 'Send Invite',
    width: MODAL.SIZE.SEMI_LARGE + 10,
    isSubmitLoading: loading,
    className: classes.modal,
    closeIcon: null,
    blueColor: true,
    okBtnProps: {
      size: 'medium',
    },
    cancelBtnProps: {
      size: 'medium',
      type: 'default',
    },
  };
};
