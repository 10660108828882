// Fields for view mode
export const COMPANY_INFO_OBJ_KEYS = [
  {
    label: 'Legal Company Name',
    key: 'legal_name',
    type: 'value',
  },
  {
    label: 'DBA - Doing Business As',
    key: 'dba',
    type: 'value',
  },
  {
    label: 'Phone',
    key: 'phone_number',
    type: 'formatValue',
  },
  {
    label: 'DOT Number',
    key: 'dot_number',
    type: 'value',
  },
  {
    label: 'EIN - Employer Identification Number',
    key: 'ein',
    type: 'value',
  },
  {
    label: 'MC Number',
    key: 'mc_number',
    type: 'value',
  },
  {
    label: 'Company Physical Address',
    key: 'physical_address',
    type: 'value',
  },
  {
    label: 'Company Mailing Address',
    key: 'mailing_address',
    type: 'value',
  },
];

export const dotNumberQuestion = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];
