import React, { useState } from 'react';
import cn from 'classnames';
import Tabs from 'components/Tabs';
import { useReportTabs } from 'utils/report';
import { actionCb } from 'utils/action';
import { downloadCSVFile } from 'utils/file';
import { getMomentDateWithFormat } from 'utils/date';
import Header from './Header';
import Content from './Content';
import LoadingSpin from './LoadingSpin';
import ReportTab from './ReportTab';
import { useActions, useIndexData } from './selectorData';
import { getFilters } from './utils';
import classes from './MyReports.module.scss';

const MyReports = () => {
  const { getReports, downloadReports } = useActions();
  const { reportData } = useIndexData();
  const { reportTabs } = useReportTabs();
  const [activeTab, setActiveTab] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [activeFields, setActiveFields] = useState({});
  const [activeClients, setActiveClients] = useState({});
  const isNotFound = false;
  const handleGetReports = key => ({
    page,
    keyword,
    tFields,
    tClients,
    tSort,
    cb,
  }) => {
    getReports(
      getFilters({
        page,
        key,
        keyword,
        fields: tFields,
        clients: tClients,
        download: false,
        sort: tSort,
      }),
      actionCb(cb)
    );
  };
  const handleDownloadReport = (key, rFields) => ({
    page,
    keyword,
    tFields,
    tClients,
    tSelectedRows,
    tSort,
    cb,
  }) => {
    downloadReports(
      getFilters({
        page,
        key,
        keyword,
        fields: tFields,
        clients: tClients,
        download: true,
        selectedRows: tSelectedRows,
        sort: tSort,
        rFields,
      }),
      (res, err) => {
        if (cb) cb();
        downloadCSVFile(
          err,
          `report_${key}_${getMomentDateWithFormat(new Date(), 'YYYY_MM_DD')}`
        );
      }
    );
  };
  const comonProps = {
    keyword,
    setKeyword,
    activeTab,
  };
  const tabs = reportTabs.map(tab => {
    return {
      title: <span>{tab.label}</span>,
      content: (
        <ReportTab
          {...comonProps}
          fields={tab.fields}
          currentTab={tab.intKey}
          handleGetReports={handleGetReports(tab.key)}
          handleDownloadReport={handleDownloadReport(tab.key, tab.fields)}
          activeFields={activeFields[tab.key] || ['all']}
          setActiveFields={f => {
            setActiveFields({
              ...activeFields,
              [tab.key]: f,
            });
          }}
          activeClients={activeClients[tab.key] || ['all']}
          setActiveClients={f => {
            setActiveClients({
              ...activeClients,
              [tab.key]: f,
            });
          }}
          data={reportData[tab.key] || {}}
        />
      ),
    };
  });

  return (
    <LoadingSpin>
      <div
        className={cn(classes.wrapper, {
          [classes.isNotFound]: isNotFound,
        })}
      >
        <Header />
        <Content>
          <Tabs
            tabs={tabs}
            activeKey={activeTab.toString()}
            onChange={activeKey => {
              const iActiveKey = parseInt(activeKey, 10);
              setActiveTab(iActiveKey);
            }}
            spaceTop
          />
        </Content>
      </div>
    </LoadingSpin>
  );
};

export default MyReports;
