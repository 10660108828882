import React from 'react';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import classes from './OrderDetail.module.scss';

export const getFieldValue = field => {
  let returnValue = '-';
  const fieldValue = field.field_value;
  if (typeof fieldValue !== 'object') {
    returnValue = fieldValue || fieldValue === 0 ? fieldValue : '-';
  }
  if (field.type === 'select') {
    if (field.multiselect === true) {
      const fieldValues = isArray(fieldValue)
        ? fieldValue
        : fieldValue && typeof fieldValue === 'string'
        ? JSON.parse(fieldValue)
        : [];
      const thisOptions = (field.options || []).filter(o =>
        fieldValues.includes(String(o.value))
      );
      if (thisOptions.length > 0) {
        returnValue = (
          <>
            {thisOptions.map((o, i) => {
              return (
                <React.Fragment key={i}>
                  {o.label}
                  <br />
                </React.Fragment>
              );
            })}
          </>
        );
      }
    } else {
      const thisOption =
        find(field.options, {
          value: parseInt(fieldValue, 10),
        }) || {};
      returnValue = thisOption.label || '-';
    }
  } else if (field.type === 'file') {
    const fieldValuesDisplay = isArray(fieldValue)
      ? fieldValue
      : fieldValue && typeof fieldValue === 'string'
      ? JSON.parse(fieldValue)
      : [];
    returnValue = (
      <div className={classes.displayFileList}>
        {fieldValuesDisplay.length === 0 && '-'}
        {fieldValuesDisplay.map(f => {
          return (
            <a
              key={f.id}
              href={f.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {f.document_name}
            </a>
          );
        })}
      </div>
    );
  }
  return returnValue;
};
