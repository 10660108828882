import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { getMomentDate } from 'utils/date';
import { scrollToErrorField, validateForm } from 'utils/form';
import { Select, Checkbox } from 'components/FormFields';
import Button, { ButtonColors } from 'components/Button';
import { FormItem } from 'components/Form';
import {
  useActions,
  useNewOrderModalData,
} from 'views/pages/ClientDetail/selectorData';
import {
  FormSection,
  Title,
} from 'views/pages/ClientDetail/components/Elements';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import classes from '../../NewOrderModal.module.scss';
import {
  orderDetailsValidateObj,
  getOtherFieldPrefix,
  isPrimaryContactField,
  getFieldNameWithIndex,
  getIsArrayField,
  getIsPCRequired,
  getIsMainSelectField,
  getFieldPrefixFromCategory,
} from '../../utils';
import { updateOtherPrimaryContact, filterMainOptions } from './utils';

const OrderDetailsStep = forwardRef(
  (
    {
      form,
      style,
      currentStep,
      formDetailsFieldPopulated,
      setFormDetailsFieldPopulated,
      isEditOrder,
      addItems,
      setAddItems,
    },
    ref
  ) => {
    const handleUpdateAddItems = (data, key) => {
      if (!!key) {
        setAddItems({
          ...addItems,
          [key]: [...data],
        });
      }
    };
    const { getOrderDetailFieldSubRecords } = useActions();
    const { orderDetailFields, detailData } = useNewOrderModalData();
    useImperativeHandle(ref, () => ({
      validateStep(cb) {
        const values = form.getFieldsValue();
        const fieldErrors = validateForm(
          orderDetailsValidateObj(orderDetailFields, addItems, form),
          values
        );
        if (fieldErrors.length > 0) {
          form.setFields(fieldErrors);
          scrollToErrorField();
        } else {
          setFormDetailsFieldPopulated(true);
          cb(true);
        }
      },
    }));
    const handleInputChange = (value, fieldName, cat) => {
      const tFieldName = `${getOtherFieldPrefix(cat)}${fieldName}`;
      form.setFieldsValue({
        [tFieldName]: value,
      });
    };
    const emptyOrderDetailsFields = categories => {
      categories.fields.map(fields => {
        fields.fields.map(field => {
          if (!field.is_subrecord_selector && field.require_multi_record) {
            if (field.type === 'select' && field.multiselect) {
              form.setFieldsValue({
                [`field-${field.field_id}`]: [],
              });
            } else {
              form.setFieldsValue({
                [`field-${field.field_id}`]: null,
              });
            }
          }
          return field;
        });
        return fields;
      });
    };
    const loadSubrecords = (value, field_id, categories, nameIndex) => {
      if (value === '0') {
        emptyOrderDetailsFields(categories);
        return;
      }
      const values = form.getFieldsValue();
      const thisCategory = (field_id || '').replace('field-', '');
      getOrderDetailFieldSubRecords(
        detailData.id,
        values.order_type_id,
        value,
        thisCategory,
        response => {
          if (!response) {
            return;
          }
          emptyOrderDetailsFields(categories);
          response.data.forEach(fieldValue => {
            if (
              fieldValue.value !== null &&
              !(fieldValue.field_name || '').includes('is_primary')
            ) {
              let value = fieldValue.value;
              if (fieldValue.type === 'date' && value) {
                value = getMomentDate(value, true);
              }
              const fieldName = getFieldNameWithIndex(fieldValue, nameIndex);
              form.setFieldsValue({ [fieldName]: value });
            }
          });
        }
      );
    };
    useEffect(() => {
      if (!isEditOrder) {
        orderDetailFields.map(categories => {
          categories.fields.map(fields => {
            fields.fields.map(inputField => {
              if (
                !formDetailsFieldPopulated &&
                !inputField.is_subrecord_selector &&
                inputField.value
              ) {
                let value = inputField.value;
                if (inputField.type === 'date' && value) {
                  value = getMomentDate(value, true);
                }
                const tName = `${getOtherFieldPrefix(
                  fields.category
                )}${getFieldNameWithIndex(inputField, 0)}`;
                form.setFields([
                  {
                    name: tName,
                    value: value,
                  },
                ]);
              }
              return true;
            });
            return true;
          });
          return true;
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderDetailFields]);

    return (
      <div style={style} className={cn(classes.stepContent)}>
        <p>Fill out order details below.</p>
        <>
          {orderDetailFields.map((categories, key) => {
            const isArrayField = getIsArrayField(categories.parent_category);
            const fieldPrefix = getFieldPrefixFromCategory(categories);
            const tAddItems = addItems[fieldPrefix] || [];
            const tCategory = get(categories, 'fields[0].category', 'Official');
            const renderSections = parentNameIndex => {
              return (
                <>
                  {categories.fields.map((fields, categoryKey) => {
                    const renderFields = nameIndex => {
                      return (
                        <>
                          {fields.fields.map(inputField => {
                            const fieldName = getFieldNameWithIndex(
                              inputField,
                              nameIndex
                            );
                            const fieldNameWithOther = `${getOtherFieldPrefix(
                              fields.category
                            )}${fieldName}`;
                            const isPrimaryContact = isPrimaryContactField(
                              inputField
                            );
                            if (inputField.type === 'select') {
                              const isMainField = getIsMainSelectField(
                                fieldName
                              );
                              const thisOptions = inputField.options
                                ? inputField.options
                                : [];
                              return (
                                <FormItem
                                  key={`field-${inputField.field_id}`}
                                  shouldUpdate
                                >
                                  {() => {
                                    return (
                                      <FormItem
                                        className={classes.paymentMethod}
                                        name={fieldNameWithOther}
                                        label={inputField.name}
                                        required={inputField.is_required}
                                      >
                                        <Select
                                          className={
                                            !form.getFieldsValue()[fieldName]
                                          }
                                          name={fieldNameWithOther}
                                          form={form}
                                          placeholder={
                                            inputField.multiselect
                                              ? 'Select all that apply'
                                              : 'Select one'
                                          }
                                          onChange={value => {
                                            if (
                                              inputField.is_subrecord_selector
                                            ) {
                                              loadSubrecords(
                                                value,
                                                `field-${inputField.parent_category}`,
                                                categories,
                                                nameIndex
                                              );
                                            } else {
                                              handleInputChange(
                                                value,
                                                fieldName,
                                                fields.category
                                              );
                                            }
                                          }}
                                          size="medium"
                                          items={
                                            isMainField
                                              ? filterMainOptions(
                                                  thisOptions,
                                                  form,
                                                  fieldName,
                                                  tAddItems
                                                )
                                              : thisOptions
                                          }
                                          mode={
                                            inputField.multiselect
                                              ? 'multiple'
                                              : ''
                                          }
                                          isMultipleOptionCheckbox={
                                            inputField.multiselect
                                          }
                                          isBlue
                                          showArrow
                                          showSearch
                                          required={inputField.is_required}
                                          // do not floatting on modal
                                          getPopupContainer={trigger =>
                                            trigger.parentNode
                                          }
                                        />
                                      </FormItem>
                                    );
                                  }}
                                </FormItem>
                              );
                            } else if (inputField.type === 'checkbox') {
                              return (
                                <FormItem
                                  shouldUpdate
                                  key={`field-${inputField.field_id}`}
                                >
                                  {() => {
                                    return (
                                      <FormItem
                                        key={`field-${inputField.field_id}`}
                                        className={classes.paymentMethod}
                                        label={inputField.name}
                                        name={fieldName}
                                        valuePropName="checked"
                                        shouldUpdate
                                        required={
                                          isPrimaryContact
                                            ? getIsPCRequired({
                                                form,
                                                addItems: tAddItems,
                                                inputField,
                                                isRequired:
                                                  inputField.is_required,
                                                nameIndex,
                                              })
                                            : inputField.is_required
                                        }
                                      >
                                        <Checkbox
                                          className={
                                            !form.getFieldsValue()[fieldName]
                                              ? classes.inputHighlight
                                              : ''
                                          }
                                          label={inputField.name}
                                          required={
                                            isPrimaryContact
                                              ? getIsPCRequired({
                                                  form,
                                                  addItems: tAddItems,
                                                  inputField,
                                                  isRequired:
                                                    inputField.is_required,
                                                  nameIndex,
                                                })
                                              : inputField.is_required
                                          }
                                          onChange={e => {
                                            if (isPrimaryContact) {
                                              updateOtherPrimaryContact({
                                                form,
                                                addItems: tAddItems,
                                                nameIndex,
                                                inputField,
                                              });
                                            }
                                          }}
                                          customValidateName={fieldName}
                                          form={form}
                                        />
                                      </FormItem>
                                    );
                                  }}
                                </FormItem>
                              );
                            } else {
                              return (
                                <InputItem
                                  className={
                                    !form.getFieldsValue()[
                                      `${getOtherFieldPrefix(
                                        fields.category
                                      )}${fieldName}`
                                    ]
                                      ? classes.inputHighlight
                                      : ''
                                  }
                                  type={inputField.type}
                                  name={fieldNameWithOther}
                                  key={`${getOtherFieldPrefix(
                                    fields.category
                                  )}${fieldName}`}
                                  label={inputField.name}
                                  onChange={event => {
                                    if (event.target) {
                                      handleInputChange(
                                        event.target.value,
                                        fieldName,
                                        fields.category
                                      );
                                    }
                                  }}
                                  form={form}
                                  required={inputField.is_required}
                                  multiple={inputField.multiselect}
                                />
                              );
                            }
                          })}
                        </>
                      );
                    };
                    return (
                      <React.Fragment key={`title-${key}-${categoryKey}`}>
                        <Title
                          className={classes.subTitle}
                          text={fields.category}
                        />
                        <FormSection>
                          {renderFields(parentNameIndex)}
                        </FormSection>
                      </React.Fragment>
                    );
                  })}
                </>
              );
            };
            return (
              <div
                key={`parent-category-${key}`}
                className={cn({
                  [classes.parentSection]: key > 0,
                })}
              >
                {renderSections(0)}
                {isArrayField && (
                  <div>
                    {tAddItems.map(addItem => {
                      return (
                        <div key={addItem.id} className={classes.hasBorderTop}>
                          {renderSections(addItem.id)}
                          <div className={classes.removeSubrecord}>
                            <Button
                              isLink
                              color={ButtonColors.DARK_RED}
                              leftIcon={<MinusCircleOutlined />}
                              onClick={() => {
                                handleUpdateAddItems(
                                  tAddItems.filter(a => a.id !== addItem.id),
                                  fieldPrefix
                                );
                              }}
                            >
                              Remove this {tCategory}
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                    {tAddItems.length < 4 && (
                      <div className={classes.addItemBlock}>
                        <Button
                          onClick={() => {
                            if (tAddItems.length < 4) {
                              const lastItem =
                                tAddItems[tAddItems.length - 1] || {};
                              handleUpdateAddItems(
                                [
                                  ...tAddItems,
                                  {
                                    id: lastItem.id
                                      ? lastItem.id + 1
                                      : tAddItems.length + 1,
                                  },
                                ],
                                fieldPrefix
                              );
                            }
                          }}
                          isLink
                          color={ButtonColors.BLUE}
                          leftIcon={<PlusCircleOutlined />}
                        >
                          Add Additional {tCategory}
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </>
      </div>
    );
  }
);

export default OrderDetailsStep;
