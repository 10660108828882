import Request from './request';

// GET
export const apiGetChecklists = async (filters = {}) => {
  return Request.call({
    url: `/checklists`,
    method: 'GET',
    params: filters,
  });
};

// POST
export const apiCreateUpdateChecklist = async data => {
  return Request.call({
    url: `/checklists`,
    method: 'POST',
    data,
  });
};
export const apiCheckUncheckChecklist = async (id, checked) => {
  return Request.call({
    url: `/checklists/${id}/check-uncheck`,
    method: 'POST',
    data: { is_checked: checked },
  });
};
export const apiDeleteChecklist = async id => {
  return Request.call({
    url: `/checklists/${id}`,
    method: 'DELETE',
  });
};
