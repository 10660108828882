import React from 'react';
import cn from 'classnames';
import Button from 'components/Button';
import toast from 'helper/toast';
import { getProfileDate } from 'utils/date';
import { getStrWithFormat } from 'utils/textFormat';
import { useIsInternalUser } from 'helper/hooks';
import { FORMATS, STATUS_VALUES, SECURITY_FIELDS } from 'helper/const';
import SecurityField from 'components/SecurityField';
import { getErrorMessageFromObj } from 'utils/object';
import { getDoActionBR } from 'views/pages/MyTeam/utils';
import { DefaultAvatar } from 'views/pages/MyProfile/Elements';
import { Color } from '../components/Elements';
import { onInactiveTeammate, onActiveTeammate, onResendInvite } from '../utils';
import { getStatusMapping } from './utils';
import { useInformationData, useActions } from './selectorData';
import classes from './Detail.module.scss';

const informationMessageMapping = {
  [STATUS_VALUES.INVITE_SENT]: 'Team member resent invite successfully.',
  [STATUS_VALUES.ACTIVE]: 'Team member deactivated successfully.',
  [STATUS_VALUES.IN_ACTIVE]: 'Team member activated successfully.',
};
const statusValueMapping = {
  [STATUS_VALUES.ACTIVE]: STATUS_VALUES.IN_ACTIVE,
  [STATUS_VALUES.IN_ACTIVE]: STATUS_VALUES.ACTIVE,
};

const Information = ({ confirmModal, permission, companyId }) => {
  const { isInternalUser } = useIsInternalUser();
  const {
    detailData,
    doActionLoading,
    resendInviteLoading,
    currentUser,
    updateCompanyTeamMemberStatusLoading,
  } = useInformationData();
  const {
    doActionTeammate,
    getTeammateDetail,
    resendInviteTeammate,
    updateCompanyTeamMemberStatus,
  } = useActions();
  const { id: teammateId, group_name, role_name } = detailData;
  const status = detailData.status;
  const handleCallback = (res, err, message) => {
    if (res) {
      getTeammateDetail(teammateId);
      if (message) {
        toast.success(message);
      }
    } else {
      toast.error(getErrorMessageFromObj(err)[0]);
    }
  };
  const handleInformation = status => () => {
    const fnMapping = {
      [STATUS_VALUES.ACTIVE]: onInactiveTeammate,
      [STATUS_VALUES.IN_ACTIVE]: onActiveTeammate,
      [STATUS_VALUES.INVITE_SENT]: onResendInvite,
    };
    const message = informationMessageMapping[status];
    if (fnMapping[status]) {
      if (status === STATUS_VALUES.INVITE_SENT) {
        resendInviteTeammate(
          {
            id: teammateId,
          },
          (res, err) => {
            handleCallback(res, err, message);
          }
        );
      } else {
        fnMapping[status](() => {
          if (permission.isDetailCompanyTeam) {
            updateCompanyTeamMemberStatus(
              companyId,
              [detailData.id],
              statusValueMapping[status],
              (res, err) => {
                handleCallback(res, err, message);
              }
            );
          } else {
            doActionTeammate(
              getDoActionBR([detailData], statusValueMapping[status]),
              (res, err) => {
                handleCallback(res, err, message);
              }
            );
          }
        }, confirmModal);
      }
    }
  };
  const statusMapping = getStatusMapping(
    status,
    classes,
    handleInformation,
    detailData
  );
  const fields = [
    {
      label: 'Email Address',
      value: detailData.email || '-',
      required: true,
      key: 'email',
    },
    {
      label: 'Alternative / Personal Email',
      value: detailData.alternate_email || '-',
      key: 'alternate_email',
    },
    {
      label: 'Phone Number',
      value: getStrWithFormat(detailData.phone_number || '', FORMATS.PHONE),
      required: true,
      key: 'phone_number',
    },
    {
      label: 'Date of Birth',
      value: detailData.dob
        ? getProfileDate(detailData.dob, true) || detailData.dob
        : '-',
      key: 'dob',
    },
    {
      label: 'Department',
      value: group_name || '-',
      required: true,
      key: 'group_id',
    },
    {
      label: 'Position',
      value: role_name || '-',
      required: true,
      key: 'role_id',
    },
    {
      label: 'Start Date',
      value: detailData.start_date
        ? getProfileDate(detailData.start_date, true)
        : '-',
      required: true,
      key: 'start_date',
    },
    {
      label: 'Employee ID',
      value: detailData.employee_id || '-',
      key: 'employee_id',
    },
  ];
  return (
    <div className={classes.informationSection}>
      <div className={classes.profileAvatar}>
        {detailData.profile_pic ? (
          <img
            src={detailData.profile_pic}
            alt="profile"
            className={classes.avatar}
          />
        ) : (
          <DefaultAvatar className={classes.avatar} />
        )}
      </div>
      <div className={classes.informationInner}>
        <div>
          <div>
            <span>
              First Name<span className={classes.requiredMark}>*</span>
            </span>
            <span>{detailData.first_name || '-'}</span>
          </div>
          <div className={classes.mergedColumn}>
            <div>
              <span>M.I.</span>
              <span>{detailData.mi || '-'}</span>
            </div>
            <div>
              <span>
                Last Name<span className={classes.requiredMark}>*</span>
              </span>
              <span>{detailData.last_name || '-'}</span>
            </div>
          </div>
        </div>
        <div className={classes.informationRestFields}>
          {fields
            .filter(field => permission.detailFields.includes(field.key))
            .map((field, index) => {
              const tLabel = permission.detailFieldLabels[index] || field.label;
              const isRequired = field.required;
              const tValue = field.value;
              return (
                <div key={index}>
                  {SECURITY_FIELDS.includes(field.key) && isInternalUser ? (
                    <>
                      <SecurityField
                        fieldObj={{
                          label: (
                            <span>
                              {tLabel}
                              {isRequired && (
                                <span className={classes.requiredMark}>*</span>
                              )}
                            </span>
                          ),
                          value: tValue,
                          key: field.key,
                          type_id: detailData?.id,
                          type: 'official',
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <span>
                        {tLabel}
                        {isRequired && (
                          <span className={classes.requiredMark}>*</span>
                        )}
                      </span>
                      <span>{tValue}</span>
                    </>
                  )}
                </div>
              );
            })}
          <div className={classes.statusRow}>
            <div>
              <span>Status</span>
              <span>
                <Color color={statusMapping.color}>{statusMapping.label}</Color>
                <span>{statusMapping.des}</span>
              </span>
              {permission.teamAction && detailData.id !== currentUser.id && (
                <Button
                  size="large"
                  htmlType="button"
                  danger={statusMapping.button?.color === 'danger'}
                  color={statusMapping.button?.color}
                  className={cn(
                    classes.statusButton,
                    statusMapping.button?.className,
                    {
                      [classes.isLoading]:
                        (permission.isDetailCompanyTeam
                          ? updateCompanyTeamMemberStatusLoading
                          : doActionLoading) || resendInviteLoading,
                    }
                  )}
                  onClick={statusMapping.button?.onClick}
                  loading={
                    (permission.isDetailCompanyTeam
                      ? updateCompanyTeamMemberStatusLoading
                      : doActionLoading) || resendInviteLoading
                  }
                >
                  {statusMapping.button?.label}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Information;
