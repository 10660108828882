const initialUnderwritingState = {
  // GET
  backgrounds: {
    loading: false,
    data: [],
    error: '',
  },
  uccs: {
    loading: false,
    data: [],
    error: '',
  },
  businesss: {
    loading: false,
    data: [],
    error: '',
  },
  credits: {
    loading: false,
    data: [],
    error: '',
  },
  ifas: {
    loading: false,
    data: [],
    error: '',
  },
  locationss: {
    loading: false,
    data: [],
    error: '',
  },
  // POST
  createUnderwritingRecord: {
    loading: false,
    data: {},
    error: '',
  },
  updateUnderwritingRecord: {
    loading: false,
    data: {},
    error: '',
  },
  updateUnderwritingRecordStatus: {
    loading: false,
    data: {},
    error: '',
  },
};

export { initialUnderwritingState };
