import React from 'react';

import Rate from 'components/Rate';
import Space from 'components/Space';

const RateComponent = () => {
  return (
    <Space direction={'vertical'}>
      <Space direction={'horizontal'}>
        <Rate label="Label text" allowHalf defaultValue={2.5} />
      </Space>
    </Space>
  );
};

export default RateComponent;
