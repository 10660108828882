import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import classes from './Chat.module.scss';

const BackToChat = ({ isDetail, handleBack, isEdit }) => {
  return (
    <div className={classes.backBlock}>
      {isDetail && (
        <ArrowLeftOutlined className={classes.backIcon} onClick={handleBack} />
      )}
      {isDetail && (
        <span onClick={handleBack}>Back to chat{isEdit ? '' : 's'}</span>
      )}
    </div>
  );
};

export default BackToChat;
