import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_ORDER_REQUESTER_LIST',
  'GET_ORDER_DETAIL_FIELDS',
  'GET_ORDER_DETAIL_FIELD_SUB_RECORDS',
  'GET_ORDER_LIST',
  'GET_CLIENT_ORDER_DETAIL',
  'GET_CLIENT_ORDER',
  'SAVE_ORDER_AS_DRAFT',
  'SUBMIT_ORDER',
  'DELETE_ORDER',
  'UPDATE_CLIENT_ORDER_STATUS',
  'SYNC_UPDATE_CLIENT_ORDER_STATUS',
  'SYNC_ADD_NEW_CHECKLIST',
  'SYNC_REMOVE_CHECKLIST',
  'SYNC_UPDATE_CHECKLIST',
  'SYNC_UPDATE_CLIENT_ORDER_NOTE',
  'SYNC_UPDATE_ORDER_DETAIL_FIELDS'
);
