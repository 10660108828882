import { apiGetAllClients, apiCreateClient } from 'api';

import { clientTypes } from '../types';

// GET_ALL_CLIENTS
export const getAllClients = (filters, cb, isMore) => {
  return {
    isAsyncCall: true,
    baseType: clientTypes.GET_ALL_CLIENTS,
    payload: { isMore },
    asyncCall: () => {
      return apiGetAllClients(filters);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// GET_ARCHIVED_CLIENTS
export const getArchivedClients = (filters, cb, isMore) => {
  return {
    isAsyncCall: true,
    baseType: clientTypes.GET_ARCHIVED_CLIENTS,
    payload: { isMore },
    asyncCall: () => {
      return apiGetAllClients({
        ...filters,
        status: 'archived',
      });
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CREATE_CLIENT
export const createClient = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: clientTypes.CREATE_CLIENT,
    payload: {},
    asyncCall: () => {
      return apiCreateClient(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
