import React from 'react';

import { RadioGroup, RadioButtonGroup } from 'components/FormFields';
import Space from 'components/Space';

const RadioComponent = props => {
  return (
    <Space direction={'vertical'}>
      <RadioGroup />
      <Space direction={'horizontal'}>
        <RadioButtonGroup
          defaultValue={'a'}
          buttonStyle={'solid'}
          size={'large'}
          items={[
            { value: 'a', label: 'One' },
            { value: 'b', label: 'Two' },
            { value: 'c', label: 'Three' },
            { value: 'd', label: 'Four' },
          ]}
        />
        <RadioButtonGroup
          defaultValue={'a'}
          buttonStyle={'outline'}
          size={'large'}
          items={[
            { value: 'a', label: 'One' },
            { value: 'b', label: 'Two' },
            { value: 'c', label: 'Three' },
            { value: 'd', label: 'Four' },
          ]}
        />
      </Space>
      <Space direction={'horizontal'}>
        <RadioButtonGroup
          defaultValue={'a'}
          buttonStyle={'solid'}
          size={'large'}
          disabled
          items={[
            { value: 'a', label: 'One' },
            { value: 'b', label: 'Two' },
            { value: 'c', label: 'Three' },
            { value: 'd', label: 'Four' },
          ]}
        />
        <RadioButtonGroup
          defaultValue={'a'}
          buttonStyle={'outline'}
          size={'large'}
          disabled
          items={[
            { value: 'a', label: 'One' },
            { value: 'b', label: 'Two' },
            { value: 'c', label: 'Three' },
            { value: 'd', label: 'Four' },
          ]}
        />
      </Space>
      <Space direction={'horizontal'}>
        <RadioButtonGroup
          defaultValue={'a'}
          buttonStyle={'solid'}
          items={[
            { value: 'a', label: 'One' },
            { value: 'b', label: 'Two' },
            { value: 'c', label: 'Three' },
            { value: 'd', label: 'Four' },
          ]}
        />
        <RadioButtonGroup
          defaultValue={'a'}
          buttonStyle={'outline'}
          items={[
            { value: 'a', label: 'One' },
            { value: 'b', label: 'Two' },
            { value: 'c', label: 'Three' },
            { value: 'd', label: 'Four' },
          ]}
        />
      </Space>
      <Space direction={'horizontal'}>
        <RadioButtonGroup
          defaultValue={'a'}
          buttonStyle={'solid'}
          disabled
          items={[
            { value: 'a', label: 'One' },
            { value: 'b', label: 'Two' },
            { value: 'c', label: 'Three' },
            { value: 'd', label: 'Four' },
          ]}
        />
        <RadioButtonGroup
          defaultValue={'a'}
          buttonStyle={'outline'}
          disabled
          items={[
            { value: 'a', label: 'One' },
            { value: 'b', label: 'Two' },
            { value: 'c', label: 'Three' },
            { value: 'd', label: 'Four' },
          ]}
        />
      </Space>
      <Space direction={'horizontal'}>
        <RadioButtonGroup
          defaultValue={'a'}
          buttonStyle={'solid'}
          size={'small'}
          items={[
            { value: 'a', label: 'One' },
            { value: 'b', label: 'Two' },
            { value: 'c', label: 'Three' },
            { value: 'd', label: 'Four' },
          ]}
        />
        <RadioButtonGroup
          defaultValue={'a'}
          buttonStyle={'outline'}
          size={'small'}
          items={[
            { value: 'a', label: 'One' },
            { value: 'b', label: 'Two' },
            { value: 'c', label: 'Three' },
            { value: 'd', label: 'Four' },
          ]}
        />
      </Space>
      <Space direction={'horizontal'}>
        <RadioButtonGroup
          defaultValue={'a'}
          buttonStyle={'solid'}
          size={'small'}
          disabled
          items={[
            { value: 'a', label: 'One' },
            { value: 'b', label: 'Two' },
            { value: 'c', label: 'Three' },
            { value: 'd', label: 'Four' },
          ]}
        />
        <RadioButtonGroup
          defaultValue={'a'}
          buttonStyle={'outline'}
          size={'small'}
          disabled
          items={[
            { value: 'a', label: 'One' },
            { value: 'b', label: 'Two' },
            { value: 'c', label: 'Three' },
            { value: 'd', label: 'Four' },
          ]}
        />
      </Space>
    </Space>
  );
};

export default RadioComponent;
