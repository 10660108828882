import React from 'react';
import cn from 'classnames';
import { Input } from 'antd';

import ReactInputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import classes from './InputMark.module.scss';

const InputMask = ({
  isBlue,
  form,
  className,
  inputRef,
  customValidateName,
  ...other
}) => {
  return (
    <ReactInputMask
      {...other}
      onChange={e => {
        if (other.onChange) {
          other.onChange(e);
        }
        if (e.target.value && form) {
          form.setFields([
            {
              name: customValidateName || other.name,
              errors: null,
            },
          ]);
        }
      }}
      className={cn(
        classes.wrapper,
        {
          'ant-input-is-blue': isBlue,
        },
        className
      )}
    >
      {inputProps => (
        <Input
          {...inputProps}
          disabled={other.disabled ? other.disabled : null}
          size={inputProps.size || 'large'}
          ref={inputRef}
        />
      )}
    </ReactInputMask>
  );
};

InputMask.defaultProps = {
  mask: '',
  maskChar: null,
  isBlue: false,
};

InputMask.propTypes = {
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  formatChars: PropTypes.object,
  alwaysShowMask: PropTypes.bool,
  isBlue: PropTypes.bool,
};

export default InputMask;
