import React from 'react';
// import { POLICY_NUMBER_FIELD, TRUCKS_TRAILERS_FIELD } from 'helper/const';
import { TRUCKS_TRAILERS_FIELD } from 'helper/const';
import { parseSingleFile } from 'utils/file';
import InputItem from '../InputItem';
import { Section, FormSection, ListInfo } from '../Elements';
import { GENERAL_INFO_OBJ_KEYS, FILE_FIELDS } from './constants';

const GeneralInfo = ({ isEdit, form, data }) => {
  return (
    <Section>
      {isEdit ? (
        <FormSection>
          {/* Use it to save delete_attachments for attachment removing */}
          <InputItem name={'delete_attachments'} label="" form={form} hide />
          <InputItem
            name={'insurance_company'}
            label="Insurance Company Name"
            form={form}
            required
          />
          <InputItem
            name={'policy_number'}
            label="Insurance Policy Number"
            form={form}
            placeholder="000000000000"
            // type="number"
            // mask={POLICY_NUMBER_FIELD.MASK}
            // placeholder={POLICY_NUMBER_FIELD.PLACEHOLDER}
          />
          <InputItem name={'username'} label="Username" form={form} />
          <InputItem
            name={'password'}
            label="Password"
            form={form}
            placeholder="xxxxxxxxxxxxx"
          />
          {/* <InputItem
            name={'annual_premium'}
            label="Annual Premium"
            form={form}
            type="price"
            prefix={'$'}
            placeholder="000,000.00"
          /> */}
          <InputItem
            name={'expiration_date'}
            label="Expiration Date"
            form={form}
            type="date"
            placeholder="01/01/2000"
          />
          <InputItem
            name={'trucks'}
            label="Trucks"
            form={form}
            addressGroupField
            type="number"
            mask={TRUCKS_TRAILERS_FIELD.MASK}
            placeholder={TRUCKS_TRAILERS_FIELD.PLACEHOLDER}
          />
          <InputItem
            name={'trailers'}
            label="Trailers"
            form={form}
            addressGroupField
            type="number"
            mask={TRUCKS_TRAILERS_FIELD.MASK}
            placeholder={TRUCKS_TRAILERS_FIELD.PLACEHOLDER}
          />
          <InputItem
            name={'description'}
            label="Description"
            form={form}
            placeholder="Description Details"
          />
          {/* <InputItem
            name={'cert_of_previous_years'}
            label="Cert. of Ins. for 5 Previous Years"
            form={form}
            type="file"
            multiple
          /> */}
          <InputItem
            name={'cert_of_current_year'}
            label="Cert. of Ins. for Current Year"
            form={form}
            type="file"
          />
        </FormSection>
      ) : (
        <ListInfo
          objKeys={GENERAL_INFO_OBJ_KEYS}
          data={parseSingleFile(data, FILE_FIELDS, 'documents')}
        />
      )}
    </Section>
  );
};

export default GeneralInfo;
