import cloneDeep from 'lodash/cloneDeep';

export const cleanDuplicateOrderFieldValues = data => {
  if (!data) return [];
  const duplicateName = {};
  const newData = [];
  data.map(cat => {
    if (!duplicateName[cat.parent_category]) {
      duplicateName[cat.parent_category] = [];
      newData.push(cloneDeep(cat));
    }
    return true;
  });
  return newData;
};
