import get from 'lodash/get';
import find from 'lodash/find';
import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  workQueueTypes,
} from '../types';
import { initialWorkQueueState } from '../states/workQueue';

export default (state = initialWorkQueueState, action) => {
  switch (action.type) {
    // SYNC_UPDATE_WORK_QUEUE_FILTER_SETTING
    case workQueueTypes.SYNC_UPDATE_WORK_QUEUE_FILTER_SETTING: {
      return {
        ...state,
        filterSetting: {
          ...state.filterSetting,
          data: {
            ...state.filterSetting.data,
            [action.payload.key]: action.payload.value,
          },
        },
      };
    }
    // SYNC_ADD_WORK_QUEUE_COLUMN
    case workQueueTypes.SYNC_ADD_WORK_QUEUE_COLUMN: {
      return {
        ...state,
        columns: {
          ...state.columns,
          data: [...(state.columns?.data || []), action.payload],
        },
      };
    }
    // SYNC_UPDATE_WORK_QUEUE_COLUMN
    case workQueueTypes.SYNC_UPDATE_WORK_QUEUE_COLUMN: {
      return {
        ...state,
        columns: {
          ...state.columns,
          data: (state.columns?.data || []).map(d => {
            if (action.payload?.id === d.id) {
              const updatedKey = action.payload?.key;
              if (updatedKey === 'orders') {
                if (!d.orders) {
                  d.orders = {
                    data: action.payload?.value || d[updatedKey]?.data || [],
                  };
                } else {
                  d.orders.data =
                    action.payload?.value || d[updatedKey]?.data || [];
                }
              } else {
                d[updatedKey] = action.payload?.value || d[updatedKey];
              }
            }
            return d;
          }),
        },
      };
    }
    // GET_WORK_QUEUE_CC_COLUMNS
    case asyncActionTypeRequest(workQueueTypes.GET_WORK_QUEUE_CC_COLUMNS): {
      return {
        ...state,
        ccColumns: {
          ...state.ccColumns,
          loading: action.payload?.silent ? false : true,
        },
      };
    }
    case asyncActionTypeSuccess(workQueueTypes.GET_WORK_QUEUE_CC_COLUMNS): {
      return {
        ...state,
        ccColumns: {
          loading: false,
          data: action.payload?.isMore
            ? state.ccColumns.data.map(column => {
                const columnsResponse = get(action, 'response.data') || [];
                const foundColumnResponse = find(columnsResponse, {
                  id: column.id,
                });
                if (!!column.orders && !!foundColumnResponse?.orders) {
                  const responseOrders = foundColumnResponse.orders;
                  const oldOrdersData = column.orders.data || [];
                  const newOrdersData = foundColumnResponse.orders.data || [];
                  column.orders = {
                    ...column.orders,
                    ...responseOrders,
                    data: [...oldOrdersData, ...newOrdersData],
                  };
                }
                return column;
              })
            : get(action, 'response.data') || [],
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(workQueueTypes.GET_WORK_QUEUE_CC_COLUMNS): {
      return {
        ...state,
        ccColumns: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    }
    // GET_WORK_QUEUE_COLUMNS
    case asyncActionTypeRequest(workQueueTypes.GET_WORK_QUEUE_COLUMNS): {
      return {
        ...state,
        columns: {
          ...state.columns,
          loading: action.payload?.silent ? false : true,
        },
      };
    }
    case asyncActionTypeSuccess(workQueueTypes.GET_WORK_QUEUE_COLUMNS): {
      return {
        ...state,
        columns: {
          loading: false,
          data: action.payload?.isMore
            ? state.columns.data.map(column => {
                const columnsResponse = get(action, 'response.data') || [];
                const foundColumnResponse = find(columnsResponse, {
                  id: column.id,
                });
                if (!!column.orders && !!foundColumnResponse?.orders) {
                  const responseOrders = foundColumnResponse.orders;
                  const oldOrdersData = column.orders.data || [];
                  const newOrdersData = foundColumnResponse.orders.data || [];
                  column.orders = {
                    ...column.orders,
                    ...responseOrders,
                    data: [...oldOrdersData, ...newOrdersData],
                  };
                }
                return column;
              })
            : get(action, 'response.data') || [],
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(workQueueTypes.GET_WORK_QUEUE_COLUMNS): {
      return {
        ...state,
        columns: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    }
    // GET_WORK_QUEUE_FILTER_SETTING
    case asyncActionTypeRequest(workQueueTypes.GET_WORK_QUEUE_FILTER_SETTING): {
      return {
        ...state,
        filterSetting: {
          ...state.filterSetting,
          loading: action.payload?.silent ? false : true,
        },
      };
    }
    case asyncActionTypeSuccess(workQueueTypes.GET_WORK_QUEUE_FILTER_SETTING): {
      return {
        ...state,
        filterSetting: {
          loading: false,
          data: {
            ...state.filterSetting.data,
            [action.payload?.departmentId]: get(action, 'response.data') || {},
          },
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(workQueueTypes.GET_WORK_QUEUE_FILTER_SETTING): {
      return {
        ...state,
        filterSetting: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // ADD_WORK_QUEUE_COLUMN
    case asyncActionTypeRequest(workQueueTypes.ADD_WORK_QUEUE_COLUMN): {
      return {
        ...state,
        addColumn: {
          ...state.addColumn,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(workQueueTypes.ADD_WORK_QUEUE_COLUMN): {
      return {
        ...state,
        addColumn: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(workQueueTypes.ADD_WORK_QUEUE_COLUMN): {
      return {
        ...state,
        addColumn: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // UPDATE_WORK_QUEUE_COLUMN
    case asyncActionTypeRequest(workQueueTypes.UPDATE_WORK_QUEUE_COLUMN): {
      return {
        ...state,
        updateColumn: {
          ...state.updateColumn,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(workQueueTypes.UPDATE_WORK_QUEUE_COLUMN): {
      return {
        ...state,
        updateColumn: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(workQueueTypes.UPDATE_WORK_QUEUE_COLUMN): {
      return {
        ...state,
        updateColumn: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // DELETE_WORK_QUEUE_COLUMN
    case asyncActionTypeRequest(workQueueTypes.DELETE_WORK_QUEUE_COLUMN): {
      return {
        ...state,
        deleteColumn: {
          ...state.deleteColumn,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(workQueueTypes.DELETE_WORK_QUEUE_COLUMN): {
      return {
        ...state,
        deleteColumn: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
        columns: {
          ...state.columns,
          data: (state.columns?.data || []).filter(
            d => d.id !== action.payload?.columnId
          ),
        },
      };
    }
    case asyncActionTypeFailure(workQueueTypes.DELETE_WORK_QUEUE_COLUMN): {
      return {
        ...state,
        deleteColumn: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // MOVE_WORK_QUEUE_ORDER_TO_COLUMN
    case asyncActionTypeRequest(
      workQueueTypes.MOVE_WORK_QUEUE_ORDER_TO_COLUMN
    ): {
      return {
        ...state,
        moveOrderToColumn: {
          ...state.moveOrderToColumn,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(
      workQueueTypes.MOVE_WORK_QUEUE_ORDER_TO_COLUMN
    ): {
      return {
        ...state,
        moveOrderToColumn: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
        columns: {
          ...state.columns,
          data: action.payload.columnData,
        },
      };
    }
    case asyncActionTypeFailure(
      workQueueTypes.MOVE_WORK_QUEUE_ORDER_TO_COLUMN
    ): {
      return {
        ...state,
        moveOrderToColumn: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // STORE_WORK_QUEUE_FILTER_SETTING
    case asyncActionTypeRequest(
      workQueueTypes.STORE_WORK_QUEUE_FILTER_SETTING
    ): {
      return {
        ...state,
        storeFilterSetting: {
          ...state.storeFilterSetting,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(
      workQueueTypes.STORE_WORK_QUEUE_FILTER_SETTING
    ): {
      return {
        ...state,
        storeFilterSetting: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(
      workQueueTypes.STORE_WORK_QUEUE_FILTER_SETTING
    ): {
      return {
        ...state,
        storeFilterSetting: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    default:
      return state;
  }
};
