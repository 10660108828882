import React from 'react';
import Spin from 'components/Spin';
import { useLoadingSpinData } from './selectorData';

const LoadingSpin = ({ children }) => {
  const {
    clientOrderLoading,
    detailLoading,
    orderDetailFieldValuesLoading,
    orderDetailFieldsLoading,
  } = useLoadingSpinData();
  return (
    <Spin
      spinning={
        clientOrderLoading ||
        detailLoading ||
        orderDetailFieldValuesLoading ||
        orderDetailFieldsLoading
      }
      tip={'Loading...'}
    >
      {children}
    </Spin>
  );
};

export default LoadingSpin;
