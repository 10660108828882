import { useReducerData, useReducerLoading } from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// CHECKLISTS
export const useSelectChecklists = () => {
  return useReducerData(
    REDUCER_NAMES.CHECKLIST,
    REDUCER_ATTRIBUTES.CHECKLIST.CHECKLISTS
  );
};
export const useSelectChecklistsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHECKLIST,
    REDUCER_ATTRIBUTES.CHECKLIST.CHECKLISTS
  );
};
// CREATE_UPDATE_CHECKLIST
export const useSelectCreateUpdateChecklistLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHECKLIST,
    REDUCER_ATTRIBUTES.CHECKLIST.CREATE_UPDATE_CHECKLIST
  );
};
// CHECK_UNCHECK_CHECKLIST
export const useSelectCheckUncheckChecklistLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHECKLIST,
    REDUCER_ATTRIBUTES.CHECKLIST.CHECK_UNCHECK_CHECKLIST
  );
};
// DELETE_CHECKLIST
export const useSelectDeleteChecklistLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHECKLIST,
    REDUCER_ATTRIBUTES.CHECKLIST.DELETE_CHECKLIST
  );
};
