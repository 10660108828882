import React from 'react';

import Tabs from 'components/Tabs';
import Space from 'components/Space';

const TabsComponent = () => {
  return (
    <Space direction={'vertical'}>
      <h2>Line Tab</h2>
      <h3>Large</h3>
      <Tabs type="editable-card" size="large" />
      <h3>Medium</h3>
      <Tabs type="editable-card" size="medium" />
      <h3>Small</h3>
      <Tabs type="editable-card" size="small" />
      <h2>Pane Tab</h2>
      <Tabs type="editable-card" isPane size="large" />
    </Space>
  );
};

export default TabsComponent;
