import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  reportTypes,
} from '../types';
import get from 'lodash/get';

const initialState = {
  reportFields: {
    loading: false,
    data: {},
    error: '',
  },
  reports: {
    data: {},
  },
  downloadReports: {
    loading: false,
    data: {},
    error: '',
  },
};

export default (state = initialState, action) => {
  const resData = get(action, 'response.data') || {};
  const { next_page_url, current_page, data = [], total = 0 } = resData;
  const isMore = get(action, 'payload.isMore');
  const reportType = get(action, 'payload.type');
  const isBEMore = !!next_page_url || current_page > 1;
  switch (action.type) {
    // GET_REPORTS
    case asyncActionTypeRequest(reportTypes.GET_REPORTS): {
      return {
        ...state,
        reports: {
          data: {
            ...(state.reports?.data || {}),
            [reportType]: {
              ...(state.reports?.data[reportType] || {}),
              loading: true,
            },
          },
        },
      };
    }
    case asyncActionTypeSuccess(reportTypes.GET_REPORTS): {
      return {
        ...state,
        reports: {
          data: {
            ...(state.reports?.data || {}),
            [reportType]: {
              data: isMore
                ? [...(state.reports.data[reportType]?.data || []), ...data]
                : data,
              totalNo: total || 0,
              loading: false,
              isMore: isBEMore,
              error: '',
            },
          },
        },
      };
    }
    case asyncActionTypeFailure(reportTypes.GET_REPORTS): {
      return {
        ...state,
        reports: {
          data: {
            ...(state.reports?.data || {}),
            [reportType]: {
              data: [],
              totalNo: 0,
              loading: false,
              isMore: false,
              error: action.payload,
            },
          },
        },
      };
    }
    // GET_REPORT_FIELDS
    case asyncActionTypeRequest(reportTypes.GET_REPORT_FIELDS): {
      return {
        ...state,
        reportFields: {
          ...state.reportFields,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(reportTypes.GET_REPORT_FIELDS): {
      return {
        ...state,
        reportFields: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(reportTypes.GET_REPORT_FIELDS): {
      return {
        ...state,
        reportFields: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // DOWNLOAD_REPORTS
    case asyncActionTypeRequest(reportTypes.DOWNLOAD_REPORTS): {
      return {
        ...state,
        downloadReports: {
          ...state.downloadReports,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(reportTypes.DOWNLOAD_REPORTS): {
      return {
        ...state,
        downloadReports: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(reportTypes.DOWNLOAD_REPORTS): {
      return {
        ...state,
        downloadReports: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    default:
      return state;
  }
};
