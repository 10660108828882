import React from 'react';
import get from 'lodash/get';
import cn from 'classnames';
import { FormSection } from 'views/pages/ClientDetail/components/Elements';
import { Checkbox } from 'components/FormFields';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import classes from './SecurityBlock.module.scss';

const FullView = ({ className, fields, form, parentName, parentIndex }) => {
  return (
    <div className={cn(classes.fullView, className)}>
      <FormSection className={classes.fullViewFormSection}>
        {(fields || []).map((field, index) => {
          const {
            name,
            label,
            type,
            addressGroupField,
            format,
            picker,
            mask,
            fullWidth,
            options,
            width,
          } = field;
          const values = form.getFieldsValue();
          if (type === 'block') {
            return (
              <div
                key={index}
                style={{
                  minWidth: `calc(${width} - 30px)`,
                  maxWidth: `calc(${width} - 30px)`,
                }}
              ></div>
            );
          }
          if (type === 'label') {
            return (
              <label
                key={index}
                className={cn(classes.title, {
                  [classes.fullWidth]: fullWidth,
                })}
              >
                {label}
              </label>
            );
          }
          if (type === 'checkbox') {
            return (
              <Checkbox
                key={index}
                checked={get(values, `${parentName}.[${parentIndex}].${name}`)}
                label={label}
                disabled
                className={cn({
                  [classes.fullWidth]: fullWidth,
                })}
              />
            );
          }
          return (
            <InputItem
              key={index}
              name={name}
              label={label}
              form={form}
              disabled
              hidePlaceholder
              type={type}
              addressGroupField={addressGroupField}
              format={format}
              picker={picker}
              mask={mask}
              fullWidth={fullWidth}
              options={options}
            />
          );
        })}
      </FormSection>
    </div>
  );
};

export default FullView;
