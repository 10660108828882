import React from 'react';
import get from 'lodash/get';
import cn from 'classnames';
import { onPreviewFile } from 'utils/file';
import { PaperClipOutlined } from '@ant-design/icons';
import classes from './OtherSection.module.scss';

export const OtherSectionView = ({ fields, data, className, onDelete }) => {
  return (
    <ul className={cn(classes.viewItem, className)}>
      {(fields || []).map((field, index) => {
        const { labelName } = field;
        let fileUrl = data.url;
        let fileName = data.document_name;
        const firstDocument = get(data, 'document[0]') || {};
        if (data.document) {
          const documentFileUrl = firstDocument.url;
          fileUrl = documentFileUrl;
          fileName = firstDocument.name;
          if (!documentFileUrl) {
            fileUrl = true;
          }
        }
        const emptyFile = !fileUrl;
        return (
          <li
            key={index}
            className={cn({
              [classes.emptyFile]: emptyFile,
            })}
          >
            <span>{data[labelName]}</span>
            <span title={fileName || ''}>
              {!!onDelete && !emptyFile && (
                <PaperClipOutlined className={classes.paperIcon} />
              )}
              <span
                className={classes.fileUrl}
                onClick={() => {
                  if (!emptyFile) {
                    if (fileUrl === true) {
                      onPreviewFile(firstDocument);
                    } else {
                      const win = window.open(fileUrl, '_blank');
                      win.focus();
                    }
                  }
                }}
              >
                {fileName || '-'}
              </span>{' '}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

const View = ({ className, fields, data, onDelete }) => {
  return (
    <div className={cn(classes.view, className)}>
      <OtherSectionView
        fields={fields}
        data={data}
        className={classes.otherSectionView}
        onDelete={onDelete}
      />
    </div>
  );
};

export default View;
