import React, { useState, useRef } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import cn from 'classnames';
import Modal from 'components/Modal';
import Button, { ButtonColors } from 'components/Button';
import { actionCb } from 'utils/action';
import { Title, SectionWrapper, ListInfo } from '../components/Elements';
import Header from './components/Header';
import classes from './DotClassifications.module.scss';
import NotFound from './components/NotFound';
import SearchDOT from './components/SearchDOT';
import {
  DOT_CLARIFICATION_FIELDS,
  SEARCH_DOT_CLARIFICATION_FIELDS,
} from './constants';
import { useActions, useIndexData } from './selectorData';

let resetSearch = null;
const CONNECTION_ISSUE_MSG = 'Connection issue error';

const DotClassifications = ({
  className,
  rootKey,
  permission,
  clientId,
  dot_saved,
}) => {
  const searchRef = useRef(null);
  const { dotSearchSafer, getClientDetail, saveDOT } = useActions();
  const {
    searchSaferLoading,
    saveDOTLoading,
    detailLoading,
    data,
  } = useIndexData();
  const [searchData, setSearchData] = useState({});
  const [searchSuccess, setSearchSuccess] = useState(false);
  const [searchError, setSearchError] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const wrapperId = `dot_classifications_${rootKey}`;
  const notFound = Object.keys(data).length === 0;
  const handleSearch = (val, cb) => {
    resetSearch = cb;
    if (!!val) {
      dotSearchSafer(
        {
          dot_number: val,
          company_id: clientId,
        },
        (res, err, fullErrorObj) => {
          if (res) {
            setSearchData(res.data || {});
            setSearchSuccess(true);
            if (cb) cb();
          } else {
            const isConnectionIssue = fullErrorObj?.status === 500;
            if (!notFound && isConnectionIssue) {
              setErrorModal(true);
            } else {
              setSearchError(
                isConnectionIssue ? CONNECTION_ISSUE_MSG : err.message
              );
            }
          }
        }
      );
    }
  };
  const resetSearchDOT = () => {
    setSearchError('');
    setSearchSuccess(false);
    if (resetSearch) resetSearch();
    setTimeout(() => {
      if (searchRef && searchRef.current) {
        searchRef.current.focus();
      }
    }, 100);
  };
  const modalProps = {
    isOpen: errorModal,
    onSubmit: () => {},
    onClose: () => {
      resetSearchDOT();
      setErrorModal(false);
    },
    title: <></>,
    maskClosable: true,
    closable: true,
    className: classes.modalWrapper,
    isSubmitLoading: false,
    closeIcon: null,
    hideFooter: true,
  };

  return (
    <div className={classes.wrapper}>
      <Header className={classes.header} />
      {permission.dotNumber?.searchDOT && !dot_saved && (
        <>
          {searchSuccess ? (
            <div className={classes.tempButtons}>
              <Button
                type={'ghost'}
                htmlType="button"
                onClick={() => {
                  setSearchSuccess(false);
                  setSearchData({});
                  setSearchError('');
                }}
              >
                Cancel
              </Button>
              <Button
                type={'primary'}
                htmlType="button"
                leftIcon={<CheckCircleFilled />}
                onClick={() => {
                  saveDOT(
                    clientId,
                    searchData,
                    actionCb(() => {
                      getClientDetail({ id: clientId });
                    })
                  );
                }}
                color={ButtonColors.BLUE}
                loading={saveDOTLoading || detailLoading}
              >
                Save
              </Button>
            </div>
          ) : (
            <SearchDOT
              setSearchSuccess={setSearchSuccess}
              searchError={searchError}
              setSearchError={setSearchError}
              onSearch={handleSearch}
              loading={searchSaferLoading}
              searchRef={searchRef}
              showError={!notFound}
            />
          )}
        </>
      )}
      {notFound && !searchSuccess ? (
        <NotFound
          text={permission.dotNumber?.emptyText}
          searchError={searchError}
          isConnectionIssue={
            !!searchError && searchError === CONNECTION_ISSUE_MSG
          }
          onClick={() => {
            resetSearchDOT();
          }}
        />
      ) : (
        <SectionWrapper className={cn(className)} id={wrapperId}>
          <Title
            text={`DOT Number: ${
              searchSuccess ? searchData.dotNumber : data.dotNumber
            }`}
          />
          <ListInfo
            objKeys={
              searchSuccess
                ? SEARCH_DOT_CLARIFICATION_FIELDS
                : DOT_CLARIFICATION_FIELDS
            }
            data={searchSuccess ? searchData : data}
            sCompanyId={clientId}
            sType="dot_classification"
            needSecure
          />
        </SectionWrapper>
      )}
      <Modal {...modalProps}>
        <NotFound
          isConnectionIssue
          onClick={() => {
            resetSearchDOT();
            setErrorModal(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default DotClassifications;
