import { standardRequiredFieldObj, expDateFieldObj } from 'utils/form';

export const getInitialValues = data => {
  const { accounts, cards } = data || {};
  const initialValues = {
    accounts: accounts.map(acc => ({
      ...acc,
      id: acc.id,
      originId: acc.id,
      is_default_account: acc.is_primary,
    })),
    cards: cards.map(card => {
      return {
        ...card,
        originId: card.id,
      };
    }),
  };
  return initialValues;
};
export const validateFuelCardObj = () => {
  return {
    card_number: [standardRequiredFieldObj],
    cvv: [standardRequiredFieldObj],
    expiration_date: [standardRequiredFieldObj, expDateFieldObj],
    zip_code: [standardRequiredFieldObj],
    address: [standardRequiredFieldObj],
    city: [standardRequiredFieldObj],
    state_id: [standardRequiredFieldObj],
  };
};
export const getValidateArray = values => {
  const validateArray = [];
  const formAccounts = values.accounts || [];
  const formCards = values.cards || [];
  formAccounts.map((account, i) => {
    if (!account.originId) {
      validateArray.push({
        name: ['accounts', i, 'name_on_account'],
        rules: [standardRequiredFieldObj],
      });
      validateArray.push({
        name: ['accounts', i, 'bank_name'],
        rules: [standardRequiredFieldObj],
      });
      validateArray.push({
        name: ['accounts', i, 'account_number'],
        rules: [standardRequiredFieldObj],
      });
      validateArray.push({
        name: ['accounts', i, 'type'],
        rules: [standardRequiredFieldObj],
      });
    }
    return true;
  });
  formCards.map((card, i) => {
    if (!card.originId) {
      validateArray.push({
        name: ['cards', i, 'card_number'],
        rules: [standardRequiredFieldObj],
      });
      validateArray.push({
        name: ['cards', i, 'cvv'],
        rules: [standardRequiredFieldObj],
      });
      validateArray.push({
        name: ['cards', i, 'expiration_date'],
        rules: [standardRequiredFieldObj, expDateFieldObj],
      });
      validateArray.push({
        name: ['cards', i, 'zip_code'],
        rules: [standardRequiredFieldObj],
      });
      validateArray.push({
        name: ['cards', i, 'address'],
        rules: [standardRequiredFieldObj],
      });
      validateArray.push({
        name: ['cards', i, 'city'],
        rules: [standardRequiredFieldObj],
      });
      validateArray.push({
        name: ['cards', i, 'state_id'],
        rules: [standardRequiredFieldObj],
      });
    }
    return true;
  });
  return validateArray;
};
