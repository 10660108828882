import {
  useReducerData,
  useReducerLoading,
  useReducerAttribute,
} from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';
import * as authSelectors from 'store/selectors/auth';

// INTERNAL_CONVERSATIONS
export const useSelectInternalConversationsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.INTERNAL_CONVERSATIONS
  );
};
export const useSelectInternalConversations = () => {
  return useReducerData(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.INTERNAL_CONVERSATIONS,
    {}
  );
};
// CLIENT_CONVERSATIONS
export const useSelectClientConversationsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.CLIENT_CONVERSATIONS
  );
};
export const useSelectClientConversations = () => {
  return useReducerData(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.CLIENT_CONVERSATIONS,
    {}
  );
};
// CHAT_DEPARTMENTS
export const useSelectChatDepartmentsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.CHAT_DEPARTMENTS
  );
};
export const useSelectChatDepartments = () => {
  return useReducerData(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.CHAT_DEPARTMENTS
  );
};
// CHAT_DEPARTMENTS_LIST
export const useSelectChatDepartmentsListLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.CHAT_DEPARTMENTS_LIST
  );
};
export const useSelectChatDepartmentsList = () => {
  return useReducerData(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.CHAT_DEPARTMENTS_LIST
  );
};
// MESSAGES
export const useSelectMessagesLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.MESSAGES
  );
};
export const useSelectMessages = () => {
  const messages = useReducerData(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.MESSAGES
  );
  return messages
    .map((m, i) => ({
      ...m,
      index: i,
    }))
    .sort((a, b) => (b.index > a.index ? 1 : -1));
};
// ATTACHMENT
export const useSelectAttachmentLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.ATTACHMENT
  );
};
export const useSelectAttachment = () => {
  return useReducerData(REDUCER_NAMES.CHAT, REDUCER_ATTRIBUTES.CHAT.ATTACHMENT);
};
// SEARCH_INTERNAL_MEMBERS
export const useSelectSearchInternalMembersLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.SEARCH_INTERNAL_MEMBERS
  );
};
export const useSelectSearchInternalMembers = () => {
  const currentUser = authSelectors.useSelectCurrentUser();
  const searchMembers = useReducerData(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.SEARCH_INTERNAL_MEMBERS
  );
  return searchMembers
    .filter(m => m.id !== currentUser.id)
    .map(m => ({
      ...m,
      user_id: m.id,
    }));
};
// SEARCH_CLIENT_MEMBERS
export const useSelectSearchClientMembersLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.SEARCH_CLIENT_MEMBERS
  );
};
export const useSelectSearchClientMembers = () => {
  const currentUser = authSelectors.useSelectCurrentUser();
  const searchMembers = useReducerData(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.SEARCH_CLIENT_MEMBERS
  );
  return searchMembers
    .filter(m => m.id !== currentUser.id)
    .map(m => ({
      ...m,
      user_id: m.id,
    }));
};
// CHAT_MEMBERS
export const useSelectChatMembersLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.CHAT_MEMBERS
  );
};
export const useSelectChatMembers = () => {
  return useReducerData(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.CHAT_MEMBERS
  );
};
// SEND_MESSAGE
export const useSelectSendMessageLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.SEND_MESSAGE
  );
};
// MARK_READ
export const useSelectMarkReadLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.MARK_READ
  );
};
// UPDATE_CHAT_SETTINGS
export const useSelectUpdateChatSettingsLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.UPDATE_CHAT_SETTINGS
  );
};
// ADD_MEMBERS
export const useSelectAddMembersLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.ADD_MEMBERS
  );
};
// REMOVE_MEMBERS
export const useSelectRemoveMembersLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.REMOVE_MEMBERS
  );
};
// CHECK_CONVERSATION
export const useSelectCheckConversationLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.CHECK_CONVERSATION
  );
};
// CREATE_CHAT_GROUP
export const useSelectCreateChatGroupLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.CREATE_CHAT_GROUP
  );
};

// CUSTOM
// INTERNAL_COUNT
export const useSelectInternalCount = () => {
  return useReducerAttribute(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.INTERNAL_COUNT,
    0
  );
};
// CLIENT_COUNT
export const useSelectClientCount = () => {
  return useReducerAttribute(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.CLIENT_COUNT,
    0
  );
};
// COMPANIES
export const useSelectCompanies = () => {
  return useReducerAttribute(
    REDUCER_NAMES.CHAT,
    REDUCER_ATTRIBUTES.CHAT.COMPANIES,
    []
  );
};
