import React, { useState } from 'react';
import cx from 'classnames';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import { FormItem } from 'components/Form';
import { InputPassword } from 'components/FormFields';

import classes from './InputPasswordStrong.module.scss';

const { Text } = Typography;
const PASSWORD_STRONG = {
  MIN_LENGTH: 'minLength',
  UPPER_LOWER_CHAR: 'upperLowerChar',
  HAS_NUMBER: 'hasNumber',
  SPECIAL_CHAR: 'specialChar',
};

const PasswordIcon = ({ isError }) => {
  return <>{isError ? <CloseOutlined /> : <CheckOutlined />}</>;
};

const InputPasswordStrong = props => {
  const {
    passwordStrongChange,
    className,
    placeholder = '',
    label,
    form,
    autoFocus = false,
    required,
    size = 'large',
    confirmPasswordName,
    isBlue,
  } = props;
  const [password, setPassword] = useState('');
  const [strongPassword, setStrongPassword] = useState([]);
  const validateStrongPassword = val => {
    const passwordStr = val.trim();
    const hasEnoughChars = passwordStr.length > 6;
    const hasUppercase = null !== passwordStr.match(/[A-Z]/);
    const hasLowercase = null !== passwordStr.match(/[a-z]/);
    const hasDigit = null !== passwordStr.match(/[0-9]/);
    const pwdStrong = [];
    if (hasEnoughChars) {
      if (pwdStrong.indexOf(PASSWORD_STRONG.MIN_LENGTH) < 0) {
        pwdStrong.push(PASSWORD_STRONG.MIN_LENGTH);
      }
    }
    if (hasUppercase && hasLowercase) {
      if (pwdStrong.indexOf(PASSWORD_STRONG.UPPER_LOWER_CHAR) < 0) {
        pwdStrong.push(PASSWORD_STRONG.UPPER_LOWER_CHAR);
      }
    }
    if (hasDigit) {
      if (pwdStrong.indexOf(PASSWORD_STRONG.HAS_NUMBER) < 0) {
        pwdStrong.push(PASSWORD_STRONG.HAS_NUMBER);
      }
    }
    setStrongPassword(pwdStrong);
    passwordStrongChange(pwdStrong);
  };
  const renderPasswordInformContent = () => {
    return (
      <>
        <Space direction={'vertical'}>
          <Space
            direction={'horizontal'}
            className={cx(
              !strongPassword.includes(PASSWORD_STRONG.MIN_LENGTH)
                ? classes.unCheck
                : classes.checked
            )}
          >
            <PasswordIcon
              isError={!strongPassword.includes(PASSWORD_STRONG.MIN_LENGTH)}
            />
            <Text>Use 7 or more characters</Text>
          </Space>
          <Space
            direction={'horizontal'}
            className={cx(
              !strongPassword.includes(PASSWORD_STRONG.UPPER_LOWER_CHAR)
                ? classes.unCheck
                : classes.checked
            )}
          >
            <PasswordIcon
              isError={
                !strongPassword.includes(PASSWORD_STRONG.UPPER_LOWER_CHAR)
              }
            />
            <Text>Use upper and lower case letters (e.g. Aa)</Text>
          </Space>
          <Space
            direction={'horizontal'}
            className={cx(
              !strongPassword.includes(PASSWORD_STRONG.HAS_NUMBER)
                ? classes.unCheck
                : classes.checked
            )}
          >
            <PasswordIcon
              isError={!strongPassword.includes(PASSWORD_STRONG.HAS_NUMBER)}
            />
            <Text>Use a number (e.g. 1234)</Text>
          </Space>
        </Space>
      </>
    );
  };
  return (
    <div className={cx(classes.passwordWrapper, className)}>
      <FormItem name={'password'} label={label} required={required}>
        <InputPassword
          size={size}
          placeholder={placeholder}
          onChange={ev => {
            setPassword(ev.target.value);
            validateStrongPassword(ev.target.value);
            if (confirmPasswordName) {
              form.setFields([
                {
                  name: confirmPasswordName,
                  errors: null,
                },
              ]);
            }
          }}
          form={form}
          name="password"
          autoFocus={autoFocus}
          isBlue={isBlue}
        />
      </FormItem>
      {strongPassword.length < 3 && !!password && (
        <div className={classes.description}>
          {renderPasswordInformContent()}
        </div>
      )}
    </div>
  );
};

export default InputPasswordStrong;
