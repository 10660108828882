import React, { useState, useRef } from 'react';
import cn from 'classnames';
import { DownOutlined, UpOutlined, MoreOutlined } from '@ant-design/icons';
import Form, { FormItem, useForm } from 'components/Form';
import { ColumnActions, ActionItemColors } from 'components/Table';
import { Input } from 'components/FormFields';
import Badge from 'components/Badge';
import Tooltip from 'components/Tooltip';
import Button, { ButtonColors } from 'components/Button';
import ClassTooltipContent from 'views/pages/ClientDetail/CompanyOfficial/components/ClassTooltipContent';
import { getSortOptions } from './utils';
import classes from './Column.module.scss';

export const ColumnHeader = ({
  className,
  count,
  title,
  onUpdate,
  disabled,
  tooltip,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [form] = useForm();
  const inputRef = useRef(null);
  return (
    <div className={cn(classes.header, className)}>
      {isEdit ? (
        <Form
          validateMessages={''}
          onFinish={values => {
            if (onUpdate) {
              onUpdate(values, () => {
                setIsEdit(false);
              });
            }
          }}
          initialValues={{
            title: '',
          }}
          form={form}
          className={classes.form}
        >
          <FormItem name="title">
            <Input
              name="title"
              size="medium"
              form={form}
              isBlue
              placeholder="Enter column title..."
              autoFocus
              inputRef={inputRef}
              onBlur={() => {
                if (onUpdate) {
                  const values = form.getFieldsValue();
                  onUpdate(values, () => {
                    setIsEdit(false);
                  });
                }
              }}
            />
          </FormItem>
        </Form>
      ) : (
        <>
          <div
            className={classes.headerText}
            onClick={() => {
              if (!disabled) {
                setIsEdit(true);
                if (form) {
                  form.setFieldsValue({
                    title,
                  });
                  setTimeout(() => {
                    if (inputRef && inputRef.current) {
                      inputRef.current.select();
                    }
                  }, 0);
                }
              }
            }}
          >
            {tooltip ? (
              <Tooltip
                title={
                  <ClassTooltipContent
                    description={tooltip}
                    className={classes.tooltipContent}
                  />
                }
                overlayClassName={classes.tooltip}
                placement="bottomLeft"
              >
                {title}
              </Tooltip>
            ) : (
              title
            )}
          </div>
          <Badge count={count || 0} showZero size="default"></Badge>
        </>
      )}
    </div>
  );
};
export const ColumnTitle = ({
  className,
  text,
  onDelete,
  column,
  onSort,
  isDES,
  currentSort,
}) => {
  const columnCount = (column?.orders || []).length || 0;
  const isActionDisabled = columnCount > 0 || column?.is_default === 1;
  return (
    <div className={cn(classes.title, className)}>
      <ColumnActions
        trigger={['click']}
        actions={getSortOptions({ onSort, currentSort })}
        placement="bottomLeft"
        className={classes.sortAction}
        menuClassName={classes.titleActionMenu}
      >
        <div className={classes.sortActionTrigger}>
          {text} {isDES ? <DownOutlined /> : <UpOutlined />}
        </div>
      </ColumnActions>
      <ColumnActions
        trigger={['click']}
        disabled={isActionDisabled}
        actions={[
          {
            label: (
              <Tooltip
                title={
                  isActionDisabled ? (
                    <ClassTooltipContent
                      description={
                        columnCount > 0 ? (
                          <>
                            You can’t delete a column with existing orders. You
                            must move all order to another column first.
                          </>
                        ) : (
                          <>Default column can't be deleted.</>
                        )
                      }
                      className={classes.tooltipContent}
                    />
                  ) : (
                    ''
                  )
                }
                overlayClassName={classes.tooltip}
                placement="bottomLeft"
              >
                <div>Delete Column</div>
              </Tooltip>
            ),
            props: {
              onClick: () => {
                if (!isActionDisabled && onDelete) {
                  onDelete();
                }
              },
            },
          },
        ]}
        placement="bottomRight"
        lastItemColor={isActionDisabled ? '' : ActionItemColors.RED}
        className={classes.titleAction}
        menuClassName={classes.titleActionMenu}
      >
        <Button
          htmlType="button"
          color={ButtonColors.DEFAULT}
          iconOnly
          leftIcon={<MoreOutlined />}
          className={classes.actionBtn}
        ></Button>
      </ColumnActions>
    </div>
  );
};

const Column = ({ className, children, innerRef, ...other }) => {
  return (
    <div {...other} ref={innerRef} className={cn(classes.wrapper, className)}>
      {children}
    </div>
  );
};

export default Column;
