export const DEFAULT_FILTER = {
  employee: ['all'],
  order_type: ['all'],
  client: ['all'],
  order_status: ['all'],
};
export const SORT_OPTIONS = [
  {
    key: 'submitted_on_a_z',
    label: 'Date Requested (oldest first)',
  },
  {
    key: 'submitted_on_z_a',
    label: 'Date Requested (newest first)',
  },
  {
    key: 'legal_name_a_z',
    label: 'Client Name (A to Z)',
  },
  {
    key: 'legal_name_z_a',
    label: 'Client Name (Z to A)',
  },
  {
    key: 'order_type_a_z',
    label: 'Order Type (A to Z)',
  },
  {
    key: 'order_type_z_a',
    label: 'Order Type (Z to A)',
  },
  {
    key: 'id_a_z',
    label: 'Order Number (Ascending)',
  },
  {
    key: 'id_z_a',
    label: 'Order Number (Descending)',
  },
];
export const DEFAULT_SORT = 'submitted_on_a_z';
