import React from 'react';
import cn from 'classnames';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  Title,
} from 'views/pages/ClientDetail/components/Elements';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import ClassTooltipContent from 'views/pages/ClientDetail/CompanyOfficial/components/ClassTooltipContent';
import { LEASED_OPTIONS } from 'views/pages/ClientDetail/Trailer/constants';
import { useOptions } from 'helper/hooks';
import { LEASED_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const Leased = ({ className, isEdit, form, data }) => {
  const { leasorOptions } = useOptions();
  return (
    <div className={cn(classes.leasedSection, className)}>
      <Title text="Leased" />
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            <InputItem
              name={'truck_leased'}
              label="Truck Leased?"
              form={form}
              type="select"
              options={LEASED_OPTIONS}
              labelTooltip={
                <ClassTooltipContent
                  title="Truck Leased?"
                  description={
                    <>
                      When they are leasing someone else's
                      <br />
                      truck. Not when they are paying a finance
                      <br />
                      company for their own truck.
                    </>
                  }
                />
              }
            />
            <InputItem
              name={'truck_leased_from_id'}
              label="Leasor"
              placeholder="Leasor"
              form={form}
              type="select"
              options={leasorOptions}
            />
          </FormSection>
        ) : (
          <ListInfo objKeys={LEASED_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default Leased;
