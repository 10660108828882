import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import PageContent from 'components/PageContent';
import { DefaultAvatar } from 'views/pages/MyProfile/Elements';
import { actionCb } from 'utils/action';
import Profile from './components/Profile';
import Checklist from './components/Checklist';
import OrderDetail from './components/OrderDetail';
import OrderNote from './components/OrderNote';
import { useMainContentData, useActions } from './selectorData';
import { handleActionCb } from './utils';
import classes from './ClientOrderDetail.module.scss';

const MainContent = ({ onStatusChange, clientId, orderId, isAdmin }) => {
  const {
    detailData,
    clientOrder,
    updateStatusLoading,
    orderDetailFieldValues,
  } = useMainContentData();
  const {
    saveClientOrderChecklist,
    deleteClientOrderChecklist,
    getClientOrder,
    syncAddNewChecklist,
    syncRemoveChecklist,
    getClientDetailLog,
    syncUpdateChecklist,
    addClientOrderNote,
    checkUncheckChecklist,
    syncUpdateClientOrderNote,
  } = useActions();
  const [addChecklistLoading, setAddChecklistLoading] = useState(false);
  const [updateChecklistLoading, setUpdateChecklistLoading] = useState({});
  const checklistData = clientOrder?.checklists || [];
  const lastChecklist = checklistData[checklistData.length - 1];
  const handleActionCbFn = (cb, cbErr) => (res, err) => {
    if (res) {
      getClientDetailLog({ id: clientId });
    }
    handleActionCb(cb, cbErr)(res, err);
  };
  const handleCheckedChange = (checklist, checked) => {
    checkUncheckChecklist(
      checklist.id,
      checked,
      handleActionCbFn(() => {
        syncUpdateChecklist(checklist.id, 'is_checked', checked);
      })
    );
  };
  const handleUpdateChecklist = (checklist, value, cb) => {
    setUpdateChecklistLoading({
      ...updateChecklistLoading,
      [checklist.id]: true,
    });
    saveClientOrderChecklist(
      clientId,
      orderId,
      {
        checklist: [
          {
            ...checklist,
            description: value,
          },
        ],
      },
      handleActionCbFn(() => {
        setUpdateChecklistLoading({
          ...updateChecklistLoading,
          [checklist.id]: false,
        });
        if (cb) cb();
      }),
      () => {
        setUpdateChecklistLoading({
          ...updateChecklistLoading,
          [checklist.id]: true,
        });
      }
    );
  };
  const handleRemoveChecklist = checklist => {
    deleteClientOrderChecklist(
      clientId,
      orderId,
      checklist.id,
      handleActionCbFn(
        () => {
          syncRemoveChecklist(checklist.id);
        },
        () => {
          getClientOrder(clientId, orderId);
        }
      )
    );
  };
  const handleOrderChecklist = checklists => {
    saveClientOrderChecklist(
      clientId,
      orderId,
      {
        checklist: checklists.map((c, index) => ({
          ...c,
          order: index + 1,
        })),
      },
      handleActionCbFn()
    );
  };
  const handleNewChecklist = (values, cb) => {
    setAddChecklistLoading(true);
    saveClientOrderChecklist(
      clientId,
      orderId,
      {
        checklist: [
          {
            description: values.text,
            order:
              (lastChecklist ? lastChecklist.order : checklistData.length) + 1,
          },
        ],
      },
      handleActionCbFn(
        res => {
          setAddChecklistLoading(false);
          if (cb) cb();
          const newChecklists = res.data || [];
          const newChecklistObj = newChecklists[newChecklists.length - 1] || {};
          if (!isEmpty(newChecklistObj)) {
            syncAddNewChecklist(newChecklistObj);
          } else {
            getClientOrder(clientId, orderId);
          }
        },
        () => {
          setAddChecklistLoading(false);
        }
      )
    );
  };
  return (
    <PageContent initFullHeight hasMargin className={classes.mainContent}>
      <div className={classes.profile}>
        <div className={classes.logoWrapper}>
          {detailData?.company_logo ? (
            <img src={detailData?.company_logo} alt="client-detail-avatar" />
          ) : (
            <DefaultAvatar className={classes.defaultAvatar} isPicture />
          )}
        </div>
        <Profile
          data={clientOrder}
          onStatusChange={onStatusChange}
          statusLoading={updateStatusLoading}
          isAdmin={isAdmin}
        />
      </div>
      <div className={classes.orderInformation}>
        {isAdmin && (
          <>
            <OrderNote
              onSubmit={(note, cb) => {
                addClientOrderNote(
                  clientId,
                  orderId,
                  {
                    order_notes: note,
                  },
                  actionCb(() => {
                    syncUpdateClientOrderNote(note);
                    getClientDetailLog({ id: clientId });
                    if (cb) cb();
                  })
                );
              }}
              existingNote={clientOrder.order_notes || ''}
            />
            <Checklist
              data={checklistData}
              onNew={handleNewChecklist}
              onOrder={handleOrderChecklist}
              onRemove={handleRemoveChecklist}
              onUpdate={handleUpdateChecklist}
              onCheckedChange={handleCheckedChange}
              addChecklistLoading={addChecklistLoading}
              updateChecklistLoading={updateChecklistLoading}
              names={{
                list: 'checklists',
                description: 'description',
                isChecked: 'is_checked',
              }}
            />
          </>
        )}
        <OrderDetail
          className={classes.checklist}
          values={orderDetailFieldValues}
        />
      </div>
    </PageContent>
  );
};

export default MainContent;
