import React from 'react';
import cn from 'classnames';
import { useOptions } from 'helper/hooks';
import { ZIP_CODE_NUMBER_FIELD } from 'helper/const';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  Title,
  MailingAddressWrapper,
} from 'views/pages/ClientDetail/components/Elements';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import ClassTooltipContent from 'views/pages/ClientDetail/CompanyOfficial/components/ClassTooltipContent';
import { ADDRESSES_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const GarageAddress = ({ className, isEdit, form, data }) => {
  const { stateOptions } = useOptions();

  return (
    <div className={cn(classes.trailerTitleSection, className)}>
      <Title
        text="Garage Address"
        tooltipWidth={220}
        tooltip={
          <ClassTooltipContent
            title="Garage Address"
            description={
              <>
                Where this vehicle is
                <br />
                being stored or parked.
              </>
            }
          />
        }
      />
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            <InputItem
              name={'garage_address_same_as_physical'}
              label="Garage address same as physical address"
              form={form}
              type="checkbox"
              fullWidth
            />
            <MailingAddressWrapper>
              {() => {
                const values = form.getFieldsValue();
                if ((values.garage_address_same_as_physical || []).length > 0) {
                  return <></>;
                }
                return (
                  <>
                    <InputItem
                      name={'address'}
                      label="Street Address"
                      form={form}
                      addressField
                    />
                    <InputItem name={'apt'} label="Apt." form={form} aptField />
                    <InputItem
                      name={'city'}
                      label="City"
                      form={form}
                      addressGroupField
                    />
                    {/* <InputItem
                      name={'county'}
                      label="County"
                      form={form}
                      addressGroupField
                    /> */}
                    <InputItem
                      name={'state_id'}
                      label="State"
                      form={form}
                      type="select"
                      options={stateOptions}
                      addressGroupField
                    />
                    <InputItem
                      name={'zip_code'}
                      label="Zip Code"
                      form={form}
                      type="number"
                      mask={ZIP_CODE_NUMBER_FIELD.MASK}
                      placeholder={ZIP_CODE_NUMBER_FIELD.PLACEHOLDER}
                      addressGroupField
                    />
                  </>
                );
              }}
            </MailingAddressWrapper>
          </FormSection>
        ) : (
          <ListInfo objKeys={ADDRESSES_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default GarageAddress;
