import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as orderTypeSelectors from 'store/selectors/orderType';
import * as sharedSelectors from 'store/selectors/shared';
// ACTIONS
import * as orderTypeActions from 'store/actions/orderType';

export const useIndexData = () => {
  return {
    orderTypesLoading: orderTypeSelectors.useSelectAllOrderTypesLoading(),
    orderTypes: orderTypeSelectors.useSelectAllOrderTypes(),
  };
};
export const useAllTabData = () => {
  return {
    orderTypesLoading: orderTypeSelectors.useSelectAllOrderTypesLoading(),
    orderTypes: orderTypeSelectors.useSelectAllOrderTypes(),
    orderTypesTotal: orderTypeSelectors.useSelectAllOrderTypesTotal(),
    deleteLoading: orderTypeSelectors.useSelectDeleteOrderTypeLoading(),
    departments: sharedSelectors.useSelectDepartments(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...orderTypeActions,
  });
};
