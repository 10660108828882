import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { useForm } from 'components/Form';
import { validateForm, scrollToErrorField } from 'utils/form';
import { useModal } from 'components/Modal';
import { confirmModalBlueConfig, getConfirmModal } from 'utils/modal';
import toast from 'helper/toast';
import { getErrorMessageFromObj } from 'utils/object';
import { getUrlParamValueByKey, removeAllSearchParam } from 'utils/routes';
import { getInviteTeammateBodyRequest } from 'views/pages/MyTeam/utils';
import Header from './Header';
import Content from './Content';
import Information from './Information';
import EditForm from './EditForm';
import ButtonActions from './ButtonActions';
import LoadingSpin from './LoadingSpin';
import {
  validateObj,
  getInitialValues,
  getEditTeammateBodyRequest,
} from './utils';
import getPermission from '../permissions';
import { useIndexData, useActions } from './selectorData';
import classes from './Detail.module.scss';

const TeammateDetail = ({ match, location, history }) => {
  const [form] = useForm();
  const [modal, contextHolder] = useModal();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const confirmModal = getConfirmModal(modal, confirmModalBlueConfig);
  const [avatarFinalSrc, setAvatarFinalSrc] = useState('');
  const [finalFile, setFinalFile] = useState({});
  const { detailData, currentUser } = useIndexData();
  const currentRole = currentUser.role || '';
  const companyId = currentUser.company_id || '';
  const permission = getPermission(currentRole);
  const inviteTeamAsClient = permission.inviteTeamAsClient;
  const { profile_pic } = detailData;
  const {
    getTeammateDetail,
    updateTeammate,
    updateCompanyTeamMember,
  } = useActions();
  const teammateId = get(match, 'params.id');
  const onSubmit = values => {
    const fieldErrors = validateForm(validateObj(permission), values);
    if (fieldErrors.length > 0) {
      form.setFields(fieldErrors);
      scrollToErrorField();
    } else {
      const inviteBr = getInviteTeammateBodyRequest({
        ...values,
      });
      if (!inviteTeamAsClient) {
        inviteBr.id = detailData.id || '';
      }
      const br = getEditTeammateBodyRequest(
        inviteBr,
        finalFile,
        profile_pic,
        avatarFinalSrc
      );
      const cbHandler = (res, err) => {
        if (res) {
          toast.success('Teammate updated successfully.');
          setIsEdit(false);
        } else {
          toast.error(getErrorMessageFromObj(err)[0]);
        }
      };
      if (inviteTeamAsClient) {
        updateCompanyTeamMember(companyId, detailData.id, br, cbHandler);
      } else {
        updateTeammate(br, cbHandler);
      }
    }
  };
  useEffect(() => {
    setAvatarFinalSrc(profile_pic || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile_pic]);
  useEffect(() => {
    if (teammateId) {
      getTeammateDetail(teammateId, () => {
        const isEdit = getUrlParamValueByKey(location.search, 'edit');
        if (isEdit === 'true') {
          setIsEdit(true);
        }
        removeAllSearchParam(history, ['edit']);
      });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (isEdit && form) {
      setIsFormChanged(false);
      setFinalFile({});
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [isEdit]);
  return (
    <LoadingSpin>
      <div className={classes.wrapper}>
        <Header />
        <Content isEdit={isEdit}>
          {isEdit ? (
            <EditForm
              form={form}
              initialValues={getInitialValues(detailData)}
              onSubmit={onSubmit}
              isEdit={isEdit}
              confirmModal={confirmModal}
              avatarFinalSrc={avatarFinalSrc}
              setAvatarFinalSrc={setAvatarFinalSrc}
              finalFile={finalFile}
              setFinalFile={setFinalFile}
              setIsFormChanged={setIsFormChanged}
              permission={permission}
            />
          ) : (
            <Information
              confirmModal={confirmModal}
              permission={permission}
              companyId={companyId}
            />
          )}
          <ButtonActions
            setIsEdit={setIsEdit}
            isEdit={isEdit}
            onSubmit={onSubmit}
            form={form}
            isFormChanged={isFormChanged}
            confirmModal={confirmModal}
            finalFile={finalFile}
            permission={permission}
          />
        </Content>
        {contextHolder}
      </div>
    </LoadingSpin>
  );
};

export default TeammateDetail;
