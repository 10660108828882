export const NORMAL_FIELDS = [
  'notes',
  'first_name',
  'last_name',
  'phone',
  'email',
  'dot_number',
  'prospect_source_other',
];
export const NULL_FIELDS = [
  'sales_person_id',
  'prospect_source_id',
  'title_id',
  'dot_number_status_id',
];
export const DATE_FIELDS = ['initial_contact', 'date_of_application'];
export const TOP_OBJ_KEYS = [
  {
    label: 'Sales Person',
    key: 'sales_person',
    type: 'value',
  },
  {
    label: 'Initial Contact',
    key: 'initial_contact',
    type: 'date',
  },
  {
    label: 'Prospect Source',
    key: 'prospect_source',
    type: 'value',
  },
  {
    label: 'Date of Application',
    key: 'date_of_application',
    type: 'date',
  },
  {
    label: 'Other',
    key: 'prospect_source_other',
    type: 'value',
    dependField: 'prospect_source',
    dependFn: val => val !== 'Other',
    fullWidth: true,
  },
  {
    label: 'Notes',
    key: 'notes',
    type: 'value',
  },
];
export const BOTTOM_OBJ_KEYS = [
  {
    label: 'Name',
    key: 'first_name',
    type: 'value',
    nameGroup: true,
    fields: [
      {
        label: 'First Name',
        key: 'first_name',
        type: 'value',
      },
      {
        label: 'Last Name',
        key: 'last_name',
        type: 'value',
      },
    ],
  },
  {
    label: 'Title',
    key: 'title',
    type: 'value',
  },
  {
    label: 'Phone',
    key: 'phone',
    type: 'formatValue',
  },
  {
    label: 'Email',
    key: 'email',
    type: 'value',
  },
  {
    label: 'DOT Preference',
    key: 'dot_number_status',
    type: 'value',
  },
  {
    label: 'DOT Number',
    key: 'dot_number',
    type: 'value',
  },
];
