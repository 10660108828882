export const NEW_KEY = 'new_ifa_alert';
export const NORMAL_FIELDS = ['notes'];
export const NULL_FIELDS = ['business_name_search', 'client_name_search'];
export const NUMBER_FIELDS = [];
export const DATE_FIELDS = [];
export const FILE_FIELDS = [];
export const YES_NO_FIELDS = ['business_name_search', 'client_name_search'];
export const BOOLEAN_FIELDS = [];
export const SECTION_FIELDS = [];
export const SECTION_NULL_FIELDS = [];
export const SECTION_NUMBER_FIELDS = [];
export const SECTION_DATE_FIELDS = [];
export const BODY_REQUEST_NORMAL_FIELDS = ['notes'];
export const DEFAULT_TRUCK = {
  key: 'section 1',
  id: 10,
  label: '435',
  status: 'active',
};
export const DEFAULT_SECTIONS = [];

export const PROFILE_OBJ_KEYS = [
  {
    label: 'Business Name Search',
    key: 'business_name_search',
    type: 'boolean',
  },
  {
    label: 'Client Name Search',
    key: 'client_name_search',
    type: 'boolean',
  },
  {
    label: 'Notes',
    key: 'notes',
    type: 'value',
    fullWidth: true,
  },
];
