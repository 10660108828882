import isEmpty from 'lodash/isEmpty';
import { compile } from 'path-to-regexp';
import { QUEUE_DEPARTMENTS } from 'helper/const';

const processURL = (pathRegex, params) => {
  if (isEmpty(params)) {
    return pathRegex;
  }
  const toPath = compile(pathRegex);
  return toPath(params || {}, { encode: value => value });
};

const abstractURL = pathRegex => options => processURL(pathRegex, options);
const routes = {
  INDEX: abstractURL('/'),
  HOME: abstractURL('/home'),
  INTAKE_FORM: abstractURL('/intake-form'),
  MOBILE_LOCK: abstractURL('/mobile-lock'),

  // Auth
  LOGIN: abstractURL('/login'),
  REGISTRATION: abstractURL('/registration'),
  CLIENT_REGISTRATION: abstractURL('/registration/:token'),
  FORGOT_PASSWORD: abstractURL('/forgot-password'),
  RESET_PASSWORD: abstractURL('/reset-password/:token'),
  CONFIRM_EMAIL: abstractURL('/confirm-email/:token'),
  CONFIRM_EMAIL_SUCCESS: abstractURL('/confirm-email-success'),
  REQUIRED_UPDATE_PASSWORD: abstractURL('/required-update-password'),

  // Dashboard
  DASHBOARD: abstractURL('/dashboard'),
  NEW_ORDER_TYPE: abstractURL('/new-order-type'),
  ORDER_TYPE_DETAIL: abstractURL('/order-types/:id'),

  // MyProfile
  MY_PROFILE: abstractURL('/my-profile'),

  // MyCompany
  MY_COMPANY: abstractURL('/my-company'),
  COMPANY_ORDER: abstractURL('/my-company/:id/order/:orderId'),

  // MyTeam
  MY_TEAM: abstractURL('/my-team'),
  TEAMMATE_DETAIL: abstractURL('/my-team/:id/detail'),
  CREATE_ACCOUNT: abstractURL('/create-account/:token'),
  ACCEPT_INVITE: abstractURL('/accept-invitation/:token'),
  CREATE_ACCOUNT_SUCCESS: abstractURL('/create-account-success'),

  // ClientList
  CLIENT_LIST: abstractURL('/client-list'),
  CLIENT_DETAIL: abstractURL('/client-list/:id/detail'),
  CLIENT_ORDER_DETAIL: abstractURL('/client-list/:id/order/:orderId'),

  // Reminders
  REMINDERS: abstractURL('/reminders'),
  NEW_REMINDER: abstractURL('/new-reminder'),
  REMINDER_DETAIL: abstractURL('/reminders/:id'),

  // My Reports
  MY_REPORTS: abstractURL('/my-reports'),

  // Public routes
  UNSUBSCRIBE_REMINDERS: abstractURL('/unsubscribe-reminders'),
};
QUEUE_DEPARTMENTS.map(({ upperText, lowerText }) => {
  routes[`${upperText}_WORK_QUEUE`] = abstractURL(`/${lowerText}-work-queue`);
  return true;
});

export default routes;
