import React from 'react';
import cn from 'classnames';
import { Tooltip as AntdTooltip } from 'antd';

import classes from './Tooltip.module.scss';

const Tooltip = ({ overlayClassName, tooltipWidth, ...other }) => {
  const styles = {};
  if (tooltipWidth) {
    styles.maxWidth = `${tooltipWidth}${
      typeof tooltipWidth === 'number' ? 'px' : ''
    }`;
  }
  return (
    <AntdTooltip
      {...other}
      overlayClassName={cn(classes.wrapper, overlayClassName)}
      overlayStyle={styles}
    />
  );
};

export { default as TooltipBlock } from './TooltipBlock';

export default Tooltip;
