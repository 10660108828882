import { TAB_KEYS } from '../constants';

export const clientPermission = {
  isClient: true,
  tabs: [
    TAB_KEYS.CLIENT_RECORD,
    TAB_KEYS.ORDERS,
    TAB_KEYS.PAYMENT_SOURCES,
    TAB_KEYS.REMINDERS,
  ],
  newOrder: true,
  addRecord: true,
  dotNumber: {
    searchDOT: true,
    emptyText: 'No DOT Number Available',
  },
  editOrderPrice: false,
  companyInfo: {
    dotNumber: true,
  },
};
