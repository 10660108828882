import React from 'react';
import { Popover as AntdPopover } from 'antd';
import cx from 'classnames';

import classes from './Popover.module.scss';

const Popover = props => {
  const { className, ...other } = props;
  return <AntdPopover className={cx(classes.wrapper, className)} {...other} />;
};

export default Popover;
