const initialLeasorState = {
  // GET
  leasors: {
    loading: false,
    data: [],
    error: '',
  },
  leasorAccount: {
    loading: {},
    data: {},
    error: {},
  },
  leasorFuelCard: {
    loading: {},
    data: {},
    error: {},
  },
  // POST
  createLeasor: {
    loading: false,
    data: {},
    error: '',
  },
  updateLeasor: {
    loading: {},
    data: {},
    error: {},
  },
  updateLeasorStatus: {
    loading: {},
    data: {},
    error: {},
  },
  createLeasorAccount: {
    loading: false,
    data: {},
    error: '',
  },
  updateLeasorAccount: {
    loading: {},
    data: {},
    error: {},
  },
  createLeasorFuelCard: {
    loading: false,
    data: {},
    error: '',
  },
  updateLeasorFuelCard: {
    loading: {},
    data: {},
    error: {},
  },
};

export { initialLeasorState };
