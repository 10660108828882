import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';

import Button, { ButtonColors } from 'components/Button';
import toast from 'helper/toast';
import Spin from 'components/Spin';
import Space from 'components/Space';
import { getErrorMessageFromObj } from 'utils/object';
import Form, { FormItem, useForm } from 'components/Form';
import { InputPassword, InputPasswordStrong } from 'components/FormFields';
import {
  standardRequiredFieldObj,
  validateForm,
  scrollToErrorField,
  confirmPasswordDontMatchFieldObj,
} from 'utils/form';
import ROUTES from 'routes';
import PlaceholderIcon from 'assets/icons/Placeholder.svg';
import { useActions, useIndexData } from './selectorData';
import classes from './ResetPassword.module.scss';

const validateObj = () => {
  return {
    password: [standardRequiredFieldObj],
    confirmPassword: [
      standardRequiredFieldObj,
      confirmPasswordDontMatchFieldObj,
    ],
  };
};

const ResetPassword = ({ match }) => {
  const token = get(match, 'params.token', '');
  const { validateTokenLoading, resetPasswordLoading } = useIndexData();
  const { validateForgotPasswordToken, resetPassword } = useActions();
  const history = useHistory();
  const [form] = useForm();
  const [isSuccess, setIsSuccess] = useState(false);
  const [strongPassword, setStrongPassword] = useState([]);

  const onSubmit = values => {
    const fieldErrors = validateForm(validateObj(), values);
    if (fieldErrors.length > 0) {
      form.setFields(fieldErrors);
      scrollToErrorField();
    } else {
      if (strongPassword.length === 3) {
        resetPassword(
          {
            token,
            password: values.password,
            confirm_password: values.confirmPassword,
          },
          (res, err) => {
            if (res) {
              setIsSuccess(true);
            } else {
              toast.error(getErrorMessageFromObj(err)[0]);
            }
          }
        );
      }
    }
  };

  useEffect(() => {
    validateForgotPasswordToken({ token }, (_, err) => {
      if (err) {
        toast.error(getErrorMessageFromObj(err)[0]);
        history.push(ROUTES.LOGIN());
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  return (
    <Spin spinning={validateTokenLoading} tip={'Verifying...'}>
      <Space direction={'vertical'} size={'large'} className={classes.wrapper}>
        {isSuccess ? (
          <div className={classes.successContent}>
            <img src={PlaceholderIcon} alt="success" />
            <div className={classes.heading}>Success!</div>
            <p>Your password has been reset.</p>
            <div className={classes.bottomButton}>
              <Button
                type={'primary'}
                size={'large'}
                htmlType={'button'}
                className={classes.fullWidth}
                loading={resetPasswordLoading}
                color={ButtonColors.DARK_RED1}
                onClick={() => {
                  history.push(ROUTES.LOGIN());
                }}
              >
                Back to Sign In
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className={classes.heading}>Password Reset</div>
            <p>Enter your new password below.</p>
            <Form
              validateMessages={''}
              onFinish={onSubmit}
              initialValues={initialValues}
              form={form}
            >
              <InputPasswordStrong
                passwordStrongChange={val => {
                  setStrongPassword(val);
                }}
                required
                placeholder="New Password"
                label="New Password"
                form={form}
                autoFocus={true}
              />
              <FormItem name={'confirmPassword'} label="Confirm Password">
                <InputPassword
                  size={'large'}
                  placeholder={'Confirm Password'}
                  name="confirmPassword"
                  form={form}
                />
              </FormItem>
              <div className={classes.bottomButton}>
                <Button
                  type={'primary'}
                  size={'large'}
                  htmlType={'submit'}
                  className={classes.fullWidth}
                  loading={resetPasswordLoading}
                  color={ButtonColors.DARK_RED1}
                >
                  Reset Password
                </Button>
                <Button
                  type={'primary'}
                  size={'large'}
                  htmlType={'button'}
                  isLink
                  className={classes.fullWidth}
                  onClick={() => {
                    history.push(ROUTES.LOGIN());
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </>
        )}
      </Space>
    </Spin>
  );
};

export default ResetPassword;
