const initialReminderState = {
  // GET
  activeReminders: {
    loading: false,
    data: [],
    totalNo: 0,
    isMore: false,
    error: '',
  },
  inactiveReminders: {
    loading: false,
    data: [],
    totalNo: 0,
    isMore: false,
    error: '',
  },
  activeCompanyReminders: {
    loading: false,
    data: {},
    error: '',
  },
  dismissedCompanyReminders: {
    loading: false,
    data: {},
    error: '',
  },
  completedCompanyReminders: {
    loading: false,
    data: {},
    error: '',
  },
  reminderDetail: {
    loading: false,
    data: {},
    error: '',
  },

  // POST
  createReminder: {
    loading: false,
    data: {},
    error: '',
  },
  updateReminder: {
    loading: false,
    data: {},
    error: '',
  },
  deleteReminder: {
    loading: false,
    data: {},
    error: '',
  },
  changeReminderStatus: {
    loading: {},
    data: {},
    error: '',
  },
  subscribeReminderEmail: {
    loading: false,
    data: {},
    error: '',
  },
};

export { initialReminderState };
