const initialClientOrderDetailState = {
  clientOrderFieldValues: {
    loading: false,
    data: [],
    error: '',
  },
  clientOrderChecklist: {
    loading: false,
    data: [],
    error: '',
  },
  saveClientOrderChecklist: {
    loading: false,
    data: {},
    error: '',
  },
  markClientOrderChecklist: {
    loading: false,
    data: {},
    error: '',
  },
  deleteClientOrderChecklist: {
    loading: false,
    data: {},
    error: '',
  },
};

export { initialClientOrderDetailState };
