const DEFAULT_SORT = {
  field: 'legal_name',
  order: 'ascend',
};
const DEFAULT_ALL_FILTERS = {
  status: 'all',
  state: 'all',
  city: '',
};
const SORT_BY_MAPPING = {};
const CLIENT_DATA = [
  {
    name: 'Diamond Trucking',
    dot: '87654321',
    mc: '123456',
    ein: '67-8912345',
    ifta: 'ID 23-4567891',
    irp: 'ID 23-4567891',
    irp_exp_date: '11/12/2023',
    gvw: '123,456,789.00',
    trucks: '1234',
    first_name: 'Martha',
    last_name: 'Roberts',
    title: 'Roberts',
    phone: '(208) 555-1234',
    email: 'mroberts@email.com',
    city: 'Nampa',
    state: 'Idaho',
    status: 'active',
    customer_since: '04/05/2014',
    id: 1,
  },
  {
    name: 'Diamond Trucking 1',
    dot: '87654321',
    mc: '123456',
    ein: '67-8912345',
    ifta: 'ID 23-4567891',
    irp: 'ID 23-4567891',
    irp_exp_date: '11/12/2023',
    gvw: '123,456,789.00',
    trucks: '1234',
    first_name: 'Martha',
    last_name: 'Roberts',
    title: 'Roberts',
    phone: '(208) 555-1234',
    email: 'mroberts@email.com',
    city: 'Nampa',
    state: 'Idaho',
    status: 'active',
    customer_since: '04/05/2014',
    id: 2,
  },
  {
    name: 'Diamond Trucking 2',
    dot: '87654321',
    mc: '123456',
    ein: '67-8912345',
    ifta: 'ID 23-4567891',
    irp: 'ID 23-4567891',
    irp_exp_date: '11/12/2023',
    gvw: '123,456,789.00',
    trucks: '1234',
    first_name: 'Martha',
    last_name: 'Roberts',
    title: 'Roberts',
    phone: '(208) 555-1234',
    email: 'mroberts@email.com',
    city: 'Nampa',
    state: 'Idaho',
    status: 'active',
    customer_since: '04/05/2014',
    id: 3,
  },
  {
    name: 'Diamond Trucking 3',
    dot: '87654321',
    mc: '123456',
    ein: '67-8912345',
    ifta: 'ID 23-4567891',
    irp: 'ID 23-4567891',
    irp_exp_date: '11/12/2023',
    gvw: '123,456,789.00',
    trucks: '1234',
    first_name: 'Martha',
    last_name: 'Roberts',
    title: 'Roberts',
    phone: '(208) 555-1234',
    email: 'mroberts@email.com',
    city: 'Nampa',
    state: 'Idaho',
    status: 'active',
    customer_since: '04/05/2014',
    id: 4,
  },
];
export { DEFAULT_SORT, SORT_BY_MAPPING, CLIENT_DATA, DEFAULT_ALL_FILTERS };
