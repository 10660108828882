import { useStoreActions } from 'store/hooks';
import * as reminderActions from 'store/actions/reminder';
import * as reminderSelectors from 'store/selectors/reminder';

export const useIndexData = () => {
  return {
    activeRemindersLoading: reminderSelectors.useSelectActiveRemindersLoading(),
    activeReminders: reminderSelectors.useSelectActiveReminders(),
    activeRemindersTotal: reminderSelectors.useSelectActiveRemindersTotal(),
    activeRemindersIsMore: reminderSelectors.useSelectActiveRemindersIsMore(),
    inactiveRemindersLoading: reminderSelectors.useSelectInactiveRemindersLoading(),
    inactiveReminders: reminderSelectors.useSelectInactiveReminders(),
    inactiveRemindersTotal: reminderSelectors.useSelectInactiveRemindersTotal(),
    inactiveRemindersIsMore: reminderSelectors.useSelectInactiveRemindersIsMore(),
  };
};
export const useLoadingSpinData = () => {
  return {};
};

export const useActions = () => {
  return useStoreActions({
    ...reminderActions,
  });
};
