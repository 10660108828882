import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import { useOptions } from 'helper/hooks';
import Form, { useForm } from 'components/Form';
import { actionCb } from 'utils/action';
import { validateForm, scrollToErrorField } from 'utils/form';
import { Item } from 'views/pages/MyTeam/components/InviteTeammateModal';
import { getInitialValues, validateObj, getModalProps, getBR } from './utils';
import { useActions } from './selectorData';
import classes from './InviteUserModal.module.scss';

const InviteUserModal = ({ isOpen, handleClose, onSubmitCb, clientId }) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const { inviteCompanyTeamMember } = useActions();
  const { roleOptions } = useOptions();
  const [form] = useForm();
  const resetState = () => {
    setSubmitLoading(false);
  };
  const handleSubmitForm = values => {
    const fieldErrors = validateForm(validateObj(), values);
    if (fieldErrors.length > 0) {
      form.setFields(fieldErrors);
      scrollToErrorField();
    } else {
      setSubmitLoading(true);
      inviteCompanyTeamMember(
        clientId,
        getBR(values),
        actionCb(
          () => {
            setSubmitLoading(false);
            if (onSubmitCb) onSubmitCb(values);
          },
          () => {
            setSubmitLoading(false);
          }
        )
      );
    }
  };
  const modalProps = getModalProps({
    isOpen,
    form,
    handleSubmitForm,
    handleClose,
    loading: submitLoading,
  });
  useEffect(() => {
    if (isOpen && form) {
      form.resetFields();
      form.validateFields();
    }
    if (isOpen) {
      resetState();
    }
  }, [form, isOpen]);
  return (
    <Modal {...modalProps}>
      <Form
        submitHidden
        onFinish={values => {
          handleSubmitForm(values);
        }}
        initialValues={getInitialValues()}
        form={form}
        className={classes.form}
      >
        <div className="ant-modal-body-inner">
          <p>Fill out the form below to invite a new teammate.</p>
          <div className={classes.formInner}>
            <Item
              name={'first_name'}
              label="First Name"
              required
              autoFocus={true}
              form={form}
            />
            <div className={classes.customItem}>
              <Item name={'mi'} label="M.I" form={form} />
              <Item name={'last_name'} label="Last Name" required form={form} />
            </div>
            <Item
              name={'email'}
              label={'Email Address'}
              placeholder="example@email.com"
              required
              form={form}
            />
            <Item
              name={'phone_number'}
              label={'Phone Number'}
              type="phone"
              form={form}
            />
            <Item
              name={'role_id'}
              label={'Role'}
              type="select"
              required
              form={form}
              options={roleOptions}
            />
          </div>
        </div>
      </Form>
    </Modal>
  );
};
export default InviteUserModal;
