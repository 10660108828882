import React from 'react';
import cn from 'classnames';
import { InboxOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { Upload as AntdUpload } from 'antd';
import ImgCrop from 'antd-img-crop';

import classes from './Upload.module.scss';

const { Dragger: AntdDragger } = AntdUpload;

const Dragger = props => {
  const {
    icon = <InboxOutlined />,
    title = 'Click or drag file to this area to upload',
    description = `Support for a single or bulk upload. Strictly prohibit from uploading
  company data or other band files`,
    ...other
  } = props;
  return (
    <AntdDragger
      {...other}
      showUploadList={{
        ...(other.showUploadList || {}),
        removeIcon: <CloseOutlined />,
      }}
    >
      <p className="ant-upload-drag-icon">{icon}</p>
      <p className="ant-upload-text">{title}</p>
      <p className="ant-upload-hint">{description}</p>
    </AntdDragger>
  );
};

const Upload = props => {
  const {
    type,
    showChild,
    children,
    uploadRef,
    className,
    noAction,
    beforeUpload,
    ...other
  } = props;
  const addProps = {
    beforeUpload: noAction
      ? () => {
          return false;
        }
      : beforeUpload,
  };
  if (type === 'img-crop') {
    return (
      <ImgCrop rotate>
        <AntdUpload {...other} className={cn(classes.wrapper, className)}>
          {children || (
            <>
              {showChild && (
                <div className="avatar-upload-placeholder">
                  <PlusOutlined />
                  <span>Upload</span>
                </div>
              )}
            </>
          )}
        </AntdUpload>
      </ImgCrop>
    );
  }
  return (
    <AntdUpload
      {...other}
      {...addProps}
      ref={uploadRef}
      className={cn(classes.wrapper, className)}
    >
      {children}
    </AntdUpload>
  );
};

export { Dragger };
export default Upload;
