import React from 'react';
import cn from 'classnames';
import { Collapse as AntdCollapse } from 'antd';

import classes from './Collapse.module.scss';

const { Panel } = AntdCollapse;

const Collapse = ({ className, ...other }) => {
  return <AntdCollapse {...other} className={cn(classes.wrapper, className)} />;
};

export { Panel };
export default Collapse;
