import React from 'react';
import cn from 'classnames';
import { DeleteOutlined } from '@ant-design/icons';
import { useOptions } from 'helper/hooks';
import { FormSection } from 'views/pages/ClientDetail/components/Elements';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import classes from './OtherSection.module.scss';

const EditBlock = ({ className, index, form, hideLabel, onDelete }) => {
  const { fieldCategoryOptions, scOtherFieldTypeOptions } = useOptions();
  return (
    <FormSection className={cn(classes.newFormBlock, className)}>
      <InputItem
        name={[index, 'field_name']}
        label={hideLabel ? '' : 'Field Name'}
        form={form}
        placeholder="Field Name"
        required={!hideLabel}
        customValidateName={['other', index, 'field_name']}
      />
      <InputItem
        name={[index, 'category_id']}
        label={hideLabel ? '' : 'Field Category'}
        form={form}
        placeholder="Field Category"
        type="select"
        options={fieldCategoryOptions}
        required={!hideLabel}
        customValidateName={['other', index, 'category_id']}
      />
      <InputItem
        name={[index, 'field_type_id']}
        label={hideLabel ? '' : 'Field Type'}
        form={form}
        placeholder="Field Type"
        type="select"
        options={scOtherFieldTypeOptions}
        required={!hideLabel}
        customValidateName={['other', index, 'field_type_id']}
      />
      {!!onDelete && (
        <span className={classes.actionBlock}>
          <DeleteOutlined onClick={onDelete} />
        </span>
      )}
    </FormSection>
  );
};

export default EditBlock;
