import Request from './request';

// GET
export const apiGetReportFields = async () => {
  return Request.call({
    url: '/reports/fields',
    method: 'GET',
  });
};
export const apiGetReports = async data => {
  return Request.call({
    url: '/reports',
    method: 'POST',
    data,
  });
};
