import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as clientDetailSelectors from 'store/selectors/clientDetail';
import * as authSelectors from 'store/selectors/auth';
// ACTIONS
import * as orderActions from 'store/actions/order';
import * as orderTypeActions from 'store/actions/orderType';
import * as clientDetailActions from 'store/actions/clientDetail';
import * as formsMSAActions from 'store/actions/formsMSA';
import * as truckActions from 'store/actions/truck';
import * as trailerActions from 'store/actions/trailer';
import * as driverActions from 'store/actions/driver';
import * as leasorActions from 'store/actions/leasor';
import * as sharedActions from 'store/actions/shared';
import * as paymentSourceActions from 'store/actions/paymentSource';
import * as underwritingActions from 'store/actions/underwriting';
import * as clientOrderDetailActions from 'store/actions/clientOrderDetail';
import * as reminderActions from 'store/actions/reminder';

export const useIndexData = () => {
  return {
    detailData: clientDetailSelectors.useSelectClientDetail(),
    currentUser: authSelectors.useSelectCurrentUser(),
  };
};

export const useLoadingSpinData = () => {
  return {};
};

export const useActions = () => {
  return useStoreActions({
    ...orderTypeActions,
    ...clientDetailActions,
    ...formsMSAActions,
    ...truckActions,
    ...trailerActions,
    ...driverActions,
    ...leasorActions,
    ...sharedActions,
    ...paymentSourceActions,
    ...underwritingActions,
    ...orderActions,
    ...clientOrderDetailActions,
    ...reminderActions,
  });
};
