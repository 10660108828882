import React from 'react';
import Button, { ButtonColors } from 'components/Button';
import Form, { FormItem } from 'components/Form';
import { getMomentDate } from 'utils/date';
import {
  InputPassword,
  InputPasswordStrong,
  FakeAutocompleteField,
} from 'components/FormFields';
import { Item } from 'views/pages/MyTeam/components/InviteTeammateModal';
import { useFormContentData } from './selectorData';
import classes from './CreateAccount.module.scss';

const FormContent = ({
  onSubmit,
  initialValues,
  form,
  setStrongPassword,
  data,
}) => {
  const { createAccountLoading } = useFormContentData();
  const { email, start_date, group_name, role_name, employee_id } = data || {};
  return (
    <Form
      onFinish={onSubmit}
      initialValues={initialValues}
      form={form}
      className={classes.form}
    >
      <FakeAutocompleteField />
      <div className={classes.formInner}>
        <Item
          name={'first_name'}
          label="First Name"
          required
          autoFocus={true}
          form={form}
        />
        <div className={classes.customItem}>
          <Item name={'mi'} label="M.I" form={form} />
          <Item name={'last_name'} label="Last Name" required form={form} />
        </div>
        <Item
          label="Email Address"
          placeholder="example@email.com"
          required
          disabled
          value={email}
        />
        <Item
          name={'alternate_email'}
          label="Alternative / Personal Email"
          placeholder="example@email.com"
          form={form}
        />
        <InputPasswordStrong
          passwordStrongChange={val => {
            setStrongPassword(val);
          }}
          required
          label="Password"
          form={form}
          size="medium"
          isBlue
          placeholder="Enter Password"
          confirmPasswordName="confirm_password"
        />
        <FormItem name={'confirm_password'} label="Confirm Password" required>
          <InputPassword
            name="confirm_password"
            form={form}
            isBlue
            placeholder="Re-enter Password"
          />
        </FormItem>
        <Item
          name={'phone_number'}
          label="Phone Number"
          type="phone"
          required
          form={form}
        />
        <Item
          name={'dob'}
          label="Date of Birth"
          type="date"
          required
          form={form}
        />
        <Item
          label="Department"
          type="select"
          required
          disabled
          value={group_name}
        />
        <Item
          label="Position"
          type="select"
          required
          disabled
          value={role_name}
        />
        <Item
          label="Start Date"
          type="date"
          required
          disabled
          value={start_date ? getMomentDate(start_date, false, true) : ''}
        />
        <Item label="Employee ID" disabled value={employee_id} />
      </div>
      <div className={classes.bottomButton}>
        <FormItem>
          <Button
            type={'primary'}
            size={'large'}
            htmlType={'submit'}
            className={classes.fullWidth}
            loading={createAccountLoading}
            color={ButtonColors.BLUE}
          >
            Create Account
          </Button>
        </FormItem>
      </div>
    </Form>
  );
};

export default FormContent;
