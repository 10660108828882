import React from 'react';
import { CheckCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import { capitalizeFirstLetter } from 'utils/text';

export const PATTERN = {
  // eslint-disable-next-line
  EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  NUMBER: /^([0-9]+)$/,
  FLOAT: /^[0-9.,]*$/,
};
export const DATE_TIME_FORMAT = {
  BE_DATE: 'YYYY-MM-DD',
  FE_DATE: 'MMMM D, YYYY',
  TEAMMATE_PROFILE_DATE_TIME: 'MM/DD/YYYY h:mm A',
  PROFILE_DATE_TIME: 'MM/DD/YYYY hh:mma',
  PROFILE_DATE: 'MM/DD/YYYY',
  CLIENT_DATE: 'MMM YYYY',
  ORDER_LOG_DATE_TIME: 'MMMM D, YYYY [at] h:mma',
  CLIENT_LOG_DATE_TIME: 'M/D/YY [at] h:mm A',
  REMINDER_TIME: 'MM/DD/YYYY [at] h:mma',
  CHAT_MESSAGE_TIME: 'h:mm A',
};
export const FORM_VALIDATION_MESSAGES = {
  REQUIRED: 'This field is required.',
  EMAIL_PATTERN: 'Enter a valid email address.',
  PHONE: 'Enter a valid phone number.',
  PASSWORD_DONT_MATCH: 'Password does not match. Try again.',
  FIRST_NAME_REQUIRED: 'Enter your first name.',
  LAST_NAME_REQUIRED: 'Enter your last name.',
  EMAIL_REQUIRED: 'Email address cannot be blank.',
  PHONE_REQUIRED: 'Phone number cannot be blank.',
  PASSWORD_REQUIRED: 'Password cannot be blank.',
  MAX_MIN_INVALID: 'Min can be greater than max.',
  EXP_DATE_INVALID: 'MM/YY is not valid.',
};
export const PHONE_FIELD = {
  PLACEHOLDER: '123-456–7890',
  MASK: '999-999-9999',
  PLACEHOLDER1: '(123) 456–7890',
  MASK1: '(999) 999-9999',
  PLACEHOLDER2: '(000) 000–0000',
};
export const BANK_NUMBER_FIELD = {
  MASK: '999999999999999999999999999999',
};
export const FILING_NUMBER_FIELD = {
  MASK: '9999999999',
};
export const NUMBER_FIELD = {
  PLACEHOLDER: '00000000',
  MASK: '99999999',
};
export const ZIP_CODE_NUMBER_FIELD = {
  PLACEHOLDER: '00000',
  MASK: '99999',
};
export const MC_NUMBER_FIELD = {
  PLACEHOLDER: '000000',
  MASK: '999999',
};
export const CARD_NUMBER_FIELD = {
  AMEX: {
    PLACEHOLDER: '0000 000000 00000',
    MASK: '999999999999999',
  },
  NORMAL: {
    PLACEHOLDER: '0000 0000 0000 0000',
    MASK: '9999999999999999',
  },
};
export const FILE_NUMBER_FIELD = {
  PLACEHOLDER: '0000000000000000',
  MASK: '9999999999999999',
};
export const EIN_NUMBER_FIELD = {
  PLACEHOLDER: '00-0000000',
  MASK: '99-9999999',
};
export const SSN_FIELD = {
  PLACEHOLDER: '000-00-0000',
  MASK: '999-99-9999',
};
export const LICENSE_FIELD = {
  PLACEHOLDER: '0000000000000',
  MASK: '9999999999999',
};
export const POLICY_NUMBER_FIELD = {
  PLACEHOLDER: '0000000000-000',
  MASK: '9999999999-999',
};
export const VIN_FIELD = {
  PLACEHOLDER: ' 0AAAA0A00AA000000',
  MASK: '9aaaa9a99aa999999',
};
export const DOT_PIN_FIELD = {
  PLACEHOLDER: '1A23BC4D',
  MASK: '9a99aa9a',
};
export const DOT_NUMBER_FIELD = {
  PLACEHOLDER: '000000',
  MASK: '999999',
};
export const TRAILER_LENGTH_FIELD = {
  PLACEHOLDER: '00/00',
  MASK: '99/99',
};
export const TRUCKS_TRAILERS_FIELD = {
  PLACEHOLDER: '#',
  MASK: '9999999999',
};
export const MONTH_FIELD = {
  PLACEHOLDER: 'MM/YY',
  FORMAT: 'MM/YY',
  MASK: '99/99',
};
export const DATE_FIELD = {
  PLACEHOLDER: '01/01/2000',
  FORMAT: 'MM/DD/YYYY',
};
export const FORMATS = {
  PHONE: '###-###-####',
  TABLE_PHONE: '(###) ###-####',
  EIN: '##-#######',
  SSN: '###-##-####',
  LICENSE: '###########',
  POLICY_NUMBER: '##########-###',
};
export const FILE_TYPE_ACCEPT = {
  IMAGE: 'image/*',
};
const imageLimitSize = process.env.REACT_APP_IMAGE_LIMIT;
const chatAttachmentLimitSize = process.env.REACT_APP_CHAT_ATTACHMENT_LIMIT;
const commonAttachmentLimitSize = process.env.REACT_APP_COMMON_ATTACHMENT_LIMIT;
const commonWordsLimit = process.env.REACT_APP_COMMON_WORDS_LIMIT;
export const FILE_SIZE_LIMIT = {
  IMAGE: imageLimitSize ? parseInt(imageLimitSize, 10) : 2, // unit is MB
  CHAT_ATTACHMENT: chatAttachmentLimitSize
    ? parseInt(chatAttachmentLimitSize, 10)
    : 10, // unit is MB
  COMMON_ATTACHMENT: commonAttachmentLimitSize
    ? parseInt(commonAttachmentLimitSize, 10)
    : 10, // unit is MB
};
export const WORDS_LIMIT = {
  COMMON: commonWordsLimit ? parseInt(commonWordsLimit, 10) : 1000,
};
export const MODAL = {
  SIZE: {
    SMALL: 488,
    NORMAL: 584,
    SEMI_NORMAL: 650,
    SEMI_LARGE: 810,
    LARGE: 1120,
  },
};
export const STATUS_VALUES = {
  ACTIVE: 'active',
  IN_ACTIVE: 'inactive',
  ARCHIVED: 'archived',
  IN_PROGRESS: 'in-progress',
  INVITE_SENT: 'invite sent',
  DRAFT: 'draft',
  DISMISS: 'dismiss',
  COMPLETE: 'complete',
};
export const STATUS_MAPPING = {
  active: {
    label: 'Active',
    color: 'success',
    icon: <CheckCircleFilled />,
  },
  inactive: {
    label: 'Inactive',
    color: 'error',
    icon: <MinusCircleFilled />,
  },
  archived: {
    label: 'Archived',
    color: 'error',
  },
  draft: {
    label: 'Draft',
    color: 'error',
  },
  submitted: {
    label: 'Submitted',
    color: 'success',
  },
  Submitted: {
    label: 'Submitted',
    color: 'success',
  },
  'in-progress': {
    label: 'In-Progress',
    color: 'warning',
  },
  'invite sent': {
    label: 'Invite Sent',
    color: 'warning',
  },
};
export const CLIENT_ORDER_STATUS_OPTIONS = [
  'submitted',
  'payment_cleared',
  'payment_pending',
  'payment_failed',
  'in_progress',
  'completed',
  'cancelled',
  'refunded',
];
export const CLIENT_ORDER_STATUS_MAPPING = {
  draft: {
    label: 'Draft',
    color: 'error',
  },
  submitted: {
    label: 'Submitted',
    color: 'success',
  },
  in_progress: {
    label: 'In Progress',
    color: 'purple',
  },
  payment_cleared: {
    label: 'Payment Cleared',
    color: 'cyan',
  },
  payment_pending: {
    label: 'Payment Pending',
    color: 'warning',
  },
  payment_failed: {
    label: 'Payment Failed',
    color: 'red',
  },
  completed: {
    label: 'Completed',
    color: 'blue',
  },
  cancelled: {
    label: 'Cancelled',
    color: 'geekblue',
  },
  refunded: {
    label: 'Refunded',
    color: 'gold',
  },
};
export const PAGINATION = {
  PER_PAGE: 10,
  INIT_PAGE: 1,
};
export const VALID_IMAGE_TYPE = ['image/jpeg', 'image/png'];
export const ROLES = {
  SUPER_ADMIN: 'Super Admin',
  ADMIN: 'Admin',
  CLIENT: 'Client',
  CLIENT_DRIVER: 'Driver',
  ACCOUNTING_MANAGER: 'Accounting Manager',
  COMPLIANCE_MANAGER: 'Authority Express Manager',
  FACTORING_DEPARTMENT_MANAGER: 'Factoring Department Manager',
  FACTORING_ACCOUNT_MANAGER: 'Factoring Account Manager',
  SALE_MANAGER: 'Sales/Marketing Manager',
  TECH_SUPPORT_MANAGER: 'Tech Support Manager',
  UNDERWRITING_MANAGER: 'Underwriting Manager',
  OWNER_OPERATOR: 'Owner/Operator',
  OWNER_MEMBER: 'Owner/Member',
  OTHER: 'Other',
};
export const SORT_DIRECTION_MAPPING = {
  ascend: 'asc',
  descend: 'desc',
};
export const USER_TIMEZONE = 'America/Boise';
export const CHAT_TYPES = {
  INTERNAL_DEPARTMENT: 'internal_department',
  INTERNAL_CUSTOM: 'internal_custom',
  INTERNAL_INDIVIDUAL: 'internal_individual',
  CLIENT_DEPARTMENT: 'client_department',
  CLIENT_INDIVIDUAL: 'client_individual',
};
export const CHAT_SORT_MAPPING = {
  newest: 'message_id',
  oldest: 'message_id',
  az: 'name',
  za: 'name',
  unread: 'unread_message_count',
};
export const CHAT_ORDER_MAPPING = {
  newest: 'DESC',
  oldest: 'ASC',
  az: 'ASC',
  za: 'DESC',
  unread: 'DESC',
};
export const STATUS_ALL = 'all';
export const MONTHS_STRING = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const MONTHS = MONTHS_STRING.map((m, i) => {
  return {
    value: m.toLowerCase(),
    label: m,
  };
});
export const QUARTERS_STRING = ['Q1', 'Q2', 'Q3', 'Q4'];
export const QUARTERS = QUARTERS_STRING.map((q, i) => {
  return {
    value: i + 1,
    label: q,
  };
});
export const TIME_OUT_REFRESH_TOKEN = 1000;
export const SOON_REFRESH_TOKEN_TIME = 20 * 1000;
export const COMPLETED_WORK_QUEUE_NAME = {
  NORMAL: 'completed',
  CAPITALIZE: 'Completed',
};
export const CANCELLED_WORK_QUEUE_NAME = {
  NORMAL: 'cancelled',
  CAPITALIZE: 'Cancelled',
};
export const QUEUE_DEPARTMENTS = [
  'compliance',
  'underwriting',
  COMPLETED_WORK_QUEUE_NAME.NORMAL,
].map(department => {
  const cDepartment = capitalizeFirstLetter(department);
  return {
    upperText: department.toUpperCase(),
    lowerText: department,
    cName: cDepartment,
    name: cDepartment,
    slug: department,
  };
});
export const MOBILE_APP_STORE_LINK =
  process.env.REACT_APP_MOBILE_APP_STORE_LINK ||
  'https://google.com/search?q=road+office+app';
export const MOBILE_GOOGLE_PLAY_LINK =
  process.env.REACT_APP_MOBILE_GOOGLE_PLAY_LINK ||
  'https://google.com/search?q=road+office+app';
export const MY_TEAM_DISPLAY_TABS = [
  'administration',
  'accounting',
  'compliance',
  'factoring',
  'sales',
  'tech_support',
  'underwriting',
];
export const ORDER_TYPE_HIDDEN_TABS = [
  'administration',
  'accounting',
  'tech_support',
];
export const CLIENT_MY_TEAM_DISPLAY_TABS = [
  'All Employees',
  'Company Officials',
  'Drivers',
];
export const REMINDER_TRIGGER_INFORMATION_HIDDEN = [
  'Truck Added',
  'Driver Added',
  'Truck Inactivated',
  'Driver Inactivated',
  'Out of Service date',
];
export const RECURRENCY_LABEL_HAS_DUE_DATE = 'Annually';
export const SECURITY_FIELDS = [
  'dl_number',
  'dl_attachment',
  'dob',
  'ssn',
  'ein',
  'dot_pin',
];
