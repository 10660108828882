import pick from 'lodash/pick';
import { SORT_OPTIONS } from '../../constants';
export const getSortOptions = ({ onSort, currentSort }) => {
  return SORT_OPTIONS.map(option => ({
    ...pick(option, ['label', 'key']),
    props: {
      onClick: onSort(option.key),
    },
    selected: currentSort === option.key,
  }));
};
