const DEFAULT_SORT = {
  field: 'name',
  order: 'ascend',
};
const SORT_BY_MAPPING = {
  name: 'name',
  group_name: 'group_name',
  role: 'role',
  employee_id: 'employee_id',
  status: 'status',
};

export { DEFAULT_SORT, SORT_BY_MAPPING };
