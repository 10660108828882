import React from 'react';

import ButtonComponent from './Button';
import BreadcrumbComponent from './Breadcrumb';
import DropdownComponent from './Dropdown';
import MenuComponent from './Menu';
import StepComponent from './Step';
import SliderComponent from './Slider';

const NavigationComponent = ({ classes }) => {
  return (
    <>
      <h1>Navigation</h1>
      <div>
        <h2>Buttons</h2>
        <ButtonComponent classes={classes} />
      </div>
      <div>
        <h2>Breadcrumbs</h2>
        <BreadcrumbComponent classes={classes} />
      </div>
      <div>
        <h2>Dropdown</h2>
        <DropdownComponent classes={classes} />
      </div>
      <div>
        <h2>Menu</h2>
        <MenuComponent classes={classes} />
      </div>
      <div>
        <h2>Step</h2>
        <StepComponent classes={classes} />
      </div>
      <div>
        <h2>Slider</h2>
        <SliderComponent classes={classes} />
      </div>
    </>
  );
};

export default NavigationComponent;
