import {
  useReducerData,
  useReducerLoading,
  useReducerAttribute,
} from 'store/hooks';
import { REDUCER_NAMES, REDUCER_ATTRIBUTES } from 'helper/store';

// CONVERSATION_DETAIL
export const useSelectConversationDetailLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CONVERSATION_DETAIL,
    REDUCER_ATTRIBUTES.CONVERSATION_DETAIL.CONVERSATION_DETAIL
  );
};
export const useSelectConversationDetail = () => {
  return useReducerData(
    REDUCER_NAMES.CONVERSATION_DETAIL,
    REDUCER_ATTRIBUTES.CONVERSATION_DETAIL.CONVERSATION_DETAIL,
    {}
  );
};
// MARK_CHAT_UNREAD
export const useSelectMarkChatUnreadLoading = () => {
  return useReducerLoading(
    REDUCER_NAMES.CONVERSATION_DETAIL,
    REDUCER_ATTRIBUTES.CONVERSATION_DETAIL.MARK_CHAT_UNREAD
  );
};
// ACTIVE_CONVERSATION
export const useSelectActiveConversation = () => {
  return useReducerData(
    REDUCER_NAMES.CONVERSATION_DETAIL,
    REDUCER_ATTRIBUTES.CONVERSATION_DETAIL.ACTIVE_CONVERSATION,
    {}
  );
};
// RESET_ACTIVE_CONVERSATION
export const useSelectResetActiveConversation = () => {
  return useReducerAttribute(
    REDUCER_NAMES.CONVERSATION_DETAIL,
    REDUCER_ATTRIBUTES.CONVERSATION_DETAIL.RESET_ACTIVE_CONVERSATION
  );
};
