import React from 'react';
import { BANK_NUMBER_FIELD } from 'helper/const';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { FormSection } from 'views/pages/ClientDetail/components/Elements';
import { BUSINESS_PERSONAL_OPTIONS } from 'views/pages/ClientDetail/Driver/constants';

const NewAccountForm = ({ form, index, getBankName }) => {
  const handleSearchRoutingNumber = () => {
    const values = form.getFieldsValue();
    if (values[index].routing_number_ach) {
      getBankName(values[index].routing_number_ach, res => {
        if (!res) {
          return;
        }
        if (res.data.bank_name) {
          form.setFieldsValue({
            [index]: {
              ...values[index],
              bank_name: res.data.bank_name,
            },
          });
        }
      });
    }
  };
  return (
    <FormSection>
      <InputItem
        name={[index, 'routing_number_ach']}
        label="Bank Routing Number"
        form={form}
        placeholder="Routing Number ACH"
        mask={BANK_NUMBER_FIELD.MASK}
        type="number"
        onChange={handleSearchRoutingNumber}
        required
      />
      <InputItem
        name={[index, 'account_number']}
        label="Bank Account Number"
        form={form}
        placeholder="Bank Account Number"
        mask={BANK_NUMBER_FIELD.MASK}
        type="number"
        customValidateName={[index, 'account_number']}
        required
      />
      <InputItem
        name={[index, 'bank_name']}
        label="Bank Name"
        form={form}
        placeholder="Bank Name"
        customValidateName={[index, 'bank_name']}
      />
      <InputItem
        name={[index, 'name_on_account']}
        label="Account Holder Name"
        form={form}
        placeholder="Account Holder Name"
        customValidateName={[index, 'name_on_account']}
        required
      />
      <InputItem
        name={[index, 'type']}
        label="Account Type"
        form={form}
        placeholder="Account Type"
        type="select"
        options={BUSINESS_PERSONAL_OPTIONS}
        customValidateName={[index, 'type']}
        required
      />
    </FormSection>
  );
};

export default NewAccountForm;
