const initialConversationDetailState = {
  conversationDetail: {
    loading: false,
    data: {},
    error: '',
  },
  markChatUnread: {
    loading: false,
    data: {},
    error: '',
  },
  activeConversation: {},
  resetActiveConversation: null,
};

export { initialConversationDetailState };
