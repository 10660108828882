import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as sharedSelectors from 'store/selectors/shared';
import * as truckSelectors from 'store/selectors/truck';
// ACTIONS
import * as clientDetailActions from 'store/actions/clientDetail';
import * as sharedActions from 'store/actions/shared';
import * as truckActions from 'store/actions/truck';

export const useIndexData = () => {
  return {
    trucks: truckSelectors.useSelectTrucks(),
    trucksLoading: truckSelectors.useSelectTrucksLoading(),
    updateTruckLoading: truckSelectors.useSelectUpdateTruckLoading(),
    vinDecoderLoading: sharedSelectors.useSelectVinDecoderLoading(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...clientDetailActions,
    ...sharedActions,
    ...truckActions,
  });
};
