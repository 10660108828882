import React from 'react';
import cn from 'classnames';
import { RadioGroup } from 'components/FormFields';
import { STATUS_OPTIONS } from 'views/pages/ClientDetail/CompanyOfficial/constants';
import FormListSection from '../FormListSection';
import InputItem from '../InputItem';
import ViewListSection from '../ViewListSection';
import { FormSection, Title } from '../Elements';
import classes from './Forms.module.scss';

const MSA = ({
  className,
  isEdit,
  form,
  formsMSAs,
  onStatusFilter,
  status,
  setStatus,
  onStatusChange,
  changeStatusLoading,
}) => {
  const handleRenderTitle = obj => {
    return obj?.customer_name || '';
  };
  return (
    <div className={cn(classes.msaSection, className)}>
      <Title text="MSA'S">
        <div className={classes.statusFilter}>
          <RadioGroup
            items={STATUS_OPTIONS}
            onChange={e => {
              const newStatus = e.target.value;
              setStatus(newStatus);
              if (onStatusFilter) onStatusFilter(newStatus);
            }}
            value={status}
            disabled={isEdit}
          />
        </div>
      </Title>
      <FormListSection
        notFoundMessage="Currently there is no MSA information available."
        isEdit={isEdit}
        form={form}
        data={formsMSAs || []}
        titleField="customer_name"
        name="customers"
        addBtnText="Add MSA"
        newLabel="New Customer"
        renderFields={index => {
          return (
            <FormSection>
              <InputItem
                name={[index, 'customer_name']}
                label="Customer Name"
                form={form}
                placeholder="(Last Name, First Name) or Business Name"
              />
              <InputItem
                name={[index, 'msa']}
                label="MSA - Master Service Agreement"
                form={form}
                type="file"
                parentName="customers"
                customFileName={`customers.[${index}].msa`}
              />
            </FormSection>
          );
        }}
        renderViews={() => {
          return (
            <ViewListSection
              name="customers"
              renderTitle={handleRenderTitle}
              data={{
                customers: formsMSAs,
              }}
              onStatusChange={onStatusChange}
              changeStatusLoading={changeStatusLoading}
              fields={[
                {
                  label: 'Customer Name',
                  key: 'customer_name',
                  type: 'value',
                },
                {
                  label: 'MSA - Master Service Agreement',
                  key: 'documents.msa',
                  type: 'file',
                  isSingleFile: true,
                },
              ]}
            />
          );
        }}
      />
    </div>
  );
};

export default MSA;
