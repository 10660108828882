import React from 'react';
import cn from 'classnames';
import { ColumnActions } from 'components/Table';
import classes from './Elements.module.scss';

const Action = ({ className, actions = [] }) => {
  return (
    <ColumnActions
      actions={actions}
      className={cn(classes.actionBlock, className)}
      menuClassName={classes.actionBlockMenu}
      trigger={['hover']}
    />
  );
};

export default Action;
