import React from 'react';
import cn from 'classnames';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  Title,
} from 'views/pages/ClientDetail/components/Elements';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { MEDICAL_CARD_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const MedicalCard = ({ className, isEdit, form, data, disabled }) => {
  return (
    <div className={cn(classes.medicalCardSection, className)}>
      <Title text="Medical Card" />
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            <InputItem
              name={'mc_expiration_date'}
              label="Expiration Date"
              form={form}
              type="date"
              disabled={disabled}
            />
            <InputItem
              name={'medical_card'}
              label="Medical Card Certificate"
              form={form}
              type="file"
              disabled={disabled}
            />
            <InputItem
              name={'mc_verification'}
              label="I hearby verfiy that the medical examiner is listed on the National Registry of Certified Medical Examiners."
              form={form}
              type="checkbox"
              fullWidth
            />
            <InputItem
              name={'mc_first_name'}
              label="First Name"
              form={form}
              addressGroupField
            />
            <InputItem
              name={'mc_last_name'}
              label="Last Name"
              form={form}
              addressGroupField
            />
            <InputItem
              name={'mc_title'}
              label="Title"
              form={form}
              addressGroupField
            />
            <InputItem
              name={'mc_date'}
              label="Date"
              form={form}
              addressGroupField
              type="date"
            />
          </FormSection>
        ) : (
          <ListInfo objKeys={MEDICAL_CARD_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default MedicalCard;
