import * as React from 'react';
import cx from 'classnames';
import { Button as AntButton } from 'antd';

import classes from './Button.module.scss';

export const ButtonColors = {
  SECONDARY: 'secondary',
  SECONDARY_LIGHT6: 'secondary-light-6',
  DEFAULT: 'default',
  GREEN: 'green',
  BLUE: 'blue',
  BLUE_OUTLINED: 'blue-outlined',
  DARK_BLUE: 'dark-blue',
  RED: 'red',
  DARK_RED: 'dark-red',
  DARK_RED1: 'dark-red1',
  GRAY: 'gray',
};

const Button = props => {
  const {
    leftIcon,
    rightIcon,
    children,
    className,
    iconOnly,
    type = 'primary',
    color,
    isLink,
    largeIcon,
    loading,
    onClick,
    blueHover,
    ...extraProps
  } = props;
  return (
    <AntButton
      {...extraProps}
      type={type}
      className={cx(
        classes.wrapper,
        {
          [classes.iconOnly]: iconOnly,
          [classes.isLink]: isLink,
          [classes.largeIcon]: largeIcon,
          'ant-btn-secondary': color === ButtonColors.SECONDARY,
          'ant-btn-secondary-light-6': color === ButtonColors.SECONDARY_LIGHT6,
          'ant-btn-default': color === ButtonColors.DEFAULT,
          'ant-btn-green': color === ButtonColors.GREEN,
          'ant-btn-blue': color === ButtonColors.BLUE,
          'ant-btn-blue-outlined': color === ButtonColors.BLUE_OUTLINED,
          'ant-btn-dark-blue': color === ButtonColors.DARK_BLUE,
          'ant-btn-red': color === ButtonColors.RED,
          'ant-btn-dark-red': color === ButtonColors.DARK_RED,
          'ant-btn-dark-red1': color === ButtonColors.DARK_RED1,
          'ant-btn-gray': color === ButtonColors.GRAY,
          [classes.blueHover]: blueHover,
        },
        className
      )}
      shape={iconOnly ? 'circle' : 'round'}
      icon={leftIcon}
      onClick={loading ? undefined : onClick}
      loading={loading}
    >
      {children || ''}
      {rightIcon || ''}
    </AntButton>
  );
};

export default Button;
