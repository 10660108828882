import find from 'lodash/find';
import {
  standardRequiredFieldObj,
  standardArrayRequiredFieldObj,
} from 'utils/form';
import { getBEDate, getMomentDate } from 'utils/date';
import { hideTriggerInformation } from 'utils/reminder';

export const getInitialValues = (reminderDetail, isDetail) => {
  const initialValues = {
    name: isDetail ? reminderDetail.name : '',
    message: isDetail ? reminderDetail.message : '',
    order_type_id: isDetail ? reminderDetail.order_type_id : null,
    trigger_field_id: isDetail ? reminderDetail.trigger_field_id : null,
    reminder_frequency: isDetail ? reminderDetail.reminder_frequency : [],
    initial_reminder_date: isDetail
      ? reminderDetail.initial_reminder_date
        ? getMomentDate(reminderDetail.initial_reminder_date, false, true)
        : null
      : null,
    due_date: isDetail
      ? reminderDetail.due_date
        ? getMomentDate(reminderDetail.due_date, false, true)
        : null
      : null,
    recurrence_id: isDetail ? reminderDetail.recurrence_id : null,
  };
  return initialValues;
};
export const getSelectedTrigger = (val, options) => {
  const selectedTrigger = find(options, { value: val }) || {};
  const triggerWithDate = (selectedTrigger.label || '')
    .toLowerCase()
    .includes('date');

  return { selectedTrigger, triggerWithDate };
};
export const validateObj = (
  scReminderTriggerFieldOptions,
  scReminderRecurrenceOptions
) => {
  return {
    name: [standardRequiredFieldObj],
    trigger_field_id: [standardRequiredFieldObj],
    reminder_frequency: [
      {
        ...standardArrayRequiredFieldObj,
        willExecuteBy: values => {
          const { selectedTrigger, triggerWithDate } = getSelectedTrigger(
            values.trigger_field_id,
            scReminderTriggerFieldOptions
          );
          return (
            !triggerWithDate || hideTriggerInformation(selectedTrigger.label)
          );
        },
      },
    ],
    initial_reminder_date: [
      {
        ...standardRequiredFieldObj,
        willExecuteBy: values => {
          const { selectedTrigger, triggerWithDate } = getSelectedTrigger(
            values.trigger_field_id,
            scReminderTriggerFieldOptions
          );
          return (
            triggerWithDate || hideTriggerInformation(selectedTrigger.label)
          );
        },
      },
    ],
    recurrence_id: [
      {
        ...standardRequiredFieldObj,
        willExecuteBy: values => {
          const { selectedTrigger, triggerWithDate } = getSelectedTrigger(
            values.trigger_field_id,
            scReminderTriggerFieldOptions
          );
          return (
            triggerWithDate || hideTriggerInformation(selectedTrigger.label)
          );
        },
      },
    ],
    // due_date: [
    //   {
    //     ...standardRequiredFieldObj,
    //   },
    // ],
  };
};
export const getBodyRequest = (values, scReminderFrequencyOptions) => {
  const returnObj = {
    ...values,
  };
  if (
    values.reminder_frequency &&
    values.reminder_frequency.length > 0 &&
    values.reminder_frequency.includes('all')
  ) {
    returnObj.reminder_frequency = scReminderFrequencyOptions.map(o => o.value);
  }
  if (values.initial_reminder_date) {
    returnObj.initial_reminder_date = getBEDate(values.initial_reminder_date);
  }
  returnObj.due_date = values.due_date ? getBEDate(values.due_date) : '';
  return returnObj;
};
