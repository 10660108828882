import React from 'react';
import cn from 'classnames';
import PageContent from 'components/PageContent';
import classes from './Detail.module.scss';

const Content = ({ children, isEdit }) => {
  return (
    <PageContent
      initFullHeight
      hasMargin
      className={cn(classes.mainContent, {
        [classes.isEdit]: isEdit,
      })}
    >
      {children}
    </PageContent>
  );
};

export default Content;
