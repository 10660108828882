import React, { useState } from 'react';
import cn from 'classnames';
import { useOptions } from 'helper/hooks';
import { DOT_PIN_FIELD } from 'helper/const';
import Button, { ButtonColors } from 'components/Button';
import Form, { FormItem, useForm } from 'components/Form';
import { Input, RadioGroup } from 'components/FormFields';
import { ListInfo, Title } from 'views/pages/ClientDetail/components/Elements';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { useActions, useIndexData } from '../selectorData';
import { COMPANY_INFO_OBJ_KEYS, dotNumberQuestion } from '../utils';
import classes from '../IntakeForm.module.scss';

const Start = ({ next }) => {
  const { searchDotNumber } = useActions();
  const { dotNumberLoading } = useIndexData();
  const { stateOptions } = useOptions();
  const [hasDotNumber, setHasDotNumber] = useState('yes');
  const [hasFormValues, setHasFormValues] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({});
  const [form] = useForm();
  const handleSubmit = () => {
    const values = form.getFieldsValue();
    if (hasDotNumber === 'yes') {
      searchDotNumber(values, function(data, error) {
        if (!data) {
          const errors = [];
          for (const errorKey in error.error) {
            errors.push({ name: errorKey, errors: error.error[errorKey] });
          }
          form.setFields(errors);
        } else {
          setCompanyDetails(data.data);
          form.resetFields();
        }
      });
    } else {
      navigateNextPage();
    }
  };
  const resetDetails = () => {
    setCompanyDetails({});
  };
  const navigateNextPage = () => {
    next();
  };
  const checkFormValues = newStatus => {
    const values = form.getFieldsValue();
    const dotRadioStatus = newStatus ? newStatus : hasDotNumber;
    if (dotRadioStatus === 'yes') {
      setHasFormValues(values.dot_number && values.dot_pin);
    } else {
      setHasFormValues(values.company_name && values.city && values.state);
    }
  };

  return (
    <Form
      submitHidden
      onFinish={() => {
        handleSubmit();
      }}
      initialValues={{
        dot_number: null,
        dot_pin: null,
        company_name: null,
        city: null,
        state: null,
      }}
      form={form}
    >
      {Object.keys(companyDetails).length ? (
        <>
          <div className={cn(classes.stepContent, classes.listInfo)}>
            <Title text="DOT Search" />
            <ListInfo
              objKeys={COMPANY_INFO_OBJ_KEYS}
              data={companyDetails}
              className={classes.listInfo}
            />
          </div>
          <div className={cn(classes.stepAction)}>
            <Button
              disabled={!hasFormValues || dotNumberLoading}
              type="primary"
              onClick={navigateNextPage}
              color={ButtonColors.BLUE}
            >
              Continue
            </Button>
            <Button color={ButtonColors.BLUE_OUTLINED} onClick={resetDetails}>
              Back
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className={cn(classes.stepContent)}>
            <h1 className={cn(classes.stepTitle)}>Apply for Services</h1>
            <div className={cn(classes.dotNumberRow)}>
              <p>Do you have a DOT Number?</p>
              <RadioGroup
                className={cn(classes.dotNumberQue)}
                items={dotNumberQuestion}
                onChange={e => {
                  const newStatus = e.target.value;
                  setHasDotNumber(newStatus);
                  setTimeout(() => {
                    checkFormValues(newStatus);
                  }, 10);
                }}
                value={hasDotNumber}
              />
            </div>
            {hasDotNumber === 'yes' ? (
              <div className={cn(classes.dotNumberRow)}>
                <InputItem
                  name={'dot_number'}
                  size={'large'}
                  label="DOT Number"
                  form={form}
                  placeholder={'00000000'}
                  type="number"
                  onChange={() => {
                    checkFormValues();
                  }}
                />
                <InputItem
                  name={'dot_pin'}
                  size={'large'}
                  label="DOT PIN"
                  form={form}
                  placeholder={DOT_PIN_FIELD.PLACEHOLDER}
                  type="number"
                  mask={DOT_PIN_FIELD.MASK}
                  onChange={() => {
                    checkFormValues();
                  }}
                />
              </div>
            ) : (
              <>
                <div className={cn(classes.dotNumberRow, classes.companyName)}>
                  <FormItem name={'company_name'} label="Company Name">
                    <Input
                      placeholder={'Company Name'}
                      size={'large'}
                      name="company_name"
                      form={form}
                      onChange={() => {
                        checkFormValues();
                      }}
                    />
                  </FormItem>
                </div>
                <div className={cn(classes.dotNumberRow)}>
                  <FormItem name={'city'} label="City">
                    <Input
                      placeholder={'City'}
                      size={'large'}
                      name="city"
                      form={form}
                      onChange={() => {
                        checkFormValues();
                      }}
                    />
                  </FormItem>
                  <FormItem name={'state'} label="State">
                    <InputItem
                      placeholder={'State'}
                      size={'large'}
                      type={'select'}
                      name="state"
                      form={form}
                      options={stateOptions}
                      onChange={() => {
                        checkFormValues();
                      }}
                    />
                  </FormItem>
                </div>
              </>
            )}
          </div>
          <div className={cn(classes.stepAction)}>
            <Button
              disabled={!hasFormValues || dotNumberLoading}
              type="primary"
              onClick={handleSubmit}
              color={ButtonColors.BLUE}
            >
              {hasDotNumber === 'yes' ? 'Search' : 'Next'}
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default Start;
