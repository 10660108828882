import React from 'react';
import cn from 'classnames';
import Form, { useForm } from 'components/Form';
import Button, { ButtonColors } from 'components/Button';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import classes from './SearchDOT.module.scss';

const SearchDOT = ({
  className,
  searchError,
  setSearchError,
  setSearchSuccess,
  onSearch,
  loading,
  searchRef,
  showError,
}) => {
  const [form] = useForm();
  return (
    <div className={cn(classes.wrapper, className)}>
      <div>
        <label>DOT Number:</label>
        <Form
          validateMessages={''}
          onFinish={values => {
            onSearch(values.dot_number, () => {
              form.resetFields();
            });
          }}
          initialValues={{
            dot_number: '',
          }}
          form={form}
          className={classes.dotNumberWrapper}
        >
          <InputItem
            name={'dot_number'}
            form={form}
            fullWidth
            label=""
            placeholder="DOT Number"
            onChange={() => {
              setSearchError('');
            }}
            inputRef={searchRef}
          />
          <Button
            htmlType="submit"
            color={ButtonColors.BLUE}
            size="small"
            loading={loading}
          >
            Search
          </Button>
        </Form>
        {!!searchError && !!showError && (
          <div className={classes.errorMessage}>{searchError}</div>
        )}
      </div>
    </div>
  );
};

export default SearchDOT;
