import * as cdApi from 'api/conversationDetail';
import toast from 'helper/toast';
import { getErrorMessageFromObj } from 'utils/object';
import { conversationDetailTypes } from '../types';
import { increaseUnreadChat, increaseUnreadChats } from '../actions/chat';

// GET_CONVERSATION_DETAIL
export const getConversationDetail = (id, cb) => {
  return {
    isAsyncCall: true,
    baseType: conversationDetailTypes.GET_CONVERSATION_DETAIL,
    asyncCall: () => {
      return cdApi.apiGetConversationDetail(id);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// MARK_CHAT_UNREAD
export const markChatUnread = (id, { isClientTab, isDepartment }, cb) => {
  return {
    isAsyncCall: true,
    baseType: conversationDetailTypes.MARK_CHAT_UNREAD,
    asyncCall: () => {
      return cdApi.apiMarkChatUnread(id);
    },
    afterSuccessCall: (dispatch, response) => {
      if (cb) cb(response);
      dispatch(
        increaseUnreadChat(
          id,
          isClientTab ? 'clientConversations' : 'internalConversations',
          isDepartment ? 'department' : 'individual'
        )
      );
      dispatch(
        increaseUnreadChats(isClientTab ? 'clientCount' : 'internalCount')
      );
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
      toast.error(getErrorMessageFromObj(err)[0]);
    },
  };
};
// SYNC_UPDATE_CONVERSATION_DETAIL
export const syncUpdateConversationDetail = ({ attr, value }) => dispatch => {
  dispatch({
    type: conversationDetailTypes.SYNC_UPDATE_CONVERSATION_DETAIL,
    payload: { attr, value },
  });
};
// SYNC_SET_ACTIVE_CONVERSATION
export const syncSetActiveConversation = data => dispatch => {
  dispatch({
    type: conversationDetailTypes.SYNC_SET_ACTIVE_CONVERSATION,
    payload: data,
  });
};
// SYNC_SET_RESET_ACTIVE_CONVERSATION
export const syncSetResetActiveConversation = data => dispatch => {
  dispatch({
    type: conversationDetailTypes.SYNC_SET_RESET_ACTIVE_CONVERSATION,
    payload: data,
  });
};
