import React from 'react';
import FuelCardEditForm from 'views/pages/ClientDetail/Driver/components/FuelCardEditForm';

const FCEditForm = ({ form, index, parentName }) => {
  return (
    <FuelCardEditForm
      form={form}
      index={index}
      parentName={parentName}
      hideFields={[
        'name_on_card',
        'carrier_id',
        'pin',
        'card_type',
        'credit_or_debit',
      ]}
      fromPaymentSource
    />
  );
};

export default FCEditForm;
