import React from 'react';
import Spin from 'components/Spin';
// import { useLoadingSpinData } from './selectorData';

const LoadingSpin = ({ children }) => {
  // const { detailLoading } = useLoadingSpinData();
  return (
    <Spin spinning={false} tip={'Loading...'}>
      {children}
    </Spin>
  );
};

export default LoadingSpin;
