import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as trailerSelectors from 'store/selectors/trailer';
import * as sharedSelectors from 'store/selectors/shared';
// ACTIONS
import * as clientDetailActions from 'store/actions/clientDetail';
import * as sharedActions from 'store/actions/shared';
import * as trailerActions from 'store/actions/trailer';

export const useIndexData = () => {
  return {
    trailers: trailerSelectors.useSelectTrailers(),
    trailersLoading: trailerSelectors.useSelectTrailersLoading(),
    updateTrailerLoading: trailerSelectors.useSelectUpdateTrailerLoading(),
    vinDecoderLoading: sharedSelectors.useSelectVinDecoderLoading(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...clientDetailActions,
    ...sharedActions,
    ...trailerActions,
  });
};
