import get from 'lodash/get';
import {
  getUpdatedArrayData,
  getUpdatedCompanyOfficialsWithPC,
} from 'utils/store';
import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  underwritingTypes,
} from '../types';
import { initialUnderwritingState } from '../states/underwriting';

export default (state = initialUnderwritingState, action) => {
  const { recordId, recordData, tabKey } = action?.payload || {};
  switch (action.type) {
    // SYNC_SET_UNDERWRITING_PRIMARY_CONTACT
    case underwritingTypes.SYNC_SET_UNDERWRITING_PRIMARY_CONTACT: {
      return {
        ...state,
        [tabKey]: {
          ...(state[tabKey] || {}),
          data: getUpdatedCompanyOfficialsWithPC(state[tabKey], recordId),
        },
      };
    }
    // SYNC_UPDATE_UNDERWRITING_RECORD
    case underwritingTypes.SYNC_UPDATE_UNDERWRITING_RECORD: {
      return {
        ...state,
        [tabKey]: {
          ...(state[tabKey] || {}),
          data: getUpdatedArrayData(state[tabKey], recordId, recordData),
        },
      };
    }
    // GET_BACKGROUNDS
    case asyncActionTypeRequest(underwritingTypes.GET_BACKGROUNDS): {
      return {
        ...state,
        backgrounds: {
          ...state.backgrounds,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(underwritingTypes.GET_BACKGROUNDS): {
      return {
        ...state,
        backgrounds: {
          loading: false,
          data: get(action, 'response.data') || [],
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(underwritingTypes.GET_BACKGROUNDS): {
      return {
        ...state,
        backgrounds: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    }
    // CREATE_UNDERWRITING_RECORD
    case asyncActionTypeRequest(underwritingTypes.CREATE_UNDERWRITING_RECORD): {
      return {
        ...state,
        createUnderwritingRecord: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(underwritingTypes.CREATE_UNDERWRITING_RECORD): {
      return {
        ...state,
        createUnderwritingRecord: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(underwritingTypes.CREATE_UNDERWRITING_RECORD): {
      return {
        ...state,
        createUnderwritingRecord: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // UPDATE_UNDERWRITING_RECORD
    case asyncActionTypeRequest(underwritingTypes.UPDATE_UNDERWRITING_RECORD): {
      return {
        ...state,
        updateUnderwritingRecord: {
          ...state.updateUnderwritingRecord,
          loading: {
            ...state.updateUnderwritingRecord?.loading,
            [recordId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(underwritingTypes.UPDATE_UNDERWRITING_RECORD): {
      return {
        ...state,
        updateUnderwritingRecord: {
          loading: {
            ...state.updateUnderwritingRecord?.loading,
            [recordId]: false,
          },
          data: {
            ...state.updateUnderwritingRecord.data,
            [recordId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.updateUnderwritingRecord.error,
            [recordId]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(underwritingTypes.UPDATE_UNDERWRITING_RECORD): {
      return {
        ...state,
        updateUnderwritingRecord: {
          loading: {
            ...state.updateUnderwritingRecord?.loading,
            [recordId]: false,
          },
          data: {
            ...state.updateUnderwritingRecord.data,
            [recordId]: {},
          },
          error: {
            ...state.updateUnderwritingRecord.error,
            [recordId]: action.error,
          },
        },
      };
    }
    // UPDATE_UNDERWRITING_RECORD_STATUS
    case asyncActionTypeRequest(
      underwritingTypes.UPDATE_UNDERWRITING_RECORD_STATUS
    ): {
      return {
        ...state,
        updateUnderwritingRecordStatus: {
          ...state.updateUnderwritingRecordStatus,
          loading: {
            ...state.updateUnderwritingRecordStatus?.loading,
            [recordId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(
      underwritingTypes.UPDATE_UNDERWRITING_RECORD_STATUS
    ): {
      return {
        ...state,
        updateUnderwritingRecordStatus: {
          loading: {
            ...state.updateUnderwritingRecordStatus?.loading,
            [recordId]: false,
          },
          data: {
            ...state.updateUnderwritingRecordStatus.data,
            [recordId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.updateUnderwritingRecordStatus.error,
            [recordId]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(
      underwritingTypes.UPDATE_UNDERWRITING_RECORD_STATUS
    ): {
      return {
        ...state,
        updateUnderwritingRecordStatus: {
          loading: {
            ...state.updateUnderwritingRecordStatus?.loading,
            [recordId]: false,
          },
          data: {
            ...state.updateUnderwritingRecordStatus.data,
            [recordId]: {},
          },
          error: {
            ...state.updateUnderwritingRecordStatus.error,
            [recordId]: action.error,
          },
        },
      };
    }
    // GET_UCCS
    case asyncActionTypeRequest(underwritingTypes.GET_UCCS): {
      return {
        ...state,
        uccs: {
          ...state.uccs,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(underwritingTypes.GET_UCCS): {
      return {
        ...state,
        uccs: {
          loading: false,
          data: get(action, 'response.data') || [],
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(underwritingTypes.GET_UCCS): {
      return {
        ...state,
        uccs: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    }
    // GET_BUSINESS_ASSOCIATIONS
    case asyncActionTypeRequest(underwritingTypes.GET_BUSINESS_ASSOCIATIONS): {
      return {
        ...state,
        businesss: {
          ...state.businesss,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(underwritingTypes.GET_BUSINESS_ASSOCIATIONS): {
      return {
        ...state,
        businesss: {
          loading: false,
          data: get(action, 'response.data') || [],
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(underwritingTypes.GET_BUSINESS_ASSOCIATIONS): {
      return {
        ...state,
        businesss: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    }
    // GET_CREDITS
    case asyncActionTypeRequest(underwritingTypes.GET_CREDITS): {
      return {
        ...state,
        credits: {
          ...state.credits,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(underwritingTypes.GET_CREDITS): {
      return {
        ...state,
        credits: {
          loading: false,
          data: get(action, 'response.data') || [],
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(underwritingTypes.GET_CREDITS): {
      return {
        ...state,
        credits: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    }
    // GET_ALERTS
    case asyncActionTypeRequest(underwritingTypes.GET_ALERTS): {
      return {
        ...state,
        ifas: {
          ...state.ifas,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(underwritingTypes.GET_ALERTS): {
      return {
        ...state,
        ifas: {
          loading: false,
          data: get(action, 'response.data') || [],
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(underwritingTypes.GET_ALERTS): {
      return {
        ...state,
        ifas: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    }
    // GET_LOCATIONS
    case asyncActionTypeRequest(underwritingTypes.GET_LOCATIONS): {
      return {
        ...state,
        locationss: {
          ...state.locationss,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(underwritingTypes.GET_LOCATIONS): {
      return {
        ...state,
        locationss: {
          loading: false,
          data: get(action, 'response.data') || [],
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(underwritingTypes.GET_LOCATIONS): {
      return {
        ...state,
        locationss: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    }
    default:
      return state;
  }
};
