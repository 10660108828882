import React, { useState } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { TooltipBlock } from 'components/Tooltip';
import Collapse, { Panel } from 'components/Collapse';
import ButtonActions from '../ButtonActions';
import classes from './CollapseSection.module.scss';

const CollapseSection = ({
  className,
  form,
  renderContent,
  title,
  headerLink,
  headerExpiredDate,
  onSubmit,
  onEdit,
  hasFile = false,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [activeKey, setActiveKey] = useState('');
  const isExpanded = !!activeKey;
  return (
    <Collapse
      className={cn(
        classes.wrapper,
        {
          [classes.isExpanded]: isExpanded,
          [classes.isEdit]: isEdit,
        },
        className
      )}
      destroyInactivePanel
      onChange={keys => {
        setActiveKey(!!activeKey ? '' : '1');
      }}
      activeKey={activeKey ? [activeKey] : []}
    >
      <Panel
        forceRender={true}
        header={
          <div className={classes.header}>
            <h3>{title}</h3>
            {!isExpanded && (
              <>
                {hasFile ? (
                  <div className={classes.hasFile}>Includes Attachments</div>
                ) : (
                  <>
                    {!isEmpty(headerLink) && (
                      <TooltipBlock
                        tooltip={headerLink.name}
                        placement="top"
                        tooltipWidth={'100%'}
                      >
                        <div className={classes.headingLink}>
                          <a
                            rel="noreferrer"
                            href={headerLink.url}
                            target={headerLink.url !== '#' ? '_blank' : '_self'}
                          >
                            {headerLink.name}
                          </a>
                        </div>
                      </TooltipBlock>
                    )}
                    {!!headerExpiredDate && (
                      <div className={classes.expiredDate}>
                        <span>Expiration Date</span>
                        <span>{headerExpiredDate}</span>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        }
        key={'1'}
        extra={
          isExpanded ? (
            <ButtonActions
              setIsEdit={val => {
                if (val && onEdit) {
                  onEdit();
                }
                setIsEdit(val);
              }}
              isEdit={isEdit}
              form={form}
              // isFormChanged={isFormChange}
              // loading={loading}
              className={classes.buttonActions}
              preventDefault
              onSubmit={(values, cb) => {
                onSubmit(values, () => {
                  if (cb) cb();
                  setIsEdit(false);
                });
              }}
              selfLoading
              // openWarningModal={openWarningModal}
            />
          ) : null
        }
      >
        <div>{renderContent(isEdit)}</div>
      </Panel>
    </Collapse>
  );
};

export default CollapseSection;
