import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Button, { ButtonColors } from 'components/Button';
import { Input, Select } from 'components/FormFields';
import { useOptions } from 'helper/hooks';
import { applySearch } from 'utils';
import getPermission from './permissions';
import classes from './MyReports.module.scss';
import { useFilterActionsData } from './selectorData';

const FilterActions = ({
  keyword,
  setKeyword,
  onSearch,
  setActiveFields,
  fields,
  activeFields,
  activeClients,
  setActiveClients,
  onFieldChange,
  onClientChange,
  onDownload,
  downloadLoading,
}) => {
  const { currentUser } = useFilterActionsData();
  const currentRole = currentUser.role || '';
  const permission = getPermission(currentRole, currentUser);
  const { clientOptions } = useOptions();
  const selectProps = {
    placeholder: 'All',
    size: 'medium',
    isMultipleOptionCheckbox: true,
    allValue: 'all',
    showSearch: true,
    showArrow: true,
    mode: 'multiple',
    isBlue: true,
    seeFullOption: true,
    isCustomMultipleSelected: true,
    borderFirstOption: true,
  };
  return (
    <div className={classes.tabContentHeader}>
      <Input
        size={'medium'}
        name="keyword"
        allowClear
        value={keyword}
        placeholder={`Search`}
        onChange={e => {
          const value = e.target.value;
          setKeyword(value);
          if (!value) {
            onSearch('');
          } else {
            applySearch(value, val => {
              onSearch(val);
            });
          }
        }}
        prefix={<SearchOutlined />}
        isBlue
      />
      <Button
        size="large"
        color={ButtonColors.BLUE}
        onClick={onDownload}
        loading={downloadLoading}
      >
        Download CSV
      </Button>
      <div className={classes.filterDropdown}>
        {!!permission?.clientFilter && (
          <div className={classes.selectWrapper}>
            <span>Clients</span>
            <Select
              items={[
                {
                  value: 'all',
                  label: 'All',
                },
                ...clientOptions,
              ]}
              name={'activeClients'}
              onChange={val => {
                setActiveClients(val);
                onClientChange(val);
              }}
              value={activeClients}
              {...selectProps}
            />
          </div>
        )}
        <div className={classes.selectWrapper}>
          <span>Show</span>
          <Select
            items={[
              {
                value: 'all',
                label: 'All',
              },
              ...fields,
            ]}
            name={'activeFields'}
            onChange={val => {
              setActiveFields(val);
              onFieldChange(val);
            }}
            value={activeFields}
            {...selectProps}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterActions;
