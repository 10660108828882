import React from 'react';
import Spin from 'components/Spin';
import { useLoadingData } from './selectorData';

const LoadingSpin = ({ children }) => {
  const { reminderDetailLoading } = useLoadingData();
  return (
    <Spin spinning={reminderDetailLoading} tip={'Loading...'}>
      {children}
    </Spin>
  );
};

export default LoadingSpin;
