import React from 'react';
import PropTypes from 'prop-types';
import { PageHeader as AntdPageHeader } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import cx from 'classnames';

import Breadcrumb from 'components/Breadcrumb';

import classes from './PageHeader.module.scss';

const PageHeader = props => {
  const {
    className,
    breadcrumbClassName,
    titleClassName,
    breadcrumbs,
    size,
    title,
    onBack,
    isTitleClickBack,
    isWhite,
    hasPadding,
    paddingBottomLarge,
    breadcrumbMargin,
    noPadding,
    ...other
  } = props;

  return (
    <div
      className={cx(
        classes.wrapper,
        {
          [classes.noBreadcrumb]: !breadcrumbs || breadcrumbs.length === 0,
          [classes.isWhite]: isWhite,
          [classes.hasPadding]: hasPadding,
          [classes.noPadding]: noPadding,
          [classes.paddingBottomLarge]: paddingBottomLarge,
        },
        className
      )}
    >
      {breadcrumbs.length > 0 && (
        <Breadcrumb
          links={breadcrumbs}
          className={cx(
            classes.breadcrumbs,
            {
              [classes.marginLarge]: breadcrumbMargin === 'large',
              [classes.marginMedium]: breadcrumbMargin === 'medium',
            },
            breadcrumbClassName
          )}
        />
      )}
      <AntdPageHeader
        {...other}
        title={
          title ? (
            <span
              className={cx({
                [classes.isClickable]: isTitleClickBack,
              })}
              onClick={isTitleClickBack ? onBack : () => {}}
            >
              {title}
            </span>
          ) : (
            ''
          )
        }
        onBack={onBack}
        breadcrumb={{ routes: [] }}
        className={cx(classes.title, titleClassName)}
      />
    </div>
  );
};

PageHeader.defaultProps = {
  breadcrumbs: [
    {
      to: '/',
    },
    {
      to: '/',
      label: 'Second Link',
      icon: <UserOutlined />,
    },
    {
      label: 'Current Page',
    },
  ],
  className: '',
  title: '',
  onBack: null,
  breadcrumbClassName: '',
  titleClassName: '',
  isTitleClickBack: false,
  size: 'large',
  isWhite: false,
  hasPadding: false,
};

PageHeader.propTypes = {
  breadcrumbs: PropTypes.array,
  className: PropTypes.any,
  breadcrumbClassName: PropTypes.any,
  titleClassName: PropTypes.any,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  title: PropTypes.any,
  onBack: PropTypes.func,
  isTitleClickBack: PropTypes.bool,
  isWhite: PropTypes.bool,
  hasPadding: PropTypes.bool,
};

export default PageHeader;
