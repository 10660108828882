import React from 'react';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import {
  Section,
  FormSection,
  ListInfo,
} from 'views/pages/ClientDetail/components/Elements';
import { PROFILE_OBJ_KEYS } from '../../tabConstants/newYork';

const Profile = ({ isEdit, form, data }) => {
  return (
    <Section>
      {isEdit ? (
        <FormSection>
          {/* Use it to save delete_attachments for attachment removing */}
          <InputItem name={'delete_attachments'} label="" form={form} hide />
          <InputItem name={'hut_portal_id'} label="" form={form} hide />
          <InputItem name={'oscar_portal_id'} label="" form={form} hide />
          <InputItem
            name={'number'}
            label="NY HUT Permit Number"
            form={form}
            placeholder="L00000"
            maxLength={10}
          />
        </FormSection>
      ) : (
        <ListInfo objKeys={PROFILE_OBJ_KEYS} data={data} />
      )}
    </Section>
  );
};

export default Profile;
