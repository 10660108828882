import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Form, { useForm } from 'components/Form';
import { validateForm, scrollToErrorField } from 'utils/form';
import toast from 'helper/toast';
import { getErrorMessageFromObj } from 'utils/object';
import { parseSingleFile } from 'utils/file';
import { Title, SectionWrapper } from '../Elements';
import OtherIntegrateSection from '../OtherIntegrateSection';
import ButtonActions from '../ButtonActions';
import { getInitialValues, validateObj, parseMSAs } from './utils';
import Documents from './Documents';
import MSA from './MSA';
import { FILE_FIELDS } from './constants';
import classes from './Forms.module.scss';

const Forms = ({
  className,
  isEdit,
  setIsEdit,
  rootKey,
  data,
  onFormChange,
  openWarningModal,
  onSubmit,
  loading,
  formsMSAs,
  onStatusFilter,
  updateFormsMSAStatus,
  clientId,
}) => {
  const initialValues = getInitialValues(data, formsMSAs);
  const wrapperId = `record_information_${rootKey}`;
  const [form] = useForm();
  const [isFormChange, setIsFormChanged] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState({});
  const [msaStatus, setMsaStatus] = useState('active');
  const handleSubmit = values => {
    const fieldErrors = validateForm(validateObj, values);
    if (fieldErrors.length > 0) {
      form.setFields(fieldErrors);
      scrollToErrorField(wrapperId);
    } else {
      onSubmit(values, () => {
        setMsaStatus('active');
      });
    }
  };
  const handleMSAStatusChange = (status, msaId) => {
    setChangeStatusLoading({
      ...changeStatusLoading,
      [msaId]: true,
    });
    updateFormsMSAStatus(clientId, msaId, { status }, (res, err) => {
      if (res) {
        onStatusFilter(msaStatus, () => {
          setChangeStatusLoading({
            ...changeStatusLoading,
            [msaId]: false,
          });
        });
      } else {
        setChangeStatusLoading({
          ...changeStatusLoading,
          [msaId]: false,
        });
        toast.error(getErrorMessageFromObj(err)[0]);
      }
    });
  };
  useEffect(() => {
    if (isEdit) {
      setIsFormChanged(false);
      if (form) {
        form.resetFields();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);
  useEffect(() => {
    if (isEdit && form) {
      form.setFieldsValue({
        customers: parseMSAs(formsMSAs),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formsMSAs]);

  return (
    <>
      <Title text="Forms / MSA’s" nonBorder big />
      <SectionWrapper className={cn(classes.wrapper, className)} id={wrapperId}>
        <Form
          validateMessages={''}
          onFinish={handleSubmit}
          initialValues={initialValues}
          form={form}
          onValuesChange={() => {
            setIsFormChanged(true);
            onFormChange();
          }}
        >
          <div>
            <Title text="Forms">
              <ButtonActions
                setIsEdit={setIsEdit}
                isEdit={isEdit}
                form={form}
                isFormChanged={isFormChange}
                loading={loading}
                className={classes.buttonActions}
                onSubmit={handleSubmit}
                openWarningModal={openWarningModal}
              />
            </Title>
            <Documents
              isEdit={isEdit}
              form={form}
              data={parseSingleFile(data, FILE_FIELDS)}
            />
            <MSA
              isEdit={isEdit}
              form={form}
              formsMSAs={formsMSAs}
              onStatusFilter={onStatusFilter}
              status={msaStatus}
              setStatus={setMsaStatus}
              onStatusChange={handleMSAStatusChange}
              changeStatusLoading={changeStatusLoading}
            />
            <OtherIntegrateSection
              isEdit={isEdit}
              form={form}
              other={data.custom_fields || []}
              className={classes.other}
            />
          </div>
        </Form>
      </SectionWrapper>
    </>
  );
};

export default Forms;
