import { TAB_KEYS } from '../constants';

export const adminPermission = {
  isAdmin: true,
  tabs: [
    TAB_KEYS.CLIENT_RECORD,
    TAB_KEYS.ORDERS,
    TAB_KEYS.PAYMENT_SOURCES,
    TAB_KEYS.USER_MANAGEMENT,
  ],
  newOrder: true,
  addRecord: true,
  dotNumber: {
    searchDOT: false,
    emptyText: 'No records to display',
  },
  editOrderPrice: true,
  companyInfo: {
    dotNumber: false,
  },
};
