const initialTruckState = {
  // GET
  trucks: {
    loading: false,
    data: [],
    error: '',
  },
  // POST
  createTruck: {
    loading: false,
    data: {},
    error: '',
  },
  updateTruck: {
    loading: {},
    data: {},
    error: {},
  },
  updateTruckStatus: {
    loading: {},
    data: {},
    error: {},
  },
};

export { initialTruckState };
