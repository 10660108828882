import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  clientOrderDetailTypes,
} from '../types';
import get from 'lodash/get';
import { initialClientOrderDetailState } from '../states/clientOrderDetail';

export default (state = initialClientOrderDetailState, action) => {
  switch (action.type) {
    // SYNC_UPDATE_CLIENT_ORDER_CHECKLIST
    case clientOrderDetailTypes.SYNC_UPDATE_CLIENT_ORDER_CHECKLIST: {
      return {
        ...state,
        clientOrderChecklist: {
          ...state.clientOrderChecklist,
          data: action.payload || [],
        },
      };
    }
    // GET_CLIENT_ORDER_FIELD_VALUES
    case asyncActionTypeRequest(
      clientOrderDetailTypes.GET_CLIENT_ORDER_FIELD_VALUES
    ): {
      return {
        ...state,
        clientOrderFieldValues: {
          ...state.clientOrderFieldValues,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(
      clientOrderDetailTypes.GET_CLIENT_ORDER_FIELD_VALUES
    ): {
      return {
        ...state,
        clientOrderFieldValues: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(
      clientOrderDetailTypes.GET_CLIENT_ORDER_FIELD_VALUES
    ): {
      return {
        ...state,
        clientOrderFieldValues: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // GET_CLIENT_ORDER_CHECKLIST
    case asyncActionTypeRequest(
      clientOrderDetailTypes.GET_CLIENT_ORDER_CHECKLIST
    ): {
      return {
        ...state,
        clientOrderChecklist: {
          ...state.clientOrderChecklist,
          loading: action.payload?.silent ? false : true,
          data: action.payload?.emptyPrevState
            ? []
            : state.clientOrderChecklist.data,
        },
      };
    }
    case asyncActionTypeSuccess(
      clientOrderDetailTypes.GET_CLIENT_ORDER_CHECKLIST
    ): {
      return {
        ...state,
        clientOrderChecklist: {
          data: get(action, 'response.data') || [],
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(
      clientOrderDetailTypes.GET_CLIENT_ORDER_CHECKLIST
    ): {
      return {
        ...state,
        clientOrderChecklist: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    // SAVE_CLIENT_ORDER_CHECKLIST
    case asyncActionTypeRequest(
      clientOrderDetailTypes.SAVE_CLIENT_ORDER_CHECKLIST
    ): {
      return {
        ...state,
        saveClientOrderChecklist: {
          ...state.saveClientOrderChecklist,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(
      clientOrderDetailTypes.SAVE_CLIENT_ORDER_CHECKLIST
    ): {
      return {
        ...state,
        saveClientOrderChecklist: {
          data: get(action, 'response.data') || {},
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(
      clientOrderDetailTypes.SAVE_CLIENT_ORDER_CHECKLIST
    ): {
      return {
        ...state,
        saveClientOrderChecklist: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    default:
      return state;
  }
};
