import React from 'react';
import cn from 'classnames';
import { CheckCircleFilled, EditFilled, EyeFilled } from '@ant-design/icons';
import Button, { ButtonColors } from 'components/Button';

import classes from './SecurityBlock.module.scss';

const Actions = ({
  isView,
  setIsView,
  onView,
  isEdit,
  setIsEdit,
  onEdit,
  className,
  parentEdit,
  onSubmit,
  loading,
  deleteLoading,
}) => {
  return (
    <div className={cn(classes.actionButtons, className)}>
      {isEdit ? (
        <Button
          type={'ghost'}
          htmlType="button"
          onClick={() => {
            setIsEdit(false);
          }}
          color={ButtonColors.DEFAULT}
          disabled={deleteLoading}
        >
          Cancel
        </Button>
      ) : (
        <Button
          type={'ghost'}
          htmlType="button"
          onClick={() => {
            if (!isView) {
              onView();
            } else {
              setIsView(false);
            }
          }}
          color={ButtonColors.DEFAULT}
          leftIcon={isView ? null : <EyeFilled />}
          disabled={deleteLoading}
        >
          {isView ? 'Hide' : 'View'}
        </Button>
      )}
      {parentEdit && (
        <Button
          type={'primary'}
          htmlType="button"
          color={ButtonColors.BLUE}
          onClick={() => {
            if (!isEdit) {
              onEdit();
            } else {
              onSubmit();
            }
          }}
          disabled={deleteLoading}
          loading={loading}
          leftIcon={isEdit ? <CheckCircleFilled /> : <EditFilled />}
        >
          {isEdit ? 'Save' : 'Edit'}
        </Button>
      )}
    </div>
  );
};

export default Actions;
