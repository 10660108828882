import get from 'lodash/get';
import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  intakeFormTypes,
} from '../types';
import { initialIntakeFormState } from '../states/intakeForm';

export default (state = initialIntakeFormState, action) => {
  switch (action.type) {
    // SEARCH_DOT_NUMBER
    case asyncActionTypeRequest(intakeFormTypes.SEARCH_DOT_NUMBER): {
      return {
        ...state,
        dotNumberDetails: {
          ...state.dotNumberDetails,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(intakeFormTypes.SEARCH_DOT_NUMBER): {
      return {
        ...state,
        dotNumberDetails: {
          loading: false,
          data: get(action, 'response.data') || [],
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(intakeFormTypes.SEARCH_DOT_NUMBER): {
      return {
        ...state,
        dotNumberDetails: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    default:
      return state;
  }
};
