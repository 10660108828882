import React from 'react';
import cn from 'classnames';
import Tag from 'components/Tag';
import classes from './FiltersDisplay.module.scss';

const FilterTag = ({ children, onClose }) => {
  return (
    <Tag closable={true} onClose={onClose} className={classes.filterTag}>
      {children}
    </Tag>
  );
};
const FiltersDisplay = ({ className, data, onDelete }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      {(data || []).map(d => {
        return (
          <FilterTag
            onClose={() => {
              if (onDelete) onDelete(d);
            }}
            key={d.key}
          >
            {d.label}
          </FilterTag>
        );
      })}
    </div>
  );
};

export default FiltersDisplay;
