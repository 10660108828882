import React, { useEffect, useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import cn from 'classnames';
import Popover from 'components/Popover';
import Spin from 'components/Spin';
import Form, { useForm, FormList, FormItem } from 'components/Form';
import { Checkbox, CheckboxGroupWrapper, Input } from 'components/FormFields';
import Button, { ButtonColors } from 'components/Button';
import classes from './ChecklistPopover.module.scss';

const ChecklistPopover = ({
  className,
  visible,
  setVisible,
  children,
  checklists,
  onAddChecklist,
  onCheckedChange,
  checklistLoading,
  addLoading,
}) => {
  const [isAdd, setIsAdd] = useState(false);
  const listName = 'checklists';
  const [form] = useForm();
  useEffect(() => {
    if (checklists && visible) {
      form.setFieldsValue({
        checklists: checklists.map(c => ({
          id: c.id,
          label: c.description,
          checked: c.is_checked ? ['checked'] : [],
        })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklists]);
  useEffect(() => {
    if (visible) {
      setIsAdd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Popover
      content={
        <div className={classes.checklistContent}>
          <Form
            onFinish={() => {}}
            validateMessages={''}
            initialValues={{
              [listName]: [],
              addTitle: '',
            }}
            form={form}
            className={classes.form}
          >
            {checklistLoading && (
              <div className={classes.loading}>
                <Spin icon />
              </div>
            )}
            <FormList name={listName}>
              {(fields, { add, remove, move }) => {
                const values = form.getFieldsValue();
                return (
                  <>
                    {fields.map((field, index) => {
                      const obj = get(values, `${listName}[${index}]`) || {};
                      const checklistName = [index, 'checked'];
                      return (
                        <FormItem key={index} label="" name={checklistName}>
                          <CheckboxGroupWrapper>
                            <Checkbox
                              label={obj.label}
                              value={'checked'}
                              onChange={e => {
                                if (onCheckedChange)
                                  onCheckedChange(e.target.checked, obj.id);
                              }}
                            />
                          </CheckboxGroupWrapper>
                        </FormItem>
                      );
                    })}
                  </>
                );
              }}
            </FormList>
            <div className={classes.addBlock}>
              {isAdd ? (
                <>
                  <FormItem name="addTitle" label="">
                    <Input
                      name="addTitle"
                      isBlue
                      placeholder="Add checklist item..."
                    />
                  </FormItem>
                  <FormItem shouldUpdate className={classes.addFooter}>
                    {() => {
                      const thisValues = form.getFieldsValue();
                      const { addTitle } = thisValues || {};
                      return (
                        <>
                          <Button
                            color={ButtonColors.DEFAULT}
                            htmlType="button"
                            onClick={() => {
                              setIsAdd(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            color={ButtonColors.BLUE}
                            htmlType="button"
                            onClick={() => {
                              if (onAddChecklist)
                                onAddChecklist(addTitle, () => {
                                  setIsAdd(false);
                                });
                            }}
                            leftIcon={<PlusCircleOutlined />}
                            className={classes.addItemBtn}
                            disabled={!addTitle}
                            loading={addLoading}
                          >
                            Add Item
                          </Button>
                        </>
                      );
                    }}
                  </FormItem>
                </>
              ) : (
                <Button
                  color={ButtonColors.BLUE}
                  isLink
                  className={classes.addChecklistBtn}
                  onClick={() => {
                    setIsAdd(true);
                    form.setFieldsValue({
                      addTitle: '',
                    });
                  }}
                  leftIcon={<PlusCircleOutlined />}
                >
                  Add Checklist Item
                </Button>
              )}
            </div>
          </Form>
        </div>
      }
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
      placement="bottomRight"
      overlayClassName={classes.overlay}
      className={cn(classes.wrapper, className)}
    >
      <div>{children}</div>
    </Popover>
  );
};

export default ChecklistPopover;
