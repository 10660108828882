const initialChatState = {
  // CUSTOM
  internalCount: 0,
  clientCount: 0,
  companies: [],
  // GET
  internalConversations: {
    loading: false,
    data: {
      department: [],
      individual: [],
    },
    error: '',
  },
  clientConversations: {
    loading: false,
    data: {},
    error: '',
  },
  chatDepartments: {
    loading: false,
    data: [],
    error: '',
  },
  chatDepartmentsList: {
    loading: false,
    data: [],
    error: '',
  },
  messages: {
    loading: false,
    data: [],
    error: '',
  },
  attachment: {
    loading: false,
    data: {},
    error: '',
  },
  searchInternalMembers: {
    loading: false,
    data: [],
    error: '',
  },
  searchClientMembers: {
    loading: false,
    data: [],
    error: '',
  },
  chatMembers: {
    loading: false,
    data: [],
    error: '',
  },
  // POST
  sendMessage: {
    loading: false,
    data: {},
    error: '',
  },
  markRead: {
    loading: false,
    data: {},
    error: '',
  },
  updateChatSettings: {
    loading: false,
    data: {},
    error: '',
  },
  addMembers: {
    loading: false,
    data: {},
    error: '',
  },
  removeMembers: {
    loading: false,
    data: {},
    error: '',
  },
  checkConversation: {
    loading: false,
    data: {},
    error: '',
  },
  createChatGroup: {
    loading: false,
    data: {},
    error: '',
  },
};

export { initialChatState };
