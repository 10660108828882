import React, { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'components/FormFields';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import { PAGINATION } from 'helper/const';
import { applySearch } from 'utils';
import Spin from 'components/Spin';
import { useActions, useIndexData } from './selectorData';
import { orderColumns } from './utils';
import { DEFAULT_SORT, SORT_NAME_MAPPING } from './constants';
import classes from './UserManagementTab.module.scss';

const UserManagementTab = ({ clientId, onUsersFilterChanged }) => {
  // eslint-disable-next-line
  const [checkedRows, setCheckedRows] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState(DEFAULT_SORT);
  const { users, total, usersLoading } = useIndexData();
  const { getCompanyUsers } = useActions();
  const handleFilterChanged = (key, value) => {
    if (key === 'search') {
      setSearch(value);
    } else {
      setCurrentPage(value);
    }
  };
  const reloadData = (val, page, tSort) => {
    const tFilters = {
      page,
      search: val,
    };
    if (!!tSort?.order) {
      tFilters.sort_by = SORT_NAME_MAPPING[tSort.field] || tSort.field;
      tFilters.order = tSort.order === 'ascend' ? 'asc' : 'desc';
    }
    getCompanyUsers(clientId, tFilters);
    if (onUsersFilterChanged) {
      onUsersFilterChanged(tFilters);
    }
  };
  const handleSearch = val => {
    handleFilterChanged('page', 1);
    reloadData(val, 1, sort);
  };
  const columns = orderColumns();

  return (
    <div className={classes.userManagementTab}>
      <div className={classes.tabContentHeader}>
        <Input
          size={'medium'}
          name="keyword"
          value={search}
          placeholder={`Search users`}
          allowClear
          onChange={e => {
            const value = e.target.value;
            handleFilterChanged('search', value);
            if (!value) {
              handleSearch('');
            } else {
              applySearch(value, val => {
                handleSearch(val);
              });
            }
          }}
          prefix={<SearchOutlined />}
          isBlue
        />
      </div>
      <Spin spinning={!!usersLoading} icon tip={'Loading...'}>
        <Table
          rowSelection={{
            onChange: selectedRowKeys => {
              setCheckedRows(selectedRowKeys);
            },
          }}
          columns={columns}
          data={users}
          rowKey="id"
          loading={false}
          isScroll
          scroll={{ x: 850 }}
          showSorterTooltip={false}
          onSortChange={sorter => {
            const newSort = {
              field: sorter.field,
              order: sorter.order,
            };
            setSort(newSort);
            reloadData(search, currentPage, newSort);
          }}
          // rowIsClick
          // onRow={() => {
          //   return {
          //     onClick: event => {
          //       // row click
          //     },
          //   };
          // }}
        />
      </Spin>

      {total > 0 && (
        <Pagination
          current={currentPage}
          pageSize={PAGINATION.PER_PAGE}
          total={total}
          right
          onChange={page => {
            handleFilterChanged('page', page);
            reloadData(search, page, sort);
          }}
          className={classes.pagination}
        />
      )}
    </div>
  );
};

export default UserManagementTab;
