import React from 'react';
import { Comment as AntdComment } from 'antd';

import { FormItem } from 'components/Form';
import { TextArea } from 'components/FormFields';
import Button from 'components/Button';

import classes from './Comment.module.scss';

const CommentEditor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <FormItem>
      <TextArea rows={4} onChange={onChange} value={value} />
    </FormItem>
    <FormItem>
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        type="primary"
      >
        Add Comment
      </Button>
    </FormItem>
  </>
);

const Comment = props => {
  return <AntdComment {...props} className={classes.wrapper} />;
};

export { CommentEditor };

export default Comment;
