import React from 'react';
import cn from 'classnames';
import { ReactComponent as ChatNotContactFoundIcon } from 'assets/icons/ChatNotContactFoundIcon.svg';
import classes from './Elements.module.scss';

const NotContactFound = ({ className, message, isCollapse }) => {
  return (
    <div
      className={cn(
        classes.notContactFound,
        {
          [classes.isCollapse]: isCollapse,
        },
        className
      )}
    >
      <div>
        <p>{message || ''}</p>
        <ChatNotContactFoundIcon />
      </div>
    </div>
  );
};

export default NotContactFound;
