import React from 'react';
import find from 'lodash/find';
import cn from 'classnames';
import { useOptions } from 'helper/hooks';
import { EditFilled, DeleteOutlined } from '@ant-design/icons';
// import Button, { ButtonColors } from 'components/Button';
import EditBlock from './EditBlock';
import classes from './OtherSection.module.scss';

export const ViewHeader = () => {
  return (
    <div className={classes.viewHeader}>
      <div>Field Name</div>
      <div>Field Category</div>
      <div>Field Type</div>
    </div>
  );
};
export const Actions = ({ onDelete, onEdit }) => {
  return (
    <span className={classes.actionBlock}>
      <EditFilled onClick={onEdit} />
      <DeleteOutlined onClick={onDelete} />
    </span>
  );
};

const ViewBlock = ({
  className,
  data,
  canEdit,
  isEdit,
  setIsEdit,
  onDelete,
  form,
  index,
}) => {
  const { fieldCategoryOptions, scOtherFieldTypeOptions } = useOptions();
  const currentCatObj =
    find(fieldCategoryOptions, { value: data.category_id }) || {};
  const currentTypeObj =
    find(scOtherFieldTypeOptions, { value: data.field_type_id }) || {};
  if (isEdit) {
    return (
      <>
        <EditBlock
          index={index}
          form={form}
          hideLabel
          className={classes.editBlockAtView}
          onDelete={onDelete}
        />
      </>
    );
  }
  return (
    <div
      className={cn(
        classes.viewBlock,
        {
          [classes.canEdit]: canEdit,
        },
        className
      )}
    >
      <div>{data.field_name}</div>
      <div>{currentCatObj.label}</div>
      <div>{currentTypeObj.label}</div>
      {canEdit && (
        <Actions
          onDelete={onDelete}
          onEdit={() => {
            if (!isEdit) {
              setIsEdit(true);
            }
          }}
        />
      )}
    </div>
  );
};

export default ViewBlock;
