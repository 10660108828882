import { getMomentDate } from 'utils/date';

export const checkCorrectDateFormat = val => {
  if (!val || typeof val !== 'string') return false;
  const valArr = val.split('/');
  return !!valArr[2] && valArr[2].length === 4;
};
export const checkEnoughSlash = (val, checkNumber) => {
  if (!val || typeof val !== 'string') return false;
  const valArr = val.split('/');
  const numberOfSlash = val.split('').filter(k => k === '/').length;
  if (checkNumber === 2) {
    return val.length === 2 && numberOfSlash === 0;
  } else if (checkNumber === 4) {
    return (
      val.length === 5 &&
      numberOfSlash === 1 &&
      !!valArr[1] &&
      valArr[1].length === 2
    );
  }
  return false;
};
const checkValidNameArr = nameArr => {
  if (!nameArr || nameArr.length < 3) return false;
  const parentName = nameArr[0];
  const name = nameArr[2];
  if (typeof parentName !== 'string' || typeof name !== 'string') return false;
  return true;
};

export const reformatDate = (form, customValidateName, name) => event => {
  const { code, key } = event;
  if (code.includes('Digit') || code.includes('Numpad') || code === 'Slash') {
    const eventValue = event.target.value;
    const value = `${eventValue}${eventValue.length < 10 ? key : ''}`;
    const nameIsSingle = typeof name === 'string';
    if (
      code !== 'Slash' &&
      (checkEnoughSlash(value, 2) || checkEnoughSlash(value, 4))
    ) {
      event.target.value = `${value}/`;
      event.preventDefault();
    } else if (value.length >= 8 && checkCorrectDateFormat(value)) {
      const momentDate = getMomentDate(value);
      if (momentDate && momentDate.isValid()) {
        if (nameIsSingle) {
          form.setFieldsValue({
            [name]: momentDate.clone(),
          });
        } else if (checkValidNameArr(customValidateName)) {
          const parentName = customValidateName[0];
          const index = customValidateName[1];
          const name = customValidateName[2];
          const values = form.getFieldsValue();
          const previousParentObj = (values[parentName] || []).map((r, i) => {
            if (i === index) {
              r[name] = momentDate.clone();
            }
            return r;
          });
          if (previousParentObj.length > 0) {
            form.setFieldsValue({
              [parentName]: [...previousParentObj],
            });
          }
        }
      }
    }
  } else {
    event.preventDefault();
  }
};
