import React from 'react';
import { Space as AntdSpace } from 'antd';
import cx from 'classnames';

import classes from './Space.module.scss';

const Space = props => {
  const { className, ...other } = props;
  return <AntdSpace {...other} className={cx(classes.wrapper, className)} />;
};

export default Space;
