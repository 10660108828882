import React from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import Menu from 'components/Menu';
import { useActions } from './selectorData';
import styles from './style.module.scss';

const LogoutMenu = ({ isCollapsed }) => {
  const { doLogout } = useActions();
  return (
    <div className={styles.sideBarBottomContainer}>
      <Menu
        menus={[
          {
            to: '#',
            label: 'Log Out',
            icon: <LogoutOutlined />,
            props: {
              onClick: doLogout,
            },
          },
        ]}
        className={styles.mainMenu}
        isCollapsed={isCollapsed}
      />
    </div>
  );
};

export default LogoutMenu;
