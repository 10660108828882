import { ROLES } from 'helper/const';
import { adminPermission } from './admin';
import { clientPermission } from './client';

const permissions = {
  [ROLES.ADMIN]: adminPermission,
  [ROLES.SUPER_ADMIN]: adminPermission,
  [ROLES.CLIENT]: clientPermission,
};

const getPermission = (role = ROLES.CLIENT) => {
  return permissions[role] || clientPermission;
};

export default getPermission;
