import Request from './request';

// GET
export const apiGetCompanyTeamList = async (companyId, filters) => {
  return Request.call({
    url: `/company/${companyId}/team/list`,
    method: 'GET',
    params: {
      ...(filters || {}),
    },
  });
};
// POST
export const apiGetAllTeams = async data => {
  return Request.call({
    url: '/user/team-list',
    method: 'POST',
    data,
  });
};
export const apiInviteTeammate = async data => {
  return Request.call({
    url: '/user/invite',
    method: 'POST',
    data,
  });
};
export const apiResendInviteTeammate = async data => {
  return Request.call({
    url: '/user/invite/resent',
    method: 'POST',
    data,
  });
};
export const apiInviteCompanyTeamMember = async (companyId, data) => {
  return Request.call({
    url: `company/${companyId}/team/member/invite`,
    method: 'POST',
    data,
  });
};
export const apiUpdateCompanyTeamMember = async (companyId, memberId, data) => {
  return Request.call({
    url: `company/${companyId}/team/${memberId}`,
    method: 'POST',
    data,
  });
};
export const apiUpdateCompanyTeamMemberStatus = async (
  companyId,
  ids,
  status
) => {
  return Request.call({
    url: `company/${companyId}/team/status/update`,
    method: 'POST',
    data: {
      status,
      memberIds: ids,
    },
  });
};
