import React from 'react';
import cn from 'classnames';
import { ZIP_CODE_NUMBER_FIELD } from 'helper/const';
import { useOptions } from 'helper/hooks';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  Title,
} from 'views/pages/ClientDetail/components/Elements';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { ADDRESSES_OBJ_KEYS } from './constants';
import classes from './Buyout.module.scss';

const Address = ({ className, isEdit, form, data, disabled }) => {
  const { stateOptions } = useOptions();

  return (
    <div className={cn(classes.addressSection, className)}>
      <Title text="Address" />
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            <InputItem
              name={'address'}
              label="Street Address"
              form={form}
              addressField
              disabled={disabled}
            />
            <InputItem
              name={'apt'}
              label="Apt."
              form={form}
              aptField
              disabled={disabled}
            />
            <InputItem
              name={'city'}
              label="City"
              form={form}
              addressGroupField
              disabled={disabled}
            />
            {/* <InputItem
              name={'county'}
              label="County"
              form={form}
              addressGroupField
              disabled={disabled}
            /> */}
            <InputItem
              name={'state_id'}
              label="State"
              form={form}
              type="select"
              options={stateOptions}
              addressGroupField
              disabled={disabled}
            />
            <InputItem
              name={'zip_code'}
              label="Zip Code"
              form={form}
              type="number"
              mask={ZIP_CODE_NUMBER_FIELD.MASK}
              placeholder={ZIP_CODE_NUMBER_FIELD.PLACEHOLDER}
              addressGroupField
              disabled={disabled}
            />
          </FormSection>
        ) : (
          <ListInfo objKeys={ADDRESSES_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default Address;
