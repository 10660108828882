import React from 'react';
import cn from 'classnames';
import { FilterOutlined } from '@ant-design/icons';
import Dropdown from 'components/Dropdown';
import { useOptions } from 'helper/hooks';
import Button, { ButtonColors } from 'components/Button';
import { applyFilter } from 'utils';
import {
  COMPLETED_WORK_QUEUE_NAME,
  CANCELLED_WORK_QUEUE_NAME,
} from 'helper/const';
import FiltersDisplay from './components/FiltersDisplay';
import { getFilterMenus, getFiltersDisplayData } from './uiUtils';
import { getIsDefaultFilters } from './utils';
import { DEFAULT_FILTER } from './constants';
import classes from './WorkQueue.module.scss';
import { useFilterData, useActions } from './selectorData';

const Filter = ({
  reloadWorkQueue,
  filters,
  setFilters,
  handleSettingsStore,
  isCompletedWorkQueuePage,
}) => {
  const { clientMeta, orderTypeMeta, employeeMeta } = useFilterData();
  const completedStatuses = [
    COMPLETED_WORK_QUEUE_NAME.NORMAL,
    CANCELLED_WORK_QUEUE_NAME.NORMAL,
  ];
  const {
    statusOptions,
    paginationClientOptions,
    paginationEmployeeOptions,
    paginationOrderTypeptions,
  } = useOptions();
  const { getPClientList, getPOrderTypeList, getPEmployeeList } = useActions();

  const isDefaultFilter = getIsDefaultFilters(filters);
  const handleFilter = nFilters => {
    reloadWorkQueue(nFilters, null, true);
  };
  const handleFilterChange = (k, v) => {
    const nFilters = {
      ...filters,
      [k]: v,
    };
    handleSettingsStore(nFilters, null);
    setFilters(nFilters);
    applyFilter(nFilters, val => {
      handleFilter(val);
    });
  };
  const handleClearFilters = () => {
    if (!isDefaultFilter) {
      setFilters(DEFAULT_FILTER);
      handleSettingsStore(DEFAULT_FILTER, null);
      reloadWorkQueue('', null, true);
    }
  };
  const handleDeleteFilter = deleteFilter => {
    handleFilterChange(deleteFilter.key, ['all']);
  };
  const handleLoadMore = (tSearch, tPage, cb, key) => {
    const actionFn =
      key === 'employee'
        ? getPEmployeeList
        : key === 'client'
        ? getPClientList
        : getPOrderTypeList;
    actionFn(
      {
        page: tPage,
        search: tSearch,
        isMore: true,
      },
      () => {
        if (cb) cb();
      }
    );
  };
  const handleSearchData = (tSearch, key) => {
    const actionFn =
      key === 'employee'
        ? getPEmployeeList
        : key === 'client'
        ? getPClientList
        : getPOrderTypeList;
    actionFn({
      page: 1,
      search: tSearch,
    });
  };
  return (
    <>
      <FiltersDisplay
        className={classes.filtersDisplay}
        data={getFiltersDisplayData({
          filters,
          employeeOptions: paginationEmployeeOptions,
          clientOptions: paginationClientOptions,
          statusOptions,
          allOrderTypeOptions: paginationOrderTypeptions,
        })}
        onDelete={handleDeleteFilter}
      />
      <Dropdown
        trigger={['click']}
        menus={getFilterMenus({
          onFilterChange: handleFilterChange,
          filters,
          employeeOptions: paginationEmployeeOptions,
          clientOptions: paginationClientOptions,
          statusOptions: statusOptions.filter(o => {
            const isCompletedOptions = completedStatuses.includes(o.value);
            return isCompletedWorkQueuePage
              ? isCompletedOptions
              : !isCompletedOptions;
          }),
          allOrderTypeOptions: paginationOrderTypeptions,
          onClear: handleClearFilters,
          metadata: {
            employee: employeeMeta,
            orderType: orderTypeMeta,
            client: clientMeta,
          },
          loadMore: handleLoadMore,
          searchData: handleSearchData,
        })}
        hideArrow
        className={cn({
          [classes.iconActive]: !isDefaultFilter,
        })}
        menuClassName={cn(classes.filterOverlay)}
        placement="bottomRight"
      >
        <Button
          htmlType="button"
          color={ButtonColors.DEFAULT}
          iconOnly
          leftIcon={<FilterOutlined />}
        ></Button>
      </Dropdown>
    </>
  );
};

export default Filter;
