import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { validateForm, scrollToErrorField } from 'utils/form';
import Form, { useForm } from 'components/Form';
import HeaderActions from 'views/pages/ClientDetail/CompanyOfficial/components/HeaderActions';
import Profile from './Profile';
import Pull from './Pull';
import { validateObj, getInitialValues } from '../../utils';
import classes from './Section.module.scss';

const Section = ({
  className,
  isActive,
  isEdit,
  setIsEdit,
  onFormChange,
  openWarningModal,
  rootKey,
  data,
  onSubmit,
  onStatusChange,
  statusLoading,
  loading,
}) => {
  const wrapperId = `underwriting_background_${rootKey}`;
  const [isFormChange, setIsFormChanged] = useState(false);
  const [form] = useForm();
  const handleSubmit = values => {
    const fieldErrors = validateForm(validateObj(), values);
    if (fieldErrors.length > 0) {
      form.setFields(fieldErrors);
      scrollToErrorField(wrapperId);
    } else {
      onSubmit(values, () => {
        setIsEdit(false);
      });
    }
  };

  useEffect(() => {
    if (isEdit) {
      setIsFormChanged(false);
      if (form) {
        form.resetFields();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  return (
    <div className={cn(classes.wrapper, className)} id={wrapperId}>
      <Form
        validateMessages={''}
        onFinish={handleSubmit}
        initialValues={getInitialValues(data)}
        form={form}
        onValuesChange={() => {
          setIsFormChanged(true);
          onFormChange();
        }}
      >
        {isActive && (
          <HeaderActions
            className={classes.headerActions}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            form={form}
            isFormChange={isFormChange}
            loading={loading}
            handleSubmit={handleSubmit}
            openWarningModal={openWarningModal}
            status={data?.status}
            onStatusChange={onStatusChange}
            statusLoading={statusLoading}
            inSection
            hideStatus
          />
        )}
        <Profile isEdit={isEdit} form={form} data={data} />
        <Pull isEdit={isEdit} form={form} data={data} />
      </Form>
    </div>
  );
};

export default Section;
