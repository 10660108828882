import get from 'lodash/get';
import moment from 'moment';
import isArray from 'lodash/isArray';
import { parseStringToNumber } from 'utils/number';
import { getDateFromMMYY } from 'utils/date';
import { PATTERN, FORM_VALIDATION_MESSAGES } from 'helper/const';

const isEmail = val => {
  const isEmail = PATTERN.EMAIL.test(
    String(val)
      .trim()
      .toLowerCase()
  );
  return isEmail;
};

// Standard required validate object.
// willExecuteBy is additional condition to execute validate
const standardRequiredFieldObj = {
  func: val => {
    return isArray(val)
      ? val?.length > 0
      : typeof val === 'number'
      ? !!val || val === 0
      : typeof val === 'string'
      ? !!(val || '').trim()
      : !!val;
  },
  message: FORM_VALIDATION_MESSAGES.REQUIRED,
  willExecuteBy: null,
  isRequired: true,
};
// Standard Array required validate object.
// willExecuteBy is additional condition to execute validate
const standardArrayRequiredFieldObj = {
  func: val => {
    return typeof val === 'object' && !!val && val.length > 0;
  },
  message: FORM_VALIDATION_MESSAGES.REQUIRED,
  willExecuteBy: null,
  isRequired: true,
};
// Confirm Password Don't Match validate object.
// willExecuteBy is additional condition to execute validate
const confirmPasswordDontMatchFieldObj = {
  func: (val, values) => {
    const strVal = String(val).trim();
    const strPasswordVal = String(values.password).trim();
    return strVal === strPasswordVal;
  },
  message: FORM_VALIDATION_MESSAGES.PASSWORD_DONT_MATCH,
  willExecuteBy: null,
};

const rangeMaxMinFieldObjFn = ({ field = '', fieldIs = 'max' }) => {
  if (!field) {
    return {
      func: () => {
        return true;
      },
      message: '',
      willExecuteBy: null,
    };
  }
  return {
    func: (val, values) => {
      const valNum = parseFloat(val);
      const otherValNum = parseFloat(values[field]);
      if (!valNum && !otherValNum) {
        return true;
      }
      return fieldIs === 'max' ? valNum < otherValNum : valNum > otherValNum;
    },
    message: FORM_VALIDATION_MESSAGES.MAX_MIN_INVALID,
    willExecuteBy: null,
  };
};

const phoneEFaxFaxValidate = val => {
  const validateVal = parseStringToNumber(val);
  return !validateVal || validateVal.length >= 10;
};
// Phone pattern validate.
const phoneFieldObj = {
  func: phoneEFaxFaxValidate,
  message: FORM_VALIDATION_MESSAGES.PHONE,
  willExecuteBy: null,
};

// Email Pattern validate.
const emailFieldObj = {
  func: val => {
    return !val || isEmail(val);
  },
  message: FORM_VALIDATION_MESSAGES.EMAIL_PATTERN,
  willExecuteBy: null,
};

// MM/YY Date validate.
const expDateFieldObj = {
  func: val => {
    const tDateStr = getDateFromMMYY(val, '/');
    return !val || (!!tDateStr && moment(tDateStr).isValid());
  },
  message: FORM_VALIDATION_MESSAGES.EXP_DATE_INVALID,
  willExecuteBy: null,
};

// Base on UX, we created form custom validation function
// The idea is that we will use custom validation instead of antd form validation
// Validation execute after submit form
// And each field validation will be reset after end user changed each field
const validateForm = (validateObj, values, isArr) => {
  const fieldErrors = [];
  const arr = isArr ? validateObj : Object.keys(validateObj);
  arr.map(obj => {
    const rules = isArr ? obj.rules : validateObj[obj];
    const name = isArr ? obj.name : obj;
    for (var i = 0; i < rules.length; i++) {
      const fieldValidateObj = rules[i];
      const value = get(values, name, '');
      if (
        !fieldValidateObj.func(value, values) &&
        (!fieldValidateObj.willExecuteBy ||
          (fieldValidateObj.willExecuteBy &&
            !fieldValidateObj.willExecuteBy(values)))
      ) {
        fieldErrors.push({
          name,
          errors: [fieldValidateObj.message],
        });
        break;
      }
    }
    return true;
  });

  return fieldErrors;
};
const formRequired = (validateObj, values) => {
  let stillRequired = false;
  const arr = Object.keys(validateObj);
  arr
    .filter(obj => validateObj[obj].filter(r => r.isRequired).length > 0)
    .map(obj => {
      if (!values[obj]) stillRequired = true;
      return true;
    });
  return stillRequired;
};

// Scroll to error field after submit form
const scrollToErrorField = wrapperId => {
  const rootDoc = wrapperId ? document.getElementById(wrapperId) : document;
  setTimeout(() => {
    const errorEls = rootDoc
      ? rootDoc.querySelector('.ant-form-item-has-error')
      : null;
    if (errorEls) {
      errorEls.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, 0);
};

const getFileFieldInitialValues = (fieldFields, data, prefix, otherPrefixs) => {
  const initialValues = {};
  (fieldFields || []).map((field, index) => {
    const otherPrefix = otherPrefixs?.[index];
    const fileObj = get(data, field) || null;
    let originField = prefix ? field.replace(`${prefix}.`, '') : field;
    if (otherPrefix) {
      originField = originField.replace(`${otherPrefix}.`, '');
    }
    initialValues[originField] = fileObj
      ? fileObj.id
        ? [
            {
              ...fileObj,
              uid: fileObj.id,
            },
          ]
        : fileObj.map(f => ({
            ...f,
            uid: f.id,
          }))
      : null;
    return true;
  });
  return initialValues;
};

export {
  isEmail,
  validateForm,
  standardRequiredFieldObj,
  standardArrayRequiredFieldObj,
  scrollToErrorField,
  confirmPasswordDontMatchFieldObj,
  rangeMaxMinFieldObjFn,
  phoneFieldObj,
  emailFieldObj,
  formRequired,
  getFileFieldInitialValues,
  expDateFieldObj,
};
