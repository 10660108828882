import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import {
  UpCircleOutlined,
  PaperClipOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { TextArea } from 'components/FormFields';
import { FILE_SIZE_LIMIT } from 'helper/const';
import Upload from 'components/Upload';
import { scrollChatContentToBottom } from 'utils/index';
import { SelectedAttachment, MessageSending } from './components/Elements';
import { useNewMessage } from './hooks';
import { useMessageInputData, useActions } from './selectorData';
import classes from './Chat.module.scss';

const totalFileSize = (previousFiles, currentFile) => {
  let totalSizes = 0;
  previousFiles.map(f => {
    totalSizes += f.size;
    return true;
  });
  totalSizes += currentFile.size;
  return totalSizes / 1024 / 1024;
};

const MessageInput = ({ defaultValue, onSend = () => {}, confirmModal }) => {
  const inputRef = useRef(null);
  const { syncSetMessages } = useActions();
  const { sendMessageLoading } = useMessageInputData();
  const [fileSend, setFileSend] = useState([]);
  const fileSendLength = fileSend.length;
  const hasFile = fileSend && fileSendLength > 0;
  const [value, setValue] = useState(defaultValue);
  const newMessage = useNewMessage({ text: value });
  const handleSendValue = () => {
    if (!sendMessageLoading && ((value && value.trim() !== '') || hasFile)) {
      if (hasFile) {
        // If has attachments, call api to send message with attachments
        // wait api success to reset message and attachments
        onSend({ message: value, attachments: fileSend }, () => {
          setValue('');
          setFileSend([]);
        });
      } else {
        // If has message only, append message into chatbox
        // Call api at background
        // isSync to prevent calling api reload message after sent
        syncSetMessages(newMessage);
        scrollChatContentToBottom();
        setValue('');
        onSend({ message: value, attachments: [], isSync: true });
      }
    }
  };
  useEffect(() => {
    if (defaultValue !== value) {
      setValue(defaultValue || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);
  return (
    <div
      className={cn(classes.messageInput, {
        [classes.hasFile]: hasFile,
      })}
    >
      {sendMessageLoading && hasFile && (
        <div className={classes.messageSendingWrapper}>
          <MessageSending
            message={
              hasFile &&
              `Uploading Media: ${fileSendLength} item${
                fileSendLength > 1 ? 's' : ''
              }`
            }
          />
        </div>
      )}
      {hasFile && !sendMessageLoading && (
        <div className={classes.selectedAttachments}>
          {fileSend.map((f, i) => {
            return (
              <SelectedAttachment
                name={f.name}
                onRemove={() => {
                  setFileSend(fileSend.filter((_fs, fsIndex) => fsIndex !== i));
                }}
                key={f.uid}
              />
            );
          })}
        </div>
      )}
      <Upload
        fileList={[]}
        accept={''}
        beforeUpload={file => {
          const fileLimit = FILE_SIZE_LIMIT.CHAT_ATTACHMENT;
          if (totalFileSize(fileSend, file) > fileLimit) {
            confirmModal(
              'Upload Failed',
              <div>
                Attachments size cannot exceed {fileLimit}MB. Please try again.
              </div>,
              () => {},
              {
                type: 'error',
                centered: true,
              }
            );
          } else {
            setFileSend([...fileSend, file]);
            if (inputRef && inputRef.current) {
              inputRef.current.focus();
            }
          }

          return false;
        }}
      >
        {hasFile ? <PlusCircleOutlined /> : <PaperClipOutlined />}
      </Upload>
      <TextArea
        name="inputMessage"
        value={value}
        placeholder="Start your message"
        autoSize={{ minRows: 1, maxRows: 5 }}
        onPressEnter={e => {
          if (!e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
            handleSendValue();
          }
        }}
        onChange={e => setValue(e.target.value)}
        autoFocus
        innerRef={inputRef}
      />
      <UpCircleOutlined onClick={handleSendValue} />
    </div>
  );
};

export default MessageInput;
