import { useStoreActions } from 'store/hooks';
// SELECTORS
import * as intakeSelectors from 'store/selectors/intakeForm';
// ACTIONS
import * as intakeFormActions from 'store/actions/intakeForm';

export const useIndexData = () => {
  return {
    dotNumberLoading: intakeSelectors.useSelectDotNumberLoading(),
    dotNumberDetails: intakeSelectors.useSelectDotNumber(),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...intakeFormActions,
  });
};
