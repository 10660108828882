import React from 'react';
import cn from 'classnames';
import Actions from './Actions';
import Edit from './Edit';
import View from './View';
import classes from './OtherSection.module.scss';

const OtherSection = ({
  className,
  form,
  index,
  parentName,
  renderEdit,
  data,
  onDelete,
  onUpdate,
  isEdit,
  setIsEdit,
}) => {
  const viewFields = [{ labelName: 'field_name' }];
  const handleEdit = () => {
    setIsEdit(true);
  };
  const handleSubmit = () => {
    if (onUpdate) {
      onUpdate();
    }
  };
  const handleDelete = () => {
    if (onDelete) {
      onDelete();
    }
  };
  const actionsProps = {
    isEdit,
    setIsEdit,
    onEdit: handleEdit,
    onSubmit: handleSubmit,
    onDelete: handleDelete,
    loading: false,
    deleteLoading: false,
  };
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.viewMode]: !isEdit,
        },
        className
      )}
    >
      <Actions {...actionsProps} />
      {isEdit ? (
        <>
          {renderEdit ? (
            renderEdit()
          ) : (
            <Edit
              form={form}
              index={index}
              parentName={parentName}
              data={data}
            />
          )}
        </>
      ) : (
        <div>
          <View fields={viewFields} data={data} onDelete={handleDelete} />
        </div>
      )}
    </div>
  );
};

export default OtherSection;
