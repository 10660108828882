import createTypes from 'redux-create-action-types';

export default createTypes(
  // GET
  'GET_CONVERSATION_DETAIL',
  // POST
  'MARK_CHAT_UNREAD',
  // CUSTOM
  'SYNC_UPDATE_CONVERSATION_DETAIL',
  'SYNC_SET_ACTIVE_CONVERSATION',
  'SYNC_SET_RESET_ACTIVE_CONVERSATION'
);
