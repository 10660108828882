import get from 'lodash/get';
import { getUpdatedArrayData } from 'utils/store';
import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  trailerTypes,
} from '../types';
import { initialTrailerState } from '../states/trailer';

export default (state = initialTrailerState, action) => {
  const { trailerId, trailerData } = action?.payload || {};
  switch (action.type) {
    // SYNC_UPDATE_TRAILER
    case trailerTypes.SYNC_UPDATE_TRAILER: {
      return {
        ...state,
        trailers: {
          ...state.trailers,
          data: getUpdatedArrayData(state.trailers, trailerId, trailerData),
        },
      };
    }
    // GET_TRAILERS
    case asyncActionTypeRequest(trailerTypes.GET_TRAILERS): {
      return {
        ...state,
        trailers: {
          ...state.trailers,
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(trailerTypes.GET_TRAILERS): {
      return {
        ...state,
        trailers: {
          loading: false,
          data: get(action, 'response.data') || [],
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(trailerTypes.GET_TRAILERS): {
      return {
        ...state,
        trailers: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    }
    // CREATE_TRAILER
    case asyncActionTypeRequest(trailerTypes.CREATE_TRAILER): {
      return {
        ...state,
        createTrailer: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(trailerTypes.CREATE_TRAILER): {
      return {
        ...state,
        createTrailer: {
          loading: false,
          data: get(action, 'response.data') || {},
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(trailerTypes.CREATE_TRAILER): {
      return {
        ...state,
        createTrailer: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    }
    // UPDATE_TRAILER
    case asyncActionTypeRequest(trailerTypes.UPDATE_TRAILER): {
      return {
        ...state,
        updateTrailer: {
          ...state.updateTrailer,
          loading: {
            ...state.updateTrailer.loading,
            [trailerId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(trailerTypes.UPDATE_TRAILER): {
      return {
        ...state,
        updateTrailer: {
          loading: {
            ...state.updateTrailer.loading,
            [trailerId]: false,
          },
          data: {
            ...state.updateTrailer.data,
            [trailerId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.updateTrailer.error,
            [trailerId]: '',
          },
        },
      };
    }
    case asyncActionTypeFailure(trailerTypes.UPDATE_TRAILER): {
      return {
        ...state,
        updateTrailer: {
          loading: {
            ...state.updateTrailer.loading,
            [trailerId]: false,
          },
          data: {
            ...state.updateTrailer.data,
            [trailerId]: {},
          },
          error: {
            ...state.updateTrailer.error,
            [trailerId]: action.error,
          },
        },
      };
    }
    // UPDATE_TRAILER_STATUS
    case asyncActionTypeRequest(trailerTypes.UPDATE_TRAILER_STATUS): {
      return {
        ...state,
        updateTrailerStatus: {
          ...state.updateTrailerStatus,
          loading: {
            ...state.updateTrailerStatus.loading,
            [trailerId]: true,
          },
        },
      };
    }
    case asyncActionTypeSuccess(trailerTypes.UPDATE_TRAILER_STATUS): {
      return {
        ...state,
        updateTrailerStatus: {
          loading: {
            ...state.updateTrailerStatus.loading,
            [trailerId]: false,
          },
          data: {
            ...state.updateTrailerStatus.data,
            [trailerId]: get(action, 'response.data') || {},
          },
          error: {
            ...state.updateTrailerStatus.error,
            [trailerId]: {},
          },
        },
      };
    }
    case asyncActionTypeFailure(trailerTypes.UPDATE_TRAILER_STATUS): {
      return {
        ...state,
        updateTrailerStatus: {
          loading: {
            ...state.updateTrailerStatus.loading,
            [trailerId]: false,
          },
          data: {
            ...state.updateTrailerStatus.data,
            [trailerId]: {},
          },
          error: {
            ...state.updateTrailerStatus.error,
            [trailerId]: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
};
