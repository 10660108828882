import React from 'react';
import { Tabs as AntdTabs } from 'antd';
import { AndroidOutlined } from '@ant-design/icons';
import cx from 'classnames';
import PropTypes from 'prop-types';

import classes from './Tabs.module.scss';

const { TabPane } = AntdTabs;

const Tabs = props => {
  const {
    tabs,
    defaultActiveTab,
    border,
    className,
    type,
    hideAdd,
    isPane,
    spaceTop,
    ...other
  } = props;

  return (
    <AntdTabs
      {...other}
      defaultActiveKey={defaultActiveTab.toString()}
      type={type}
      hideAdd={hideAdd}
      className={cx(
        classes.wrapper,
        {
          [classes.border]: border,
          [classes.spaceTop]: spaceTop,
          'ant-tabs-custom-pane': isPane,
        },
        className
      )}
    >
      {tabs.map((tab, i) => {
        return (
          <TabPane
            tab={tab.title}
            key={i + 1}
            closable={tab.closable}
            disabled={tab.disabled}
          >
            {tab.content}
          </TabPane>
        );
      })}
    </AntdTabs>
  );
};

Tabs.defaultProps = {
  tabs: [
    {
      title: (
        <span>
          <AndroidOutlined />
          Tab 1
        </span>
      ),
      content: <span>Tab Content 1</span>,
      closable: false,
    },
    {
      title: <span>Tab 2</span>,
      content: <span>Tab Content 2</span>,
      closable: false,
    },
    {
      title: (
        <span>
          <AndroidOutlined />
          Tab 3
        </span>
      ),
      content: <span>Tab Content 3</span>,
      closable: false,
      disabled: true,
    },
    {
      title: <span>Tab 4</span>,
      content: <span>Tab Content 4</span>,
      closable: true,
    },
  ],
  defaultActiveTab: 1,
  border: false,
  className: '',
  hideAdd: true,
  type: 'inline',
  isPane: false,
};

Tabs.propTypes = {
  tabs: PropTypes.array,
  defaultActiveTab: PropTypes.number,
  border: PropTypes.bool,
  className: PropTypes.any,
  hideAdd: PropTypes.bool,
  isPane: PropTypes.bool,
  type: PropTypes.string,
};

export default Tabs;
