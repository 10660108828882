import React, { useState, useEffect } from 'react';
import isArray from 'lodash/isArray';
import findIndex from 'lodash/findIndex';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Tabs from 'components/Tabs';
import Button, { ButtonColors } from 'components/Button';
import { getUrlParamValueByKey, removeAllSearchParam } from 'utils/routes';
import PageContent from 'components/PageContent';
import RouterLeavingGuard from 'components/RouterLeavingGuard';
import { getErrorMessageFromObj } from 'utils/object';
import { useIsInternalUser } from 'helper/hooks';
import { getInitialValues as getPaymentSourceInitialValues } from 'views/pages/ClientDetail/PaymentSourceTab/utils';
import toast from 'helper/toast';
import { getTabs } from './uiUtils';
import getPermission from './permissions';
import NewOrderModal from './components/NewOrderModal';
import InviteUserModal from './UserManagementTab/components/InviteUserModal';
import { TAB_KEYS, TAB_KEYS_MAPPING } from './constants';
import { useActions, useMainContentData } from './selectorData';
import { getActiveTabName } from './utils';
import classes from './ClientDetail.module.scss';

let userFilters = {};

const initBeforeUnLoad = showExitPrompt => {
  window.onbeforeunload = event => {
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      e.stopPropagation();
      return '';
    }
  };
};
let paymentSourceForm = null;

const MainContent = ({ confirmModal, clientId, tabNames, location }) => {
  const { isInternalUser } = useIsInternalUser();
  const preselectTab = getUrlParamValueByKey(location.search, 'tab');
  const preStartOrderId = getUrlParamValueByKey(
    location.search,
    'startOrderId'
  );
  const [isEditing, setIsEditing] = useState(false);
  const [newOrderModal, setNewOrderModal] = useState(false);
  const [isEditOrder, setIsEditOrder] = useState(false);
  const [inviteUserModal, setInviteUserModal] = useState(false);
  const {
    getClientOrderDetail,
    getClientOrderFieldValues,
    getCompanyUsers,
  } = useActions();
  const {
    clientOrderDetail,
    orderDetailFieldValues,
    currentUser,
  } = useMainContentData();
  const permission = getPermission(currentUser.role, currentUser);
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(1);
  const [search, setSearch] = useState('');
  const orderDetailData = {
    ...(isArray(clientOrderDetail)
      ? clientOrderDetail[0] || {}
      : clientOrderDetail),
  };
  const openWarningModal = cb => {
    confirmModal(
      'Do you want to leave without saving?',
      <div>Any unsaved changes will be discarded.</div>,
      () => {
        if (cb) cb();
      },
      {
        type: 'confirm',
        onCancel: () => {},
        okText: 'Leave Without Saving',
        cancelText: 'Continue Editing',
        centered: true,
        className: classes.warningModal,
        revertButton: true,
      }
    );
  };
  const handleEditDraftOrder = record => {
    getClientOrderDetail(clientId, record.id, (res, err) => {
      if (res) {
        setIsEditOrder(true);
        setNewOrderModal(true);
        getClientOrderFieldValues(clientId, record.id);
      } else {
        toast.error(getErrorMessageFromObj(err)[0]);
      }
    });
  };
  const handleSubmitOrderCb = ({ cards, accounts }) => {
    if (paymentSourceForm) {
      const paymentSourceValues = getPaymentSourceInitialValues({
        accounts,
        cards,
      });
      paymentSourceForm.setFieldsValue({
        ...paymentSourceValues,
      });
    }
  };
  const tabs = getTabs({
    search,
    setSearch,
    confirmModal,
    setIsEditing,
    openWarningModal,
    activeTab,
    clientId,
    onEditDraftOrder: handleEditDraftOrder,
    permission,
    tabNames,
    paymentSourceFormChange: psForm => {
      paymentSourceForm = psForm;
    },
    onUsersFilterChanged: obj => {
      userFilters = obj;
    },
  });
  const activeTabName = getActiveTabName(activeTab, tabs);

  useEffect(() => {
    initBeforeUnLoad(isEditing);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  useEffect(() => {
    if (preselectTab || preStartOrderId) {
      const preselectTabKey = TAB_KEYS_MAPPING[preselectTab] || '';
      const preselectTabIndex = findIndex(tabs, { key: preselectTabKey });
      if (preselectTabIndex >= 0) {
        setActiveTab(preselectTabIndex + 1);
      }
      if (!preStartOrderId) {
        removeAllSearchParam(history);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselectTab, preStartOrderId]);

  useEffect(() => {
    userFilters = {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  return (
    <PageContent initFullHeight hasMargin className={classes.mainContent}>
      <RouterLeavingGuard
        when={isEditing}
        navigate={path => {
          history.push(path);
        }}
        handleOpenModal={cb => {
          openWarningModal(cb);
        }}
      />
      <Tabs
        tabs={tabs}
        type="line"
        activeKey={activeTab.toString()}
        onChange={activeKey => {
          const iActiveKey = parseInt(activeKey, 10);
          if (iActiveKey !== 1 && isEditing) {
            openWarningModal(() => {
              setActiveTab(iActiveKey);
              setIsEditing(false);
            });
          } else {
            setActiveTab(iActiveKey);
          }
        }}
        className={classes.tabs}
      />
      {isInternalUser &&
      [TAB_KEYS.CLIENT_RECORD, TAB_KEYS.USER_MANAGEMENT].includes(
        activeTabName
      ) ? (
        <div className={classes.tabHeaderButton}>
          <Button
            type="primary"
            size="medium"
            onClick={() => {
              setInviteUserModal(true);
            }}
            color={ButtonColors.BLUE}
            leftIcon={<PlusCircleOutlined />}
          >
            Invite User
          </Button>
        </div>
      ) : (
        <>
          {!!permission.newOrder && (
            <div className={classes.tabHeaderButton}>
              <Button
                type="primary"
                size="medium"
                onClick={() => {
                  setIsEditOrder(false);
                  setNewOrderModal(true);
                }}
                color={ButtonColors.BLUE}
                leftIcon={<PlusCircleOutlined />}
              >
                New Order
              </Button>
            </div>
          )}
        </>
      )}
      <NewOrderModal
        clientId={clientId}
        setActiveTab={setActiveTab}
        isOpen={newOrderModal}
        handleClose={() => {
          setNewOrderModal(false);
          setIsEditOrder(false);
        }}
        orderDetailData={orderDetailData}
        isEditOrder={isEditOrder}
        orderDetailFieldValues={isEditOrder ? orderDetailFieldValues : []}
        onSubmitOrderCb={handleSubmitOrderCb}
        permission={permission}
      />
      <InviteUserModal
        isOpen={inviteUserModal}
        handleClose={() => {
          setInviteUserModal(false);
        }}
        clientId={clientId}
        onSubmitCb={values => {
          getCompanyUsers(clientId, {
            ...userFilters,
          });
          setInviteUserModal(false);
          confirmModal(
            'Invitation has been sent to the user',
            <div>
              <span>The invitation email has been sent to</span>
              <br />
              <span>{values.email}.</span>
            </div>,
            () => {}
          );
        }}
      />
    </PageContent>
  );
};

export default MainContent;
