import React from 'react';
import cn from 'classnames';
import {
  Section as ElementSection,
  FormSection,
  ListInfo,
  Title,
} from 'views/pages/ClientDetail/components/Elements';
import { useOptions } from 'helper/hooks';
import InputItem from 'views/pages/ClientDetail/components/InputItem';
import { EQUIPMENT_OBJ_KEYS } from '../../constants';
import classes from './Section.module.scss';

const Equipment = ({ className, isEdit, form, data }) => {
  const { driverOptions, trailerOptions } = useOptions();
  return (
    <div className={cn(classes.equipmentSection, className)}>
      <Title text="Assigned Equipment" />
      <ElementSection className={classes.elSection}>
        {isEdit ? (
          <FormSection>
            <InputItem
              name={'assigned_drivers'}
              label="Assigned Driver"
              form={form}
              type="select"
              options={driverOptions}
              mode="multiple"
            />
            <InputItem
              name={'assigned_trailers'}
              label="Assigned Trailers"
              form={form}
              type="select"
              options={trailerOptions}
              mode="multiple"
            />
          </FormSection>
        ) : (
          <ListInfo objKeys={EQUIPMENT_OBJ_KEYS} data={data} />
        )}
      </ElementSection>
    </div>
  );
};

export default Equipment;
