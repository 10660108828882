import React from 'react';
import { Tag as AntdTag } from 'antd';
import cx from 'classnames';

import classes from './Tag.module.scss';

const Tag = props => {
  const { className, color, borderSameColor, size, ...other } = props;
  return (
    <AntdTag
      {...other}
      color={color}
      className={cx(
        classes.wrapper,
        {
          'ant-tag-primary': color === 'primary',
          'ant-tag-border-same-color': borderSameColor,
          [classes.large]: size === 'large',
        },
        className
      )}
    />
  );
};

export default Tag;
