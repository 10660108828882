import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_WORK_QUEUE_CC_COLUMNS',
  'GET_WORK_QUEUE_COLUMNS',
  'GET_WORK_QUEUE_FILTER_SETTING',
  'ADD_WORK_QUEUE_COLUMN',
  'UPDATE_WORK_QUEUE_COLUMN',
  'DELETE_WORK_QUEUE_COLUMN',
  'MOVE_WORK_QUEUE_ORDER_TO_COLUMN',
  'STORE_WORK_QUEUE_FILTER_SETTING',
  'SYNC_ADD_WORK_QUEUE_COLUMN',
  'SYNC_UPDATE_WORK_QUEUE_COLUMN',
  'SYNC_UPDATE_WORK_QUEUE_FILTER_SETTING'
);
